import { DisplayFromFHIR, getStringFromFHIR } from './ResourceFunctions';
import ProfilesByResourceType from './ProfilesByResourceType';

const DisplayClassifiers = ({ classificationsArray }) => {
  return <div style={{ marginLeft: "24px" }}>
    {(classificationsArray?.length) ?
      classificationsArray.map((classification, classificationIndex) => {
        return <div key={classificationIndex}>
          <DisplayFromFHIR classification={classification} />
        </div>
      })
      :
      <p>No classifiers.</p>
    }
  </div>
}

const displayProfileName = (profile, resourceType) => {
  let profileName;
  const profileDictionary = ProfilesByResourceType[resourceType];
  for (const key in profileDictionary) {
    if (profileDictionary[key].url === profile) {
      profileName = key;
    }
  }
  return profileName || profile;
}

const MetadataPatternDisplay = ({ fhirJson }) => {
  let title = "";
  if (fhirJson.title) {
    title = fhirJson.title;
  }
  let name = "";
  if (fhirJson.name) {
    name = fhirJson.name;
  }
  let url = "";
  if (fhirJson.url) {
    url = fhirJson.url;
  }
  let identifier = [];
  if (fhirJson.identifier) {
    identifier = JSON.parse(JSON.stringify(fhirJson.identifier));
  }
  let profile = [];
  if (fhirJson.meta.profile) {
    profile = fhirJson.meta.profile;
  }
  let version = "";
  if (fhirJson.version) {
    version = fhirJson.version;
  }
  let versionAlgorithmString = "";
  if (fhirJson.versionAlgorithmString) {
    versionAlgorithmString = fhirJson.versionAlgorithmString;
  }
  let status = "";
  if (fhirJson.status) {
    status = fhirJson.status;
  }
  let experimental = null;
  if (typeof fhirJson.experimental === "boolean") {
    experimental = fhirJson.experimental;
  }
  let date = "";
  if (fhirJson.date) {
    date = fhirJson.date;
  }
  let publisher = "";
  if (fhirJson.publisher) {
    publisher = fhirJson.publisher;
  }
  let contact = [];
  if (fhirJson.contact) {
    contact = JSON.parse(JSON.stringify(fhirJson.contact));
  }
  let custodian = "";
  if (fhirJson.custodian) {
    custodian = JSON.parse(JSON.stringify(fhirJson.custodian));
  }
  let description = "";
  if (fhirJson.description) {
    description = fhirJson.description;
  }
  let useContext = [];
  if (fhirJson.useContext) {
    useContext = JSON.parse(JSON.stringify(fhirJson.useContext));
  }
  let jurisdiction = [];
  if (fhirJson.jurisdiction) {
    jurisdiction = JSON.parse(JSON.stringify(fhirJson.jurisdiction));
  }
  let purpose = "";
  if (fhirJson.purpose) {
    purpose = fhirJson.purpose;
  }
  let copyright = "";
  if (fhirJson.copyright) {
    copyright = fhirJson.copyright;
  }
  let copyrightLabel = "";
  if (fhirJson.copyrightLabel) {
    copyrightLabel = fhirJson.copyrightLabel;
  }
  let approvalDate = "";
  if (fhirJson.approvalDate) {
    approvalDate = fhirJson.approvalDate;
  }
  let lastReviewDate = "";
  if (fhirJson.lastReviewDate) {
    lastReviewDate = fhirJson.lastReviewDate;
  }
  let effectivePeriod = "";
  if (fhirJson.effectivePeriod) {
    effectivePeriod = fhirJson.effectivePeriod;
  }
  let topic = [];
  if (fhirJson.topic) {
    topic = JSON.parse(JSON.stringify(fhirJson.topic));
  }
  let author = [];
  if (fhirJson.author) {
    author = JSON.parse(JSON.stringify(fhirJson.author));
  }
  if (fhirJson.resourceType === "List" && fhirJson.source) {
    author.push({"name": fhirJson.source.display});
  }
  let reviewer = [];
  if (fhirJson.reviewer) {
    reviewer = JSON.parse(JSON.stringify(fhirJson.reviewer));
  }
  let editor = [];
  if (fhirJson.editor) {
    editor = JSON.parse(JSON.stringify(fhirJson.editor));
  }
  let endorser = [];
  if (fhirJson.endorser) {
    endorser = JSON.parse(JSON.stringify(fhirJson.endorser));
  }
  let relatedArtifact = [];
  if (fhirJson.relatedArtifact) {
    relatedArtifact = JSON.parse(JSON.stringify(fhirJson.relatedArtifact));
  } else if (fhirJson.relatesTo) {
    relatedArtifact = JSON.parse(JSON.stringify(fhirJson.relatesTo));
  }
  let attester = [];
  if (Array.isArray(fhirJson.attester) && fhirJson.attester.length > 0) {
    attester = JSON.parse(JSON.stringify(fhirJson.attester));
  }
  let note = [];
  if (Array.isArray(fhirJson.note) && fhirJson.note.length > 0) {
    note = JSON.parse(JSON.stringify(fhirJson.note));
  }
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (const extension of fhirJson.extension) {
      switch (extension.url) {
        case 'http://hl7.org/fhir/StructureDefinition/artifact-title':
          title = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-name':
          name = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-url':
          url = extension.valueUri;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-identifier':
          identifier.push(extension.valueIdentifier);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-version':
          version = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-versionAlgorithm':
          versionAlgorithmString = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-status':
          status = extension.valueCode;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-experimental':
          experimental = extension.valueBoolean;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-date':
          date = extension.valueDateTime;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-publisher':
          publisher = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-contact':
          contact.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-description':
          description = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-useContext':
          useContext.push(extension.valueUsageContext);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-jurisdiction':
          jurisdiction.push(extension.valueCodeableConcept);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-purpose':
          purpose = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-copyright':
          copyright = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-copyrightLabel':
          copyrightLabel = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-approvalDate':
          approvalDate = extension.valueDate;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-lastReviewDate':
          lastReviewDate = extension.valueDate;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-effectivePeriod':
          effectivePeriod = extension.valuePeriod;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-topic':
          topic.push(extension.valueCodeableConcept);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-author':
          author.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-reviewer':
          reviewer.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-editor':
          editor.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-endorser':
          endorser.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-relatedArtifact':
          relatedArtifact.push(extension.valueRelatedArtifact);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/note':
          note.push(extension.valueAnnotation);
        default:
        //
      }
    }
  }
  if (Array.isArray(fhirJson.modifierExtension) && fhirJson.modifierExtension.length > 0) {
    for (const extension of fhirJson.modifierExtension) {
      switch (extension.url) {
        case 'http://hl7.org/fhir/StructureDefinition/artifact-title':
          title = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-name':
          name = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-url':
          url = extension.valueUri;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-identifier':
          identifier.push(extension.valueIdentifier);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-version':
          version = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-versionAlgorithm':
          versionAlgorithmString = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-status':
          status = extension.valueCode;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-experimental':
          experimental = extension.valueBoolean;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-date':
          date = extension.valueDateTime;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-publisher':
          publisher = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-contact':
          contact.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-description':
          description = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-useContext':
          useContext.push(extension.valueUsageContext);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-jurisdiction':
          jurisdiction.push(extension.valueCodeableConcept);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-purpose':
          purpose = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-copyright':
          copyright = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-copyrightLabel':
          copyrightLabel = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-approvalDate':
          approvalDate = extension.valueDate;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-lastReviewDate':
          lastReviewDate = extension.valueDate;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-effectivePeriod':
          effectivePeriod = extension.valuePeriod;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-topic':
          topic.push(extension.valueCodeableConcept);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-author':
          author.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-reviewer':
          reviewer.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-editor':
          editor.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-endorser':
          endorser.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-relatedArtifact':
          relatedArtifact.push(extension.valueRelatedArtifact);
          break;
        default:
        //
      }
    }
  }

  return <div>
    {title && <p><b>Title: </b>{title}</p>}
    {fhirJson.subtitle && <p><b>Subtitle: </b>{fhirJson.subtitle}</p>}
    {name && <p><b>Name: </b>{name}</p>}
    {url && <p><b>URL: </b>{url}</p>}
    {(Array.isArray(identifier) && identifier.length === 1) && <p><b>Identifier: </b>{getStringFromFHIR.Identifier(identifier[0])}</p>}
    {(Array.isArray(identifier) && identifier.length > 1) && <p><b>Identifiers: </b>{identifier.map((identifierInstance, identifierIndex) => getStringFromFHIR.Identifier(identifierInstance)).join(', ')}</p>}
    {(Array.isArray(profile) && profile.length === 1) && <p><b>Profile: </b>{displayProfileName(profile[0], fhirJson.resourceType)}</p>}
    {(Array.isArray(profile) && profile.length > 1) && <p><b>Profiles: </b>{profile.map((profileInstance, profileIndex) => displayProfileName(profileInstance, fhirJson.resourceType)).join(', ')}</p>}
    {version && <p><b>Version: </b>{version}</p>}
    {versionAlgorithmString && <p><b>Version Algorithm: </b>{versionAlgorithmString}</p>}
    {fhirJson.versionAlgorithmCoding && <p><b>Version Algorithm: </b><DisplayFromFHIR coding={fhirJson.versionAlgorithmCoding} /></p>}
    {status && <p><b>Status: </b>{status}</p>}
    {(typeof experimental === "boolean") && <p><b>Experimental: </b>{getStringFromFHIR.boolean(experimental)}</p>}
    {date && <p><b>Date last changed: </b>{getStringFromFHIR.dateTime(date)}</p>}
    {custodian && <span><b>Custodian/Publisher: </b><DisplayFromFHIR reference={custodian} /></span>}
    {publisher && <p><b>Publisher: </b>{publisher}</p>}
    {(contact && Array.isArray(contact)) && contact.map((contactInstance, contactIndex) => {
      return <div key={contactIndex}><div><b>Publisher Contact: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR contactDetail={contactInstance} /></div></div></div>
    })}
    {description && <div><b>Description: </b><DisplayFromFHIR markdown={description} /><br /><br /></div>}
    {(Array.isArray(useContext) && useContext.length === 1) && <div><b>Use Context: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR usageContext={useContext[0]} /></div><br /></div>}
    {(Array.isArray(useContext) && useContext.length > 1) && useContext.map((useContextInstance, useContextIndex) => {
      return <div key={useContextIndex}><div><b>Use Context: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR usageContext={useContextInstance} /></div></div><br /></div>
    })}
    {(Array.isArray(jurisdiction) && jurisdiction.length === 1) && <p><b>Jurisdiction: </b><DisplayFromFHIR codeableConcept={jurisdiction[0]} /></p>}
    {(Array.isArray(jurisdiction) && jurisdiction.length > 1) && jurisdiction.map((jurisdictionInstance, jurisdictionIndex) => {
      return <div key={jurisdictionIndex}><div><b>Jurisdiction: </b><DisplayFromFHIR codeableConcept={jurisdictionInstance} /></div></div>
    })}
    {purpose && <div><b>Purpose: </b><DisplayFromFHIR markdown={purpose} /><br /><br /></div>}
    {fhirJson.usage && <div><b>Usage: </b><DisplayFromFHIR markdown={fhirJson.usage} /></div>}
    {(Array.isArray(note) && note.length === 1) && <p><b>Note: </b><DisplayFromFHIR annotation={note[0]} /></p>}
    {(Array.isArray(note) && note.length > 1) && note.map((noteInstance, noteIndex) => {
      return <div key={noteIndex}><div><b>Note: </b><DisplayFromFHIR annotation={noteInstance} /></div></div>
    })}
    {copyright && <div><b>Copyright: </b><DisplayFromFHIR markdown={copyright} /><br /><br /></div>}
    {copyrightLabel && <p><b>Copyright: </b>{copyrightLabel}</p>}
    {approvalDate && <p><b>Date approved: </b>{getStringFromFHIR.date(approvalDate)}</p>}
    {lastReviewDate && <p><b>Date last reviewed: </b>{getStringFromFHIR.date(lastReviewDate)}</p>}
    {effectivePeriod && <p><b>Effective period: </b>{getStringFromFHIR.Period(effectivePeriod)}</p>}
    {(Array.isArray(topic) && topic.length === 1) && <p><b>Topic: </b><DisplayFromFHIR codeableConcept={topic[0]} /></p>}
    {(Array.isArray(topic) && topic.length > 1) && topic.map((topicInstance, topicIndex) => {
      return <div key={topicIndex}><div><b>Topic: </b><DisplayFromFHIR codeableConcept={topicInstance} /></div></div>
    })}
    {fhirJson.resourceType === "Composition" ?
      <>
        {(Array.isArray(author) && author.length === 1) &&
          <div><b>Author: </b>
            <>{author[0].name ?
              <div style={{ marginLeft: "24px" }}><DisplayFromFHIR contactDetail={author[0]} /></div>
              :
              <div style={{ marginLeft: "24px" }}><DisplayFromFHIR reference={author[0]} /></div>
            }</>
            <br />
          </div>
        }
        {(Array.isArray(author) && author.length > 1) && author.map((contact, contactIndex) => {
          return <div key={contactIndex}><div><b>Author: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR reference={contact} /></div></div></div>
        })}
        {(Array.isArray(attester) && attester.length === 1) && <div><b>Attester: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR attester={attester[0]} /></div><br /></div>}
        {(Array.isArray(attester) && attester.length > 1) && attester.map((attester, attesterIndex) => {
          return <div key={attesterIndex}><div><b>Attester: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR attester={attester} /></div></div></div>
        })}
      </>
      :
      <>
        {(Array.isArray(author) && author.length === 1) && <div><b>Author: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR contactDetail={author[0]} /></div><br /></div>}
        {(Array.isArray(author) && author.length > 1) && author.map((contact, contactIndex) => {
          return <div key={contactIndex}><div><b>Author: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR contactDetail={contact} /></div></div></div>
        })}
        {(Array.isArray(editor) && editor.length === 1) && <div><b>Editor: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR contactDetail={editor[0]} /></div><br /></div>}
        {(Array.isArray(editor) && editor.length > 1) && editor.map((contact, contactIndex) => {
          return <div key={contactIndex}><div><b>Editor: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR contactDetail={contact} /></div></div></div>
        })}
        {(Array.isArray(reviewer) && reviewer.length === 1) && <div><b>Reviewer: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR contactDetail={reviewer[0]} /></div><br /></div>}
        {(Array.isArray(reviewer) && reviewer.length > 1) && reviewer.map((contact, contactIndex) => {
          return <div key={contactIndex}><div><b>Reviewer: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR contactDetail={contact} /></div></div></div>
        })}
        {(Array.isArray(endorser) && endorser.length === 1) && <div><b>Endorser: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR contactDetail={endorser[0]} /></div><br /></div>}
        {(Array.isArray(endorser) && endorser.length > 1) && endorser.map((contact, contactIndex) => {
          return <div key={contactIndex}><div><b>Endorser: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR contactDetail={contact} /></div></div></div>
        })}
      </>
    }
    {(Array.isArray(relatedArtifact) && relatedArtifact.length === 1) && <div><b>Related Item: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR relatedArtifact={relatedArtifact[0]} /></div></div>}
    {(Array.isArray(relatedArtifact) && relatedArtifact.length > 1) && relatedArtifact.map((relatedItem, relatedItemIndex) => {
      return <div key={relatedItemIndex}><div><b>Related Item: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR relatedArtifact={relatedItem} /></div></div></div>
    })}
  </div>
}

export { MetadataPatternDisplay, DisplayClassifiers };