import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { TextField, Checkbox } from '@mui/material';
import DropDownWithAdditions from './DropDownWithAdditions';
import { changeResourceElementState } from './ResourceFunctions';

const SchemaElementDisplay = ({changeFormState, formInputsStateRef, fhirJson, fhirEntryState}) => {
  const buildStatusOptions = [
    {
      key: "deprecated",
      text: "Deprecated",
      value: "deprecated",
    },
    {
      key: "modified",
      text: "Modified",
      value: "modified",
    },
    {
      key: "Not Relevant",
      text: "Not Relevant",
      value: "Not Relevant",
    }
  ];
/*
  const schemaElementBlankState = {
    schema: "",
    context:[{}],
    mapping:[],
    minimumCardinality: "",
    maximumCardinality: "",
    datatype: [],
    contains: [],
    rule: [],
    allowedValue: [],
    allowedValueSet: []
  };
  */
  let schemaElementLoadedState = {
    name: fhirJson.name,
    title: fhirJson.title,
    schema: fhirJson.schema,
    schemaVersion: fhirJson.schemaVersion,
    path: fhirJson.path,
    elementPosition: fhirJson.elementPosition,
    elementName: fhirJson.elementName,
    description: fhirJson.description,
    mapping: [],
    minimumCardinality: fhirJson.minimumCardinality || "",
    maximumCardinality: fhirJson.maximumCardinality || "",
    datatype: [],
    datatypeCodeableConcept: [],
    contains: [],
    rule: [],
    allowedValue: [],
    allowedValueSet: []
  };
  
  //[{ key: "0", text: "0", value: "0" }, { key: "1", text: "1", value: "1" }]
  //[{ key: "1", text: "1", value: "1" }, { key: "*", text: "*", value: "*" }]

  let startingMinimumCardinalityOptions = [{ key: "0", text: "0", value: "0" }, { key: "1", text: "1", value: "1" }];
  let startingMaximumCardinalityOptions = [{ key: "1", text: "1", value: "1" }, { key: "*", text: "*", value: "*" }];

  if (fhirJson.minimumCardinality && fhirJson.minimumCardinality !== "0" && fhirJson.minimumCardinality !== "1") {
    startingMinimumCardinalityOptions.push({ key: fhirJson.minimumCardinality, text: fhirJson.minimumCardinality, value: fhirJson.minimumCardinality });
  }
  if (fhirJson.maximumCardinality && fhirJson.maximumCardinality !== "0" && fhirJson.maximumCardinality !== "1") {
    startingMaximumCardinalityOptions.push({ key: fhirJson.maximumCardinality, text: fhirJson.maximumCardinality, value: fhirJson.maximumCardinality });
  }

  let containsStartingOptions = [];
  for (let containsIndex in fhirJson.contains) {
    let containsInstance = fhirJson.contains[containsIndex];
    schemaElementLoadedState.contains.push(containsInstance);
    containsStartingOptions.push({ key: containsInstance, text: containsInstance, value: containsInstance });
  }
  let ruleStartingOptions = [];
  for (let ruleIndex in fhirJson.rule) {
    let ruleInstance = fhirJson.rule[ruleIndex];
    schemaElementLoadedState.rule.push(ruleInstance);
    ruleStartingOptions.push({ key: ruleInstance, text: ruleInstance, value: ruleInstance });
  }
  let allowedValueSetStartingOptions = [];
  for (let allowedValueSetIndex in fhirJson.allowedValueSet) {
    let allowedValueSet = fhirJson.allowedValueSet[allowedValueSetIndex];
    let allowedValueSetDisplay = "";
    if (allowedValueSet.display) {
      allowedValueSetDisplay = allowedValueSet.display;
    } else if (allowedValueSet.reference) {
      allowedValueSetDisplay = allowedValueSet.reference;      
    }
    schemaElementLoadedState.allowedValueSet.push(allowedValueSetDisplay);
    allowedValueSetStartingOptions.push({ key: allowedValueSet, text: allowedValueSet, value: allowedValueSet });
  }
  for (let datatypeCodeableConceptIndex in fhirJson.datatypeCodeableConcept) {
    schemaElementLoadedState.datatypeCodeableConcept.push(JSON.stringify(fhirJson.datatypeCodeableConcept[datatypeCodeableConceptIndex], null, 2));
  }
  for (let datatypeIndex in fhirJson.datatype) {
    if (typeof fhirJson.datatype[datatypeIndex] === "string") {
      schemaElementLoadedState.datatype.push(fhirJson.datatype[datatypeIndex]);
    } else {
      schemaElementLoadedState.datatypeCodeableConcept.push(JSON.stringify(fhirJson.datatype[datatypeIndex], null, 2));
    }
  }
  for (let allowedValueIndex in fhirJson.allowedValue) {
    schemaElementLoadedState.allowedValue.push(JSON.stringify(fhirJson.allowedValue[allowedValueIndex], null, 2));
  }
  for (let mapIndex in fhirJson.map) {
    let mappingInstance = {mappingSchemaElementFOI: '', mappingSchemaElementDisplay: '', mappingRule: [], mappingRuleOptions: [], mappingSchemaVersion: "", mappingSchemaVersionOptions: [], mappingSchemaPath: "", mappingSchemaPathOptions: [] };
    let mapInstance = fhirJson.map[mapIndex];
    mappingInstance.mappingFhirBuildStatus = mapInstance.laterFhirBuildStatus || 'Not Relevant';
    if (mapInstance.schemaElement) {
      mappingInstance.schemaElementJson = mapInstance.schemaElement;
      if (mapInstance.schemaElement.reference) {
        mappingInstance.mappingSchemaElementFOI = mapInstance.schemaElement.reference.replace("SchemaElement/", "") || '';
      }
      mappingInstance.mappingSchemaElementDisplay = mapInstance.schemaElement.display || '';
    }
    if (mapInstance.inverse !== undefined) {
      mappingInstance.inverse = mapInstance.inverse;
    } else {
      mappingInstance.inverse = false;
    }
    mappingInstance.mappingSchema = mapInstance.schema || '';
    mappingInstance.mappingElementName = mapInstance.elementName || '';
    mappingInstance.mappingSchemaVersion = mapInstance.schemaVersion || '';
    mappingInstance.mappingSchemaPath = mapInstance.schemaPath || '';
    for (let ruleIndex in mapInstance.rule) {
      let rule = mapInstance.rule[ruleIndex];
      mappingInstance.mappingRule.push(rule);
      mappingInstance.mappingRuleOptions.push({ key: rule, text: rule, value: rule });
    }
    schemaElementLoadedState.mapping.push(mappingInstance);
  }

  const [schemaElementState, setSchemaElementState] = useState(schemaElementLoadedState);

  const [minimumCardinalityOptions, setMinimumCardinalityOptions] = useState({"options": startingMinimumCardinalityOptions });
  const [maximumCardinalityOptions, setMaximumCardinalityOptions] = useState({"options": startingMaximumCardinalityOptions });
  //const [datatypeOptions, setDatatypeOptions] = useState({"options": []});
  const [containsOptions, setContainsOptions] = useState({"options": containsStartingOptions});
  const [ruleOptions, setRuleOptions] = useState({"options": ruleStartingOptions});
  //const [allowedValueOptions, setAllowedValueOptions] = useState({"options": []});
  //const [allowedValueSetOptions, setAllowedValueSetOptions] = useState({"options": allowedValueSetStartingOptions});

  useEffect( () => {
  }, []); 
  
  useEffect( async () => {
    if (fhirEntryState.editMode) {
        changeFormState("Computable Publishing®: SchemaElement Builder", "pageTitle");
    } else {
        changeFormState("Computable Publishing®: SchemaElement Viewer", "pageTitle");
    }
  }, [fhirEntryState]);
  
  useImperativeHandle(formInputsStateRef, () => ({
    schemaElementState: schemaElementState
  }), [schemaElementState]);    

  let editMode = fhirEntryState.editMode;

  return <div>    
    <div style={{marginTop: "12px"}}>
      <h3 id="identification">Identification</h3>
      <div style={{marginLeft: "24px"}}>
        {editMode ?
          <>
            <br/><TextField style={{width: "580px"}} multiline className="inputField" type='text' label={'Title'} size="small" variant='outlined' value={schemaElementState.title} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "title", undefined, undefined); } }/>
            <br/><br/><TextField style={{width: "580px"}} multiline className="inputField" type='text' label={'Name'} size="small" variant='outlined' value={schemaElementState.name} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "name", undefined, undefined); } }/>
            <br/><br/><TextField style={{width: "580px"}} multiline className="inputField" type='text' label={'Schema'} size="small" variant='outlined' value={schemaElementState.schema} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "schema", undefined, undefined); } }/>
            <br/><br/>
            <div style={{marginLeft: "24px"}}>
              <TextField style={{width: "200px"}} multiline className="inputField" type='text' label={'Schema Version'} size="small" variant='outlined' value={schemaElementState.schemaVersion} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "schemaVersion", undefined, undefined); } }/>
              <br/>
              <TextField style={{width: "440px", marginTop: "9px"}} multiline className="inputField" type='text' label={'Path'} size="small" variant='outlined' value={schemaElementState.path} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "path", undefined, undefined); } }/>
              <br/>
              <TextField style={{width: "200px", marginTop: "9px"}} multiline className="inputField" type='text' label={'Element Position'} size="small" variant='outlined' value={schemaElementState.elementPosition} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "elementPosition", undefined, undefined); } }/>
              {/*Array.isArray(schemaElementState.context) && schemaElementState.context.map( (context, contextIndex) => {
                if (editMode) {
                  return <div key={contextIndex}>
                      <h4 style={{marginTop: "10px", marginBottom: "0px"}}>Context {contextIndex+1} of {schemaElementState.context.length}</h4>
                      <div style={{paddingLeft: "28px", paddingTop: "6px"}}>

                      </div>
                      <br/>
                    </div>
                }
              })*/}
              {/*editMode && <><Button className="formButton" style={{ color: "#000000" }} content="+ Add Context" onClick={() => {
                setSchemaElementState(prevState  => {
                  let prevContext = schemaElementState.context;
                  prevContext.push({schemaVersion: "", path: "", elementPosition: ""});
                  return {
                    ...prevState,
                    context: prevContext,
                  };
                });
              } } /></>*/}
            </div>
            <br/><TextField style={{width: "580px"}} multiline className="inputField" type='text' label={'Element Name'} size="small" variant='outlined' value={schemaElementState.elementName} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "elementName", undefined, undefined); } }/>
          </>
        :
          <>
            <div><b>Title: </b> {schemaElementState.title || <span style={{ color: "#EE1111" }}>No data.</span>}</div>
            <div><b>Name: </b> {schemaElementState.name}</div>
            <div><b>Schema: </b> {schemaElementState.schema || <span style={{ color: "#EE1111" }}>No data.</span>} <span style={{paddingLeft: "60px"}}><b>Version:</b> {schemaElementState.schemaVersion || <span style={{ color: "#EE1111" }}>No data.</span>}</span></div>
            <div><b>Path: </b> {schemaElementState.path || <span style={{ color: "#EE1111" }}>No data.</span>} <span style={{paddingLeft: "60px"}}><b>ElementPosition:</b> {schemaElementState.elementPosition || <span style={{ color: "#EE1111" }}>No data.</span>}</span></div>
            {/*fhirJson.context && fhirJson.context.length > 0 ? fhirJson.context.map((context, contextIndex) => { return <div key={contextIndex}><i>Context {contextIndex+1} of {fhirJson.context.length}: </i> <b>Version:</b> {context.schemaVersion || "____"}, <b>Path:</b> {context.path || "____"}, <b>ElementPosition:</b> {context.elementPosition || "____"}</div>; }) : <><b>Context:</b> <span style={{ color: "#EE1111" }}>No data.</span></>*/}
            <div><b>Element Name: </b> {schemaElementState.elementName || <span style={{ color: "#EE1111" }}>No data.</span>}</div>
          </>
        }
      </div>
      <br/>
      <h3 id="details">Details</h3>
      <div style={{marginLeft: "24px"}}>
        {editMode ?
          <>
            <DropDownWithAdditions width={"140px"} currentValue={schemaElementState.minimumCardinality} labelText={'Min Cardinality'} options={minimumCardinalityOptions.options} setter={setMinimumCardinalityOptions} stateSetter={setSchemaElementState} changeElementState={changeResourceElementState} elementKeyEntry={"minimumCardinality"} multiple={false} />
            &nbsp;&nbsp;
            <DropDownWithAdditions width={"140px"} currentValue={schemaElementState.maximumCardinality} labelText={'Max Cardinality'} options={maximumCardinalityOptions.options} setter={setMaximumCardinalityOptions} stateSetter={setSchemaElementState} changeElementState={changeResourceElementState} elementKeyEntry={"maximumCardinality"} multiple={false} />
            <br/><br/>
            {/*<DropDownWithAdditions width={"340px"} currentValue={schemaElementState.datatype} labelText={'Datatype'} options={datatypeOptions.options} setter={setDatatypeOptions} stateSetter={setSchemaElementState} changeElementState={changeResourceElementState} elementKeyEntry={"datatype"} multiple={true} />*/}
            <h4 style={{marginTop: "10px", marginBottom: "0px"}}>Datatype</h4>
            {schemaElementState.datatype.map((datatype, datatypeIndex) => {
              return <div key={datatypeIndex} style={{paddingLeft: "28px", paddingTop: "12px"}}>
                  <TextField style={{width: "500px"}} multiline className="inputField" type='text'
                  label={`Datatype CodeableConcept JSON ${datatypeIndex+1} of ${schemaElementState.datatype.length}`}
                  size="small" variant='outlined'
                  value={datatype}
                  onChange={ (e) => {
                    changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "datatype", datatypeIndex, undefined); } }/>
                </div>
            })}
            <Button className="formButton" style={{ color: "#000000", marginTop: "8px" }} content="+ Add Datatype" onClick={() => {
              setSchemaElementState(prevState  => {
                let prevDatatype = schemaElementState.datatype;
                prevDatatype.push("");
                return {
                  ...prevState,
                  datatype: prevDatatype,
                };
              });
            } } />
            <br/><br/>
            <h4 style={{marginTop: "10px", marginBottom: "0px"}}>Datatype CodeableConcept</h4>
            {schemaElementState.datatypeCodeableConcept.map((datatypeCodeableConcept, datatypeCodeableConceptIndex) => {
              return <div key={datatypeCodeableConceptIndex} style={{paddingLeft: "28px", paddingTop: "12px"}}>
                  <TextField style={{width: "500px"}} multiline className="inputField" type='text'
                  label={`Datatype CodeableConcept JSON ${datatypeCodeableConceptIndex+1} of ${schemaElementState.datatypeCodeableConcept.length}`}
                  size="small" variant='outlined'
                  value={datatypeCodeableConcept}
                  onChange={ (e) => {
                    changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "datatypeCodeableConcept", datatypeCodeableConceptIndex, undefined); } }/>
                </div>
            })}
            <Button className="formButton" style={{ color: "#000000", marginTop: "8px" }} content="+ Add Datatype CodeableConcept" onClick={() => {
              setSchemaElementState(prevState  => {
                let prevDatatypeCodeableConcept = schemaElementState.datatypeCodeableConcept;
                prevDatatypeCodeableConcept.push("");
                return {
                  ...prevState,
                  datatypeCodeableConcept: prevDatatypeCodeableConcept,
                };
              });
            } } />
            &nbsp;&nbsp;&nbsp;
            <Button className="formButton" style={{ color: "#000000", marginTop: "8px" }} content="Launch CodeableConcept Builder" onClick={() => {  window.open('/createcodeableconcept'); } } />
            <><br/><br/><TextField style={{width: "580px"}} multiline className="inputField" type='text' label={'Description'} size="small" variant='outlined' value={schemaElementState.description} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "description", undefined, undefined); } }/></>
            <br/><br/>
            <DropDownWithAdditions width={"340px"} currentValue={schemaElementState.contains} labelText={'Contains'} options={containsOptions.options} setter={setContainsOptions} stateSetter={setSchemaElementState} changeElementState={changeResourceElementState} elementKeyEntry={"contains"} multiple={true} />
            <br/><br/>
            <DropDownWithAdditions width={"340px"} currentValue={schemaElementState.rule} labelText={'Rule'} options={ruleOptions.options} setter={setRuleOptions} stateSetter={setSchemaElementState} changeElementState={changeResourceElementState} elementKeyEntry={"rule"} multiple={true} />
            <br/><br/>
            {/*<DropDownWithAdditions width={"340px"} currentValue={schemaElementState.allowedValue} labelText={'AllowedValue'} options={allowedValueOptions.options} setter={setAllowedValueOptions} stateSetter={setSchemaElementState} changeElementState={changeResourceElementState} elementKeyEntry={"allowedValue"} multiple={true} />*/}
            <h4 style={{marginTop: "10px", marginBottom: "0px"}}>AllowedValue</h4>
            {schemaElementState.allowedValue.map((allowedValue, allowedValueIndex) => {
              return <div key={allowedValueIndex} style={{paddingLeft: "28px", paddingTop: "12px"}}>
                  <TextField style={{width: "500px"}} multiline className="inputField" type='text'
                  label={`AllowedValue CodeableConcept JSON ${allowedValueIndex+1} of ${schemaElementState.allowedValue.length}`}
                  size="small" variant='outlined'
                  value={allowedValue}
                  onChange={ (e) => {
                    changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "allowedValue", allowedValueIndex, undefined); } }/>
                </div>
            })}
            <Button className="formButton" style={{ color: "#000000", marginTop: "8px" }} content="+ Add AllowedValue" onClick={() => {
              setSchemaElementState(prevState  => {
                let prevAllowedValue = schemaElementState.allowedValue;
                prevAllowedValue.push("");
                return {
                  ...prevState,
                  allowedValue: prevAllowedValue,
                };
              });
            } } />
            &nbsp;&nbsp;&nbsp;
            <Button className="formButton" style={{ color: "#000000", marginTop: "8px" }} content="Launch CodeableConcept Builder" onClick={() => {  window.open('/createcodeableconcept'); } } />
            <br/>
            {/*<DropDownWithAdditions width={"340px"} currentValue={schemaElementState.allowedValueSet} labelText={'AllowedValueSet'} options={allowedValueSetOptions.options} setter={setAllowedValueSetOptions} stateSetter={setSchemaElementState} changeElementState={changeResourceElementState} elementKeyEntry={"allowedValueSet"} multiple={true} disabled={true} />*/}
            <div style={{whiteSpace: "pre-wrap", paddingTop: "10px"}}><b>Allowed Value Set:</b> {fhirJson.allowedValueSet && JSON.stringify(fhirJson.allowedValueSet, null, 2)}</div>
          </>
        :
          <>
            <div><b>Min Cardinality: </b> {schemaElementState.minimumCardinality || <span style={{ color: "#EE1111" }}>No data</span>}, <b>Max Cardinality: </b> {schemaElementState.maximumCardinality || <span style={{ color: "#EE1111" }}>No data.</span>}</div>
            {(schemaElementState.datatype === undefined || schemaElementState.datatype.length === 0) && <div style={{whiteSpace: "pre-wrap"}}><b>Datatype:</b> <span style={{ color: "#EE1111" }}>No data.</span></div>}
            {(schemaElementState.datatype && schemaElementState.datatype.length === 1) &&
              <div style={{whiteSpace: "pre-wrap"}}><b>Datatype: </b>
                <>{schemaElementState.datatype[0] && schemaElementState.datatype[0].toLowerCase().substring(0,4) === "http" ? <a href={schemaElementState.datatype[0]} target="_blank" rel="noopener noreferrer">{schemaElementState.datatype[0]}</a> : schemaElementState.datatype[0]}</>
              </div>
            }
            {(schemaElementState.datatype && schemaElementState.datatype.length > 1) &&
              <div style={{whiteSpace: "pre-wrap"}}><b>Datatypes: </b>
                <div style={{marginLeft: "40px"}}>{schemaElementState.datatype.map((datatypeEntry, datatypeIndex) => {
                  return <div key={datatypeIndex}>
                      {datatypeEntry.toLowerCase().substring(0,4) === "http" ? <a href={datatypeEntry} target="_blank" rel="noopener noreferrer">{datatypeEntry}</a>: datatypeEntry}
                    </div>
                })}</div>
              </div>
            }
            <div style={{whiteSpace: "pre-wrap"}}><b>Datatype CodeableConcept JSON: </b>{schemaElementState.datatypeCodeableConcept?.length > 0 ? <>[<div style={{paddingLeft: "20px"}}>{schemaElementState.datatypeCodeableConcept.join(", ")}</div>]</> : <span style={{ color: "#EE1111" }}>No data.</span>}</div>
            <div><b>Description: </b>{schemaElementState.description || <span style={{ color: "#EE1111" }}>No data.</span>}</div>
            <div><b>Contains: </b>{(Array.isArray(schemaElementState.contains) && schemaElementState.contains.length > 0) ? schemaElementState.contains.join(", ") : <span style={{ color: "#EE1111" }}>No data.</span>}</div>
            <div><b>Rule: </b>{(Array.isArray(schemaElementState.rule) && schemaElementState.rule.length > 0) ? schemaElementState.rule.join(", ") : <span style={{ color: "#EE1111" }}>No data.</span>}</div>
            <div style={{whiteSpace: "pre-wrap"}}><b>AllowedValue CodeableConcept JSON:</b> {fhirJson.allowedValue ? JSON.stringify(fhirJson.allowedValue, null, 2) : <span style={{ color: "#EE1111" }}>No data.</span>}</div>
            <div style={{whiteSpace: "pre-wrap"}}><b>AllowedValueSet CodeableConcept JSON:</b> {fhirJson.allowedValueSet ? JSON.stringify(fhirJson.allowedValueSet, null, 2) : <span style={{ color: "#EE1111" }}>No data.</span>}</div>
          </>
        }
      </div>
      <br/>
      <h3 id="mapping">Mapping</h3>
      <div style={{marginLeft: "24px"}}>
      {(!editMode && (!Array.isArray(schemaElementState.mapping) || schemaElementState.mapping.length === 0)) && <span style={{ color: "#EE1111" }}>No data.</span>}
      {Array.isArray(schemaElementState.mapping) && schemaElementState.mapping.map( (mapping, mappingIndex) => {
          const addMappingOption = (field, value) => {
            let newOptions = schemaElementState.mapping[mappingIndex][field];
            newOptions.push({ key: value, text: value, value: value });
            changeResourceElementState(setSchemaElementState, newOptions, undefined, undefined, "mapping", mappingIndex, field);
          }
          if (editMode) {
            return <div key={mappingIndex}>
                <h4 style={{marginTop: "10px", marginBottom: "0px"}}>Map {mappingIndex+1} of {schemaElementState.mapping.length}</h4>
                <div style={{paddingLeft: "28px"}}>
                  <>
                    <>Inverse: <Checkbox
                        checked={mapping.inverse}
                        onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.checked, undefined, undefined, "mapping", mappingIndex, "inverse"); } }
                        color="primary"
                        style={{ paddingLeft: "0px" }}
                      />
                    </>
                    <br/>
                    <>Later FHIR Build Status:</> <Dropdown placeholder={"Later FHIR Build Status"} closeOnChange selection clearable selectOnBlur={false}
                        style={{width: "380px"}}
                        options={buildStatusOptions}
                        value={mapping.mappingFhirBuildStatus}
                        onChange={(e, data) => { changeResourceElementState(setSchemaElementState, data.value, undefined, undefined, "mapping", mappingIndex, "mappingFhirBuildStatus"); }}
                    />
                    <br/>
                    <div style={{verticalAlign: "middle", display: "flex", alignItems: "center", marginTop: "9px"}}>
                      <span>SchemaElement: </span>
                      &nbsp;
                      <TextField style={{width: "110px"}} multiline className="inputField" type='text' label={'FOI'} size="small" variant='outlined' value={mapping.mappingSchemaElementFOI} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "mapping", mappingIndex, "mappingSchemaElementFOI"); } }/>
                      &nbsp;&nbsp;
                      <TextField style={{width: "440px"}} multiline className="inputField" type='text' label={'Display'} size="small" variant='outlined' value={mapping.mappingSchemaElementDisplay} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "mapping", mappingIndex, "mappingSchemaElementDisplay"); } }/>
                    </div>
                  </>
                  <br/>
                  <><TextField style={{width: "480px", marginTop: "9px"}} multiline className="inputField" type='text' label={'Schema'} size="small" variant='outlined' value={mapping.mappingSchema} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "mapping", mappingIndex, "mappingSchema"); } }/></>
                  <br/><br/>
                  <><TextField style={{width: "440px"}} multiline className="inputField" type='text' label={'Schema Version'} size="small" variant='outlined' value={mapping.mappingSchemaVersion} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "mapping", mappingIndex, "mappingSchemaVersion"); } }/></>
                  <br/><br/>
                  <><TextField style={{width: "440px"}} multiline className="inputField" type='text' label={'Schema Path'} size="small" variant='outlined' value={mapping.mappingSchemaPath} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "mapping", mappingIndex, "mappingSchemaPath"); } }/></>
                  <br/><br/>
                  <><TextField style={{width: "480px", marginTop: "9px"}} multiline className="inputField" type='text' label={'Element Name'} size="small" variant='outlined' value={mapping.mappingElementName} onChange={(e) => { changeResourceElementState(setSchemaElementState, e.target.value, undefined, undefined, "mapping", mappingIndex, "mappingElementName"); } }/></>
                  <br/><br/>
                  <div style={{verticalAlign: "middle", display: "flex", alignItems: "center"}}><span>Rule:</span>&nbsp;<DropDownWithAdditions width={"660px"} currentValue={mapping.mappingRule} labelText={'Rule'} options={mapping.mappingRuleOptions} addOptionCustom={addMappingOption} setter={"mappingRuleOptions"} stateSetter={setSchemaElementState} changeElementState={changeResourceElementState} index={mappingIndex} elementKeyEntry={"mapping"} elementKeySubEntry={"mappingRule"} multiple={true} /></div>
                </div>
                <br/><br/>
              </div>
          } else {
            return <div key={mappingIndex}>
              <h4 style={{marginTop: "10px", marginBottom: "0px"}}>Map {mappingIndex+1} of {schemaElementState.mapping.length}</h4>
              <div style={{marginLeft: "24px"}}>
                {mapping.inverse && <div><b>Inverse Mapping</b></div>}
                {mapping.mappingFhirBuildStatus !== "Not Relevant" && <div><b>Later FHIR Build Status: </b> {mapping.mappingFhirBuildStatus}</div>}
                {mapping.schemaElementJson && <div><b>SchemaElement: </b> {JSON.stringify(mapping.schemaElementJson)}</div>}
                {mapping.mappingSchema && <div><b>Schema: </b> {mapping.mappingSchema}</div>}
                {(mapping.mappingSchemaVersion && !Array.isArray(mapping.mappingSchemaVersion)) && <div><b>Schema Version: </b> {mapping.mappingSchemaVersion}</div>}
                {(mapping.mappingSchemaPath && !Array.isArray(mapping.mappingSchemaPath)) && <div><b>Schema Path: </b> {mapping.mappingSchemaPath}</div>}
                {mapping.mappingElementName && <div><b>Element Name: </b> {mapping.mappingElementName}</div>}
                {(mapping.mappingRule && Array.isArray(mapping.mappingRule)) && <div><b>Rule: </b> {mapping.mappingRule.join(", ")}</div>}
              </div>
            </div>;
          }
        })
      }
      {editMode && <><Button className="formButton" style={{ color: "#000000" }} content="+ Add Map" onClick={() => {
        setSchemaElementState(prevState  => {
          let prevMapping = schemaElementState.mapping;
          prevMapping.push({mappingFhirBuildStatus: "Not Relevant", mappingRuleOptions: [], mappingSchemaVersion: [], mappingSchemaVersionOptions: [], mappingSchemaPath: [], mappingSchemaPathOptions: [] });
          return {
            ...prevState,
            mapping: prevMapping,
          };
        });
       } } /></>}
      </div>
    </div>
    <br/>
  </div>
}

export default SchemaElementDisplay;