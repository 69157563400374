import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Table } from 'semantic-ui-react';
import { SimpleResourceFieldViewer } from './ResourceFunctions';
import { CertaintyRows } from './EvidenceFunctions';
import { commentRatingTextViewCalculations } from './CommentRatingFunctions';

const CommentRatingDisplay = ({formInputsStateRef, fhirJson, fhirEntryState, changeFhirEntryState, changeFormState}) => {

    const commentRatingBlankState = {"invalidCommentRating": false, "headers": ["Type", "Rating", "Description", "Notes", "Rater"]};
    
    const [commentRatingState, setCommentRatingState] = useState(commentRatingBlankState);

    useEffect( async () => {
        if (fhirEntryState.editMode) {
            changeFormState("Computable Publishing®: Comment/Rating Builder", "pageTitle");
        } else {
            changeFormState("Computable Publishing®: Comment/Rating Viewer", "pageTitle");
        }
        if (fhirEntryState.activeIndex == 0) {
            commentRatingTextViewCalculations(fhirEntryState, changeFhirEntryState, setCommentRatingState, commentRatingBlankState);
        }
    }, [fhirEntryState]);

    useImperativeHandle(formInputsStateRef, () => ({
        commentRatingState: commentRatingState
      }), [commentRatingState]);    

    let detailsSubheader = "";
    if (fhirJson.informationType === "classifier") {
      detailsSubheader = "Classifiers";
    } else if (fhirJson.informationType === "comment") {
      detailsSubheader = "Comments";
    } else if (fhirJson.informationType === "rating") {
      detailsSubheader = "Ratings";
    } else if (fhirJson.informationType === "container") {
      detailsSubheader = "Classifiers, Comments, and Ratings";
    }

    let editMode = fhirEntryState.editMode;
    return <div>
        <div style={{marginTop: "12px"}}>
        <h3 id="summary">Summary</h3>
          <div style={{marginLeft: "24px"}}>
            <span><b>Name: </b> {fhirJson.name ? fhirJson.name : <></>}</span>
            
            {fhirJson.title && <span><br/><b>Title: </b> {fhirJson.title}</span>}
            <br/>
            <span><b>Rated Artifact: </b> {fhirJson.ratedArtifact?.reference?.split("/").length === 2  ? <a href={"/resources/"+fhirJson.ratedArtifact.reference} target="_blank" rel="noopener noreferrer">{fhirJson.ratedArtifact.display}</a> : <>{fhirJson.ratedArtifact.display}</>}</span>
          </div>
        <br />
        <h3 id="classifiers">Classifiers</h3>
        <div style={{marginLeft: "24px"}}>TBD: There are no classifiers.</div>
        <h3 id="ratings">Ratings</h3>
        <div style={{marginLeft: "24px"}}>
          {detailsSubheader && <b>{detailsSubheader}</b>}
          <Table style={{margin: "4px"}}>
              <Table.Header>
                  <Table.Row>
                      <Table.HeaderCell style={{padding: "6px"}}>{commentRatingState.headers[0]}</Table.HeaderCell>
                      <Table.HeaderCell style={{padding: "6px"}}>{commentRatingState.headers[1]}</Table.HeaderCell>
                      <Table.HeaderCell style={{padding: "6px", width: "25%"}}>{commentRatingState.headers[2]}</Table.HeaderCell>
                      <Table.HeaderCell style={{padding: "6px", width: "25%"}}>{commentRatingState.headers[3]}</Table.HeaderCell>
                      <Table.HeaderCell style={{padding: "6px"}}>{commentRatingState.headers[4]}</Table.HeaderCell>
                  </Table.Row>
              </Table.Header>
              <Table.Body>
                  <CertaintyRows certaintyEntries={fhirJson.component} level={0} editMode={editMode} state={commentRatingState} stateSetter={setCommentRatingState} />
              </Table.Body>
          </Table>
        </div>
        <h3 id="comments">Comments</h3>
        <div style={{marginLeft: "24px"}}>TBD: There are no comments.</div>
        <h3 id="metadata">Metadata</h3>  
        </div>
        <SimpleResourceFieldViewer resource={JSON.parse(fhirEntryState.fhirEntryString)} parentElement={""} />
      </div>
}

export default CommentRatingDisplay;