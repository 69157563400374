import React, { useState, useImperativeHandle } from 'react';
import { SimpleResourceFieldViewer, getStringFromFHIR } from './ResourceFunctions';
import { DataEntry, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import { Table } from 'semantic-ui-react';
import { CertaintyRows } from './EvidenceFunctions';
import SEVCO from './SEVCO';

const evidenceDotSynthesisTypeValueSet = [
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "std-MA", display: "summary data meta-analysis" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "IPD-MA", display: "individual patient data meta-analysis" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "indirect-NMA", display: "indirect network meta-analysis" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "combined-NMA", display: "combined direct plus indirect network meta-analysis" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "range", display: "range of results" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "classification", display: "classifcation of results" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "NotApplicable", display: "not applicable" }
];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.assertion) {
      innerDiv += "<p><b>Assertion: </b>" + resource.assertion + "</p><br/>";
    }
    if (resource.note?.length > 0) {
      innerDiv += resource.note.map((note) => {
        return "<p><b>Note: </b>" + note.text + "</p><br/>"
      }).join("");
    }
    if (resource.variableDefinition?.length > 0) {
      innerDiv += resource.variableDefinition.map((varDef) => {
        let varRole;
        if (typeof(varDef.variableRole) === "string") {
          varRole = varDef.variableRole;
        } else if (varDef.variableRole.coding?.[0]?.code) {
          varRole = varDef.variableRole.coding[0].code;
        } else {
          varRole = getStringFromFHIR.CodeableConcept(varDef.variableRole);
        }
        let varDefDisplay = "<b>Variable in role of " + varRole + ": </b>";
        if (varDef.description) {
          varDefDisplay += varDef.description;
        } else if (varDef.observed?.display) {
          varDefDisplay += varDef.observed.display;
        } else if (varDef.intended?.display) {
          varDefDisplay += varDef.intended.display;
        } else {
          varDefDisplay += "noted";
        }
        return "<p>" + varDefDisplay + "</p><br/>"
      }).join("");
    }
    if (resource.synthesisType) {
      innerDiv += "<p><b>Synthesis Type: </b>" + getStringFromFHIR.CodeableConcept(resource.synthesisType) + "</p><br/>";
    }
    if (resource.studyDesign?.length > 0) {
      innerDiv += resource.studyDesign.map((item) => {
        return "<p><b>Study Design: </b>" + getStringFromFHIR.CodeableConcept(item) + "</p><br/>"
      }).join("");
    }
    if (resource.statistic?.length > 0) {
      innerDiv += resource.statistic.map((item) => {
        if (item.description) {
          return "<p><b>Statistic: </b>" + item.description + "</p><br/>"
        } else if (item.statisticType && item.quantity) {
          return "<p><b>Statistic: </b>" + getStringFromFHIR.CodeableConcept(item.statisticType) + " " + getStringFromFHIR.Quantity(item.quantity) + "</p><br/>"
        } else {
          return "<p><b>Statistic: </b>unclear</p><br/>"
        }
      }).join("");
    }
    if (resource.certainty?.length > 0) {
      innerDiv += resource.certainty.map((item) => {
        let certaintyType = getStringFromFHIR.CodeableConcept(item.type) || "untyped";
        let certaintyRating = getStringFromFHIR.CodeableConcept(item.rating) || "unrated";
        return "<p><b>Certainty of type " + certaintyType + ": </b>" + certaintyRating + "</p><br/>"
      }).join("");
    }
  } catch { 
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const EvidenceEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson, classificationsArrayState, classificationsLoadedState }) => {
  /*
    const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
      "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title",
      "citeAsReference", "citeAsMarkdown", "status", "experimental", "date", "publisher", "contact", "description",
      "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
      "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
      "assertion", "note", "variableDefinition", "synthesisType", "studyDesign", "statistic", "certainty"]
  */
  if (!fhirJson.studyDesign && fhirJson.studyType) {
    if (Array.isArray(fhirJson.studyType)) {
      fhirJson.studyDesign = fhirJson.studyType;
    } else {
      fhirJson.studyDesign = [fhirJson.studyType];
    }
  }

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "versionAlgorithmString": fhirJson.versionAlgorithmString, "versionAlgorithmCoding": fhirJson.versionAlgorithmCoding,
    "name": fhirJson.name, "title": fhirJson.title, "citeAsReference": fhirJson.citeAsReference, "citeAsMarkdown": fhirJson.citeAsMarkdown,
    "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "assertion": fhirJson.assertion, "note": fhirJson.note, "variableDefinition": fhirJson.variableDefinition,
    "synthesisType": fhirJson.synthesisType, "studyDesign": fhirJson.studyDesign,
    "statistic": fhirJson.statistic, "certainty": fhirJson.certainty, "newClassifications": null
  });

  useImperativeHandle(formInputsStateRef, () => ({
    evidenceState: resourceState
  }), [resourceState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startingValue={resourceState.description} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='assertion' fieldLabel='Assertion'
          startingValue={resourceState.assertion} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Annotation' elementName='note'
          startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
          fieldLabel='Note' startingValue={resourceState.note} setResourceState={setResourceState} />
        <h4>Narrative Summary</h4>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
            generatedNarrative={generateNarrative(resourceState)}
            startingValue={resourceState.text} setResourceState={setResourceState} />
        </div>
      </div>
      <h3 id="variable-definitions">Variable Definitions</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='EvidenceVariableDefinition' elementName='variableDefinition'
          fieldLabel='Variable Definition' startingValue={resourceState.variableDefinition} setResourceState={setResourceState} />
      </div>
      <h3 id="study-design">Study Design</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='CodeableConcept' elementName='synthesisType' fieldLabel='Synthesis Type'
          startingValue={resourceState.synthesisType}
          valueSet={evidenceDotSynthesisTypeValueSet} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='CodeableConcept' elementName='studyDesign' fieldLabel='Study Design'
          startingValue={resourceState.studyDesign}
          valueSet={SEVCO.studyDesign} setResourceState={setResourceState} />
      </div>
      <h3 id="statistics">Statistics</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='EvidenceStatistic' elementName='statistic'
          fieldLabel='Statistic' startingValue={resourceState.statistic} setResourceState={setResourceState} />
      </div>
      <h3 id="certainty">Certainty</h3>
      <p>Note: Certainty elements are not editable from here yet.</p>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.certainty?.length > 0 &&
          <Table style={{ margin: "4px" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ padding: "6px" }}>Type</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px" }}>Rating</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px", width: "25%" }}>Description</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px", width: "25%" }}>Notes</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px" }}>Rater</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <CertaintyRows certaintyEntries={fhirJson.certainty} level={0} editMode={false} />
            </Table.Body>
          </Table>
        }
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default EvidenceEdit;
