import { DisplayFromFHIR, getStringFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { Table } from 'semantic-ui-react';
import EvidenceVariableCriteriaDisplay from './EvidenceVariableCriteriaDisplay';
import DisplayGroupCharacteristics from './DisplayGroupCharacteristics';

const EvidenceVariableDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (fhirJson.relatedArtifact && Array.isArray(fhirJson.relatedArtifact) && fhirJson.relatedArtifact.length) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }
  let definitionReference = fhirJson.definition?.reference?.reference || "";
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.shortTitle && <p><b>Short title: </b>{fhirJson.shortTitle}</p>}
        {fhirJson.name && <p><b>Name: </b>{fhirJson.name}</p>}
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
        {(fhirJson.note && Array.isArray(fhirJson.note) && fhirJson.note.length > 0) &&
          fhirJson.note.map((note, noteIndex) => {
            return <div key={noteIndex}><b>Note: </b><DisplayFromFHIR annotation={note} /><br /><br /></div>
          })}
        {(typeof fhirJson.actual === "boolean") &&
          <p><b>Actual:</b> {getStringFromFHIR.boolean(fhirJson.actual)}</p>}
        {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") && <>
          <h4>Narrative Summary:</h4>
          <div style={{ marginLeft: "24px" }}>
            <DisplayFromFHIR xhtml={fhirJson.text.div} />
          </div>
        </>}
      </div>
      <h3 id="definition">Definition</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.definition && <>
          <DisplayFromFHIR codeableReference={fhirJson.definition} />
          {definitionReference && <>
            <br />
            <DisplayGroupCharacteristics reference={definitionReference} />
          </>}
        </>}
        {fhirJson.characteristicDefinition &&
          <DisplayFromFHIR reference={fhirJson.characteristicDefinition} />}
        {fhirJson.characteristic && <EvidenceVariableCriteriaDisplay fhirJson={fhirJson} />}
        {(!fhirJson.definition && !fhirJson.characteristic && !fhirJson.characteristicDefinition) &&
          <p>No definition element (No structured definition.)</p>}
      </div>
      <h3 id="handling">Handling</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.handling
          ?
          <p><b>Handling: </b>{fhirJson.handling}</p>
          :
          <p>No handling data.</p>
        }
        {(fhirJson.category && Array.isArray(fhirJson.category) && fhirJson.category.length > 0) &&
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Value</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {fhirJson.category.map((category, categoryIndex) => {
                return <Table.Row key={categoryIndex}>
                  <Table.Cell style={{ verticalAlign: "top", width: "25%" }}>
                    {(category.name && typeof category.name === "string") && category.name}
                  </Table.Cell>
                  <Table.Cell style={{ verticalAlign: "top" }}>
                    {category.valueCodeableConcept && <DisplayFromFHIR codeableConcept={category.valueCodeableConcept} />}
                    {category.valueQuantity && getStringFromFHIR.Quantity(category.valueQuantity)}
                    {category.valueRange && getStringFromFHIR.Range(category.valueRange)}
                    {category.valueReference && <DisplayFromFHIR reference={category.valueReference} />}
                  </Table.Cell>
                </Table.Row>
              })}
            </Table.Body>
          </Table>
        }
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div >
}

export default EvidenceVariableDisplay;