import scrollTo from './ScrollTo';
import { submitUpdatedFhirResource } from './ResourceFunctions';
import submitToFevirServer from './SubmitToFevirServer';
import { generateCitation } from './DataEntryFormFunctions';
import { jsonErrorCheck, convertBooleanToString, getValueSetCodesAsOptions, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';

const findConceptByCode = (json, code, path, indexPath) => {
  let conceptPath = [...path];
  let conceptIndexPath = [...indexPath];
  if (json.concept) {
    for (let conceptIndex in json.concept) {
      let concept = json.concept[conceptIndex];
      if (concept) {
        if (concept.code === code) {
          conceptPath.push(concept.display);
          conceptIndexPath.push(conceptIndex);
          return [concept, conceptPath, conceptIndexPath]
        } else if (concept.concept) {
          let conceptFound = findConceptByCode(concept, code, [...conceptPath, concept.display], [...conceptIndexPath, conceptIndex]);
          if (conceptFound?.length > 0) {
            return conceptFound
          }
        }
      }
    }
  }
}

const findRobatConceptByCode = (json, code, path, indexPath) => {
  let conceptPath = [...path];
  let conceptIndexPath = [...indexPath];
  let concepts;
  if (json.component) {
    concepts = json.component;
  } else if (json.content) {
    concepts = json.content;
  }
  if (concepts) {
    for (let conceptIndex in concepts) {
      let concept = concepts[conceptIndex];
      if (concept) {
        let jsonConceptCode;
        let jsonConceptDisplay;
        if (concept.type) {
          for (let codingIndex in concept.type.coding) {
            let coding = concept.type.coding[codingIndex];
            if (coding.code) {
              jsonConceptCode = coding.code;
              jsonConceptDisplay = coding.display;
              break;
            }
          }
        }
        if (jsonConceptCode === code) {
          conceptPath.push(jsonConceptDisplay);
          conceptIndexPath.push(conceptIndex);
          return [concept, conceptPath, conceptIndexPath]
        } else if (concept.component || concept.content) {
          let conceptFound = findRobatConceptByCode(concept, code, [...conceptPath, jsonConceptDisplay], [...conceptIndexPath, conceptIndex]);
          if (conceptFound?.length > 0) {
            return conceptFound
          }
        }
      }
    }
  }
}

const navigateToAnotherTerm = (scrollName, history, fhirEntryState, setFhirEntryState, resourceType, resourceId, json, concept, display, path, pathIndexes, pathString) => {
  if (fhirEntryState.conceptDelete) {
    alert("Please click the 'Update' button before navigating away. Or refresh browser to discard changes.")
  } else {
    //history.push(`/resources/${resourceType}/${resourceId}#${concept.code},${pathIndexes.toString()}`);    //INCLUDES PATH SO IT CAN HANDLE DUPLICATE CODES, COMMENTED OUT FOR NOW
    let navigateAway = true;
    if (fhirEntryState.termChanged || fhirEntryState.newChildConcepts?.length > 0) {
      if ((fhirEntryState.conceptCode && fhirEntryState.conceptName) || (fhirEntryState.conceptPath === undefined || fhirEntryState.conceptPath?.length === 0)) {
        let alertMessage = "You made changes to the term, would you like to save your changes?";
        if (fhirEntryState.conceptPath === undefined || fhirEntryState.conceptPath?.length === 0) {
          if (fhirEntryState.newChildConcepts.length === 1) {
            alertMessage = "You added a term, would you like to save your changes?";
          } else {
            alertMessage = "You added terms, would you like to save your changes?";
          }
        }
        if (window.confirm(alertMessage)) {
          //fhirJson = builderUpdateCodeSystemJson(resourceId, fhirEntryState, formInputsStateRef);
          navigateAway = false;
          setFhirEntryState(prevState => { return { ...prevState, submittingToServer: true, termToNavigateTo: concept.code } });
        } else {
          navigateAway = false;
        }
      } else {
        navigateAway = false;
        alert("Term is missing either a 'Code' or a 'Preferred term.'");
      }
    }
    if (navigateAway) {
      let conceptCode = "";
      let scrollToId = "";
      if (concept.code) {
        conceptCode = concept.code;
        scrollToId = scrollName.toLowerCase() + "detail-navigation-code-" + conceptCode;
      }
      if (conceptCode) {
        history.push(`/resources/${resourceType}/${resourceId}#${conceptCode}`);
      } else {
        history.push(`/resources/${resourceType}/${resourceId}`);
      }

      let propertyProperties = getPropertyCodes(json);
      let propertyCodes = propertyProperties["propertyCodes"];
      let propertyDescriptions = propertyProperties["propertyDescriptions"];

      let conceptProperties = findConceptProperties(concept, propertyCodes);
      let conceptOpenForVoting = false;
      let conceptPropertyComment = "";
      if (conceptProperties["open-for-voting"] && conceptProperties["open-for-voting"] !== "") {
        conceptOpenForVoting = true;
      }
      if (conceptProperties["comment"] && conceptProperties["comment"] !== "") {
        conceptPropertyComment = conceptProperties["comment"];
      }

      let classifierCodes = [];
      let notes = [];

      setFhirEntryState(prevState => { return { ...prevState, conceptName: display, conceptPath: path, conceptPathIndexes: pathIndexes, conceptPathString: pathString, conceptSelected: concept, conceptDefinition: concept.definition, conceptSummary: concept.summary, conceptCode: concept.code, classifierCodes: classifierCodes, notes: notes, alternativeTerms: getAlternativeTerms(concept), propertyCodes: propertyCodes, propertyDescriptions: propertyDescriptions, conceptProperties: conceptProperties, conceptOpenForVoting: conceptOpenForVoting, conceptPropertyComment: conceptPropertyComment, termChanged: false, newChildConcepts: [], conceptCommentsExpanded: false, comments: [], votes: [], myvotes: [] }; });

      scrollTo("navigation" + scrollName.toLowerCase() + "DetailSegment", scrollToId, true);
      scrollTo("resourceTextViewSegment", "", true);
    }
  };
}


const navigateToAnotherRobatTerm = async (scrollName, history, fhirEntryState, setFhirEntryState, resourceType, resourceId, json, concept, display, path, pathIndexes, pathString, globalContext, changeFormState, formInputsStateRef, changeFhirEntryState, addToast) => {
  //history.push(`/resources/${resourceType}/${resourceId}#${concept.code},${pathIndexes.toString()}`);    //INCLUDES PATH SO IT CAN HANDLE DUPLICATE CODES, COMMENTED OUT FOR NOW
  /*let navigateAway = true;
  if (fhirEntryState.termChanged || fhirEntryState.newChildConcepts?.length > 0) {
    //setFhirEntryState(prevState => { return {...prevState, submittingToServer: true, termToNavigateTo: concept.code}});
  }
  if (navigateAway) {    */
  //setFhirEntryState(prevState => { return {...prevState, submittingToServer: true, termToNavigateTo: concept.code}});
  let submitResult;
  let termChanged = false;

  //let editUrl = "";
  if (fhirEntryState.editMode) {
    //editUrl = "/edit";
    if (fhirEntryState.editMode && fhirEntryState.conceptPathIndexes?.length > 0) {
      submitResult = await submitUpdatedFhirResource(null, "ArtifactAssessment", resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
      if (submitResult) {
        termChanged = true;
        if (addToast) {
          addToast('The resource has been updated.', { appearance: 'success' });
        }
      }
    }
  }

  let conceptCode = "";
  let scrollToId = "";
  let jsonConceptCode;
  let jsonConceptSystem;
  let jsonConceptDisplay;
  if (concept.type) {
    for (let codingIndex in concept.type.coding) {
      let coding = concept.type.coding[codingIndex];
      if (coding.code) {
        if (concept.code === undefined) {
          jsonConceptCode = coding.code;
        }
        jsonConceptSystem = coding.system;
        jsonConceptDisplay = coding.display;
        break;
      }
    }
  }
  if (concept.code) {
    conceptCode = concept.code;
    scrollToId = scrollName.toLowerCase() + "detail-navigation-code-" + conceptCode;
  }
  if (conceptCode) {
    history.push(`/resources/${resourceType}/${resourceId}#${conceptCode}`);
  } else {
    history.push(`/resources/${resourceType}/${resourceId}`);
  }

  let propertyProperties = getPropertyCodes(json);
  let propertyCodes = propertyProperties["propertyCodes"];
  let propertyDescriptions = propertyProperties["propertyDescriptions"];

  let conceptProperties = findConceptProperties(concept, propertyCodes);
  let conceptOpenForVoting = false;
  let conceptPropertyComment = "";
  if (conceptProperties["open-for-voting"] && conceptProperties["open-for-voting"] !== "") {
    conceptOpenForVoting = true;
  }
  if (conceptProperties["comment"] && conceptProperties["comment"] !== "") {
    conceptPropertyComment = conceptProperties["comment"];
  }

  let classifierCodes = [];
  let notes = [];
  if (resourceType === "ArtifactAssessment") {
    for (let classifierIndex in concept.classifier) {
      let classifier = concept.classifier[classifierIndex];
      if (classifier.coding?.length > 0 && classifier.coding[0].code) {
        classifierCodes.push(classifier.coding[0].code);
      }
    }
    for (let componentIndex in concept.component) {
      let component = concept.component[componentIndex];
      if (component.informationType === "comment" && component.summary) {
        notes.push(component.summary);
      }
    }
  }
  setFhirEntryState(prevState => { return { ...prevState, submittingToServer: termChanged, conceptName: display, conceptPath: path, conceptPathIndexes: pathIndexes, conceptPathString: pathString, conceptSelected: concept, conceptDefinition: concept.definition, conceptSummary: concept.summary, conceptCode: concept.code, conceptDisplay: jsonConceptDisplay, conceptSystem: jsonConceptSystem, classifierCodes: classifierCodes, notes: notes, alternativeTerms: getAlternativeTerms(concept), propertyCodes: propertyCodes, propertyDescriptions: propertyDescriptions, conceptProperties: conceptProperties, conceptOpenForVoting: conceptOpenForVoting, conceptPropertyComment: conceptPropertyComment, termChanged: termChanged, newChildConcepts: [], conceptCommentsExpanded: false, comments: [], votes: [], myvotes: [] }; });

  scrollTo("navigation" + scrollName + "DetailSegment", scrollToId, true);
  scrollTo("resourceTextViewSegment", "", true);
  //}
}

const iterateThroughTermsToBeVotedOn = (parentConcept, myvotesdict, systemId, systemTitle, systemName, terms) => {
  for (let conceptIndex in parentConcept.concept) {
    let concept = parentConcept.concept[conceptIndex];
    let openForVoting;
    let commentsForApplication;
    for (let propertyIndex in concept.property) {
      let property = concept.property[propertyIndex];
      if (property.valueString) {
        if (property.code === "open-for-voting") {
          openForVoting = property.valueString;
        } else if (property.code === "comment") {
          commentsForApplication = property.valueString;
        }
      }
    }
    if (openForVoting) {
      let alternativeTerms = [];
      for (let designationIndex in concept.designation) {
        let designation = concept.designation[designationIndex];
        if ((designation.use?.display === "Alternative term" || designation.use?.display === "Synonym (core metadata concept)" ||
          designation.use?.code === "900000000000013009") && designation.value) {
          alternativeTerms.push(designation.value);
        }
      }
      let term = { "code": concept.code, "display": concept.display, "definition": concept.definition, "alternativeTerms": alternativeTerms, "commentsForApplication": commentsForApplication, "openForVotingString": openForVoting, "systemId": systemId, "systemTitle": systemTitle, "systemName": systemName, "myvotes": [], "latestValidVote": {}, "voteLabel": "", "voteComment": "" };

      term["myvotes"] = myvotesdict[concept.code];
      let latestValidVote = {};
      let latestValidVoteDate;
      if (term["myvotes"]?.length > 0) {
        latestValidVote = term["myvotes"][0];
        latestValidVoteDate = new Date(term["myvotes"][0]["datesubmitted"]);
      }
      let openForVotingDate = new Date(openForVoting);
      if (latestValidVoteDate >= openForVotingDate) {
        term["latestValidVote"] = latestValidVote;
        if (term["latestValidVote"]["vote"] !== undefined) {
          if (term["latestValidVote"]["vote"]) {
            term["voteLabel"] = "Yes";
          } else {
            term["voteLabel"] = "No";
          }
        }
        if (term["latestValidVote"].summary) {
          term["voteComment"] = term["latestValidVote"].summary;
        }
      }


      terms.push(term);
    }
    if (concept.concept) {
      terms = iterateThroughTermsToBeVotedOn(concept, myvotesdict, systemId, systemTitle, systemName, terms);
    }
  }
  return terms;
}

const getTermsToBeVotedOn = (json, myvotesdict) => {
  let terms = [];
  let systemId = json.id;
  let systemTitle = json.title;
  let systemName = json.name;
  terms = iterateThroughTermsToBeVotedOn(json, myvotesdict, systemId, systemTitle, systemName, terms);
  return terms;
}

const codeSystemTextViewCalculations = (fhirEntryState, changeFhirEntryState, setCodeSystemState, codeSystemBlankState) => {
  let json = JSON.parse(fhirEntryState.fhirEntryString);
  setCodeSystemState(prevState => {
    return {
      ...prevState, description: json.description || "",
      publisher: json.publisher || "", contact: json.contact || "",
      version: json.version || "", versionAlgorithmString: json.versionAlgorithmString || "", versionAlgorithmCoding: json.versionAlgorithmCoding || "",
      name: json.name || "",
      title: json.title || "",
      url: json.url || "", identifier: json.identifier || "",
      status: json.status || "",
      experimental: (json.experimental === true || json.experimental === false) ? json.experimental : "",
      date: json.date || "",
      useContext: json.useContext || "", jurisdiction: json.jurisdiction || "", purpose: json.purpose || "",
      usage: json.usage || "",
      approvalDate: json.approvalDate || "", lastReviewDate: json.lastReviewDate || "",
      effectivePeriod: json.effectivePeriod || "", topic: json.topic || "",
      copyright: json.copyright || "", copyrightLabel: json.copyrightLabel || "",
      author: json.author || "", editor: json.editor || "",
      reivewer: json.reviewer || "",
      endorser: json.endorser || "",
      relatedArtifact: json.relatedArtifact || "",
      caseSensitive: convertBooleanToString(json.caseSensitive),
      versionNeeded: convertBooleanToString(json.versionNeeded),
      compositional: convertBooleanToString(json.compositional),
      hierarchyMeaning: json.hierarchyMeaning,
      content: json.content || "complete"
    }
  });
}


const getAlternativeTerms = (concept) => {

  let alternativeTerms = [];

  if (concept) {
    for (let designationIndex in concept.designation) {
      let designation = concept.designation[designationIndex];
      if (designation.value) {
        if (designation.use) {
          if (designation.use.display === "Alternative term" || designation.use.display === "Synonym (core metadata concept)" ||
            designation.use.code === "900000000000013009") {
            alternativeTerms.push(designation.value);
          } else {

          }
        } else {

        }
      }
    }
  }
  return alternativeTerms
}


const getPropertyCodes = (json) => {
  let propertyCodes = [];
  let propertyDescriptions = {};
  for (let propertyIndex in json.property) {
    let property = json.property[propertyIndex];
    let propertyCode = property.code;
    if (propertyCode && !propertyCodes.includes(propertyCode)) {
      propertyCodes.push(propertyCode);
      propertyDescriptions[propertyCode] = property.description;
    }
  }
  return { propertyCodes: propertyCodes, propertyDescriptions: propertyDescriptions }
}

const findConceptProperties = (concept, propertyCodes) => {
  let properties = {};

  for (let propertyIndex in concept.property) {
    let property = concept.property[propertyIndex];
    let valueString = property.valueString;

    if (valueString) {
      if (propertyCodes.includes(property.code)) {
        properties[property.code] = valueString
      } else {
        alert("A property exists in this term that's not defined at the CodeSystem level. This data will be deleted if saved.");
      }
    }
  }
  for (let codeIndex in propertyCodes) {
    let code = propertyCodes[codeIndex];
    if (properties[code] === undefined) {
      properties[code] = "";
    }
  }

  return properties
}

const checkConceptCodes = (json, conceptArray, duplicateCodesFound, termsWithMultipleParents) => {
  let codeCompliant = true;
  for (let conceptIndex in json.concept) {
    let concept = json.concept[conceptIndex];
    if (concept.code) {
      if (!termsWithMultipleParents.includes(concept.code)) {
        for (let propertyIndex in concept.property) {
          let property = concept.property[propertyIndex];
          if (property.code === "multiple-parents") {
            termsWithMultipleParents.push(concept.code);
            break;
          }
        }
      }
      if (!conceptArray.includes(concept.code)) {
        conceptArray.push(concept.code);
        //duplicateCodesFound = checkConceptCodes(concept, conceptArray, duplicateCodesFound);
        //if (codeCompliant === false) {
        //return false;
        //return duplicateCodesFound;
        //}
      } else {
        //alert("At least two terms have identical codes. CODE: " + concept.code);
        if (!duplicateCodesFound.includes(concept.code)) {
          if (!termsWithMultipleParents.includes(concept.code)) {
            duplicateCodesFound.push(concept.code);
          }
        }
        codeCompliant = false;
        //return false;
        //return duplicateCodesFound;
      }
      duplicateCodesFound = checkConceptCodes(concept, conceptArray, duplicateCodesFound, termsWithMultipleParents);
    } else {
      codeCompliant = false;
      alert("A term is missing a code.");
      duplicateCodesFound = checkConceptCodes(concept, conceptArray, duplicateCodesFound, termsWithMultipleParents);
      //return false;
      //return duplicateCodesFound;
    }
  }
  //return codeCompliant
  return duplicateCodesFound;
}

const submitCodeSystemComment = async (globalContext, informationType, firebase, resourceId, resourceTitle, conceptCode, conceptName, conceptDefinition, concept, title, commentSummary, termVote, giveAlerts, setCommentEntryState, votes) => {
  if (!votes) {
    if (informationType === "comment") {
      if (title === null || title === undefined || title === "") {
        alert("Comment doesn't have a title");
        return { "success": false };
        //return ;
      }

      if (commentSummary === null || commentSummary === undefined || commentSummary === "") {
        commentSummary = prompt("Comment doesn't have content. Please enter here: ");
        if (commentSummary === "") {
          let response = await submitCodeSystemComment(globalContext, "comment", firebase, resourceId, resourceTitle, conceptCode, conceptName, conceptDefinition, concept, title, commentSummary, termVote, giveAlerts, setCommentEntryState, null);
          if (response.success) {
            return { "success": true };
          } else {
            return { "success": false };
          }
        } else if (commentSummary === null) {
          alert("No summary enetered, not submitting comment.");
          return { "success": false };
        }
      }

      let idToken;
      try {
        if (globalContext.userState?.idToken) {
          idToken = globalContext.userState.idToken;
        } else {
          let user = firebase.auth().currentUser;
          await user.getIdToken(true).then(async (storedIdToken) => { idToken = storedIdToken });
        }
      } catch (e) { }

      if (idToken === undefined) {
        alert(`${informationType} NOT submitted. Please try again or save ${informationType} in another way: ` + commentSummary);
      } else {
        let path = '';
        if (conceptCode) {
          path = `#${conceptCode}`;
        }
        let jsonInformationType = informationType;
        let profileUrl = "http://hl7.org/fhir/uv/ebm/StructureDefinition/comment";
        let commentJson = {
          "resourceType": "ArtifactAssessment",
          "meta": { "profile": [profileUrl] },
          "artifactReference": { "reference": "CodeSystem/" + resourceId, "type": "CodeSystem", "display": resourceTitle },
          "title": title,
          "content": [{
            "informationType": jsonInformationType,
            "summary": commentSummary,
            "author": { "display": globalContext.userState.name },
            "path": [path]
          }]
        };
        if (commentSummary === undefined || commentSummary === "") {
          delete commentJson.content.summary;
        }


        const body = {
          'functionid': 'submitrtifactassessmentcomment',
          'idToken': '',
          'informationtype': informationType,
          'aboutformstateid': resourceId,
          'path': path,
          'fhirEntry': JSON.stringify(commentJson, null, 2),
          'title': title
        };
        let response;
        try {
          response = await submitToFevirServer(globalContext, 10000, body, true, false);
        } catch (e) { }
        if (response === undefined || response === false || response.success === false) {
          alert("Comment NOT submitted. Please try again or save comment in another way: " + commentSummary);
          return { "success": false };
        } else {
          if (giveAlerts) {
            alert(`${informationType} submitted.`);
          }
          if (setCommentEntryState) {
            setCommentEntryState(prevState => { return { ...prevState, "commentSummary": "" } });
          }
          return { "success": true };
        }
      }
      //history.push(`/resources/${resourceType}/${resourceId}#${conceptCode}`);
      return;
    } else {

      let termVoteBoolean;
      if (termVote) {
        termVoteBoolean = termVote.toLowerCase() !== "no"
      }

      let termCopy = JSON.parse(JSON.stringify(concept));
      delete termCopy.comments;
      delete termCopy.votes;
      delete termCopy.myvotes;
      delete termCopy.latestValidVote;
      delete termCopy.systemId;
      delete termCopy.systemTitle;
      delete termCopy.systemName;
      delete termCopy.voteLabel;
      delete termCopy.voteComment;
      delete termCopy.changeMade;
      votes = [{
        'codesystem': resourceId,
        'termcode': conceptCode,
        'termconcept': termCopy,
        'vote': termVoteBoolean,  //How the user voted true/false or yes/no
        'votechoice': termVote,   //For something other than true/false or yes/no, but I'll record the yes/no anyways.
        'votecomment': commentSummary
      }];
    }
  }
  const body = {
    'functionid': 'submitvote',
    'idToken': '',
    'votes': votes
  };
  let response;
  try {
    response = await submitToFevirServer(globalContext, 10000, body, true, false);
  } catch (e) { }
  if (response === undefined || response === false || response.success === false) {
    if (votes || informationType === "vote") {
      alert("Vote NOT submitted. Please try again. " + JSON.stringify(votes));
    } else {
      alert("Comment NOT submitted. Please try again or save comment in another way: " + commentSummary);
    }
    return { "success": false };
  } else {
    if (giveAlerts) {
      alert(`${informationType} submitted.`);
    }
    if (setCommentEntryState) {
      setCommentEntryState(prevState => { return { ...prevState, "commentSummary": "" } });
    }
    return { "success": true };
  }

}

const addingNewChildConcept = (concept, newChildConcepts) => {
  for (let newChildConceptIndex in newChildConcepts) {
    let newChildConcept = newChildConcepts[newChildConceptIndex];
    if (newChildConcept.code && newChildConcept.display) {
      let newChildConceptJson = { "code": newChildConcept.code, "display": newChildConcept.display };
      if (newChildConcept.definition) {
        newChildConceptJson["definition"] = newChildConcept.definition;
      }
      if (concept.concept === undefined) {
        concept.concept = [];
      }
      concept.concept.push(newChildConceptJson);
    } else {
      alert("All child concepts are required to have a code and a display.");
      return false;
    }
  }
  return concept;
}

const codesystemTextViewChangesToJson = (fhirJson, fhirEntryState, codeSystemState) => {
  if (fhirJson === undefined) {
    return;
  }
  fhirJson.content = codeSystemState.content;

  if (fhirJson.url || codeSystemState.url) {
    fhirJson.url = codeSystemState.url;
  }
  if (fhirJson.identifier || codeSystemState.identifier) {
    fhirJson.identifier = codeSystemState.identifier;
  }
  if (fhirJson.version || codeSystemState.version) {
    fhirJson.version = codeSystemState.version;
  }
  if (fhirJson.versionAlgorithmString || codeSystemState.versionAlgorithmString) {
    fhirJson.versionAlgorithmString = codeSystemState.versionAlgorithmString;
  }
  if (fhirJson.versionAlgorithmCoding || codeSystemState.versionAlgorithmCoding) {
    fhirJson.versionAlgorithmCoding = codeSystemState.versionAlgorithmCoding;
  }
  if (fhirJson.name || codeSystemState.name) {
    fhirJson.name = codeSystemState.name;
  }
  if (fhirJson.title || codeSystemState.title) {
    fhirJson.title = codeSystemState.title;
  }
  if (fhirJson.status || codeSystemState.status) {
    fhirJson.status = codeSystemState.status;
  }
  if (fhirJson.experimental !== undefined || typeof codeSystemState.experimental === "boolean") {
    if (codeSystemState.experimental === true) {
      fhirJson.experimental = true;
    } else if (codeSystemState.experimental === false) {
      fhirJson.experimental = false;
    } else {
      delete fhirJson.experimental;
    }
  }
  if (fhirJson.date || codeSystemState.date) {
    fhirJson.date = codeSystemState.date;
  }
  if (fhirJson.publisher || codeSystemState.publisher) {
    fhirJson.publisher = codeSystemState.publisher;
  }
  if (fhirJson.contact || codeSystemState.contact) {
    fhirJson.contact = codeSystemState.contact;
  }
  if (fhirJson.description || codeSystemState.description) {
    fhirJson.description = codeSystemState.description;
  }
  if (fhirJson.useContext || codeSystemState.useContext) {
    fhirJson.useContext = codeSystemState.useContext;
  }
  if (fhirJson.jurisdiction || codeSystemState.jurisdiction) {
    fhirJson.jurisdiction = codeSystemState.jurisdiction;
  }
  if (fhirJson.purpose || codeSystemState.purpose) {
    fhirJson.purpose = codeSystemState.purpose;
  }
  if (fhirJson.usage || codeSystemState.usage) {
    fhirJson.usage = codeSystemState.usage;
  }
  if (fhirJson.copyright || codeSystemState.copyright) {
    fhirJson.copyright = codeSystemState.copyright;
  }
  if (fhirJson.copyrightLabel || codeSystemState.copyrightLabel) {
    fhirJson.copyrightLabel = codeSystemState.copyrightLabel;
  }
  if (fhirJson.approvalDate || codeSystemState.approvalDate) {
    fhirJson.approvalDate = codeSystemState.approvalDate;
  }
  if (fhirJson.lastReviewDate || codeSystemState.lastReviewDate) {
    fhirJson.lastReviewDate = codeSystemState.lastReviewDate;
  }
  if (fhirJson.effectivePeriod || codeSystemState.effectivePeriod) {
    fhirJson.effectivePeriod = codeSystemState.effectivePeriod;
  }
  if (fhirJson.topic || codeSystemState.topic) {
    fhirJson.topic = codeSystemState.topic;
  }
  if (fhirJson.author || codeSystemState.author) {
    fhirJson.author = codeSystemState.author;
  }
  if (fhirJson.editor || codeSystemState.editor) {
    fhirJson.editor = codeSystemState.editor;
  }
  if (fhirJson.reviewer || codeSystemState.reviewer) {
    fhirJson.reviewer = codeSystemState.reviewer;
  }
  if (fhirJson.endorser || codeSystemState.endorser) {
    fhirJson.endorser = codeSystemState.endorser;
  }
  if (fhirJson.relatedArtifact || codeSystemState.relatedArtifact) {
    fhirJson.relatedArtifact = codeSystemState.relatedArtifact;
  }
  if (fhirJson.hierarchyMeaning || codeSystemState.hierarchyMeaning) {
    fhirJson.hierarchyMeaning = codeSystemState.hierarchyMeaning;
  }
  if (fhirJson.caseSensitive !== undefined || codeSystemState.caseSensitive) {
    if (codeSystemState.caseSensitive === "True") {
      fhirJson.caseSensitive = true;
    } else if (codeSystemState.caseSensitive === "False") {
      fhirJson.caseSensitive = false;
    } else {
      delete fhirJson.caseSensitive;
    }
  }
  if (fhirJson.versionNeeded !== undefined || codeSystemState.versionNeeded) {
    if (codeSystemState.versionNeeded === "True") {
      fhirJson.versionNeeded = true;
    } else if (codeSystemState.versionNeeded === "False") {
      fhirJson.versionNeeded = false;
    } else {
      delete fhirJson.versionNeeded;
    }
  }
  if (fhirJson.compositional !== undefined || codeSystemState.compositional) {
    if (codeSystemState.compositional === "True") {
      fhirJson.compositional = true;
    } else if (codeSystemState.compositional === "False") {
      fhirJson.compositional = false;
    } else {
      delete fhirJson.compositional;
    }
  }

  deleteEmptyElementsInObjectRecursive(fhirJson);
  let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
  fhirJson = fhirJsonWithCiteAs;
  return fhirJson;
}

const codesystemTermDetailChangesToJson = (fhirJson, fhirEntryState) => {
  if (fhirJson === undefined) {
    return;
  }

  if (fhirEntryState.conceptPath?.length > 0) { //Checks to see if a term is currently selected in the fhirEntryState
    if (fhirEntryState.conceptCode) {
      if (fhirEntryState.conceptName) {
        let concept = fhirJson;
        let parentConcept = fhirJson;
        let parentConceptArray = fhirJson.concept;
        for (let indexIndex in fhirEntryState.conceptPathIndexes) {
          let index = fhirEntryState.conceptPathIndexes[indexIndex];
          if (fhirEntryState.conceptPathIndexes.length - 1 === parseInt(indexIndex)) {
            parentConceptArray = concept["concept"];
            parentConcept = concept;
          }
          concept = concept["concept"][index];
        }
        if (fhirEntryState.conceptDelete === true) {
          parentConceptArray.splice(fhirEntryState.conceptPathIndexes[fhirEntryState.conceptPathIndexes.length - 1], 1);
          if (parentConceptArray.length === 0) {
            delete parentConcept["concept"];
          }

        } else {
          concept.code = fhirEntryState.conceptCode;
          concept.display = fhirEntryState.conceptName;
          concept.definition = fhirEntryState.conceptDefinition;
          concept.property = [];

          for (let propertyCode in fhirEntryState.conceptProperties) {
            let valueString = fhirEntryState.conceptProperties[propertyCode];
            if (valueString) {
              concept.property.push({ "code": propertyCode, "valueString": valueString });
            }
          }
          if (concept.property.length === 0) {
            delete concept.property;
          }
          let newDesignations = [];
          if (concept.designation !== undefined) {
            //for (let designationIndex = concept.designation.length - 1; designationIndex >= 0; designationIndex++) { //For reverse order
            for (let designationIndex in concept.designation) {
              let designation = concept.designation[designationIndex];
              //Goes through all the designations, and adds the designation IF it's not an Alternative term
              if (!(designation.use?.display === "Alternative term" || designation.use?.display === "Synonym (core metadata concept)" ||
                designation.use?.code === "900000000000013009")) {
                newDesignations.push(designation);
              }
            }
          }
          //Adds the Alternative term from the form
          for (let alternativeTermsIndex in fhirEntryState.alternativeTerms) {
            let alternativeTerm = fhirEntryState.alternativeTerms[alternativeTermsIndex];
            newDesignations.push({ "use": { "display": "Alternative term" }, "value": alternativeTerm });
          }
          concept.designation = newDesignations;
          if (concept.designation.length === 0) {
            delete concept.designation;
          }
          if (fhirEntryState.newChildConcepts.length > 0 && concept.concept === undefined) {
            concept.concept = [];
          }
          concept = addingNewChildConcept(concept, fhirEntryState.newChildConcepts);
          if (concept === false) {
            return false;
          }
        }
      } else {
        alert("The 'Preferred term' field can't be blank.");
        return false;
      }
    } else {
      alert("The 'Code' field cannot be blank.");
      return false;
    }
  } else {
    fhirJson = addingNewChildConcept(fhirJson, fhirEntryState.newChildConcepts);
    if (fhirJson === false) {
      return false;
    }
  }
  let duplicateConceptCodes = checkConceptCodes(fhirJson, [], [], []);
  if (duplicateConceptCodes.length !== 0) {
    alert("At least two terms have identical codes. Proceeding with resource update.\n\nDuplicate CODES: " + duplicateConceptCodes.join(", "));
    //return false
  }
  return fhirJson;
}

const builderUpdateCodeSystemJson = (resourceId, fhirEntryState, formInputsStateRef) => {

  let fhirJson = jsonErrorCheck("CodeSystem", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString, fhirEntryState.fhirJson, "");
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }

  if (fhirEntryState.activeIndex == 0) {
    let codeSystemState = formInputsStateRef.current.codeSystemState;
    fhirJson = codesystemTextViewChangesToJson(fhirJson, fhirEntryState, codeSystemState);
  } else if (fhirEntryState.activeIndex === 1) {
    fhirJson = codesystemTermDetailChangesToJson(fhirJson, fhirEntryState);
  }
  return fhirJson;
}

const robatTermDetailChangesToJson = async (fhirJson, fhirEntryState, formInputsStateRef, globalContext) => {

  if (fhirJson === undefined) {
    return false;
  }
  if (fhirEntryState.conceptPathIndexes?.length > 0) {
    let originalFhirJson = JSON.stringify(fhirJson, null, 2);
    let conceptPathIndexes = fhirEntryState.conceptPathIndexes;
    let component = fhirJson;
    let parentComponent = fhirJson;
    let parentConceptArray = fhirJson.content;
    for (let indexIndex in conceptPathIndexes) {
      indexIndex = parseInt(indexIndex);
      let index = conceptPathIndexes[indexIndex];
      if (indexIndex !== 0 && conceptPathIndexes.length - 1 === indexIndex) {
        parentConceptArray = component["component"];
        parentComponent = component;
      }
      if (component["content"]) {
        component = component["content"][index];
      } else {
        component = component["component"][index];
      }
    }

    let jsonConceptCode;
    if (component.code) {
      jsonConceptCode = component.code;
    } else if (component.type) {
      for (let codingIndex in component.type.coding) {
        let coding = component.type.coding[codingIndex];
        if (coding.code && coding.display) {
          jsonConceptCode = coding.code;
          break;
        }
      }
    }

    let codeMatch = true;
    if (fhirEntryState.conceptCode === undefined || fhirEntryState.conceptCode !== jsonConceptCode) {
      codeMatch = false;
      return false;
    }

    //console.log("+++___---");
    //console.log(conceptPathIndexes);
    //console.log(formInputsStateRef.current);
    //console.log(component);
    let factorPresenceSelectedState;
    let biasDirectionSelectedState;
    let potentialInfluenceSelectedState;
    let biasRiskSelectedState;
    let explainRationaleState;
    let notesState;
    let factorPresenceLookup;
    let biasDirectionLookup;
    let potentialInfluenceLookup;
    let biasRiskLookup;

    if (formInputsStateRef?.current) {
      factorPresenceSelectedState = formInputsStateRef.current.factorPresenceSelectedState;
      biasDirectionSelectedState = formInputsStateRef.current.biasDirectionSelectedState;
      potentialInfluenceSelectedState = formInputsStateRef.current.potentialInfluenceSelectedState;
      biasRiskSelectedState = formInputsStateRef.current.biasRiskSelectedState;
      explainRationaleState = formInputsStateRef.current.explainRationaleState;
      notesState = formInputsStateRef.current.notesState;
      factorPresenceLookup = formInputsStateRef.current.factorPresenceLookup;
      biasDirectionLookup = formInputsStateRef.current.biasDirectionLookup;
      potentialInfluenceLookup = formInputsStateRef.current.potentialInfluenceLookup;
      biasRiskLookup = formInputsStateRef.current.biasRiskLookup;
    }

    component.summary = explainRationaleState;

    if (component.component === undefined) {
      component.component = [];
    }
    let newComponentList = [];
    for (let componentIndex in component.component) {
      let subComponent = component.component[componentIndex];
      if (subComponent.informationType !== "comment") {
        newComponentList.push(subComponent);
      }
    }
    component.component = newComponentList;
    for (let noteIndex in notesState) {
      let note = notesState[noteIndex];
      if (note) {
        let noteComponent = {
          informationType: "comment",
          summary: note
        };
        component.component.push(noteComponent);
      }
    }
    component.classifier = [];
    if (factorPresenceSelectedState) {
      if (factorPresenceLookup === undefined || Object.keys(factorPresenceLookup).length === 0) {
        let factorPresence = await getValueSetCodesAsOptions('29654', globalContext);
        factorPresenceLookup = factorPresence[1];
      }
      let factorPresence = factorPresenceLookup[factorPresenceSelectedState];
      component.classifier.push({ coding: [{ system: factorPresence.system, code: factorPresence.code, display: factorPresence.display }] });
    }

    if (potentialInfluenceSelectedState) {
      if (potentialInfluenceLookup === undefined || Object.keys(potentialInfluenceLookup).length === 0) {
        let potentialInfluence = await getValueSetCodesAsOptions('29656', globalContext);
        potentialInfluenceLookup = potentialInfluence[1];
      }
      let potentialInfluence = potentialInfluenceLookup[potentialInfluenceSelectedState];
      component.classifier.push({ coding: [{ system: potentialInfluence.system, code: potentialInfluence.code, display: potentialInfluence.display }] });
    }

    if (biasDirectionSelectedState) {
      if (biasDirectionLookup === undefined || Object.keys(biasDirectionLookup).length === 0) {
        let biasDirection = await getValueSetCodesAsOptions('29655', globalContext);
        biasDirectionLookup = biasDirection[1];
      }
      let biasDirection = biasDirectionLookup[biasDirectionSelectedState];
      component.classifier.push({ coding: [{ system: biasDirection.system, code: biasDirection.code, display: biasDirection.display }] });
    }

    if (biasRiskSelectedState) {
      if (biasRiskLookup === undefined || Object.keys(biasRiskLookup).length === 0) {
        let biasRisk = await getValueSetCodesAsOptions('29653', globalContext);
        biasRiskLookup = biasRisk[1];
      }
      let biasRisk = biasRiskLookup[biasRiskSelectedState];
      component.classifier.push({ coding: [{ system: biasRisk.system, code: biasRisk.code, display: biasRisk.display }] });
    }
    if (JSON.stringify(fhirJson, null, 2) !== originalFhirJson) {
      /*console.log("INTERESTING");
      console.log(originalFhirJson);
      console.log(fhirJson);*/
      return fhirJson;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const builderUpdateRobatJson = async (resourceId, fhirEntryState, formInputsStateRef, globalContext) => {
  if (fhirEntryState.loading) {
    return false;
  } else {
    let fhirJson = jsonErrorCheck("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString, fhirEntryState.fhirJson, "");
    if (fhirJson === undefined || fhirJson === false) {
      return false;
    }

    if (fhirEntryState.activeIndex == 0) {
      let codeSystemState = formInputsStateRef.current.codeSystemState;
      //fhirJson = fhirJson;
      fhirJson = codesystemTextViewChangesToJson(fhirJson, fhirEntryState, codeSystemState);
    } else if (fhirEntryState.activeIndex === 1) {
      fhirJson = await robatTermDetailChangesToJson(fhirJson, fhirEntryState, formInputsStateRef, globalContext);
    }
    return fhirJson;
  }
}

export { findConceptByCode, findRobatConceptByCode, submitCodeSystemComment, navigateToAnotherTerm, navigateToAnotherRobatTerm, getTermsToBeVotedOn, codeSystemTextViewCalculations, getAlternativeTerms, getPropertyCodes, findConceptProperties, builderUpdateCodeSystemJson, builderUpdateRobatJson };