import React, { useState, useEffect, memo } from 'react';
import { RadioGroup, Radio, FormControlLabel, TextField } from '@mui/material';
import { Button, Table, Modal } from 'semantic-ui-react';
import submitToFevirServer from './SubmitToFevirServer';
import { addFoisFromFlis } from './SummaryOfFindingsFunctions';

const handleChange = (name, targetfoi, value, setResourceState, setChangeAvailableToSaveState) => {
    setResourceState(prevState => {
        let ratingDictionary = JSON.parse(JSON.stringify(prevState.ratingDictionary));
        ratingDictionary[targetfoi][name] = value;
        ratingDictionary[targetfoi]["itemChanged"] = true;
        return {
            ...prevState,
            "ratingDictionary": ratingDictionary
        };
    });
    setChangeAvailableToSaveState(true);
}

const updateResourceWithImportanceRatings = (resource, desirabilityCode, desirabilityDisplay, quantityValue) => {
    resource.content = resource.content.map(content => {
        if (content.type?.coding?.length > 0 && content.type.coding[0].code) {
            if (content.type.coding[0].code === "desirability") {
                content.classifier = [
                    {
                        "coding": [
                            {
                                "system": "https://fevir.net/resources/CodeSystem/112689",
                                "code": desirabilityCode,
                                "display": desirabilityDisplay
                            }
                        ]
                    }
                ]
            }
            if (content.type.coding[0].code === "relative-importance") {
                content.quantity = {
                    "value": quantityValue,
                    "unit": "%",
                    "system": "http://unitsofmeasure.org",
                    "code": "%"
                }
            }
        }
        return content;
    })
    return resource;
}

const DesirabilityEntry = memo(({ targetfoi, startingValue,
    setResourceState, setChangeAvailableToSaveState }) => {

    return <div>
        {/*
      TO DO THIS DOESN'T WORK YET, FOR FUTURE
      <YesNoRadioCheckBoxSwitch elementName={"desirabilityCode"} labelElementName={"desirabilityDisplay"} thin={true} togglable={true} boxes={true} alternativeValues={["desirable", "undesirable"]} alternativeLabels={["Desirable", "Undesirable"]} parentElement={targetfoi} startingValue={startingValue || ""} setter={setResourceState} setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
      */}
        <RadioGroup row aria-label="desirability" name="desirability"
            value={startingValue}
            onChange={(e) => {
                handleChange("desirabilityCode", targetfoi, e.target.value.toLowerCase(),
                    setResourceState, setChangeAvailableToSaveState);
                handleChange("desirabilityDisplay", targetfoi, e.target.value,
                    setResourceState, setChangeAvailableToSaveState);
            }}>
            <FormControlLabel
                value="Desirable"
                control={<Radio color="primary" />}
                name="radio-button-control"
                color="default"
                inputprops={{ 'aria-label': 'Desirable' }}
                labelPlacement="top"
                label={<p style={{ textAlign: 'center' }}>Desirable</p>}
            />
            <FormControlLabel
                value="Undesirable"
                control={<Radio color="primary" />}
                color="default"
                name="radio-button-control"
                inputprops={{ 'aria-label': 'Undesirable' }}
                labelPlacement="top"
                label={<p style={{ textAlign: 'center' }}>Undesirable</p>}
            />
        </RadioGroup>
    </div>
})

const QuantityValueEntry = memo(({ elementName, fieldLabel, targetfoi, startingValue,
    setResourceState, setChangeAvailableToSaveState }) => {

    return <div>
        <TextField style={{ width: "100%", marginTop: "16px", maxWidth: "150px" }}
            className="inputField" type='number' label={fieldLabel} size="small" variant='outlined'
            value={startingValue}
            onChange={(e) => {
                handleChange(elementName, targetfoi, parseFloat(e.target.value), setResourceState,
                    setChangeAvailableToSaveState)
            }} />
    </div>
})

const RateRelativeImportanceTable = ({ resourceState, setResourceState,
    setChangeAvailableToSaveState, globalContext }) => {

    const [ready, setReady] = useState(false);

    const addFoisFromFlisInTargetResources = async () => {
        await addFoisFromFlis(globalContext, resourceState.targetResources);
        setReady(true);
    }

    useEffect(() => {
        addFoisFromFlisInTargetResources();
    }, []);

    let ratingDictionary = {};
    if (resourceState.ratingDictionary) {
        ratingDictionary = JSON.parse(JSON.stringify(resourceState.ratingDictionary))
    }

    return <>{ready && <Table className='viewmyworktable'>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <span>Outcome</span>
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <span>Desirability</span>
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <span>Relative Importance (as %)</span>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {(resourceState?.targetResources?.length > 0 && Object.keys(ratingDictionary).length > 0) &&
                resourceState.targetResources.map((item, itemIndex) => {
                    return <Table.Row key={itemIndex}>
                        <Table.Cell>
                            <a href={"/resources/EvidenceVariable/" + item.id.toString()}
                                target="_blank" rel="noopener noreferrer" >
                                {item.titleTrimmed || item.title}
                            </a>
                        </Table.Cell>
                        <Table.Cell>
                            <DesirabilityEntry
                                targetfoi={item.id}
                                setResourceState={setResourceState}
                                startingValue={ratingDictionary[item.id]?.desirabilityDisplay || ""}
                                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                        </Table.Cell>
                        <Table.Cell>
                            <QuantityValueEntry elementName='quantityValue'
                                targetfoi={item.id}
                                setResourceState={setResourceState}
                                fieldLabel={'Relative Importance (as %)'}
                                startingValue={ratingDictionary[item.id]?.quantityValue?.toString() || "0"}
                                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                        </Table.Cell>
                    </Table.Row>
                })}
        </Table.Body>
    </Table>}</>
}

const RateRelativeImportanceModal = memo(({ globalContext, rateRelativeImportanceModalState,
    setRateRelativeImportanceModalState, resourceState, setResourceState, setNeedToRefreshState }) => {

    const [saveChangesState, setSaveChangesState] = useState(false);
    const [changeAvailableToSaveState, setChangeAvailableToSaveState] = useState(false);

    const saveChanges = async () => {
        if (saveChangesState && resourceState.ratingDictionary) {
            let ratingDictionary = JSON.parse(JSON.stringify(resourceState.ratingDictionary));
            for (const key in ratingDictionary) {
                const item = ratingDictionary[key];
                if (item.itemChanged) {
                    if (item.exists) {
                        let updatedResource = updateResourceWithImportanceRatings(item.resource, item.desirabilityCode, item.desirabilityDisplay, item.quantityValue);
                        let updatedRatingBody = {
                            'functionid': 'updatefhirresource',
                            'idToken': "",
                            'resourceid': item.resourceFOI,
                            'resourcetype': "ArtifactAssessment",
                            'fhirEntry': JSON.stringify(updatedResource),
                            'status': "active",
                            'associatedProjectFoi': null,
                            'aboutformstateid': key,
                            'tool': 'SummaryOfFindingsAuthoringTool',
                            'title': updatedResource.title
                        }
                        let response = await submitToFevirServer(globalContext, 5000, updatedRatingBody, false, false);
                        if (response?.success) { } else {
                            alert("Problem updating Rating for FOI " + key);
                        }
                        ratingDictionary[key]["exists"] = true;
                        ratingDictionary[key]["itemChanged"] = false;
                    }
                    if (!item.exists) {
                        let newRating = {
                            "resourceType": "ArtifactAssessment",
                            "meta": {
                                "profile": [
                                    "http://hl7.org/fhir/uv/ebm/StructureDefinition/rating",
                                    "http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-importance"
                                ]
                            },
                            "extension": [
                                {
                                    "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
                                    "valueCode": "active"
                                },
                                {
                                    "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
                                    "valueContactDetail": {
                                        "name": globalContext.userState.name
                                    }
                                }
                            ],
                            "title": "Outcome Importance Rating of " + item.targettitleTrimmed + " by " + globalContext.userState.name,
                            "artifactReference": {
                                "reference": "EvidenceVariable/" + key,
                                "type": "EvidenceVariable",
                                "display": item.targettitle
                            },
                            "content": [
                                {
                                    "informationType": "classification",
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112689",
                                                "code": "desirability",
                                                "display": "Desirability"
                                            }
                                        ]
                                    },
                                    "classifier": [
                                        {
                                            "coding": [
                                                {
                                                    "system": "https://fevir.net/resources/CodeSystem/112689",
                                                    "code": item.desirabilityCode,
                                                    "display": item.desirabilityDisplay
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "informationType": "rating",
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112689",
                                                "code": "relative-importance",
                                                "display": "Relative Importance"
                                            }
                                        ]
                                    },
                                    "quantity": {
                                        "value": item.quantityValue,
                                        "unit": "%",
                                        "system": "http://unitsofmeasure.org",
                                        "code": "%"
                                    }
                                }
                            ]
                        };
                        let newRatingBody = {
                            'functionid': 'submitfhirresource',
                            'idToken': "",
                            'fhirEntry': JSON.stringify(newRating),
                            'status': "active",
                            'associatedProjectFoi': null,
                            'aboutformstateid': key,
                            'tool': 'SummaryOfFindingsAuthoringTool',
                            'title': newRating.title
                        }
                        let response = await submitToFevirServer(globalContext, 5000, newRatingBody, false, false);
                        if (response?.success) { } else {
                            alert("Problem submitting Rating for FOI " + key);
                        }
                        ratingDictionary[key]["exists"] = true;
                        ratingDictionary[key]["resourceFOI"] = response.formstateid;
                        ratingDictionary[key]["itemChanged"] = false;
                    }
                }
            }
            setResourceState(prevState => {
                return { ...prevState, "ratingDictionary": ratingDictionary };
            });
            setSaveChangesState(false);
            setChangeAvailableToSaveState(false);
            setNeedToRefreshState(true);
            alert("Your changes are saved. Please wait a moment to confirm the changes appear in the Table View.");
            //close window added to save changes because re-editing after save was reverting to earlier value on first data entry
            setRateRelativeImportanceModalState(prevState => { return { ...prevState, modalOpen: false }; });
        }
    }

    useEffect(() => {
        saveChanges();
    }, [saveChangesState]);

    let modalContent = <div style={{
        paddingTop: "0px", paddingLeft: "20px", paddingRight: "20px",
        paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
    }}>
        {/*<Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
        content="✖"
        onClick={() => {
          setRateRelativeImportanceModalState(prevState => { return { ...prevState, modalOpen: false }; });
        }} />*/}
        <span style={{
            position: "absolute", backgroundColor: "#FFFFFF", width: "calc(90vw - 38px)", zIndex: 100,
            paddingTop: "6px", paddingBottom: "6px"
        }} >
            <Button style={{ color: changeAvailableToSaveState ? "#FFFFFF" : "#000000", width: "230px", float: "left" }}
                className="formButton" disabled={saveChangesState}
                content="Save Changes" positive={changeAvailableToSaveState}
                onClick={() => {
                    setSaveChangesState(true);
                }}
            />
            &nbsp;&nbsp;&nbsp;
            <Button style={{ color: "#000000", width: "180px", float: "right" }} className="formButton"
                content="Close Window"
                onClick={() => {
                    setRateRelativeImportanceModalState(prevState => { return { ...prevState, modalOpen: false }; });
                }}
            />
        </span>
        <br /><br /><br />
        <div className='tableFixHead'>
            <RateRelativeImportanceTable resourceState={resourceState} setResourceState={setResourceState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} globalContext={globalContext} />
        </div>
        <br /><br />
    </div>;
    return (
        <Modal
            style={{ padding: "0px", margin: "0px" }}
            dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
            open={rateRelativeImportanceModalState?.modalOpen}
            centered={false}
            content={modalContent}
            className="viewmywork"
        />
    )
})

const RateRelativeImportance = memo(({ globalContext, resourceState, setResourceState, setNeedToRefreshState,
    previousVersionLoaded }) => {

    const [rateRelativeImportanceModalState, setRateRelativeImportanceModalState] = useState({ "modalOpen": false });

    return <>
        {rateRelativeImportanceModalState.modalOpen &&
            <RateRelativeImportanceModal globalContext={globalContext} resourceState={resourceState}
                setResourceState={setResourceState}
                rateRelativeImportanceModalState={rateRelativeImportanceModalState}
                setNeedToRefreshState={setNeedToRefreshState}
                setRateRelativeImportanceModalState={setRateRelativeImportanceModalState} />}
        <span>
            <Button className="formButton" style={{ color: "#000000", width: "220px" }}
                content="Rate Relative Importance"
                onClick={() => {
                    if (globalContext.userState.id) {
                        setRateRelativeImportanceModalState({ "modalOpen": true });
                    } else {
                        alert("Please login to use this function.");
                    }
                }}
                disabled={previousVersionLoaded}
            />
            &nbsp;&nbsp;&nbsp;
            <>
                Select Desirable or Undesirable and rate your relative importance of each outcome (from 0 for no importance to 100 to represent the reference value of importance).
            </>
        </span>
    </>
})

export default RateRelativeImportance;