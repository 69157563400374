import submitToFevirServer from './SubmitToFevirServer';
import { getFoiFromReference, getFoisFromReference, getHowToCite, getResourceDictionary } from './ResourceDictionaryFunctions';
import { getTargetResourceRatingDictionary } from './SummaryOfFindingsFunctions';

const getRecommendationJsonArray = async (globalContext, recommendationFoiArray) => {
    const body = {
        'functionid': 'getmultiplefhirresources',
        'resourceids': recommendationFoiArray,
        'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 20000, body, true, false);
    if (response?.success && response.resourceentries) {
        let recommendationJsonArray = response.resourceentries.map(entry => {
            return JSON.parse(entry.toolstatestring);
        });
        return recommendationJsonArray;
    } else {
        return null;
    }
}

const getRecommendationDisplayValues = (recommendationJsonArray) => {
    let recommendationDisplayValuesDictionary = {};
    for (const resource of recommendationJsonArray) {
        let id = resource.id;
        let title = resource.title || resource.name || "Recommendation with FOI " + resource.id;
        let statement;
        let ratings;
        let population;
        let action;
        if (Array.isArray(resource.section) && resource.section.length > 0 &&
            resource.section[0].section && Array.isArray(resource.section[0].section) &&
            resource.section[0].section.length > 0) {
            let recSections = resource.section[0].section;
            for (const recSection of recSections) {
                let sectionCode = recSection.code?.text || recSection.code?.coding?.[0]?.code;
                if (sectionCode === "overallSummary" || sectionCode === "recommendation-statement") {
                    statement = recSection.text?.div;
                    if (statement === "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>") {
                        statement = "";
                    }
                } else if (sectionCode === "ratings" || sectionCode === "strengthOfRecommendationRating" || sectionCode === "strength-of-recommendation") {
                    ratings = recSection.text?.div;
                    if (ratings === "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>") {
                        ratings = "";
                    }
                } else if (sectionCode === "population") {
                    population = recSection.text?.div;
                    if (population === "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>") {
                        population = "";
                    }
                } else if (sectionCode === "action") {
                    action = recSection.text?.div;
                    if (action === "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>") {
                        action = "";
                    }
                }
            }
        }

        let resourceReference = {
            "reference": "Composition/" + resource.id,
            "type": "Composition",
            "display": title
        };
        recommendationDisplayValuesDictionary[id] = {
            id: id,
            title: title,
            statement: statement,
            ratings: ratings,
            population: population,
            action: action,
            resourceReference: resourceReference
        }
    }
    return recommendationDisplayValuesDictionary;
}

const loadSourceJsonFunction = async (resourceState, globalContext, setSourceJsonState, setResourceState,
    interventionDescription = null, comparatorDescription = null, customizedState) => {
    let fhirJson;
    if (resourceState.resourceJson) {
        fhirJson = resourceState.resourceJson;
    } else if (resourceState.resourceType) {
        fhirJson = resourceState;
    }
    fhirJson.section = resourceState.section;
    fhirJson.relatesTo = resourceState.relatesTo;
    let howToCite = getHowToCite(fhirJson);
    let resourceDictionary = await getResourceDictionary(fhirJson, globalContext);
    let ratingDictionary;
    if (customizedState?.customized && resourceState.targetResources && fhirJson?.section?.length > 1) {
        let response = await getTargetResourceRatingDictionary(resourceState.targetResources, globalContext);
        if (response?.success && response.targetResourceRatingDictionary) {
            ratingDictionary = response.targetResourceRatingDictionary;
        }
    }
    if (ratingDictionary && (fhirJson?.type?.text?.includes("Summary of ") ||
        fhirJson?.type?.coding?.[0]?.code?.includes("SummaryOf"))) {
        setResourceState(prevState => {
            let newSection = JSON.parse(JSON.stringify(prevState.section));
            let loadedSection = newSection.map(section => {
                if (section.focus?.reference) {
                    let targetfoi = section.focus.reference.replace("EvidenceVariable/", "");
                    if (!targetfoi) {
                        let targetfli;
                        if (section.focus?.identifier?.system === "https://fevir.net/FLI" && section.focus.identifier.value) {
                            targetfli = section.focus.identifier.value;
                        }
                        for (const foiKey in ratingDictionary) {
                            if (ratingDictionary[foiKey].fli == targetfli) {
                                targetfoi = foiKey;
                            }
                        }
                    }
                    if (ratingDictionary[targetfoi]) {
                        let entry = ratingDictionary[targetfoi];
                        if (entry.exists === false) {
                            section.section[8] = {
                                "title": "Relative Importance",
                                "code": {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/179423",
                                            "code": "relative-importance",
                                            "display": "Relative Importance"
                                        }
                                    ],
                                    "text": "relative importance of outcome"
                                },
                                "emptyReason": {
                                    "coding": [
                                        {
                                            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                                            "code": "notstarted",
                                            "display": "Not Started"
                                        }
                                    ]
                                }
                            }
                        }
                        if (entry.exists === true) {
                            section.section[8] = {
                                "title": "Relative Importance",
                                "code": {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/179423",
                                            "code": "relative-importance",
                                            "display": "Relative Importance"
                                        }
                                    ],
                                    "text": "relative importance of outcome"
                                },
                                "text": {
                                    "status": "generated",
                                    "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + entry.desirabilityDisplay + " " + entry.quantityValue.toString() + "%</div>"
                                },
                                "entry": [
                                    {
                                        "reference": "ArtifactAssessment/" + targetfoi, //TODO - check if targetfoi should be a different FOI here
                                        "type": "ArtifactAssessment",
                                        "display": entry.targettitle //TODO - check what title shows up here
                                    }
                                ]
                            }
                        }
                    }
                }
                return section;
            });
            return {
                ...prevState,
                "section": loadedSection,
                "ratingDictionary": ratingDictionary
            };
        });
    }
    let totalGroup = null;
    let subpopulations = [];
    let interventionGroup = null;
    let comparatorGroup = null;
    let researchStudy = null;
    let recommendationDisplayValuesDictionary;
    if (resourceState?.section?.length) {
        for (const section of resourceState.section) {
            if (section.code) {
                if (section.code.text === "Population" || section.code.coding?.[0]?.code === "population") {
                    totalGroup = section.entry?.[0] || null;
                    if (section.section?.[0]?.code?.text === "Population Subgroups") {
                        subpopulations = section.section[0].entry || null;
                    }
                } else if (section.code.text === "Intervention" || section.code.coding?.[0]?.code === "intervention") {
                    for (const subsection of section.section) {
                        if (subsection.code.text === "Intervention Description" || subsection.code.coding?.[0]?.code === "intervention-description") {
                            interventionDescription = subsection.entry?.[0] || null;
                        } else if (subsection.code.text === "Intervention Group" || subsection.code.coding?.[0]?.code === "intervention-group") {
                            interventionGroup = subsection.entry?.[0] || null;
                        }
                    }
                } else if (section.code.text === "Comparator" || section.code.coding?.[0]?.code === "comparator") {
                    for (const subsection of section.section) {
                        if (subsection.code.text === "Comparator Description" || subsection.code.coding?.[0]?.code === "comparator-description") {
                            comparatorDescription = subsection.entry?.[0] || null;
                        } else if (subsection.code.text === "Comparator Group" || subsection.code.coding?.[0]?.code === "comparator-group") {
                            comparatorGroup = subsection.entry?.[0] || null;
                        }
                    }
                } else if (section.code.text === "Research Study" || section.code.coding?.[0]?.code === "research-study") {
                    researchStudy = section.entry?.[0] || null;
                } else if (section.code.text === "Recommendations" || section.code.coding?.[0]?.code === "recommendations") {
                    if (section.entry?.length > 0) {
                        let recommendationFoiArray = section.entry
                            .filter(item => item.reference?.split('/').length > 1)
                            .map(item => { return item.reference.split('/')[1] });
                        if (recommendationFoiArray?.length > 0) {
                            let recommendationJsonArray = await getRecommendationJsonArray(globalContext, recommendationFoiArray);
                            if (recommendationJsonArray?.length > 0) {
                                recommendationDisplayValuesDictionary = getRecommendationDisplayValues(recommendationJsonArray);
                            }
                        }
                    }
                }
            }
        }
    }

    let totalGroupFoi;
    let totalGroupJson;
    let interventionGroupFoi;
    let interventionGroupJson;
    let comparatorGroupFoi;
    let comparatorGroupJson;
    let groupAssignmentFoi;
    let groupAssignmentJson;
    let groupAssignment;
    let comparatorCategory;

    if (resourceState?.relatesTo && Array.isArray(resourceState.relatesTo) && resourceState.relatesTo.length > 0) {
        for (let relatedArtifactEntry of resourceState.relatesTo) {
            if (relatedArtifactEntry.type === "depends-on") {
                if (relatedArtifactEntry.label === "Intervention Group") {
                    interventionGroupFoi = getFoiFromReference(relatedArtifactEntry.resourceReference, "", resourceDictionary);
                } else if (relatedArtifactEntry.label === "Comparator Group") {
                    comparatorGroupFoi = getFoiFromReference(relatedArtifactEntry.resourceReference, "", resourceDictionary);
                } else if (relatedArtifactEntry.label === "Total Group") {
                    totalGroupFoi = getFoiFromReference(relatedArtifactEntry.resourceReference, "", resourceDictionary);
                }
            } else if (relatedArtifactEntry.type === "composed-of") {
                groupAssignmentFoi = getFoiFromReference(relatedArtifactEntry.resourceReference, "", resourceDictionary);
            }
        }
    }
    if (totalGroupFoi) {
        totalGroupJson = resourceDictionary[totalGroupFoi];
        totalGroup = {
            "reference": "Group/" + totalGroupFoi,
            "type": "Group",
            "display": totalGroupJson.title || totalGroupJson.name || "[Untitled Group.]"
        };
    }
    if (interventionGroupFoi) {
        interventionGroupJson = resourceDictionary[interventionGroupFoi];
        interventionGroup = {
            "reference": "Group/" + interventionGroupFoi,
            "type": "Group",
            "display": interventionGroupJson?.title || interventionGroupJson?.name || "[Untitled Group.]"
        };
    }
    if (comparatorGroupFoi) {
        comparatorGroupJson = resourceDictionary[comparatorGroupFoi];
        comparatorGroup = {
            "reference": "Group/" + comparatorGroupFoi,
            "type": "Group",
            "display": comparatorGroupJson?.title || comparatorGroupJson?.name || "[Untitled Group.]"
        };
    }

    if (groupAssignmentFoi) {
        groupAssignmentJson = resourceDictionary[groupAssignmentFoi];
        groupAssignment = {
            "reference": "EvidenceVariable/" + groupAssignmentFoi,
            "type": "EvidenceVariable",
            "display": groupAssignmentJson?.title
        };
        comparatorCategory = groupAssignmentJson?.title.split(" vs. ")[1] || null;
    } else if (interventionDescription && comparatorDescription) {
        let interventionDisplay = interventionDescription.display || interventionDescription.reference || "Untitled Intervention";
        let comparatorDisplay = comparatorDescription.display || comparatorDescription.reference || "Untitled Comparator";

        groupAssignmentJson = {
            "resourceType": "EvidenceVariable",
            "meta": {
                "profile": [
                    "http://hl7.org/fhir/uv/ebm/StructureDefinition/group-assignment"
                ]
            },
            "title": "GroupAssignment: " + interventionDisplay + " vs. " + comparatorDisplay,
            "status": "active",
            "publisher": "Computable Publishing LLC",
            "contact": [
                {
                    "telecom": [
                        {
                            "system": "email",
                            "value": "support@computablepublishing.com"
                        }
                    ]
                }
            ],
            "description": "This EvidenceVariable Resource (GroupAssignment Profile) describes a 2-group study (so handling='dichotomous') and lists the 2 study arms in category.name elements. Because the definition of this EvidenceVariable Resource is fully expressed in the handling and category elements, the defintion.concept element uses a CodeableConcept for 'Defined in handling and category elements'.",
            "useContext": [
                {
                    "code": {
                        "system": "https://fevir.net/resources/CodeSystem/179423",
                        "code": "evidence-communication",
                        "display": "Evidence Communication"
                    },
                    "valueCodeableConcept": {
                        "coding": [
                            {
                                "system": "https://fevir.net/resources/CodeSystem/179423",
                                "code": "GroupAssignment",
                                "display": "GroupAssignment"
                            }
                        ]
                    }
                }
            ],
            "copyright": "https://creativecommons.org/licenses/by-nc-sa/4.0/",
            "definition": {
                "concept": {
                    "coding": [
                        {
                            "system": "https://fevir.net/resources/CodeSystem/179423",
                            "code": "defined-in-handling-and-category",
                            "display": "Defined in handling and category elements"
                        }
                    ]
                }
            },
            "handling": "dichotomous",
            "category": [
                {
                    "name": interventionDisplay,
                    "valueReference": interventionDescription
                },
                {
                    "name": comparatorDisplay,
                    "valueReference": comparatorDescription
                }
            ]
        }
        const body = {
            'functionid': "submitfhirresource",
            'idToken': "",
            'fhirEntry': JSON.stringify(groupAssignmentJson),
            'title': groupAssignmentJson.title,
            'status': 'active',
        };
        let response = await submitToFevirServer(globalContext, 5000, body, true, false);
        let groupAssignmentFOI;
        if (response.success) {
            groupAssignmentFOI = response.formstateid;
            setResourceState(prevState => {
                let newRelatesTo = prevState.relatesTo || [];
                newRelatesTo.push({
                    "type": "composed-of",
                    "classifier": [{
                        "coding": [
                            {
                                "system": "https://fevir.net/resources/CodeSystem/179423",
                                "code": "GroupAssignment",
                                "display": "GroupAssignment"
                            }
                        ]
                    }],
                    "resourceReference": {
                        "reference": "EvidenceVariable/" + groupAssignmentFOI,
                        "type": "EvidenceVariable",
                        "display": groupAssignmentJson.title
                    }
                });
                return { ...prevState, relatesTo: newRelatesTo }
            });
            groupAssignment = {
                "reference": "EvidenceVariable/" + groupAssignmentFOI,
                "type": "EvidenceVariable",
                "display": groupAssignmentJson.title
            };
            comparatorCategory = comparatorDisplay;
        }
    }
    let groupReferences = {
        totalGroup: totalGroup,
        subpopulations: subpopulations,
        interventionGroup: interventionGroup,
        interventionDescription: interventionDescription,
        comparatorGroup: comparatorGroup,
        comparatorDescription: comparatorDescription,
        researchStudy: researchStudy,
        groupAssignment: groupAssignment,
        comparatorCategory: comparatorCategory
    };
    setSourceJsonState({
        "howToCite": howToCite,
        "resourceDictionary": resourceDictionary,
        "loaded": true,
        "loaded2": true,
        "groupReferences": groupReferences,
        "recommendationDisplayValuesDictionary": recommendationDisplayValuesDictionary
    });
}

export { loadSourceJsonFunction };