import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import React, { useState, useEffect, useContext } from 'react';
import { AssociatedResourcesDisplay, getStringFromFHIR } from './ResourceFunctions';
import FevirContext from './FevirContext';
import { EvidenceReportPackageSectionDisplay } from './EvidenceReportPackageViewingTool';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';

const RecommendationsSectionDisplay = ({ recommendationsSection, recommendationDisplayValuesDictionary }) => {
  if (!recommendationsSection || !Object.keys(recommendationsSection).length) {
    return <>No content in this section.</>
  }

  let title;
  if (recommendationsSection.title) {
    title = recommendationsSection.title;
  }
  let code;
  if (recommendationsSection.code && Object.keys(recommendationsSection.code).length > 0) {
    code = recommendationsSection.code;
  }
  let author;
  if (recommendationsSection.author && Array.isArray(recommendationsSection.author) && recommendationsSection.author.length > 0) {
    author = recommendationsSection.author
  }
  let focus;
  if (recommendationsSection.focus && Object.keys(recommendationsSection.focus).length > 0) {
    focus = recommendationsSection.focus;
  }
  let text;
  if (recommendationsSection.text && Object.keys(recommendationsSection.text).length > 0) {
    text = recommendationsSection.text;
  }
  let orderedBy;
  if (recommendationsSection.orderedBy && Object.keys(recommendationsSection.orderedBy).length > 0) {
    orderedBy = recommendationsSection.orderedBy;
  }
  let entry;
  if (recommendationsSection.entry && Array.isArray(recommendationsSection.entry) && recommendationsSection.entry.length > 0) {
    entry = recommendationsSection.entry
  }
  let emptyReason;
  if (recommendationsSection.emptyReason && Object.keys(recommendationsSection.emptyReason).length > 0) {
    emptyReason = recommendationsSection.emptyReason;
  }
  let section;
  if (recommendationsSection.section && Array.isArray(recommendationsSection.section) && recommendationsSection.section.length > 0) {
    section = recommendationsSection.section
  }
  let recommendationKeys = [];
  if (recommendationDisplayValuesDictionary && typeof recommendationDisplayValuesDictionary === 'object' &&
    Object.keys(recommendationDisplayValuesDictionary).length > 0) {
    recommendationKeys = Object.keys(recommendationDisplayValuesDictionary);
  }
  let numberOfRecommendations = recommendationKeys.length || 0;

  return <div>
    <p><b>{title ? title : getStringFromFHIR.CodeableConcept(code)}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {focus && <span><b>Focus: </b><DisplayFromFHIR reference={focus} /><br /></span>}
      {(Array.isArray(author) && author.length > 0) &&
        author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {(text && Object.keys(text).length > 0) && <div>
        {text.status === "empty" ?
          <>No human-readable text added.</>
          :
          <div>
            <b>Narrative Summary: </b>
            <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
              <DisplayFromFHIR xhtml={text.div} />
            </div>
          </div>}
        <br />
      </div>}
      {numberOfRecommendations > 0 && recommendationKeys.map((key, keyIndex) => {
        const rec = recommendationDisplayValuesDictionary[key];
        return <div key={keyIndex} >
          <p><b>Recommendation {keyIndex + 1} of {numberOfRecommendations}</b></p>
          <div style={{ marginLeft: "24px" }}>
            {rec.title && <p><b>Title: </b>{rec.title}</p>}
            {rec.statement && <span><b>Recommendation Statement: </b><DisplayFromFHIR xhtml={rec.statement} /><br /></span>}
            {rec.ratings && <span><b>Strength/Level of Recommendation: </b><DisplayFromFHIR xhtml={rec.ratings} /><br /></span>}
            {rec.population && <span><b>Population: </b><DisplayFromFHIR xhtml={rec.population} /><br /></span>}
            {rec.action && <span><b>Action: </b><DisplayFromFHIR xhtml={rec.action} /><br /></span>}
            {rec.resourceReference && <span><b>Recommendation Resource: </b>
              <DisplayFromFHIR reference={rec.resourceReference} /></span>}
          </div>
        </div>
      })}
      {orderedBy && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={orderedBy} /><br /></span>}
      {(Array.isArray(entry) && entry.length > 0) &&
        entry.map((entry, entryIndex) => {
          return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
        })}
      {emptyReason && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={emptyReason} /><br /></span>}
      {(Array.isArray(section) && section.length > 0) &&
        section.map((subsection, subsectionIndex) => {
          return <span key={subsectionIndex}>
            <SectionDisplay sect={subsection} sectIndex={subsectionIndex} />
          </span>;
        })}
    </div>
  </div>
}

const SectionDisplay = ({ sect, sectIndex }) => {
  if (!sect || !Object.keys(sect).length) {
    return <div key={sectIndex} >
      <br />
      <p><b>{'Section ' + (sectIndex + 1).toString()}:</b></p>
      <>No content in this section.</>
    </div>

  }
  return <div key={sectIndex} >
    <br />
    <p><b>{sect.title ? sect.title : 'Section ' + (sectIndex + 1).toString()}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {(sect.code && Object.keys(sect.code).length > 0) && <span><b>Code: </b><DisplayFromFHIR codeableConcept={sect.code} /><br /></span>}
      {(sect.focus && Object.keys(sect.focus).length > 0) && <span><b>Focus: </b><DisplayFromFHIR reference={sect.focus} /><br /></span>}
      {(Array.isArray(sect.author) && sect.author.length > 0) &&
        sect.author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {(sect.text && Object.keys(sect.text).length > 0) && <div>
        {sect.text.status === "empty" ?
          <>No human-readable text added.</>
          :
          <div>
            <b>Narrative Summary: </b>
            <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
              <DisplayFromFHIR xhtml={sect.text.div} />
            </div>
          </div>
        }
        <br />
      </div>}
      {(sect.orderedBy && Object.keys(sect.orderedBy).length > 0) && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={sect.orderedBy} /><br /></span>}
      {(Array.isArray(sect.entry) && sect.entry.length > 0) &&
        sect.entry.map((entry, entryIndex) => {
          return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
        })}
      {(sect.emptyReason && Object.keys(sect.emptyReason).length > 0) && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={sect.emptyReason} /><br /></span>}
      {(Array.isArray(sect.section) && sect.section.length > 0) &&
        sect.section.map((subsection, subsectionIndex) => {
          return <span key={subsectionIndex}><SectionDisplay sect={subsection} sectIndex={subsectionIndex} /></span>;
        })}
    </div>
  </div>
}

const SummarySectionDisplay = ({ topSection }) => {

  if (!topSection || !Object.keys(topSection).length) {
    return <>No content in this section.</>
  }

  let title;
  if (topSection.title) {
    title = topSection.title;
  }
  let code;
  if (topSection.code && Object.keys(topSection.code).length > 0) {
    code = topSection.code;
  }
  let author;
  if (topSection.author && Array.isArray(topSection.author) && topSection.author.length > 0) {
    author = topSection.author
  }
  let focus;
  if (topSection.focus && Object.keys(topSection.focus).length > 0) {
    focus = topSection.focus;
  }
  let text;
  if (topSection.text && Object.keys(topSection.text).length > 0) {
    text = topSection.text;
  }
  let orderedBy;
  if (topSection.orderedBy && Object.keys(topSection.orderedBy).length > 0) {
    orderedBy = topSection.orderedBy;
  }
  let entry;
  if (topSection.entry && Array.isArray(topSection.entry) && topSection.entry.length > 0) {
    entry = topSection.entry
  }
  let emptyReason;
  if (topSection.emptyReason && Object.keys(topSection.emptyReason).length > 0) {
    emptyReason = topSection.emptyReason;
  }
  let section;
  if (topSection.section && Array.isArray(topSection.section) && topSection.section.length > 0) {
    section = topSection.section
  }
  let titleDisplay = title || getStringFromFHIR.CodeableConcept(code);

  return <div>
    <p><b>{titleDisplay}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {focus && <span><b>Focus: </b><DisplayFromFHIR reference={focus} /><br /></span>}
      {(Array.isArray(author) && author.length > 0) &&
        author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {(text && Object.keys(text).length > 0) && <div>
        {text.status === "empty" ?
          <>No human-readable text added.</>
          :
          <div>
            <b>Narrative Summary: </b>
            <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
              <DisplayFromFHIR xhtml={text.div} />
            </div>
          </div>}
        <br />
      </div>}
      {orderedBy && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={orderedBy} /><br /></span>}
      {(Array.isArray(entry) && entry.length > 0) &&
        entry.map((entry, entryIndex) => {
          return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
        })}
      {emptyReason && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={emptyReason} /><br /></span>}
      {(Array.isArray(section) && section.length > 0) &&
        section.map((subsection, subsectionIndex) => {
          return <span key={subsectionIndex}>
            <SectionDisplay sect={subsection} sectIndex={subsectionIndex} />
          </span>;
        })}
    </div>
  </div>
}

const GuidelineViewerTool = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (fhirJson.relatesTo && Array.isArray(fhirJson.relatesTo) && fhirJson.relatesTo.length > 0) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.resourceReference?.display;
        break;
      }
    }
  }
  let description;
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (const extension of fhirJson.extension) {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-description') {
        description = extension.valueMarkdown;
      }
    }
  }

  const globalContext = useContext(FevirContext);
  const [sourceJsonState, setSourceJsonState] = useState({});

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(fhirJson, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, []);

  let summarySection;
  let recommendationsSection = {};
  let introductionSection = {};
  let discussionSection = {};
  let methodsSection = {};
  let referencesSection = {};
  let competingInterestsSection = {};
  let acknowlegementsSection = {};
  let appendicesSection = {};
  let additionalSections = [];
  if (Array.isArray(fhirJson?.section)) {
    for (const section of fhirJson.section) {
      let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
      if (sectionCode === "recommendations" || sectionCode === "Recommendations") {
        recommendationsSection = section;
      } else if (sectionCode === "introduction" || sectionCode === "Introduction") {
        introductionSection = section;
      } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
        discussionSection = section;
      } else if (sectionCode === "methods" || sectionCode === "Methods") {
        methodsSection = section;
      } else if (sectionCode === "summary" || sectionCode === "Summary") {
        summarySection = section;
      } else if (sectionCode === "references" || sectionCode === "References") {
        referencesSection = section;
      } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
        competingInterestsSection = section;
      } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
        acknowlegementsSection = section;
      } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
        appendicesSection = section;
      } else {
        additionalSections.push(section);
      }
    }
  }

  return <div style={{ marginTop: "12px" }}>
    <div>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Guideline Title: </b>
          {fhirJson.title ? fhirJson.title : fhirJson.name ? fhirJson.name : "No title provided."}
        </p>
        {description &&
          <div><b>Guideline Description: </b><DisplayFromFHIR markdown={description} />
          </div>}
        {(fhirJson.subject && Array.isArray(fhirJson.subject) && fhirJson.subject.length > 0) &&
          fhirJson.subject.map((subject, subjectIndex) => {
            return <div key={subjectIndex}><b>Subject: </b><DisplayFromFHIR reference={subject} /></div>;
          })}
        {(fhirJson.category && Array.isArray(fhirJson.category) && fhirJson.category.length > 0) &&
          fhirJson.category.map((subject, subjectIndex) => {
            return <div key={subjectIndex}><b>Category: </b><DisplayFromFHIR codeableConcept={subject} /></div>;
          })}
        {(fhirJson.text && fhirJson.text.status !== "empty" && fhirJson.text.div) && <div>
          <p>Narrative Summary of Guideline Resource:</p>
          <DisplayFromFHIR xhtml={fhirJson.text.div} />
        </div>}
        {summarySection && <SummarySectionDisplay topSection={summarySection} />}
      </div>
      <h3 id="introduction">Introduction</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={introductionSection} />
      </div>
      <br />
      <h3 id="methods">Methods</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={methodsSection} />
      </div>
      <br />
      <h3 id="recommendations">Recommendations</h3>
      <div style={{ marginLeft: "24px" }}>
        <RecommendationsSectionDisplay recommendationsSection={recommendationsSection}
          recommendationDisplayValuesDictionary={sourceJsonState.recommendationDisplayValuesDictionary} />
      </div>
      <br />
      <h3 id="discussion">Discussion</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={discussionSection} />
        {additionalSections.length > 0 && additionalSections.map((section, sectionIndex) => {
          return <div key={sectionIndex} >
            <EvidenceReportPackageSectionDisplay topSection={section} />
          </div>
        })}
      </div>
      <br />
      <h3 id="references">References</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={referencesSection} />
      </div>
      <br />
      <h3 id="competing-interests">Competing Interests</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={competingInterestsSection} />
      </div>
      <br />
      <h3 id="acknowledgements">Acknowledgements</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={acknowlegementsSection} />
      </div>
      <br />
      <h3 id="appendices">Appendices</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={appendicesSection} />
      </div>
      <br />
    </div>
    <h3 id="how-to-cite">How to Cite</h3>
    <div style={{ marginLeft: "24px" }}>
      {(howToCite) ?
        <div>
          <p><b>Citation Summary:</b></p>
          <DisplayFromFHIR markdown={howToCite} />
        </div>
        :
        <p>Can be added upon editing.</p>
      }
    </div>
    <h3 id="metadata">Metadata</h3>
    <div style={{ marginLeft: "24px" }}>
      <MetadataPatternDisplay fhirJson={fhirJson} />
    </div>
    <h3 id="associated-resources">Associated Resources</h3>
    <div style={{ marginLeft: "24px" }}>
      <AssociatedResourcesDisplay fhirJson={fhirJson} />
    </div>
    <h3 id="classifiers">Classifiers</h3>
    {classificationsLoadedState ?
      <DisplayClassifiers classificationsArray={classificationsArrayState} />
      :
      <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
    }
    <h3 id="json-outline">JSON Outline</h3>
    <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
    <br /><br />
  </div>
}

export default GuidelineViewerTool;