import React, { memo, useState, useEffect, useImperativeHandle } from 'react';
import { AssociatedResourcesDisplay, SimpleResourceFieldViewer, clearDivWrapper } from './ResourceFunctions';
import { DataEntry, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import { EvidenceReportMetadataPatternEdit } from './EvidenceReportMetadataDataEntry';
import {
  EditIntroduction, EditDiscussion, EditMethods, EditReferences, EditCompetingInterests,
  EditAcknowledgements, EditAppendices
} from './EvidenceReportPackageAuthoringTool';
import { generateCompositionSummary, generateGroupSummary, generateSubgroupsSummary, generateResearchStudySummary } from './GenerateNarrativeSummaryFunctions';
import { getFoiFromReference, getFoisFromReference } from './ResourceDictionaryFunctions';
import { DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import { ButtonWithConfirmModal } from './ConfirmModal';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } })
}

let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];

let emptyReasonValueSet = [
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }
];
let emptyReasonCodeableConceptSet = [
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }]
  },
  {
    "coding":
      [{ "system": "https://fevir.net/resources/CodeSystem/179423", "code": "not-relevant", "display": "Not Relevant" }]
  }
];

const ExposureSectionsDataEntry = ({ startingValue, setResourceState, topSection,
  sourceJsonState, globalContext, setSourceJsonState,
  fullResourceState, setFullResourceState }) => {
  if (!Array.isArray(startingValue)) {
    startingValue = null;
  }
  let startingSubsection0;
  let startingSubsection1;
  let startingSubsection2;
  let startingDescriptionEntry = null;
  let startingGroupEntry = null;
  let startingSubpopulationsEntry = [];
  if (topSection === "Intervention") {
    try {
      startingSubsection0 = startingValue[0];
    } catch {
      startingSubsection0 = {
        "title": "Intervention Description",
        "code": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "intervention-description",
              "display": "Intervention Description"
            }
          ]
        },
        "emptyReason": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
              "code": "notstarted",
              "display": "Not Started"
            }
          ]
        }
      }
    }
    try {
      startingSubsection1 = startingValue[1];
    } catch {
      startingSubsection1 = {
        "title": "Intervention Group",
        "code": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "intervention-group",
              "display": "Intervention Group"
            }
          ]
        },
        "emptyReason": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
              "code": "notstarted",
              "display": "Not Started"
            }
          ]
        }
      }
    }
    if (sourceJsonState?.groupReferences?.subpopulations?.length) {
      try {
        startingSubsection2 = startingValue[2];
        if (!startingSubsection2) {
          startingSubsection2 = {
            "title": "Intervention Groups for Population Subgroups",
            "code": {
              "text": "Intervention Groups for Population Subgroups"
            },
            "emptyReason": {
              "coding": [
                {
                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                  "code": "notstarted",
                  "display": "Not Started"
                }
              ]
            }
          }
        }
      } catch {
        startingSubsection2 = {
          "title": "Intervention Groups for Population Subgroups",
          "code": {
            "text": "Intervention Groups for Population Subgroups"
          },
          "emptyReason": {
            "coding": [
              {
                "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                "code": "notstarted",
                "display": "Not Started"
              }
            ]
          }
        }
      }
    }
  } else if (topSection === "Comparator") {
    try {
      startingSubsection0 = startingValue[0];
    } catch {
      startingSubsection0 = {
        "title": "Comparator Description",
        "code": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "comparator-description",
              "display": "Comparator Description"
            }
          ]
        },
        "emptyReason": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
              "code": "notstarted",
              "display": "Not Started"
            }
          ]
        }
      }
    }
    try {
      startingSubsection1 = startingValue[1];
    } catch {
      startingSubsection1 = {
        "title": "Comparator Group",
        "code": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "comparator-group",
              "display": "Comparator Group"
            }
          ]
        },
        "emptyReason": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
              "code": "notstarted",
              "display": "Not Started"
            }
          ]
        }
      }
    }
    if (sourceJsonState?.groupReferences?.subpopulations?.length) {
      try {
        startingSubsection2 = startingValue[2];
        if (!startingSubsection2) {
          startingSubsection2 = {
            "title": "Comparator Groups for Population Subgroups",
            "code": {
              "text": "Comparator Groups for Population Subgroups"
            },
            "emptyReason": {
              "coding": [
                {
                  "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                  "code": "notstarted",
                  "display": "Not Started"
                }
              ]
            }
          }
        }
      } catch {
        startingSubsection2 = {
          "title": "Comparator Groups for Population Subgroups",
          "code": {
            "text": "Comparator Groups for Population Subgroups"
          },
          "emptyReason": {
            "coding": [
              {
                "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                "code": "notstarted",
                "display": "Not Started"
              }
            ]
          }
        }
      }
    }
  }

  let startingExposureSectionArray = [
    {
      "title": topSection + " Description",
      "code": {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": topSection.toLowerCase() + "-description",
            "display": topSection + " Description"
          }
        ]
      },
      "emptyReason": {
        "coding": [
          {
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }
        ]
      }
    },
    {
      "title": topSection + " Group",
      "code": {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": topSection.toLowerCase() + "-group",
            "display": topSection + " Group"
          }
        ]
      },
      "emptyReason": {
        "coding": [
          {
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted",
            "display": "Not Started"
          }
        ]
      }
    }
  ];
  if (Array.isArray(startingValue)) {
    startingExposureSectionArray = startingValue;
  }

  if (startingExposureSectionArray[0].entry) {
    startingDescriptionEntry = startingExposureSectionArray[0].entry[0];
  }
  if (startingExposureSectionArray[1].entry) {
    startingGroupEntry = startingExposureSectionArray[1].entry[0];
  }
  if (startingExposureSectionArray[2]?.entry) {
    startingSubpopulationsEntry = startingExposureSectionArray[2].entry;
  }
  if (!startingExposureSectionArray[2] && sourceJsonState?.groupReferences?.subpopulations?.length) {
    startingExposureSectionArray.push(startingSubsection2);
  }

  const [exposureSectionArrayState, setExposureSectionArrayState] = useState(startingExposureSectionArray);
  const [referencesEntryState, setReferencesEntryState] = useState({
    "descriptionReference": startingDescriptionEntry,
    "groupReference": startingGroupEntry,
    "subgroupReferences": startingSubpopulationsEntry
  });

  useEffect((() => {
    let newArray = JSON.parse(JSON.stringify(exposureSectionArrayState));
    if (newArray && Array.isArray(newArray) && (newArray.length === 2 || newArray.length === 3)) {
      setResourceState(prevState => { return { ...prevState, section: newArray } })
    }
  }), [exposureSectionArrayState])

  return <div>
    <h3>{topSection} Description</h3>
    <ExposureSectionDataEntry subsectionCode="Description" startingValue={startingSubsection0}
      setResourceState={setExposureSectionArrayState} resourceState={exposureSectionArrayState}
      topSection={topSection} globalContext={globalContext}
      referencesEntryState={referencesEntryState} setReferencesEntryState={setReferencesEntryState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fullResourceState={fullResourceState} setFullResourceState={setFullResourceState} />
    <h3>{topSection} Group</h3>
    <ExposureSectionDataEntry subsectionCode="Group" startingValue={startingSubsection1}
      setResourceState={setExposureSectionArrayState} resourceState={exposureSectionArrayState}
      topSection={topSection} globalContext={globalContext}
      referencesEntryState={referencesEntryState} setReferencesEntryState={setReferencesEntryState}
      sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
      fullResourceState={fullResourceState} setFullResourceState={setFullResourceState} />
    {startingSubsection2 && <>
      <h3>{topSection} Groups for Population Subgroups</h3>
      <ExposureSubpopulationsSectionDataEntry startingValue={startingSubsection2}
        setResourceState={setExposureSectionArrayState} resourceState={exposureSectionArrayState}
        topSection={topSection} globalContext={globalContext}
        referencesEntryState={referencesEntryState} setReferencesEntryState={setReferencesEntryState}
        sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
        fullResourceState={fullResourceState} setFullResourceState={setFullResourceState}
      />
    </>}
  </div>
}

const ExposureSectionDataEntry = ({ subsectionCode, startingValue, resourceState, setResourceState, topSection,
  sourceJsonState, globalContext, referencesEntryState, setReferencesEntryState,
  setSourceJsonState, fullResourceState, setFullResourceState }) => {
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let startingExposureSection = {
    "title": subsectionCode + " Section",
    "code": { "text": subsectionCode },
    "author": [],
    "focus": {},
    "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": []
  };
  if (startingValue) {
    if (startingValue.title) { startingExposureSection.title = startingValue.title; }
    if (startingValue.code) { startingExposureSection.code = startingValue.code; }
    if (startingValue.author) { startingExposureSection.author = startingValue.author; }
    if (startingValue.focus) { startingExposureSection.focus = startingValue.focus; }
    if (startingValue.text) { startingExposureSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingExposureSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingExposureSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingExposureSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingExposureSection.section = startingValue.section; }
  } else {
    startingExposureSection = {
      "title": topSection + " " + subsectionCode + " Section",
      "code": { "text": topSection + " " + subsectionCode }
    };
  }

  const [exposureSectionState, setExposureSectionState] = useState(startingExposureSection);
  const [firstRenderState, setFirstRenderState] = useState(true);
  const [interventionGroupValuesState, setInterventionGroupValuesState] = useState(null);

  let emptyReasonValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }
  ];

  let generateInterventionGroupValues = () => {
    if (sourceJsonState?.groupReferences?.totalGroup && sourceJsonState.groupReferences.interventionDescription) {
      return {
        "combinationMethod": "all-of",
        "characteristic": [
          {
            "description": "inclusion in " + sourceJsonState.groupReferences.totalGroup.display,
            "code": { "text": "Member of" },
            "valueReference": sourceJsonState.groupReferences.totalGroup,
            "exclude": false
          },
          {
            "description": "exposed to " + sourceJsonState.groupReferences.interventionDescription.display,
            "code": { "text": "Exposed to" },
            "valueReference": sourceJsonState.groupReferences.interventionDescription,
            "exclude": false
          }
        ]
      }
    }
  }

  let generateComparatorGroupValues = () => {
    if (sourceJsonState?.groupReferences?.totalGroup && sourceJsonState.groupReferences.comparatorDescription) {
      return {
        "combinationMethod": "all-of",
        "characteristic": [
          {
            "description": "inclusion in " + sourceJsonState.groupReferences.totalGroup.display,
            "code": { "text": "Member of" },
            "valueReference": sourceJsonState.groupReferences.totalGroup,
            "exclude": false
          },
          {
            "description": "exposed to " + sourceJsonState.groupReferences.comparatorDescription.display,
            "code": { "text": "Exposed to" },
            "valueReference": sourceJsonState.groupReferences.comparatorDescription,
            "exclude": false
          }
        ]
      }
    }
  }

  useEffect(() => {
    if (topSection === "Intervention") {
      setInterventionGroupValuesState(generateInterventionGroupValues());
    } else if (topSection === "Comparator") {
      setInterventionGroupValuesState(generateComparatorGroupValues());
    }
  }, [sourceJsonState]);

  useEffect(() => {
    if (subsectionCode === "Description" && referencesEntryState?.descriptionReference) {
      setExposureSectionState(prevState => {
        return { ...prevState, entry: [JSON.parse(JSON.stringify(referencesEntryState.descriptionReference))] };
      });
    }
    if (subsectionCode === "Group" && referencesEntryState?.groupReference) {
      setExposureSectionState(prevState => {
        return { ...prevState, entry: [JSON.parse(JSON.stringify(referencesEntryState.groupReference))] };
      });
    }
  }, [referencesEntryState])

  useEffect(() => {
    if (resourceState) {
      let newSectionArray = JSON.parse(JSON.stringify(resourceState));
      let newSection = JSON.parse(JSON.stringify(exposureSectionState));
      if (newSection.section?.length || newSection.entry?.length ||
        (newSection.text && newSection.text.status !== "empty")) {
        delete newSection.emptyReason;
      } else if (!newSection.emptyReason) {
        newSection.emptyReason = {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted", "display": "Not Started"
          }]
        }
      }
      if (!newSectionArray || !Array.isArray(newSectionArray) || newSectionArray.length === 0) {
        if (topSection === "Intervention") {
          newSectionArray = [
            {
              "title": "Intervention Description",
              "code": {
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "intervention-description",
                    "display": "Intervention Description"
                  }
                ]
              },
              "emptyReason": {
                "coding": [
                  {
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }
                ]
              }
            },
            {
              "title": "Intervention Group",
              "code": {
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "intervention-group",
                    "display": "Intervention Group"
                  }
                ]
              },
              "emptyReason": {
                "coding": [
                  {
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }
                ]
              }
            }
          ];
        }
        if (topSection === "Comparator") {
          newSectionArray = [
            {
              "title": "Comparator Description",
              "code": {
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "comparator-description",
                    "display": "Comparator Description"
                  }
                ]
              },
              "emptyReason": {
                "coding": [
                  {
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }
                ]
              }
            },
            {
              "title": "Comparator Group",
              "code": {
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "comparator-group",
                    "display": "Comparator Group"
                  }
                ]
              },
              "emptyReason": {
                "coding": [
                  {
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }
                ]
              }
            }
          ];
        }
      }
      if (subsectionCode === "Description") {
        newSectionArray[0] = newSection;
      } else if (subsectionCode === "Group") {
        if (newSectionArray.length === 1) {
          newSectionArray.push(newSection);
        } else {
          newSectionArray[1] = newSection;
        }
      }
      setResourceState(newSectionArray);
    }
  }, [exposureSectionState]);

  if (subsectionCode === "Description") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={topSection + " " + subsectionCode + ' Section Summary'}
          generateSummaryFunction={generateGroupSummary}
          sectionEntry={exposureSectionState.entry} globalContext={globalContext}
          resourceType="Group" resourceDictionary={resourceDictionary}
          startingValue={exposureSectionState.text} setResourceState={setExposureSectionState} />
        <h3>{subsectionCode} Section Content: Create or identify the Group Resource (ExposureDefinition or ComparatorDefinition Profile) for the {topSection}.</h3>
        <div style={{ marginLeft: "24px" }}>
          {referencesEntryState && <DataEntry datatype='Reference' elementName='descriptionReference'
            fieldLabel='Group Resource (ExposureDefinition or ComparatorDefinition Profile)'
            startingValue={referencesEntryState.descriptionReference} referencedResourceTypes={['Group']}
            startingResourceType="Group" selectProfile={['ExposureDefinition', 'ComparatorDefinition']}
            startCollapsed enableCreation={true}
            setResourceState={setReferencesEntryState} setSourceJsonState={setSourceJsonState}
            globalContext={globalContext}
            fullResourceState={fullResourceState} />}
        </div>
        <br />
        {((!exposureSectionState.section || exposureSectionState.section.length === 0) &&
          (!exposureSectionState.entry || exposureSectionState.entry.length === 0) &&
          (!exposureSectionState.text || exposureSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={exposureSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setExposureSectionState} />
            </div>
          </>}
      </div>
    </>
  } else if (subsectionCode === "Group") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={topSection + " " + subsectionCode + ' Section Summary'}
          generateSummaryFunction={generateGroupSummary}
          sectionEntry={exposureSectionState.entry} globalContext={globalContext}
          resourceType="Group" resourceDictionary={resourceDictionary}
          startingValue={exposureSectionState.text} setResourceState={setExposureSectionState} />
        <h3>{subsectionCode} Section Content: Create or identify the Group Resource (ExposureGroup or ComparatorGroup Profile) for the {topSection}.</h3>
        <div style={{ marginLeft: "24px" }}>
          {referencesEntryState && <DataEntry datatype='Reference' elementName='groupReference'
            fieldLabel='Group Resource (ExposureGroup or ComparatorGroup Profile)'
            startingValue={referencesEntryState.groupReference} referencedResourceTypes={['Group']}
            startingResourceType="Group" selectProfile={['ExposureGroup', 'ComparatorGroup']}
            startCollapsed enableCreation={true}
            addElementValues={interventionGroupValuesState}
            setResourceState={setReferencesEntryState} setSourceJsonState={setSourceJsonState}
            globalContext={globalContext}
            fullResourceState={fullResourceState} />}
        </div>
        <br />
        {((!exposureSectionState.section || exposureSectionState.section.length === 0) &&
          (!exposureSectionState.entry || exposureSectionState.entry.length === 0) &&
          (!exposureSectionState.text || exposureSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={exposureSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setExposureSectionState} />
            </div>
          </>}
      </div>
    </>
  } else {
    return <>
      <p>Unrecognized section detected with sectionCode:</p>
      <p>{subsectionCode}</p>
    </>
  }
}

const ExposureSubpopulationsSectionDataEntry = ({ startingValue, resourceState, setResourceState, topSection,
  sourceJsonState, globalContext, referencesEntryState, setReferencesEntryState,
  setSourceJsonState, fullResourceState, setFullResourceState }) => {
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let startingExposureSection = {
    "title": "Subgroups Section",
    "code": { "text": "Subgroups" },
    "author": [],
    "focus": {},
    "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": []
  };
  if (startingValue) {
    if (startingValue.title) { startingExposureSection.title = startingValue.title; }
    if (startingValue.code) { startingExposureSection.code = startingValue.code; }
    if (startingValue.author) { startingExposureSection.author = startingValue.author; }
    if (startingValue.focus) { startingExposureSection.focus = startingValue.focus; }
    if (startingValue.text) { startingExposureSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingExposureSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingExposureSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingExposureSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingExposureSection.section = startingValue.section; }
  } else {
    startingExposureSection = {
      "title": topSection + " Subgroups Section",
      "code": { "text": "Subgroups Section" }
    };
  }

  const [exposureSectionState, setExposureSectionState] = useState(startingExposureSection);
  const [firstRenderState, setFirstRenderState] = useState(true);
  const [interventionSubpopulationsGroupValuesState, setInterventionSubpopulationsGroupValuesState] = useState(null);

  let emptyReasonValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }
  ];

  let generateSubpopulationsInterventionGroupValues = () => {
    if (sourceJsonState?.groupReferences?.subpopulations?.length && sourceJsonState.groupReferences.interventionDescription) {
      return sourceJsonState.groupReferences.subpopulations.map(subgroup => {
        return {
          "combinationMethod": "all-of",
          "characteristic": [
            {
              "description": "inclusion in " + subgroup.display,
              "code": { "text": "Member of" },
              "valueReference": subgroup,
              "exclude": false
            },
            {
              "description": "exposed to " + sourceJsonState.groupReferences.interventionDescription.display,
              "code": { "text": "Exposed to" },
              "valueReference": sourceJsonState.groupReferences.interventionDescription,
              "exclude": false
            }
          ]
        }
      });

    }
  }

  let generateSubpopulationsComparatorGroupValues = () => {
    if (sourceJsonState?.groupReferences?.subpopulations?.length && sourceJsonState.groupReferences.comparatorDescription) {
      return sourceJsonState.groupReferences.subpopulations.map(subgroup => {
        return {
          "combinationMethod": "all-of",
          "characteristic": [
            {
              "description": "inclusion in " + subgroup.display,
              "code": { "text": "Member of" },
              "valueReference": subgroup,
              "exclude": false
            },
            {
              "description": "exposed to " + sourceJsonState.groupReferences.comparatorDescription.display,
              "code": { "text": "Exposed to" },
              "valueReference": sourceJsonState.groupReferences.comparatorDescription,
              "exclude": false
            }
          ]
        }
      });
    }
  }

  useEffect(() => {
    if (topSection === "Intervention") {
      setInterventionSubpopulationsGroupValuesState(generateSubpopulationsInterventionGroupValues());
    } else if (topSection === "Comparator") {
      setInterventionSubpopulationsGroupValuesState(generateSubpopulationsComparatorGroupValues());
    }
  }, [sourceJsonState]);

  useEffect(() => {
    if (referencesEntryState?.subgroupReferences) {
      setExposureSectionState(prevState => {
        return { ...prevState, entry: JSON.parse(JSON.stringify(referencesEntryState.subgroupReferences)) };
      });
    }
  }, [referencesEntryState])

  useEffect(() => {
    if (firstRenderState) {
      setFirstRenderState(false);
    } else if (resourceState) {
      let newSectionArray = JSON.parse(JSON.stringify(resourceState));
      let newSection = JSON.parse(JSON.stringify(exposureSectionState));
      if (newSection.section?.length || newSection.entry?.length ||
        (newSection.text && newSection.text.status !== "empty")) {
        delete newSection.emptyReason;
      } else if (!newSection.emptyReason) {
        newSection.emptyReason = {
          "coding": [{
            "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
            "code": "notstarted", "display": "Not Started"
          }]
        }
      }
      if (!newSectionArray || !Array.isArray(newSectionArray) || newSectionArray.length === 0) {
        if (topSection === "Intervention") {
          newSectionArray = [
            {
              "title": "Intervention Description",
              "code": {
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "intervention-description",
                    "display": "Intervention Description"
                  }
                ]
              },
              "emptyReason": {
                "coding": [
                  {
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }
                ]
              }
            },
            {
              "title": "Intervention Group",
              "code": {
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "intervention-group",
                    "display": "Intervention Group"
                  }
                ]
              },
              "emptyReason": {
                "coding": [
                  {
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }
                ]
              }
            }
          ];
        }
        if (topSection === "Comparator") {
          newSectionArray = [
            {
              "title": "Comparator Description",
              "code": {
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "comparator-description",
                    "display": "Comparator Description"
                  }
                ]
              },
              "emptyReason": {
                "coding": [
                  {
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }
                ]
              }
            },
            {
              "title": "Comparator Group",
              "code": {
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "comparator-group",
                    "display": "Comparator Group"
                  }
                ]
              },
              "emptyReason": {
                "coding": [
                  {
                    "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
                    "code": "notstarted",
                    "display": "Not Started"
                  }
                ]
              }
            }
          ];
        }
      }
      if (newSectionArray.length === 3) {
        newSectionArray[2] = newSection;
      } else if (newSectionArray.length === 2) {
        newSectionArray.push(newSection);
      } else {
        alert("newSectionArray should have length 2 or 3 but has length of " + newSectionArray.length);
      }
      setResourceState(newSectionArray);
    }
  }, [exposureSectionState]);

  return <>
    <div style={{ marginLeft: "24px" }}>
      <NarrativeEntry elementName='text' fieldLabel={topSection + ' Subgroups Section Summary'}
        generateSummaryFromArrayFunction={generateSubgroupsSummary}
        sectionEntry={exposureSectionState.entry} globalContext={globalContext}
        resourceType="Group" resourceDictionary={resourceDictionary}
        startingValue={exposureSectionState.text} setResourceState={setExposureSectionState} />
      <h3>Subgroups Section Content: Create or identify the Group Resource(s) (ExposureGroup or ComparatorGroup Profile) for the {topSection}.</h3>
      <div style={{ marginLeft: "24px" }}>
        {referencesEntryState && <DataEntry asArray={true} datatype='Reference' elementName='subgroupReferences'
          fieldLabel='Group Resource(s) (ExposureGroup or ComparatorGroup Profile)'
          startingValue={referencesEntryState.subgroupReferences} referencedResourceTypes={['Group']}
          startingResourceType="Group" selectProfile={['ExposureGroup', 'ComparatorGroup']}
          startCollapsed enableCreation={true}
          addElementValuesArray={interventionSubpopulationsGroupValuesState}
          setResourceState={setReferencesEntryState} setSourceJsonState={setSourceJsonState}
          globalContext={globalContext}
          fullResourceState={fullResourceState} />}
      </div>
      <br />
      {((!exposureSectionState.section || exposureSectionState.section.length === 0) &&
        (!exposureSectionState.entry || exposureSectionState.entry.length === 0) &&
        (!exposureSectionState.text || exposureSectionState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={exposureSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setExposureSectionState} />
          </div>
        </>}
    </div>
  </>
}

const SingleMeasureReportEntryDataEntry = ({ startingValue, setResourceState, setProfile, fieldLabel,
  groupReferences, compositionId, compositionTitle }) => {
  let startingEntryInstance = { "reference": "", "type": "Composition", "display": "" };
  if (Array.isArray(startingValue) && startingValue[0]) {
    if (startingValue[0].reference) {
      startingEntryInstance.reference = startingValue[0].reference;
    }
    if (startingValue[0].display) {
      startingEntryInstance.display = startingValue[0].display;
    }
  }
  let startingRelatesTo = [
    {
      "type": "part-of",
      "resourceReference": {
        "reference": "Composition/" + compositionId,
        "type": "Composition",
        "display": compositionTitle
      }
    }
  ];
  if (groupReferences) {
    if (groupReferences.totalGroup) {
      startingRelatesTo.push({
        "type": "depends-on",
        "label": "Total Group",
        "resourceReference": groupReferences.totalGroup
      });
    }
    if (groupReferences.interventionGroup) {
      startingRelatesTo.push({
        "type": "depends-on",
        "label": "Intervention Group",
        "resourceReference": groupReferences.interventionGroup
      });
    }
    if (groupReferences.comparatorGroup) {
      startingRelatesTo.push({
        "type": "depends-on",
        "label": "Comparator Group",
        "resourceReference": groupReferences.comparatorGroup
      });
    }
    if (groupReferences.groupAssignment) {
      startingRelatesTo.push({
        "type": "composed-of",
        "classifier": [
          {
            "coding": [
              {
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "GroupAssignment",
                "display": "GroupAssignment"
              }
            ]
          }
        ],
        "resourceReference": groupReferences.groupAssignment
      });
    }
  }

  const [entryInstanceState, setEntryInstanceState] = useState({ "instance": startingEntryInstance });

  useEffect(() => {
    if (entryInstanceState.instance && JSON.stringify(entryInstanceState.instance) !== startingEntryInstance) {
      setResourceState(prevState => {
        return {
          ...prevState,
          "entry": [JSON.parse(JSON.stringify(entryInstanceState.instance))]
        };
      });
    }
  }, [entryInstanceState]);

  return <DataEntry datatype='Reference' elementName='instance' fieldLabel={fieldLabel}
    startingValue={entryInstanceState.instance} referencedResourceTypes={['Composition']}
    startingResourceType="Composition" setProfile={setProfile}
    addElementValues={{
      relatesTo: startingRelatesTo || ""
    }}
    startCollapsed enableCreation={true}
    setResourceState={setEntryInstanceState} />
}

const OutcomesEntryDataEntry = ({ startingValue, setResourceState,
  groupReferences, compositionId, compositionTitle }) => {
  let startingEntryInstance0 = { "reference": "", "type": "Composition", "display": "" };
  let startingEntryInstance1 = { "reference": "", "type": "Composition", "display": "" };
  if (Array.isArray(startingValue)) {
    if (startingValue[0]) {
      if (startingValue[0].reference) {
        startingEntryInstance0.reference = startingValue[0].reference;
      }
      if (startingValue[0].display) {
        startingEntryInstance0.display = startingValue[0].display;
      }
    }
    if (startingValue[1]) {
      if (startingValue[0].reference) {
        startingEntryInstance1.reference = startingValue[0].reference;
      }
      if (startingValue[0].display) {
        startingEntryInstance1.display = startingValue[0].display;
      }
    }
  }
  let startingRelatesTo = [
    {
      "type": "part-of",
      "resourceReference": {
        "reference": "Composition/" + compositionId,
        "type": "Composition",
        "display": compositionTitle
      }
    }
  ];
  if (groupReferences) {
    if (groupReferences.totalGroup) {
      startingRelatesTo.push({
        "type": "depends-on",
        "label": "Total Group",
        "resourceReference": groupReferences.totalGroup
      });
    }
    if (groupReferences.interventionGroup) {
      startingRelatesTo.push({
        "type": "depends-on",
        "label": "Intervention Group",
        "resourceReference": groupReferences.interventionGroup
      });
    }
    if (groupReferences.comparatorGroup) {
      startingRelatesTo.push({
        "type": "depends-on",
        "label": "Comparator Group",
        "resourceReference": groupReferences.comparatorGroup
      });
    }
    if (groupReferences.groupAssignment) {
      startingRelatesTo.push({
        "type": "composed-of",
        "classifier": [
          {
            "coding": [
              {
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "GroupAssignment",
                "display": "GroupAssignment"
              }
            ]
          }
        ],
        "resourceReference": groupReferences.groupAssignment
      });
    }
  }

  const [entryInstanceState, setEntryInstanceState] = useState({
    "instance": startingEntryInstance0,
    "secondInstance": startingEntryInstance1
  });

  useEffect(() => {
    if (entryInstanceState.instance && JSON.stringify(entryInstanceState.instance) !== startingEntryInstance0) {
      setResourceState(prevState => {
        let newEntry = [JSON.parse(JSON.stringify(entryInstanceState.instance))];
        if (entryInstanceState.secondInstance.reference || entryInstanceState.secondInstance.display) {
          newEntry.push(JSON.parse(JSON.stringify(entryInstanceState.secondInstance)));
        }
        return {
          ...prevState,
          "entry": newEntry
        };
      });
    }
  }, [entryInstanceState]);

  return <div>
    <DataEntry datatype='Reference' elementName='instance'
      fieldLabel='Composition Resource (OutcomeMeasureReport Profile)'
      startingValue={entryInstanceState.instance} referencedResourceTypes={['Composition']}
      startingResourceType="Composition" setProfile='OutcomeMeasureReport'
      addElementValues={{
        relatesTo: startingRelatesTo || ""
      }}
      startCollapsed enableCreation={true}
      setResourceState={setEntryInstanceState} />
    <DataEntry datatype='Reference' elementName='secondInstance'
      fieldLabel='Composition Resource (SummaryOfFindings Profile)'
      startingValue={entryInstanceState.secondInstance} referencedResourceTypes={['Composition']}
      startingResourceType="Composition" setProfile='SummaryOfFindings'
      addElementValues={{
        relatesTo: startingRelatesTo || ""
      }}
      startCollapsed enableCreation={true}
      setResourceState={setEntryInstanceState} />
  </div>
}

const NarrativeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  generateSummaryFunction, generateSummaryFromArrayFunction,
  sectionEntry, sectionFocus, resourceType, resourceDictionary, globalContext }) => {
  let startingNarrative = { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" };
  if (startingValue) {
    if (startingValue.extension) { startingNarrative.extension = startingValue.extension; }
    if (startingValue.status) { startingNarrative.status = startingValue.status; }
    if (startingValue.div) { startingNarrative.div = startingValue.div; }
  }
  let startingDivTextValue = startingNarrative.div;
  if (startingDivTextValue.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
    startingDivTextValue = startingDivTextValue.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
    let sDTVLength = startingDivTextValue.length;
    if (startingDivTextValue.substring(sDTVLength - 6, sDTVLength) === "</div>") {
      startingDivTextValue = startingDivTextValue.substring(0, sDTVLength - 6);
    }
  }
  let startingDivValue = { "divText": startingDivTextValue };
  const [narrativeState, setNarrativeState] = useState(startingNarrative);
  const [narrativeDivState, setNarrativeDivState] = useState(startingDivValue);
  const [narrativeIsGeneratedState, setNarrativeIsGeneratedState] = useState(startingNarrative.status === "generated");
  const checkIfStatusAdditional = () => {
    return narrativeState.status === "additional";
  };
  const updateNarrativeStates = async () => {
    if (generateSummaryFunction) {
      let entryFoi = getFoiFromReference(sectionEntry || sectionFocus, resourceType, resourceDictionary);
      if (entryFoi) {
        let narrativeDivValue = await generateSummaryFunction(entryFoi, resourceDictionary, globalContext);
        if (narrativeDivValue.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
          narrativeDivValue = narrativeDivValue.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
          let sDTVLength = narrativeDivValue.length;
          if (narrativeDivValue.substring(sDTVLength - 6, sDTVLength) === "</div>") {
            narrativeDivValue = narrativeDivValue.substring(0, sDTVLength - 6);
          }
        }
        setNarrativeIsGeneratedState(true);
        setNarrativeDivState({ "divText": narrativeDivValue, "generated": true });
        setNarrativeState(prevState => { return { ...prevState, "status": "generated" }; });
      }
    } else if (generateSummaryFromArrayFunction) {
      let entryFoiList = getFoisFromReference(sectionEntry, resourceType, resourceDictionary);
      if (entryFoiList?.length) {
        let narrativeDivValue = await generateSummaryFromArrayFunction(entryFoiList, resourceDictionary, globalContext);
        if (narrativeDivValue.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
          narrativeDivValue = narrativeDivValue.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
          let sDTVLength = narrativeDivValue.length;
          if (narrativeDivValue.substring(sDTVLength - 6, sDTVLength) === "</div>") {
            narrativeDivValue = narrativeDivValue.substring(0, sDTVLength - 6);
          }
        }
        setNarrativeIsGeneratedState(true);
        setNarrativeDivState({ "divText": narrativeDivValue, "generated": true });
        setNarrativeState(prevState => { return { ...prevState, "status": "generated" }; });
      }
    }
  }

  useEffect((() => {
    if (Object.keys(narrativeState).length) {
      let newNarrative = {};
      if (narrativeState.extension) { newNarrative.extension = narrativeState.extension; }
      if (narrativeState.status) { newNarrative.status = narrativeState.status; }
      if (narrativeState.div) { newNarrative.div = narrativeState.div; }
      if (Object.keys(newNarrative).length === 0 ||
        (newNarrative.status === "empty" && !clearDivWrapper(newNarrative.div) && !newNarrative.extension)) {
        newNarrative = null;
      }
      handleChange(elementName, newNarrative, setResourceState);
    }
  }), [narrativeState]);

  useEffect((() => {
    if (startingDivValue.divText !== '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>") {
      if ((narrativeDivState.divText || narrativeDivState.divText === "0") &&
        narrativeDivState.divText !== '[No data]' && narrativeDivState.divText !== '[No data.]') {
        if (narrativeIsGeneratedState) {
          setNarrativeState(prevState => { return { ...prevState, "status": "generated", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
          setNarrativeIsGeneratedState(false);
        } else {
          setNarrativeState(prevState => { return { ...prevState, "status": "additional", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
        }
      } else {
        setNarrativeState(prevState => { return { ...prevState, "status": "empty", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
      }
    }
  }), [narrativeDivState]);

  return <div>
    <ButtonWithConfirmModal buttonContent="Generate Natural Language Summary"
      message="This will overwrite the manually edited summary with a generated summary."
      conditionForConfirmModal={checkIfStatusAdditional}
      functionIfConfirmed={updateNarrativeStates}
      disabled={((!sectionEntry || !sectionEntry[0] || (!sectionEntry[0].reference && !sectionEntry[0].identifier)) && !sectionFocus)} />
    <p style={{ marginBottom: "0px" }}><b>Natural language summary: </b>
      (status: {(narrativeState.status === "additional") ? "manually edited" : narrativeState.status})
    </p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype="xhtml" elementName='divText' fieldLabel={fieldLabel}
        startingValue={narrativeDivState.divText} setResourceState={setNarrativeDivState} />
    </div>
  </div>
})

const TopSectionDataEntry = ({ sectionCode, startingValue, resourceState, setResourceState, globalContext,
  sourceJsonState, setSourceJsonState }) => {
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let groupReferences = sourceJsonState.groupReferences;
  let startingTopSection = {
    "title": sectionCode + " Section",
    "code": { "text": sectionCode },
    "author": [],
    "focus": {},
    "text": { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" },
    "orderedBy": {},
    "entry": [],
    "emptyReason": {},
    "section": []
  };
  let startingPopulationEntry = null;
  let populationSubgroup = false;
  if (startingValue) {
    if (startingValue.title) { startingTopSection.title = startingValue.title; }
    if (startingValue.code) { startingTopSection.code = startingValue.code; }
    if (startingValue.author) { startingTopSection.author = startingValue.author; }
    if (startingValue.focus) { startingTopSection.focus = startingValue.focus; }
    if (startingValue.text) { startingTopSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingTopSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) {
      startingTopSection.entry = startingValue.entry;
      if (sectionCode === "Population") {
        if (startingValue.entry.length !== 1) {
          alert("The Population section has more than one entry value. Only the first entry value will be kept if the content is edited.");
        }
        startingPopulationEntry = startingValue.entry[0];
      }
    }
    if (startingValue.emptyReason) { startingTopSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) {
      startingTopSection.section = startingValue.section;
      populationSubgroup = true;
    }
  } else {
    startingTopSection = {
      "title": sectionCode + " Section",
      "code": { "text": sectionCode }
    };
  }

  const [topSectionState, setTopSectionState] = useState(startingTopSection);
  const [populationEntryState, setPopulationEntryState] = useState({ "referenceInstance": startingPopulationEntry });
  const [populationSubgroupState, setPopulationSubgroupState] = useState(populationSubgroup);

  useEffect(() => {
    if (sectionCode === "Population" && populationEntryState.referenceInstance) {
      setTopSectionState(prevState => {
        return { ...prevState, entry: [JSON.parse(JSON.stringify(populationEntryState.referenceInstance))] };
      });
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState, setResourceState);
    }
  }, [populationEntryState])

  useEffect(() => {
    let newResource = JSON.parse(JSON.stringify(resourceState));
    let newSection = JSON.parse(JSON.stringify(topSectionState));
    if (newSection.section?.length || newSection.entry?.length ||
      (newSection.text && newSection.text.status !== "empty")) {
      delete newSection.emptyReason;
    } else if (!newSection.emptyReason) {
      newSection.emptyReason = {
        "coding": [{
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted", "display": "Not Started"
        }]
      }
    }
    if (sectionCode === "Population") {
      newResource.section[1] = newSection;
      if (newSection.section?.[0]?.entry?.length) {
        let newSubpopulationFoiList = newSection.section[0].entry.map(item => { return item.reference?.split("/")[1] });
        if (newSubpopulationFoiList?.length) {
          setSubpopulationFoiListState(newSubpopulationFoiList);
        }
      }
    } else if (sectionCode === "Intervention") {
      newResource.section[2] = newSection;
    } else if (sectionCode === "Comparator") {
      newResource.section[3] = newSection;
    } else if (sectionCode === "Research Study") {
      newResource.section[4] = newSection;
    } else if (sectionCode === "Baseline Measures") {
      newResource.section[6] = newSection;
    } else if (sectionCode === "Participant Flow") {
      newResource.section[7] = newSection;
    } else if (sectionCode === "Outcomes") {
      newResource.section[8] = newSection;
    }
    setResourceState(newResource);
  }, [topSectionState]);

  const [expandAuthorEntryState, setExpandAuthorEntryState] = useState(false);
  const [subpopulationFoiListState, setSubpopulationFoiListState] = useState(null);

  if (sectionCode === "Population") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
          generateSummaryFunction={generateGroupSummary}
          sectionEntry={topSectionState.entry} globalContext={globalContext}
          resourceType="Group" resourceDictionary={resourceDictionary}
          startingValue={topSectionState.text} setResourceState={setTopSectionState} />
        <br />
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
          {expandAuthorEntryState ? <>
            <b>Collapse Section Author Data Entry ▼</b>
          </> : <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </>}
        </span>
        <br />
        {expandAuthorEntryState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
            startCollapsed startEmptyArrayClosed enableCreation={true}
            startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            setResourceState={setTopSectionState} />
        </>}
        <h3>{sectionCode} Section Content: Create or identify the Group Resource (StudyGroup Profile) for the Population.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='Reference' elementName='referenceInstance' fieldLabel='Group Resource (StudyGroup Profile)'
            startingValue={populationEntryState.referenceInstance} referencedResourceTypes={['Group']}
            startingResourceType="Group" setProfile="StudyGroup"
            startCollapsed enableCreation={true}
            setResourceState={setPopulationEntryState} setSourceJsonState={setSourceJsonState}
            globalContext={globalContext}
            fullResourceState={resourceState} />
        </div>
        <br />
        {((!topSectionState.section || topSectionState.section.length === 0) &&
          (!topSectionState.entry || topSectionState.entry.length === 0) &&
          (!topSectionState.text || topSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setTopSectionState} />
            </div>
          </>}
        <br />
        {!populationSubgroupState && <div>
          <span onClick={() => { setPopulationSubgroupState(true); }} >
            <p><b>Click to add Population Subgroups.</b></p>
          </span>
        </div>}
        {populationSubgroupState && <div>
          <DocumentSectionEntry sectionArrayIndex={0}
            fieldLabel="Population Subgroup"
            startingValue={topSectionState.section[0] || {
              "title": "Population Subgroups",
              "code": {
                "coding": [
                  {
                    "system": "https://fevir.net/resources/CodeSystem/179423",
                    "code": "subgroup",
                    "display": "Subgroup"
                  }
                ]
              },
              "entry": []
            }}
            fixedTitle="Population Subgroups" fixedCode={{
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "subgroup",
                  "display": "Subgroup"
                }
              ]
            }} editTextDiv={true}
            generateNarrativeFromEntryArrayFunction={generateSubgroupsSummary}
            entryFoiList={subpopulationFoiListState}
            editOrderedBy={true} editOrderedByStartCollapsed={true}
            entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
            entryStartCollapsed={true} entryReferencedResourceTypes={["Group"]}
            entryStartingResourceType="Group" editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
            emptyReasonStartCollapsed={true}
            emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
            setResourceState={setTopSectionState}
            compositionId={resourceState.id} compositionTitle={resourceState.title}
            resourceDictionary={resourceDictionary}
            setSourceJsonState={setSourceJsonState} globalContext={globalContext}
            resourceState={resourceState}
          />
        </div>}
      </div>
    </>
  } else if (sectionCode === "Intervention" || sectionCode === "Comparator") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel={sectionCode + ' Section Summary'}
          startingValue={topSectionState.text} setResourceState={setTopSectionState} />
        <br />
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
          {expandAuthorEntryState ? <>
            <b>Collapse Section Author Data Entry ▼</b>
          </> : <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </>}
        </span>
        <br />
        {expandAuthorEntryState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
            startCollapsed startEmptyArrayClosed enableCreation={true}
            startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            setResourceState={setTopSectionState} />
        </>}
        <ExposureSectionsDataEntry startingValue={topSectionState.section}
          setResourceState={setTopSectionState} globalContext={globalContext}
          sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
          fullResourceState={resourceState} setFullResourceState={setResourceState}
          topSection={sectionCode} />
      </div>
    </>
  } else if (sectionCode === "Research Study") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
          generateSummaryFunction={generateResearchStudySummary}
          sectionEntry={topSectionState.entry} globalContext={globalContext}
          resourceType="ResearchStudy" resourceDictionary={resourceDictionary}
          startingValue={topSectionState.text} setResourceState={setTopSectionState} />
        <br />
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
          {expandAuthorEntryState ? <>
            <b>Collapse Section Author Data Entry ▼</b>
          </> : <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </>}
        </span>
        <br />
        {expandAuthorEntryState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
            startCollapsed startEmptyArrayClosed enableCreation={true}
            startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            setResourceState={setTopSectionState} />
        </>}
        <h3>{sectionCode} Section Content: Create or identify the ResearchStudy Resource containing the Study Design information.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='ResearchStudy Resource'
            startingValue={topSectionState.entry} referencedResourceTypes={['ResearchStudy']}
            startingResourceType="ResearchStudy"
            startCollapsed startEmptyArrayClosed enableCreation={true}
            setResourceState={setTopSectionState} />
        </div>
        <br />
        {((!topSectionState.section || topSectionState.section.length === 0) &&
          (!topSectionState.entry || topSectionState.entry.length === 0) &&
          (!topSectionState.text || topSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setTopSectionState} />
            </div>
          </>}
      </div>
    </>
  } else if (sectionCode === "Baseline Measures") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
          generateSummaryFunction={generateCompositionSummary}
          sectionEntry={topSectionState.entry} globalContext={globalContext}
          resourceType="Composition" resourceDictionary={resourceDictionary}
          startingValue={topSectionState.text} setResourceState={setTopSectionState} />
        <br />
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
          {expandAuthorEntryState ? <>
            <b>Collapse Section Author Data Entry ▼</b>
          </> : <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </>}
        </span>
        <br />
        {expandAuthorEntryState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
            startCollapsed startEmptyArrayClosed enableCreation={true}
            startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            setResourceState={setTopSectionState} />
        </>}
        <h3>{sectionCode} Section Content: Create or identify the Composition Resource containing the Baseline Measure Report.</h3>
        <div style={{ marginLeft: "24px" }}>
          <SingleMeasureReportEntryDataEntry startingValue={topSectionState.entry} setResourceState={setTopSectionState}
            setProfile="BaselineMeasureReport" fieldLabel='Composition Resource (BaselineMeasureReport Profile)'
            groupReferences={groupReferences} compositionId={resourceState.id} compositionTitle={resourceState.title || resourceState.name} />
        </div>
        <br />
        {((!topSectionState.section || topSectionState.section.length === 0) &&
          (!topSectionState.entry || topSectionState.entry.length === 0) &&
          (!topSectionState.text || topSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setTopSectionState} />
            </div>
          </>}
      </div>
    </>
  } else if (sectionCode === "Participant Flow") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
          generateSummaryFunction={generateCompositionSummary}
          sectionEntry={topSectionState.entry} globalContext={globalContext}
          resourceType="Composition" resourceDictionary={resourceDictionary}
          startingValue={topSectionState.text} setResourceState={setTopSectionState} />
        <br />
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
          {expandAuthorEntryState ? <>
            <b>Collapse Section Author Data Entry ▼</b>
          </> : <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </>}
        </span>
        <br />
        {expandAuthorEntryState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
            startCollapsed startEmptyArrayClosed enableCreation={true}
            startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            setResourceState={setTopSectionState} />
        </>}
        <h3>{sectionCode} Section Content: Create or identify the Composition Resource containing the Participant Flow Report.</h3>
        <div style={{ marginLeft: "24px" }}>
          <SingleMeasureReportEntryDataEntry startingValue={topSectionState.entry} setResourceState={setTopSectionState}
            setProfile="ParticipantFlowReport" fieldLabel='Composition Resource (ParticipantFlowReport Profile)'
            groupReferences={groupReferences} compositionId={resourceState.id} compositionTitle={resourceState.title || resourceState.name} />
        </div>
        <br />
        {((!topSectionState.section || topSectionState.section.length === 0) &&
          (!topSectionState.entry || topSectionState.entry.length === 0) &&
          (!topSectionState.text || topSectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setTopSectionState} />
            </div>
          </>}
      </div>
    </>
  } else if (sectionCode === "Outcomes") {
    return <>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
          generateSummaryFunction={generateCompositionSummary}
          sectionEntry={topSectionState.entry} globalContext={globalContext}
          resourceType="Composition" resourceDictionary={resourceDictionary}
          startingValue={topSectionState.text} setResourceState={setTopSectionState} />
        <br />
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
          {expandAuthorEntryState ? <>
            <b>Collapse Section Author Data Entry ▼</b>
          </> : <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </>}
        </span>
        <br />
        {expandAuthorEntryState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
            startCollapsed startEmptyArrayClosed enableCreation={true}
            startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            setResourceState={setTopSectionState} />
        </>}
        <h3>{sectionCode} Section Content: Create or identify the Composition Resource(s) containing the Outcome Measure Report and Summary of Findings Report.</h3>
        <div style={{ marginLeft: "24px" }}>
          <OutcomesEntryDataEntry startingValue={topSectionState.entry} setResourceState={setTopSectionState}
            groupReferences={groupReferences} compositionId={resourceState.id} compositionTitle={resourceState.title || resourceState.name} />
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
        </div>
      </div>
    </>
  } else {
    return <>
      <p>Unrecognized section detected with sectionCode:</p>
      <p>{sectionCode}</p>
    </>
  }

}

const reorderSections = (sectionArray) => {
  let emptyIntroduction = {
    "title": "Introduction",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "introduction",
        display: "Introduction"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyPopulation = {
    "title": "Population",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "population",
        display: "Population"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyIntervention = {
    "title": "Intervention",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "intervention",
        display: "Intervention"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyComparator = {
    "title": "Comparator",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "comparator",
        display: "Comparator"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyResearchStudy = {
    "title": "Research Study",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "research-study",
        display: "Research Study"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyMethods = {
    "title": "Methods Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "methods",
        display: "Methods"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyBaselineMeasures = {
    "title": "Baseline Measures",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "baseline-measures",
        display: "Baseline Measures"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyParticipantFlow = {
    "title": "Participant Flow",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "participant-flow",
        display: "Participant Flow"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyOutcomes = {
    "title": "Outcomes",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "outcome-measures",
        display: "Outcome Measures"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyDiscussion = {
    "title": "Discussion Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "discussion",
        display: "Discussion"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyReferences = {
    "title": "References Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "references",
        display: "References"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyCompetingInterests = {
    "title": "Competing Interests Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "competing-interests",
        display: "Competing Interests"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAcknowledgements = {
    "title": "Acknowledgements Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "acknowledgements",
        display: "Acknowledgements"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let emptyAppendices = {
    "title": "Appendices Section",
    "code": {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "appendices",
        display: "Appendices"
      }]
    },
    "emptyReason": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }
      ]
    }
  };
  let sectionDictionary = {
    introduction: emptyIntroduction,
    population: emptyPopulation,
    intervention: emptyIntervention,
    comparator: emptyComparator,
    researchStudy: emptyResearchStudy,
    methods: emptyMethods,
    baselineMeasures: emptyBaselineMeasures,
    participantFlow: emptyParticipantFlow,
    outcomeMeasures: emptyOutcomes,
    discussion: emptyDiscussion,
    references: emptyReferences,
    competingInterests: emptyCompetingInterests,
    acknowledgements: emptyAcknowledgements,
    appendices: emptyAppendices,
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
    if (sectionCode === "introduction" || sectionCode === "Introduction") {
      sectionDictionary.introduction = section;
    } else if (sectionCode === "population" || sectionCode === "Population") {
      sectionDictionary.population = section;
    } else if (sectionCode === "intervention" || sectionCode === "Intervention") {
      sectionDictionary.intervention = section;
    } else if (sectionCode === "comparator" || sectionCode === "Comparator") {
      sectionDictionary.comparator = section;
    } else if (sectionCode === "research-study" || sectionCode === "Research Study") {
      sectionDictionary.researchStudy = section;
    } else if (sectionCode === "methods" || sectionCode === "Methods") {
      sectionDictionary.methods = section;
    } else if (sectionCode === "baseline-measures" || sectionCode === "Baseline Measures") {
      sectionDictionary.baselineMeasures = section;
    } else if (sectionCode === "participant-flow" || sectionCode === "Participant Flow") {
      sectionDictionary.participantFlow = section;
    } else if (sectionCode === "outcome-measures" || sectionCode === "Outcome Measures" || sectionCode === "Outcomes") {
      sectionDictionary.outcomeMeasures = section;
    } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
      sectionDictionary.discussion = section;
    } else if (sectionCode === "references" || sectionCode === "References") {
      sectionDictionary.references = section;
    } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
      sectionDictionary.competingInterests = section;
    } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
      sectionDictionary.acknowledgements = section;
    } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
      sectionDictionary.appendices = section;
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  let reorderedSectionArray = [
    sectionDictionary.introduction,
    sectionDictionary.population,
    sectionDictionary.intervention,
    sectionDictionary.comparator,
    sectionDictionary.researchStudy,
    sectionDictionary.methods,
    sectionDictionary.baselineMeasures,
    sectionDictionary.participantFlow,
    sectionDictionary.outcomeMeasures,
    sectionDictionary.discussion,
    sectionDictionary.references,
    sectionDictionary.competingInterests,
    sectionDictionary.acknowledgements,
    sectionDictionary.appendices
  ].concat(sectionDictionary.additionalSections);
  return reorderedSectionArray;
};

const ComparativeEvidenceReportAuthoringTool = ({ fhirJson, formInputsStateRef, citationSummary, citationJson,
  classificationsArrayState, classificationsLoadedState, globalContext, previousVersionLoaded }) => {
  /*
      const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
      "url", "identifier", "version", "status", "type", "category", "subject", "encounter",
      "date", "useContext", "author", "name", "title", "note", "attester", "custodian", "relatesTo",
      "event", "section"]
      */
  if (Array.isArray(fhirJson.author) && fhirJson.author.length === 1 && fhirJson.author[0].name) {
    fhirJson.author[0].display = fhirJson.author[0].name;
    delete fhirJson.author[0].name;
  }

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "status": fhirJson.status,
    "type": fhirJson.type || {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "ComparativeEvidenceReport",
        display: "ComparativeEvidenceReport"
      }],
      text: 'Comparative Evidence Report'
    }, "category": fhirJson.category, "subject": fhirJson.subject, "encounter": fhirJson.encounter,
    "date": fhirJson.date, "useContext": fhirJson.useContext, "author": fhirJson.author,
    "name": fhirJson.name, "title": fhirJson.title, "note": fhirJson.note, "attester": fhirJson.attester,
    "custodian": fhirJson.custodian, "relatesTo": fhirJson.relatesTo, "event": fhirJson.event,
    "section": reorderSections(fhirJson.section), "newClassifications": null
  });

  useImperativeHandle(formInputsStateRef, () => ({
    compositionDocumentState: resourceState
  }), [resourceState]);

  const [sourceJsonState, setSourceJsonState] = useState({});

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded || !sourceJsonState.loaded2) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState, setResourceState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  useEffect(() => {
    setSourceJsonState(prevState => { return { ...prevState, loaded2: false } });
  }, [resourceState.section]);

  return <>{sourceJsonState.loaded ?
    <div style={{ marginTop: "12px" }}>
      <div>
        {resourceState.section ? <>
          <EditIntroduction sectionIndex={0} resourceState={resourceState} setResourceState={setResourceState} />
          <h3 id="population">Population</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Population"
              startingValue={resourceState.section[1] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState} />
          </div>
          <br />
          <h3 id="intervention">Intervention</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Intervention"
              startingValue={resourceState.section[2] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            />
          </div>
          <h3 id="comparator">Comparator</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Comparator"
              startingValue={resourceState.section[3] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState} />
          </div>
          <br />
          <h3 id="research-study">Research Study</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Research Study"
              startingValue={resourceState.section[4] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} />
          </div>
          <EditMethods sectionIndex={5} resourceState={resourceState} setResourceState={setResourceState} />
          <br />
          <h3 id="baseline-measures">Baseline Measures</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Baseline Measures"
              startingValue={resourceState.section[6] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} />
          </div>
          <br />
          <h3 id="participant-flow">Participant Flow</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Participant Flow"
              startingValue={resourceState.section[7] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} />
          </div>
          <br />
          <h3 id="outcomes">Outcomes</h3>
          <div style={{ marginLeft: "24px" }}>
            <TopSectionDataEntry sectionCode="Outcomes"
              startingValue={resourceState.section[8] || null}
              globalContext={globalContext}
              resourceState={resourceState} setResourceState={setResourceState}
              sourceJsonState={sourceJsonState} />
          </div>
          <br />
          <EditDiscussion sectionIndex={9} resourceState={resourceState} setResourceState={setResourceState} />
          <br />
          <EditReferences sectionIndex={10} previousVersionLoaded={previousVersionLoaded}
            resourceState={resourceState} setResourceState={setResourceState} />
          <br />
          <EditCompetingInterests sectionIndex={11} resourceState={resourceState} setResourceState={setResourceState} />
          <br />
          <EditAcknowledgements sectionIndex={12} resourceState={resourceState} setResourceState={setResourceState} />
          <br />
          <EditAppendices sectionIndex={13} resourceState={resourceState} setResourceState={setResourceState} />
        </>
          :
          <>Section element is missing in the JSON.</>
        }
        <h3 id="how-to-cite">How to Cite</h3>
        <div style={{ marginLeft: "24px" }}>
          <DisplayHowToCite citationSummary={citationSummary}
            citationJson={citationJson} />
        </div>
        <h3 id="metadata">Metadata</h3>
        <div style={{ marginLeft: "24px" }}>
          <EvidenceReportMetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
        </div>
        <h3 id="associated-resources">Associated Resources</h3>
        <div style={{ marginLeft: "24px" }}>
          <AssociatedResourcesDisplay fhirJson={fhirJson} />
        </div>
        <h3 id="classifiers">Classifiers</h3>
        <div style={{ marginLeft: "24px" }}>
          <div>
            <p>Add Classifiers:</p>
            <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
              fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
          </div>
          {(classificationsArrayState) && <div>
            <p>Existing Classifiers:</p>
            {classificationsLoadedState ?
              <DisplayClassifiers classificationsArray={classificationsArrayState} />
              :
              <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
            }
          </div>}
        </div>
        <h3 id="json-outline">JSON Outline</h3>
        <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
        <br /><br />
      </div>
    </div>
    :
    <div><p>Loading ...</p></div>}</>
};

export default ComparativeEvidenceReportAuthoringTool;