import React, { useState, useContext, useImperativeHandle, useEffect } from 'react';
import { AssociatedResourcesDisplay, SimpleResourceFieldViewer } from './ResourceFunctions';
import { DataEntry, DisplayHowToCite } from './DataEntryFormFunctions';
import { DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import { EvidenceReportMetadataPatternEdit } from './EvidenceReportMetadataDataEntry';
import FevirContext from './FevirContext';

let emptyReasonValueSet = [
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }
];

let emptyReasonCodeableConceptSet = [
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" }]
  },
  {
    "coding":
      [{ "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }]
  },
  {
    "coding":
      [{ "system": "https://fevir.net/resources/CodeSystem/179423", "code": "not-relevant", "display": "Not Relevant" }]
  }
];

const EditIntroduction = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState
}) => {
  return <>
    <h3 id="introduction">Introduction</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        fieldLabel="Introduction" sectionCode="section:https://fevir.net/resources/CodeSystem/179423#introduction"
        startingValue={resourceState.section[sectionIndex] || null}
        editTitle={true} fixedCode={{
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "introduction",
              "display": "Introduction"
            }
          ]
        }} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true} sectionDeletable={true} sectionInstanceDeletable={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};
const EditDiscussion = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  return <>
    <h3 id="discussion">Discussion</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        startingValue={resourceState.section[sectionIndex] || null}
        fieldLabel="Discussion"
        editTitle={true} fixedCode={{
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "discussion",
              "display": "Discussion"
            }
          ]
        }} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true} sectionDeletable={true} sectionInstanceDeletable={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};

const EditMethods = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  return <>
    <h3 id="methods">Methods</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        startingValue={resourceState.section[sectionIndex] || null}
        fieldLabel="Methods"
        editTitle={true} fixedCode={{
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "methods",
              "display": "Methods"
            }
          ]
        }} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true} sectionDeletable={true} sectionInstanceDeletable={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};

const EditReferences = ({ resourceState, setResourceState, sectionIndex, previousVersionLoaded,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  const globalContext = useContext(FevirContext);
  return <>
    <h3 id="references">References</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry createCitation={true} globalContext={globalContext}
        previousVersionLoaded={previousVersionLoaded} sectionArrayIndex={sectionIndex}
        fieldLabel="References"
        startingValue={resourceState.section[sectionIndex] || null}
        editTitle={true} fixedCode={{
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "references",
              "display": "References"
            }
          ]
        }} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true} entryReferencedResourceTypes={["Citation"]}
        entryStartingResourceType="Citation"
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true} sectionDeletable={true} sectionInstanceDeletable={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState} resourceState={resourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
    <br />
  </>
};

const EditCompetingInterests = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  return <>
    <h3 id="competing-interests">Competing Interests</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        startingValue={resourceState.section[sectionIndex] || null}
        fieldLabel="Competing Interests"
        editTitle={true} fixedCode={{
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "competing-interests",
              "display": "Competing Interests"
            }
          ]
        }} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true} sectionDeletable={true} sectionInstanceDeletable={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};

const EditAcknowledgements = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  return <>
    <h3 id="acknowledgements">Acknowledgements</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        fieldLabel="Acknowledgements"
        startingValue={resourceState.section[sectionIndex] || null}
        editTitle={true} fixedCode={{
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "acknowledgements",
              "display": "Acknowledgements"
            }
          ]
        }} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true} sectionDeletable={true} sectionInstanceDeletable={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};

const EditAppendices = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  return <>
    <h3 id="appendices">Appendices</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        fieldLabel="Appendices"
        startingValue={resourceState.section[sectionIndex] || null}
        editTitle={true} fixedCode={{
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/179423",
              "code": "appendices",
              "display": "Appendices"
            }
          ]
        }} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true} sectionDeletable={true} sectionInstanceDeletable={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};

const EvidenceReportPackageAuthoringTool = ({ fhirJson, formInputsStateRef, citationSummary, citationJson,
  classificationsArrayState, classificationsLoadedState, previousVersionLoaded }) => {
  /*
    const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
      "url", "identifier", "version", "status", "type", "category", "subject", "encounter",
      "date", "useContext", "author", "name", "title", "note", "attester", "custodian", "relatesTo",
      "event", "section"]
  */
  if (Array.isArray(fhirJson.author) && fhirJson.author.length === 1 && fhirJson.author[0].name) {
    fhirJson.author[0].display = fhirJson.author[0].name;
    delete fhirJson.author[0].name;
  }

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "status": fhirJson.status,
    "type": fhirJson.type || {
      coding: [{
        system: "https://fevir.net/resources/CodeSystem/179423",
        code: "EvidenceReportPackage",
        display: "EvidenceReportPackage"
      }]
    }, "category": fhirJson.category, "subject": fhirJson.subject, "encounter": fhirJson.encounter,
    "date": fhirJson.date, "useContext": fhirJson.useContext, "author": fhirJson.author,
    "name": fhirJson.name, "title": fhirJson.title, "note": fhirJson.note, "attester": fhirJson.attester,
    "custodian": fhirJson.custodian, "relatesTo": fhirJson.relatesTo, "event": fhirJson.event,
    "section": fhirJson.section, "newClassifications": null
  });

  useImperativeHandle(formInputsStateRef, () => ({
    compositionDocumentState: resourceState
  }), [resourceState]);

  return <div style={{ marginTop: "12px" }}>
    <div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportMetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      {resourceState.section?.length > 6 ? <>
        <EditIntroduction sectionIndex={0} resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditDiscussion sectionIndex={1} resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditMethods sectionIndex={2} resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditReferences sectionIndex={3} previousVersionLoaded={previousVersionLoaded}
          resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditCompetingInterests sectionIndex={4} resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditAcknowledgements sectionIndex={5} resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditAppendices sectionIndex={6} resourceState={resourceState} setResourceState={setResourceState} />
      </>
        :
        <>Section element is missing in the JSON, or missing 7 instances.</>
      }
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
};

export {
  EvidenceReportPackageAuthoringTool, EditIntroduction, EditDiscussion, EditMethods,
  EditReferences, EditCompetingInterests, EditAcknowledgements, EditAppendices
};