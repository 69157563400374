import React, { useState, useEffect, memo } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { DisplayFromFHIR } from './ResourceFunctions';
import { DataEntry, CodeEntry } from './DataEntryFormFunctions';
import { ButtonWithConfirmModal } from './ConfirmModal';

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } })
}

const ExpandToAddOrEdit = ({ startingValue, setStartCollapsedState }) => {
  return <span className={"unselectable"} style={{ cursor: "pointer" }}
    onClick={() => { setStartCollapsedState(false) }}>
    {startingValue ? <>✎ Edit</> : <>➕ Add</>}
  </span>
}

const EditTextFunction = ({ functionid, name, placeholder, optionsList, value, setState }) => {
  if (functionid === "Dropdown") {
    return <Dropdown name={name} placeholder={placeholder}
      search={true}
      closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
      style={{ minWidth: "50%", width: "100%" }}
      options={optionsList.map((code, codeIndex) => { return { "key": codeIndex, "value": code, "text": code } })}
      value={value}
      onChange={(e, data) => {
        setState({ "divText": data.value });
      }}
    />
  }
}

const NarrativeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed,
  editTextStatus, editTextDiv, emptyTextDivValue, editTextFunction, generateNarrativeFromSingleEntryFunction,
  generateNarrativeFromEntryArrayFunction,
  generateSummaryFunction, entryFoi, entryFoiList, resourceDictionary, globalContext,
  path, adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  let startingNarrative = { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" };
  if (startingValue) {
    if (startingValue.extension) { startingNarrative.extension = startingValue.extension; }
    if (startingValue.status) { startingNarrative.status = startingValue.status; }
    if (startingValue.div) { startingNarrative.div = startingValue.div; }
  }
  let startingDivTextValue = startingNarrative.div;
  if (startingDivTextValue.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
    startingDivTextValue = startingDivTextValue.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
    let sDTVLength = startingDivTextValue.length;
    if (startingDivTextValue.substring(sDTVLength - 6, sDTVLength) === "</div>") {
      startingDivTextValue = startingDivTextValue.substring(0, sDTVLength - 6);
    }
  }

  let startingDivValue = { "divText": startingDivTextValue };
  const [narrativeState, setNarrativeState] = useState(startingNarrative);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);
  const [narrativeDivState, setNarrativeDivState] = useState(startingDivValue);
  const [editTextFunctionState, setEditTextFunctionState] = useState(startingDivValue);
  const [narrativeIsGeneratedState, setNarrativeIsGeneratedState] = useState(startingNarrative.status === "generated");
  const checkIfStatusAdditional = () => {
    return narrativeState.status === "additional";
  };

  useEffect((() => {
    if (Object.keys(narrativeState).length) {
      let newNarrative = {};
      if (narrativeState.extension) { newNarrative.extension = narrativeState.extension; }
      if (narrativeState.status) { newNarrative.status = narrativeState.status; }
      if (narrativeState.div) { newNarrative.div = narrativeState.div; }
      if (Object.keys(newNarrative).length === 0 ||
        (newNarrative.status === "empty" && !newNarrative.div && !newNarrative.extension)) {
        newNarrative = null;
      }
      handleChange(elementName, newNarrative, setResourceState);
    }
  }), [narrativeState]);

  useEffect((() => {
    if (startingDivValue.divText !== '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>") {
      if ((narrativeDivState.divText || narrativeDivState.divText === "0") &&
        narrativeDivState.divText !== '[No data]' &&
        narrativeDivState.divText !== '[No data.]' &&
        narrativeDivState.divText !== emptyTextDivValue) {
        if (narrativeIsGeneratedState) {
          setNarrativeState(prevState => { return { ...prevState, "status": "generated", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
          setNarrativeIsGeneratedState(false);
        } else {
          setNarrativeState(prevState => { return { ...prevState, "status": "additional", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
        }
      } else {
        setNarrativeState(prevState => { return { ...prevState, "status": "empty", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + (narrativeDivState.divText || "[No data.]") + "</div>" } });
      }
    }
  }), [narrativeDivState]);

  useEffect((() => {
    if (editTextFunction && editTextFunctionState.divText !== narrativeDivState.divText) {
      setNarrativeDivState({ "divText": editTextFunctionState.divText });
    }
  }), [editTextFunctionState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR xhtml={startingNarrative.div} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    if (editTextStatus) {
      return <div>
        <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
        <div style={{ marginLeft: "24px" }}>
          <CodeEntry elementName='status' fieldLabel='Status' togglable={true}
            allowedValues={['generated', 'extensions', 'additional', 'empty']}
            startingValue={narrativeState.status} setResourceState={setNarrativeState} />
          {editTextDiv ?
            <>{editTextFunction ?
              <EditTextFunction functionid={editTextFunction.functionid} name={editTextFunction.name}
                placeholder={editTextFunction.placeholder} optionsList={editTextFunction.optionsList}
                value={editTextFunction.value} setState={setEditTextFunctionState} />
              :
              <DataEntry datatype="xhtml" elementName='divText' fieldLabel='Narrative Summary'
                startingValue={narrativeDivState.divText} setResourceState={setNarrativeDivState}
                path={path}
                adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            }</>
            :
            <DisplayFromFHIR xhtml={narrativeDivState.divText} />}
        </div>
      </div>
    } else {
      const runFunctionForGenerateSummaryFunction = async () => {
        if (entryFoi) {
          let narrativeDivValue = await generateSummaryFunction(entryFoi, resourceDictionary, globalContext);
          if (narrativeDivValue.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
            narrativeDivValue = narrativeDivValue.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
            let sDTVLength = narrativeDivValue.length;
            if (narrativeDivValue.substring(sDTVLength - 6, sDTVLength) === "</div>") {
              narrativeDivValue = narrativeDivValue.substring(0, sDTVLength - 6);
            }
          }
          setNarrativeIsGeneratedState(true);
          setNarrativeDivState({ "divText": narrativeDivValue, "generated": true });
          setNarrativeState(prevState => { return { ...prevState, "status": "generated" }; });
        }
      };
      const runFunctionForGenerateNarrativeFromSingleEntryFunction = () => {
        if (entryFoi) {
          let narrativeSummary = generateNarrativeFromSingleEntryFunction(resourceDictionary[entryFoi]);
          let divTextValue = narrativeSummary.div;
          if (divTextValue.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
            divTextValue = divTextValue.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
            let sDTVLength = divTextValue.length;
            if (divTextValue.substring(sDTVLength - 6, sDTVLength) === "</div>") {
              divTextValue = divTextValue.substring(0, sDTVLength - 6);
            }
          }
          if (narrativeSummary.status === "generated") {
            setNarrativeIsGeneratedState(true);
          }
          setNarrativeDivState({ "divText": divTextValue, "generated": true });
          setNarrativeState(prevState => {
            return {
              ...prevState, "status": narrativeSummary.status,
              "div": narrativeSummary.div
            };
          });
        }
      };
      const runFunctionForGenerateNarrativeFromEntryArrayFunction = async () => {
        if (entryFoiList) {
          let divTextValue = await generateNarrativeFromEntryArrayFunction(entryFoiList, resourceDictionary, globalContext);
          let narrativeSummary = { "status": "generated", "div": divTextValue };
          if (divTextValue.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
            divTextValue = divTextValue.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
            let sDTVLength = divTextValue.length;
            if (divTextValue.substring(sDTVLength - 6, sDTVLength) === "</div>") {
              divTextValue = divTextValue.substring(0, sDTVLength - 6);
            }
          }
          if (divTextValue === "[No data.]" || divTextValue === "[No data]" || divTextValue === "") {
            narrativeSummary.status = "empty";
          }
          if (narrativeSummary.status === "generated") {
            setNarrativeIsGeneratedState(true);
          }
          setNarrativeDivState({ "divText": divTextValue, "generated": true });
          setNarrativeState(prevState => {
            return {
              ...prevState, "status": narrativeSummary.status,
              "div": narrativeSummary.div
            };
          });
        }
      }
      return <div>
        {generateSummaryFunction && <ButtonWithConfirmModal buttonContent="Generate Natural Language Summary"
          message="This will overwrite the manually edited summary with a generated summary."
          conditionForConfirmModal={checkIfStatusAdditional}
          functionIfConfirmed={runFunctionForGenerateSummaryFunction}
          disabled={!entryFoi} />}
        {generateNarrativeFromSingleEntryFunction && <ButtonWithConfirmModal buttonContent="Generate Natural Language Summary"
          message="This will overwrite the manually edited summary with a generated summary."
          conditionForConfirmModal={checkIfStatusAdditional}
          functionIfConfirmed={runFunctionForGenerateNarrativeFromSingleEntryFunction}
          disabled={!entryFoi || !resourceDictionary || !resourceDictionary[entryFoi]}
        />}
        {generateNarrativeFromEntryArrayFunction && <ButtonWithConfirmModal buttonContent="Generate Natural Language Summary"
          message="This will overwrite the manually edited summary with a generated summary."
          conditionForConfirmModal={checkIfStatusAdditional}
          functionIfConfirmed={async () => { await runFunctionForGenerateNarrativeFromEntryArrayFunction() }}
          disabled={!entryFoiList || !resourceDictionary}
        />}
        <p style={{ marginBottom: "0px" }}>
          <b>Natural language summary: </b>
          (status: {narrativeState.status === "additional" ? "manually edited" : narrativeState.status})
        </p>
        <div style={{ marginLeft: "24px" }}>
          {editTextDiv ?
            <>{editTextFunction ?
              <EditTextFunction functionid={editTextFunction.functionid} name={editTextFunction.name}
                placeholder={editTextFunction.placeholder} optionsList={editTextFunction.optionsList}
                value={editTextFunction.value} setState={setEditTextFunctionState} />
              :
              <DataEntry datatype="xhtml" elementName='divText' fieldLabel={fieldLabel}
                startingValue={narrativeDivState.divText} setResourceState={setNarrativeDivState}
                path={path}
                adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            }</>
            :
            <DisplayFromFHIR xhtml={narrativeDivState.divText} />}
        </div>
      </div>
    }
  }
});

export { NarrativeEntry };