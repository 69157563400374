import React, { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Segment } from 'semantic-ui-react';
import scrollTo from './ScrollTo';
import { getCharacteristicDefinitionDisplay } from './EvidenceVariableCharacteristicDisplay';
import FevirContext from './FevirContext';

const bulletPoints = ['◉', '⦾', '■', '○', '♦', '◘', '•'];

let separatorCharacter = ": ";

const characteristicNavigationEntryDisplay = (characteristic) => {
    let pathString = "[Undefined Characteristic]";
    let characteristicDefinitionDisplay = getCharacteristicDefinitionDisplay(characteristic);
    if (characteristicDefinitionDisplay) {
        pathString = characteristicDefinitionDisplay;
    }
    let pathStringWithSymbol = pathString;
    if (characteristic.exclude) {
        pathStringWithSymbol = "🚫 " + pathStringWithSymbol;
    }
    return [pathString, pathStringWithSymbol];
}

const navigateToAnotherCharacteristic = async (scrollName, history, fhirEntryState, setFhirEntryState, resourceType, resourceId, 
    json, characteristic, display, path, pathIndexes, pathString, formInputsStateRef, changeFhirEntryState, submitUpdatedFhirResource,
    globalContext, changeFormState, addToast) => {
    let characteristicState;
    if (formInputsStateRef?.current) {
        characteristicState = formInputsStateRef.current.characteristicState;
    }
    let navigateAway = true;
    let submitResult;
    let submittingToServer = false;

    if (fhirEntryState.conceptDelete) {
        alert("Please click the 'Update' button before navigating away. Or refresh browser to discard changes.")
    } else {
        //history.push(`/resources/${resourceType}/${resourceId}#${concept.code},${pathIndexes.toString()}`);    //INCLUDES PATH SO IT CAN HANDLE DUPLICATE CODES, COMMENTED OUT FOR NOW
        if (fhirEntryState.editMode && characteristicState?.termChanged) {
            if (window.confirm("Do you want to save your change to criteria to the server? If not, please refresh this page to discard changes.")) {
                submitResult = await submitUpdatedFhirResource(null, "EvidenceVariable", resourceId, formInputsStateRef, fhirEntryState, 
                setFhirEntryState, globalContext, history, changeFormState, false, addToast);
                if (submitResult) {
                    submittingToServer = true;
                }
            } else {
                navigateAway = false;
            }
        }
        if ((fhirEntryState.editMode && characteristicState.termChanged) || fhirEntryState.newChildConcepts?.length > 0) {
            //console.log(pathIndexes); //pathIndexes where the user is navigating to
            if (pathIndexes || !(fhirEntryState.conceptPath?.length > 0)) {
                /*
                let alertMessage = "You made changes to the criteria, would you like to save your changes?";
                if (!(fhirEntryState.conceptPath?.length > 0)) {
                    if (fhirEntryState.newChildConcepts.length === 1) {
                        alertMessage = "You added a criteria, would you like to save your changes?";
                    } else {
                        alertMessage = "You added criterian, would you like to save your changes?";
                    }
                }
                if (window.confirm(alertMessage)) {
                    //fhirJson = builderUpdateCodeSystemJson(resourceId, fhirEntryState, formInputsStateRef);
                    navigateAway = false;
                    setFhirEntryState(prevState => { return {...prevState, submittingToServer: true, criteriaToNavigateTo: pathIndexes}}); //termToNavigateTo
                } else {
                    navigateAway = false;
                }*/
            }
        }
        if (navigateAway) {
            let characteristicCode = "";
            let characteristicDefinition = "";
            let characteristicSummary = "";
            let scrollToId = "";
            if (characteristic) {
                if (characteristic.code) {
                    characteristicCode = characteristic.code;
                    scrollToId = scrollName.toLowerCase() + "detail-navigation-code-" + characteristicCode;
                }
                if (characteristic.definition) {
                    characteristicDefinition = characteristic.definition;
                }
                if (characteristic.summary) {
                    characteristicSummary = characteristic.summary;
                }
            }

            if (pathIndexes?.length > 0) {
                history.push(`/resources/${resourceType}/${resourceId}#${pathIndexes.toString()}`);
                if (submittingToServer) {
                    //console.log("HASH LOADED FALSE")
                    //changeFormState(false, "hashLoaded");
                }
            } else {
                //characteristic = json;
                history.push(`/resources/${resourceType}/${resourceId}`);
                //history.push(`/resources/${resourceType}/${resourceId}#TOP`);
            }

            let classifierCodes = [];
            let notes = [];

            setFhirEntryState(prevState => { return { ...prevState, submittingToServer: submittingToServer, conceptName: display, conceptPath: path, conceptPathIndexes: pathIndexes, conceptPathString: pathString, characteristicSelected: characteristic, conceptDefinition: characteristicDefinition, conceptSummary: characteristicSummary, characteristicCode: characteristicCode, classifierCodes: classifierCodes, notes: notes, termChanged: false, newChildConcepts: [] }; });

            scrollTo("navigation" + scrollName.toLowerCase() + "DetailSegment", scrollToId, true);
            scrollTo("resourceTextViewSegment", "", true);
        }
    }
}

const NavigationCharacteristicSegment = ({ resourceId, fhirEntryState, setFhirEntryState, formInputsStateRef, changeFhirEntryState, submitUpdatedFhirResource, changeFormState, submitResult, addToast }) => {
    const globalContext = useContext(FevirContext);
    useEffect(async () => {
        if (fhirEntryState.initialScrolled !== true && fhirEntryState.editMode !== true) {
            let scrollToId = "characteristicdetail-navigation-code-" + fhirEntryState.conceptCode;
            if (fhirEntryState.conceptPathIndexes?.length === 1 && parseInt(fhirEntryState.conceptPathIndexes[0]) === 0) {
                scrollToId = ""; //So it doesn't scroll for the top-most element
            }
            scrollTo("navigationCharacteristicDetailSegment", scrollToId, true);
            setFhirEntryState(prevState => { return { ...prevState, initialScrolled: true } })
        }
    }, [fhirEntryState]);


    const history = useHistory();

    let json;
    if (fhirEntryState.fhirEntryString) {
        json = JSON.parse(fhirEntryState.fhirEntryString);
    }

    const NavigationCharacteristics = ({ parentJson, level, parentPathString, parentPath, parentPathIndexes, setFhirEntryState, formInputsStateRef }) => {
        let style = { paddingLeft: (level * 10).toString() + "px" };
        let separator = "";
        if (parentPathString) {
            separator = separatorCharacter;
        }
        return <>
            {(parentJson?.characteristic && Array.isArray(parentJson.characteristic)) && parentJson.characteristic.map((characteristic, characteristicIndex) => {
                //let display = characteristic.display;
                let tempPathString = characteristicNavigationEntryDisplay(characteristic);
                let display = tempPathString[1];
                if (display) {
                    let pathString = parentPathString + separator + display;
                    let path = [...parentPath, display];
                    let pathIndexes = [...parentPathIndexes, characteristicIndex];


                    let ewgAgreement = [];
                    for (let propertyIndex in characteristic.property) {
                        let property = characteristic.property[propertyIndex];
                        let valueString = property.valueString;
                        if (valueString) {
                            if (property.code === "approval") {
                                ewgAgreement.push(valueString);
                            }
                        }
                    }

                    let linkStyle = {};
                    let linkOnClick;

                    if (JSON.stringify(pathIndexes) === JSON.stringify(fhirEntryState.conceptPathIndexes)) { // || (fhirEntryState.termChanged && fhirEntryState.conceptName === characteristic.display)                        
                        linkStyle = { cursor: "default", color: "#000000", fontWeight: "bold" };
                    } else {
                        //console.log("TEST2");
                        linkOnClick = () => {
                            //console.log("TEST4");
                            //console.log(characteristic);
                            //console.log(pathIndexes);
                            //console.log(fhirEntryState.conceptCode);
                            //EXCLUSIVELY USE pathIndexes
                            //I NEED TO GIVE EACH CHARACTERISTIC A UNIQUE IDENTIFIER THAT CAN BE NAVIGATED TO, JUST FOR THE STATE
                            navigateToAnotherCharacteristic("Characteristic", history, fhirEntryState, setFhirEntryState, 'EvidenceVariable',
                                resourceId, json, characteristic, display, path, pathIndexes, pathString, formInputsStateRef, changeFhirEntryState,
                                submitUpdatedFhirResource, globalContext, changeFormState, addToast);
                        }
                    }
                    return <span key={characteristicIndex} style={style} 
                    id={characteristic && "characteristicdetail-navigation-code-" + (characteristic.code || '')}>
                        {bulletPoints[level % bulletPoints.length]}
                        &nbsp;<a style={linkStyle} onClick={linkOnClick}>{display}</a>
                        <br />
                        {characteristic.definitionByCombination?.characteristic?.length > 0 &&
                            <NavigationCharacteristics parentJson={characteristic.definitionByCombination} level={level + 1} parentPathString={pathString} setFhirEntryState={setFhirEntryState} parentPath={path} parentPathIndexes={pathIndexes} formInputsStateRef={formInputsStateRef} />
                        }
                    </span>
                } else {
                    return <span key={characteristicIndex}></span>
                }
            })}
        </>
    }


    return <Segment id={`navigationCharacteristicDetailSegment`} className={`containerSegment maxNavigationCharacteristicSegment`} style={{ marginTop: "8px", padding: "2px 4px 6px 4px" }} raised>
        <h2 className={"unselectable"} style={{ fontSize: "22px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }}>Criteria Navigation</h2>
        <div className={`navigationCharacteristicCharacteristicDetailSection`} style={{ paddingTop: "4px" }}>
            <span>
                {fhirEntryState.conceptPath?.length > 0 ?
                    <span onClick={() => {
                        navigateToAnotherCharacteristic("Characteristic", history, fhirEntryState, setFhirEntryState, 'EvidenceVariable',
                            resourceId, json, undefined, "", [], [], [], formInputsStateRef, changeFhirEntryState, submitUpdatedFhirResource,
                            globalContext, changeFormState, addToast);
                    }}>
                        <a>TOP</a>
                    </span>
                    :
                    <b>TOP</b>
                }
                <br />
            </span>
            <NavigationCharacteristics parentJson={json} level={0} parentPathString={""} parentPath={[]} parentPathIndexes={[]} setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} />
        </div>
    </Segment>
}

export { NavigationCharacteristicSegment, characteristicNavigationEntryDisplay, navigateToAnotherCharacteristic };