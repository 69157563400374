import React, { useState, useEffect, useImperativeHandle } from 'react';
import { SimpleResourceFieldViewer, getStringFromFHIR } from './ResourceFunctions';
import { DataEntry, DatatypeSelector, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';

const activityDefinitionDotKindValues = ['Appointment', 'AppointmentResponse', 'CarePlan', 'Claim', 'CommunicationRequest', 'Contract', 'CoverageEligibilityRequest', 'DeviceRequest', 'EnrollmentRequest', 'ImmunizationRecommendation', 'MedicationRequest', 'NutritionOrder', 'RequestOrchestration', 'ServiceRequest', 'SupplyRequest', 'VisionPrescription'];
const activityDefinitionDotCodeSystemChoices = [{ 'uri': 'http://www.nlm.nih.gov/research/umls/rxnorm', 'display': 'RxNorm' }, { 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }, { 'uri': 'https://www.whocc.no/atc_ddd_index/', 'display': 'ATC' }];
const activityDefinitionDotIntentValues = ['proposal', 'plan', 'directive', 'order', 'original-order', 'reflex-order', 'filler-order', 'instance-order', 'option'];
const activityDefinitionDotPriorityValues = ['routine', 'urgent', 'asap', 'stat'];
const activityDefinitionDotAsNeededCodeableConceptSystemChoices = [{ 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }];
const activityDefinitionDotLocationResourceTypes = ['Location'];
const activityDefinitionDotProductCodeableConceptSystemChoices = [{ 'uri': 'http://www.nlm.nih.gov/research/umls/rxnorm', 'display': 'RxNorm' }, { 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }, { 'uri': 'https://www.whocc.no/atc_ddd_index/', 'display': 'ATC' }, { 'uri': 'https://wiki.vnr.fi/', 'display': 'Nordic Article Number (Vnr)' }];
const activityDefinitionDotSubjectReferenceResourceTypes = ['Group', 'MedicinalProductDefinition', 'SubstanceDefinition', 'AdministrableProductDefinition', 'ManufacturedItemDefinition', 'PackagedProductDefinition'];
const activityDefinitionDotSubjectCodeableConceptValueSet = [
  { system: "http://hl7.org/fhir/fhir-types", code: "CareTeam", display: "CareTeam" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Device", display: "Device" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Group", display: "Group" },
  { system: "http://hl7.org/fhir/fhir-types", code: "HealthcareService", display: "HealthcareService" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Location", display: "Location" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Organization", display: "Organization" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Patient", display: "Patient" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Practitioner", display: "Practitioner" },
  { system: "http://hl7.org/fhir/fhir-types", code: "PractitionerRole", display: "PractitionerRole" },
  { system: "http://hl7.org/fhir/fhir-types", code: "RelatedPerson", display: "RelatedPerson" }
];
const activityDefinitionDotProductReferenceResourceTypes = ['Medication', 'Substance', 'Ingredient'];
const activityDefinitionDotSubjectAllowedDatatypes = ['CodeableConcept', 'Reference', 'canonical'];
const activityDefinitionDotTimingAllowedDatatypes = ['Timing', 'Age', 'Range', 'Duration'];
const activityDefinitionDotAsNeededAllowedDatatypes = ['boolean', 'CodeableConcept'];
const activityDefinitionDotProductAllowedDatatypes = ['Reference', 'CodeableConcept'];
const activityDefinitionDotBodySiteSystemChoices = [{ 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  if (resource.title) {
    innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
  }
  if (resource.subtitle) {
    innerDiv += "<p><b>Subtitle: </b>" + resource.subtitle + "</p><br/>";
  }
  if (resource.name) {
    innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
  }
  if (resource.description) {
    innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
  }
  if (resource.subjectCodeableConcept) {
    innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.CodeableConcept(resource.subjectCodeableConcept) + "</p><br/>";
  }
  if (resource.subjectReference) {
    innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.Reference(resource.subjectReference) + "</p><br/>";
  }
  if (resource.subjectCanonical) {
    innerDiv += "<p><b>Subject: </b>" + resource.subjectCanonical + "</p><br/>";
  }
  if (resource.code) {
    innerDiv += "<p><b>Code: </b>" + getStringFromFHIR.CodeableConcept(resource.code) + "</p><br/>";
  }
  if (resource.doNotPerform) {
    innerDiv += "<p><b>Negation: DO NOT PEFORM</b></p><br/>";
  }
  if (resource.timingTiming) {
    innerDiv += "<p><b>Timing: </b>" + getStringFromFHIR.Timing(resource.timingTiming) + "</p><br/>";
  }
  if (resource.timingAge) {
    innerDiv += "<p><b>Timing: </b>" + getStringFromFHIR.Quantity(resource.timingAge) + "</p><br/>";
  }
  if (resource.timingRange) {
    innerDiv += "<p><b>Timing: </b>" + getStringFromFHIR.Range(resource.timingRange) + "</p><br/>";
  }
  if (resource.timingDuration) {
    innerDiv += "<p><b>Timing: </b>" + getStringFromFHIR.Quantity(resource.timingDuration) + "</p><br/>";
  }
  if (resource.asNeededBoolean !== undefined) {
    innerDiv += "<p><b>As needed: </b>" + getStringFromFHIR.boolean(resource.asNeededBoolean) + "</p><br/>";
  }
  if (resource.asNeededCodeableConcept) {
    innerDiv += "<p><b>As needed for: </b>" + getStringFromFHIR.CodeableConcept(resource.asNeededCodeableConcept) + "</p><br/>";
  }
  if (resource.productReference) {
    innerDiv += "<p><b>Product: </b>" + getStringFromFHIR.Reference(resource.productReference) + "</p><br/>";
  }
  if (resource.productCodeableConcept) {
    innerDiv += "<p><b>Product: </b>" + getStringFromFHIR.CodeableConcept(resource.productCodeableConcept) + "</p><br/>";
  }
  if (resource.quantity) {
    innerDiv += "<p><b>Quantity: </b>" + getStringFromFHIR.Quantity(resource.quantity) + "</p><br/>";
  }
  if (resource.dosage?.length > 0) {
    innerDiv += resource.dosage.map((dosage) => {
      return "<p><b>Dosage: </b>" + getStringFromFHIR.Dosage(dosage) + "</p><br/>"
    }).join("");
  }
  if (resource.bodySite?.length > 0) {
    innerDiv += resource.bodySite.map((bodySite) => {
      return "<p><b>Body Site: </b>" + getStringFromFHIR.CodeableConcept(bodySite) + "</p><br/>"
    }).join("");
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const ActivityDefinitionEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson, classificationsArrayState, classificationsLoadedState }) => {
  /*
    const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
      "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "status", "experimental", "date", "publisher", "contact", "description", "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
      "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
      "subtitle", "subjectCodeableConcept", "subjectReference", "subjectCanonical", "library", "kind", "profile",
      "code", "intent", "priority", "doNotPerform", "timingTiming", "timingAge", "timingRange", "timingDuration",
      "asNeededBoolean", "asNeededCodeableConcept", "location", "participant", "productReference", "productCodeableConcept",
      "quantity", "dosage", "bodySite", "specimenRequirement", "observationRequirement", "observationResultRequirement",
      "transform", "dynamicValue"]
  */
  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "versionAlgorithmString": fhirJson.versionAlgorithmString, "versionAlgorithmCoding": fhirJson.versionAlgorithmCoding,
    "name": fhirJson.name, "title": fhirJson.title, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "subtitle": fhirJson.subtitle, "subjectCodeableConcept": fhirJson.subjectCodeableConcept, "subjectReference": fhirJson.subjectReference, "subjectCanonical": fhirJson.subjectCanonical, "library": fhirJson.library, "kind": fhirJson.kind, "profile": fhirJson.profile,
    "code": fhirJson.code, "intent": fhirJson.intent, "priority": fhirJson.priority, "doNotPerform": fhirJson.doNotPerform, "timingTiming": fhirJson.timingTiming, "timingAge": fhirJson.timingAge, "timingRange": fhirJson.timingRange, "timingDuration": fhirJson.timingDuration,
    "asNeededBoolean": fhirJson.asNeededBoolean, "asNeededCodeableConcept": fhirJson.asNeededCodeableConcept, "location": fhirJson.location, "participant": fhirJson.participant, "productReference": fhirJson.productReference, "productCodeableConcept": fhirJson.productCodeableConcept,
    "quantity": fhirJson.quantity, "dosage": fhirJson.dosage, "bodySite": fhirJson.bodySite, "specimenRequirement": fhirJson.specimenRequirement, "observationRequirement": fhirJson.observationRequirement, "observationResultRequirement": fhirJson.observationResultRequirement,
    "transform": fhirJson.transform, "dynamicValue": fhirJson.dynamicValue, "newClassifications": null
  });

  let startingSubjectDatatype = 'none';
  if (fhirJson.subjectCodeableConcept) { startingSubjectDatatype = 'CodeableConcept'; }
  if (fhirJson.subjectReference) { startingSubjectDatatype = 'Reference'; }
  if (fhirJson.subjectCanonical) { startingSubjectDatatype = 'canonical'; }
  let startingTimingDatatype = 'none';
  if (fhirJson.timingTiming) { startingTimingDatatype = 'Timing'; }
  if (fhirJson.timingAge) { startingTimingDatatype = 'Age'; }
  if (fhirJson.timingRange) { startingTimingDatatype = 'Range'; }
  if (fhirJson.timingDuration) { startingTimingDatatype = 'Duration'; }
  let startingAsNeededDatatype = 'none';
  if (fhirJson.asNeededBoolean !== undefined) { startingAsNeededDatatype = 'boolean'; }
  if (fhirJson.asNeededCodeableConcept) { startingAsNeededDatatype = 'CodeableConcept'; }
  let startingProductDatatype = 'none';
  if (fhirJson.productReference) { startingProductDatatype = 'Reference'; }
  if (fhirJson.productCodeableConcept) { startingProductDatatype = 'CodeableConcept'; }
  const [subjectDatatypeState, setSubjectDatatypeState] = useState(startingSubjectDatatype);
  const [timingDatatypeState, setTimingDatatypeState] = useState(startingTimingDatatype);
  const [asNeededDatatypeState, setAsNeededDatatypeState] = useState(startingAsNeededDatatype);
  const [productDatatypeState, setProductDatatypeState] = useState(startingProductDatatype);

  useImperativeHandle(formInputsStateRef, () => ({
    activityDefinitionState: resourceState
  }), [resourceState]);

  useEffect((() => {
    if (subjectDatatypeState === 'CodeableConcept') {
      setResourceState(prevState => { return { ...prevState, 'subjectReference': null, 'subjectCanonical': null } });
    }
    if (subjectDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'subjectCodeableConcept': null, 'subjectCanonical': null } });
    }
    if (subjectDatatypeState === 'canonical') {
      setResourceState(prevState => { return { ...prevState, 'subjectReference': null, 'subjectCodeableConcept': null } });
    }
  }), [subjectDatatypeState]);

  useEffect((() => {
    if (timingDatatypeState === 'Timing') {
      setResourceState(prevState => { return { ...prevState, 'timingAge': null, 'timingRange': null, 'timingDuration': null } });
    }
    if (timingDatatypeState === 'Age') {
      setResourceState(prevState => { return { ...prevState, 'timingTiming': null, 'timingRange': null, 'timingDuration': null } });
    }
    if (timingDatatypeState === 'Range') {
      setResourceState(prevState => { return { ...prevState, 'timingAge': null, 'timingTiming': null, 'timingDuration': null } });
    }
    if (timingDatatypeState === 'Duration') {
      setResourceState(prevState => { return { ...prevState, 'timingAge': null, 'timingRange': null, 'timingTiming': null } });
    }
  }), [timingDatatypeState]);

  useEffect((() => {
    if (asNeededDatatypeState === 'boolean') {
      setResourceState(prevState => { return { ...prevState, 'asNeededCodeableConcept': null } });
    }
    if (asNeededDatatypeState === 'CodeableConcept') {
      setResourceState(prevState => { return { ...prevState, 'asNeededBoolean': null } });
    }
  }), [asNeededDatatypeState]);

  useEffect((() => {
    if (productDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'productCodeableConcept': null } });
    }
    if (productDatatypeState === 'CodeableConcept') {
      setResourceState(prevState => { return { ...prevState, 'productReference': null } });
    }
  }), [productDatatypeState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          generatedNarrative={generateNarrative(resourceState)}
          startingValue={resourceState.text} setResourceState={setResourceState} />
      </div>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='subtitle' fieldLabel='Subtitle'
          startingValue={resourceState.subtitle} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={resourceState.name} setResourceState={setResourceState} />
      </div>
      <h3 id="activity-definition">Activity Definition</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Code' startingValue={resourceState.code}
          setResourceState={setResourceState} systemChoices={activityDefinitionDotCodeSystemChoices} systemChoicesOpen={true} />
        <DataEntry datatype='code' elementName='intent' fieldLabel='Intent' startingValue={resourceState.intent} setResourceState={setResourceState}
          allowedValues={activityDefinitionDotIntentValues} />
        <DataEntry datatype='code' elementName='priority' fieldLabel='Priority' startingValue={resourceState.priority} setResourceState={setResourceState}
          allowedValues={activityDefinitionDotPriorityValues} />
        <DataEntry datatype='boolean' elementName='doNotPerform' fieldLabel='DO NOT PEFORM' startingValue={resourceState.doNotPerform} setResourceState={setResourceState} />
        <p><b>Timing:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='timing[x]' allowedDatatypes={activityDefinitionDotTimingAllowedDatatypes}
            datatypeState={timingDatatypeState} setDatatypeState={setTimingDatatypeState} />
          {(timingDatatypeState === 'Timing') &&
            <DataEntry datatype='Timing' elementName='timingTiming'
              fieldLabel='Timing' startingValue={resourceState.timingTiming || null}
              setResourceState={setResourceState} />}
          {(timingDatatypeState === 'Age') &&
            <DataEntry datatype='Age' elementName='timingAge' fieldLabel='Timing (Age)'
              startingValue={resourceState.timingAge || null} setResourceState={setResourceState}
              dataEntryStyle='Age' />}
          {(timingDatatypeState === 'Range') &&
            <DataEntry datatype='Range' elementName='timingRange' fieldLabel='Timing (Range)'
              startingValue={resourceState.timingRange || null} setResourceState={setResourceState}
              dataEntryStyle='Duration' />}
          {(timingDatatypeState === 'Duration') &&
            <DataEntry datatype='Duration' elementName='timingDuration' fieldLabel='Timing (Duration)'
              startingValue={resourceState.timingDuration || null} setResourceState={setResourceState}
              dataEntryStyle='Duration' />}
        </div>
        <p><b>As Needed:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='asNeeded[x]' allowedDatatypes={activityDefinitionDotAsNeededAllowedDatatypes}
            datatypeState={asNeededDatatypeState} setDatatypeState={setAsNeededDatatypeState} />
          {(asNeededDatatypeState === 'boolean') &&
            <DataEntry datatype='boolean' elementName='asNeededBoolean' fieldLabel='As needed'
              startingValue={resourceState.asNeededBoolean ?? null} setResourceState={setResourceState}
              storeFalse={true} />}
          {(asNeededDatatypeState === 'CodeableConcept') &&
            <DataEntry datatype='CodeableConcept' elementName='asNeededCodeableConcept'
              fieldLabel='As needed (reason)' startingValue={resourceState.asNeededCodeableConcept || null}
              systemChoices={activityDefinitionDotAsNeededCodeableConceptSystemChoices} systemChoicesOpen
              setResourceState={setResourceState} />}
        </div>
        <DataEntry datatype='CodeableReference' elementName='location' fieldLabel='Location'
          referencedResourceTypes={activityDefinitionDotLocationResourceTypes}
          enableCreation={true}
          startingValue={resourceState.location} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='ActivityDefinitionParticipant' elementName='participant'
          fieldLabel='Participant(s)' startingValue={resourceState.participant} setResourceState={setResourceState} />
        <p><b>Product:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='product[x]' allowedDatatypes={activityDefinitionDotProductAllowedDatatypes}
            datatypeState={productDatatypeState} setDatatypeState={setProductDatatypeState} />
          {(productDatatypeState === 'Reference') &&
            <DataEntry datatype='Reference' elementName='productReference' fieldLabel='Product (as Reference)'
              referencedResourceTypes={activityDefinitionDotProductReferenceResourceTypes}
              startingValue={resourceState.productReference || null} setResourceState={setResourceState} />}
          {(productDatatypeState === 'CodeableConcept') &&
            <DataEntry datatype='CodeableConcept' elementName='productCodeableConcept'
              fieldLabel='Product (as CodeableConcept)' startingValue={resourceState.productCodeableConcept || null}
              systemChoices={activityDefinitionDotProductCodeableConceptSystemChoices} systemChoicesOpen={true}
              setResourceState={setResourceState} />}
        </div>
        <DataEntry datatype='SimpleQuantity' elementName='quantity' fieldLabel='Quantity' startingValue={resourceState.quantity} setResourceState={setResourceState}
          dataEntryStyle='SimpleQuantity' valueSet='UCUM' />
        <DataEntry asArray={true} datatype='Dosage' elementName='dosage' fieldLabel='Dosage'
          startingValue={resourceState.dosage} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='CodeableConcept' elementName='bodySite' fieldLabel='Body Site'
          startingValue={resourceState.bodySite} systemChoices={activityDefinitionDotBodySiteSystemChoices}
          systemChoicesOpen setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='uri' elementName='specimenRequirement'
          fieldLabel='Specimen Requirement' startingValue={resourceState.specimenRequirement} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='uri' elementName='observationRequirement'
          fieldLabel='Observation Requirement' startingValue={resourceState.observationRequirement} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='uri' elementName='observationResultRequirement'
          fieldLabel='Observation Result Requirement' startingValue={resourceState.observationResultRequirement} setResourceState={setResourceState} />
        <DataEntry datatype='uri' elementName='transform' fieldLabel='Transform' startingValue={resourceState.transform} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='ActivityDefinitionDynamicValue' elementName='dynamicValue'
          fieldLabel='Dynamic Value' startingValue={resourceState.dynamicValue} setResourceState={setResourceState} />
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
        <p><b>Subject:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='subject[x]' allowedDatatypes={activityDefinitionDotSubjectAllowedDatatypes}
            datatypeState={subjectDatatypeState} setDatatypeState={setSubjectDatatypeState} />
          {(subjectDatatypeState === 'CodeableConcept') &&
            <DataEntry datatype='CodeableConcept' elementName='subjectCodeableConcept'
              fieldLabel='Subject (as CodeableConcept)' startingValue={resourceState.subjectCodeableConcept || null}
              valueSet={activityDefinitionDotSubjectCodeableConceptValueSet} setResourceState={setResourceState} />}
          {(subjectDatatypeState === 'Reference') &&
            <DataEntry datatype='Reference' elementName='subjectReference' fieldLabel='Subject (as Reference)'
              referencedResourceTypes={activityDefinitionDotSubjectReferenceResourceTypes}
              startingValue={resourceState.subjectReference || null} setResourceState={setResourceState} />}
          {(subjectDatatypeState === 'canonical') &&
            <DataEntry datatype='uri' elementName='subjectCanonical' fieldLabel='Subject (as Canonical URI)'
              startingValue={resourceState.subjectCanonical || null} setResourceState={setResourceState} />}
        </div>
        <DataEntry asArray={true} datatype='uri' elementName='library' fieldLabel='Library' startingValue={resourceState.library} setResourceState={setResourceState} />
        <DataEntry datatype='code' elementName='kind' fieldLabel='Kind of Resource' startingValue={resourceState.kind} setResourceState={setResourceState}
          allowedValues={activityDefinitionDotKindValues}
          dataEntryStyle='dropdown' />
        <DataEntry datatype='uri' elementName='profile' fieldLabel='Profile' startingValue={resourceState.profile} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default ActivityDefinitionEdit;
