import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Button, Dimmer, Loader, Table, Input } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import { DisplayFromFHIR } from './ResourceFunctions';
import { DataEntry, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';

const ProjectEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson, classificationsArrayState,
  classificationsLoadedState, fhirEntryState }) => {
/*
  const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "status", "experimental", "date", "publisher", "contact", "description", "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "detail", "action", "associatedLink", "softwareCode", "referenceList", "showOnProjectsList", "enableRoBAT"]
*/
  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "resourceType": "Project", "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "versionAlgorithmString": fhirJson.versionAlgorithmString, "versionAlgorithmCoding": fhirJson.versionAlgorithmCoding,
    "name": fhirJson.name, "title": fhirJson.title, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "detail": fhirJson.detail, "action": fhirJson.action, "associatedLink": fhirJson.associatedLink,
    "softwareCode": fhirJson.softwareCode, "referenceList": fhirJson.referenceList,
    "showOnProjectsList": fhirJson.showOnProjectsList, "enableRoBAT": fhirJson.enableRoBAT, "newClassifications": null
  });

  useImperativeHandle(formInputsStateRef, () => ({
    projectState: resourceState
  }), [resourceState]);

  let pdfAttachments = [];
  if (fhirJson.relatedArtifact && Array.isArray(fhirJson.relatedArtifact) && fhirJson.relatedArtifact.length) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.document && relatedArtifactEntry.document.contentType === "application/pdf") {
        let documentAttachment = relatedArtifactEntry.document;
        let title = "Untitled PDF Attachment";
        if (relatedArtifactEntry.document.title) {
          title = relatedArtifactEntry.document.title;
        } else {
          documentAttachment.title = title;
        }
        if (relatedArtifactEntry.document.data) {
          pdfAttachments.push(documentAttachment);
        }
      }
    }
  }
  let resourceOfTypeCount = 0;
  let netEffectPatternDetected = false;
  if (Array.isArray(fhirJson.action)) {
    for (const action of fhirJson.action) {
      if (action.function === 'raterelativeimportance' || action.function === 'changebaselinerates' || action.function === 'generateneteffectreport') {
        netEffectPatternDetected = true;
      }
    }
  }

  const [attachmentsState, setAttachmentsState] = useState({ "pdfAttachments": pdfAttachments, "newAttachments": [] });
  const [netEffectActionsState, setNetEffectActionsState] = useState({ "netEffectPatternDetected": netEffectPatternDetected, "buttonClicked": false });

  useEffect(() => {
    if (attachmentsState.newAttachments.length > 0) {
      let newRelatedArtifactArray = resourceState.relatedArtifact || [];
      for (const newItem of attachmentsState.newAttachments) {
        let newRelatedArtifact = {
          "type": "supported-with",
          "document": {
            "contentType": "application/pdf",
            "data": newItem.data,
            "title": newItem.title
          }
        }
        newRelatedArtifactArray.push(newRelatedArtifact);
      }
      setResourceState(prevState => { return { ...prevState, "relatedArtifact": newRelatedArtifactArray } })
    }
  }, [attachmentsState]);

  return <div>
    {
      fhirEntryState.projectResourcesLoaded !== true
      &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <div style={{ marginTop: "12px" }}>
      <h3 id="project-title">Project Title</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Project Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
      </div>
      <h3 id="project-description">Project Description</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Project Description'
          startingValue={resourceState.description} setResourceState={setResourceState} />
      </div>
      <h3 id="project-actions">Project Actions</h3>
      <div style={{ marginLeft: "24px" }}>
        {netEffectActionsState.netEffectPatternDetected === false && <div>
          <Button className="formButton" style={{ color: "#000000" }} content="Add Net Effect Actions Package"
            onClick={() => {
              let newAction = resourceState.action || [];
              newAction.push({
                "description": "Select Desirable or Undesirable and rate your relative importance of each outcome (from 0 for no importance to 100 to represent the reference value of importance).",
                "label": "Rate Relative Importance",
                "function": "raterelativeimportance",
                "parameter": [
                  {
                    "element": "targetResourceType",
                    "valueArray": [
                      "EvidenceVariable"
                    ]
                  },
                  {
                    "element": "columnHeaders",
                    "valueArray": [
                      "Outcome",
                      "Desirability",
                      "Relative Importance (as %)"
                    ]
                  },
                  {
                    "element": "columnValues",
                    "valueArray": [
                      "resource.title",
                      "desirability",
                      "relative-importance"
                    ]
                  }
                ]
              });
              newAction.push({
                "description": "Choose to change the baseline risk (risk without treatment) for any outcome, instead of the value derived from the research estimates.",
                "label": "Change Baseline Risks",
                "function": "changebaselinerates",
                "parameter": [
                  {
                    "element": "targetResourceType",
                    "valueArray": [
                      "EvidenceVariable"
                    ]
                  },
                  {
                    "element": "columnHeaders",
                    "valueArray": [
                      "Outcome",
                      "Research Estimate",
                      "Self-Reported Risk (as %)"
                    ]
                  },
                  {
                    "element": "columnValues",
                    "valueArray": [
                      "resource.title",
                      "research-estimate",
                      "self-reported"
                    ]
                  }
                ]
              });
              newAction.push({
                "description": "Generate a Summary of Net Effect Report using your personal relative importance ratings (use Rate Relative Importance first) and optionally your personal baseline risks (use Change Baseline Risks first).",
                "label": "Generate Net Effect Report",
                "function": "generateneteffectreport"
              });
              setResourceState(prevState => { return { ...prevState, "action": newAction } });
              alert("Click Update to add 3 Project Actions. Refresh the page to cancel.");
              setNetEffectActionsState({ "netEffectPatternDetected": true, "buttonClicked": true });
            }} />
        </div>}
        <DataEntry asArray={true} datatype='ProjectAction' elementName='action' fieldLabel='Project Action'
          startingValue={resourceState.action} setResourceState={setResourceState} />
      </div>
      <h3 id="project-details">Project Details</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='markdown' elementName='detail'
          fieldLabel='Project Detail' startingValue={resourceState.detail} setResourceState={setResourceState} />
      </div>
      <h3 id="associated-links">Associated Links</h3>
      <div style={{ marginLeft: "24px" }}>
        {resourceState.associatedLink?.length > 0 ?
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>URL</Table.HeaderCell>
                <Table.HeaderCell style={{ width: "70%" }}>Description</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {resourceState.associatedLink.map((link, index) => {
                return <Table.Row key={index}>
                  <Table.Cell style={{ verticalAlign: "top" }}>
                    <TextField style={{ width: "100%" }} multiline className="inputField" type='text'
                      label={'URL'} size="small" variant='outlined' value={link.url || ''}
                      onChange={(e) => {
                        let newAssociatedLinkArray = JSON.parse(JSON.stringify(resourceState.associatedLink));
                        newAssociatedLinkArray[index].url = e.target.value;
                        setResourceState(prevState => { return { ...prevState, "associatedLink": newAssociatedLinkArray } });
                      }} />
                  </Table.Cell>
                  <Table.Cell style={{ verticalAlign: "top" }}>
                    <TextField style={{ width: "100%" }} multiline className="inputField" type='text'
                      label={'Description'} size="small" variant='outlined' value={link.description || ''}
                      onChange={(e) => {
                        let newAssociatedLinkArray = JSON.parse(JSON.stringify(resourceState.associatedLink));
                        newAssociatedLinkArray[index].description = e.target.value;
                        setResourceState(prevState => { return { ...prevState, "associatedLink": newAssociatedLinkArray } });
                      }} />
                  </Table.Cell>
                </Table.Row>;
              })
              }
            </Table.Body>
          </Table>
          :
          <>None<br /></>
        }
        <Button className="formButton" style={{ color: "#000000" }} content="+ Add Link" onClick={() => {
          let newAssociatedLinkArray = JSON.parse(JSON.stringify(resourceState.associatedLink || []));
          newAssociatedLinkArray.push({ "url": "", "description": "" })
          setResourceState(prevState => { return { ...prevState, "associatedLink": newAssociatedLinkArray } });
        }} />
      </div>
      <h3 id="associated-documents">Associated Documents</h3>
      <div style={{ marginLeft: "24px" }}>
        {pdfAttachments?.length > 0 ?
          pdfAttachments.map((attachment, contentIndex) => {
            return <span key={contentIndex}><DisplayFromFHIR attachment={attachment} /><br /></span>
          })
          :
          <>None</>
        }
        <br />
        <Input id="fileAttachment" type="file"
          onChange={(e, data) => {
            if (e.target.files.length > 0) {
              let file = e.target.files[0];
              if (file.name.length >= 5 && file.name.slice(-4).toLowerCase() === ".pdf") {
                let reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = function () {
                  if (reader.result.length < 70000000) {
                    setAttachmentsState(prevState => {
                      let newItem = {
                        "data": reader.result.replace("data:application/pdf;base64,", ""),
                        "title": file.name.slice(0, -4)
                      };
                      let prevPdfAttachments = attachmentsState.pdfAttachments;
                      prevPdfAttachments.push(newItem);
                      let prevNewAttachments = attachmentsState.newAttachments;
                      prevNewAttachments.push(newItem);
                      return {
                        ...prevState,
                        pdfAttachments: prevPdfAttachments,
                        newAttachments: prevNewAttachments
                      };
                    });
                  } else {
                    alert("We only support attaching files that are 50 MB or less. If your file is, then please email support@computablepublishing.com");
                  }
                };
                reader.onerror = function (error) {
                  console.log('Error, ', error);
                };
              } else {
                alert("We currently only support .pdf files. Please choose another file.");
                //TODO: selected file name still shows in the data entry space, should be cleared.
              }
            }
          }}
        />
      </div>
      <h3 id="associated-resources">Associated FHIR<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup> Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirEntryState.projectResources?.length > 0
          ?
          fhirEntryState.projectResources.map((resource, resourceIndex) => {
            if (resourceIndex === 0 || resource.resourcetype !== fhirEntryState.projectResources[resourceIndex - 1].resourcetype) {
              resourceOfTypeCount = 0;
            }
            resourceOfTypeCount += 1;
            return <div key={resourceIndex}>
              {(resourceIndex === 0 || resource.resourcetype !== fhirEntryState.projectResources[resourceIndex - 1].resourcetype) &&
                <div className="resourceListHeaderTextForProject" style={{ width: "100%", marginTop: "12px", marginBottom: "8px" }}>
                  <span className="resourceListHeader" style={{ fontWeight: "bold", paddingLeft: "30%", cursor: "auto" }}>
                    <span>{resource.resourcetype} <b style={{ padding: "none !important", fontWeight: "normal" }}>Resources</b></span>
                  </span><br />
                </div>
              }
              <>{resourceOfTypeCount}. </> <a href={"/resources/" + resource.resourcetype + "/" + resource.id}>{resource.title}</a>
            </div>
          })
          :
          <>None</>
        }
      </div>
      <h3 id="software-code">Software Code</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='Expression' elementName='softwareCode'
          fieldLabel='Software Code' deletableArray={true}
          startingValue={resourceState.softwareCode} setResourceState={setResourceState} />
      </div>
      <h3 id="reference-list">Reference List</h3>
      <div>
        {fhirJson.referenceList?.length > 0 ?
          <ol style={{ marginTop: "0px" }}>
            {fhirJson.referenceList.map((reference, referenceIndex) => {
              let availableAt;
              if (reference.summary) {
                if (reference.summary.includes("Available at: ")) {
                  availableAt = reference.summary.substring(reference.summary.indexOf("Available at: ") + 14, reference.summary.length - 1);
                  if (availableAt === "" || availableAt.includes(" ") || !availableAt.includes("http")) {
                    availableAt = undefined;
                  }
                }
              }
              return <li key={referenceIndex}>
                {reference.reference?.reference ?
                  <>{availableAt ?
                    <>
                      <a href={"/resources/" + reference.reference.reference} target="_blank" rel="noopener noreferrer">{reference.summary.substring(0, reference.summary.indexOf("Available at: "))}</a>
                      <>Available at: </>
                      <a href={availableAt} target="_blank" rel="noopener noreferrer">{availableAt}</a>
                      .&nbsp;{fhirJson.enableRoBAT && <>
                        <Button className="formButton" style={{ color: "#000000", padding: "2px" }} content="Rate risk of bias"
                          onClick={() => {
                            window.open("/createriskofbiasassessment/" + reference.reference.reference + "/" + fhirJson.id + "/", "_blank"); //opens RoBAT tool in new tab
                          }}
                          disabled={fhirEntryState.previousVersionLoaded}
                        />
                      </>}</>
                    :
                    <><a href={"/resources/" + reference.reference.reference} target="_blank" rel="noopener noreferrer">{reference.summary || reference.reference.display || reference.description}</a> {fhirJson.enableRoBAT && <>
                      <Button className="formButton" style={{ color: "#000000", padding: "2px" }} content="Rate risk of bias"
                        onClick={() => {
                          window.open("/createriskofbiasassessment/" + reference.reference.reference + "/" + fhirJson.id + "/", "_blank"); //opens RoBAT tool in new tab
                        }}
                        disabled={fhirEntryState.previousVersionLoaded}
                      />
                    </>}</>
                  }</>
                  :
                  <>{reference.summary || reference.description}</>
                }

                {reference.description && <><br /><div style={{ paddingLeft: "16px" }}><b>Description: </b><DisplayFromFHIR markdown={reference.description} /></div></>}
                {reference.robatRating?.length > 0 && <><br /><span style={{ paddingLeft: "16px" }}><b>Risk of bias ratings:</b> {
                  reference.robatRating.map((robatRating, robatRatingIndex) => {
                    return <span key={robatRatingIndex}>
                      <a href={"/resources/" + robatRating.reference} target="_blank" rel="noopener noreferrer">{robatRating.display}</a>{(robatRatingIndex < reference.robatRating.length - 1) && <b>, </b>}
                    </span>
                  })
                }</span></>}
              </li>
            })}
          </ol>
          :
          <div style={{ marginLeft: "24px" }}>None</div>
        }
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternEdit fhirJson={fhirJson} resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <br /><br />
      <span style={{ fontSize: "12px" }}>The FEvIR Platform uses the HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> standard (<a href="http://build.fhir.org/" target="_blank" rel="noopener noreferrer">current build</a>). HL7<sup>&#174;</sup>, and FHIR<sup>&#174;</sup> are the registered trademarks of Health Level Seven International and their use of these trademarks does not constitute an endorsement by HL7.</span>
    </div>
  </div>
}

export default ProjectEdit;