import { DisplayFromFHIR, getStringFromFHIR } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';

const SoftwareScriptDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (fhirJson.relatedArtifact && Array.isArray(fhirJson.relatedArtifact) && fhirJson.relatedArtifact.length) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.subtitle && <p><b>Subtitle: </b>{fhirJson.subtitle}</p>}
        {fhirJson.name && <p><b>Name (for machine use): </b>{fhirJson.name}</p>}
        {(fhirJson.nameWithoutExtension && fhirJson.extensionCode) &&
          <p><b>Filename: </b>{fhirJson.nameWithoutExtension + "." + fhirJson.extensionCode}</p>}
        {(fhirJson.nameWithoutExtension && !fhirJson.extensionCode) &&
          <p><b>Filename: </b>{fhirJson.nameWithoutExtension}</p>}
        {(!fhirJson.nameWithoutExtension && fhirJson.extensionCode) &&
          <p><b> Extension: </b>{fhirJson.extensionCode}</p>}
      </div>
      <h3 id="detail">Detail</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.language && <p><b>Language: </b>{fhirJson.language}</p>}
        {(Array.isArray(fhirJson.format) && fhirJson.format.length > 0) &&
          fhirJson.format.map((format, formatIndex) => {
            return <div key={formatIndex}><p><b>Format: </b>{getStringFromFHIR.CodeableConcept(format)}</p></div>
          })}
        {fhirJson.size && <p><b>Size: </b>{getStringFromFHIR.Quantity(fhirJson.size)}</p>}
        {(Array.isArray(fhirJson.location) && fhirJson.location.length > 0) &&
          fhirJson.location.map((location, locationIndex) => {
            return <div key={locationIndex}><p><b>Location: </b>{location}</p></div>
          })}
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
      </div>
      <h3 id="code-string">Code String</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.codeString ?
          <div style={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }} >{fhirJson.codeString}</div>
          :
          <div><p>No Code String to display at this time.</p></div>
        }
      </div>
      <h3 id="files-documents">Files/Documents</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.relatedArtifact?.length === 1 && <div><b>Related Item: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR relatedArtifact={fhirJson.relatedArtifact[0]} /></div></div>}
        {fhirJson.relatedArtifact?.length > 1 && fhirJson.relatedArtifact.map((relatedItem, relatedItemIndex) => {
          return <div key={relatedItemIndex}><div><b>Related Item: </b><div style={{ marginLeft: "24px" }}><DisplayFromFHIR relatedArtifact={relatedItem} /></div></div></div>
        })}
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <br /><br />
    </div>
  </div>
}

export default SoftwareScriptDisplay;