import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';

const LibraryDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (fhirJson.relatedArtifact && Array.isArray(fhirJson.relatedArtifact) && fhirJson.relatedArtifact.length) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") ?
          <DisplayFromFHIR xhtml={fhirJson.text.div} />
          : <p>[Narrative summary not generated.]</p>}
      </div>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.subtitle && <p><b>Subtitle: </b>{fhirJson.subtitle}</p>}
        {fhirJson.name && <p><b>Name: </b>{fhirJson.name}</p>}
      </div>
      <h3 id="subject">Subject</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
        {fhirJson.type && <span><b>Type: </b><DisplayFromFHIR codeableConcept={fhirJson.type} /></span>}
        {fhirJson.subjectCodeableConcept && <span><b>Subject: </b><DisplayFromFHIR codeableConcept={fhirJson.subjectCodeableConcept} /></span>}
        {fhirJson.subjectReference && <p><b>Subject: </b><DisplayFromFHIR reference={fhirJson.subjectReference} /></p>}
      </div>
      <h3 id="content">Content</h3>
      <div style={{ marginLeft: "24px" }}>
        {(fhirJson.relatedArtifact && Array.isArray(fhirJson.relatedArtifact)) &&
          fhirJson.relatedArtifact.map((relatedArtifact, relatedArtifactIndex) => {
            return <div key={relatedArtifactIndex}><b>Item: </b><DisplayFromFHIR relatedArtifact={relatedArtifact} /></div>
          })}
        {(fhirJson.content && Array.isArray(fhirJson.content)) &&
          fhirJson.content.map((content, contentIndex) => {
            return <div key={contentIndex}><b>Attachment: </b><DisplayFromFHIR attachment={content} /></div>
          })}
      </div>
      <h3 id="parameters">Parameters</h3>
      <div style={{ marginLeft: "24px" }}>
        {(fhirJson.parameter && Array.isArray(fhirJson.parameter)) &&
          fhirJson.parameter.map((parameter, parameterIndex) => {
            return <div key={parameterIndex}><b>Parameter: </b><DisplayFromFHIR parameterDefinition={parameter} /></div>
          })}
        {(fhirJson.dataRequirement && Array.isArray(fhirJson.dataRequirement)) &&
          fhirJson.dataRequirement.map((dataRequirement, dataRequirementIndex) => {
            return <div key={dataRequirementIndex}><b>Data Requirement: </b><DisplayFromFHIR dataRequirement={dataRequirement} /></div>
          })}
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default LibraryDisplay;