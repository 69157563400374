import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import { navigateToAnotherTerm } from './CodeSystemFunctions';
import { ReorganizeSectionsModal } from './ResourceFunctions';
import { getCodeSystemConceptValuesFromFhirJson } from './CodeSystemEditFunctions';
import { DataEntry } from './DataEntryFormFunctions';

const ChildConceptsTable = ({ resourceId, fhirEntryState, setFhirEntryState, history }) => {

  let header = "Child concepts:";
  let addButtonText = "+ Add Child Concept";
  let concept = fhirEntryState.conceptSelected;
  let conceptPath = fhirEntryState.conceptPath;
  let json;
  if (fhirEntryState.fhirEntryString) {
    json = JSON.parse(fhirEntryState.fhirEntryString);
  }

  if (conceptPath === undefined || conceptPath.length === 0) {
    concept = json;
    header = "Top-level concepts:"
    addButtonText = "+ Add Top-Level Concept";
  }
  return <>
    <b>{header}</b>
    {(concept?.concept?.length > 0 || fhirEntryState.newChildConcepts?.length > 0) &&
      <div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Code</Table.HeaderCell>
              <Table.HeaderCell>Display</Table.HeaderCell>
              <Table.HeaderCell>Definition</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {concept?.concept?.map((subConcept, subConceptIndex) => {
              let subConceptPath = [];
              let subConceptPathString = fhirEntryState.conceptPathString + ": " + subConcept.display;
              if (conceptPath) {
                subConceptPath = [...conceptPath, subConcept.display];
              } else {
                subConceptPath = [subConcept.display];
              }
              let subConceptPathIndexes = [];
              if (fhirEntryState.conceptPathIndexes) {
                subConceptPathIndexes = [...fhirEntryState.conceptPathIndexes, subConceptIndex];
              } else {
                subConceptPathIndexes = [subConceptIndex];
              }
              return <Table.Row key={subConceptIndex}>
                <Table.Cell>{subConcept.code}</Table.Cell>
                <Table.Cell>
                  <a onClick={() => {
                    navigateToAnotherTerm("term", history, fhirEntryState, setFhirEntryState, 'CodeSystem', resourceId, json,
                      subConcept, subConcept.display, subConceptPath, subConceptPathIndexes, subConceptPathString);
                  }}>
                    {subConcept.display}
                  </a>
                </Table.Cell>
                <Table.Cell>{subConcept.definition}</Table.Cell>
              </Table.Row>
            })}
            {fhirEntryState.newChildConcepts?.map((newConcept, newConceptIndex) => {
              return <Table.Row key={newConceptIndex}>
                <Table.Cell>
                  <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Code'}
                    size="small" variant='outlined'
                    value={fhirEntryState.newChildConcepts[newConceptIndex]["code"] || ''}
                    onChange={(e) => {
                      setFhirEntryState(prevState => {
                        let newNewChildConcepts = prevState.newChildConcepts;
                        newNewChildConcepts[newConceptIndex]["code"] = e.target.value;
                        return { ...prevState, "newChildConcepts": newNewChildConcepts };
                      });
                    }} />
                </Table.Cell>
                <Table.Cell>
                  <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Display'}
                    size="small" variant='outlined' value={fhirEntryState.newChildConcepts[newConceptIndex]["display"] || ''}
                    onChange={(e) => {
                      setFhirEntryState(prevState => {
                        let newNewChildConcepts = prevState.newChildConcepts;
                        newNewChildConcepts[newConceptIndex]["display"] = e.target.value;
                        return { ...prevState, "newChildConcepts": newNewChildConcepts };
                      });
                    }} />
                </Table.Cell>
                <Table.Cell>
                  <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Definition'}
                    size="small" variant='outlined' value={fhirEntryState.newChildConcepts[newConceptIndex]["definition"] || ''}
                    onChange={(e) => {
                      setFhirEntryState(prevState => {
                        let newNewChildConcepts = prevState.newChildConcepts;
                        newNewChildConcepts[newConceptIndex]["definition"] = e.target.value;
                        return { ...prevState, "newChildConcepts": newNewChildConcepts };
                      });
                    }} />
                </Table.Cell>
              </Table.Row>
            })}
          </Table.Body>
        </Table>
      </div>
    }
    <Button className="formButton" style={{ color: "#000000" }} content={addButtonText}
      onClick={() => {
        setFhirEntryState(prevState => {
          let newNewChildConcepts = prevState.newChildConcepts;
          newNewChildConcepts.push({ "code": "", "display": "", "definition": "" });
          return { ...prevState, "newChildConcepts": newNewChildConcepts };
        });
      }} />
  </>
}

//no longer used with creation of CodeSystemConceptDesignationEntry
const getCodeSystemDesignationJsonFormat = (alternativeTermsState) => {
  let newDesignation = alternativeTermsState.map(term => {
    return {
      "use": { system: "http://snomed.info/sct", code: "900000000000013009", display: "Synonym (core metadata concept)" },
      "value": term
    }
  });
  return newDesignation;
}

const getCodeSystemProperties = (codeSystemTermState) => {
  let startingConceptProperty = {};
  if (Array.isArray(codeSystemTermState.property) && codeSystemTermState.property.length > 0) {
    for (const property of codeSystemTermState.property) {
      startingConceptProperty[property.code] = {
        valueCode: property.valueCode || null,
        valueCoding: property.valueCoding || null,
        valueString: property.valueString || null,
        valueInteger: property.valueInteger || null,
        valueBoolean: property.valueBoolean ?? null,
        valueDateTime: property.valueDateTime || null,
        valueDecimal: property.valueDecimal || null
      }
    }
  }
  return startingConceptProperty;
}

//no longer used with creation of CodeSystemConceptDesignationEntry
const getCodeSystemAlternativeTerms = (designation) => {
  let startingAlternativeTerms = [];
  let alternativeTermsOptions = [];
  if (Array.isArray(designation) && designation.length > 0) {
    for (const designationEntry of designation) {
      if (designationEntry.use?.code === "900000000000013009" || designationEntry.use?.display === "Synonym (core metadata concept)" || designationEntry.use?.display === "Alternative term") {
        startingAlternativeTerms.push(designationEntry.value);
        alternativeTermsOptions.push({ key: designationEntry.value, text: designationEntry.value, value: designationEntry.value });
      }
    }
  }
  return [startingAlternativeTerms, alternativeTermsOptions]
}

const CodeSystemConceptDataEntry = ({ resourceId, fhirEntryState, setFhirEntryState, history,
  codeSystemTermState, setCodeSystemTermState, previousCode }) => {
  const fhirJson = JSON.parse(fhirEntryState.fhirEntryString);

  const [conceptPropertyState, setConceptPropertyState] = useState(getCodeSystemProperties(codeSystemTermState));
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (ready && previousCode === fhirEntryState.conceptCode) {
      let newConceptProperty = [];
      for (const code in conceptPropertyState) {
        let instance = { "code": code };
        if (conceptPropertyState[code].valueString) {
          instance.valueString = conceptPropertyState[code].valueString;
        } else if (conceptPropertyState[code].valueCode) {
          instance.valueCode = conceptPropertyState[code].valueCode;
        } else if (conceptPropertyState[code].valueCoding) {
          instance.valueCoding = conceptPropertyState[code].valueCoding;
        } else if (conceptPropertyState[code].valueInteger) {
          instance.valueInteger = conceptPropertyState[code].valueInteger;
        } else if (conceptPropertyState[code].valueDecimal) {
          instance.valueDecimal = conceptPropertyState[code].valueDecimal;
        } else if (conceptPropertyState[code].valueDateTime) {
          instance.valueDateTime = conceptPropertyState[code].valueDateTime;
        } else if (typeof conceptPropertyState[code].valueBoolean === "boolean") {
          instance.valueBoolean = conceptPropertyState[code].valueBoolean;
        } else {
          instance.valueString = "";
        }
        newConceptProperty.push(instance);
      }

      if (JSON.stringify(codeSystemTermState.property) !== JSON.stringify(newConceptProperty)) {
        setCodeSystemTermState(prevState => { return { ...prevState, "property": newConceptProperty } });
      }
    } else if (!ready) {
      setReady(true);
    }
  }, [conceptPropertyState]);

  useEffect(() => {
    if (ready && previousCode !== fhirEntryState.conceptCode) {
      setConceptPropertyState(getCodeSystemProperties(codeSystemTermState));
    }
  }, [codeSystemTermState]); //fhirEntryState.conceptCode

  return <>
    <DataEntry datatype='string' elementName='code' fieldLabel='Code'
      startingValue={codeSystemTermState.code} setResourceState={setCodeSystemTermState} />
    <DataEntry datatype='string' elementName='display' fieldLabel='Preferred term (Display)'
      startingValue={codeSystemTermState.display} setResourceState={setCodeSystemTermState} />
    <DataEntry datatype='string' elementName='definition' fieldLabel='Definition'
      startingValue={codeSystemTermState.definition} setResourceState={setCodeSystemTermState} />
    <DataEntry asArray={true} datatype='CodeSystemConceptDesignation'
      elementName='designation' fieldLabel='Alternative Term' deletable={true}
      startingValue={codeSystemTermState.designation} setResourceState={setCodeSystemTermState} />
    <br /><br />
    {fhirJson.property?.length > 0 &&
      <Table style={{ margin: "0px" }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: "30%" }}>Type of Property</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "70%" }}>Property Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fhirJson.property.map((systemProperty, systemPropertyIndex) => {
            return <Table.Row key={systemPropertyIndex}>
              <Table.Cell>{systemProperty.description}</Table.Cell>
              <Table.Cell>
                <TextField style={{ width: "100%" }} multiline className="inputField" type='text'
                  label={'Value'} size="small" variant='outlined'
                  value={conceptPropertyState[systemProperty.code]?.valueString || ""}
                  onChange={(e) => {
                    setConceptPropertyState(prevState => { return { ...prevState, [systemProperty.code]: { valueString: e.target.value } } })
                  }} />
              </Table.Cell>
            </Table.Row>
          })}
        </Table.Body>
      </Table>
    }
    <br />
    <ChildConceptsTable resourceId={resourceId} fhirEntryState={fhirEntryState}
      setFhirEntryState={setFhirEntryState} history={history} />
    <br /><br />
    <div style={{ width: "100%" }}>
      <Button style={{ color: "#000000", float: "right" }} className="formButton"
        content="Delete Concept"
        onClick={() => {
          if (window.confirm("Are you sure you want to permanately remove the term \"" + codeSystemTermState.display + "\" and all related concepts including child concepts (if any)?")) {
            setCodeSystemTermState(prevState => { return { ...prevState, "conceptDelete": true }; });
            //TODO - see if this line can be deleted
            setFhirEntryState(prevState => { return { ...prevState, "conceptDelete": true, termChanged: true }; });
          }
        }} />
    </div>
    <br /><br />
  </>
}

const CodeSystemTermEdit = ({ formInputsStateRef, fhirEntryState, resourceId, setFhirEntryState, history, update }) => {
  let startingResourceState;
  if (!startingResourceState && fhirEntryState?.fhirEntryString) {
    startingResourceState = {"concept": JSON.parse(fhirEntryState.fhirEntryString)["concept"]};
  }
  
  const [resourceState, setResourceState] = useState(startingResourceState);

  const [ready, setReady] = useState(false);
  const [previousCode, setPreviousCode] = useState(fhirEntryState.conceptCode);
  const [codeSystemTermState, setCodeSystemTermState] = useState(getCodeSystemConceptValuesFromFhirJson(fhirEntryState, { loaded: false, termChanged: false, editChanged: false }));
  const [reorganizeConceptsModalState, setReorganizeConceptsModalState] = useState({});

  useImperativeHandle(formInputsStateRef, () => ({
    codeSystemTermState,
  }), [codeSystemTermState]);

  useEffect(() => {
    if (!resourceState && fhirEntryState?.fhirEntryString) {
      setResourceState({"concept": JSON.parse(fhirEntryState.fhirEntryString)["concept"]});
    }
  }, [fhirEntryState.fhirEntryString]);

  useEffect(() => {
    if (!ready) {
      setReady(true);
    } else if (resourceState.concept) {
      setFhirEntryState(prevState => { return {...prevState, conceptsRearranged: resourceState.concept } });
    }
  }, [resourceState]);
  
  useEffect(() => {
    if (ready && !fhirEntryState.termChanged && previousCode === fhirEntryState.conceptCode) {
      setFhirEntryState(prevState => { return { ...prevState, "termChanged": true } });
      setPreviousCode(fhirEntryState.conceptCode);
    } else if (!ready) {
      setReady(true);
    } else if (previousCode !== fhirEntryState.conceptCode) {
      setPreviousCode(fhirEntryState.conceptCode);
    }
  }, [codeSystemTermState]);

  useEffect(() => {
    if (ready && previousCode !== fhirEntryState.conceptCode) {
      setCodeSystemTermState(getCodeSystemConceptValuesFromFhirJson(fhirEntryState, { loaded: false, termChanged: false, editChanged: false }));
    }
  }, [fhirEntryState.conceptCode])

  let conceptPath = fhirEntryState.conceptPath;

  return <div>
    <div style={{ marginTop: "12px" }}>
      {conceptPath?.length > 0 ?
        <>
          {fhirEntryState.conceptDelete ?
            <><h1 style={{ color: "#FF0000" }}>
              Concept "{fhirEntryState.conceptName}" deletion pending, please click "Update" to complete deletion.
            </h1></>
            :
            <>
              <h2 style={{ margin: "0px" }}>{fhirEntryState.conceptName}</h2>
              <CodeSystemConceptDataEntry fhirEntryState={fhirEntryState}
                resourceId={resourceId} setFhirEntryState={setFhirEntryState} history={history}
                codeSystemTermState={codeSystemTermState}
                setCodeSystemTermState={setCodeSystemTermState} previousCode={previousCode} />
              <br /><br />
            </>
          }
        </>
        :
        <>
          <span>Please select a term</span>
          <br />
          <br />
          <ChildConceptsTable resourceId={resourceId} fhirEntryState={fhirEntryState}
            setFhirEntryState={setFhirEntryState} history={history} />
          <br/>
          {reorganizeConceptsModalState.modalOpen && <ReorganizeSectionsModal resourceState={resourceState} setResourceState={setResourceState} reorganizeSectionsModalState={reorganizeConceptsModalState} setReorganizeSectionsModalState={setReorganizeConceptsModalState} update={update} elementName={"concept"} />}
          <Button style={{ color: "#000000", marginTop: "18px", marginLeft: "6px", fontSize: "18px", padding: "4px" }} className="formButton sectionReorganizeButton"
            content="Reorganize Concepts" onClick={() => { setReorganizeConceptsModalState(prevState => { return { ...prevState, modalOpen: true } }); }} />
        </>
      }
    </div>
  </div>
}

export default CodeSystemTermEdit;