import React, { useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import "firebase/compat/auth";
import './App.css';
import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';

const ratingCodeLookup = {
  "SEVCO:00186": {"shorthand": "Low", "scale": 1, "ratingType": "BiasRisk"},
  "SEVCO:00187": {"shorthand": "Moderate", "scale": 3, "ratingType": "BiasRisk"},
  "SEVCO:00188": {"shorthand": "High", "scale": 5, "ratingType": "BiasRisk"},
  "SEVCO:00189": {"shorthand": "Serious", "scale": 4, "ratingType": "BiasRisk"},
  "SEVCO:00190": {"shorthand": "Critical", "scale": 5, "ratingType": "BiasRisk"},
  "SEVCO:00191": {"shorthand": "Some", "scale": 3, "ratingType": "BiasRisk"},
  "SEVCO:00192": {"shorthand": "Unclear", "scale": 3, "ratingType": "BiasRisk"},
  "SEVCO:00194": {"shorthand": "Yes", "scale": 5, "ratingType": "FactorPresence"},
  "SEVCO:00195": {"shorthand": "Likely yes", "scale": 4, "ratingType": "FactorPresence"},
  "SEVCO:00196": {"shorthand": "Likely no", "scale": 2, "ratingType": "FactorPresence"},
  "SEVCO:00197": {"shorthand": "No", "scale": 1, "ratingType": "FactorPresence"},
  "SEVCO:00198": {"shorthand": "No information", "scale": 0, "ratingType": "FactorPresence"},
  "SEVCO:00199": {"shorthand": "Unclear", "scale": 3, "ratingType": "FactorPresence"},
  "SEVCO:00201": {"shorthand": "Favor experimental", "scale": 0, "ratingType": "BiasDirection"},
  "SEVCO:00202": {"shorthand": "Favor comparator", "scale": 0, "ratingType": "BiasDirection"},
  "SEVCO:00203": {"shorthand": "Toward null", "scale": 0, "ratingType": "BiasDirection"},
  "SEVCO:00204": {"shorthand": "Away from null", "scale": 0, "ratingType": "BiasDirection"},
  "SEVCO:00205": {"shorthand": "Unpredictable", "scale": 0, "ratingType": "BiasDirection"},
  "SEVCO:00207": {"shorthand": "Yes", "scale": 5, "ratingType": "PotentialInfluence"},
  "SEVCO:00208": {"shorthand": "Likely yes", "scale": 4, "ratingType": "PotentialInfluence"},
  "SEVCO:00209": {"shorthand": "Likely no", "scale": 2, "ratingType": "PotentialInfluence"},
  "SEVCO:00210": {"shorthand": "No", "scale": 1, "ratingType": "PotentialInfluence"}
  };

const flattenComponents = (components, depth, list) => {
  let startingIndex = list.length-1;
  for (let componentIndex in components) {
    let component = components[componentIndex];
    if (component.informationType === "rating") {
      let rating = {};
      rating["depth"] = depth;
      rating["summary"] = component.summary;
      rating["notes"] = [];
      rating["FactorPresence"] = {};
      rating["PotentialInfluence"] = {};
      rating["BiasDirection"] = {};
      rating["BiasRisk"] = {};
      if (component.type?.coding?.length > 0) {
        rating["typeSystem"] = component.type.coding[0].system;
        rating["typeCode"] = component.type.coding[0].code;
        rating["typeDisplay"] = component.type.coding[0].display;
      }
      for (let classifierIndex in component.classifier) {
        let classifier = component.classifier[classifierIndex];
        if (classifier.coding?.length > 0) {
          if (classifier.coding[0].code && ratingCodeLookup[classifier.coding[0].code]) {
            let ratingDetails = ratingCodeLookup[classifier.coding[0].code];
            rating[ratingDetails.ratingType] = ratingDetails;
          }
        }
      }
      list.push(rating);
      list = flattenComponents(component.component, depth+1, list);
    } else if (component.informationType === "comment") {
      if (component.summary) {
        list[startingIndex]["notes"].push(component.summary);
      }
    }
  }
  return list
}

const lookupColor = (scale) => {
  if (scale === 5) {
    return "#FF78789A";
  }
  if (scale === 4) {
    return "#FFBB889A";
  }
  if (scale === 3) {
    return "#FFF9B39A";
  }
  if (scale === 2) {
    return "#C6F4D29A";
  }
  if (scale === 1) {
    return "#8AED869A";
  }
  return ;
}

const RiskOfBiasAssessmentReader = ( { fhirJson, changeFormState } ) => {

  useEffect( async () => {
    changeFormState("Computable Publishing®: Risk of Bias Assessment Reader", "pageTitle");
  }, []);

  let biasContent;
  let biasComponents;
  let biasNotes = [];
  let flattenedComponents;
  let profileName;
  let citeAsReference;
  let citeAsMarkdown;
  let riskOfBiasRatingSystem;
  let riskOfBiasRatingCode;
  let riskOfBiasRatingDisplay;
  let directionOfBiasRatingSystem;
  let directionOfBiasRatingCode;
  let directionOfBiasRatingDisplay;
  for (let contentIndex in fhirJson.content) {
    let content = fhirJson.content[contentIndex];
    if (content.informationType === "rating") {
      if (content.type?.coding?.length > 0 && content.type.coding[0].code === "SEVCO:00001") {
        biasContent = content;
        biasComponents = biasContent.component;
        flattenedComponents = flattenComponents(biasComponents, 0, [{"notes": []}]);
        biasNotes = flattenedComponents[0]["notes"];
        flattenedComponents.shift();
      }
      for (let classifierIndex in content.classifier) {
        let classifier = content.classifier[classifierIndex];
        for (let codingIndex in classifier.coding) {
          if (["SEVCO:00186", "SEVCO:00187", "SEVCO:00188", "SEVCO:00189", "SEVCO:00190", "SEVCO:00191", "SEVCO:00192"].includes(classifier.coding[codingIndex].code)) {
            riskOfBiasRatingSystem = classifier.coding[codingIndex].system;
            riskOfBiasRatingCode = classifier.coding[codingIndex].code;
            riskOfBiasRatingDisplay = classifier.coding[codingIndex].display;
          } else if (["SEVCO:00201", "SEVCO:00202", "SEVCO:00203", "SEVCO:00204", "SEVCO:00205"].includes(classifier.coding[codingIndex].code)) {
            directionOfBiasRatingSystem = classifier.coding[codingIndex].system;
            directionOfBiasRatingCode = classifier.coding[codingIndex].code;
            directionOfBiasRatingDisplay = classifier.coding[codingIndex].display;
          }
        }
      }
    } else if (content.informationType === "classifier") {
      if (content.type?.text === "Profile") {
        if (content.classifier?.length > 0 && content.classifier[0].text) {
          profileName = content.classifier[0].text;
        }
      }
    }
  }
  if (fhirJson.citeAsReference) {
    citeAsReference = fhirJson.citeAsReference;
  } else if (fhirJson.citeAsMarkdown) {
    citeAsMarkdown = fhirJson.citeAsMarkdown;
  }

  return <>
    {flattenedComponents &&
      <>
        <span id="top" />
        {/*<h3>Description</h3>*/}
        <div style={{marginLeft: "0px"}}>
          <br/>
          {fhirJson.title && <><b>Title: </b> {fhirJson.title}<br/></>}
          <div style={{marginTop: "8px", marginLeft: "24px"}}>
            {fhirJson.artifactReference?.reference && <><b>Rated artifact: </b> <a href={"/resources/" + fhirJson.artifactReference.reference} target="blank">{fhirJson.artifactReference.display || fhirJson.artifactReference.reference}</a><br/></>}
            {(profileName && profileName !== "Base") && <><b>Profile used: </b> {profileName}<br/></>}
            {biasContent.author?.display && <><b>Author: </b> {biasContent.author.display}<br/></>}
            {fhirJson.approvalDate && <><b>Approval date: </b> {fhirJson.approvalDate}<br/></>}
            {fhirJson.lastReviewDate && <><b>Last review date: </b> {fhirJson.lastReviewDate}<br/></>}
            {fhirJson.copyright && <><b>Copyright: </b> <DisplayFromFHIR markdown={fhirJson.copyright} /><br/></>}
            {(citeAsReference || citeAsMarkdown) && <><b>Cite as: </b> <DisplayFromFHIR reference={citeAsReference} markdown={citeAsMarkdown} /><br/></>}
          </div>
        </div>
        <h3 id="risk-of-bias-assessment">Risk of Bias Assessment</h3>
        <div style={{marginLeft: "24px"}}>
          {directionOfBiasRatingDisplay && <><b>Direction of Bias Rating: </b>{directionOfBiasRatingDisplay}<br/></>}
          <><b>Risk of Bias Rating: </b> {riskOfBiasRatingDisplay ? riskOfBiasRatingDisplay : "no data"}<br/></>
          {biasContent.summary && <><b>Rationale: </b> <DisplayFromFHIR markdown={biasContent.summary} /><br/></>}
          {biasNotes?.length > 0 &&
            <>
              <h4 style={{padding: "0px", margin: "0px"}}>{biasNotes.length === 1 ? <>Note</> : <>Notes</>}</h4>
              <div style={{marginLeft: "24px"}}>
                {biasNotes.map((note, noteIndex) => { return <span key={noteIndex}>{note}<br/></span>})}
              </div>
            </>
          }
        </div>
        <h3 id="details">Details</h3>
        <div style={{marginLeft: "24px"}}>        
        {flattenedComponents.length > 0 ?
          <Table style={{margin: "0px"}}>
              <Table.Header>
                  <Table.Row>
                  <Table.HeaderCell style={{width: "20%"}}>Type</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "7%"}}>Factor presence</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "7%"}}>Potential to Influence</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "7%"}}>Bias Direction</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "7%"}}>Risk of Bias</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "20%"}}>Summary</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "32%"}}>Notes</Table.HeaderCell>
                  {/*
                  <Table.HeaderCell style={{width: "16%"}}>Type</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "12%"}}>Factor presence</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "12%"}}>Potential to Influence</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "12%"}}>Bias Direction</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "12%"}}>Risk of Bias</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "20%"}}>Summary</Table.HeaderCell>
                  <Table.HeaderCell style={{width: "16%"}}>Notes</Table.HeaderCell>
                  */}
                  </Table.Row>
              </Table.Header>
              <Table.Body>
                {flattenedComponents.map((component, componentIndex) => {
                  let typePaddingLeft = (24*component.depth).toString()+"px";
                  let rowShadeNumber = 255;
                  rowShadeNumber -= (12 * component.depth);
                  if (rowShadeNumber < 155) {
                    rowShadeNumber = 155;
                  }
                  rowShadeNumber = (rowShadeNumber).toString(16); //Convert it to hexadecimal
                  let rowShade = "#"+rowShadeNumber+rowShadeNumber+rowShadeNumber;

                  let factorPresenceStyle = {verticalAlign: "top", whiteSpace: "pre-wrap", fontWeight: "bold", padding: "0px", height: "0px"};
                  let potentialInfluenceStyle = {verticalAlign: "top", whiteSpace: "pre-wrap", fontWeight: "bold", padding: "0px", height: "0px"};
                  let biasDirectionStyle = {verticalAlign: "top", whiteSpace: "pre-wrap", fontWeight: "bold", padding: "0px", height: "0px"};
                  let biasRiskStyle = {verticalAlign: "top", whiteSpace: "pre-wrap", fontWeight: "bold", padding: "0px", height: "0px"};

                  let factorPresenceColor = lookupColor(component.FactorPresence.scale);
                  let potentialInfluenceColor = lookupColor(component.PotentialInfluence.scale);
                  let biasDirectionColor = lookupColor(component.BiasDirection.scale);
                  let biasRiskColor = lookupColor(component.BiasRisk.scale);

                  return <Table.Row key={componentIndex} style={{backgroundColor: rowShade}}>
                    <Table.Cell style={{verticalAlign: "top", whiteSpace: "pre-wrap", fontWeight: "bold"}}>
                      <span style={{paddingLeft: typePaddingLeft, display: "flex"}}>
                        {(component.typeSystem && component.typeCode) ? <a href={component.typeSystem + "#" + component.typeCode} target="_blank" rel="noopener noreferrer">{(component.typeDisplay || component.typeCode)}</a> : <>{(component.typeDisplay || component.typeCode)}</>}
                      </span>
                    </Table.Cell>
                    <Table.Cell style={factorPresenceStyle}><div style={{padding: "8px", width: "100%", height: "100%", backgroundColor: factorPresenceColor}}>{component.FactorPresence.shorthand && component.FactorPresence.shorthand}</div></Table.Cell>
                    <Table.Cell style={potentialInfluenceStyle}><div style={{padding: "8px", width: "100%", height: "100%", backgroundColor: potentialInfluenceColor}}>{component.PotentialInfluence.shorthand && component.PotentialInfluence.shorthand}</div></Table.Cell>
                    <Table.Cell style={biasDirectionStyle}><div style={{padding: "8px", width: "100%", height: "100%", backgroundColor: biasDirectionColor}}>{component.BiasDirection.shorthand && component.BiasDirection.shorthand}</div></Table.Cell>
                    <Table.Cell style={biasRiskStyle}><div style={{padding: "8px", width: "100%", height: "100%", backgroundColor: biasRiskColor}}>{component.BiasRisk.shorthand && component.BiasRisk.shorthand}</div></Table.Cell>
                    <Table.Cell style={{verticalAlign: "top", whiteSpace: "pre-wrap"}}><DisplayFromFHIR markdown={component.summary} /></Table.Cell>
                    <Table.Cell style={{verticalAlign: "top", whiteSpace: "pre-wrap"}}>{component.notes.map((note, noteIndex) => {
                      return <span key={noteIndex}>{note}<br/></span>
                    })}</Table.Cell>
                  </Table.Row>
                })}
              </Table.Body>
            </Table>
          :
            <span>No components.</span>
          }
        </div>
      </>
    }
    <br/><br/><br/>
    <h3 id="metadata">Metadata</h3>
    <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
  </>
};

export default RiskOfBiasAssessmentReader;