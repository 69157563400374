import { monthLookup } from './CodeSystemLookup';
import { parseISO } from 'date-fns';

//expected input of Address datatype (object with {use:code, type:code, text:string, line:[string], city:string, district:string, state:string, postalCode:string, country:string, period:Period}) 
//expected output if matching data structure with matching datatypes is {text} || comma-separated concatenation of data values
//expected output if string datatype is "{address} [ERROR: Address data not in expected Address datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: Address data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Address data not interpretable.]"
const getAddressDisplay = (address) => {
    if (address === undefined || address === null || address === '') {
        return ""
    }
    if (typeof address === "object") {
        try {
            if (address.text) {
                return `${address.text}`;
            }
            let addressDisplay = "";
            if (address.line?.length > 0) {
                for (const lineItem of address.line) {
                    addressDisplay = addressDisplay + lineItem + ", ";
                }
            }
            if (address.city) {
                addressDisplay = addressDisplay + address.city + ", ";
            }
            if (address.district) {
                addressDisplay = addressDisplay + address.district + ", ";
            }
            if (address.state) {
                addressDisplay = addressDisplay + address.state + ", ";
            }
            if (address.postalCode) {
                addressDisplay = addressDisplay + address.postalCode + ", ";
            }
            if (address.country) {
                addressDisplay = addressDisplay + address.country;
            }
            let addressDisplayLength = addressDisplay.length;
            if (addressDisplay.substring(addressDisplayLength - 2) === ", ") {
                addressDisplay = addressDisplay.substring(0, addressDisplayLength - 2);
            }
            if (addressDisplay && typeof addressDisplay === "string") {
                return addressDisplay;
            }
        } catch { }
    }
    if (typeof address === "string") {
        return `${address} [ERROR: Address data not in expected Address datatype.]`
    }
    return "[ERROR: Address data not interpretable.]";
}
//expected input of Annotation datatype (object with {author[x]:Reference/string, time:dateTime, text:markdown}) 
//expected output if matching data structure with matching datatypes is {text} ({author[x]} {time})
//expected output if string datatype is "{note} [ERROR: Annotation data not in expected Annotation datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: Annotation data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Annotation data not interpretable.]"
const getAnnotationDisplay = (note) => {
    if (note === undefined || note === null || note === '') {
        return ""
    }
    if (typeof note === "object") {
        try {
            if (note.text) {
                let authorDisplay = "";
                if (note.authorString) {
                    authorDisplay = note.authorString;
                } else if (note.authorReference) {
                    authorDisplay = getReferenceDisplay(note.authorReference);
                }
                let timeDisplay = "";
                if (note.time) {
                    timeDisplay = " " + getDateTimeDisplay(note.time);
                }
                let modifierDisplay = "";
                if (authorDisplay || timeDisplay) {
                    modifierDisplay = " (" + authorDisplay + timeDisplay + ")";
                }
                return note.text + modifierDisplay;
            }
        } catch { }
    }
    if (typeof note === "string") {
        return `${note} [ERROR: Annotation data not in expected Annotation datatype.]`
    }
    return "[ERROR: Annotation data not interpretable.]";
}
//expected input of boolean datatype (true or false without string or numeral)
//expected output of "True" or "False" (string datatype matching boolean value) or string with "[ERROR: ..."
const getBooleanDisplay = (boolean) => {
    if (boolean === true) {
        return "True";
    } else if (boolean === false) {
        return "False";
    } else if (boolean === undefined || boolean === null || boolean === "") {
        return ""
    } else if (typeof (boolean) === "string" && boolean.toLowerCase() === "true") {
        return "True [ERROR: Boolean data in string datatype.]";
    } else if (typeof (boolean) === "string" && boolean.toLowerCase() === "false") {
        return "False [ERROR: Boolean data in string datatype.]";
    } else {
        return "[ERROR: Boolean data not interpretable.]";
    }
}
//expected input of CodeableConcept datatype (object with {coding:[Coding], text:string}) 
//expected output if matching data structure with matching datatypes is [{coding}].join(; )"; "{text}
//expected output if string datatype is "{codeableConcept} [ERROR: CodeableConcept data not in expected CodeableConcept datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: CodeableConcept data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: CodeableConcept data not interpretable.]"
const getCodeableConceptDisplay = (codeableConcept) => {
    if (codeableConcept === undefined || codeableConcept === null || codeableConcept === '') {
        return ""
    }
    if (typeof codeableConcept === "object") {
        try {
            let codeableConceptDisplay = "";
            if (codeableConcept.coding) {
                for (let codingIndex in codeableConcept.coding) {
                    let coding = codeableConcept.coding[codingIndex];
                    if (coding.display || coding.code) {
                        if (codeableConceptDisplay !== "") {
                            codeableConceptDisplay += "; ";
                        }
                        codeableConceptDisplay += getCodingDisplay(coding);
                    }
                }
            }
            if (codeableConcept.text) {
                if (codeableConceptDisplay !== "") {
                    codeableConceptDisplay += "; ";
                }
                codeableConceptDisplay += codeableConcept.text;
            }
            if (codeableConceptDisplay !== "") {
                return codeableConceptDisplay;
            }
        } catch { }
    }
    if (typeof codeableConcept === "string") {
        return `${codeableConcept} [ERROR: CodeableConcept data not in expected CodeableConcept datatype.]`
    }
    return "[ERROR: CodeableConcept data not interpretable.]";
}
//specs and script changed on 2023-02-13
//expected input of Coding datatype (object with {system:uri, version:string, code:code, display:string, userSelected:boolean})
//expected output if matching data structure with matching datatypes is "{display} (coded as: {code} from {system})"
//expected output if matching data structure with matching datatypes and known system is "{display} ({systemDisplay} code: {code})"
//expected output if matching data structure with matching datatypes but missing display is "code {code} from {system}"
//expected output if matching data structure with matching datatypes and known system but missing display is "{systemDisplay} code: {code}"
//expected output if matching data structure but not matching datatypes is "[ERROR: Coding data not interpretable.]"
//expected output if matching data structure but missing code is "[Code missing.]" in place of code value
//expected output if matching data structure but missing system is "[Code System missing.]" in place of system value
//expected output if string datatype is "{coding} [ERROR: Coding data not in expected Coding datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: Coding data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Coding data not interpretable.]"
const getCodingDisplay = (coding) => {
    if (coding === undefined || coding === null || coding === '') {
        return ""
    }
    if (typeof coding === "object") {
        try {
            let codingDisplay = "";
            let code = coding.code || "[Code missing.]";
            let system = coding.system || "[Code System missing.]";
            let systemDisplay;
            if (system === "http://snomed.info/sct") {
                systemDisplay = "SNOMED-CT";
            } else if (system === "http://loinc.org") {
                systemDisplay = "LOINC";
            } else if (system === "http://www.nlm.nih.gov/research/umls/rxnorm") {
                systemDisplay = "RxNorm";
            } else if (system === "http://unitsofmeasure.org") {
                systemDisplay = "UCUM";
            }
            if (coding.display && systemDisplay) {
                codingDisplay = coding.display + " (" + systemDisplay + " code: " + code + ")";
            } else if (coding.display && !systemDisplay) {
                codingDisplay = coding.display + " (coded as: " + code + " from " + system + ")";
            } else if (systemDisplay) {
                codingDisplay = systemDisplay + " code: " + code;
            } else {
                codingDisplay = "code " + code + " from " + system;
            }
            if (codingDisplay && typeof codingDisplay === "string") {
                return codingDisplay;
            }
        } catch { }
    }
    if (typeof coding === "string") {
        return `${coding} [ERROR: Coding data not in expected Coding datatype.]`
    }
    return "[ERROR: Coding data not interpretable.]";
}
//expected input of date datatype (string with format YYYY-MM-DD)
//expected output if ISO format is one of:  YYYY --or-- Month YYYY --or-- Month DD, YYYY --or-- Month D, YYYY [when DD < 10]
//expected output if non-ISO format is the same date value (string datatype) followed by " [ERROR: date data not ISO format YYYY-MM-DD]"
//expected output if not string datatype is "[ERROR: date data not interpretable.]" or if a number "### [ERROR: date data not in string datatype with ISO format YYYY-MM-DD]"
//expected output if undefined/null/'' is ''
const getDateDisplay = (date) => {
    if (date === undefined || date === null || date === '') {
        return ""
    }
    if (typeof date === "string") {
        if (date.length === 4 && /^[0-9]+$/.test(date)) {
            return `${date}`;
        }
        let dateSplit = date.split("-");
        if (dateSplit[0].length === 4 && /^[0-9]+$/.test(dateSplit[0])) {
            if (dateSplit.length >= 2 && monthLookup[dateSplit[1]]) {
                if (dateSplit.length === 3 && date.length === 10 && /^[0-9]+$/.test(dateSplit[2])) {
                    if (dateSplit[2] > 9) {
                        return monthLookup[dateSplit[1]] + " " + dateSplit[2] + ", " + dateSplit[0];
                    } else if (dateSplit[2] > 0) {
                        return monthLookup[dateSplit[1]] + " " + dateSplit[2][1] + ", " + dateSplit[0];
                    }
                } else if (dateSplit.length === 2 && date.length === 7) {
                    return monthLookup[dateSplit[1]] + " " + dateSplit[0];
                }
            }
        }
        return `${date} [ERROR: date data not ISO format YYYY-MM-DD]`;
    }
    if (!isNaN(date) && typeof date === "number") {
        return `${date} [ERROR: date data not in string datatype with ISO format YYYY-MM-DD]`
    }
    return "[ERROR: date data not interpretable.]";
}
//expected input of dateTime datatype (string with format YYYY-MM-DDThh:mm:ss.sTZD where TZD = time zone designator (Z or +hh:mm or -hh:mm))
//expected output if ISO format is [getDateDisplay] at hh:mm:ss.sTZD
//expected output if non-ISO format is the same dateTime value (string datatype) followed by " [ERROR: dateTime data not ISO format YYYY-MM-DDThh:mm:ss.sTZD]"
//expected output if not string datatype is "[ERROR: dateTime data not interpretable.]" or if a number "### [ERROR: dateTime data not in string datatype with ISO format YYYY-MM-DDThh:mm:ss.sTZD]"
//expected output if undefined/null/'' is ''
//expected output if NaN, empty {}, or empty [] is "[ERROR: dateTime data not interpretable.]"
const getDateTimeDisplay = (dateTime) => {
    if (dateTime === undefined || dateTime === null || dateTime === '') {
        return ""
    }
    if (typeof dateTime === "string") {
        if (parseISO(dateTime) != "Invalid Date") {
            let dateTimeSplit = dateTime.split('T');
            if (dateTimeSplit.length === 1) {
                return getDateDisplay(dateTime);
            }
            if (dateTimeSplit.length === 2) {
                let dateDisplay = getDateDisplay(dateTimeSplit[0]);
                let timeDisplay = dateTimeSplit[1];
                return `${dateDisplay} at ${timeDisplay}`;
            }
        }
        return `${dateTime} [ERROR: dateTime data not ISO format YYYY-MM-DDThh:mm:ss.sTZD]`;
    }
    if (!isNaN(dateTime) && typeof dateTime === "number") {
        return `${dateTime} [ERROR: dateTime data not in string datatype with ISO format YYYY-MM-DDThh:mm:ss.sTZD]`
    }
    return "[ERROR: dateTime data not interpretable.]";
}
//expected input of Dosage datatype (object with {sequence:integer, text:string, 
//       additionalInstruction:[CodeableConcept], patientInstruction:string, timing:Timing, asNeeded:boolean, 
//       asNeededFor:[CodeableConcept], site:CodeableConcept,route:CodeableConcept, method:CodeableConcept,
//       doseAndRate: [{type:CodeableConcept, dose[x]:Range/SimpleQuantity, rate[x]:Ratio/Range/SimpleQuantity}], 
//       maxDosePerPeriod:[Ratio], maxDosePerAdministration:SimpleQuantity, maxDosePerLifetime:SimpleQuantity})
//expected output if matching data structure with matching datatypes is noted in the code script
//expected output if string datatype is "{dosage} [ERROR: Dosage data not in expected Dosage datatype.]"
//expected output if missing (undefined/null/''/{}) is ''
//expected output if NaN, empty [], or [a,b] is "[ERROR: Dosage data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Dosage data not interpretable.]"
const getDosageDisplay = (dosage) => {
    if (dosage === undefined || dosage === null || dosage === '') {
        return ""
    }
    if (Array.isArray(dosage)) {
        return "[ERROR: Dosage data not interpretable.]";
    }
    if (typeof dosage === 'object') {
        let dosageDisplay = "";
        if (dosage.sequence) {
            dosageDisplay = "Dosage Sequence #" + dosage.sequence + ": ";
        }
        if (dosage.text) {
            dosageDisplay += dosage.text;
            return dosageDisplay;
        }
        if (dosage.method) {
            dosageDisplay += getCodeableConceptDisplay(dosage.method) + " ";
        }
        if (dosage.doseAndRate?.length > 0) {
            dosageDisplay += dosage.doseAndRate.map((item, index) => getDoseAndRateDisplay(item)).join(', ');
        }
        if (dosage.route) {
            dosageDisplay += " via " + getCodeableConceptDisplay(dosage.route);
        }
        if (dosage.site) {
            dosageDisplay += " applied to " + getCodeableConceptDisplay(dosage.site);
        }
        if (dosage.asNeeded) {
            dosageDisplay += " as needed"
        }
        if (dosage.asNeededFor?.length > 0) {
            dosageDisplay += " as needed for " + dosage.asNeededFor.map((item, index) => getCodeableConceptDisplay(item)).join(' -OR- ');
        }
        if (dosage.timing) {
            dosageDisplay += " " + getTimingDisplay(dosage.timing);
        }
        if (dosage.maxDosePerPeriod?.length > 0) {
            dosageDisplay += "; MAXIMUM LIMIT " + dosage.maxDosePerPeriod.map((item, index) => getRatioDisplay(item)).join(', ');
        }
        if (dosage.maxDosePerAdministration) {
            dosageDisplay += "; MAXIMUM LIMIT PER DOSE " + getQuantityDisplay(dosage.maxDosePerAdministration);
        }
        if (dosage.maxDosePerLifetime) {
            dosageDisplay += "; MAXIMUM CUMULATIVE LIMIT " + getQuantityDisplay(dosage.maxDosePerLifetime);
        }
        if (dosage.additionalInstruction?.length > 0) {
            dosageDisplay += "; " + dosage.additionalInstruction.map((item, index) => getCodeableConceptDisplay(item)).join('; ');
        }
        if (dosage.patientInstruction) {
            dosageDisplay += "; " + dosage.patientInstruction;
        }
        if (dosageDisplay && typeof dosageDisplay === 'string') {
            return dosageDisplay;
        }
        if (dosageDisplay === '') {
            return ""
        }
    }
    if (typeof dosage === "string") {
        return `${dosage} [ERROR: Dosage data not in expected Dosage datatype.]`;
    }
    return "[ERROR: Dosage data not interpretable.]";
}

const getDoseAndRateDisplay = (doseAndRate) => {
    let doseAndRateDisplay = "";
    if (doseAndRate.doseRange) {
        doseAndRateDisplay = getRangeDisplay(doseAndRate.doseRange);
    }
    if (doseAndRate.doseQuantity) {
        doseAndRateDisplay = getQuantityDisplay(doseAndRate.doseQuantity);
    }
    if (doseAndRate.type) {
        doseAndRateDisplay += " [" + getCodeableConceptDisplay(doseAndRate.type) + "]";
    }
    if (doseAndRate.rateRatio || doseAndRate.rateRange || doseAndRate.rateQuantity) {
        if (doseAndRateDisplay) {
            doseAndRateDisplay += " ";
        }
        if (doseAndRate.rateRatio) {
            doseAndRateDisplay += getRatioDisplay(doseAndRate.rateRatio);
        }
        if (doseAndRate.rateRange) {
            doseAndRateDisplay += getRangeDisplay(doseAndRate.rateRange);
        }
        if (doseAndRate.rateQuantity) {
            doseAndRateDisplay += getQuantityDisplay(doseAndRate.rateQuantity);
        }
    }
    return doseAndRateDisplay;
}
//expected input of Expression datatype (object with {description:string, name:id, language:code, expression:string, reference:uri})
//expected output if matching data structure with matching datatypes is "{name}: {description}; defined in [{language}] as: {expression}, defined at: {uri}"
//expected output if matching data structure but missing some elements is variation without some of the connector characters (:, ;, ...)
//expected output if string datatype is "{expression} [ERROR: Expression data not in expected Expression datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: Expression data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Expression data not interpretable.]"
const getExpressionDisplay = (expression) => {
    if (expression === undefined || expression === null || expression === '') {
        return ""
    }
    if (typeof expression === 'object') {
        if (expression.description || expression.name || expression.language || expression.expression || expression.reference) {
            let description = expression.description;
            let name = expression.name;
            let language = expression.language;
            let expressionString = expression.expression;
            let referenceUri = expression.reference;
            let expressionDisplay = "";
            if (name) {
                expressionDisplay = name + ": ";
            }
            if (description) {
                expressionDisplay += description + "; ";
            }
            if (language && expressionString) {
                expressionDisplay += "defined in [" + language + "] as: " + expressionString;
            } else if (language && !expressionString) {
                expressionDisplay += "defined in [" + language + "]";
            } else if (!language && expressionString) {
                expressionDisplay += "defined as: " + expressionString;
            }
            if (referenceUri) {
                expressionDisplay += ", defined at: " + referenceUri;
            }
            return expressionDisplay;
        }
    }
    if (typeof expression === 'string') {
        return `${expression} [ERROR: Expression data not in expected Expression datatype.]`;
    }
    return "[ERROR: Expression data not interpretable.]";
}
//expected input of HumanName datatype (object with {use:code, text:string, family:string, given:[string], prefix:[string], suffix:[string], period:Period})
//expected output if matching data structure with matching datatypes is {text} ELSE [prefix].join( ) [given].join( ) family [suffix].join(,)
//expected output if string datatype is "{humanName} [ERROR: HumanName data not in expected HumanName datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: HumanName data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: HumanName data not interpretable.]"
const getHumanNameDisplay = (humanName) => {
    if (humanName === undefined || humanName === null || humanName === '') {
        return ""
    }
    if (typeof humanName === 'object') {
        if (humanName.text) {
            return `${humanName.text}`;
        }
        if (typeof humanName.given === 'string' || typeof humanName.prefix === 'string' || typeof humanName.suffix === 'string') {
            return "[ERROR: HumanName data not interpretable. One of the contained elements is in string form instead of array.]";
        }
        let prefixDisplay = "";
        let givenDisplay = "";
        let suffixDisplay = "";
        let familyDisplay = humanName.family || "";
        if (humanName.prefix?.length > 0) {
            for (const prefixItem of humanName.prefix) {
                prefixDisplay = prefixDisplay + prefixItem + " ";
            }
        }
        if (humanName.given?.length > 0) {
            for (const givenName of humanName.given) {
                givenDisplay = givenDisplay + givenName + " ";
            }
        }
        if (humanName.suffix?.length > 0) {
            for (const suffixItem of humanName.suffix) {
                suffixDisplay = suffixDisplay + ", " + suffixItem;
            }
        }
        let nameDisplay = prefixDisplay + givenDisplay + familyDisplay + suffixDisplay;
        if (nameDisplay && typeof nameDisplay === 'string') {
            return nameDisplay;
        }
    }
    if (typeof humanName === 'string') {
        return `${humanName} [ERROR: HumanName data not in expected HumanName datatype.]`;
    }
    return "[ERROR: HumanName data not interpretable.]";
}
//expected input of Identifier datatype (object with {use:code, type:CodeableConcept, system:uri, value:string, period:Period, assigner:Reference(Organization)}) where Reference(Organization) means Reference datatype but Organization is only value allowed for reference.type
//expected output if matching data structure with known system is `{system-based display} {value}`
//expected output if matching data structure with unknown system is `Identifier {value} from {system}`
//expected output if matching data structure and missing system is `{type} identifier {value}` or `{type} {value}`
//expected output if matching data structure and missing system and type is `identifier {value} assigned by {assigner}`
//expected output if matching data structure and missing system and type and assigner is `{use} identifier {value}`
//expected output if matching data structure and missing system and type and assigner and use is `{value}`
//expected output if matching data structured and missing value is substituting "[No identifier value.]" for value
//expected output if string datatype is "{identifier} [ERROR: Identifier data not in expected HumanName datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: Identifier data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Identifier data not interpretable.]"
const getIdentifierDisplay = (identifier) => {
    if (identifier === undefined || identifier === null || identifier === '') {
        return ""
    }
    if (typeof identifier === "object") {
        let use = identifier.use || "";
        let type = getCodeableConceptDisplay(identifier.type) || "";
        let system = identifier.system || "";
        let value = identifier.value || "[No identifier value.]";
        let assigner = getReferenceDisplay(identifier.assigner) || "";
        if (system) {
            switch (system) {
                case 'https://clinicaltrials.gov':
                    return `ClinicalTrials.gov ${value}`
                case 'https://doi.org':
                    return `DOI ${value}`
                case 'https://www.crd.york.ac.uk/prospero/':
                    return `PROSPERO ${value}`
                case 'https://fevir.net':
                    if (type === "FOI" || type === "FEvIR Object Identifier") {
                        return `FOI ${value}`
                    } else if (type === "FLI" || type === "FEvIR Linking Identifier") {
                        return `FEvIR Linking Identifier ${value}`
                    } else {
                        return `FEvIR Identifier ${value}`
                    }
                case 'https://fevir.net/FLI':
                    return `FEvIR Linking Identifier ${value}`
                case 'https://www.ncbi.nlm.nih.gov/pmc/':
                    return `PMCID ${value}`
                case 'https://www.ncbi.nlm.nih.gov/pmc':
                    return `PMCID ${value}`
                case 'https://pubmed.ncbi.nlm.nih.gov':
                    return `PMID ${value}`
                case 'https://pubmed.ncbi.nlm.nih.gov/':
                    return `PMID ${value}`
                case 'https://orcid.org/':
                    return `ORCID ${value}`
                case 'https://orcid.org':
                    return `ORCID ${value}`
                default:
                    return `Identifier ${value} from ${system}`;
            }
        }
        if (type) {
            let typeLower = type.toLowerCase();
            if (!typeLower.includes("identifier") || !typeLower.includes(" id")) {
                return `${type} identifier ${value}`;
            } else {
                return `${type} ${value}`;
            }
        }
        if (assigner) {
            return `identifier ${value} assigned by ${assigner}`
        }
        if (use) {
            return `${use} identifier ${value}`
        }
        return `${value}`
    }
    if (typeof identifier === 'string') {
        return `${identifier} [ERROR: Identifier data not in expected Identifier datatype.]`;
    }
    return "[ERROR: Identifier data not interpretable.]";
}
//expected input of Period datatype (object with {start:dateTime, end:dateTime})
//expected output if matching data structure with matching datatypes is "{start} to {end}"
//expected output if matching data structure but not matching datatypes is "{start [ERROR: ]} to {end [ERROR: ]}"
//expected output if matching data structure but missing start is "[No start date.] to {end}"
//expected output if matching data structure but missing end is "{start} to [No end date.]"
//expected output if object missing both start and end is "[ERROR: Period data not interpretable.]"
//expected output if string datatype is "{period} [ERROR: Period data not in expected Period datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: Period data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Period data not interpretable.]"
const getPeriodDisplay = (period) => {
    if (period === undefined || period === null || period === '') {
        return ""
    }
    if (typeof period === 'object') {
        if (period.start || period.end) {
            let startDisplay = getDateTimeDisplay(period.start) || "[No start date.]";
            let endDisplay = getDateTimeDisplay(period.end) || "[No end date.]";
            return `${startDisplay} to ${endDisplay}`;
        }
    }
    if (typeof period === 'string') {
        return `${period} [ERROR: Period data not in expected Period datatype.]`;
    }
    return "[ERROR: Period data not interpretable.]";
}
//expected input of Quantity datatype (object with {value:decimal, comparator:code, unit:string, system:uri, code:code})
//expected output if matching data structure with matching datatypes is "{comparator} {value} {unit || code}"
//expected output if matching data structure but not matching datatypes is "[ERROR: Quantity data not interpretable.]"
//expected output if string datatype is "{quantity} [ERROR: Quantity data not in expected Quantity datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: Quantity data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Quantity data not interpretable.]"
const getQuantityDisplay = (quantity) => {
    if (quantity === undefined || quantity === null || quantity === '') {
        return ""
    }
    if (typeof quantity === "object") {
        try {
            let quantityDisplay = "";
            if (quantity.comparator) {
                quantityDisplay = quantity.comparator + " ";
            }
            let value = quantity.value;
            if (value !== undefined && value !== null) {
                quantityDisplay += value.toString();
            }
            if (quantity.unit || quantity.code) {
                if (quantityDisplay) {
                    quantityDisplay += " " + (quantity.unit || quantity.code);
                } else {
                    quantityDisplay = quantity.unit || quantity.code;
                }
            }
            if (quantityDisplay && typeof quantityDisplay === "string") {
                return quantityDisplay;
            }
        } catch { }
    }
    if (typeof quantity === "string") {
        return `${quantity} [ERROR: Quantity data not in expected Quantity datatype.]`
    }
    return "[ERROR: Quantity data not interpretable.]";
}
//expected input of Range datatype (object with {low:SimpleQuantity, high:SimpleQuantity}) SimpleQuantity = Quantity without comparator
//expected output if matching data structure with matching datatypes is "{low} to {high}"
//expected output if matching data structure with matching datatypes AND low and high have same units is "{low} to {high}" but only display units with high
//expected output if matching data structure but missing low is "No Lower Bound to {high}"
//expected output if matching data structure but missing high is "{low} to No Upper Bound"
//expected output if matching data structure but not matching datatypes is "[ERROR: Range data not interpretable.]"
//expected output if string datatype is "{range} [ERROR: Range data not in expected Range datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: Range data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Range data not interpretable.]"
const getRangeDisplay = (range) => {
    if (range === undefined || range === null || range === '') {
        return ""
    }
    if (typeof range === "object") {
        try {
            let low = range.low || null;
            let high = range.high || null;
            let lowUnit = "";
            if (low && typeof (low) === 'object') {
                lowUnit = low.unit || low.code || ""
            }
            let highUnit = "";
            if (high && typeof (high) === 'object') {
                highUnit = high.unit || high.code || ""
            }
            if (low && high && lowUnit === highUnit && typeof (low) === 'object') {
                low = {...low, unit: "", code: ""};
            }
            let lowDisplay = getQuantityDisplay(low);
            let highDisplay = getQuantityDisplay(high);
            if (lowDisplay || highDisplay) {
                return `${low ? lowDisplay : "No Lower Bound"} to ${high ? highDisplay : "No Upper Bound"}`;
            }
        } catch { }
    }
    if (typeof range === "string") {
        return `${range} [ERROR: Range data not in expected Range datatype.]`
    }
    return "[ERROR: Range data not interpretable.]";
}
//expected input of Ratio datatype (object with {numerator:Quantity, denominator:SimpleQuantity}) SimpleQuantity = Quantity without comparator
//expected output if matching data structure with matching datatypes is "[ {numerator} / {denominator} ]"
//expected output if matching data structure but missing numerator is "[ [No Numerator] / {denominator} ]"
//expected output if matching data structure but missing denominator is "[ {numerator} / [No Denominator] ]"
//expected output if matching data structure but not matching datatypes is "[ERROR: Ratio data not interpretable.]"
//expected output if string datatype is "{ratio} [ERROR: Ratio data not in expected Ratio datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: Ratio data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Ratio data not interpretable.]"
const getRatioDisplay = (ratio) => {
    if (ratio === undefined || ratio === null || ratio === '') {
        return ""
    }
    if (typeof ratio === "object" && !Array.isArray(ratio) && (ratio.numerator || ratio.denominator)) {
        try {
            let numeratorDisplay = getQuantityDisplay(ratio.numerator);
            let denominatorDisplay = getQuantityDisplay(ratio.denominator);
            return `[ ${numeratorDisplay ? numeratorDisplay : "[No Numerator]"} / ${denominatorDisplay ? denominatorDisplay : "[No Denominator]"} ]`;
        } catch { }
    }
    if (typeof ratio === "string") {
        return `${ratio} [ERROR: Ratio data not in expected Ratio datatype.]`
    }
    return "[ERROR: Ratio data not interpretable.]";
}
//expected input of RelativeTime datatype (object with {contextReference:Reference, contextDefinition:uri, 
//   contextPath:string, contextCode:CodeableConcept, offset[x]:Duration | Range, text:string} 
//   contextDfintion:uri is actually exact-match resource from FHIR Resource Types)
//expected output if matching data structure with text value is text value
//expected output if matching data structure without text but with matching datatypes is {offsetDuration} || {offsetRange} " from " {contextCode} {contextReference} [" event defined at " {contextDefinition}] [" specific to " {contextPath}]
//expected output if matching data structure but not matching datatypes is "[ERROR: RelativeTime data not interpretable.]"
//expected output if string datatype is "{relativeTime} [ERROR: RelativeTime data not in expected RelativeTime datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: RelativeTime data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: RelativeTime data not interpretable.]"
const getRelativeTimeDisplay = (relativeTime) => {
    if (relativeTime === undefined || relativeTime === null || relativeTime === '') {
        return ""
    }
    if (typeof relativeTime === "object" && !Array.isArray(relativeTime)) {
        try {
            let relativeTimeDisplay = "";
            if (relativeTime.text) {
                relativeTimeDisplay = relativeTime.text;
            } else {
                if (relativeTime.offsetDuration) {
                    relativeTimeDisplay = getQuantityDisplay(relativeTime.offsetDuration) + " from ";
                }
                if (relativeTime.offsetRange) {
                    relativeTimeDisplay = getRangeDisplay(relativeTime.offsetRange) + " from ";
                }
                if (relativeTime.contextCode) {
                    relativeTimeDisplay += getCodeableConceptDisplay(relativeTime.contextCode);
                }
                if (relativeTime.contextReference) {
                    relativeTimeDisplay += getReferenceDisplay(relativeTime.contextReference);
                }
                if (relativeTime.contextDefinition) {
                    relativeTimeDisplay += "event defined at " + relativeTime.contextDefinition;
                }
                if (relativeTime.contextPath) {
                    relativeTimeDisplay += " specific to " + relativeTime.contextPath;
                }
            }
            return relativeTimeDisplay;
        } catch { }
    }
    if (typeof relativeTime === "string") {
        return `${relativeTime} [ERROR: RelativeTime data not in expected RelativeTime datatype.]`
    }
    return "[ERROR: RelativeTime data not interpretable.]";
}
//expected input of Reference datatype (object with {reference:string, type:uri, identifier:Identifier, display:string}) type:uri is actually exact-match resource from FHIR Resource Types
//expected output if matching data structure with matching datatypes (and at least one of display/reference/identifier) is {display} || {reference} || {identifier}
//expected output if matching data structure with matching datatypes (but none of display/reference/identifier) is "[No data for {type} Resource.]"
//expected output if matching data structure but not matching datatypes is "[ERROR: Reference data not interpretable.]"
//expected output if string datatype is "{reference} [ERROR: Reference data not in expected Reference datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: Reference data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Reference data not interpretable.]"
const getReferenceDisplay = (reference) => {
    if (reference === undefined || reference === null || reference === '') {
        return ""
    }
    if (typeof reference === "object") {
        let referenceDisplay = reference.display || reference.reference || getIdentifierDisplay(reference.identifier) || "";
        if (typeof referenceDisplay === "string" && referenceDisplay !== "") {
            return referenceDisplay;
        }
        if (reference.type) {
            return "[No data for " + reference.type + " Resource.]";
        }
    }
    if (typeof reference === "string") {
        return `${reference} [ERROR: Reference data not in expected Reference datatype.]`
    }
    return "[ERROR: Reference data not interpretable.]";
}
//expected input of Timing datatype (object with {event:[dateTime], 
//       repeat: {bounds[x]:Duration/Range/Period, count:positiveInt, countMax:positiveInt, duration:decimal, durationMax:decimal,
//                durationUnit:code, frequency:positiveInt, frequencyMax:positiveInt, period:decimal, periodMax:decimal,
//                periodUnit:code, dayOfWeek:[code], timeOfDay:[time], when:[code], offset:unsignedInt}, 
//       code:CodeableConcept})
//expected output if matching data structure with matching datatypes is noted in the code script
//expected output if string datatype is "{timing} [ERROR: Timing data not in expected Timing datatype.]"
//expected output if missing (undefined/null/'') is ''
//expected output if NaN, empty {}, empty [], or [a,b] is "[ERROR: Timing data not interpretable.]"
//expected output if not otherwise specified is "[ERROR: Timing data not interpretable.]"
const getTimingDisplay = (timing) => {
    if (timing === undefined || timing === null || timing === '') {
        return ""
    }
    if (typeof timing === "object") {
        let timingDisplay = "";
        if (timing.event?.length > 0) {
            timingDisplay = "At " + getDateTimeDisplay(timing.event[0]) + ", ";
        }
        if (timing.event?.length > 1) {
            let i = 1;
            while (i < timing.event.length - 1) {
                timingDisplay += getDateTimeDisplay(timing.event[i]) + ", ";
                i += 1;
            }
            timingDisplay += "and " + getDateTimeDisplay(timing.event[i]) + "; ";
        }
        if (timing.code) {
            timingDisplay += "[[[ " + getCodeableConceptDisplay(timing.code) + " ]]]";
        }
        if (timingDisplay && timing.repeat) {
            timingDisplay += "; ";
        }
        if (timing.repeat) {
            timingDisplay += "repeat ";
            if (timing.repeat.count) {
                timingDisplay += timing.repeat.count + " times ";
            }
            if (timing.repeat.countMax) {
                timingDisplay += "up to " + timing.repeat.countMax + " times ";
            }
            if (timing.repeat.duration) {
                timingDisplay += "for " + timing.repeat.duration + " ";
            }
            if (timing.repeat.durationMax) {
                timingDisplay += "for up to " + timing.repeat.durationMax + " ";
            }
            if (timing.repeat.durationUnit) {
                timingDisplay += timing.repeat.durationUnit + " ";
            }
            if (timing.repeat.frequency) {
                timingDisplay += timing.repeat.frequency + " times per ";
            }
            if (timing.repeat.frequencyMax) {
                timingDisplay += "up to " + timing.repeat.frequencyMax + " times per ";
            }
            if (timing.repeat.period) {
                timingDisplay += timing.repeat.period + " ";
            }
            if (timing.repeat.periodMax) {
                timingDisplay += "up to " + timing.repeat.periodMax + " ";
            }
            if (timing.repeat.periodUnit) {
                timingDisplay += timing.repeat.periodUnit + " ";
            }
            if (timing.repeat.dayOfWeek) {
                timingDisplay += "on days of week (" + timing.repeat.dayOfWeek.toString() + ") ";
            }
            if (timing.repeat.timeOfDay) {
                timingDisplay += "at times of day (" + timing.repeat.timeOfDay.toString() + ") ";
            }
            if (timing.repeat.offset) {
                timingDisplay += timing.repeat.offset + " minutes after "
            }
            if (timing.repeat.when) {
                timingDisplay += "when (" + timing.repeat.when.toString() + ") ";
            }
            if (timing.repeat.boundsDuration) {
                timingDisplay += "within " + getQuantityDisplay(timing.repeat.boundsDuration);
            }
            if (timing.repeat.boundsRange) {
                timingDisplay += "within " + getRangeDisplay(timing.repeat.boundsRange);
            }
            if (timing.repeat.boundsPeriod) {
                timingDisplay += "within " + getPeriodDisplay(timing.repeat.boundsPeriod);
            }
        }
        let timingDisplayLength = timingDisplay.length;
        if (timingDisplay.substring(timingDisplayLength - 2) === ", ") {
            timingDisplay = timingDisplay.substring(0, timingDisplayLength - 2);
        } else if (timingDisplay.substring(timingDisplayLength - 2) === "; ") {
            timingDisplay = timingDisplay.substring(0, timingDisplayLength - 2);
        } else if (timingDisplay.substring(timingDisplayLength - 1) === " ") {
            timingDisplay = timingDisplay.substring(0, timingDisplayLength - 1);
        }
        if (timingDisplay && typeof timingDisplay === 'string') {
            return timingDisplay;
        }
    }
    if (typeof timing === "string") {
        return `${timing} [ERROR: Timing data not in expected Timing datatype.]`
    }
    return "[ERROR: Timing data not interpretable.]";
}

const getStringFromFHIR = {
    'Address': getAddressDisplay,
    'Annotation': getAnnotationDisplay,
    'boolean': getBooleanDisplay,
    'CodeableConcept': getCodeableConceptDisplay,
    'Coding': getCodingDisplay,
    'date': getDateDisplay,
    'dateTime': getDateTimeDisplay,
    'Dosage': getDosageDisplay,
    'Expression': getExpressionDisplay,
    'HumanName': getHumanNameDisplay,
    'Identifier': getIdentifierDisplay,
    'Period': getPeriodDisplay,
    'Quantity': getQuantityDisplay,
    'Range': getRangeDisplay,
    'Ratio': getRatioDisplay,
    'RelativeTime': getRelativeTimeDisplay,
    'Reference': getReferenceDisplay,
    'Timing': getTimingDisplay
};

export default getStringFromFHIR;