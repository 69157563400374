import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { Table } from 'semantic-ui-react';

const ValueSetDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (fhirJson.relatedArtifact && Array.isArray(fhirJson.relatedArtifact) && fhirJson.relatedArtifact.length) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="description">ValueSet Description</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.version && <p><b>Version: </b>{fhirJson.version}</p>}
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
        {fhirJson.scope?.inclusionCriteria && <p><b>Inclusion Criteria: </b>{fhirJson.scope.inclusionCriteria}</p>}
        {fhirJson.scope?.exclusionCriteria && <p><b>Exclusion Criteria: </b>{fhirJson.scope.exclusionCriteria}</p>}
        {fhirJson.copyright && <div><b>Copyright: </b><DisplayFromFHIR markdown={fhirJson.copyright} /><br /><br /></div>}
      </div>
      <h3 id="content">Content</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.compose && <>
          {(Array.isArray(fhirJson.compose.include) && fhirJson.compose.include.length > 0) && <>
            <h4>Included Values</h4>
            {fhirJson.compose.include.map((include, includeIndex) => {
              let includeStyle = {};
              if (fhirJson.compose.include.length > 1) {
                includeStyle = { paddingLeft: "40px" };
              }
              let fevirSystemURL = "";
              let codeSystemFevirId = "";
              if (include.system.substring(0, 39) === 'https://fevir.net/resources/CodeSystem/') {
                fevirSystemURL = include.system;
                codeSystemFevirId = include.system.substring(39);
              }
              return <span key={includeIndex}>
                {fhirJson.compose.include.length > 1 &&
                  <h4>Code System {includeIndex + 1} of {fhirJson.compose.include.length}</h4>}
                <div style={includeStyle}>
                  <>
                    <p><b>System: </b>
                      {fevirSystemURL ?
                        <a href={fevirSystemURL} target="_blank" rel="noopener noreferrer">{fevirSystemURL}</a>
                        :
                        <>{include.system}</>
                      }
                    </p>
                    <p><b>Version: </b>{include.version}</p>
                  </>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Code</Table.HeaderCell>
                        <Table.HeaderCell>Display</Table.HeaderCell>
                        <Table.HeaderCell>Definition</Table.HeaderCell>
                        <Table.HeaderCell>Alternative Terms</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {include.concept.map((concept, conceptIndex) => {
                        return <Table.Row key={conceptIndex}>
                          <Table.Cell style={{ verticalAlign: "top", width: "15%" }}>
                            {codeSystemFevirId
                              ?
                              <a href={"/resources/CodeSystem/" + codeSystemFevirId + "#" + concept.code}
                                target="blank">{concept.code}</a>
                              :
                              <>{concept.code}</>}
                          </Table.Cell>
                          <Table.Cell style={{ verticalAlign: "top", width: "20%" }}>
                            {concept.display}
                          </Table.Cell>
                          <Table.Cell style={{ verticalAlign: "top", width: "50%" }}>
                            {Array.isArray(concept.designation) && 
                            concept.designation.filter(designation => (designation.use?.code === "900000000000550004" || designation.use?.display === "definition" || designation.use?.display === "Definition"))
                            .map(designation => { return designation.value }).join("; ")}
                          </Table.Cell>
                          <Table.Cell style={{ verticalAlign: "top", width: "15%" }}>
                          {Array.isArray(concept.designation) && 
                            concept.designation.filter(designation => (designation.use?.code === "900000000000013009" || designation.use.display === "Alternative term"))
                            .map(designation => { return designation.value }).join("; ")}
                          </Table.Cell>
                        </Table.Row>;
                      })
                      }
                    </Table.Body>
                  </Table>
                </div>
                <br />
              </span>
            })}
          </>}
          {(Array.isArray(fhirJson.compose.exclude) && fhirJson.compose.exclude.length > 0) && <>
            <br />
            <h4>Excluded Values</h4>
            {fhirJson.compose.exclude.map((exclude, excludeIndex) => {
              let excludeStyle = {};
              if (fhirJson.compose.exclude.length > 1) {
                excludeStyle = { paddingLeft: "40px" };
              }
              let fevirSystemURL = "";
              let codeSystemFevirId = "";
              if (exclude.system.substring(0, 39) === 'https://fevir.net/resources/CodeSystem/') {
                fevirSystemURL = exclude.system;
                codeSystemFevirId = exclude.system.substring(39);
              }
              return <span key={excludeIndex}>
                {fhirJson.compose.exclude.length > 1 &&
                  <h4>Code System {excludeIndex + 1} of {fhirJson.compose.exclude.length}</h4>}
                <div style={excludeStyle}>
                  <>
                    <p><b>System: </b>
                      {fevirSystemURL ?
                        <a href={fevirSystemURL} target="_blank" rel="noopener noreferrer">{fevirSystemURL}</a>
                        :
                        <>{exclude.system}</>
                      }
                    </p>
                    <p><b>Version: </b>{exclude.version}</p>
                  </>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Code</Table.HeaderCell>
                        <Table.HeaderCell>Display</Table.HeaderCell>
                        <Table.HeaderCell>Definition</Table.HeaderCell>
                        <Table.HeaderCell>Alternative Terms</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {exclude.concept.map((concept, conceptIndex) => {
                        return <Table.Row key={conceptIndex}>
                          <Table.Cell style={{ verticalAlign: "top", width: "15%" }}>
                            {codeSystemFevirId
                              ?
                              <a href={"/resources/CodeSystem/" + codeSystemFevirId + "#" + concept.code}
                                target="blank">{concept.code}</a>
                              :
                              <>{concept.code}</>}
                          </Table.Cell>
                          <Table.Cell style={{ verticalAlign: "top", width: "20%" }}>
                            {concept.display}
                          </Table.Cell>
                          <Table.Cell style={{ verticalAlign: "top", width: "50%" }}>
                            {Array.isArray(concept.designation) && 
                            concept.designation.filter(designation => designation.use?.code === "900000000000550004")
                            .map(designation => { return designation.value }).join("; ")}
                          </Table.Cell>
                          <Table.Cell style={{ verticalAlign: "top", width: "15%" }}>
                          {Array.isArray(concept.designation) && 
                            concept.designation.filter(designation => designation.use?.code === "900000000000013009")
                            .map(designation => { return designation.value }).join("; ")}
                          </Table.Cell>
                        </Table.Row>;
                      })
                      }
                    </Table.Body>
                  </Table>
                </div>
                <br />
              </span>
            })}
          </>}
        </>}
        {(fhirJson.expansion?.contains && Array.isArray(fhirJson.expansion.contains) && fhirJson.expansion.contains.length > 0) &&
          <>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>System</Table.HeaderCell>
                  <Table.HeaderCell>Code</Table.HeaderCell>
                  <Table.HeaderCell>Display</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {fhirJson.expansion.contains.map((concept, conceptIndex) => {
                  return <Table.Row key={conceptIndex}>
                    <Table.Cell style={{ verticalAlign: "top", width: "35%" }}>
                      <DisplayFromFHIR uri={concept.system} />
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "top", width: "30%" }}>
                      {concept.code}
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "top", width: "35%" }}>
                      {concept.display}
                    </Table.Cell>
                  </Table.Row>;
                })
                }
              </Table.Body>
            </Table></>}
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default ValueSetDisplay;