import EnhancedCharacteristicsTable from './EnhancedCharacteristicsTable';
import { useState, useEffect } from "react";

const InclusionExclusionEnhancedCharacteristicTables = ({editMode, smallCriteriaHeader, groupInclusionCharacteristics, groupExclusionCharacteristics, setGroupCharacteristicsState }) => {
    return <>
        <EnhancedCharacteristicsTable criteriaType={"Inclusion"} editMode={editMode} smallCriteriaHeader={smallCriteriaHeader} groupCriteriaCharacteristics={groupInclusionCharacteristics} setGroupCharacteristicsState={setGroupCharacteristicsState} />
        <br/>
        <EnhancedCharacteristicsTable criteriaType={"Exclusion"} editMode={editMode} smallCriteriaHeader={smallCriteriaHeader} groupCriteriaCharacteristics={groupExclusionCharacteristics} setGroupCharacteristicsState={setGroupCharacteristicsState}  />
    </>
}

const ManageInclusionExclusionEnhancedCharacteristicTables = ({ fhirJson, editMode, setResourceState }) => {
    let groupInclusionCharacteristics = [];
    let groupExclusionCharacteristics = [];
    if (fhirJson.characteristic?.length > 0) {
        for (const characteristic of fhirJson.characteristic) {
            if (characteristic?.description || characteristic?.code) {
                if (characteristic.exclude) {
                    groupExclusionCharacteristics.push(characteristic);
                } else {
                    groupInclusionCharacteristics.push(characteristic);
                }
            }
        }
    }

    const [groupCharacteristicsState, setGroupCharacteristicsState] = useState({
        "resourceJson": fhirJson,
        "groupExclusionCharacteristics": groupExclusionCharacteristics,
        "groupInclusionCharacteristics": groupInclusionCharacteristics
    });

    useEffect(() => {
        if (setResourceState) {
            let inclusionCriteria = [];
            let exclusionCriteria = [];
            if (groupCharacteristicsState.groupInclusionCharacteristics) {
                inclusionCriteria = JSON.parse(JSON.stringify(groupCharacteristicsState.groupInclusionCharacteristics));
            }
            if (groupCharacteristicsState.groupExclusionCharacteristics) {
                exclusionCriteria = JSON.parse(JSON.stringify(groupCharacteristicsState.groupExclusionCharacteristics));
            }
            let newCharacteristic = inclusionCriteria.concat(exclusionCriteria);
            setResourceState(prevState => { return { ...prevState, "characteristic": newCharacteristic } });
        }
    }, [groupCharacteristicsState]);

    return <InclusionExclusionEnhancedCharacteristicTables editMode={editMode}
        smallCriteriaHeader={false} groupInclusionCharacteristics={groupCharacteristicsState.groupInclusionCharacteristics}
        groupExclusionCharacteristics={groupCharacteristicsState.groupExclusionCharacteristics}
        setGroupCharacteristicsState={setGroupCharacteristicsState} />
}

export default ManageInclusionExclusionEnhancedCharacteristicTables;