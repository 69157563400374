import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import React from 'react';
import { getStringFromFHIR } from './ResourceFunctions';

const ContentComponentDisplay = ({ contentSection, componentJson, indexNumber }) => {

  let typeDisplay;
  if (componentJson.type) {
    if (Array.isArray(componentJson.type.coding) && componentJson.type.coding.length > 0) {
      if (componentJson.type.coding[0].display) {
        typeDisplay = componentJson.type.coding[0].display;
      }
    }
    if (!typeDisplay) {
      typeDisplay = getStringFromFHIR.CodeableConcept(componentJson.type);
    }
  }

  let componentHeading;
  let showSummaryHeader = true;
  let boldHeading = false;
  let componentSummary;
  let componentInformationSummary;

  if (typeDisplay) {
    boldHeading = true;
  } else if (componentJson.summary) {
    componentSummary = "Summary";
    showSummaryHeader = false;
    boldHeading = true;
  } else if (componentJson.informationType) {
    componentInformationSummary = componentJson.informationType;
  }

  componentHeading = typeDisplay || componentSummary || componentInformationSummary || contentSection + " Component #" + (indexNumber + 1).toString();

  return <div>
    <br />
    {boldHeading ? <b>{componentHeading}</b> : <>{componentHeading}</>}
    <div style={{ marginLeft: "24px" }}>
      {componentJson.classifier?.length === 1 &&
        <span><DisplayFromFHIR codeableConcept={componentJson.classifier[0]} /><br /></span>}
      {componentJson.classifier?.length > 1 &&
        componentJson.classifier.map((classifier, classifierIndex) => {
          return <div key={classifierIndex}>
            <DisplayFromFHIR codeableConcept={classifier} />
            <br />
          </div>
        })}
      {(componentJson.summary && showSummaryHeader) &&
        <span><b>Summary: </b><DisplayFromFHIR markdown={componentJson.summary} /><br /></span>}
      {(componentJson.summary && !showSummaryHeader) &&
        <span><DisplayFromFHIR markdown={componentJson.summary} /><br /></span>}
      {componentJson.quantity && <span>
        <b>Quantity: </b>{getStringFromFHIR.Quantity(componentJson.quantity)}<br />
      </span>}
      {componentJson.author && <span>
        <b>Author: </b>{getStringFromFHIR.Reference(componentJson.author)}<br />
      </span>}
      {componentJson.path?.length > 0 && <span>
        <b>This content is specific to: </b>{componentJson.path.join(", ")}<br />
      </span>}
      {componentJson.relatedArtifact?.map((relatedArtifactEntry, relatedArtifactIndex) => {
        return <div key={relatedArtifactIndex}>
          {componentJson.relatedArtifact.length === 1 && <span><b>Related Item</b></span>}
          {componentJson.relatedArtifact.length > 1 && <span>
            <b>Related Item {relatedArtifactIndex + 1} of {componentJson.relatedArtifact.length}</b></span>}
          <DisplayFromFHIR relatedArtifact={relatedArtifactEntry} />
        </div>
      })}
      {componentJson.component?.map((subComponentEntry, subComponentIndex) => {
        return <ContentComponentDisplay key={subComponentIndex} contentSection={componentHeading}
          componentJson={subComponentEntry} indexNumber={subComponentIndex} />
      })}
    </div>
  </div>
}

const TopContent = ({ contentSection, contentEntry }) => {
  if (!contentEntry) {
    return <></>
  }
  return <>
    {contentEntry.classifier?.length === 1 && <span>
      <DisplayFromFHIR codeableConcept={contentEntry.classifier[0]} /><br /></span>}
    {contentEntry.classifier?.length > 1 && contentEntry.classifier.map((classifier, classifierIndex) => {
      return <div key={classifierIndex}>
        <DisplayFromFHIR codeableConcept={classifier} />
        <br />
      </div>
    })}
    {contentEntry.summary && <span>
      <b>Summary: </b><br /><DisplayFromFHIR markdown={contentEntry.summary} /><br /></span>}
    {contentEntry.author && <span>
      <b>Author: </b>{getStringFromFHIR.Reference(contentEntry.author)}<br /></span>}
    {contentEntry.path?.length > 0 && <span>
      <b>This content is specific to: </b>{contentEntry.path.join(", ")}<br /></span>}
    {contentEntry.relatedArtifact?.map((relatedArtifactEntry, relatedArtifactIndex) => {
      return <div key={relatedArtifactIndex}>
        {contentEntry.relatedArtifact.length === 1 && <span><b>Related Item</b></span>}
        {contentEntry.relatedArtifact.length > 1 && <span><b>Related Item {relatedArtifactIndex + 1} of {contentEntry.relatedArtifact.length}</b></span>}
        <DisplayFromFHIR relatedArtifact={relatedArtifactEntry} />
      </div>
    })}
    {contentEntry.component?.map((componentEntry, componentIndex) => {
      return <ContentComponentDisplay key={componentIndex} contentSection={contentSection}
        componentJson={componentEntry} indexNumber={componentIndex} />
    })}
  </>
}

const RecommendationJustificationDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {
  let howToCite;
  if (fhirJson.citeAsMarkdown) {
    howToCite = fhirJson.citeAsMarkdown;
  }

  if (!fhirJson.content || !Array.isArray(fhirJson.content) || fhirJson.content.length < 4) {
    return <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <><b>Title: </b> {fhirJson.title}</>
        <br /><br />
         {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") && <>
          <h4>Narrative Summary:</h4>
          <div style={{ marginLeft: "24px" }}>
            <DisplayFromFHIR xhtml={fhirJson.text.div} />
          </div>
        </>}
      </div>
      <h3 id="recommendation">Recommendation</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopContent contentSection="Recommendation" contentEntry={fhirJson.content[0]} />
        <br />
        {fhirJson.artifactReference && <DisplayFromFHIR reference={fhirJson.artifactReference} />}
        {fhirJson.artifactCanonical && <DisplayFromFHIR uri={fhirJson.artifactCanonical} />}
        {fhirJson.artifactUri && <DisplayFromFHIR uri={fhirJson.artifactUri} />}
        <br /><br />
      </div>
      <h3 id="evidence">Evidence</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopContent contentSection="Evidence" contentEntry={fhirJson.content[1]} />
      </div>
      <br />
      <h3 id="net-effect">Net Effect</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopContent contentSection="Net Effect" contentEntry={fhirJson.content[2]} />
      </div>
      <br />
      <h3 id="judgments">Judgments</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopContent contentSection="Judgments" contentEntry={fhirJson.content[3]} />
      </div>
      <br />
      <h3 id="competing-interests">Competing Interests</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopContent contentSection="Competing Interests" contentEntry={fhirJson.content[4]} />
      </div>
      <br />
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default RecommendationJustificationDisplay;