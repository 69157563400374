import React, { useState, useEffect, useContext } from 'react';
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react';
import { DisplayFromFHIR } from './ResourceFunctions';
import { getProjectsResourcesBySpecificTypesList } from './ProjectFunctions';
import FevirContext from './FevirContext';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { ProjectActionDisplay } from './ProjectActions';

const ProjectDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState, fhirEntryState,
  referencedResourceState, setReferencedResourceState, history, changeFormState, formInputsStateRef,
  setFhirEntryState }) => {
  const globalContext = useContext(FevirContext);
  let howToCite;
  let pdfAttachments = [];
  if (fhirJson.relatedArtifact && Array.isArray(fhirJson.relatedArtifact) && fhirJson.relatedArtifact.length) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
      }
      if (relatedArtifactEntry.document && relatedArtifactEntry.document.contentType === "application/pdf") {
        let documentAttachment = relatedArtifactEntry.document;
        let title = "Untitled PDF Attachment";
        if (relatedArtifactEntry.document.title) {
          title = relatedArtifactEntry.document.title;
        } else {
          documentAttachment.title = title;
        }
        if (relatedArtifactEntry.document.data) {
          pdfAttachments.push(documentAttachment);
        }
      }
    }
  }
  let resourceOfTypeCount = 0;

  const [provideInputState, setProvideInputState] = useState({});
  const [validPatientDataBundleState, setValidPatientDataBundleState] = useState(false);
  const [patientDataBundleRadioButtonsState, setPatientDataBundleRadioButtonsState] = useState({});
  const [eligibilityCriteriaCheckBoxesState, setEligibilityCriteriaCheckBoxesState] = useState({});
  const [loadingPatientDataBundleRadioButtonsState, setLoadingPatientDataBundleRadioButtonsState] = useState({ loading: false });
  const [loadingEligibilityCriteriaCheckBoxesState, setLoadingEligibilityCriteriaCheckBoxesState] = useState({ loading: false });

  useEffect(() => {
    const handlePatientDataBundleRadioButtons = async () => {
      let parameters = loadingPatientDataBundleRadioButtonsState.parameters;
      if (parameters) {
        let resourcesList = await getProjectsResourcesBySpecificTypesList(parameters["limitByProject"],
          parameters["limitByResourceType"], globalContext, true);
        if (resourcesList) {
          resourcesList.sort((a, b) => {
            if (a.title && b.title) {
              if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
              if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
            }
            return 0;
          });
          setPatientDataBundleRadioButtonsState(prevState => { return { ...prevState, loaded: true, loading: true, resourcesList: resourcesList }; });
        }
      }
    }
    handlePatientDataBundleRadioButtons();
  }, [loadingPatientDataBundleRadioButtonsState]);

  useEffect(() => {
    const handleEligibilityCriteriaListCheckboxes = async () => {
      let parameters = loadingEligibilityCriteriaCheckBoxesState.parameters;
      if (parameters) {
        let resourcesList = await getProjectsResourcesBySpecificTypesList(parameters["limitByProject"],
          parameters["limitByResourceType"], globalContext, true);
        if (resourcesList) {
          setEligibilityCriteriaCheckBoxesState(prevState => { return { ...prevState, loaded: true, loading: true, resourcesList: resourcesList }; });
        }
      }
    }
    handleEligibilityCriteriaListCheckboxes();
  }, [loadingEligibilityCriteriaCheckBoxesState]);

  return <div>
    {
      fhirEntryState.projectResourcesLoaded !== true
      &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <div style={{ marginTop: "12px" }}>
      <h3 id="project-title">Project Title</h3>
      <div style={{ marginLeft: "24px" }}>
        <p>{fhirJson.title}</p>
      </div>
      <h3 id="project-description">Project Description</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayFromFHIR markdown={fhirJson.description} />
      </div>
      <h3 id="project-actions">Project Actions</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.action ?
          <table className={"ProjectActionsTable"}>
            <tbody>
              {fhirJson.action && fhirJson.action.map((action, actionIndex) => {
                return <tr key={actionIndex}>
                  <ProjectActionDisplay actionJson={action} projectJson={fhirJson} editMode={false}
                    fhirEntryState={fhirEntryState} referencedResourceState={referencedResourceState}
                    setReferencedResourceState={setReferencedResourceState}
                    history={history} provideInputState={provideInputState}
                    setProvideInputState={setProvideInputState}
                    validPatientDataBundleState={validPatientDataBundleState}
                    setValidPatientDataBundleState={setValidPatientDataBundleState}
                    patientDataBundleRadioButtonsState={patientDataBundleRadioButtonsState}
                    setPatientDataBundleRadioButtonsState={setPatientDataBundleRadioButtonsState}
                    eligibilityCriteriaCheckBoxesState={eligibilityCriteriaCheckBoxesState}
                    setEligibilityCriteriaCheckBoxesState={setEligibilityCriteriaCheckBoxesState}
                    loadingPatientDataBundleRadioButtonsState={loadingPatientDataBundleRadioButtonsState}
                    setLoadingPatientDataBundleRadioButtonsState={setLoadingPatientDataBundleRadioButtonsState}
                    loadingEligibilityCriteriaCheckBoxesState={loadingEligibilityCriteriaCheckBoxesState}
                    setLoadingEligibilityCriteriaCheckBoxesState={setLoadingEligibilityCriteriaCheckBoxesState}
                    changeFormState={changeFormState} formInputsStateRef={formInputsStateRef}
                    setFhirEntryState={setFhirEntryState} />
                </tr>
              })}
            </tbody>
          </table>
          :
          <p>This project has no actions.</p>
        }
      </div>
      <h3 id="project-details">Project Details</h3>
      <div style={{ marginLeft: "24px" }}>
        {(Array.isArray(fhirJson.detail) && fhirJson.detail.length > 0) ?
          fhirJson.detail.map((detail, detailIndex) => {
            if ((detail.substring(0, 8) === "https://" || detail.substring(0, 7) === "http://") && !detail.includes(" ")) {
              return <div key={detailIndex}><a href={detail} target="_blank" rel="noopener noreferrer">{detail}</a><br /><br /></div>;
            } else {
              return <div style={{ whiteSpace: "pre-wrap" }} key={detailIndex}><DisplayFromFHIR markdown={detail} /><br /><br /></div>;
            }
          })
          :
          <p>No details.</p>}
      </div>
      <h3 id="associated-links">Associated Links</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.associatedLink?.length > 0 ?
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>URL</Table.HeaderCell>
                <Table.HeaderCell style={{ width: "70%" }}>Description</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {fhirJson.associatedLink.map((link, index) => {
                return <Table.Row key={index}>
                  <Table.Cell><a href={link.url} target="_blank" rel="noopener noreferrer" style={{ wordBreak: "break-all" }}>{link.url}</a></Table.Cell>
                  <Table.Cell>{link.description ? link.description : ""}</Table.Cell>
                </Table.Row>;
              })
              }
            </Table.Body>
          </Table>
          :
          <>None</>
        }
      </div>
      <h3 id="associated-documents">Associated Documents</h3>
      <div style={{ marginLeft: "24px" }}>
        {pdfAttachments?.length > 0
          ?
          pdfAttachments.map((attachment, contentIndex) => {
            return <span key={contentIndex}><DisplayFromFHIR attachment={attachment} /><br /></span>
          })
          :
          <>None</>
        }
      </div>
      <h3 id="associated-resources">Associated FHIR<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup> Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirEntryState.projectResources?.length > 0
          ?
          fhirEntryState.projectResources.map((resource, resourceIndex) => {
            if (resourceIndex === 0 || resource.resourcetype !== fhirEntryState.projectResources[resourceIndex - 1].resourcetype) {
              resourceOfTypeCount = 0;
            }
            resourceOfTypeCount += 1;
            return <div key={resourceIndex}>
              {(resourceIndex === 0 || resource.resourcetype !== fhirEntryState.projectResources[resourceIndex - 1].resourcetype) &&
                <div className="resourceListHeaderTextForProject" style={{ width: "100%", marginTop: "12px", marginBottom: "8px" }}>
                  <span className="resourceListHeader" style={{ fontWeight: "bold", paddingLeft: "30%", cursor: "auto" }}>
                    <span>{resource.resourcetype} <b style={{ padding: "none !important", fontWeight: "normal" }}>Resources</b></span>
                  </span><br />
                </div>
              }
              <>{resourceOfTypeCount}. </> <a href={"/resources/" + resource.resourcetype + "/" + resource.id}>{resource.title}</a>
            </div>
          })
          :
          <>None</>
        }
      </div>
      <h3 id="software-code">Software Code</h3>
      <div style={{ marginLeft: "24px" }}>
        {(Array.isArray(fhirJson.softwareCode) && fhirJson.softwareCode.length > 0) ?
          fhirJson.softwareCode.map((codeScript, codeScriptIndex) => {
            return <span key={codeScriptIndex}><DisplayFromFHIR expression={codeScript} /><br /></span>
          })
          :
          <>None</>}
      </div>
      <h3 id="reference-list">Reference List</h3>
      <div>
        {fhirJson.referenceList?.length > 0 ?
          <ol style={{ marginTop: "0px" }}>
            {fhirJson.referenceList.map((reference, referenceIndex) => {
              let availableAt;
              if (reference.summary) {
                if (reference.summary.includes("Available at: ")) {
                  availableAt = reference.summary.substring(reference.summary.indexOf("Available at: ") + 14, reference.summary.length - 1);
                  if (availableAt === "" || availableAt.includes(" ") || !availableAt.includes("http")) {
                    availableAt = undefined;
                  }
                }
              }
              return <li key={referenceIndex}>
                {reference.reference?.reference ?
                  <>{availableAt ?
                    <>
                      <a href={"/resources/" + reference.reference.reference} target="_blank" rel="noopener noreferrer">{reference.summary.substring(0, reference.summary.indexOf("Available at: "))}</a>
                      <>Available at: </>
                      <a href={availableAt} target="_blank" rel="noopener noreferrer">{availableAt}</a>
                      .&nbsp;{fhirJson.enableRoBAT && <>
                        <Button className="formButton" style={{ color: "#000000", padding: "2px" }} content="Rate risk of bias"
                          onClick={() => {
                            window.open("/createriskofbiasassessment/" + reference.reference.reference + "/" + fhirJson.id + "/", "_blank"); //opens RoBAT tool in new tab
                          }}
                          disabled={fhirEntryState.previousVersionLoaded}
                        />
                      </>}</>
                    :
                    <><a href={"/resources/" + reference.reference.reference} target="_blank" rel="noopener noreferrer">{reference.summary || reference.reference.display || reference.description}</a> {fhirJson.enableRoBAT && <>
                      <Button className="formButton" style={{ color: "#000000", padding: "2px" }} content="Rate risk of bias"
                        onClick={() => {
                          window.open("/createriskofbiasassessment/" + reference.reference.reference + "/" + fhirJson.id + "/", "_blank"); //opens RoBAT tool in new tab
                        }}
                        disabled={fhirEntryState.previousVersionLoaded}
                      />
                    </>}</>
                  }</>
                  :
                  <>{reference.summary || reference.description}</>
                }

                {reference.description && <><br /><div style={{ paddingLeft: "16px" }}><b>Description: </b><DisplayFromFHIR markdown={reference.description} /></div></>}
                {reference.robatRating?.length > 0 && <><br /><span style={{ paddingLeft: "16px" }}><b>Risk of bias ratings:</b> {
                  reference.robatRating.map((robatRating, robatRatingIndex) => {
                    return <span key={robatRatingIndex}>
                      <a href={"/resources/" + robatRating.reference} target="_blank" rel="noopener noreferrer">{robatRating.display}</a>{(robatRatingIndex < reference.robatRating.length - 1) && <b>, </b>}
                    </span>
                  })
                }</span></>}
              </li>
            })}
          </ol>
          :
          <div style={{ marginLeft: "24px" }}>None</div>
        }
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <b>Citation Summary: </b>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <br /><br />
      <span style={{ fontSize: "12px" }}>The FEvIR Platform uses the HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> standard (<a href="http://build.fhir.org/" target="_blank" rel="noopener noreferrer">current build</a>). HL7<sup>&#174;</sup>, and FHIR<sup>&#174;</sup> are the registered trademarks of Health Level Seven International and their use of these trademarks does not constitute an endorsement by HL7.</span>
    </div>
  </div>
}

export default ProjectDisplay;