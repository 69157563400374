import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment} from 'semantic-ui-react';
import { TextField } from '@mui/material';
import submitToFevirServer from './SubmitToFevirServer';
import FevirContext from './FevirContext';

const CEDARSearchImporterPage = ({ useTitle }) => {

  useTitle("FEvIR®: CEDAR Search Importer");

  const globalContext = useContext(FevirContext);

  const [cedarEntryState, setCedarEntryState] = useState({
    "searchQueryString": "", "projectFoi": "",
    "loadingSubmission": false, "bundle": ""
  });

  const changeCedarEntryState = (newValue, field) => {
    setCedarEntryState(prevState => {
      return {
        ...prevState,
        [field]: newValue
      };
    });
  };

  const history = useHistory();

  const importCedarSearchResults = async () => {
    if (!cedarEntryState.searchQueryString) {
      alert("Please enter a search query.")
      return;
    }

    changeCedarEntryState(true, "loadingSubmission");

    const body = {
      'functionid': "cedarsearchimport",
      'searchQueryString': cedarEntryState.searchQueryString,
      'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 120000, body, true, false);
    changeCedarEntryState(false, "loadingSubmission");
    if (response && response.success) {
      history.push(`/resources/Project/${response.projectFOI}`);
    } else {
      changeCedarEntryState(false, "loadingSubmission");
      if (response && response.error) {
        alert(response.error);
      } else {
        alert("Error importing CEDAR Search."); //PLACEHOLDER
      }
    }
    //REDIRECT to the PROJECT PAGE OR ERROR ALERT    

  };

  const importCedarAndMedlineSearchResults = async () => {
    if (!cedarEntryState.searchQueryString) {
      alert("Please enter a search query.")
      return;
    }

    changeCedarEntryState(true, "loadingSubmission");

    const body = {
      'functionid': "cedarsearchimport",
      'searchQueryString': cedarEntryState.searchQueryString,
      'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 120000, body, true, false);

    if (response && response.success) {
      let projectFOI = response.projectFOI;
      const medlinebody = {
        'functionid': 'importmedlinesearchquery',
        'medlinequery': cedarEntryState.searchQueryString + " AND (systematic[sb] OR guideline[sb])",
        'projectfoi': projectFOI,
        'idToken': ''
      };
      let medlineresponse = await submitToFevirServer(globalContext, 120000, medlinebody, true, false);
      if (medlineresponse?.success) {
        //perhaps modify the project page to note the addition of MEDLINE content
        if (medlineresponse.noResults) {
          alert("No results found for this query.");
        }
      } else {
        alert("Something went wrong with the MEDLINE search.");
      }

      changeCedarEntryState(false, "loadingSubmission");
      history.push(`/resources/Project/${projectFOI}`);
    } else {
      changeCedarEntryState(false, "loadingSubmission");
      if (response && response.error) {
        alert(response.error);
      } else {
        alert("Error importing CEDAR Search."); //PLACEHOLDER
      }
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && !globalContext.alertModalState.open) {
      importCedarSearchResults();
    }
  }

  return (
    <div>
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        <br /><br />
        {globalContext.userState.firebaseuid ?
          <>
            <TextField style={{ width: "240px" }} className="inputField" type='text' size="small" variant='outlined'
              label='Search String' value={cedarEntryState.searchQueryString} onKeyUp={handleKeyUp}
              onChange={(e) => { changeCedarEntryState(e.target.value, "searchQueryString"); }} />
            <br /><br />
            <Button className="formButton positive" content="Search CEDAR" compact
              onClick={importCedarSearchResults} />
            &nbsp;&nbsp;&nbsp;
            <Button className="formButton positive" content="Search CEDAR and MEDLINE" compact
              onClick={importCedarAndMedlineSearchResults} />
            <br /><br />
            <img className={cedarEntryState.loadingSubmission ? 'visible' : 'invisible'} style={{ height: "22px" }}
              src="/spinner.gif" alt="Loading" />
            <br />
            {cedarEntryState.bundle && <div style={{ "textAlign": "left", "width": "100%" }}>
              <p><b>Returned Bundle:</b></p>
              <span>{cedarEntryState.bundle}</span>
            </div>}
          </>
          :
          <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
        }
      </Segment>
    </div>
  );
};

export default CEDARSearchImporterPage;