import React, { useState, useImperativeHandle, useEffect } from 'react';
import { DataEntry, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import { SimpleResourceFieldViewer } from './ResourceFunctions';

const ValueSetEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson, classificationsArrayState, classificationsLoadedState }) => {
/*
  const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "status", "experimental",
    "date", "publisher", "contact", "description", "useContext", "jurisdiction", "immutable", "purpose", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "compose", "expansion", "scope"]
*/
  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "resourceType": "ValueSet", "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "versionAlgorithmString": fhirJson.versionAlgorithmString, "versionAlgorithmCoding": fhirJson.versionAlgorithmCoding,
    "name": fhirJson.name, "title": fhirJson.title, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "immutable": fhirJson.immutable, "purpose": fhirJson.purpose, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "compose": fhirJson.compose, "expansion": fhirJson.expansion, "scope": fhirJson.scope,
    "newClassifications": null
  });

  const [scopeState, setScopeState] = useState({ "inclusionCriteria": fhirJson.scope?.inclusionCriteria || "", "exclusionCriteria": fhirJson.scope?.exclusionCriteria || "" })

  useImperativeHandle(formInputsStateRef, () => ({
    valueSetState: resourceState
  }), [resourceState]);

  useEffect(() => {
    if (Object.keys(scopeState).length > 0) {
      let newScope = {};
      if (scopeState.extension) { newScope.extension = scopeState.extension; }
      if (scopeState.inclusionCriteria) { newScope.inclusionCriteria = scopeState.inclusionCriteria; }
      if (scopeState.exclusionCriteria) { newScope.exclusionCriteria = scopeState.exclusionCriteria; }
      if (Object.keys(newScope).length === 0) {
        newScope = null;
      }
      setResourceState(prevState => { return { ...prevState, "scope": newScope } });
    }
  }, [scopeState]);

  return <div style={{ marginTop: "12px" }}>
      <h3 id="description">ValueSet Description</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='version' fieldLabel='Version'
          startingValue={resourceState.version} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startingValue={resourceState.description} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='inclusionCriteria' fieldLabel='Inclusion Criteria'
          startingValue={scopeState.inclusionCriteria} setResourceState={setScopeState} />
        <DataEntry datatype='string' elementName='exclusionCriteria' fieldLabel='Exclusion Criteria'
          startingValue={scopeState.exclusionCriteria} setResourceState={setScopeState} />
        <DataEntry datatype='markdown' elementName='copyright' fieldLabel='Copyright'
          startingValue={resourceState.copyright} setResourceState={setResourceState} />
      </div>
      <h3 id="content">Content</h3>
      <div style={{ marginLeft: "24px" }}></div>
      <DataEntry datatype='ValueSetCompose' elementName='compose' fieldLabel='Compose (content logical definition)' 
        startingValue={resourceState.compose} setResourceState={setResourceState} />
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
}

export default ValueSetEdit;
