import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Dropdown } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import { getRobatValueSetResourcesData } from './ResourceFunctions';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const CreateNewRiskOfBiasAssessmentForm = ({ editExistingAssessment, fhirJson, changeFormState, ratedResourceType, ratedResourceId, associatedProjectFoi, getResource }) => {
  const globalContext = useContext(FevirContext);
  const [loadingState, setLoadingState] = useState(true);

  const [fhirEntryState, setFhirEntryState] = useState({ "title": "", "freeToShare": "freeToShare", "ratedArtifact": "", "component": [], "status": "draft", "approvalDate": "", "lastReviewDate": "", "copyright": "" });

  const [dataLoadedState, setDataLoadedState] = useState(false);

  const [valueSetDictionaryState, setValueSetDictionaryState] = useState({});

  const [profileListLoadedState, setProfileListLoadedState] = useState(false);
  const [profileSelectedState, setProfileSelectedState] = useState("Base");
  const [profileListState, setProfileListState] = useState(["Base"]);
  const [profileOptions, setProfileOptions] = useState([{ key: "Base", text: "No profile selected", value: "Base" }]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [codeSystemsJsonState, setCodeSystemsJsonState] = useState({});

  const [factorPresenceLookup, setFactorPresenceLookup] = useState({});

  const [potentialInfluenceLookup, setPotentialInfluenceLookup] = useState({});

  const [biasDirectionLookup, setBiasDirectionLookup] = useState({});

  const [biasRiskLookup, setBiasRiskLookup] = useState({});

  const changeFhirEntryState = (newValue, field) => {
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        [field]: newValue
      };
    });
  };

  const history = useHistory();

  const convertComponentToJson = (components) => {
    let componentsJson = [];
    for (let index in components) {
      let component = components[index];
      let componentJson = {
        informationType: "rating",
        summary: component.description,
        type: {
          coding: [{ system: component.valueSetCodeSystemUrl, code: component.valueSetCode, display: component.valueSetDisplay }]
        },
        classifier: [],
        component: convertComponentToJson(component.component)
      };

      for (let noteIndex in component.note) {
        let note = component.note[noteIndex];
        if (note) {
          let noteComponent = {
            informationType: "comment",
            summary: note
          };
          componentJson.component.push(noteComponent);
        }
      }

      if (component.factorPresence) {
        let factorPresence = factorPresenceLookup[component.factorPresence];
        componentJson.classifier.push({ coding: [{ system: factorPresence.system, code: factorPresence.code, display: factorPresence.display }] });
      }

      if (component.potentialInfluence) {
        let potentialInfluence = potentialInfluenceLookup[component.potentialInfluence];
        componentJson.classifier.push({ coding: [{ system: potentialInfluence.system, code: potentialInfluence.code, display: potentialInfluence.display }] });
      }

      if (component.biasDirection) {
        let biasDirection = biasDirectionLookup[component.biasDirection];
        componentJson.classifier.push({ coding: [{ system: biasDirection.system, code: biasDirection.code, display: biasDirection.display }] });
      }

      if (component.biasRisk) {
        let biasRisk = biasRiskLookup[component.biasRisk];
        componentJson.classifier.push({ coding: [{ system: biasRisk.system, code: biasRisk.code, display: biasRisk.display }] });
      }

      componentsJson.push(componentJson);
    }
    return componentsJson;
  }

  const getProfileStructure = async (valueSetCode) => {

    let profileName = "Base";
    if (profileSelectedState) {
      profileName = profileSelectedState;
    }
    let valueSetCodeSystemUrl = "https://fevir.net/resources/CodeSystem/27270";

    let codeKey = valueSetCodeSystemUrl + "#" + valueSetCode + "&" + profileName;

    const blankComponent = { "informationType": "rating", "type": "", "rating": [], "component": [], "loadedSubcomponents": [], "factorPresence": "", "potentialInfluence": "", "biasDirection": "", "biasRisk": "", "codeKey": "", "valueSetCodeSystemUrl": "", "valueSetCode": "", "valueSetDisplay": "", "valueSetDefinition": "", "status": "", expanded: true };
    let thisComponent = JSON.parse(JSON.stringify(blankComponent));

    thisComponent.valueSetCode = valueSetCode;

    let codesAndDetails = {};
    let codeDisplays = [];

    if (valueSetDictionaryState) {
      let valueSetKey = valueSetCodeSystemUrl + "#" + valueSetCode;
      valueSetKey += "&" + profileName;

      let valueSetInfo = valueSetDictionaryState[valueSetKey];
      if (valueSetInfo === undefined && valueSetDictionaryState[valueSetCodeSystemUrl + "#" + valueSetCode + "&Base"]) {
        valueSetInfo = valueSetDictionaryState[valueSetCodeSystemUrl + "#" + valueSetCode + "&Base"];
      }
      if (valueSetInfo) {
        let jsonResource = valueSetInfo.resource;
        if (jsonResource) {
          let jsonResourceProfile;
          for (let jsonResourceUseContextIndex in jsonResource.useContext) {
            let useContext = jsonResource.useContext[jsonResourceUseContextIndex];
            if (useContext.code?.display === "when this profile is selected" && useContext.valueCodeableConcept?.text) {
              jsonResourceProfile = useContext.valueCodeableConcept.text;
            }
          }
          if (jsonResource.compose) {
            //for (let includeIndex in jsonResource.compose.include) { }
            let include = jsonResource.compose.include[0];
            for (let conceptIndex in include.concept) {
              let concept = include.concept[conceptIndex];

              let codeDetailsKey = include.system + "#" + concept.code + "&" + profileName;
              if (codesAndDetails[codeDetailsKey] === undefined) {
                codesAndDetails[codeDetailsKey] = { "system": include.system, "code": concept.code, "display": concept.display };
                codeDisplays.push(concept.display);
              }
            }
          }
        }
      }
    }
    for (let codesAndDetailsIndex in codesAndDetails) {
      let codeDetail = codesAndDetails[codesAndDetailsIndex];
      let subComponents = await getProfileStructure(codeDetail.code);
      subComponents = subComponents.component;
      thisComponent["component"].push({ "description": "", "note": [], "type": "", "rating": "", "component": subComponents, "loadedSubcomponents": [], "factorPresence": "", "potentialInfluence": "", "biasDirection": "", "biasRisk": "", "status": "active", "codeKey": codeKey, "valueSetCodeSystemUrl": codeDetail.system, "valueSetCode": codeDetail.code, "valueSetDisplay": codeDetail.display, "valueSetDefinition": "" });
    }
    return thisComponent;
  }

  const submitResource = async () => {
    let outputJson;
    if (editExistingAssessment) {
      outputJson = JSON.parse(JSON.stringify(fhirJson));
    } else {
      outputJson = {
        resourceType: "ArtifactAssessment",
        title: "",
        status: "",
        useContext: [
          {
            "code": {
              "display": "to be viewed by this tool"
            },
            "valueCodeableConcept": {
              "text": "Computable Publishing: Risk of Bias Assessment Tool"
            }
          }
        ],
        copyright: "",
        approvalDate: "",
        lastReviewDate: "",
        artifactReference: {
          reference: fhirEntryState.ratedArtifactReference,
          type: fhirEntryState.ratedArtifactType,
          display: fhirEntryState.ratedArtifact,
        },
        content: []
      };
    }

    let contentInformation = await getProfileStructure("SEVCO:00001");

    outputJson["content"] = [{
      informationType: "rating",
      summary: fhirEntryState.component[0].description,
      type: {
        coding: [{ system: "https://fevir.net/resources/CodeSystem/27270", code: "SEVCO:00001", display: "Bias" }]
      },
      classifier: [],
      author: {},
      freeToShare: true,
      component: convertComponentToJson(contentInformation.component)
    }];

    outputJson["title"] = fhirEntryState.title;
    outputJson["approvalDate"] = fhirEntryState.approvalDate;
    outputJson["lastReviewDate"] = fhirEntryState.lastReviewDate;
    outputJson["copyright"] = fhirEntryState.copyright;
    outputJson["status"] = fhirEntryState.status;

    let profileName = "Base";
    if (profileSelectedState) {
      profileName = profileSelectedState;
    }
    outputJson["content"].push({
      informationType: "classifier",
      type: {
        text: "Profile"
      },
      classifier: [{ text: profileName }]
    });

    //And then run code to delete empty fields

    let fhirEntryString = JSON.stringify(outputJson, null, 2);

    let date = new Date();
    let submitDateTime = date.toISOString();
    let ratedArtifactDisplay = fhirEntryState.ratedArtifactReference || fhirEntryState.ratedArtifact;
    let computerFriendlyName = "RoB of " + ratedArtifactDisplay + " - " + fhirEntryState.userId + " - " + submitDateTime;

    let body = {
      'functionid': "submitfhirresource",
      'idToken': "",
      'fhirEntry': fhirEntryString,
      'title': outputJson.title || computerFriendlyName,
      'status': fhirEntryState.status,
    };

    if (associatedProjectFoi) {
      body.associatedProjectFoi = associatedProjectFoi;
    }

    let response = await submitToFevirServer(globalContext, 5000, body, true, false);

    if (response.success) {
      history.push(`/resources/${response.resourcetype}/${response.formstateid}/edit`);
    }

  };

  const loadData = async () => {
    if (!globalContext.userState.loading && globalContext.userState.id && dataLoadedState === false) {
      setDataLoadedState(true);
      let failed = false;
      let component = [{ "description": "", "note": [], "informationType": "", "type": "", "rating": [], "rater": "", "component": [], "loadedSubcomponents": [], "factorPresence": "", "potentialInfluence": "", "biasDirection": "", "biasRisk": "", "codeKey": "", "valueSetCodeSystemUrl": "https://fevir.net/resources/CodeSystem/27270", "valueSetCode": "SEVCO:00001", "valueSetDisplay": "Bias", "valueSetDefinition": "" }];

      let fhirArtifactTitle;
      let ratedArtifactUrl;

      let rater;
      let raterLoaded;

      if (editExistingAssessment && fhirJson && ratedResourceId === undefined) {
        if (fhirJson.artifactReference?.display) {
          fhirArtifactTitle = fhirJson.artifactReference.display;
          ratedArtifactUrl = fhirJson.artifactReference.reference;
        }
        if (ratedArtifactUrl) {
          let urlParts = ratedArtifactUrl.split("/");
          if (urlParts.length === 2) {
            ratedResourceType = urlParts[0];
            ratedResourceId = urlParts[1];
          }
        }
      }
      if (ratedResourceId) {
        let resourceEntry = await getResource(ratedResourceId, ratedResourceType, globalContext.userState.idToken);
        if (resourceEntry.found) {

          let title;
          let copyright;

          let approvalDate = "";
          let lastReviewDate = "";

          fhirArtifactTitle = resourceEntry["title"] || ratedResourceType + "/" + ratedResourceId;
          component[0].informationType = "rating";
          title = globalContext.userState.name + "'s Risk of Bias Assessment of " + fhirArtifactTitle;
          ratedArtifactUrl = ratedResourceType + "/" + ratedResourceId;
          copyright = "https://creativecommons.org/licenses/by-nc-sa/4.0/";

          window.history.replaceState(null, "", "/createriskofbiasassessment");

          setFhirEntryState(prevState => {
            if (raterLoaded) {
            } else {
              rater = prevState.rater;
              raterLoaded = prevState.raterLoaded;
            }
            return {
              ...prevState,
              rater: rater,
              raterLoaded: raterLoaded,
              component: component,
              title: title,
              ratedArtifact: fhirArtifactTitle,
              ratedArtifactType: ratedResourceType,
              ratedArtifactReference: ratedArtifactUrl,
              copyright: copyright,
              approvalDate: approvalDate,
              lastReviewDate: lastReviewDate,
              failedToLoadResource: false
            };
          });
          //setProfileSelectedState(profileSelected);
        } else {
          failed = true;
        }
      } else {
        failed = true;
      }
      if (fhirEntryState.ratedArtifact) {

      } else {
        if (failed) {
          changeFhirEntryState(true, "failedToLoadResource");
        }
      }
      changeFhirEntryState(globalContext.userState.name, "rater");
      changeFhirEntryState(globalContext.userState.id, "userId");
      changeFhirEntryState(true, "raterLoaded");

      if (profileListLoadedState === false) {
        let valueSetResourcesData = await getRobatValueSetResourcesData(globalContext);
        if (valueSetResourcesData?.success) {
          setProfileListState(valueSetResourcesData.profileList);
          setProfileOptions(valueSetResourcesData.profileOptionsList);
          setCodeSystemsJsonState(valueSetResourcesData.codeSystemsJson);
          setValueSetDictionaryState(valueSetResourcesData.valueSetDictionary);
        }
        setSubmitDisabled(false);
        setProfileListLoadedState(true);
      }
    }
  }

  useEffect(async () => {
    let isMounted = true;
    if (isMounted) {
      loadData();
    }
    return () => { isMounted = false };
  }, [globalContext.userState]);

  useEffect(async () => {
    let isMounted = true;
    if (isMounted && dataLoadedState === false) {
      changeFormState("Computable Publishing®: Risk of Bias Assessment Tool", "pageTitle");
      loadData();
      if (!editExistingAssessment && (ratedResourceType === undefined || ratedResourceId === undefined)) {
        changeFhirEntryState(true, "failedToLoadResource");
      }
      setLoadingState(false);
    }
    return () => { isMounted = false };
  }, []);

  return (
    <div>
      {globalContext.userState.firebaseuid ?
        <>
          <div style={{ border: "3px solid #DAA520", padding: "6px", backgroundColor: "#FCFAEF" }}>
            <span><b>Instructions:</b></span>
            <ol style={{ margin: "4px" }}>
              <li>Edit the <b>Title of this risk of bias assessment</b> below as you desire</li>
              <li>If desired, <b>Select a profile</b>. A profile will narrow down the types of bias to rate.
                <ul>
                  <li>If no profile is selected, hundreds of terms will be available for choosing the types of bias to rate.</li>
                  <li>ROB1 — 7 types of bias, for randomized trials</li>
                  <li>ROB2 — 26 types of bias, for randomized trials</li>
                  <li>ROBIS — 30 types of bias, for systematic reviews</li>
                  <li>Randomized trial — Many types of bias, for randomized trials</li>
                  <li>Systematic review — Many types of bias, for systematic reviews</li>
                </ul>
              </li>
              <li>Click the <b>Start</b> button to continue.</li>
            </ol>
          </div>
          <br />
          {(ratedResourceType && ratedResourceId) &&
            <span><b>Risk of Bias Assessment of: </b>{(fhirEntryState.ratedArtifactReference && !fhirEntryState.ratedArtifactReference.includes("http")) ? <a href={"/resources/" + fhirEntryState.ratedArtifactReference} target="_blank" rel="noopener noreferrer" >{fhirEntryState.ratedArtifact}</a> : fhirEntryState.ratedArtifact}</span>
          }
          <br />
          <table className="noStyleTable" style={{ marginTop: "8px", marginBottom: "8px" }}><tbody><tr>
            <td><b>Title of this risk of bias assessment: </b></td>
            <td><TextField style={{ width: "760px" }} multiline className="inputField" type='text' label={'Title'} size="small" variant='outlined' value={fhirEntryState.title} onChange={(e) => { changeFhirEntryState(e.target.value, "title"); }} /></td>
            {editExistingAssessment !== true && <td>&nbsp;&nbsp;(You may change the title now. You won't be able to change the title later.)</td>}
          </tr></tbody></table>
          <b>Select a profile: </b><Dropdown placeholder={"Profile"} closeOnChange search selection selectOnBlur={false}
            style={{ width: "280px" }}
            options={profileOptions}
            value={profileSelectedState}
            onChange={(e, data) => { setProfileSelectedState(data.value); }}
          />
          <br /><br />
          <Button className="formButton positive" content="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Start&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" compact onClick={submitResource} disabled={submitDisabled} />
          <br /><br /><br />
          <br />
          <br />
          <table className="noStyleTable"><tbody>
            <tr>
              <td><b>Copyright for this risk of bias assessment: </b></td>
              <td><TextField style={{ width: "400px" }} multiline className="inputField" type='text' label={'Copyright'} size="small" variant='outlined' value={fhirEntryState.copyright} onChange={(e) => { changeFhirEntryState(e.target.value, "copyright"); }} /></td>
              {editExistingAssessment !== true && <td>&nbsp;&nbsp;(Unless changed, the default copyright goes to <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noopener noreferrer">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International (CC BY-NC-SA 4.0)</a>)</td>}
            </tr>
          </tbody></table>
        </>
        :
        <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
      }
      <br />
    </div>
  );
};

export default CreateNewRiskOfBiasAssessmentForm;