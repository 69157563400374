import React, { useState, useEffect, memo } from 'react';
import { DataEntry } from './DataEntryFormFunctions';
import { Button } from 'semantic-ui-react';
import { DisplayFromFHIR } from './GetDatatypeDisplayFunctions';

const handleChange = (name, value, setResourceState) => {
  if (name.at(-1) === "]") {
    let nameSplit = name.split("[");
    setResourceState(prevState => {
      let newValue = prevState[nameSplit[0]].map((entry, entryIndex) => {
        if (entryIndex === parseInt(nameSplit[1])) {
          return value;
        } else {
          return entry;
        }
      })
      return { ...prevState, [nameSplit[0]]: newValue }
    });
  } else {
    setResourceState(prevState => { return { ...prevState, [name]: value } })
  }
}

const ExpandToAddOrEdit = ({ startingValue, setStartCollapsedState }) => {
  return <span className={"unselectable"} style={{ cursor: "pointer" }}
    onClick={() => { setStartCollapsedState(false) }}>
    {startingValue ? <>✎ Edit</> : <>➕ Add</>}
  </span>
}

const SectionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed, topSection }) => {
  let sectionCode;
  if (startingValue?.code) {
    if (startingValue.code.coding && startingValue.code.coding[0].code) {
      sectionCode = startingValue.code.coding[0].code;
    } else if (startingValue.code.text) {
      sectionCode = startingValue.code.text;
    }
  }

  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];
  let orderedByValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
  ];
  let emptyReasonValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }
  ];
  let entryReferencedResourceTypes;
  switch (sectionCode) {
    case "EvidenceVariable-outcome":
      entryReferencedResourceTypes = ['EvidenceVariable'];
      break;
    case "SampleSize":
      entryReferencedResourceTypes = ['Evidence'];
      break;
    case "Control-group-alone-Evidence":
      entryReferencedResourceTypes = ['Evidence'];
      break;
    case "Intervention-group-alone-Evidence":
      entryReferencedResourceTypes = ['Evidence'];
      break;
    case "result with intervention with calculated value derived from the control group and effect estimate":
      entryReferencedResourceTypes = ['Evidence'];
      break;
    case "Intervention-vs-Control-Evidence":
      entryReferencedResourceTypes = ['Evidence'];
      break;
    case "Certainty-of-Evidence":
      entryReferencedResourceTypes = ['ArtifactAssessment', 'Evidence'];
      break;
    case "Assertion":
      entryReferencedResourceTypes = ['Evidence'];
      break;
    default:
      entryReferencedResourceTypes = ['ActivityDefinition', 'ArtifactAssessment', 'Citation', 'Composition', 'Evidence', 'EvidenceVariable', 'Group', 'List', 'Organization', 'PlanDefinition', 'ResearchStudy'];
  }
  if (topSection === "Column-Headers") {
    entryReferencedResourceTypes = null;
  }

  let startingSection = {
    title: "", code: "", author: [], text: "", orderedBy: "", entry: [], emptyReason: ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }

  const [sectionState, setSectionState] = useState(startingSection);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(sectionState).length > 0) {
      let newSection = {};
      if (sectionState.extension) { newSection.extension = sectionState.extension; }
      if (sectionState.title) { newSection.title = sectionState.title; }
      if (sectionState.code) { newSection.code = sectionState.code; }
      if (sectionState.author && Array.isArray(sectionState.author) && sectionState.author.length > 0) {
        newSection.author = sectionState.author;
      }
      if (sectionState.focus) { newSection.focus = sectionState.focus; }
      if (sectionState.text) { newSection.text = sectionState.text; }
      if (sectionState.orderedBy) { newSection.orderedBy = sectionState.orderedBy; }
      if (sectionState.entry && Array.isArray(sectionState.entry) && sectionState.entry.length > 0) {
        newSection.entry = sectionState.entry;
      }
      if (sectionState.emptyReason) { newSection.emptyReason = sectionState.emptyReason; }
      if (sectionState.section && Array.isArray(sectionState.section) && sectionState.section.length > 0) {
        newSection.section = sectionState.section;
      }
      if (newSection.section?.length || newSection.entry?.length || 
        (newSection.text && newSection.text.status && newSection.text.status !== "empty")) {
          delete newSection.emptyReason;
        } else if (!newSection.emptyReason) {
          newSection.emptyReason = {
            "coding": [{
              "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
              "code": "notstarted", "display": "Not Started"
            }]
          }
        }
      if (Object.keys(newSection).length === 0) {
        newSection = null;
      }
      handleChange(elementName, newSection, setResourceState);
    }
  }), [sectionState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <div style={{ marginLeft: "24px" }}>
          {startingSection.title && <p><b>Title: </b>{startingSection.title}</p>}
          {startingSection.text?.div && <p><b>Summary: </b>{startingSection.text.div}</p>}
          {startingSection.entry?.length > 0 && <div>
            {startingSection.entry.map((entry, entryIndex) => {
              return <div key={entryIndex} style={{ marginLeft: "24px" }}>
                {entry && <span><b>Entry: </b><DisplayFromFHIR reference={entry} /></span>}
              </div>
            })}
          </div>}
          <br />
        </div>}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
        &nbsp;&nbsp;
        {fieldLabel}
      </div>
    </>
  } else {
    return <>
      <p><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Column Title'
          startingValue={sectionState.title} setResourceState={setSectionState} />
        <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Section Code'
          startingValue={sectionState.code} startCollapsed
          setResourceState={setSectionState} />
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Table Cell'
          startingValue={sectionState.text} setResourceState={setSectionState} />
        {topSection === "Summary of findings entry for a single outcome" &&
          <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel='Section Author'
              startingValue={sectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              startCollapsed startEmptyArrayClosed
              setResourceState={setSectionState} />
            <h3>Section Content (as Resource Entries)</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='orderedBy' fieldLabel='Ordered by'
                startingValue={sectionState.orderedBy} valueSet={orderedByValueSet} startCollapsed
                setResourceState={setSectionState} />
              <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='Content Entry'
                startingValue={sectionState.entry} referencedResourceTypes={entryReferencedResourceTypes}
                startCollapsed startEmptyArrayClosed
                setResourceState={setSectionState} />
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={sectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                setResourceState={setSectionState} />
            </div>
          </>}
      </div>
    </>
  }
})

const SummaryOfFindingsSubsectionDataEntry = memo(({ startingValue, setResourceState, topSection }) => {
  let showDataEntry = true;
  let startingArrayAsObject = {};
  if (startingValue) {
    for (let itemIndex in startingValue) {
      startingArrayAsObject[itemIndex] = startingValue[itemIndex];
    }
  } else {
    showDataEntry = false;
  }

  const [arrayState, setArrayState] = useState(startingArrayAsObject);
  const [showDataEntryState, setShowDataEntryState] = useState(showDataEntry);

  useEffect((() => {
    if (Object.keys(arrayState).length) {
      let newArray = [];
      for (let key of Object.keys(arrayState)) {
        if (arrayState[key] !== null && !(typeof arrayState[key] === "object" && Object.keys(arrayState[key]).length === 0) && !(Array.isArray(arrayState[key]) && arrayState[key].length === 0) && arrayState[key] !== "" &&
          arrayState[key] !== undefined && arrayState[key] !== "DELETEME") {
          newArray.push(arrayState[key]);
        }
      }
      if (newArray.length > 0) {
        handleChange("section", newArray, setResourceState);
      } else {
        handleChange("section", null, setResourceState);
      }
    }
  }), [arrayState]);

  return <div>
    <div style={{ marginLeft: "24px" }}>
      {(showDataEntryState === true) &&
        Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
          return <div key={keyValuePairIndex}>
            {keyValuePair[1] === "DELETEME" ?
              <>
                <p><b>{'Column entry ' + (keyValuePairIndex + 1)}: </b></p>
                <div style={{ marginLeft: "24px" }}>WILL BE DELETED.</div>
              </>
              :
              <SectionEntry elementName={keyValuePair[0]} topSection={topSection}
                fieldLabel={'Column entry ' + (keyValuePairIndex + 1)}
                startingValue={keyValuePair[1]} setResourceState={setArrayState}
                startCollapsed={keyValuePair[1] && true} />
            }
            {keyValuePairIndex > 0 && <span style={{ marginLeft: "72px" }}>
              <span onClick={() => {
                  setArrayState(prevState => {
                    return {
                      ...prevState,
                      [keyValuePairIndex.toString()]: "DELETEME"
                    };
                  });
                }}>x Delete this Column entry</span>
              <br /><br /><br />
            </span>}
          </div>
        })}
      <><br />
        <Button className="formButton" style={{ color: "#000000" }} content={"+ Add Section"}
          onClick={() => {
            setArrayState(prevState => {
              let arrayLength = Object.keys(prevState).length;
              let arrayIndexNumber = arrayLength.toString();
              if (prevState['0'] === null) {
                arrayIndexNumber = '1';
              }
              return {
                ...prevState,
                [arrayIndexNumber]: ""
              };
            });
            if (!showDataEntryState) {
              setShowDataEntryState(true);
            }
          }} />
      </>
    </div>
  </div>
});

export default SummaryOfFindingsSubsectionDataEntry;