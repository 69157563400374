import submitToFevirServer from "./SubmitToFevirServer";

const getVariableDefinitionResources = async (evidenceJson) => {
  if (!evidenceJson || evidenceJson.resourceType !== "Evidence" || !evidenceJson.variableDefinition || evidenceJson.variableDefinition.length === 0) {
    return [];
  }
  let resourceFois = [];
  let variableDefinitionMap = {};
  for (let variableDefinitionIndex in evidenceJson.variableDefinition) {
    let variableDefinition = evidenceJson.variableDefinition[variableDefinitionIndex];
    if (variableDefinition.variableRole) {
      if (typeof variableDefinition.variableRole === "string") {

            if (variableDefinition.variableRole === "population") {
              variableDefinitionMap.population = variableDefinition;
              if (variableDefinition.observed?.reference) {
                variableDefinitionMap.populationObservedReference = variableDefinition.observed.reference;
                let foi = variableDefinition.observed.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.populationObservedFoi = foi;
                  resourceFois.push(foi);
                }
              }
              if (variableDefinition.intended?.reference) {
                variableDefinitionMap.populationIntendedReference = variableDefinition.intended.reference;
                let foi = variableDefinition.intended.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.populationIntendedFoi = foi;
                  resourceFois.push(foi);
                }
              }
            } else if (variableDefinition.variableRole === "exposure") {
              variableDefinitionMap.exposure = variableDefinition;
              if (variableDefinition.observed?.reference) {
                variableDefinitionMap.exposureObservedReference = variableDefinition.observed.reference;
                let foi = variableDefinition.observed.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.exposureObservedFoi = foi;
                  resourceFois.push(foi);
                }
              }
              if (variableDefinition.intended?.reference) {
                variableDefinitionMap.exposureIntendedReference = variableDefinition.intended.reference;
                let foi = variableDefinition.intended.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.exposureIntendedFoi = foi;
                  resourceFois.push(foi);
                }
              }
            } else if (variableDefinition.variableRole === "referenceExposure") {
              variableDefinitionMap.referenceExposure = variableDefinition;
              if (variableDefinition.observed?.reference) {
                variableDefinitionMap.referenceExposureObservedReference = variableDefinition.observed.reference;
                let foi = variableDefinition.observed.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.referenceExposureObservedFoi = foi;
                  resourceFois.push(foi);
                }
              }
              if (variableDefinition.intended?.reference) {
                variableDefinitionMap.referenceExposureIntendedReference = variableDefinition.intended.reference;
                let foi = variableDefinition.intended.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.referenceExposureIntendedFoi = foi;
                  resourceFois.push(foi);
                }
              }
            } else if (variableDefinition.variableRole === "outcome" || variableDefinition.variableRole === "measuredVariable") {
              variableDefinitionMap.outcome = variableDefinition;
              if (variableDefinition.observed?.reference) {
                variableDefinitionMap.outcomeObservedReference = variableDefinition.observed.reference;
                let foi = variableDefinition.observed.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.outcomeObservedFoi = foi;
                  resourceFois.push(foi);
                }
              }
              if (variableDefinition.intended?.reference) {
                variableDefinitionMap.outcomeIntendedReference = variableDefinition.intended.reference;
                let foi = variableDefinition.intended.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.outcomeIntendedFoi = foi;
                  resourceFois.push(foi);
                }
              }
            }

      }
      if (variableDefinition.variableRole.coding) {
        for (let codingIndex in variableDefinition.variableRole.coding) {
          if (variableDefinition.variableRole.coding[codingIndex].code) {
            if (variableDefinition.variableRole.coding[codingIndex].code === "population") {
              variableDefinitionMap.population = variableDefinition;
              if (variableDefinition.observed?.reference) {
                variableDefinitionMap.populationObservedReference = variableDefinition.observed.reference;
                let foi = variableDefinition.observed.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.populationObservedFoi = foi;
                  resourceFois.push(foi);
                }
              }
              if (variableDefinition.intended?.reference) {
                variableDefinitionMap.populationIntendedReference = variableDefinition.intended.reference;
                let foi = variableDefinition.intended.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.populationIntendedFoi = foi;
                  resourceFois.push(foi);
                }
              }
            } else if (variableDefinition.variableRole.coding[codingIndex].code === "exposure") {
              variableDefinitionMap.exposure = variableDefinition;
              if (variableDefinition.observed?.reference) {
                variableDefinitionMap.exposureObservedReference = variableDefinition.observed.reference;
                let foi = variableDefinition.observed.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.exposureObservedFoi = foi;
                  resourceFois.push(foi);
                }
              }
              if (variableDefinition.intended?.reference) {
                variableDefinitionMap.exposureIntendedReference = variableDefinition.intended.reference;
                let foi = variableDefinition.intended.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.exposureIntendedFoi = foi;
                  resourceFois.push(foi);
                }
              }
            } else if (variableDefinition.variableRole.coding[codingIndex].code === "referenceExposure") {
              variableDefinitionMap.referenceExposure = variableDefinition;
              if (variableDefinition.observed?.reference) {
                variableDefinitionMap.referenceExposureObservedReference = variableDefinition.observed.reference;
                let foi = variableDefinition.observed.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.referenceExposureObservedFoi = foi;
                  resourceFois.push(foi);
                }
              }
              if (variableDefinition.intended?.reference) {
                variableDefinitionMap.referenceExposureIntendedReference = variableDefinition.intended.reference;
                let foi = variableDefinition.intended.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.referenceExposureIntendedFoi = foi;
                  resourceFois.push(foi);
                }
              }
            } else if (variableDefinition.variableRole.coding[codingIndex].code === "measuredVariable") {
              variableDefinitionMap.outcome = variableDefinition;
              if (variableDefinition.observed?.reference) {
                variableDefinitionMap.outcomeObservedReference = variableDefinition.observed.reference;
                let foi = variableDefinition.observed.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.outcomeObservedFoi = foi;
                  resourceFois.push(foi);
                }
              }
              if (variableDefinition.intended?.reference) {
                variableDefinitionMap.outcomeIntendedReference = variableDefinition.intended.reference;
                let foi = variableDefinition.intended.reference.split("/")[1];
                if (!isNaN(foi)) {
                  variableDefinitionMap.outcomeIntendedFoi = foi;
                  resourceFois.push(foi);
                }
              }
            }
          }
        }
      }
    }
  }
  const body = {
    'functionid': 'getopenresources',
    'resourceids': resourceFois,
    'idToken': ''
  };
  let response = await submitToFevirServer({ userState: "", setUserState: "", firebaseConfig: "", continuedSessionLogin: "" }, 10000, body, true, false);
  if (response?.success && response.resourceentries) {
    response.resourceentries.forEach((entry) => {
      if (entry.id === variableDefinitionMap.populationObservedFoi) {
        variableDefinitionMap.populationObservedResource = JSON.parse(entry.toolstatestring);
      } else if (entry.id === variableDefinitionMap.populationIntendedFoi) {
        variableDefinitionMap.populationIntendedResource = JSON.parse(entry.toolstatestring);
      } else if (entry.id === variableDefinitionMap.exposureObservedFoi) {
        variableDefinitionMap.exposureObservedResource = JSON.parse(entry.toolstatestring);
      } else if (entry.id === variableDefinitionMap.exposureIntendedFoi) {
        variableDefinitionMap.exposureIntendedResource = JSON.parse(entry.toolstatestring);
      } else if (entry.id === variableDefinitionMap.referenceExposureObservedFoi) {
        variableDefinitionMap.referenceExposureObservedResource = JSON.parse(entry.toolstatestring);
      } else if (entry.id === variableDefinitionMap.referenceExposureIntendedFoi) {
        variableDefinitionMap.referenceExposureIntendedResource = JSON.parse(entry.toolstatestring);
      } else if (entry.id === variableDefinitionMap.outcomeObservedFoi) {
        variableDefinitionMap.outcomeObservedResource = JSON.parse(entry.toolstatestring);
      } else if (entry.id === variableDefinitionMap.outcomeIntendedFoi) {
        variableDefinitionMap.outcomeIntendedResource = JSON.parse(entry.toolstatestring);
      }
    });
  }
  return variableDefinitionMap;
};

export default getVariableDefinitionResources;