import React, { useState, useEffect, useContext, memo } from 'react';
import { Button, Dropdown, Modal } from 'semantic-ui-react';
import { TextField, RadioGroup, Radio, FormControl, FormControlLabel, FormLabel, Checkbox, Input, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DatePicker, KeyboardTimePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, parseISO } from 'date-fns';
import UCUM from "./UCUM";
import { DisplayFromFHIR, getStringFromFHIR } from './ResourceFunctions';
import SEVCO from './SEVCO';
import {
  ActivityDefinitionDynamicValueEntry, ActivityDefinitionParticipantEntry, CharacteristicDefinitionByCombinationEntry,
  CharacteristicDefinitionByTypeAndValueEntry, CharacteristicExecutableExpressionEntry, CharacteristicTimeFromEventEntry,
  SearchPieceEntry, CodeSystemPropertyEntry, CodeSystemConceptDesignationEntry,
  EvidenceVariableCategoryEntry, EvidenceVariableDefinitionEntry,
  EvidenceStatisticEntry, EvidenceStatisticSampleSizeEntry, EvidenceStatisticAttributeEstimateEntry,
  EvidenceStatisticModelCharacteristicEntry, EvidenceStatisticModelCharacteristicVariableEntry,
  ListEntryEntry,
  PlanDefinitionActionEntry, PlanDefinitionActorEntry, PlanDefinitionGoalEntry,
  ResearchStudyAssociatedPartyEntry, ResearchStudyComparisonGroupEntry, ResearchStudyLabelEntry,
  ResearchStudyObjectiveEntry, EstimandEntry, EventHandlingEntry,
  ResearchStudyOutcomeMeasureEntry, ResearchStudyProgressStatusEntry,
  ResearchStudyRecruitmentEntry, StudyAmendmentEntry,
  ValueSetComposeEntry, ValueSetComposeIncludeEntry, ValueSetComposeIncludeConceptEntry, ConceptDesignationEntry
} from './DataEntryFormResourceBackboneFunctions';
import { RecommendationJustificationComponentEntry, RecommendationJustificationContentEntry } from './RecommendationJustificationEdit';
import { CompositionTableCellEntry } from './DataEntryFormResourceBackboneFunctions';
import GenericToggleButtons from './GenericToggleButtons';
import submitToFevirServer from './SubmitToFevirServer';
import FevirContext from './FevirContext';
import { ProjectActionEntry, ProjectActionParameterEntry } from './ProjectActionBuilder';
import ProfilesByResourceType from './ProfilesByResourceType';
import { getResourceDictionaryFromFoiList } from './ResourceDictionaryFunctions';
import ManageInclusionExclusionEnhancedCharacteristicTables from './ManageInclusionExclusionEnhancedCharacteristicTables';
import { ButtonWithConfirmModal } from './ConfirmModal';
import { AdaptItemDataEntry } from './AdaptItemDataEntryFunctions';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } });
}

const alertIfInvalidNumberEntry = (e) => {
  if (e.nativeEvent.data === null) {
  } else if (e.nativeEvent.data.length === 1 && /^[0-9.+-]*$/.test(e.nativeEvent.data)) {
  } else if (e.nativeEvent.data.length > 1 && /^-?[0-9]\d*(\.\d+)?$/.test(e.nativeEvent.data)) {
  } else {
    alert('You entered data with characters unsuitable for number data entry (e.g. space, letter, or comma). Data will not be retained on saving.')
  }
};

const generateCitation = (fhirJson, citedFOI) => {
  let resourceType = fhirJson.resourceType || "Resource";
  let citedArtifactUrl = fhirJson.url || "TBD";
  let computableArtifactUrl = citedArtifactUrl;
  if (resourceType === "Composition") {
    computableArtifactUrl = "https://fevir.net/FLI/DocumentForComposition" + fhirJson.id;
  }
  let articleDate = "";
  let lastRevisionDate = "";
  if (fhirJson.approvalDate) {
    articleDate = fhirJson.approvalDate;
  }
  if (fhirJson.date) {
    lastRevisionDate = fhirJson.date.slice(0, 10);
  } else if (fhirJson.meta?.lastUpdated) {
    lastRevisionDate = fhirJson.meta.lastUpdated.slice(0, 10);
  }

  if (!citedFOI) { citedFOI = "TBD"; }
  let citationTitle = "Citation for " + resourceType + ": ";
  let citedArtifactTitle = [];
  let citationSummaryTitle;
  if (fhirJson.title) {
    citationTitle += fhirJson.title;
    citedArtifactTitle.push({
      "type": [
        {
          "coding": [
            {
              "system": "http://hl7.org/fhir/title-type",
              "code": "human-use",
              "display": "Human use"
            }
          ]
        }
      ],
      "text": fhirJson.title
    });
    citationSummaryTitle = fhirJson.title;
  } else if (fhirJson.name) {
    citationTitle += fhirJson.name;
    citedArtifactTitle.push({
      "type": [
        {
          "coding": [
            {
              "system": "http://hl7.org/fhir/title-type",
              "code": "machine-use",
              "display": "Machine use"
            }
          ]
        }
      ],
      "text": fhirJson.name
    });
    citationSummaryTitle = fhirJson.name;
  } else {
    citationTitle += "[Untitled.]";
    citationSummaryTitle = "[Untitled.]";
  }
  let citedArtifactIdentifier = fhirJson.identifier || [];
  let languageCode = fhirJson.language || "[Not specified.]";
  let citedArtifactCopyright = fhirJson.copyright || "https://creativecommons.org/licenses/by-nc-sa/4.0/";
  let citedArtifactAbstract = [];
  if (fhirJson.description) {
    citedArtifactAbstract.push({
      "text": fhirJson.description,
      "type": {
        "text": "Description"
      }
    });
  }
  let contributorshipEntryList = [];
  let contributorshipSummary = "";
  let extensionTitle = "";
  let extensionName = "";
  let extensionAuthor = [];
  let extensionReviewer = [];
  let extensionEditor = [];
  let extensionEndorser = [];
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (const extension of fhirJson.extension) {
      switch (extension.url) {
        case 'http://hl7.org/fhir/StructureDefinition/artifact-title':
          extensionTitle = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-name':
          extensionName = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-url':
          citedArtifactUrl = extension.valueUri;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-date':
          lastRevisionDate = extension.valueDateTime.slice(0, 10);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-author':
          extensionAuthor.push(extension.valueContactDetail.name);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-reviewer':
          extensionReviewer.push(extension.valueContactDetail.name);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-editor':
          extensionEditor.push(extension.valueContactDetail.name);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-endorser':
          extensionEndorser.push(extension.valueContactDetail.name);
          break;
        default:
        //
      }
    }
  }
  if (fhirJson.author) {
    if (typeof fhirJson.author === "object" && !Array.isArray(fhirJson.author)) {
      fhirJson.author = [fhirJson.author];
    }
    let authorList = fhirJson.author.map((author, authorIndex) => {
      return {
        "contributor": {
          "display": author.name || author.display
        },
        "role": {
          "coding": [
            {
              "system": "http://hl7.org/fhir/contributor-role",
              "code": "author",
              "display": "Author/Creator"
            }
          ]
        }
      }
    });
    contributorshipEntryList = contributorshipEntryList.concat(authorList);
    let authorNameList = fhirJson.author.map((author, authorIndex) => { return author.name || author.display }).join(', ');
    contributorshipSummary += authorNameList + " [Authors/Creators]. ";
  }
  if (extensionAuthor.length > 0) {
    contributorshipSummary += extensionAuthor.join(', ') + " [Authors/Creators]. ";
  }
  if (fhirJson.reviewer) {
    let reviewerList = fhirJson.reviewer.map((reviewer, reviewerIndex) => {
      return {
        "contributor": {
          "display": reviewer.name
        },
        "role": {
          "coding": [
            {
              "system": "http://hl7.org/fhir/contributor-role",
              "code": "reviewer",
              "display": "Reviewer"
            }
          ]
        }
      }
    })
    contributorshipEntryList = contributorshipEntryList.concat(reviewerList);
    let reviewerNameList = fhirJson.reviewer.map((reviewer, reviewerIndex) => { return reviewer.name }).join(', ');
    contributorshipSummary += reviewerNameList + " [Reviewers]. ";
  }
  if (extensionReviewer.length > 0) {
    contributorshipSummary += extensionReviewer.join(', ') + " [Reviewers]. ";
  }
  if (fhirJson.editor) {
    let editorList = fhirJson.editor.map((editor, editorIndex) => {
      return {
        "contributor": {
          "display": editor.name
        },
        "role": {
          "coding": [
            {
              "system": "http://hl7.org/fhir/contributor-role",
              "code": "editor",
              "display": "Editor"
            }
          ]
        }
      }
    })
    contributorshipEntryList = contributorshipEntryList.concat(editorList);
    let editorNameList = fhirJson.editor.map((editor, editorIndex) => { return editor.name }).join(', ');
    contributorshipSummary += editorNameList + " [Editors]. ";
  }
  if (extensionEditor.length > 0) {
    contributorshipSummary += extensionEditor.join(', ') + " [Editors]. ";
  }
  if (fhirJson.endorser) {
    let endorserList = fhirJson.endorser.map((endorser, endorserIndex) => {
      return {
        "contributor": {
          "display": endorser.name
        },
        "role": {
          "coding": [
            {
              "system": "http://hl7.org/fhir/contributor-role",
              "code": "endorser",
              "display": "Endorser"
            }
          ]
        }
      }
    })
    contributorshipEntryList = contributorshipEntryList.concat(endorserList);
    let endorserNameList = fhirJson.endorser.map((endorser, endorserIndex) => { return endorser.name }).join(', ');
    contributorshipSummary += endorserNameList + " [Endorsers]. ";
  }
  if (extensionEndorser.length > 0) {
    contributorshipSummary += extensionEndorser.join(', ') + " [Endorsers]. ";
  }
  if (citationSummaryTitle === "[Untitled.]") {
    citationSummaryTitle = extensionTitle || extensionName || "[Untitled.]";
  }
  let citationSummary = citationSummaryTitle + ' [Database Entry: FHIR ' + resourceType + ' Resource]. Contributors: ' + contributorshipSummary;
  if (resourceType === 'Project' || resourceType === 'SoftwareScript' || resourceType === 'ArtifactComment' ||
    resourceType === 'Characteristic' || resourceType === 'CommonDataStructure' || resourceType === 'CommonDatStructureArtifact' ||
    resourceType === 'EBMDecisionRating' || resourceType === 'EBMRecommendation' || resourceType === 'EvidenceReport' ||
    resourceType === 'Recommendation' || resourceType === 'SchemaElement') {
    citationSummary = citationSummaryTitle + ' [Database Entry: FEvIR ' + resourceType + ' Resource]. Contributors: ' + contributorshipSummary;
  }
  citationSummary += "In: Fast Evidence Interoperability Resources (FEvIR) Platform, FOI " + citedFOI + ".";
  if (articleDate) {
    citationSummary += " Published " + articleDate + ".";
  }
  if (lastRevisionDate) {
    citationSummary += " Revised " + lastRevisionDate + ".";
  }
  if (citedArtifactUrl) {
    citationSummary += " Available at: " + citedArtifactUrl + ". Computable resource at: " + computableArtifactUrl + ".";
  }
  let citationJson = {
    "resourceType": "Citation",
    "title": citationTitle,
    "status": "active",
    "publisher": "Computable Publishing LLC",
    "contact": [
      {
        "telecom": [
          {
            "system": "email",
            "value": "support@computablepublishing.com"
          }
        ]
      }
    ],
    "description": "A citation of a Resource on the FEvIR Platform",
    "useContext": [{
      "code": {
        "system": "http://hl7.org/fhir/citation-classification-type",
        "code": "fevir-platform-use",
        "display": "FEvIR Platform Use"
      },
      "valueCodeableConcept":
      {
        "coding": [
          {
            "system": "http://hl7.org/fhir/citation-artifact-classifier",
            "code": "fhir-resource",
            "display": "FHIR Resource"
          }
        ]
      }
    }],
    "copyright": "https://creativecommons.org/licenses/by-nc-sa/4.0/",
    "author": [
      {
        "name": "automated generation from FEvIR Platform"
      }
    ],
    "summary": [
      {
        "style": {
          "coding": [
            {
              "system": "http://hl7.org/fhir/citation-summary-style",
              "code": "comppub",
              "display": "Computable Publishing"
            }
          ]
        },
        "text": citationSummary
      }
    ],
    "citedArtifact": {
      "identifier": citedArtifactIdentifier,
      "title": citedArtifactTitle,
      "abstract": citedArtifactAbstract,
      "publicationForm": [
        {
          "publishedIn": {
            "type": {
              "coding": [
                {
                  "system": "http://hl7.org/fhir/published-in-type",
                  "code": "D019991",
                  "display": "Database"
                }
              ]
            },
            "title": "Fast Evidence Interoperability Resources (FEvIR) Platform",
            "publisher": {
              "display": "Computable Publishing LLC"
            },
            "publisherLocation": "Ipswich, MA, USA"
          },
          "citedMedium": {
            "coding": [
              {
                "system": "http://hl7.org/fhir/cited-medium",
                "code": "Internet",
                "display": "Internet"
              }
            ]
          },
          "articleDate": articleDate,
          "lastRevisionDate": lastRevisionDate,
          "language": [
            {
              "text": languageCode
            }
          ],
          "accessionNumber": citedFOI,
          "copyright": citedArtifactCopyright
        }
      ],
      "webLocation": [
        {
          "classifier": [
            {
              "coding": [
                {
                  "system": "http://hl7.org/fhir/artifact-url-classifier",
                  "code": "computable-resource",
                  "display": "Computable resource"
                }
              ]
            }
          ],
          "url": citedArtifactUrl
        }
      ],
      "classification": [
        {
          "type": {
            "coding": [
              {
                "system": "http://hl7.org/fhir/cited-artifact-classification-type",
                "code": "knowledge-artifact-type",
                "display": "Knowledge Artifact Type"
              }
            ]
          },
          "classifier": [
            {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/179423",
                  "code": "classified-as-database-entry",
                  "display": "Database Entry"
                }
              ]
            },
            {
              "coding": [
                {
                  "system": "http://hl7.org/fhir/citation-artifact-classifier",
                  "code": "fhir-resource",
                  "display": "FHIR Resource"
                }
              ]
            }
          ]
        },
        {
          "type": {
            "coding": [
              {
                "system": "http://hl7.org/fhir/citation-artifact-classifier",
                "code": "fhir-resource",
                "display": "FHIR Resource"
              }
            ]
          },
          "classifier": [
            {
              "coding": [
                {
                  "system": "http://hl7.org/fhir/fhir-types",
                  "code": resourceType,
                  "display": resourceType
                }
              ]
            }
          ]
        }
      ],
      "contributorship": {
        "entry": contributorshipEntryList
      }
    }
  }
  if (resourceType === 'ActivityDefinition' || resourceType === 'PlanDefinition' || resourceType === 'Evidence' ||
    resourceType === 'EvidenceVariable' || resourceType === 'SoftwareScript' || resourceType === 'Characteristic' ||
    resourceType === 'CodeSystem' || resourceType === 'ValueSet' || resourceType === 'Project') {
    let relatedArtifactCiteAs = {
      "type": "cite-as",
      "citation": citationSummary
    };
    let citeAsFound = false;
    let newRelatedArtifactList = [];
    if (fhirJson.relatedArtifact && Array.isArray(fhirJson.relatedArtifact) && fhirJson.relatedArtifact.length > 0) {
      newRelatedArtifactList = fhirJson.relatedArtifact.map((relatedArtifactEntry) => {
        if (relatedArtifactEntry.type === "cite-as") {
          citeAsFound = true;
          return relatedArtifactCiteAs;
        } else {
          return relatedArtifactEntry;
        }
      })
    }
    if (citeAsFound === false) {
      newRelatedArtifactList.push(relatedArtifactCiteAs);
    }
    fhirJson.relatedArtifact = newRelatedArtifactList;
  } else if (resourceType === 'Composition') {
    citationJson.citedArtifact.webLocation = [
      {
        "classifier": [
          {
            "coding": [
              {
                "system": "http://hl7.org/fhir/artifact-url-classifier",
                "code": "computable-resource",
                "display": "Computable resource"
              },
              {
                "system": "http://hl7.org/fhir/fhir-types",
                "code": "Bundle",
                "display": "Bundle"
              }
            ]
          }
        ],
        "url": computableArtifactUrl
      },
      {
        "classifier": [
          {
            "coding": [
              {
                "system": "http://hl7.org/fhir/artifact-url-classifier",
                "code": "computable-resource",
                "display": "Computable resource"
              },
              {
                "system": "http://hl7.org/fhir/fhir-types",
                "code": "Composition",
                "display": "Composition"
              }
            ]
          }
        ],
        "url": citedArtifactUrl
      }
    ];
    let relatesToCiteAs = {
      "type": "cite-as",
      "citation": citationSummary
    };
    let citeAsFound = false;
    let newRelatesToList = [];
    if (fhirJson.relatesTo && Array.isArray(fhirJson.relatesTo) && fhirJson.relatesTo.length > 0) {
      newRelatesToList = fhirJson.relatesTo.map((relatedArtifactEntry) => {
        if (relatedArtifactEntry.type === "cite-as") {
          citeAsFound = true;
          relatedArtifactEntry.citation = citationSummary;
          return relatedArtifactEntry;
        } else {
          return relatedArtifactEntry;
        }
      })
    }
    if (citeAsFound === false) {
      newRelatesToList.push(relatesToCiteAs);
    }
    fhirJson.relatesTo = newRelatesToList;
  } else if (resourceType === 'ArtifactAssessment') {
    fhirJson.citeAsMarkdown = citationSummary;
  } else if (resourceType === "Group" || resourceType === "List") {
    let relatedArtifactCiteAsExtension = {
      "url": "http://hl7.org/fhir/StructureDefinition/artifact-relatedArtifact",
      "valueRelatedArtifact": {
        "type": "cite-as",
        "citation": citationSummary
      }
    };
    let citeAsFound = false;
    let newRelatedArtifactExtensionList = [];
    if (fhirJson.extension && Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
      newRelatedArtifactExtensionList = fhirJson.extension.map((extension) => {
        if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-relatedArtifact' &&
          extension.valueRelatedArtifact.type === "cite-as") {
          citeAsFound = true;
          return relatedArtifactCiteAsExtension;
        } else {
          return extension;
        }
      })
    }
    if (citeAsFound === false) {
      newRelatedArtifactExtensionList.push(relatedArtifactCiteAsExtension);
    }
    fhirJson.extension = newRelatedArtifactExtensionList;
  }
  return [citationSummary, citationJson, fhirJson]
};

const DisplayHowToCite = memo(({ citationSummary, citationJson }) => {
  return <div>
    <p>Update the Resource to refresh How to Cite information if the content has changed.</p>
    <p style={{ marginBottom: "0px" }}><b>Citation Summary:</b></p>
    <DisplayFromFHIR markdown={citationSummary} />
    <p style={{ marginBottom: "0px" }}><b>Citation Resource JSON:</b></p>
    <DisplayFromFHIR markdown={JSON.stringify(citationJson)} />
  </div>
});

const ChooseItemForCodeableConceptArrayEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, valueSet, dataEntryStyle, debug }) => {
  let startingCodeableConceptArray = [{ "coding": [], "text": "" }];
  let startingCode = "";
  if (!startingValue || startingValue.length === 0 || !startingValue[0]) {
    startingCodeableConceptArray = {};
  } else {
    if (startingValue[0].coding) {
      startingCodeableConceptArray[0].coding = startingValue[0].coding;
      startingCode = startingValue[0].coding[0].code;
    }
    if (startingValue[0].text) {
      startingCodeableConceptArray[0].text = startingValue[0].text;
    }
  }
  if (dataEntryStyle === "Age") {
    valueSet = UCUM.age;
  } else if (dataEntryStyle === "Duration") {
    valueSet = UCUM.duration;
  } else if (valueSet === "UCUM") {
    valueSet = UCUM.common;
  }

  if (!valueSet) {
    return <></>
  }
  return <>
    <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <Dropdown
        name={"picklist-for-" + elementName}
        placeholder="Preset" search
        closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
        style={{ minWidth: "25%", width: "25%" }}
        options={valueSet.map((term, termIndex) => { return { "key": termIndex, "value": term.code, "text": term.display } })}
        value={startingCode}
        onChange={(e, data) => {
          let selectedCode;
          let selectedSystem;
          let selectedDisplay;
          let selectedVersion;
          for (const term of valueSet) {
            if (term.code === data.value) {
              selectedCode = term.code;
              selectedSystem = term.system || "";
              selectedDisplay = term.display || "";
              selectedVersion = term.version || "";
            }
          }
          let newCoding = { system: selectedSystem, version: selectedVersion, code: selectedCode, display: selectedDisplay };
          if (selectedCode || selectedDisplay || selectedSystem || selectedVersion) {
            if (selectedSystem === "") {
              delete newCoding.system;
            }
            if (selectedVersion === "") {
              delete newCoding.version;
            }
            if (selectedDisplay === "") {
              delete newCoding.display;
            }
            let newClassifier = [{ "coding": [newCoding] }]
            handleChange(elementName, newClassifier, setResourceState);
          } else {
            handleChange(elementName, null, setResourceState);
          }
        }}
      />
    </div>
  </>
});

const addressDotUseValues = ["home", "work", "temp", "old", "billing"];
const addressDotTypeValues = ["postal", "physical", "both"];
const AddressEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingAddress = { "use": "", "type": "", "text": "", "line": [], "city": "", "district": "", "state": "", "postalCode": "", "country": "", "period": "" };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingAddress.extension = startingValue.extension; }
    if (startingValue.use) { startingAddress.use = startingValue.use; }
    if (startingValue.type) { startingAddress.type = startingValue.type; }
    if (startingValue.text) { startingAddress.text = startingValue.text; }
    if (startingValue.line) { startingAddress.line = startingValue.line; }
    if (startingValue.city) { startingAddress.city = startingValue.city; }
    if (startingValue.district) { startingAddress.district = startingValue.district; }
    if (startingValue.state) { startingAddress.state = startingValue.state; }
    if (startingValue.postalCode) { startingAddress.postalCode = startingValue.postalCode; }
    if (startingValue.country) { startingAddress.country = startingValue.country; }
    if (startingValue.period) { startingAddress.period = startingValue.period; }
  }
  const [addressState, setAddressState] = useState(startingAddress);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(addressState).length) {
      let newAddress = {};
      if (addressState.extension) { newAddress.extension = addressState.extension; }
      if (addressState.use) { newAddress.use = addressState.use; }
      if (addressState.type) { newAddress.type = addressState.type; }
      if (addressState.text) { newAddress.text = addressState.text; }
      if (addressState.line !== null && addressState.line !== undefined &&
        Array.isArray(addressState.line) && addressState.line.length !== 0 &&
        !(addressState.line.length === 1 && addressState.line[0] === "")) {
        newAddress.line = addressState.line;
      }
      if (addressState.city) { newAddress.city = addressState.city; }
      if (addressState.district) { newAddress.district = addressState.district; }
      if (addressState.state) { newAddress.state = addressState.state; }
      if (addressState.postalCode) { newAddress.postalCode = addressState.postalCode; }
      if (addressState.country) { newAddress.country = addressState.country; }
      if (addressState.period && Object.keys(addressState.period).length) { newAddress.period = addressState.period; }
      if (Object.keys(newAddress).length === 0) {
        newAddress = null;
      }
      handleChange(elementName, newAddress, setResourceState);
    }
  }), [addressState]);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.Address(startingAddress)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <div>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <CodeEntry elementName='use' fieldLabel='Use'
          startingValue={addressState.use} setResourceState={setAddressState} dataEntryStyle='dropdown'
          allowedValues={addressDotUseValues} />
        <CodeEntry elementName='type' fieldLabel='Type'
          startingValue={addressState.type} setResourceState={setAddressState}
          allowedValues={addressDotTypeValues} />
        <StringEntry elementName='text' fieldLabel='Text'
          startingValue={addressState.text} setResourceState={setAddressState} />
        <ArrayEntry datatype='string' elementName='line'
          fieldLabel='Line' startingValue={addressState.line} setResourceState={setAddressState} />
        <StringEntry elementName='city' fieldLabel='City'
          startingValue={addressState.city} setResourceState={setAddressState} />
        <StringEntry elementName='district' fieldLabel='District'
          startingValue={addressState.district} setResourceState={setAddressState} />
        <StringEntry elementName='state' fieldLabel='State'
          startingValue={addressState.state} setResourceState={setAddressState} />
        <StringEntry elementName='postalCode' fieldLabel='Postal Code'
          startingValue={addressState.postalCode} setResourceState={setAddressState} />
        <StringEntry elementName='country' fieldLabel='Country'
          startingValue={addressState.country} setResourceState={setAddressState} />
        <PeriodEntry elementName='period' fieldLabel='Period' startCollapsed={true}
          startingValue={addressState.period} setResourceState={setAddressState} />
      </div>
    </div>
  }
})

const AnnotationEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle, startCollapsed }) => {

  let autoAuthor = false;
  let editAuthor = false;
  let autoTime = false;
  let editTime = false;
  if (dataEntryStyle) {
    if (dataEntryStyle.includes("autoAuthor")) {
      autoAuthor = true;
    }
    if (dataEntryStyle.includes("editAuthor")) {
      editAuthor = true;
    }
    if (dataEntryStyle.includes("autoTime")) {
      autoTime = true;
    }
    if (dataEntryStyle.includes("editTime")) {
      editTime = true;
    }
  }

  if (!startingValue) {
    startingValue = {};
    if (autoAuthor) {
      startingValue.authorString = "TBD: This will be replaced with username display.";
    }
    if (autoTime) {
      let currentTime = new Date();
      startingValue.time = format(currentTime, "yyyy-MM-dd") + "T" + format(currentTime, "HH:mm:ss");
    }
  }

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  const CalendarModal = () => {
    return <div className="calendarModal"><MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        format="yyyy-MM-dd HH:mm:ss"
        value={startingValue.time ? parseISO(startingValue.time, "yyyy-MM-dd HH:mm:ss") : null}
        emptyLabel="yyyy-MM-dd HH:mm:ss"
        open={calendarOpen}
        onClose={() => setCalendarOpen(false)}
        onChange={(e) => {
          let newTime = format(e, "yyyy-MM-dd") + "T" + format(e, "HH:mm:ss");
          let newAnnotation = { authorString: "", time: newTime, text: "" };
          if (startingValue.authorReference) { newAnnotation.authorReference = startingValue.authorReference; }
          if (startingValue.authorString) { newAnnotation.authorString = startingValue.authorString; }
          if (startingValue.text) { newAnnotation.text = startingValue.text; }
          handleChange(elementName, newAnnotation, setResourceState);
        }}
      />
    </MuiPickersUtilsProvider></div>
  }
  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.Annotation(startingValue)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <div><p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      {editAuthor && <TextField style={{ width: "100%" }} className="inputField" type='text'
        label={'Note Author'}
        size="small" variant='outlined' value={startingValue.authorString || startingValue.authorReference?.display || ''}
        onChange={(e) => {
          let newAnnotation = { authorString: e.target.value, time: "", text: "" };
          if (startingValue.authorReference) { newAnnotation.authorReference = startingValue.authorReference; }
          if (startingValue.time) { newAnnotation.time = startingValue.time; } else { delete newAnnotation.time; }
          if (startingValue.text) { newAnnotation.text = startingValue.text; } else { delete newAnnotation.text; }
          if (!e.target.value && !startingValue.authorReference && !startingValue.time && !startingValue.text) {
            newAnnotation = {};
          }
          handleChange(elementName, newAnnotation, setResourceState);
        }} />
      }
      {editTime && <div>
        <TextField style={{ width: "100%", marginTop: "16px", maxWidth: "300px" }}
          className="inputField" type='text' label="Note Time" size="small" variant='outlined'
          value={startingValue.time || ""}
          onChange={(e) => {
            let newTime = e.target.value
            let newAnnotation = { authorString: "", time: newTime, text: "" };
            if (startingValue.authorReference) { newAnnotation.authorReference = startingValue.authorReference; }
            if (startingValue.authorString) { newAnnotation.authorString = startingValue.authorString; } else { delete newAnnotation.authorString; }
            if (startingValue.text) { newAnnotation.text = startingValue.text; } else { delete newAnnotation.text; }
            if (!e.target.value && !startingValue.authorReference && !startingValue.authorString && !startingValue.text) {
              newAnnotation = {};
            }
            handleChange(elementName, newAnnotation, setResourceState);
          }} />
        <Button style={{ marginTop: "18px", marginLeft: "6px", fontSize: "28px", padding: "0px", backgroundColor: "white" }} className="calendarButton"
          content="📅" onClick={() => { setCalendarOpen(true) }} />
        <CalendarModal />
      </div>
      }
      <TextField style={{ width: "100%" }} className="inputField" type='text' label={'Note Text'}
        multiline size="small" variant='outlined' value={startingValue.text || ''}
        onChange={(e) => {
          let newAnnotation = { authorString: "", time: "", text: e.target.value };
          if (startingValue.authorReference) { newAnnotation.authorReference = startingValue.authorReference; }
          if (startingValue.time) { newAnnotation.time = startingValue.time; } else { delete newAnnotation.time; }
          if (startingValue.authorString) { newAnnotation.authorString = startingValue.authorString; } else { delete newAnnotation.authorString; }
          if (!e.target.value && !startingValue.authorReference && !startingValue.authorString && !startingValue.time) {
            newAnnotation = {};
          }
          handleChange(elementName, newAnnotation, setResourceState);
        }} />
      {startingValue && <div><b>Note Text will display as: </b><DisplayFromFHIR markdown={startingValue.text} /><br /></div>}
    </div>
  }
});

const AttachmentEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, allowedContentTypes, allowedLanguages, dataEntryStyle, debug, startCollapsed }) => {
  let startingAttachment = {
    "contentType": "", "language": "", "data": "", "url": "", "size": "", "hash": "",
    "title": "", "creation": "", "height": "", "width": "", "frames": "", "duration": "", "pages": ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingAttachment.extension = startingValue.extension; }
    if (startingValue.contentType) { startingAttachment.contentType = startingValue.contentType; }
    if (startingValue.language) { startingAttachment.language = startingValue.language; }
    if (startingValue.data) { startingAttachment.data = startingValue.data; }
    if (startingValue.url) { startingAttachment.url = startingValue.url; }
    if (startingValue.size) { startingAttachment.size = startingValue.size; }
    if (startingValue.hash) { startingAttachment.hash = startingValue.hash; }
    if (startingValue.title) { startingAttachment.title = startingValue.title; }
    if (startingValue.creation) { startingAttachment.creation = startingValue.creation; }
    if (startingValue.height) { startingAttachment.height = startingValue.height; }
    if (startingValue.width) { startingAttachment.width = startingValue.width; }
    if (startingValue.frames) { startingAttachment.frames = startingValue.frames; }
    if (startingValue.duration) { startingAttachment.duration = startingValue.duration; }
    if (startingValue.pages) { startingAttachment.pages = startingValue.pages; }
  }

  const [attachmentState, setAttachmentState] = useState(startingAttachment);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(attachmentState).length) {
      let newAttachment = {};
      if (attachmentState.extension) { newAttachment.extension = attachmentState.extension; }
      if (attachmentState.contentType) { newAttachment.contentType = attachmentState.contentType; }
      if (attachmentState.language) { newAttachment.language = attachmentState.language; }
      if (attachmentState.data) { newAttachment.data = attachmentState.data; }
      if (attachmentState.url) { newAttachment.url = attachmentState.url; }
      if (attachmentState.size) { newAttachment.size = attachmentState.size; }
      if (attachmentState.hash) { newAttachment.hash = attachmentState.hash; }
      if (attachmentState.title) { newAttachment.title = attachmentState.title; }
      if (attachmentState.creation) { newAttachment.creation = attachmentState.creation; }
      if (attachmentState.height) { newAttachment.height = attachmentState.height; }
      if (attachmentState.width) { newAttachment.width = attachmentState.width; }
      if (attachmentState.frames) { newAttachment.frames = attachmentState.frames; }
      if (attachmentState.duration) { newAttachment.duration = attachmentState.duration; }
      if (attachmentState.pages) { newAttachment.pages = attachmentState.pages; }
      if (Object.keys(newAttachment).length === 0) {
        newAttachment = null;
      }
      handleChange(elementName, newAttachment, setResourceState);
    }
  }), [attachmentState]);
  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR attachment={startingAttachment} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <Input id="fileAttachment" type="file"
          onChange={(e, data) => {
            if (e.target.files.length > 0) {
              let file = e.target.files[0];
              if (file.name.length >= 5 && file.name.slice(-4).toLowerCase() === ".pdf") {
                let reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = function () {
                  if (reader.result.length && reader.result.length < 70000000) {
                    setAttachmentState(prevState => {
                      return {
                        ...prevState,
                        contentType: 'application/pdf',
                        data: reader.result.replace("data:application/pdf;base64,", ""),
                        size: reader.result.length.toString(),
                        title: file.name.slice(0, -4)
                      };
                    });
                  } else {
                    alert("We only support attaching files that are 50 MB or less. If your file is, then please email support@computablepublishing.com");
                  }
                };
                reader.onerror = function (error) {
                  console.log('Error, ', error);
                };
              } else if (dataEntryStyle === 'pdfonly') {
                alert("We currently only support .pdf files. Please choose another file.");
                //TODO: selected file name still shows in the data entry space, should be cleared.
              } else {
                let reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = function () {
                  if (reader.result.length && reader.result.length < 70000000) {
                    setAttachmentState(prevState => {
                      let resultTwoParts = reader.result.split(',', 2);
                      let resultHeaderTwoParts = resultTwoParts[0].split(':', 2);
                      return {
                        ...prevState,
                        contentType: resultHeaderTwoParts[1],
                        data: resultTwoParts[1],
                        size: reader.result.length.toString(),
                        title: file.name
                      };
                    });
                  } else {
                    alert("We only support attaching files that are 50 MB or less. If your file is, then please email support@computablepublishing.com");
                  }
                };
                reader.onerror = function (error) {
                  console.log('Error, ', error);
                };
              }
            }
          }}
        />
        {allowedContentTypes ?
          <CodeEntry elementName='contentType'
            allowedValues={allowedContentTypes} dataEntryStyle='dropdown'
            fieldLabel='Content Type (Mime type)' startingValue={attachmentState.contentType} setResourceState={setAttachmentState} />
          :
          <StringEntry elementName='contentType'
            fieldLabel='Content Type (Mime type)' startingValue={attachmentState.contentType} setResourceState={setAttachmentState} />
        }
        {allowedLanguages ?
          <CodeEntry elementName='language'
            allowedValues={allowedLanguages} dataEntryStyle='dropdown'
            fieldLabel='Language (Human language)' startingValue={attachmentState.language} setResourceState={setAttachmentState} />
          :
          <StringEntry elementName='language'
            fieldLabel='Language (Human language)' startingValue={attachmentState.language} setResourceState={setAttachmentState} />
        }
        <StringEntry elementName='data'
          fieldLabel='Data (base64Binary)' startingValue={attachmentState.data} setResourceState={setAttachmentState} />
        <UriEntry elementName='url'
          fieldLabel='URL' startingValue={attachmentState.url} setResourceState={setAttachmentState} />
        <StringEntry elementName='size'
          fieldLabel='Size (integer64 # of bytes), start with +' startingValue={attachmentState.size} setResourceState={setAttachmentState} />
        <StringEntry elementName='hash'
          fieldLabel='Hash (sha-1, base64Binary)' startingValue={attachmentState.hash} setResourceState={setAttachmentState} />
        <StringEntry elementName='title'
          fieldLabel='Title' startingValue={attachmentState.title} setResourceState={setAttachmentState} />
        <DateTimeEntry elementName='creation'
          fieldLabel='Creation date' startingValue={attachmentState.creation} setResourceState={setAttachmentState} />
        <PositiveIntEntry elementName='height'
          fieldLabel='Height (in pixels)' startingValue={attachmentState.height} setResourceState={setAttachmentState} />
        <PositiveIntEntry elementName='width'
          fieldLabel='Width (in pixels)' startingValue={attachmentState.width} setResourceState={setAttachmentState} />
        <PositiveIntEntry elementName='frames'
          fieldLabel='Number of frames' startingValue={attachmentState.frames} setResourceState={setAttachmentState} />
        <DecimalEntry elementName='duration'
          fieldLabel='Duration (in seconds)' startingValue={attachmentState.duration} setResourceState={setAttachmentState} />
        <PositiveIntEntry elementName='pages'
          fieldLabel='Number of pages' startingValue={attachmentState.pages} setResourceState={setAttachmentState} />
      </div>
    </>
  }
})

const attesterResourceTypes = ['Practitioner', 'PractitionerRole', 'Organization', 'Patient', 'RelatedPerson'];
const limitedAttesterResourceTypes = ['Practitioner', 'Organization'];
const AttesterEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle, debug, startCollapsed }) => {
  let startingAttester = { "mode": "", "time": "", "party": "" };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingAttester.extension = startingValue.extension; }
    if (startingValue.mode) { startingAttester.mode = startingValue.mode; }
    if (startingValue.time) { startingAttester.time = startingValue.time; }
    if (startingValue.party) { startingAttester.party = startingValue.party; }
  }

  const [attesterState, setAttesterState] = useState(startingAttester);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(attesterState).length) {
      let newAttester = {};
      if (attesterState.extension) { newAttester.extension = attesterState.extension; }
      if (attesterState.mode) { newAttester.mode = attesterState.mode; }
      if (attesterState.time) { newAttester.time = attesterState.time; }
      if (attesterState.party && Object.keys(attesterState.party).length > 0) {
        newAttester.party = attesterState.party;
      }
      if (Object.keys(newAttester).length === 0) {
        newAttester = null;
      }
      handleChange(elementName, newAttester, setResourceState);
    }
  }), [attesterState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR attester={startingAttester} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <CodeableConceptEntry elementName='mode' fieldLabel='Mode (e.g. reviewer, endorser, editor)'
          codeableConceptLevelValueSet={[
            {
              "coding": [{ system: "http://hl7.org/fhir/contributor-role", code: "author", display: "Author/Creator" }]
            },
            {
              "coding": [{ system: "http://hl7.org/fhir/contributor-role", code: "recorder", display: "Recorder" }]
            },
            {
              "coding": [{ system: "http://hl7.org/fhir/contributor-role", code: "reviewer", display: "Reviewer" }]
            },
            {
              "coding": [{ system: "http://hl7.org/fhir/contributor-role", code: "endorser", display: "Endorser" }]
            },
            {
              "coding": [{ system: "http://hl7.org/fhir/contributor-role", code: "editor", display: "Editor" }]
            },
            {
              "coding": [{ system: "http://hl7.org/fhir/contributor-role", code: "informant", display: "Informant" }]
            },
            {
              "coding": [{ system: "http://hl7.org/fhir/contributor-role", code: "funder", display: "Funder" }]
            },
            {
              "coding": [{ system: "http://hl7.org/fhir/contributor-role", code: "publisher", display: "Publisher" }]
            }
          ]}
          startingValue={attesterState.mode} setResourceState={setAttesterState} />
        <DateTimeEntry elementName='time'
          fieldLabel='Date/Time' startingValue={attesterState.time} setResourceState={setAttesterState} />
        <ReferenceEntry elementName='party' fieldLabel={attesterState.mode?.display || "Contributor"}
          dataEntryStyle={dataEntryStyle}
          referencedResourceTypes={dataEntryStyle === "humanAuthor" ? limitedAttesterResourceTypes : attesterResourceTypes}
          enableCreation={true}
          startingValue={attesterState.party} setResourceState={setAttesterState} />
      </div>
    </>
  }
})

const BooleanEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, storeFalse }) => {
  if (storeFalse) {
    const allowedValues = ['True', 'False'];
    //let startingValueText = "[Not specified]";
    let startingValueText;
    if (startingValue === true) {
      startingValueText = "true"
    }
    if (startingValue === false && startingValue !== undefined && startingValue !== null && startingValue !== "") {
      startingValueText = "false"
    }
    return <div>
      <b>{fieldLabel}: </b>
      <Dropdown
        name={elementName}
        placeholder={fieldLabel}
        closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
        style={{ minWidth: "25%", width: "25%" }}
        options={allowedValues.map((code, codeIndex) => { return { "key": codeIndex, "value": code.toLowerCase(), "text": code } })}
        value={startingValueText ?? ""}
        onChange={(e, data) => {
          let booleanValue = "";
          if (data.value === "true") {
            booleanValue = true;
          }
          if (data.value === "false") {
            booleanValue = false;
          }
          handleChange(elementName, booleanValue, setResourceState);
        }}
      />
    </div>
  }
  return <span><b>{fieldLabel}: </b>
    <Checkbox checked={startingValue === true || startingValue === "true"}
      onChange={(e) => {
        handleChange(elementName, e.target.checked, setResourceState);
      }}
      color="primary"
      style={{ paddingLeft: "4px", paddingRight: "4px" }}
    /></span>
});

const classificationDotAuthorResourceTypes = ['Patient', 'Practitioner', 'PractitionerRole', 'Organization', 'Device'];
const ClassificationEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, allowedValues,
  valueSet, inTableCell, debug, startCollapsed, classificationProfile }) => {
  //Classification is not a FHIR Datatype. Classification schema is an object with:
  //type 0..1 CodeableConcept
  //classifier 0..* CodeableConcept
  //(when used in Classification Profile of ArtifactAssessment.content -- author 0..1 Reference)
  //(when used in Classification Profile of ArtifactAssessment.content -- freeToShare 0..1 boolean)
  let startingClassification = { "informationType": "", "type": "", "classifier": [], "author": "", "freeToShare": true }
  if (!startingValue) {
    startingClassification = { "informationType": "", "type": "", "classifier": undefined, "author": "", "freeToShare": true };
  } else {
    if (startingValue.extension) { startingClassification.extension = startingValue.extension; }
    if (startingValue.informationType) { startingClassification.informationType = startingValue.informationType; }
    if (startingValue.type) { startingClassification.type = startingValue.type; }
    if (startingValue.classifier) { startingClassification.classifier = startingValue.classifier; }
    if (startingValue.author) { startingClassification.author = startingValue.author; }
    if (typeof startingValue.freeToShare === "boolean") { startingClassification.freeToShare = startingValue.freeToShare; }
  }

  const [classificationState, setClassificationState] = useState(startingClassification);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(classificationState).length) {
      let newClassification = {};
      if (classificationState.extension) { newClassification.extension = classificationState.extension; }
      if (classificationState.informationType) { newClassification.informationType = classificationState.informationType; }
      if (classificationState.type && Object.keys(classificationState.type).length) { newClassification.type = classificationState.type; }
      if (classificationState.classifier && Array.isArray(classificationState.classifier) && classificationState.classifier.length) { newClassification.classifier = classificationState.classifier; }
      if (classificationState.author && Object.keys(classificationState.author).length) { newClassification.author = classificationState.author; }
      if (typeof classificationState.freeToShare === "boolean") { newClassification.freeToShare = classificationState.freeToShare; }
      let numberOfClassificationKeys = Object.keys(newClassification).length;
      if (numberOfClassificationKeys === 0 || (numberOfClassificationKeys === 1 && newClassification.freeToShare !== undefined)) {
        newClassification = null;
      }
      handleChange(elementName, newClassification, setResourceState);
    }
  }), [classificationState]);
  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR classification={startingClassification} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <p>Describe a classifier as [Attribute] is [Value], e.g. [color] is [red], or [intervention] is [bariatric surgery].</p>
      <div style={{ marginLeft: "24px" }}>
        <CodeableConceptEntry elementName='type' fieldLabel='What is the Attribute being classified?'
          startingValue={classificationState.type || null} setResourceState={setClassificationState}
          dataEntryStyle={'simple'} inTableCell={inTableCell} valueSet={valueSet} debug={debug} startCollapsed={true} />
        <br /><br />
        <ArrayEntry datatype="CodeableConcept" elementName='classifier' fieldLabel='Classifier Value'
          startingValue={classificationState.classifier || null} setResourceState={setClassificationState}
          dataEntryStyle={'simple'} inTableCell={inTableCell} valueSet={valueSet} debug={debug} startCollapsed={true} />
        <br /><br />
        {classificationProfile === "date-as-rating" && <>
          <ArrayEntry datatype="Extension" elementName='extension' fieldLabel='Date Value'
            startingValue={classificationState.extension || null} setResourceState={setClassificationState}
            extensionUrl='http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating'
            extensionValueType='dateTime'
            dataEntryStyle={'simple'} inTableCell={inTableCell} valueSet={valueSet} debug={debug} startCollapsed={true} />
          <br /><br />
        </>}
        <ReferenceEntry elementName='author' fieldLabel='Author' startCollapsed={true}
          startingValue={classificationState.author || null} setResourceState={setClassificationState}
          enableCreation={true}
          referencedResourceTypes={classificationDotAuthorResourceTypes} />
        <BooleanEntry elementName="freeToShare" fieldLabel='Free to share?'
          startingValue={classificationState.freeToShare ?? true} setResourceState={setClassificationState} />
      </div>
    </>
  }
});
//if allowedValues is dynamic, then add a property to support 'change checking' for the memo function
const CodeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, allowedValues, dataEntryStyle, enableCreation, togglable }) => {
  if (!allowedValues) {
    return <></>
  }
  if (enableCreation && !allowedValues.includes('Enter a different value.')) {
    allowedValues.push('Enter a different value.');
  }
  if (dataEntryStyle === "dropdown" || dataEntryStyle === "dropdownsearch") {
    return <div>
      <b>{fieldLabel}: </b>
      <Dropdown
        name={elementName}
        placeholder={fieldLabel}
        search={dataEntryStyle === "dropdownsearch"}
        closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
        style={{ minWidth: "25%", width: "25%" }}
        options={allowedValues.map((code, codeIndex) => { return { "key": codeIndex, "value": code, "text": code } })}
        value={startingValue}
        onChange={(e, data) => {
          handleChange(elementName, data.value, setResourceState);
        }}
      />
    </div>
  }
  if (togglable === undefined) {
    togglable = true;
  }
  return <div style={{ marginTop: "8px" }}>
    <b>{fieldLabel}: </b>
    <GenericToggleButtons capitalize={true} medium={true} togglable={togglable}
      values={allowedValues} fieldLabel={fieldLabel} elementName={elementName}
      startingValue={startingValue} setter={setResourceState}
    />
  </div>
});

const CodeArrayEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, allowedValues, dataEntryStyle }) => {
  if (!allowedValues) {
    return <></>
  }
  return <div>
    <b>{fieldLabel}: </b>
    <Dropdown
      name={elementName}
      placeholder={fieldLabel}
      search={dataEntryStyle === "dropdownsearch"} multiple
      selection clearable selectOnBlur={false} autoComplete="off"
      style={{ minWidth: "25%", width: "25%" }}
      options={allowedValues.map((code, codeIndex) => { return { "key": codeIndex, "value": code, "text": code } })}
      value={startingValue}
      onChange={(e, data) => {
        handleChange(elementName, data.value, setResourceState);
      }}
    />
  </div>
});

const ChooseItemForCodeableConceptEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, codeableConceptValueSet, dataEntryStyle, inTableCell, setOpenCodeableConceptEntryState }) => {

  let startingCode = "";
  if (startingValue?.coding?.length > 0 && startingValue.coding[0].code) {
    startingCode = startingValue.coding[0].code
  } else {
    startingCode = startingValue?.text || "";
  }

  if (!codeableConceptValueSet) {
    return <></>
  }
  let fullValueSet = [{
    "text": "Enter a value not in this list."
  }].concat(codeableConceptValueSet);

  if (startingCode) {
    let matchFound = false;
    for (const item of fullValueSet) {
      if (item.coding?.length > 0 && item.coding[0].code === startingCode) {
        matchFound = true;
      } else if (item.text === startingCode) {
        matchFound = true;
      }
    }
    if (!matchFound) {
      fullValueSet.push({ "text": startingCode });
    }
  }

  return <>
    <p style={{ marginBottom: "2px" }}><b>Pick {fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <Dropdown
        name={"picklist-for-" + elementName}
        placeholder="Preset" search
        closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
        style={{ minWidth: "25%" }}
        options={fullValueSet.map((term, termIndex) => {
          let termCode = "";
          let termDisplay = "";
          if (term.coding?.length > 0) {
            termCode = term.coding[0].code || "";
            termDisplay = term.coding[0].display || "";
          }
          if (term.text) {
            if (!termCode) {
              termCode = term.text;
            }
            if (termDisplay) {
              termDisplay += "; " + term.text;
            } else {
              termDisplay = term.text;
            }
          }
          return { "key": termIndex, "value": termCode, "text": termDisplay }
        })}
        value={startingCode}
        onChange={(e, data) => {
          let selectedText;
          let selectedCoding;
          for (const term of fullValueSet) {
            if (term.text === data.value || (term.coding?.length > 0 && term.coding[0].code === data.value)) {
              selectedCoding = term.coding || "";
              selectedText = term.text || "";
            }
          }
          let newCodeableConcept = { coding: selectedCoding, text: selectedText };
          if (selectedCoding || selectedText) {
            if (selectedCoding === "") {
              delete newCodeableConcept.coding;
            }
            if (selectedText === "") {
              delete newCodeableConcept.text;
            }
            if (selectedText === "Enter a value not in this list.") {
              setOpenCodeableConceptEntryState(true);
            } else {
              setResourceState(newCodeableConcept);
              setOpenCodeableConceptEntryState(false);
            }
          } else {
            setResourceState({ text: "" });
          }
        }}
      />
    </div>
  </>
});

const CodeableConceptEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, allowedValues,
  valueSet, dataEntryStyle, inTableCell, debug, systemChoices, systemChoicesOpen, startCollapsed, codeableConceptLevelValueSet }) => {
  let startingCodeableConcept = { "coding": [], "text": "" };

  if (!startingValue) {
    startingCodeableConcept = "";
    if (dataEntryStyle === "simple") {
      startCollapsed = true;
    }
  } else {
    if (startingValue.extension) {
      startingCodeableConcept.extension = startingValue.extension;
    }
    if (startingValue.coding) {
      startingCodeableConcept.coding = startingValue.coding;
    }
    if (startingValue.text) {
      startingCodeableConcept.text = startingValue.text;
    }
  }
  if (dataEntryStyle === "Age") {
    valueSet = UCUM.age;
  } else if (dataEntryStyle === "Duration") {
    valueSet = UCUM.duration;
  } else if (valueSet === "UCUM") {
    valueSet = UCUM.common;
  }

  const [codeableConceptState, setCodeableConceptState] = useState(startingCodeableConcept);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);
  const [openCodeableConceptEntryState, setOpenCodeableConceptEntryState] = useState(false);

  useEffect((() => {
    if (Object.keys(codeableConceptState).length) {
      let newCodeableConcept = {};
      if (codeableConceptState.extension) {
        newCodeableConcept.extension = codeableConceptState.extension;
      }
      if (codeableConceptState.coding !== null && codeableConceptState.coding !== undefined &&
        Array.isArray(codeableConceptState.coding) && codeableConceptState.coding.length !== 0 &&
        !(codeableConceptState.coding.length === 1 && Object.keys(codeableConceptState.coding[0]).length === 0)) {
        newCodeableConcept.coding = codeableConceptState.coding;
      }
      if (codeableConceptState.text) {
        newCodeableConcept.text = codeableConceptState.text;
      }
      if (Object.keys(newCodeableConcept).length === 0) {
        newCodeableConcept = null;
      }
      handleChange(elementName, newCodeableConcept, setResourceState);
    }
  }), [codeableConceptState]);

  if (dataEntryStyle === "simple") {
    return <>
      <span>
        <b>{fieldLabel}: </b>
        &nbsp;&nbsp;&nbsp;
        <TextField style={{ width: "50%", marginTop: "12px" }} multiline
          className="inputField" type='text' label={fieldLabel} size="small" variant='outlined'
          value={codeableConceptState.text || ""}
          onChange={(e) => { handleChange('text', e.target.value, setCodeableConceptState) }} />
        &nbsp;&nbsp;&nbsp;
        {startCollapsedState &&
          <Button className="formButton" style={{ color: "#000000", marginTop: "12px", marginLeft: "4px" }}
            content="Provide a Coding" onClick={() => {
              setStartCollapsedState(false);
              if (!(codeableConceptState?.coding?.length > 0)) {
                setCodeableConceptState(prevState => { return { ...prevState, "coding": [{}] } });
              }
            }} />}
      </span>
      {!startCollapsedState &&
        <div><br />
          {((codeableConceptState.coding !== undefined && Array.isArray(codeableConceptState.coding))) ?
            <ArrayEntry datatype='Coding' elementName='coding' fieldLabel='Coding' debug={debug}
              startingValue={codeableConceptState.coding} setResourceState={setCodeableConceptState}
              allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={valueSet}
              systemChoices={systemChoices} systemChoicesOpen={systemChoicesOpen} />
            :
            <ArrayEntry datatype='Coding' elementName='coding' fieldLabel='Coding'
              startingValue={undefined} setResourceState={setCodeableConceptState} debug={debug}
              allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={valueSet}
              systemChoices={systemChoices} systemChoicesOpen={systemChoicesOpen} />
          }
        </div>}
    </>
  }
  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR codeableConcept={startingCodeableConcept} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    if (codeableConceptLevelValueSet) {
      return <>
        <ChooseItemForCodeableConceptEntry elementName={elementName}
          startingValue={startingValue || null}
          fieldLabel={fieldLabel}
          codeableConceptValueSet={codeableConceptLevelValueSet}
          setResourceState={setCodeableConceptState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
          setOpenCodeableConceptEntryState={setOpenCodeableConceptEntryState} />
        {openCodeableConceptEntryState && <>
          <div style={{ marginLeft: "24px" }}>
            <span><b>Enter new value: </b>&nbsp;&nbsp;&nbsp;</span>
            <div style={{ marginLeft: "24px" }}>
              <TextField style={{ width: "90%", marginTop: "12px" }} multiline
                className="inputField" type='text' label={"Text for " + fieldLabel} size="small" variant='outlined'
                value={codeableConceptState.text || ""}
                onChange={(e) => { handleChange('text', e.target.value, setCodeableConceptState) }} />
              &nbsp;&nbsp;&nbsp;
              <div><br />
                {((codeableConceptState.coding !== undefined && codeableConceptState.coding !== null &&
                  Array.isArray(codeableConceptState.coding))) ?
                  <ArrayEntry datatype='Coding' elementName='coding' fieldLabel='Coding' debug={debug}
                    startingValue={codeableConceptState.coding} setResourceState={setCodeableConceptState}
                    allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={valueSet}
                    systemChoices={systemChoices} systemChoicesOpen={systemChoicesOpen} />
                  :
                  <ArrayEntry datatype='Coding' elementName='coding' fieldLabel='Coding'
                    startingValue={undefined} setResourceState={setCodeableConceptState} debug={debug}
                    allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={valueSet}
                    systemChoices={systemChoices} systemChoicesOpen={systemChoicesOpen} />
                }
              </div>
            </div>
          </div>
        </>}
      </>
    }
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: inTableCell ? "6px" : "24px" }}>
        {((codeableConceptState.coding !== undefined && Array.isArray(codeableConceptState.coding))) ?
          <ArrayEntry datatype='Coding' elementName='coding' fieldLabel='Coding' debug={debug}
            startingValue={codeableConceptState.coding} setResourceState={setCodeableConceptState}
            allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={valueSet}
            systemChoices={systemChoices} systemChoicesOpen={systemChoicesOpen} />
          :
          <ArrayEntry datatype='Coding' elementName='coding' fieldLabel='Coding'
            startingValue={undefined} setResourceState={setCodeableConceptState} debug={debug}
            allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={valueSet}
            systemChoices={systemChoices} systemChoicesOpen={systemChoicesOpen} />
        }
        <StringEntry elementName='text' fieldLabel='Text' startingValue={codeableConceptState.text || ""}
          setResourceState={setCodeableConceptState} debug={debug} marginTop={"6px"} />
      </div>
    </>
  }
})

const CodeableReferenceEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  allowedValues, valueSet, dataEntryStyle, inTableCell, debug, referencedResourceTypes,
  systemChoices, systemChoicesOpen, startCollapsed, enableCreation }) => {
  let startingCodeableReference = { "concept": "", "reference": "" };
  if (!startingValue) {
    startingCodeableReference = "";
  } else {
    if (startingValue.extension) { startingCodeableReference.extension = startingValue.extension; }
    if (startingValue.concept) { startingCodeableReference.concept = startingValue.concept; }
    if (startingValue.reference) { startingCodeableReference.reference = startingValue.reference; }
  }
  if (dataEntryStyle === "Age") {
    valueSet = UCUM.age;
  } else if (dataEntryStyle === "Duration") {
    valueSet = UCUM.duration;
  } else if (valueSet === "UCUM") {
    valueSet = UCUM.common;
  }

  const [codeableReferenceState, setCodeableReferenceState] = useState(startingCodeableReference);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(codeableReferenceState).length) {
      let newCodeableReference = {};
      if (codeableReferenceState.extension) { newCodeableReference.extension = codeableReferenceState.extension; }
      if (codeableReferenceState.concept && Object.keys(codeableReferenceState.concept).length) { newCodeableReference.concept = codeableReferenceState.concept; }
      if (codeableReferenceState.reference && Object.keys(codeableReferenceState.reference).length) { newCodeableReference.reference = codeableReferenceState.reference; }
      if (Object.keys(newCodeableReference).length === 0) {
        newCodeableReference = null;
      }
      handleChange(elementName, newCodeableReference, setResourceState);
    }
  }), [codeableReferenceState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR codeableReference={startingCodeableReference} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <CodeableConceptEntry elementName='concept' fieldLabel='Concept'
          startingValue={codeableReferenceState.concept || null} setResourceState={setCodeableReferenceState}
          systemChoices={systemChoices} systemChoicesOpen={systemChoicesOpen}
          dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={valueSet} debug={debug} />
        <ReferenceEntry elementName='reference' fieldLabel='Reference'
          enableCreation={enableCreation} inTableCell={inTableCell}
          startingValue={codeableReferenceState.reference || null} setResourceState={setCodeableReferenceState}
          referencedResourceTypes={referencedResourceTypes} />
      </div>
    </>
  }
})

const CodingEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, valueSet,
  inTableCell, dataEntryStyle, debug, startCollapsed }) => {

  if (!startingValue) {
    startingValue = "";
  }

  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR coding={startingValue} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      {valueSet && <Dropdown
        name={"picklist-for-" + elementName}
        placeholder="Preset" search
        closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
        style={{ minWidth: "25%", width: "25%" }}
        options={valueSet.map((term, termIndex) => { return { "key": termIndex, "value": term.code, "text": term.display } })}
        value={startingValue.code}
        onChange={(e, data) => {
          let selectedCode;
          let selectedSystem;
          let selectedDisplay;
          let selectedVersion;
          for (const term of valueSet) {
            if (term.code === data.value) {
              selectedCode = term.code;
              selectedSystem = term.system || "";
              selectedDisplay = term.display || "";
              selectedVersion = term.version || "";
            }
          }
          let newCoding = { system: selectedSystem, version: selectedVersion, code: selectedCode, display: selectedDisplay };
          if (newCoding.version === "") {
            delete newCoding.version;
          }
          if (selectedCode || selectedDisplay || selectedSystem || selectedVersion) {
            handleChange(elementName, newCoding, setResourceState);
          } else {
            handleChange(elementName, {}, setResourceState);
          }
        }}
      />
      }
      {!inTableCell && <>&nbsp;&nbsp;&nbsp;</>}
      <TextField style={{ width: inTableCell ? "90%" : "25%" }}
        className="inputField" type='text' label={'System'}
        size="small" variant='outlined' value={startingValue.system || ''}
        onChange={(e) => {
          let newCoding = { system: "", version: "", code: "", display: "" };
          newCoding.system = e.target.value;
          if (startingValue.version) { newCoding.version = startingValue.version; } else { delete newCoding.version; }
          if (startingValue.code) { newCoding.code = startingValue.code; } else { delete newCoding.code; }
          if (startingValue.display) { newCoding.display = startingValue.display; } else { delete newCoding.display; }
          if (startingValue.code || startingValue.display || e.target.value || startingValue.version) {
            handleChange(elementName, newCoding, setResourceState);
          } else {
            handleChange(elementName, {}, setResourceState);
          }
        }} />
      {inTableCell ? <><br /><div style={{ marginTop: "8px" }} /></> : <>&nbsp;&nbsp;&nbsp;</>}
      <TextField style={{ width: inTableCell ? "90%" : "25%" }}
        className="inputField" type='text' label={'Code'}
        size="small" variant='outlined' value={startingValue.code || ''}
        onChange={(e) => {
          let newCoding = { system: "", version: "", code: "", display: "" };
          newCoding.code = e.target.value;
          if (startingValue.version) { newCoding.version = startingValue.version; } else { delete newCoding.version; }
          if (startingValue.system) { newCoding.system = startingValue.system; } else { delete newCoding.system; }
          if (startingValue.display) { newCoding.display = startingValue.display; } else { delete newCoding.display; }
          if (startingValue.system || startingValue.display || e.target.value || startingValue.version) {
            handleChange(elementName, newCoding, setResourceState);
          } else {
            handleChange(elementName, {}, setResourceState);
          }
        }} />
      {inTableCell ? <><br /><div style={{ paddingTop: "8px" }} /></> : <>&nbsp;&nbsp;&nbsp;</>}
      <TextField style={{ width: inTableCell ? "90%" : "25%" }}
        className="inputField" type='text' label={'Display'}
        size="small" variant='outlined' value={startingValue.display || ''}
        onChange={(e) => {
          let newCoding = { system: "", version: "", code: "", display: "" };
          newCoding.display = e.target.value;
          if (startingValue.version) { newCoding.version = startingValue.version; } else { delete newCoding.version; }
          if (startingValue.system) { newCoding.system = startingValue.system; } else { delete newCoding.system; }
          if (startingValue.code) { newCoding.code = startingValue.code; } else { delete newCoding.code; }
          if (startingValue.code || startingValue.system || e.target.value || startingValue.version) {
            handleChange(elementName, newCoding, setResourceState);
          } else {
            handleChange(elementName, {}, setResourceState);
          }
        }} />
    </>
  }
});

const CodingEntryWithSystemSelector = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, systemChoices, systemChoicesOpen, inTableCell,
  startCollapsed }) => {
  if (!startingValue) {
    startingValue = "";
  }
  let startingSystemChoices = JSON.parse(JSON.stringify(systemChoices));
  if (!startingSystemChoices) {
    startingSystemChoices = [
      { "uri": "https://fevir.net", "display": "FEvIR" },
      { "uri": "http://snomed.info/sct", "display": "SNOMED-CT" }
    ];
  }
  let currentSystemValue = startingValue?.system || "";
  if (currentSystemValue) {
    let matchFound = false;
    for (const choice of startingSystemChoices) {
      if (currentSystemValue === choice.uri) {
        matchFound = true;
      }
    }
    if (!matchFound) {
      startingSystemChoices.push({ "uri": currentSystemValue, "display": currentSystemValue });
    }
  }

  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR coding={startingValue} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "18px" }}>
        {!inTableCell && <b>System: </b>}
        <Dropdown
          name={"picklist-for-" + elementName}
          placeholder="Select System or Enter URL"
          search allowAdditions additionLabel={"Enter System URL: "}
          closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
          style={{ width: "35%" }}
          options={startingSystemChoices.map((choice, choiceIndex) => { return { "key": choiceIndex, "value": choice.uri, "text": choice.display } })}
          value={currentSystemValue}
          onAddItem={(e, data) => {
            startingSystemChoices.push({ "uri": data.value, "display": data.value });
          }}
          onChange={(e, data) => {
            let selectedSystem;
            for (const choice of startingSystemChoices) {
              if (choice.uri === data.value) {
                selectedSystem = choice.uri;
              }
            }
            if (systemChoicesOpen && !selectedSystem) {
              selectedSystem = data.value;
            }
            let newCoding = { system: "", code: "", display: "" };
            newCoding.system = selectedSystem;
            if (startingValue.code) { newCoding.code = startingValue.code; } else { delete newCoding.code; }
            if (startingValue.display) { newCoding.display = startingValue.display; } else { delete newCoding.display; }
            if (startingValue.code || startingValue.display || selectedSystem) {
              handleChange(elementName, newCoding, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }}
        />
        {!inTableCell && <br />}
        {inTableCell && <><br /><div style={{ paddingTop: "8px" }} /></>}
        <TextField style={{ width: inTableCell ? "90%" : "25%" }}
          className="inputField" type='text' label={'Code'}
          size="small" variant='outlined' value={startingValue.code || ''}
          onChange={(e) => {
            let newCoding = { system: "", version: "", code: "", display: "" };
            newCoding.code = e.target.value;
            if (startingValue.version) { newCoding.version = startingValue.version; } else { delete newCoding.version; }
            if (startingValue.system) { newCoding.system = startingValue.system; } else { delete newCoding.system; }
            if (startingValue.display) { newCoding.display = startingValue.display; } else { delete newCoding.display; }
            if (startingValue.system || startingValue.display || e.target.value || startingValue.version) {
              handleChange(elementName, newCoding, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }} />
        {inTableCell && <><br /><div style={{ paddingTop: "8px" }} /></>}
        <TextField style={{ width: inTableCell ? "90%" : "35%" }}
          className="inputField" type='text' label={'Display'}
          size="small" variant='outlined' value={startingValue.display || ''}
          onChange={(e) => {
            let newCoding = { system: "", version: "", code: "", display: "" };
            newCoding.display = e.target.value;
            if (startingValue.version) { newCoding.version = startingValue.version; } else { delete newCoding.version; }
            if (startingValue.system) { newCoding.system = startingValue.system; } else { delete newCoding.system; }
            if (startingValue.code) { newCoding.code = startingValue.code; } else { delete newCoding.code; }
            if (startingValue.code || startingValue.system || e.target.value || startingValue.version) {
              handleChange(elementName, newCoding, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }} />
      </div>
    </>
  }
});

const CommentEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, allowedValues, valueSet, dataEntryStyle, inTableCell, debug, startCollapsed }) => {
  //Comment is not a FHIR Datatype. Comment schema is an object with:
  //summary 0..1 markdown
  //path 0..* uri
  //freeToShare 0..1 boolean
  let startingComment = { "informationType": "", "summary": "", "type": "", "classifier": [], "author": "", "path": [], "freeToShare": true }
  if (!startingValue) {
    startingComment = "";
  } else {
    if (startingValue.extension) { startingComment.extension = startingValue.extension; }
    if (startingValue.informationType) { startingComment.informationType = startingValue.informationType; }
    if (startingValue.summary) { startingComment.summary = startingValue.summary; }
    if (startingValue.type) { startingComment.type = startingValue.type; }
    if (startingValue.classifier) { startingComment.classifier = startingValue.classifier; }
    if (startingValue.author) { startingComment.author = startingValue.author; }
    if (startingValue.path) { startingComment.path = startingValue.path; }
    if (typeof startingValue.freeToShare === "boolean") { startingComment.freeToShare = startingValue.freeToShare; }
  }

  const [commentState, setCommentState] = useState(startingComment);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(commentState).length) {
      let newComment = {};
      if (commentState.extension) { newComment.extension = commentState.extension; }
      if (commentState.informationType) { newComment.informationType = commentState.informationType; }
      if (commentState.summary) { newComment.summary = commentState.summary; }
      if (commentState.type && Object.keys(commentState.type).length) { newComment.type = commentState.type; }
      if (commentState.classifier && Array.isArray(commentState.classifier) && commentState.classifier.length) { newComment.classifier = commentState.classifier; }
      if (commentState.author && Object.keys(commentState.author).length) { newComment.author = commentState.author; }
      if (commentState.path && Array.isArray(commentState.path) && commentState.path.length) { newComment.path = commentState.path; }
      if (typeof commentState.freeToShare === "boolean") { newComment.freeToShare = commentState.freeToShare; }
      if (Object.keys(newComment).length === 0) {
        newComment = null;
      }
      handleChange(elementName, newComment, setResourceState);
    }
  }), [commentState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR comment={startingComment} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <MarkdownEntry elementName='summary' fieldLabel='Summary of Comment'
          startingValue={commentState.summary || null}
          setResourceState={setCommentState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <ArrayEntry datatype="uri" elementName='path' fieldLabel='Path Commented On'
          startingValue={commentState.path || null} setResourceState={setCommentState}
          dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <BooleanEntry elementName="freeToShare" fieldLabel='Free to share?'
          startingValue={commentState.freeToShare ?? true} setResourceState={setCommentState} />
      </div>
    </>
  }
});

const CompositionTableCellArrayEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  columnHeaders, dataEntryStyle, inTableCell, addTableRowModalState, setSourceJsonState }) => {
  let startingArrayAsObject = {};
  if (startingValue) {
    for (let itemIndex in startingValue) {
      startingArrayAsObject[itemIndex] = startingValue[itemIndex];
    }
  } else {
    startingArrayAsObject['0'] = null;
  }

  const [arrayState, setArrayState] = useState(startingArrayAsObject);

  useEffect((() => {
    if (Object.keys(arrayState).length) {
      let newArray = [];
      for (let key of Object.keys(arrayState)) {
        //alternatively it can be written as if (arrayState[key] === false || (arrayState[key] && arrayState[key] !== "DELETEME")) {
        if (arrayState[key] !== null && arrayState[key] !== "" && arrayState[key] !== undefined && arrayState[key] !== "DELETEME") {
          if (typeof arrayState[key] !== "object" || (Array.isArray(arrayState[key]) && arrayState[key].length !== 0) || Object.keys(arrayState[key]).length !== 0) {
            newArray.push(arrayState[key]);
          }
        }
      }
      if (newArray.length > 0) {
        handleChange(elementName, newArray, setResourceState);
      } else {
        handleChange(elementName, null, setResourceState);
      }
    }
  }), [arrayState]);

  return <div>
    {fieldLabel && <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>}
    <div style={{ marginLeft: "24px" }}>
      {Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
        let title = keyValuePair[1].title;
        let columnHeader = columnHeaders[title];
        return <div key={keyValuePairIndex}>
          <DataEntry datatype="CompositionTableCell" elementName={keyValuePair[0]}
            fieldLabel={columnHeader} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} addTableRowModalState={addTableRowModalState}
            startingValue={keyValuePair[1]} setResourceState={setArrayState}
            setSourceJsonState={setSourceJsonState} />
        </div>
      })}
    </div>
  </div>
});

const ContactDetailEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingContactDetail = { "name": "", "telecom": [] };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingContactDetail.extension = startingValue.extension; }
    if (startingValue.name) { startingContactDetail.name = startingValue.name; }
    if (startingValue.telecom) { startingContactDetail.telecom = startingValue.telecom; }
  }
  const [contactDetailState, setContactDetailState] = useState(startingContactDetail);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(contactDetailState).length) {
      let newContactDetail = {};
      if (contactDetailState.extension) { newContactDetail.extension = contactDetailState.extension; }
      if (contactDetailState.name) { newContactDetail.name = contactDetailState.name; }
      if (contactDetailState.telecom !== null && contactDetailState.telecom !== undefined &&
        Array.isArray(contactDetailState.telecom) && contactDetailState.telecom.length !== 0 &&
        !(contactDetailState.telecom.length === 1 && Object.keys(contactDetailState.telecom[0]).length === 0)) {
        newContactDetail.telecom = contactDetailState.telecom;
      }
      if (Object.keys(newContactDetail).length === 0) {
        newContactDetail = null;
      }
      handleChange(elementName, newContactDetail, setResourceState);
    }
  }), [contactDetailState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR contactDetail={startingContactDetail} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <div>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <StringEntry elementName='name' fieldLabel='Name'
          startingValue={contactDetailState.name} setResourceState={setContactDetailState} />
        <ArrayEntry datatype='ContactPoint' elementName='telecom' startCollapsed={true}
          startEmptyArrayClosed={true}
          fieldLabel='Contact Point' startingValue={contactDetailState.line} setResourceState={setContactDetailState} />
      </div>
    </div>
  }
})

const contactPointDotSystemValues = ['phone', 'fax', 'email', 'pager', 'url', 'sms', 'other'];
const contactPointDotUseValues = ['home', 'work', 'temp', 'old', 'mobile'];
const ContactPointEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingContactPoint = { "system": "", "value": "", "use": "", "rank": "", "period": "" };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingContactPoint.extension = startingValue.extension; }
    if (startingValue.system) { startingContactPoint.system = startingValue.system; }
    if (startingValue.value) { startingContactPoint.value = startingValue.value; }
    if (startingValue.use) { startingContactPoint.use = startingValue.use; }
    if (startingValue.rank) { startingContactPoint.rank = startingValue.rank; }
    if (startingValue.period) { startingContactPoint.period = startingValue.period; }
  }
  const [contactPointState, setContactPointState] = useState(startingContactPoint);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(contactPointState).length) {
      let newContactPoint = {};
      if (contactPointState.extension) { newContactPoint.extension = contactPointState.extension; }
      if (contactPointState.system) { newContactPoint.system = contactPointState.system; }
      if (contactPointState.value) { newContactPoint.value = contactPointState.value; }
      if (contactPointState.use) { newContactPoint.use = contactPointState.use; }
      if (contactPointState.rank) { newContactPoint.rank = contactPointState.rank; }
      if (contactPointState.period && Object.keys(contactPointState.period).length) { newContactPoint.period = contactPointState.period; }
      if (Object.keys(newContactPoint).length === 0) {
        newContactPoint = null;
      }
      handleChange(elementName, newContactPoint, setResourceState);
    }
  }), [contactPointState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR contactPoint={startingContactPoint} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <div>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <CodeEntry elementName='system' fieldLabel='Contact Type'
          allowedValues={contactPointDotSystemValues}
          startingValue={contactPointState.system} setResourceState={setContactPointState} />
        <StringEntry elementName='value' fieldLabel='Value'
          startingValue={contactPointState.value} setResourceState={setContactPointState} />
        <CodeEntry elementName='use' fieldLabel='Use'
          allowedValues={contactPointDotUseValues}
          startingValue={contactPointState.use} setResourceState={setContactPointState} />
        <PositiveIntEntry elementName='rank' fieldLabel='Rank'
          startingValue={contactPointState.rank} setResourceState={setContactPointState} />
        <br /><br />
        <PeriodEntry elementName='period' fieldLabel='Period' startCollapsed={true}
          startingValue={contactPointState.period} setResourceState={setContactPointState} />
      </div>
    </div>
  }
})

const DateEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, width }) => {
  const [calendarOpen, setCalendarOpen] = useState(false);

  const CalendarModal = () => {
    return <div className="calendarModal"><MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format="yyyy-MM-dd"
        emptyLabel="yyyy-MM-dd"
        value={startingValue ? parseISO(startingValue, "yyyy-MM-dd") : null}
        open={calendarOpen}
        onClose={() => setCalendarOpen(false)}
        onChange={(e) => {
          handleChange(elementName, format(e, "yyyy-MM-dd"), setResourceState);
        }}
      />
    </MuiPickersUtilsProvider></div>
  }

  return <div>
    <TextField style={{ width: "100%", marginTop: "12px", maxWidth: width ? width : "150px" }}
      className="inputField" type='text' label={fieldLabel} size="small" variant='outlined'
      value={startingValue || ""}
      onChange={(e) => { handleChange(elementName, e.target.value, setResourceState) }} />
    <Button style={{ marginTop: "18px", marginLeft: "6px", fontSize: "28px", padding: "0px", backgroundColor: "white" }} className="calendarButton"
      content="📅" onClick={() => { setCalendarOpen(true) }} />
    <CalendarModal />
  </div>
})

const DateTimeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  const [calendarOpen, setCalendarOpen] = useState(false);

  const CalendarModal = () => {
    return <div className="calendarModal"><MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        format="yyyy-MM-dd HH:mm:ss"
        value={startingValue ? parseISO(startingValue, "yyyy-MM-dd HH:mm:ss") : null}
        emptyLabel="yyyy-MM-dd HH:mm:ss"
        open={calendarOpen}
        onClose={() => setCalendarOpen(false)}
        onChange={(e) => {
          handleChange(elementName, format(e, "yyyy-MM-dd") + "T" + format(e, "HH:mm:ss"), setResourceState);
        }}
      />
    </MuiPickersUtilsProvider></div>
  }

  return <div>
    <TextField style={{ width: "100%", marginTop: "12px", maxWidth: "300px" }}
      className="inputField" type='text' label={fieldLabel} size="small" variant='outlined'
      value={startingValue || ""}
      onChange={(e) => { handleChange(elementName, e.target.value, setResourceState) }} />
    <Button style={{ marginTop: "18px", marginLeft: "6px", fontSize: "28px", padding: "0px", backgroundColor: "white" }} className="calendarButton"
      content="📅" onClick={() => { setCalendarOpen(true) }} />
    <CalendarModal />
  </div>
})

const DecimalEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  return <TextField style={{ width: "100%", marginTop: "12px", maxWidth: "260px" }}
    className="inputField" type='number' inputProps={{ step: "any" }} label={fieldLabel} size="small" variant='outlined'
    value={startingValue ?? ""}
    onInput={alertIfInvalidNumberEntry}
    onChange={(e) => {
      if (e.target.value === null) {
        handleChange(elementName, "", setResourceState);
      } else if (isNaN(e.target.value)) {
        handleChange(elementName, e.target.value, setResourceState);
      } else {
        handleChange(elementName, Number(e.target.value), setResourceState);
      }
    }} />
});

const dosageDotAdditionalInstructionSystemChoices = [{ 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }];
const dosageDotAsNeededForSystemChoices = [{ 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }];
const dosageDotSiteSystemChoices = [{ 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }];
const dosageDotRouteSystemChoices = [{ 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }];
const dosageDotMethodSystemChoices = [{ 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }];
const DosageEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle, inTableCell, debug, startCollapsed }) => {
  let startingDosage = {
    'sequence': "", 'text': "", 'additionalInstruction': [], 'patientInstruction': "", 'timing': "",
    'asNeeded': null, 'asNeededFor': [], 'site': "", 'route': "", 'method': "", 'doseAndRate': [],
    'maxDosePerPeriod': [], 'maxDosePerAdministration': "", 'maxDosePerLifetime': ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingDosage.extension = startingValue.extension; }
    if (startingValue.sequence) { startingDosage.sequence = startingValue.sequence; }
    if (startingValue.text) { startingDosage.text = startingValue.text; }
    if (startingValue.additionalInstruction) { startingDosage.additionalInstruction = startingValue.additionalInstruction; }
    if (startingValue.patientInstruction) { startingDosage.patientInstruction = startingValue.patientInstruction; }
    if (startingValue.timing) { startingDosage.timing = startingValue.timing; }
    if (typeof startingValue.asNeeded === "boolean") { startingDosage.asNeeded = startingValue.asNeeded; }
    if (startingValue.asNeededFor) { startingDosage.asNeededFor = startingValue.asNeededFor; }
    if (startingValue.site) { startingDosage.site = startingValue.site; }
    if (startingValue.route) { startingDosage.route = startingValue.route; }
    if (startingValue.method) { startingDosage.method = startingValue.method; }
    if (startingValue.doseAndRate) { startingDosage.doseAndRate = startingValue.doseAndRate; }
    if (startingValue.maxDosePerPeriod) { startingDosage.maxDosePerPeriod = startingValue.maxDosePerPeriod; }
    if (startingValue.maxDosePerAdministration) { startingDosage.maxDosePerAdministration = startingValue.maxDosePerAdministration; }
    if (startingValue.maxDosePerLifetime) { startingDosage.maxDosePerLifetime = startingValue.maxDosePerLifetime; }
  }

  const [dosageState, setDosageState] = useState(startingDosage);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(dosageState).length) {
      let newDosage = {};
      if (dosageState.extension) { newDosage.extension = dosageState.extension; }
      if (dosageState.sequence) { newDosage.sequence = dosageState.sequence; }
      if (dosageState.text) { newDosage.text = dosageState.text; }
      if (dosageState.additionalInstruction && Array.isArray(dosageState.additionalInstruction) &&
        dosageState.additionalInstruction.length) {
        newDosage.additionalInstruction = dosageState.additionalInstruction;
      }
      if (dosageState.patientInstruction) { newDosage.patientInstruction = dosageState.patientInstruction; }
      if (dosageState.timing && Object.keys(dosageState.timing).length) {
        newDosage.timing = dosageState.timing;
      }
      if (typeof dosageState.asNeeded === "boolean") {
        newDosage.asNeeded = dosageState.asNeeded;
      }
      if (dosageState.asNeededFor && Array.isArray(dosageState.asNeededFor) &&
        dosageState.asNeededFor.length) {
        newDosage.asNeededFor = dosageState.asNeededFor;
      }
      if (dosageState.site && Object.keys(dosageState.site).length) {
        newDosage.site = dosageState.site;
      }
      if (dosageState.route && Object.keys(dosageState.route).length) {
        newDosage.route = dosageState.route;
      }
      if (dosageState.method && Object.keys(dosageState.method).length) {
        newDosage.method = dosageState.method;
      }
      if (dosageState.doseAndRate && Array.isArray(dosageState.doseAndRate) &&
        dosageState.doseAndRate.length) {
        newDosage.doseAndRate = dosageState.doseAndRate;
      }
      if (dosageState.maxDosePerPeriod && Array.isArray(dosageState.maxDosePerPeriod) &&
        dosageState.maxDosePerPeriod.length) {
        newDosage.maxDosePerPeriod = dosageState.maxDosePerPeriod;
      }
      if (dosageState.maxDosePerAdministration && Object.keys(dosageState.maxDosePerAdministration).length) {
        newDosage.maxDosePerAdministration = dosageState.maxDosePerAdministration;
      }
      if (dosageState.maxDosePerLifetime && Object.keys(dosageState.maxDosePerLifetime).length) {
        newDosage.maxDosePerLifetime = dosageState.maxDosePerLifetime;
      }
      if (Object.keys(newDosage).length === 0) {
        newDosage = null;
      }
      handleChange(elementName, newDosage, setResourceState);
    }
  }), [dosageState]);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.Dosage(startingDosage)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <IntegerEntry elementName='sequence'
          fieldLabel='Dosage Sequence' startingValue={dosageState.sequence}
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <StringEntry elementName='text'
          fieldLabel='Dosage Instruction' startingValue={dosageState.text}
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <ArrayEntry elementName='additionalInstruction'
          datatype='CodeableConcept' fieldLabel='Additional Instruction' startingValue={dosageState.additionalInstruction}
          systemChoices={dosageDotAdditionalInstructionSystemChoices} systemChoicesOpen
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <StringEntry elementName='patientInstruction'
          fieldLabel='Patient Instruction' startingValue={dosageState.patientInstruction}
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <TimingEntry elementName='timing'
          fieldLabel='Timing' startingValue={dosageState.timing}
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <BooleanEntry elementName='asNeeded'
          fieldLabel='As Needed' startingValue={dosageState.asNeeded}
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <ArrayEntry elementName='asNeededFor'
          datatype='CodeableConcept' fieldLabel='As Needed For' startingValue={dosageState.asNeededFor}
          systemChoices={dosageDotAsNeededForSystemChoices} systemChoicesOpen
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <CodeableConceptEntry elementName='site'
          fieldLabel='Body Site' startingValue={dosageState.site}
          systemChoices={dosageDotSiteSystemChoices} systemChoicesOpen
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <CodeableConceptEntry elementName='route'
          fieldLabel='Route' startingValue={dosageState.route}
          systemChoices={dosageDotRouteSystemChoices} systemChoicesOpen
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <CodeableConceptEntry elementName='method'
          fieldLabel='Method' startingValue={dosageState.method}
          systemChoices={dosageDotMethodSystemChoices} systemChoicesOpen
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <ArrayEntry elementName='doseAndRate'
          datatype='DoseAndRate' fieldLabel='Dose and Rate' startingValue={dosageState.doseAndRate}
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <ArrayEntry elementName='maxDosePerPeriod'
          datatype='Ratio' fieldLabel='Maximum Dose per Period' startingValue={dosageState.maxDosePerPeriod}
          setResourceState={setDosageState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <QuantityEntry elementName='maxDosePerAdministration'
          fieldLabel='Maximum Dose per Administration' startingValue={dosageState.maxDosePerAdministration}
          setResourceState={setDosageState} dataEntryStyle={'SimpleQuantity'} inTableCell={inTableCell} debug={debug} />
        <QuantityEntry elementName='maxDosePerLifetime'
          fieldLabel='Maximum Dose per Lifetime' startingValue={dosageState.maxDosePerLifetime}
          setResourceState={setDosageState} dataEntryStyle={'SimpleQuantity'} inTableCell={inTableCell} debug={debug} />
      </div>
    </>
  }
});

const doseAndRateDotTypeValueSet = [
  { system: "https://terminology.hl7.org/5.0.0/CodeSystem-dose-rate-type.html", code: "calculated", display: "Calculated" },
  { system: "https://terminology.hl7.org/5.0.0/CodeSystem-dose-rate-type.html", code: "ordered", display: "Ordered" }
]
const doseAndRateDotDoseAllowedDatatypes = ['Range', 'SimpleQuantity'];
const doseAndRateDotRateAllowedDatatypes = ['Ratio', 'Range', 'SimpleQuantity'];
const DoseAndRateEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle, inTableCell, debug, startCollapsed }) => {
  let startingDoseAndRate = {
    'type': "", 'doseRange': "", 'doseQuantity': "",
    'rateRatio': "", 'rateRange': "", 'rateQuantity': ""
  };
  let startingDoseDatatype = 'none';
  let startingRateDatatype = 'none';
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingDoseAndRate.extension = startingValue.extension; }
    if (startingValue.type) { startingDoseAndRate.type = startingValue.type; }
    if (startingValue.doseRange) {
      startingDoseAndRate.doseRange = startingValue.doseRange;
      startingDoseDatatype = 'Range';
    }
    if (startingValue.doseQuantity) {
      startingDoseAndRate.doseQuantity = startingValue.doseQuantity;
      startingDoseDatatype = 'SimpleQuantity';
    }
    if (startingValue.rateRatio) {
      startingDoseAndRate.rateRatio = startingValue.rateRatio;
      startingRateDatatype = 'Ratio';
    }
    if (startingValue.rateRange) {
      startingDoseAndRate.rateRange = startingValue.rateRange;
      startingRateDatatype = 'Range';
    }
    if (startingValue.rateQuantity) {
      startingDoseAndRate.rateQuantity = startingValue.rateQuantity;
      startingRateDatatype = 'SimpleQuantity';
    }
  }

  const [doseAndRateState, setDoseAndRateState] = useState(startingDoseAndRate);

  const [doseDatatypeState, setDoseDatatypeState] = useState(startingDoseDatatype);
  const [rateDatatypeState, setRateDatatypeState] = useState(startingRateDatatype);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(doseAndRateState).length) {
      let newDoseAndRate = {};
      if (doseAndRateState.extension) { newDoseAndRate.extension = doseAndRateState.extension; }
      if (doseAndRateState.type && Object.keys(doseAndRateState.type).length) {
        newDoseAndRate.type = doseAndRateState.type;
      }
      if (doseDatatypeState === 'Range' && doseAndRateState.doseRange && Object.keys(doseAndRateState.doseRange).length) {
        newDoseAndRate.doseRange = doseAndRateState.doseRange;
      }
      if (doseDatatypeState === 'SimpleQuantity' && doseAndRateState.doseQuantity && Object.keys(doseAndRateState.doseQuantity).length) {
        newDoseAndRate.doseQuantity = doseAndRateState.doseQuantity;
      }
      if (rateDatatypeState === 'Ratio' && doseAndRateState.rateRatio && Object.keys(doseAndRateState.rateRatio).length) {
        newDoseAndRate.rateRatio = doseAndRateState.rateRatio;
      }
      if (rateDatatypeState === 'Range' && doseAndRateState.rateRange && Object.keys(doseAndRateState.rateRange).length) {
        newDoseAndRate.rateRange = doseAndRateState.rateRange;
      }
      if (rateDatatypeState === 'SimpleQuantity' && doseAndRateState.rateQuantity && Object.keys(doseAndRateState.rateQuantity).length) {
        newDoseAndRate.rateQuantity = doseAndRateState.rateQuantity;
      }
      if (Object.keys(newDoseAndRate).length === 0) {
        newDoseAndRate = null;
      }
      handleChange(elementName, newDoseAndRate, setResourceState);
    }
  }), [doseAndRateState, doseDatatypeState, rateDatatypeState]);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.DoseAndRate(startingDoseAndRate)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <CodeableConceptEntry elementName='type' fieldLabel='Type'
          startingValue={doseAndRateState.type} setResourceState={setDoseAndRateState}
          valueSet={doseAndRateDotTypeValueSet}
          dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        <p style={{ marginBottom: "0px" }}><b>Dose:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='dose[x]' allowedDatatypes={doseAndRateDotDoseAllowedDatatypes}
            inTableCell={inTableCell} datatypeState={doseDatatypeState} setDatatypeState={setDoseDatatypeState} />
          {doseDatatypeState === 'Range' &&
            <RangeEntry elementName='doseRange' fieldLabel={'Range per Dose'}
              dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
              startingValue={doseAndRateState.doseRange} setResourceState={setDoseAndRateState} />}
          {doseDatatypeState === 'SimpleQuantity' &&
            <QuantityEntry elementName='doseQuantity' fieldLabel={'Quantity per Dose'}
              dataEntryStyle={"SimpleQuantity"} inTableCell={inTableCell}
              startingValue={doseAndRateState.doseQuantity} setResourceState={setDoseAndRateState} />}
        </div>
        <p style={{ marginBottom: "0px" }}><b>Rate:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='rate[x]' allowedDatatypes={doseAndRateDotRateAllowedDatatypes}
            datatypeState={rateDatatypeState} setDatatypeState={setRateDatatypeState} />
          {rateDatatypeState === 'Ratio' &&
            <RatioEntry elementName='rateRatio' fieldLabel={'Ratio for Dose per Time'}
              startingValue={doseAndRateState.rateRatio} setResourceState={setDoseAndRateState} />}
          {rateDatatypeState === 'Range' &&
            <RangeEntry elementName='rateRange' fieldLabel={'Range for Dose per Time'}
              dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
              startingValue={doseAndRateState.rateRange} setResourceState={setDoseAndRateState} />}
          {rateDatatypeState === 'SimpleQuantity' &&
            <QuantityEntry elementName='rateQuantity' fieldLabel={'Quantity for Dose per Time'}
              dataEntryStyle={"SimpleQuantity"} inTableCell={inTableCell}
              startingValue={doseAndRateState.rateQuantity} setResourceState={setDoseAndRateState} />}
        </div>
      </div>
    </>
  }
})

const ExpandToAddOrEdit = ({ startingValue, setStartCollapsedState }) => {
  return <span className={"unselectable"} style={{ cursor: "pointer" }}
    onClick={() => { setStartCollapsedState(false) }}>
    {startingValue ? <>✎ Edit</> : <>➕ Add</>}
  </span>
}

const ExpressionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  if (!startingValue) {
    startingValue = "";
  }
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR expression={startingValue} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <div>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <span><TextField style={{ width: "100%" }} className="inputField" type='text' label={'Name (short label)'}
          size="small" variant='outlined' value={startingValue.name || ''}
          onChange={(e) => {
            let newExpression = { description: "", name: "", language: "", expression: "", reference: "" };
            newExpression.name = e.target.value;
            if (startingValue.description) { newExpression.description = startingValue.description; } else { delete newExpression.description; }
            if (startingValue.language) { newExpression.language = startingValue.language; } else { delete newExpression.language; }
            if (startingValue.expression) { newExpression.expression = startingValue.expression; } else { delete newExpression.expression; }
            if (startingValue.reference) { newExpression.reference = startingValue.reference; } else { delete newExpression.reference; }
            if (e.target.value || startingValue.description || startingValue.language || startingValue.expression || startingValue.reference) {
              handleChange(elementName, newExpression, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }} /></span>
        <span><TextField style={{ width: "100%" }} className="inputField" type='text' label={'Description (natural language)'}
          size="small" variant='outlined' value={startingValue.description || ''} multiline
          onChange={(e) => {
            let newExpression = { description: "", name: "", language: "", expression: "", reference: "" };
            newExpression.description = e.target.value;
            if (startingValue.name) { newExpression.name = startingValue.name; } else { delete newExpression.name; }
            if (startingValue.language) { newExpression.language = startingValue.language; } else { delete newExpression.language; }
            if (startingValue.expression) { newExpression.expression = startingValue.expression; } else { delete newExpression.expression; }
            if (startingValue.reference) { newExpression.reference = startingValue.reference; } else { delete newExpression.reference; }
            if (e.target.value || startingValue.name || startingValue.language || startingValue.expression || startingValue.reference) {
              handleChange(elementName, newExpression, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }} /></span>
        <span><TextField style={{ width: "100%" }} className="inputField" type='text' label={'Language (e.g. text/cql)'}
          size="small" variant='outlined' value={startingValue.language || ''}
          onChange={(e) => {
            let newExpression = { description: "", name: "", language: "", expression: "", reference: "" };
            newExpression.language = e.target.value;
            if (startingValue.name) { newExpression.name = startingValue.name; } else { delete newExpression.name; }
            if (startingValue.description) { newExpression.description = startingValue.description; } else { delete newExpression.description; }
            if (startingValue.expression) { newExpression.expression = startingValue.expression; } else { delete newExpression.expression; }
            if (startingValue.reference) { newExpression.reference = startingValue.reference; } else { delete newExpression.reference; }
            if (e.target.value || startingValue.name || startingValue.description || startingValue.expression || startingValue.reference) {
              handleChange(elementName, newExpression, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }} /></span>
        <span><TextField style={{ width: "100%" }} className="inputField" type='text' label={'Expression (in the specified language)'}
          size="small" variant='outlined' value={startingValue.expression || ''} multiline
          onChange={(e) => {
            let newExpression = { description: "", name: "", language: "", expression: "", reference: "" };
            newExpression.expression = e.target.value;
            if (startingValue.name) { newExpression.name = startingValue.name; } else { delete newExpression.name; }
            if (startingValue.language) { newExpression.language = startingValue.language; } else { delete newExpression.language; }
            if (startingValue.description) { newExpression.description = startingValue.description; } else { delete newExpression.description; }
            if (startingValue.reference) { newExpression.reference = startingValue.reference; } else { delete newExpression.reference; }
            if (e.target.value || startingValue.name || startingValue.language || startingValue.description || startingValue.reference) {
              handleChange(elementName, newExpression, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }} /></span>
        <span><TextField style={{ width: "100%" }} className="inputField" type='text' label={'Reference (URL)'}
          size="small" variant='outlined' value={startingValue.reference || ''}
          onChange={(e) => {
            let newExpression = { description: "", name: "", language: "", expression: "", reference: "" };
            newExpression.reference = e.target.value;
            if (startingValue.name) { newExpression.name = startingValue.name; } else { delete newExpression.name; }
            if (startingValue.description) { newExpression.description = startingValue.description; } else { delete newExpression.description; }
            if (startingValue.expression) { newExpression.expression = startingValue.expression; } else { delete newExpression.expression; }
            if (startingValue.language) { newExpression.language = startingValue.language; } else { delete newExpression.language; }
            if (e.target.value || startingValue.name || startingValue.description || startingValue.expression || startingValue.language) {
              handleChange(elementName, newExpression, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }} /></span>
      </div>
    </div>
  }
});

const ExtensionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed,
  extensionUrl, extensionValueType }) => {
  let startingExtension = { "url": "" }
  let startingExtensionDatatype = "";
  if (startingValue) {
    for (let key in startingValue) {
      startingExtension[key] = startingValue[key];
      if (key.includes("value")) {
        startingExtensionDatatype = key.replace("value", "");
        if (startingExtensionDatatype === "Uri") {
          startingExtensionDatatype = "uri";
        }
        if (startingExtensionDatatype === "String") {
          startingExtensionDatatype = "string";
        }
        if (startingExtensionDatatype === "Code") {
          startingExtensionDatatype = "code";
        }
        if (startingExtensionDatatype === "Integer") {
          startingExtensionDatatype = "integer";
        }
        if (startingExtensionDatatype === "Decimal") {
          startingExtensionDatatype = "decimal";
        }
        if (startingExtensionDatatype === "PositiveInt") {
          startingExtensionDatatype = "positiveInt";
        }
        if (startingExtensionDatatype === "UnsignedInt") {
          startingExtensionDatatype = "unsignedInt";
        }
        if (startingExtensionDatatype === "Date") {
          startingExtensionDatatype = "date";
        }
        if (startingExtensionDatatype === "DateTime") {
          startingExtensionDatatype = "dateTime";
        }
        if (startingExtensionDatatype === "Canonical") {
          startingExtensionDatatype = "canonical";
        }
        if (startingExtensionDatatype === "Boolean") {
          startingExtensionDatatype = "boolean";
        }
        if (startingExtensionDatatype === "Time") {
          startingExtensionDatatype = "time";
        }
      }
    }
  } else {
    startingExtension.url = extensionUrl || "";
  }

  const [extensionState, setExtensionState] = useState(startingExtension);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);
  const [extensionDatatypeState, setExtensionDatatypeState] = useState(extensionValueType || startingExtensionDatatype || "");

  useEffect((() => {
    if (Object.keys(extensionState).length) {
      let newExtension = {};
      for (let key in extensionState) {
        newExtension[key] = extensionState[key];
      }
      handleChange(elementName, newExtension, setResourceState);
    }
  }), [extensionState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR extension={startingValue} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <div>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <UriEntry elementName='url' fieldLabel='Extension URL'
          startingValue={extensionState.url} setResourceState={setExtensionState} />
        <TextField style={{ width: "100%" }} className="inputField" type='text' label={'Extension Datatype'}
          size="small" variant='outlined' value={extensionDatatypeState}
          onChange={(e) => { setExtensionDatatypeState(e.target.value) }} />
        {extensionDatatypeState &&
          <DataEntry datatype={extensionDatatypeState}
            elementName={'value' + extensionDatatypeState[0].toUpperCase() + extensionDatatypeState.slice(1)}
            fieldLabel='Extension Value'
            startingValue={extensionState['value' + extensionDatatypeState[0].toUpperCase() + extensionDatatypeState.slice(1)]}
            setResourceState={setExtensionState} />
        }
      </div>
    </div>
  }
});

const humanNameDotUseValues = ["usual", "official", "temp", "nickname", "anonymous", "old", "maiden"];
const HumanNameEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingHumanName = { "use": "", "text": "", "family": "", "given": [], "prefix": [], "suffix": [], "period": "" };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingHumanName.extension = startingValue.extension; }
    if (startingValue.use) { startingHumanName.use = startingValue.use; }
    if (startingValue.text) { startingHumanName.text = startingValue.text; }
    if (startingValue.family) { startingHumanName.family = startingValue.family; }
    if (startingValue.given) { startingHumanName.given = startingValue.given; }
    if (startingValue.prefix) { startingHumanName.prefix = startingValue.prefix; }
    if (startingValue.suffix) { startingHumanName.suffix = startingValue.suffix; }
    if (startingValue.period) { startingHumanName.period = startingValue.period; }
  }
  const [humanNameState, setHumanNameState] = useState(startingHumanName);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(humanNameState).length) {
      let newHumanName = {};
      if (humanNameState.extension) { newHumanName.extension = humanNameState.extension; }
      if (humanNameState.use) { newHumanName.use = humanNameState.use; }
      if (humanNameState.text) { newHumanName.text = humanNameState.text; }
      if (humanNameState.family) { newHumanName.family = humanNameState.family; }
      if (humanNameState.given !== null && humanNameState.given !== undefined &&
        Array.isArray(humanNameState.given) && humanNameState.given.length !== 0 &&
        !(humanNameState.given.length === 1 && humanNameState.given[0] === "")) {
        newHumanName.given = humanNameState.given;
      }
      if (humanNameState.prefix !== null && humanNameState.prefix !== undefined &&
        Array.isArray(humanNameState.prefix) && humanNameState.prefix.length !== 0 &&
        !(humanNameState.prefix.length === 1 && humanNameState.prefix[0] === "")) {
        newHumanName.prefix = humanNameState.prefix;
      }
      if (humanNameState.suffix !== null && humanNameState.suffix !== undefined &&
        Array.isArray(humanNameState.suffix) && humanNameState.suffix.length !== 0 &&
        !(humanNameState.suffix.length === 1 && humanNameState.suffix[0] === "")) {
        newHumanName.suffix = humanNameState.suffix;
      }
      if (humanNameState.period && Object.keys(humanNameState.period).length) { newHumanName.period = humanNameState.period; }
      if (Object.keys(newHumanName).length === 0) {
        newHumanName = null;
      }
      handleChange(elementName, newHumanName, setResourceState);
    }
  }), [humanNameState]);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.HumanName(startingHumanName)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <div>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <CodeEntry elementName='use' fieldLabel='Use'
          startingValue={humanNameState.use} setResourceState={setHumanNameState} dataEntryStyle='dropdown'
          allowedValues={humanNameDotUseValues} />
        <StringEntry elementName='text' fieldLabel='Text'
          startingValue={humanNameState.text} setResourceState={setHumanNameState} />
        <StringEntry elementName='family' fieldLabel='Family'
          startingValue={humanNameState.family} setResourceState={setHumanNameState} />
        <ArrayEntry datatype='string' elementName='given'
          fieldLabel='Given' startingValue={humanNameState.given} setResourceState={setHumanNameState} />
        <ArrayEntry datatype='string' elementName='prefix'
          fieldLabel='Prefix' startingValue={humanNameState.prefix} setResourceState={setHumanNameState} />
        <ArrayEntry datatype='string' elementName='suffix'
          fieldLabel='Suffix' startingValue={humanNameState.suffix} setResourceState={setHumanNameState} />
        <PeriodEntry elementName='period' fieldLabel='Period' startCollapsed={true}
          startingValue={humanNameState.period} setResourceState={setHumanNameState} />
      </div>
    </div>
  }
})

const IdEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, debug, marginTop, required }) => {
  return <TextField error={required && !startingValue}
    style={{ width: "20%", marginTop: marginTop || "12px" }}
    className="inputField" type='text' label={fieldLabel} size="small" variant='outlined'
    value={startingValue || ""}
    onChange={(e) => { handleChange(elementName, e.target.value, setResourceState) }} />
});

const identifierDotUseValues = ["usual", "official", "temp", "secondary", "old"];
const identifierDotAssignerResourceTypes = ['Organization'];
const IdentifierEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  allowedValues, valueSet, dataEntryStyle, inTableCell, debug, fromIdentifier,
  fromReference, startCollapsed }) => {
  let startingIdentifier = { "use": "", "type": "", "system": "", "value": "", "period": "", "assigner": "" };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingIdentifier.extension = startingValue.extension; }
    if (startingValue.use) { startingIdentifier.use = startingValue.use; }
    if (startingValue.type) { startingIdentifier.type = startingValue.type; }
    if (startingValue.system) { startingIdentifier.system = startingValue.system; }
    if (startingValue.value) { startingIdentifier.value = startingValue.value; }
    if (startingValue.period) { startingIdentifier.period = startingValue.period; }
    if (startingValue.assigner) { startingIdentifier.assigner = startingValue.assigner; }
  }
  let isFOI = false;
  if (dataEntryStyle === 'preserveFOI') {
    if (startingValue?.type?.text === 'FEvIR Object Identifier' || startingValue?.type?.text === 'FOI') {
      isFOI = true;
    }
  }

  const [identifierState, setIdentifierState] = useState(startingIdentifier);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed)

  useEffect((() => {
    if (Object.keys(identifierState).length) {
      let newIdentifier = {};
      if (identifierState.extension) { newIdentifier.extension = identifierState.extension; }
      if (identifierState.use) { newIdentifier.use = identifierState.use; }
      if (identifierState.type && Object.keys(identifierState.type).length) { newIdentifier.type = identifierState.type; }
      if (identifierState.system) { newIdentifier.system = identifierState.system; }
      if (identifierState.value) { newIdentifier.value = identifierState.value; }
      if (identifierState.period && Object.keys(identifierState.period).length) {
        newIdentifier.period = identifierState.period;
      }
      if (identifierState.assigner && Object.keys(identifierState.assigner).length) {
        newIdentifier.assigner = identifierState.assigner;
      }
      if (Object.keys(newIdentifier).length === 0) {
        newIdentifier = null;
      }
      handleChange(elementName, newIdentifier, setResourceState);
    }
  }), [identifierState]);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.Identifier(startingIdentifier)}
        &nbsp;&nbsp;
        {!isFOI && <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />}
      </p>
    </>
  } else {
    return <>
      {isFOI ?
        <><p>FOI {startingValue.value ? startingValue.value : '[Missing]'}</p></>
        :
        <>
          <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
          <div style={{ marginLeft: "24px" }}>
            <p>
              <b>Identifier Value: </b>
              <StringEntry elementName='value' fieldLabel='Identifier Value' startingValue={identifierState.value} setResourceState={setIdentifierState} />
            </p>
            <CodeEntry elementName='use' fieldLabel='Identifier Use' startingValue={identifierState.use} setResourceState={setIdentifierState}
              allowedValues={identifierDotUseValues} dataEntryStyle='dropdown' />
            <CodeableConceptEntry elementName='type' fieldLabel='Type of Identifier'
              startingValue={identifierState.type} setResourceState={setIdentifierState}
              dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
              valueSet={valueSet} debug={debug} startCollapsed={true} />
            <UriEntry elementName='system' fieldLabel='URI for Identification System'
              startingValue={identifierState.system ?
                identifierState.system : dataEntryStyle === "humanAuthor" ?
                  "https://orcid.org/" : ""}
              setResourceState={setIdentifierState} />
            <PeriodEntry elementName='period' fieldLabel='Period (when Identifier is effective)'
              inTableCell={inTableCell}
              startingValue={identifierState.period} setResourceState={setIdentifierState} startCollapsed={true} />
            {!(fromIdentifier && fromReference) && <ReferenceEntry elementName='assigner' fieldLabel='Assigner of Identifier' startingValue={identifierState.assigner} setResourceState={setIdentifierState}
              referencedResourceTypes={identifierDotAssignerResourceTypes} fromIdentifier={true} fromReference={fromReference} startCollapsed={true} />}
          </div>
        </>
      }
    </>
  }
})

const IntegerEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  return <TextField style={{ width: "100%", marginTop: "12px", maxWidth: "260px" }}
    className="inputField" type='number' inputProps={{ step: "any" }} label={fieldLabel} size="small" variant='outlined'
    value={startingValue ?? ""}
    onInput={alertIfInvalidNumberEntry}
    onChange={(e) => {
      if (e.target.value && !isNaN(e.target.value)) {
        if (parseInt(e.target.value) != parseFloat(e.target.value)) {
          alert("Only integers are allowed.");
        }
      }
      if (e.target.value === null) {
        handleChange(elementName, "", setResourceState);
      } else if (isNaN(e.target.value)) {
        handleChange(elementName, e.target.value, setResourceState);
      } else {
        handleChange(elementName, Number(e.target.value), setResourceState);
      }
    }} />
});

const MarkdownEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  return <div>
    <TextField style={{ width: "100%", marginTop: "12px" }} multiline
      className="inputField" type='text' label={fieldLabel} size="small" variant='outlined'
      value={startingValue || ""}
      onChange={(e) => { handleChange(elementName, e.target.value, setResourceState) }} />
    <p>Surround text with ** for <b>BOLD</b> or * for <i>italics</i></p>
    {startingValue && <div style={{ marginLeft: "24px" }}>
      <p><b>{fieldLabel} will display as: </b></p>
      <DisplayFromFHIR markdown={startingValue} />
      <br /></div>}
  </div>
});

const XhtmlEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  return <div>
    <TextField style={{ width: "100%", marginTop: "12px" }} multiline
      className="inputField" type='text' label={fieldLabel} size="small" variant='outlined'
      value={startingValue || ""}
      onChange={(e) => {
        let newValue = e.target.value.replaceAll(" < ", " &lt; ").replaceAll(" > ", " &gt; ").replaceAll(" & ", " &amp; ");
        handleChange(elementName, newValue, setResourceState);
      }} />
    {startingValue && <div style={{ marginLeft: "24px" }}><p>{fieldLabel} will display as: </p>
      <div style={{ marginLeft: "24px" }}>
        <DisplayFromFHIR xhtml={startingValue} /></div><br /></div>}
    <br />
  </div>
});

const NarrativeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  startCollapsed, dataEntryStyle, generatedNarrative }) => {
  let startingNarrative = { "status": "empty", "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" };
  if (startingValue) {
    if (startingValue.extension) { startingNarrative.extension = startingValue.extension; }
    if (startingValue.status) { startingNarrative.status = startingValue.status; }
    if (startingValue.div) { startingNarrative.div = startingValue.div; }
  }
  let startingDivTextValue = startingNarrative.div;
  if (startingDivTextValue.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
    startingDivTextValue = startingDivTextValue.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
    let sDTVLength = startingDivTextValue.length;
    if (startingDivTextValue.substring(sDTVLength - 6, sDTVLength) === "</div>") {
      startingDivTextValue = startingDivTextValue.substring(0, sDTVLength - 6);
    }
  }

  let startingDivValue = { "divText": startingDivTextValue };
  const [narrativeState, setNarrativeState] = useState(startingNarrative);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);
  const [narrativeDivState, setNarrativeDivState] = useState(startingDivValue);
  const [generatedNarrativeState, setGeneratedNarrativeState] = useState(null);
  const checkIfStatusAdditional = () => {
    return narrativeState.status === "additional";
  };
  const updateNarrativeStates = () => {
    let status = generatedNarrative.status;
    let div = generatedNarrative.div;
    setNarrativeState(prevState => { return { ...prevState, "status": status, "div": div }; });
    setGeneratedNarrativeState(true);
    if (div.includes('<div xmlns=\"http://www.w3.org/1999/xhtml\">')) {
      div = div.replace('<div xmlns=\"http://www.w3.org/1999/xhtml\">', '');
      let sDTVLength = div.length;
      if (div.substring(sDTVLength - 6, sDTVLength) === "</div>") {
        div = div.substring(0, sDTVLength - 6);
      }
    }
    setNarrativeDivState({ "divText": div });
  }

  useEffect((() => {
    if (Object.keys(narrativeState).length) {
      let newNarrative = {};
      if (narrativeState.extension) { newNarrative.extension = narrativeState.extension; }
      if (narrativeState.status) { newNarrative.status = narrativeState.status; }
      if (narrativeState.div) { newNarrative.div = narrativeState.div; }
      if (Object.keys(newNarrative).length === 0 ||
        (newNarrative.status === "empty" && !newNarrative.div && !newNarrative.extension)) {
        newNarrative = null;
      }
      handleChange(elementName, newNarrative, setResourceState);
    }
  }), [narrativeState]);

  useEffect((() => {
    if (generatedNarrativeState !== false) {
      setGeneratedNarrativeState(false);
    } else if (startingDivValue.divText !== '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>") {
      if ((narrativeDivState.divText || narrativeDivState.divText === "0") &&
        narrativeDivState.divText !== '[No data]' &&
        narrativeDivState.divText !== '[No data.]') {
        setNarrativeState(prevState => { return { ...prevState, "status": "additional", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + narrativeDivState.divText + "</div>" } });
      } else {
        setNarrativeState(prevState => { return { ...prevState, "status": "empty", "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + (narrativeDivState.divText || "[No data.]") + "</div>" } });
      }
    }
  }), [narrativeDivState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR xhtml={startingNarrative.div} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    if (dataEntryStyle === "editstatuselement") {
      return <div>
        <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
        <div style={{ marginLeft: "24px" }}>
          <CodeEntry elementName='status' fieldLabel='Status' togglable={true}
            allowedValues={['generated', 'extensions', 'additional', 'empty']}
            startingValue={narrativeState.status} setResourceState={setNarrativeState} />
          <XhtmlEntry elementName='divText' fieldLabel='Narrative Summary'
            startingValue={narrativeDivState.divText} setResourceState={setNarrativeDivState} />
        </div>
      </div>
    } else {
      return <div>
        {generatedNarrative && <ButtonWithConfirmModal buttonContent="Generate Natural Language Summary"
          message="This will overwrite the manually edited summary with a generated summary."
          conditionForConfirmModal={checkIfStatusAdditional}
          functionIfConfirmed={updateNarrativeStates}
        />}
        <p style={{ marginBottom: "0px" }}><b>Natural language summary: </b>
          (status: {narrativeState.status === "additional" ? "manually edited" : narrativeState.status})</p>
        <div style={{ marginLeft: "24px" }}>
          <XhtmlEntry elementName='divText' fieldLabel={fieldLabel}
            startingValue={narrativeDivState.divText} setResourceState={setNarrativeDivState} />
        </div>
      </div>
    }
  }
});

const PeriodEntry = memo(({ elementName, fieldLabel, inTableCell, startingValue, setResourceState, startCollapsed }) => {
  if (!startingValue) {
    startingValue = "";
  }

  const [startCalendarOpen, setStartCalendarOpen] = useState(false);
  const [endCalendarOpen, setEndCalendarOpen] = useState(false);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  const CalendarStartModal = () => {
    return <div className="calendarModal"><MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        format="yyyy-MM-dd HH:mm:ss"
        value={startingValue?.start ? parseISO(startingValue.start, "yyyy-MM-dd HH:mm:ss") : null}
        emptyLabel="yyyy-MM-dd HH:mm:ss"
        open={startCalendarOpen}
        onClose={() => setStartCalendarOpen(false)}
        onChange={(e) => {
          let newStart = format(e, "yyyy-MM-dd") + "T" + format(e, "HH:mm:ss");
          let newPeriod = { start: newStart, end: "" };
          if (startingValue.end) { newPeriod.end = startingValue.end; } else { delete newPeriod.end; }
          handleChange(elementName, newPeriod, setResourceState);
        }}
      />
    </MuiPickersUtilsProvider></div>
  }

  const CalendarEndModal = () => {
    return <div className="calendarModal"><MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        format="yyyy-MM-dd HH:mm:ss"
        value={startingValue?.end ? parseISO(startingValue.end, "yyyy-MM-dd HH:mm:ss") : null}
        emptyLabel="yyyy-MM-dd HH:mm:ss"
        open={endCalendarOpen}
        onClose={() => setEndCalendarOpen(false)}
        onChange={(e) => {
          let newEnd = format(e, "yyyy-MM-dd") + "T" + format(e, "HH:mm:ss");
          let newPeriod = { start: "", end: newEnd };
          if (startingValue.start) { newPeriod.start = startingValue.start; } else { delete newPeriod.start; }
          handleChange(elementName, newPeriod, setResourceState);
        }}
      />
    </MuiPickersUtilsProvider></div>
  }

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.Period(startingValue)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <><div className="inlinegrouping">
      <b>{fieldLabel}: </b>
      {inTableCell && <br />}
      <TextField style={{ width: "50%", marginTop: "16px", maxWidth: "300px" }}
        className="inputField" type='text' label="Start Date/Time" size="small" variant='outlined'
        value={startingValue.start || ""}
        onChange={(e) => {
          let newStart = e.target.value;
          let newPeriod = { start: newStart, end: "" };
          if (startingValue.end) { newPeriod.end = startingValue.end; } else { delete newPeriod.end; }
          if (startingValue.end || e.target.value) {
            handleChange(elementName, newPeriod, setResourceState);
          } else {
            handleChange(elementName, {}, setResourceState);
          }
        }} />
      <Button className="calendarButton"
        style={{ marginTop: "18px", marginLeft: "6px", fontSize: "28px", padding: "0px", backgroundColor: "white" }}
        content="📅" onClick={() => { setStartCalendarOpen(true) }} />
      <CalendarStartModal />
      {((startingValue.start && startingValue.end) && startingValue.start > startingValue.end) &&
        <p><b>Start date should NOT be later than end date!!!!!!!!!!!!!!!!!!!!!!!!!!!!</b></p>}
      <TextField style={inTableCell ? { width: "50%", marginTop: "16px", maxWidth: "300px" }
        : { width: "50%", marginLeft: "114px", marginTop: "16px", maxWidth: "300px" }}
        className="inputField" type='text' label="End Date/Time" size="small" variant='outlined'
        value={startingValue?.end || ""}
        onChange={(e) => {
          let newEnd = e.target.value;
          let newPeriod = { start: "", end: newEnd };
          if (startingValue.start) { newPeriod.start = startingValue.start; } else { delete newPeriod.start; }
          if (startingValue.start || e.target.value) {
            handleChange(elementName, newPeriod, setResourceState);
          } else {
            handleChange(elementName, {}, setResourceState);
          }
        }} />
      <Button className="calendarButton"
        style={{ marginTop: "18px", marginLeft: "6px", fontSize: "28px", padding: "0px", backgroundColor: "white" }}
        content="📅" onClick={() => { setEndCalendarOpen(true) }} />
      <CalendarEndModal />
    </div>
    </>
  }
});

const PositiveIntEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  return <TextField style={{ width: "100%", marginTop: "12px", maxWidth: "260px" }}
    className="inputField" type='number' inputProps={{ step: "any" }} label={fieldLabel} size="small" variant='outlined'
    value={startingValue ?? ""}
    onInput={alertIfInvalidNumberEntry}
    onChange={(e) => {
      if (e.target.value && !isNaN(e.target.value)) {
        if ((parseInt(e.target.value) != parseFloat(e.target.value)) || parseInt(e.target.value) <= 0) {
          alert("Only positive integers are allowed.");
        }
      }
      if (e.target.value === null) {
        handleChange(elementName, "", setResourceState);
      } else if (isNaN(e.target.value)) {
        handleChange(elementName, e.target.value, setResourceState);
      } else {
        handleChange(elementName, Number(e.target.value), setResourceState);
      }
    }} />
});

const quantityDotComparatorOptions = [{ "key": "0", "value": "=", "text": "=" }, { "key": "1", "value": "<", "text": "<" }, { "key": "2", "value": "<=", "text": "<=" }, { "key": "3", "value": ">", "text": ">" }, { "key": "4", "value": ">=", "text": ">=" }, { "key": "5", "value": "ad", "text": "ad" }]
const QuantityEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle,
  valueSet, startCollapsed, inTableCell }) => {
  if (!startingValue) {
    startingValue = "";
  }
  if (dataEntryStyle === "Age") {
    valueSet = UCUM.age;
  } else if (dataEntryStyle === "Duration") {
    valueSet = UCUM.duration;
  } else if (valueSet === "UCUM") {
    valueSet = UCUM.common;
  }

  const [quantityPresetState, setQuantityPresetState] = useState({ edited: false });
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.Quantity(startingValue)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <><p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      {dataEntryStyle !== "SimpleQuantity" &&
        <Dropdown className="dropdownWithClearIconUpperRight" name="comparator" placeholder="Comparator"
          closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
          style={{ minWidth: "18%", width: "18%" }}
          options={quantityDotComparatorOptions}
          value={startingValue.comparator || ""}
          onChange={(e, data) => {
            let newQuantity = { comparator: "", value: "", unit: "" };
            newQuantity.comparator = data.value;
            if (startingValue.value) { newQuantity.value = startingValue.value; } else { delete newQuantity.value; }
            if (startingValue.unit) { newQuantity.unit = startingValue.unit; } else { delete newQuantity.unit; }
            if (startingValue.system) { newQuantity.system = startingValue.system; }
            if (startingValue.code) { newQuantity.code = startingValue.code; }
            if (newQuantity.comparator || newQuantity.value || newQuantity.value === 0 || newQuantity.unit || newQuantity.system || newQuantity.code) {
              handleChange(elementName, newQuantity, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }}
        />
      }
      &nbsp;&nbsp;&nbsp;
      <TextField style={{ width: "15%" }} className="inputField" type='number' inputProps={{ step: "any" }} label={'Value'}
        size="small" variant='outlined' value={(startingValue.value || startingValue.value === 0) ? startingValue.value : ''}
        onInput={alertIfInvalidNumberEntry}
        onChange={(e) => {
          let newQuantity = { comparator: "", value: "", unit: "" };
          if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
            newQuantity.value = "";
          } else if (isNaN(e.target.value)) {
            newQuantity.value = e.target.value;
          } else {
            let numberInput = parseFloat(e.target.value);
            if (dataEntryStyle === "Age") {
              if (numberInput < 0) {
                numberInput = -1 * numberInput;
              }
              if (numberInput != parseFloat(e.target.value)) {
                alert("Only positive numbers are allowed. Value changed to: " + numberInput);
              }
            }
            newQuantity.value = numberInput;
          }
          if (startingValue.comparator) { newQuantity.comparator = startingValue.comparator; } else { delete newQuantity.comparator; }
          if (startingValue.unit) { newQuantity.unit = startingValue.unit; } else { delete newQuantity.unit; }
          if (startingValue.system) { newQuantity.system = startingValue.system; }
          if (startingValue.code) { newQuantity.code = startingValue.code; }
          if (newQuantity.comparator || newQuantity.value || newQuantity.value === 0 || newQuantity.unit || newQuantity.system || newQuantity.code) {
            handleChange(elementName, newQuantity, setResourceState);
          } else {
            handleChange(elementName, {}, setResourceState);
          }
        }} />
      &nbsp;&nbsp;&nbsp;
      {inTableCell && <br />}
      {(dataEntryStyle === "Age" || dataEntryStyle === "Duration") &&
        <Dropdown name="timeunit" placeholder="Units of Time"
          closeOnChange selection selectOnBlur={false} autoComplete="off"
          style={{ minWidth: "22%", width: "22%" }}
          options={valueSet.map((term, termIndex) => { return { "key": termIndex, "value": term.code, "text": term.display } })}
          value={startingValue.code || ""}
          onChange={(e, data) => {
            let selectedCode;
            let selectedSystem;
            let selectedDisplay;
            for (const term of valueSet) {
              if (term.code === data.value) {
                selectedCode = term.code;
                selectedSystem = term.system;
                selectedDisplay = term.display;
              }
            }
            let newQuantity = { comparator: "", value: "", unit: selectedDisplay, system: selectedSystem, code: selectedCode };
            if (startingValue.comparator) { newQuantity.comparator = startingValue.comparator; } else { delete newQuantity.comparator; }
            if (startingValue.value || startingValue.value === 0) { newQuantity.value = startingValue.value; } else { delete newQuantity.value; }
            if (newQuantity.comparator || newQuantity.value || newQuantity.value === 0 || newQuantity.unit || newQuantity.system || newQuantity.code) {
              handleChange(elementName, newQuantity, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }}
        />
      }
      {dataEntryStyle !== "Age" && dataEntryStyle !== "Duration" && !valueSet &&
        <TextField style={{ width: "25%" }} className="inputField" type='text' label={'Units'}
          size="small" variant='outlined' value={startingValue.unit || ''}
          onChange={(e) => {
            let newQuantity = { comparator: "", value: "", unit: "" };
            if (startingValue.comparator) { newQuantity.comparator = startingValue.comparator; } else { delete newQuantity.comparator; }
            if (startingValue.value || startingValue.value === 0) { newQuantity.value = startingValue.value; } else { delete newQuantity.value; }
            newQuantity.unit = e.target.value;
            if (startingValue.system) { newQuantity.system = startingValue.system; }
            if (startingValue.code) { newQuantity.code = startingValue.code; }
            if (newQuantity.comparator || newQuantity.value || newQuantity.value === 0 || newQuantity.unit || newQuantity.system || newQuantity.code) {
              handleChange(elementName, newQuantity, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }} />
      }
      {dataEntryStyle !== "Age" && dataEntryStyle !== "Duration" && valueSet && <>
        <Dropdown
          name={"unit-picklist-for-" + elementName}
          placeholder="Select Units" search={true}
          closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
          style={{ minWidth: "25%", width: "25%" }}
          options={valueSet.map((term, termIndex) => { return { "key": termIndex, "value": term.code, "text": term.display } })}
          value={quantityPresetState.edited ? "" : startingValue.code}
          onChange={(e, data) => {
            let selectedCode;
            let selectedSystem;
            let selectedDisplay;
            for (const term of valueSet) {
              if (term.code === data.value) {
                selectedCode = term.code;
                selectedSystem = term.system || "";
                selectedDisplay = term.display || "";
              }
            }
            let newQuantity = { comparator: "", value: "", unit: selectedDisplay, system: selectedSystem, code: selectedCode };
            if (startingValue.comparator) { newQuantity.comparator = startingValue.comparator; } else { delete newQuantity.comparator; }
            if (startingValue.value || startingValue.value === 0) { newQuantity.value = startingValue.value; } else { delete newQuantity.value; }
            if (newQuantity.comparator || newQuantity.value || newQuantity.value === 0 || newQuantity.unit || newQuantity.system || newQuantity.code) {
              handleChange(elementName, newQuantity, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
          }}
        />
        &nbsp;&nbsp;&nbsp;
        <TextField style={{ width: "25%" }} className="inputField" type='text' label={'System'}
          size="small" variant='outlined' value={startingValue.system || ''}
          onChange={(e) => {
            let newQuantity = { comparator: "", value: "", unit: "", system: "", code: "" };
            if (startingValue.comparator) { newQuantity.comparator = startingValue.comparator; } else { delete newQuantity.comparator; }
            if (startingValue.value || startingValue.value === 0) { newQuantity.value = startingValue.value; } else { delete newQuantity.value; }
            newQuantity.system = e.target.value;
            if (startingValue.unit) { newQuantity.unit = startingValue.unit; } else { delete newQuantity.unit; }
            if (startingValue.code) { newQuantity.code = startingValue.code; } else { delete newQuantity.code; }
            if (newQuantity.comparator || newQuantity.value || newQuantity.value === 0 || newQuantity.unit || newQuantity.system || newQuantity.code) {
              handleChange(elementName, newQuantity, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
            setQuantityPresetState({ edited: true });
          }} />
        &nbsp;&nbsp;&nbsp;
        <TextField style={{ width: "25%" }} className="inputField" type='text' label={'Code'}
          size="small" variant='outlined' value={startingValue.code || ''}
          onChange={(e) => {
            let newQuantity = { comparator: "", value: "", unit: "", system: "", code: "" };
            if (startingValue.comparator) { newQuantity.comparator = startingValue.comparator; } else { delete newQuantity.comparator; }
            if (startingValue.value || startingValue.value === 0) { newQuantity.value = startingValue.value; } else { delete newQuantity.value; }
            newQuantity.code = e.target.value;
            if (startingValue.unit) { newQuantity.unit = startingValue.unit; } else { delete newQuantity.unit; }
            if (startingValue.system) { newQuantity.system = startingValue.system; } else { delete newQuantity.system; }
            if (newQuantity.comparator || newQuantity.value || newQuantity.value === 0 || newQuantity.unit || newQuantity.system || newQuantity.code) {
              handleChange(elementName, newQuantity, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
            setQuantityPresetState({ edited: true });
          }} />
        &nbsp;&nbsp;&nbsp;
        <TextField style={{ width: "25%" }} className="inputField" type='text' label={'Unit Display'}
          size="small" variant='outlined' value={startingValue.unit || ''}
          onChange={(e) => {
            let newQuantity = { comparator: "", value: "", unit: "", system: "", code: "" };
            if (startingValue.comparator) { newQuantity.comparator = startingValue.comparator; } else { delete newQuantity.comparator; }
            if (startingValue.value || startingValue.value === 0) { newQuantity.value = startingValue.value; } else { delete newQuantity.value; }
            newQuantity.unit = e.target.value;
            if (startingValue.code) { newQuantity.code = startingValue.code; } else { delete newQuantity.code; }
            if (startingValue.system) { newQuantity.system = startingValue.system; } else { delete newQuantity.system; }
            if (newQuantity.comparator || newQuantity.value || newQuantity.value === 0 || newQuantity.unit || newQuantity.system || newQuantity.code) {
              handleChange(elementName, newQuantity, setResourceState);
            } else {
              handleChange(elementName, {}, setResourceState);
            }
            setQuantityPresetState({ edited: true });
          }} />
      </>
      }
    </>
  }
});

const RangeEntry = memo(({ elementName, fieldLabel, inTableCell, startingValue, setResourceState, dataEntryStyle,
  valueSet, startCollapsed }) => {
  let showStartingValue = true;
  if (!startingValue) {
    startingValue = { low: { value: "", unit: "", system: "", code: "" }, high: { value: "", unit: "", system: "", code: "" } };
    showStartingValue = false;
  }
  if (dataEntryStyle === "Age") {
    valueSet = UCUM.age;
  } else if (dataEntryStyle === "Duration") {
    valueSet = UCUM.duration;
  } else if (valueSet === "UCUM") {
    valueSet = UCUM.common;
  }

  const [rangePresetState, setRangePresetState] = useState({ lowEdited: false, highEdited: false });
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {showStartingValue && getStringFromFHIR.Range(startingValue)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    if (!valueSet || !Array.isArray(valueSet)) {
      return <div><p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
        <TextField style={{ width: "15%" }} className="inputField" type='number' inputProps={{ step: "any" }}
          label={'Low Value'}
          size="small" variant='outlined' value={startingValue.low?.value ?? ''}
          onInput={alertIfInvalidNumberEntry}
          onChange={(e) => {
            let emptyNewRange = true;
            let newValue = e.target.value;
            if (newValue === null) {
              newValue = "";
            }
            if (newValue && !isNaN(newValue)) {
              newValue = Number(newValue);
            }
            let newRange = { low: { value: newValue, unit: "", system: "", code: "" }, high: { value: "", unit: "", system: "", code: "" } };
            if (newRange.low.value || newRange.low.value === 0) { emptyNewRange = false; }
            if (startingValue.low) {
              if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
              if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
              if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
            }
            if (startingValue.high) {
              if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
              if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
              if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
              if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
            } else {
              delete newRange.high;
            }
            if (emptyNewRange) {
              newRange = {};
            }
            handleChange(elementName, newRange, setResourceState);
          }} />
        &nbsp;&nbsp;&nbsp;
        <TextField style={{ width: "15%" }} className="inputField" type='text' label={'Low Unit'}
          size="small" variant='outlined' value={startingValue.low?.unit || ''}
          onChange={(e) => {
            let emptyNewRange = true;
            let newRange = { low: { value: "", unit: e.target.value, system: "", code: "" }, high: { value: "", unit: "", system: "", code: "" } };
            if (newRange.low.unit) { emptyNewRange = false; }
            if (startingValue.low) {
              if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
              if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
              if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
            }
            if (startingValue.high) {
              if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
              if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
              if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
              if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
            } else {
              delete newRange.high;
            }
            if (emptyNewRange) {
              newRange = {};
            }
            handleChange(elementName, newRange, setResourceState);
          }} />
        &nbsp;&nbsp;&nbsp;
        {inTableCell && <br />}
        <TextField style={{ width: "15%" }} className="inputField" type='number' inputProps={{ step: "any" }}
          label={'High Value'}
          size="small" variant='outlined' value={startingValue.high?.value ?? ''}
          onInput={alertIfInvalidNumberEntry}
          onChange={(e) => {
            let emptyNewRange = true;
            let newValue = e.target.value;
            if (newValue === null) {
              newValue = "";
            }
            if (newValue && !isNaN(newValue)) {
              newValue = Number(newValue);
            }
            let newRange = { low: { value: "", unit: "", system: "", code: "" }, high: { value: newValue, unit: "", system: "", code: "" } };
            if (newRange.high.value || newRange.high.value === 0) { emptyNewRange = false; }
            if (startingValue.low) {
              if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
              if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
              if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
              if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
            } else {
              delete startingValue.low;
            }
            if (startingValue.high) {
              if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
              if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
              if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
            }
            if (emptyNewRange) {
              newRange = {};
            }
            handleChange(elementName, newRange, setResourceState);
          }} />
        &nbsp;&nbsp;&nbsp;
        <TextField style={{ width: "15%" }} className="inputField" type='text' label={'High Unit'}
          size="small" variant='outlined' value={startingValue.high?.unit || ''}
          onChange={(e) => {
            let emptyNewRange = true;
            let newRange = { low: { value: "", unit: "", system: "", code: "" }, high: { value: "", unit: e.target.value, system: "", code: "" } };
            if (newRange.high.unit) { emptyNewRange = false; }
            if (startingValue.low) {
              if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
              if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
              if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
              if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
            } else {
              delete startingValue.low;
            }
            if (startingValue.high) {
              if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
              if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
              if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
            }
            if (emptyNewRange) {
              newRange = {};
            }
            handleChange(elementName, newRange, setResourceState);
          }} />
      </div>
    }

    if (dataEntryStyle === "Age" || dataEntryStyle === "Duration") {

      return <div><p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
        <span>
          <TextField style={{ width: "15%" }} className="inputField" type='number' inputProps={{ step: "any" }}
            label={'Low Value'}
            size="small" variant='outlined' value={startingValue.low?.value ?? ''}
            onInput={alertIfInvalidNumberEntry}
            onChange={(e) => {
              let emptyNewRange = true;
              let newValue = e.target.value;
              if (newValue === null) {
                newValue = "";
              }
              if (newValue && !isNaN(newValue)) {
                newValue = Number(newValue);
              }
              let newRange = { low: { value: newValue, unit: "", system: "", code: "" }, high: { value: "", unit: "", system: "", code: "" } };
              if (newRange.low.value || newRange.low.value === 0) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
                if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
                if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
                if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
                if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
                if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
              } else {
                delete newRange.high;
              }
              if (emptyNewRange) {
                newRange = {};
              }
              handleChange(elementName, newRange, setResourceState);
            }} />
          &nbsp;&nbsp;&nbsp;
          <Dropdown name="timeunit" placeholder="Units of Time"
            closeOnChange selection selectOnBlur={false} autoComplete="off"
            style={{ minWidth: "22%", width: "22%" }}
            options={valueSet.map((term, termIndex) => { return { "key": termIndex, "value": term.code, "text": term.display } })}
            value={rangePresetState.lowEdited ? "" : startingValue.low?.code}
            onChange={(e, data) => {
              let selectedCode;
              let selectedSystem;
              let selectedDisplay;
              for (const term of valueSet) {
                if (term.code === data.value) {
                  selectedCode = term.code;
                  selectedSystem = term.system;
                  selectedDisplay = term.display;
                }
              }
              let emptyNewRange = true;
              let newRange = { low: { value: "", unit: selectedDisplay, system: selectedSystem, code: selectedCode }, high: { value: "", unit: "", system: "", code: "" } };
              if (newRange.low.unit || newRange.low.code || newRange.low.system) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
                if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
                if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
                if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
              } else {
                delete newRange.high;
              }
              if (emptyNewRange) {
                newRange = {};
              }
              handleChange(elementName, newRange, setResourceState);
            }}
          />
          &nbsp;&nbsp;&nbsp;
        </span>
        {inTableCell && <br />}
        <span>
          <TextField style={{ width: "15%" }} className="inputField" type='number' inputProps={{ step: "any" }}
            label={'High Value'}
            size="small" variant='outlined' value={startingValue.high?.value ?? ''}
            onInput={alertIfInvalidNumberEntry}
            onChange={(e) => {
              let emptyNewRange = true;
              let newValue = e.target.value;
              if (newValue === null) {
                newValue = "";
              }
              if (newValue && !isNaN(newValue)) {
                newValue = Number(newValue);
              }
              let newRange = { low: { value: "", unit: "", system: "", code: "" }, high: { value: newValue, unit: "", system: "", code: "" } };
              if (newRange.high.value || newRange.high.value === 0) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
                if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
                if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
                if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
              } else {
                delete startingValue.low;
              }
              if (startingValue.high) {
                if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
                if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
                if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
              }
              if (emptyNewRange) {
                newRange = {};
              }
              if (emptyNewRange) {
                newRange = {};
              }
              handleChange(elementName, newRange, setResourceState);
            }} />
          &nbsp;&nbsp;&nbsp;
          <Dropdown name="timeunit" placeholder="Units of Time"
            closeOnChange selection selectOnBlur={false} autoComplete="off"
            style={{ minWidth: "22%", width: "22%" }}
            options={valueSet.map((term, termIndex) => { return { "key": termIndex, "value": term.code, "text": term.display } })}
            value={rangePresetState.highEdited ? "" : startingValue.high?.code}
            onChange={(e, data) => {
              let selectedCode;
              let selectedSystem;
              let selectedDisplay;
              for (const term of valueSet) {
                if (term.code === data.value) {
                  selectedCode = term.code;
                  selectedSystem = term.system;
                  selectedDisplay = term.display;
                }
              }
              let emptyNewRange = true;
              let newRange = { low: { value: "", unit: "", system: "", code: "" }, high: { value: "", unit: selectedDisplay, system: selectedSystem, code: selectedCode } };
              if (newRange.high.unit || newRange.high.system || newRange.high.code) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
                if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
                if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
                if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
              } else {
                delete startingValue.low;
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
              }
              if (emptyNewRange) {
                newRange = {};
              }
              handleChange(elementName, newRange, setResourceState);
            }}
          />
        </span>
      </div>
    } else {
      return <div><p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
        <span>
          <TextField style={{ width: "15%" }} className="inputField" type='number' inputProps={{ step: "any" }}
            label={'Low Value'}
            size="small" variant='outlined' value={startingValue.low?.value ?? ''}
            onInput={alertIfInvalidNumberEntry}
            onChange={(e) => {
              let emptyNewRange = true;
              let newValue = e.target.value;
              if (newValue === null) {
                newValue = "";
              }
              if (newValue && !isNaN(newValue)) {
                newValue = Number(newValue);
              }
              let newRange = { low: { value: newValue, unit: "", system: "", code: "" }, high: { value: "", unit: "", system: "", code: "" } };
              if (newRange.low.value || newRange.low.value === 0) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
                if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
                if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
                if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
                if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
                if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
              } else {
                delete newRange.high;
              }
              if (emptyNewRange) {
                newRange = {};
              }
              handleChange(elementName, newRange, setResourceState);
            }} />
          &nbsp;&nbsp;&nbsp;
          <Dropdown
            name={"low-unit-picklist-for-" + elementName}
            placeholder="Select Units" search={true}
            closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
            style={{ minWidth: "25%", width: "25%" }}
            options={valueSet.map((term, termIndex) => { return { "key": termIndex, "value": term.code, "text": term.display } })}
            value={rangePresetState.lowEdited ? "" : startingValue.low?.code}
            onChange={(e, data) => {
              let selectedCode;
              let selectedSystem;
              let selectedDisplay;
              for (const term of valueSet) {
                if (term.code === data.value) {
                  selectedCode = term.code;
                  selectedSystem = term.system || "";
                  selectedDisplay = term.display || "";
                }
              }
              let emptyNewRange = true;
              let newRange = { low: { value: "", unit: selectedDisplay, system: selectedSystem, code: selectedCode }, high: { value: "", unit: "", system: "", code: "" } };
              if (newRange.low.unit || newRange.low.code || newRange.low.system) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
                if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
                if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
                if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
              } else {
                delete newRange.high;
              }
              if (emptyNewRange) {
                newRange = {};
              }
              handleChange(elementName, newRange, setResourceState);
            }}
          />
          &nbsp;&nbsp;&nbsp;
          <TextField style={{ width: "25%" }} className="inputField" type='text' label={'System'}
            size="small" variant='outlined' value={startingValue.low?.system || ''}
            onChange={(e) => {
              let emptyNewRange = true;
              let newRange = { low: { value: "", unit: "", system: e.target.value, code: "" }, high: { value: "", unit: "", system: "", code: "" } };
              if (newRange.low.system) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
                if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
                if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
                if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
                if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
                if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
              } else {
                delete newRange.high;
              }
              if (emptyNewRange) {
                newRange = {};
              }
              setRangePresetState((prev) => { return { ...prev, lowEdited: true } });
              handleChange(elementName, newRange, setResourceState);
            }} />
          &nbsp;&nbsp;&nbsp;
          <TextField style={{ width: "25%" }} className="inputField" type='text' label={'Code'}
            size="small" variant='outlined' value={startingValue.low?.code || ''}
            onChange={(e) => {
              let emptyNewRange = true;
              let newRange = { low: { value: "", unit: "", system: "", code: e.target.value }, high: { value: "", unit: "", system: "", code: "" } };
              if (newRange.low.code) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
                if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
                if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
                if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
                if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
                if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
              } else {
                delete newRange.high;
              }
              if (emptyNewRange) {
                newRange = {};
              }
              if (emptyNewRange) {
                newRange = {};
              }
              setRangePresetState((prev) => { return { ...prev, lowEdited: true } });
              handleChange(elementName, newRange, setResourceState);
            }} />
          &nbsp;&nbsp;&nbsp;
          <TextField style={{ width: "25%" }} className="inputField" type='text' label={'Unit Display'}
            size="small" variant='outlined' value={startingValue.low?.unit || ''}
            onChange={(e) => {
              let emptyNewRange = true;
              let newRange = { low: { value: "", unit: e.target.value, system: "", code: "" }, high: { value: "", unit: "", system: "", code: "" } };
              if (newRange.low.unit) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
                if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
                if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
                if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
                if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
                if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
              } else {
                delete newRange.high;
              }
              if (emptyNewRange) {
                newRange = {};
              }
              if (emptyNewRange) {
                newRange = {};
              }
              setRangePresetState((prev) => { return { ...prev, lowEdited: true } });
              handleChange(elementName, newRange, setResourceState);
            }} />
        </span>
        <br />
        <span>
          <TextField style={{ width: "15%" }} className="inputField" type='number' inputProps={{ step: "any" }}
            label={'High Value'}
            size="small" variant='outlined' value={startingValue.high?.value ?? ''}
            onInput={alertIfInvalidNumberEntry}
            onChange={(e) => {
              let emptyNewRange = true;
              let newValue = e.target.value;
              if (newValue === null) {
                newValue = "";
              }
              if (newValue && !isNaN(newValue)) {
                newValue = Number(newValue);
              }
              let newRange = { low: { value: "", unit: "", system: "", code: "" }, high: { value: newValue, unit: "", system: "", code: "" } };
              if (newRange.high.value || newRange.high.value === 0) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
                if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
                if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
                if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
              } else {
                delete startingValue.low;
              }
              if (startingValue.high) {
                if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
                if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
                if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
              }
              if (emptyNewRange) {
                newRange = {};
              }
              if (emptyNewRange) {
                newRange = {};
              }
              handleChange(elementName, newRange, setResourceState);
            }} />
          &nbsp;&nbsp;&nbsp;
          <Dropdown
            name={"high-unit-picklist-for-" + elementName}
            placeholder="Select Units" search={true}
            closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
            style={{ minWidth: "25%", width: "25%" }}
            options={valueSet.map((term, termIndex) => { return { "key": termIndex, "value": term.code, "text": term.display } })}
            value={rangePresetState.highEdited ? "" : startingValue.high?.code}
            onChange={(e, data) => {
              let selectedCode;
              let selectedSystem;
              let selectedDisplay;
              for (const term of valueSet) {
                if (term.code === data.value) {
                  selectedCode = term.code;
                  selectedSystem = term.system || "";
                  selectedDisplay = term.display || "";
                }
              }
              let emptyNewRange = true;
              let newRange = { low: { value: "", unit: "", system: "", code: "" }, high: { value: "", unit: selectedDisplay, system: selectedSystem, code: selectedCode } };
              if (newRange.high.unit || newRange.high.system || newRange.high.code) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
                if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
                if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
                if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
              } else {
                delete startingValue.low;
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
              }
              if (emptyNewRange) {
                newRange = {};
              }
              handleChange(elementName, newRange, setResourceState);
            }}
          />
          &nbsp;&nbsp;&nbsp;
          <TextField style={{ width: "25%" }} className="inputField" type='text' label={'System'}
            size="small" variant='outlined' value={startingValue.high?.system || ''}
            onChange={(e) => {
              let emptyNewRange = true;
              let newRange = { low: { value: "", unit: "", system: "", code: "" }, high: { value: "", unit: "", system: e.target.value, code: "" } };
              if (newRange.high.system) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
                if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
                if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
                if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
              } else {
                delete startingValue.low;
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
                if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
                if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
              }
              if (emptyNewRange) {
                newRange = {};
              }
              setRangePresetState((prev) => { return { ...prev, highEdited: true } });
              handleChange(elementName, newRange, setResourceState);
            }} />
          &nbsp;&nbsp;&nbsp;
          <TextField style={{ width: "25%" }} className="inputField" type='text' label={'Code'}
            size="small" variant='outlined' value={startingValue.high?.code || ''}
            onChange={(e) => {
              let emptyNewRange = true;
              let newRange = { low: { value: "", unit: "", system: "", code: "" }, high: { value: "", unit: "", system: "", code: e.target.value } };
              if (newRange.high.code) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
                if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
                if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
                if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
              } else {
                delete startingValue.low;
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
                if (startingValue.high.unit) { newRange.high.unit = startingValue.high.unit; emptyNewRange = false; } else { delete newRange.high.unit; }
                if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
              }
              if (emptyNewRange) {
                newRange = {};
              }
              if (emptyNewRange) {
                newRange = {};
              }
              setRangePresetState((prev) => { return { ...prev, highEdited: true } });
              handleChange(elementName, newRange, setResourceState);
            }} />
          &nbsp;&nbsp;&nbsp;
          <TextField style={{ width: "25%" }} className="inputField" type='text' label={'Unit Display'}
            size="small" variant='outlined' value={startingValue.high?.unit || ''}
            onChange={(e) => {
              let emptyNewRange = true;
              let newRange = { low: { value: "", unit: "", system: "", code: "" }, high: { value: "", unit: e.target.value, system: "", code: "" } };
              if (newRange.high.unit) { emptyNewRange = false; }
              if (startingValue.low) {
                if (startingValue.low.value || startingValue.low.value === 0) { newRange.low.value = startingValue.low.value; emptyNewRange = false; } else { delete newRange.low.value; }
                if (startingValue.low.unit) { newRange.low.unit = startingValue.low.unit; emptyNewRange = false; } else { delete newRange.low.unit; }
                if (startingValue.low.system) { newRange.low.system = startingValue.low.system; emptyNewRange = false; } else { delete newRange.low.system; }
                if (startingValue.low.code) { newRange.low.code = startingValue.low.code; emptyNewRange = false; } else { delete newRange.low.code; }
              } else {
                delete startingValue.low;
              }
              if (startingValue.high) {
                if (startingValue.high.value || startingValue.high.value === 0) { newRange.high.value = startingValue.high.value; emptyNewRange = false; } else { delete newRange.high.value; }
                if (startingValue.high.system) { newRange.high.system = startingValue.high.system; emptyNewRange = false; } else { delete newRange.high.system; }
                if (startingValue.high.code) { newRange.high.code = startingValue.high.code; emptyNewRange = false; } else { delete newRange.high.code; }
              }
              if (emptyNewRange) {
                newRange = {};
              }
              if (emptyNewRange) {
                newRange = {};
              }
              setRangePresetState((prev) => { return { ...prev, highEdited: true } });
              handleChange(elementName, newRange, setResourceState);
            }} />
        </span>
      </div>
    }
  }
});

const ratingDotAuthorResourceTypes = ['Patient', 'Practitioner', 'PractitionerRole', 'Organization', 'Device'];
const RatingEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, allowedValues, valueSet, dataEntryStyle, inTableCell, debug, typeValueSet, typeSpecificValueSets, typeSpecificInterface, startCollapsed }) => {
  //Rating is not a FHIR Datatype. Rating schema is an object with:
  //type 0..1 CodeableConcept
  //classifier 0..* CodeableConcept
  //quantity 0..1 Quantity
  //(when used in Rating Profile of ArtifactAssessment.content -- author 0..1 Reference)
  //(when used in Rating Profile of ArtifactAssessment.content -- freeToShare 0..1 boolean)
  let startingRating = { "informationType": "", "type": "", "classifier": [], "quantity": "", "author": "", "freeToShare": true }
  let startingTypeSpecificValueSet;
  let startingTypeSpecificInterface = typeSpecificInterface;
  if (!startingValue) {
    startingRating = "";
  } else {
    if (startingValue.extension) { startingRating.extension = startingValue.extension; }
    if (startingValue.informationType) { startingRating.informationType = startingValue.informationType; }
    if (startingValue.type) {
      startingRating.type = startingValue.type;
      if (typeSpecificValueSets) {
        try {
          let typeKey = startingValue.type.coding[0].code;
          startingTypeSpecificValueSet = typeSpecificValueSets[typeKey]
        } catch { }
      }
      if (!typeSpecificInterface) {
        try {
          startingTypeSpecificInterface = startingValue.type.coding[0].code;
        } catch { }
      }
    }
    if (startingValue.classifier) { startingRating.classifier = startingValue.classifier; }
    if (startingValue.quantity) { startingRating.quantity = startingValue.quantity; }
    if (startingValue.author) { startingRating.author = startingValue.author; }
    if (typeof startingValue.freeToShare === "boolean") { startingRating.freeToShare = startingValue.freeToShare; }
  }

  const [ratingState, setRatingState] = useState(startingRating);
  const [typeSpecificValueSetState, setTypeSpecificValueSetState] = useState(startingTypeSpecificValueSet);
  const [typeSpecificInterfaceState, setTypeSpecificInterfaceState] = useState(startingTypeSpecificInterface);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(ratingState).length) {
      let newRating = {};
      if (ratingState.extension) { newRating.extension = ratingState.extension; }
      if (ratingState.informationType) { newRating.informationType = ratingState.informationType; }
      if (ratingState.type && Object.keys(ratingState.type).length) {
        newRating.type = ratingState.type;
        if (typeSpecificValueSets) {
          try {
            let typeKey = ratingState.type.coding[0].code;
            setTypeSpecificValueSetState(typeSpecificValueSets[typeKey]);
          } catch { }
        }
        if (!typeSpecificInterface) {
          try {
            setTypeSpecificInterfaceState(ratingState.type.coding[0].code);
          } catch { }
        }
      }
      if (ratingState.classifier && Array.isArray(ratingState.classifier) && ratingState.classifier.length) { newRating.classifier = ratingState.classifier; }
      if (ratingState.quantity && Object.keys(ratingState.quantity).length) { newRating.quantity = ratingState.quantity; }
      if (ratingState.author && Object.keys(ratingState.author).length) { newRating.author = ratingState.author; }
      if (typeof ratingState.freeToShare === "boolean") { newRating.freeToShare = ratingState.freeToShare; }
      if (Object.keys(newRating).length === 0) {
        newRating = null;
      }
      handleChange(elementName, newRating, setResourceState);
    }
  }), [ratingState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR rating={startingRating} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    if (typeSpecificInterfaceState === 'desirability') {
      return <>
        <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
        <div style={{ marginLeft: "24px" }}>
          <CodeableConceptEntry elementName='type' fieldLabel='Type of Rating'
            startingValue={ratingState.type || null} setResourceState={setRatingState}
            dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={typeValueSet} debug={debug} />
          <br />
          <ChooseItemForCodeableConceptArrayEntry elementName='classifier'
            startingValue={ratingState.classifier || null}
            fieldLabel='Classifier Value for Desirability'
            valueSet={typeSpecificValueSetState || valueSet}
            setResourceState={setRatingState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
        </div>
      </>
    } else if (typeSpecificInterfaceState === 'relative-importance') {
      return <>
        <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
        <div style={{ marginLeft: "24px" }}>
          <CodeableConceptEntry elementName='type' fieldLabel='Type of Rating'
            startingValue={ratingState.type || null} setResourceState={setRatingState}
            dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={typeValueSet} debug={debug} />
          <br />
          <p style={{ marginBottom: "0px" }}><b>Relative importance (as percentage):</b></p>
          <TextField style={{ width: "15%" }} className="inputField" type='number'
            inputProps={{ step: "any" }} label={'Relative importance'}
            size="small" variant='outlined'
            value={(ratingState.quantity?.value || ratingState.quantity?.value === 0) ? ratingState.quantity.value : ''}
            onInput={alertIfInvalidNumberEntry}
            onChange={(e) => {
              let newQuantity = { value: "", unit: "%", system: "http://unitsofmeasure.org", code: "%" };
              if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
                newQuantity.value = "";
              } else if (isNaN(e.target.value)) {
                newQuantity.value = e.target.value;
              } else {
                let numberInput = parseFloat(e.target.value);
                if (numberInput < 0) {
                  numberInput = -1 * numberInput;
                }
                if (numberInput != parseFloat(e.target.value)) {
                  alert("Only positive numbers are allowed. Value changed to: " + numberInput);
                }
                newQuantity.value = numberInput;
              }
              handleChange('quantity', newQuantity, setRatingState);
            }} /><span> %</span>
          <br /><br />
          <ReferenceEntry elementName='author' fieldLabel='Rater' startCollapsed={true}
            enableCreation={true}
            startingValue={ratingState.author || null} setResourceState={setRatingState}
            referencedResourceTypes={classificationDotAuthorResourceTypes} />
          <BooleanEntry elementName="freeToShare" fieldLabel='Free to share?'
            startingValue={ratingState.freeToShare ?? true} setResourceState={setRatingState} />
        </div>
      </>
    } if (typeSpecificInterfaceState === 'cost') {
      return <>
        <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
        <div style={{ marginLeft: "24px" }}>
          <CodeableConceptEntry elementName='type' fieldLabel='Type of Rating'
            startingValue={ratingState.type || null} setResourceState={setRatingState}
            dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={typeValueSet} debug={debug} />
          <br />
          <QuantityEntry elementName='quantity' fieldLabel='Cost'
            startingValue={ratingState.quantity || null} setResourceState={setRatingState}
            dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
          <br /><br />
          <ReferenceEntry elementName='author' fieldLabel='Rater' startCollapsed={true}
            enableCreation={true}
            startingValue={ratingState.author || null} setResourceState={setRatingState}
            referencedResourceTypes={classificationDotAuthorResourceTypes} />
          <BooleanEntry elementName="freeToShare" fieldLabel='Free to share?'
            startingValue={ratingState.freeToShare ?? true} setResourceState={setRatingState} />
        </div>
      </>
    } else {
      return <>
        <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
        <div style={{ marginLeft: "24px" }}>
          <CodeableConceptEntry elementName='type' fieldLabel='Type of Rating'
            startingValue={ratingState.type || null} setResourceState={setRatingState}
            dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={typeValueSet} debug={debug} />
          <br />
          <ArrayEntry datatype="CodeableConcept" elementName='classifier' fieldLabel='Classifier Value'
            startingValue={ratingState.classifier || null} setResourceState={setRatingState}
            dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={typeSpecificValueSetState || valueSet} debug={debug} />
          <br />
          <QuantityEntry elementName='quantity' fieldLabel='Rating Value'
            startingValue={ratingState.quantity || null} setResourceState={setRatingState}
            dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} />
          <br /><br />
          <ReferenceEntry elementName='author' fieldLabel='Rater' startCollapsed={true}
            enableCreation={true}
            startingValue={ratingState.author || null} setResourceState={setRatingState}
            referencedResourceTypes={classificationDotAuthorResourceTypes} />
          <BooleanEntry elementName="freeToShare" fieldLabel='Free to share?'
            startingValue={ratingState.freeToShare ?? true} setResourceState={setRatingState} />
        </div>
      </>
    }
  }
});

const RatioEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle,
  numeratorValueSet, denominatorValueSet, startCollapsed, inTableCell }) => {
  let startingRatio = { numerator: "", denominator: "" };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingRatio.extension = startingValue.extension; }
    if (startingValue.numerator) { startingRatio.numerator = startingValue.numerator; }
    if (startingValue.denominator) { startingRatio.denominator = startingValue.denominator; }
  }
  if (numeratorValueSet === "Age") {
    numeratorValueSet = UCUM.age;
  } else if (numeratorValueSet === "Duration") {
    numeratorValueSet = UCUM.duration;
  } else if (numeratorValueSet === "UCUM") {
    numeratorValueSet = UCUM.common;
  }
  if (denominatorValueSet === "Age") {
    denominatorValueSet = UCUM.age;
  } else if (denominatorValueSet === "Duration") {
    denominatorValueSet = UCUM.duration;
  } else if (denominatorValueSet === "UCUM") {
    denominatorValueSet = UCUM.common;
  }

  const [ratioState, setRatioState] = useState(startingRatio);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(ratioState).length) {
      let newRatio = {};
      if (ratioState.extension) { newRatio.extension = ratioState.extension; }
      if (ratioState.numerator && Object.keys(ratioState.numerator).length) {
        newRatio.numerator = ratioState.numerator;
      }
      if (ratioState.denominator && Object.keys(ratioState.denominator).length) {
        newRatio.denominator = ratioState.denominator;
      }
      if (Object.keys(newRatio).length === 0) {
        newRatio = null;
      }
      handleChange(elementName, newRatio, setResourceState);
    }
  }), [ratioState]);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.Ratio(startingRatio)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <QuantityEntry elementName='numerator'
          fieldLabel='Numerator' startingValue={ratioState.numerator} inTableCell={inTableCell}
          numeratorValueSet={numeratorValueSet} setResourceState={setRatioState} />
        <QuantityEntry elementName='denominator'
          fieldLabel='Denominator' startingValue={ratioState.denominator} dataEntryStyle='SimpleQuantity'
          inTableCell={inTableCell}
          denominatorValueSet={denominatorValueSet} setResourceState={setRatioState} />
      </div>
    </>
  }
});

const SelectProfileForNewResource = ({ profileSelectionState, setProfileSelectionState }) => {
  if (!profileSelectionState) {
    console.log("MISSING profileSelectionState")
    return <></>
  } else if (!profileSelectionState.profileChoices) {
    console.log("MISSING profileSelectionState.profileChoices")
    return <></>
  } else if (profileSelectionState.profileChoices.length === 0) {
    console.log("EMPTY profileSelectionState.profileChoices")
    return <></>
  } else {
    const profileDictionary = profileSelectionState.profileDictionary;
    return <div>
      {profileSelectionState.profileChoices.length > 0 && <div style={{ marginLeft: "0px", marginBottom: "8px" }}>
        <br />
        <b>Select a Profile: </b>&nbsp;

        <ToggleButtonGroup
          className="genericToggleButtonGroup medium"
          exclusive
          value={profileSelectionState.profileSelected || ""}
          aria-label='profile-selection'
          name='profile-selection'
        >
          {profileSelectionState.profileChoices.map((profile, profileIndex) => {
            return <ToggleButton key={profileIndex} className="genericToggleButton" color="primary"
              aria-label={profile} value={profile}
              onClick={(e) => {
                let meta;
                let section;
                let content;
                let profileUrl = "";
                let citedArtifactClassificationToAdd;
                let typeValue;
                let categoryToAdd;
                let useContextToAdd;
                let membershipValue;
                let combinationMethodValue;
                let characteristic;
                let codeValue;
                if (e.target.value) {
                  let profileName = e.target.value;
                  if (profileName === profileSelectionState.profileSelected) {
                    meta = { "profile": [profileUrl] };
                    setProfileSelectionState(prevState => {
                      return {
                        ...prevState,
                        'meta': undefined,
                        'citedArtifactClassificationToAdd': undefined,
                        'section': undefined,
                        'content': undefined,
                        'characteristic': undefined,
                        'typeValue': undefined,
                        'categoryToAdd': undefined,
                        'useContextToAdd': undefined,
                        'membershipValue': undefined,
                        'combinationMethodValue': undefined,
                        'codeValue': undefined,
                        'profileSelected': undefined
                      }
                    });
                  } else {
                    if (profileDictionary) {
                      for (const key in profileDictionary) {
                        if (key === profileName) {
                          profileUrl = profileDictionary[key].url;
                          if (profileDictionary[key].citedArtifactClassificationToAdd) {
                            citedArtifactClassificationToAdd = profileDictionary[key].citedArtifactClassificationToAdd;
                          }
                          if (profileDictionary[key].typeValue) {
                            typeValue = profileDictionary[key].typeValue;
                          }
                          if (profileDictionary[key].categoryToAdd) {
                            categoryToAdd = profileDictionary[key].categoryToAdd;
                          }
                          if (profileDictionary[key].useContextToAdd) {
                            useContextToAdd = profileDictionary[key].useContextToAdd;
                          }
                          if (profileDictionary[key].membershipValue) {
                            membershipValue = profileDictionary[key].membershipValue;
                          }
                          if (profileDictionary[key].combinationMethodValue) {
                            combinationMethodValue = profileDictionary[key].combinationMethodValue;
                          }
                          if (profileDictionary[key].codeValue) {
                            codeValue = profileDictionary[key].codeValue;
                          }
                          if (profileDictionary[key].sectionStarter) {
                            section = profileDictionary[key].sectionStarter;
                          }
                          if (profileDictionary[key].contentStarter) {
                            content = profileDictionary[key].contentStarter;
                          }
                          if (profileDictionary[key].characteristicStarter) {
                            characteristic = profileDictionary[key].characteristicStarter;
                          }
                        }
                      }
                    }

                    if (profileUrl) {
                      meta = { "profile": [profileUrl] };
                      setProfileSelectionState(prevState => {
                        return {
                          ...prevState,
                          'meta': meta,
                          'citedArtifactClassificationToAdd': citedArtifactClassificationToAdd,
                          'section': section,
                          'content': content,
                          'characteristic': characteristic,
                          'typeValue': typeValue,
                          'categoryToAdd': categoryToAdd,
                          'useContextToAdd': useContextToAdd,
                          'membershipValue': membershipValue,
                          'combinationMethodValue': combinationMethodValue,
                          'codeValue': codeValue,
                          'profileSelected': profileName
                        }
                      });
                    }
                  }
                }
              }}>{profile}</ToggleButton>
          })}
        </ToggleButtonGroup>
      </div>}
    </div>
  }
}

const createNewReferencedResource = async (title, resourceType, globalContext, profileSelectionState, addElementValues) => {

  let newResource = {
    "resourceType": resourceType,
    "meta": profileSelectionState.meta || {},
    "title": title,
    "status": "active"
  };

  if (profileSelectionState.typeValue) {
    newResource.type = profileSelectionState.typeValue;
  }
  if (profileSelectionState.categoryToAdd) {
    newResource.category = [profileSelectionState.categoryToAdd];
  }
  if (profileSelectionState.useContextToAdd) {
    newResource.useContext = [profileSelectionState.useContextToAdd];
  }
  if (profileSelectionState.membershipValue) {
    newResource.membership = profileSelectionState.membershipValue;
  }
  if (profileSelectionState.combinationMethodValue) {
    newResource.combinationMethod = profileSelectionState.combinationMethodValue;
  }
  if (profileSelectionState.codeValue) {
    newResource.code = profileSelectionState.codeValue;
  }
  if (profileSelectionState.section) {
    newResource.section = profileSelectionState.section;
  } else if (resourceType === "Composition") {
    newResource.section = [];
  }
  if (profileSelectionState.content) {
    newResource.content = profileSelectionState.content;
  }
  if (profileSelectionState.characteristic) {
    newResource.characteristic = profileSelectionState.characteristic;
  }

  if (resourceType === "Composition") {
    newResource.status = "final";
  } else if (resourceType === "CodeSystem") {
    newResource.concept = [];
  } else if (resourceType === "List") {
    newResource.status = "current";
  } else if (resourceType === "Citation" && profileSelectionState.citedArtifactClassificationToAdd) {
    newResource.citedArtifact = { classification: [profileSelectionState.citedArtifactClassificationToAdd] };
  }

  if (addElementValues) {
    for (const key in addElementValues) {
      if (addElementValues[key]) {
        if (key === "relatedArtifact" && resourceType === "Composition") {
          newResource["relatesTo"] = addElementValues[key];
        } else {
          newResource[key] = addElementValues[key];
        }
        if (!title && key === "title") {
          title = newResource.title;
        }
      }
    }
  }

  const body = {
    'functionid': "submitfhirresource",
    'idToken': "",
    'fhirEntry': JSON.stringify(newResource),
    'title': title,
    'status': 'active',
  };
  let response = await submitToFevirServer(globalContext, 5000, body, true, false);
  let resourceFOI;
  if (response.success) {
    resourceFOI = response.formstateid;
  }
  return resourceFOI;
};

const getSearchResultsForReferenceEntry = async (searchQueryString, selectedResourceType, allowedResourceTypes, globalContext) => {
  const body = {
    'functionid': "getsearchresults",
    'idToken': "",
    'contextInfo': { "referenceEntry": true },
    'selectedResourceType': selectedResourceType,
    'allowedResourceTypes': allowedResourceTypes,
    'searchQueryString': searchQueryString
  };
  let response = await submitToFevirServer(globalContext, 5000, body, true, false);
  let searchResults;
  if (response?.success) {
    searchResults = response.searchResults;
  }
  return searchResults;
}

const SelectResourceFromSearchModal = memo(({ selectedResourceType, allowedResourceTypes, globalContext,
  setSelectResourceFromSearchState }) => {
  let startingSelectedResourceTypes = null;
  if (allowedResourceTypes) {
    startingSelectedResourceTypes = allowedResourceTypes;
  }
  if (selectedResourceType) {
    startingSelectedResourceTypes = [selectedResourceType];
  }
  let startingModalState = { "modalOpen": true, "searchQueryString": "", "searchResults": "" };
  const [modalState, setModalState] = useState(startingModalState);
  const [searchResourceTypeLimiterState, setSearchResourceTypeLimiterState] = useState({
    "selectedResourceType": selectedResourceType,
    "selectedResourceTypes": startingSelectedResourceTypes
  });

  const deliverSearchResults = async () => {
    if (modalState.modalOpen && modalState.searchQueryString &&
      modalState.searchQueryCompleted && !modalState.searchResultsDelivered) {
      let searchResults = await getSearchResultsForReferenceEntry(modalState.searchQueryString, selectedResourceType,
        searchResourceTypeLimiterState.selectedResourceTypes, globalContext);
      setModalState(prevState => {
        return { ...prevState, "searchResults": searchResults, "searchResultsDelivered": true }
      })
    }
  };

  useEffect((() => {
    deliverSearchResults();
  }), [modalState])

  const selectTheResource = (data) => {
    setModalState(prevState => { return { ...prevState, modalOpen: false }; });
    setSelectResourceFromSearchState(prevState => { return { ...prevState, "selectedResourceReference": data, modalOpen: false }; });
  }

  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setModalState(prevState => { return { ...prevState, modalOpen: false }; });
        setSelectResourceFromSearchState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />
    {(!selectedResourceType) && <>
      <DataEntry asArray={true} datatype='code' elementName='selectedResourceTypes' fieldLabel='Limit by Resource Type'
        allowedValues={allowedResourceTypes || allFhirResourceTypes} dataEntryStyle='dropdownsearch'
        startingValue={searchResourceTypeLimiterState.selectedResourceTypes || []}
        setResourceState={setSearchResourceTypeLimiterState} />
      <p style={{ paddingTop: "12px" }}>Limit to resource types of: {(searchResourceTypeLimiterState.selectedResourceTypes &&
        searchResourceTypeLimiterState.selectedResourceTypes.length > 0) ?
        searchResourceTypeLimiterState.selectedResourceTypes.join(', ') : "[No limits]"}</p>
      <br />
    </>}
    <h3>Enter search string</h3>
    <TextField style={{ width: "100%", marginTop: "16px" }}
      className="inputField" type='text' label={"Enter search string"} size="small" variant='outlined'
      value={modalState.searchQueryString || ""}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          setModalState(prevState => { return { ...prevState, "searchQueryCompleted": true, "searchResultsDelivered": false } });
        }
      }}
      onChange={(e) => {
        setModalState(prevState => { return { ...prevState, "searchQueryString": e.target.value } })
      }} />
    {modalState.searchResults && <>
      <h3>Select a resource</h3>
      {modalState.searchResults.length > 0
        ?
        <>
          {modalState.searchResults.map((result, resultIndex) => {
            return <p key={resultIndex}>
              <span onClick={() => { selectTheResource(result.data); }} ><b>SELECT</b></span>
              &nbsp;&nbsp;
              <span >{result.display}</span>
              &nbsp;&nbsp;
              <a href={result.onClick} target='_blank' rel='noopener noreferrer' >View</a>
            </p>
          })}
        </>
        :
        <>No results found.</>}
    </>
    }
    <br />
  </div>;

  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={modalState?.modalOpen}
      centered={false}
      content={modalContent}
    />
  )
})

const SelectMultipleResourcesFromSearchModal = memo(({ allowedResourceTypes,
  selectMultipleResourcesFromSearchState, setSelectMultipleResourcesFromSearchState }) => {

  let startingModalState = { "modalOpen": true, "searchQueryString": "", "searchResults": "" }
  const [modalState, setModalState] = useState(startingModalState);
  const globalContext = useContext(FevirContext);
  const [searchResourceTypeLimiterState, setSearchResourceTypeLimiterState] = useState({
    "selectedResourceTypes": allowedResourceTypes
  });


  const deliverSearchResults = async () => {
    if (modalState.modalOpen && modalState.searchQueryString &&
      modalState.searchQueryCompleted && !modalState.searchResultsDelivered) {
      let searchResults = await getSearchResultsForReferenceEntry(modalState.searchQueryString, null,
        searchResourceTypeLimiterState.selectedResourceTypes, globalContext);
      setModalState(prevState => {
        return { ...prevState, "searchResults": searchResults, "searchResultsDelivered": true }
      })
    }
  };

  useEffect((() => {
    deliverSearchResults();
  }), [modalState])

  const selectTheResource = (data) => {
    let newArray = selectMultipleResourcesFromSearchState.selectedResourcesArray;
    newArray.push(data);
    setSelectMultipleResourcesFromSearchState(prevState => {
      return { ...prevState, "selectedResourcesArray": newArray };
    });
  }

  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setModalState(prevState => { return { ...prevState, modalOpen: false }; });
        setSelectMultipleResourcesFromSearchState(prevState => { return { ...prevState, modalOpen: false, applySelection: true }; });
      }} />
    <DataEntry asArray={true} datatype='code' elementName='selectedResourceTypes' fieldLabel='Limit by Resource Type'
      allowedValues={allowedResourceTypes || allFhirResourceTypes} dataEntryStyle='dropdownsearch'
      startingValue={searchResourceTypeLimiterState.selectedResourceTypes || []}
      setResourceState={setSearchResourceTypeLimiterState} />
    <p style={{ paddingTop: "12px" }}>Limit to resource types of: {(searchResourceTypeLimiterState.selectedResourceTypes &&
      searchResourceTypeLimiterState.selectedResourceTypes.length > 0) ?
      searchResourceTypeLimiterState.selectedResourceTypes.join(', ') : "[No limits]"}</p>
    <br />
    <h3>Enter search string</h3>
    <TextField style={{ width: "100%", marginTop: "16px" }}
      className="inputField" type='text' label={"Enter search string"} size="small" variant='outlined'
      value={modalState.searchQueryString || ""}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          setModalState(prevState => { return { ...prevState, "searchQueryCompleted": true, "searchResultsDelivered": false } });
        }
      }}
      onChange={(e) => {
        setModalState(prevState => { return { ...prevState, "searchQueryString": e.target.value } })
      }} />
    {modalState.searchResults && <>
      <h3>Select resources</h3>
      <h4>{selectMultipleResourcesFromSearchState.selectedResourcesArray.length} resources selected. Click the x (top right corner), then click Update on the left panel, to complete.</h4>
      {modalState.searchResults.length > 0
        ?
        <>
          {modalState.searchResults.map((result, resultIndex) => {
            return <p key={resultIndex}>
              <span onClick={() => {
                selectTheResource(result.data);
              }} ><b>SELECT</b></span>
              &nbsp;&nbsp;
              <span >{result.display}</span>
              &nbsp;&nbsp;
              <a href={result.onClick} target='_blank' rel='noopener noreferrer' >View</a>
            </p>
          })}
        </>
        :
        <>No results found.</>}
    </>
    }
    <br />
  </div>;

  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={modalState?.modalOpen}
      centered={false}
      content={modalContent}
    />
  )
})

const ReferencedEvidenceResourceContentEntry = ({ referencedResourceJsonState, setReferencedResourceJsonState }) => {

  return <div style={{ marginLeft: "24px" }}>
    <DataEntry datatype='string' elementName='title'
      fieldLabel={'Evidence Title (Label for the Evidence Resource)'}
      startingValue={referencedResourceJsonState?.title || referencedResourceJsonState?.name || ""}
      required={(referencedResourceJsonState?.description || referencedResourceJsonState?.statistic)}
      setResourceState={setReferencedResourceJsonState} />
    <DataEntry datatype='markdown' elementName='description' fieldLabel={'Evidence Description (Summary)'}
      startingValue={referencedResourceJsonState?.description || ""} setResourceState={setReferencedResourceJsonState} />
    <DataEntry asArray={true} datatype='EvidenceStatistic' elementName='statistic'
      fieldLabel='Statistic' startingValue={referencedResourceJsonState?.statistic}
      setResourceState={setReferencedResourceJsonState} />
  </div>
}

const ReferencedEvidenceVariableResourceContentEntry = ({ referencedResourceJsonState, setReferencedResourceJsonState }) => {
  const evidenceVariableDefinitionConceptSystemChoices = [
    { 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' },
    { 'uri': 'http://hl7.org/fhir/sid/icd-10', 'display': 'ICD-10' },
    { 'uri': 'http://loinc.org', 'display': 'LOINC' },
    { 'uri': 'http://www.nlm.nih.gov/research/umls/rxnorm', 'display': 'RxNorm' },
    { 'uri': 'https://www.whocc.no/atc_ddd_index/', 'display': 'ATC' }
  ];
  const evidenceVariableDotHandlingValues = ['continuous', 'dichotomous', 'ordinal', 'polychotomous'];

  return <div style={{ marginLeft: "24px" }}>
    <DataEntry datatype='string' elementName='title'
      fieldLabel={'Title (Human-friendly Label for Variable Name)'}
      startingValue={referencedResourceJsonState?.title || ""}
      required={(referencedResourceJsonState?.name || referencedResourceJsonState?.description || referencedResourceJsonState?.definition || referencedResourceJsonState?.handling || referencedResourceJsonState?.category)}
      setResourceState={setReferencedResourceJsonState} />
    <DataEntry datatype='string' elementName='name'
      fieldLabel={'Name (Machine Label for Variable Name)'}
      startingValue={referencedResourceJsonState?.name || ""}
      setResourceState={setReferencedResourceJsonState} />
    <DataEntry datatype='markdown' elementName='description'
      fieldLabel={'Description'}
      startingValue={referencedResourceJsonState?.description || ""} setResourceState={setReferencedResourceJsonState} />
    <DataEntry datatype='CodeableReference' elementName='definition'
      fieldLabel='Variable Definition'
      referencedResourceTypes={['Group']}
      enableCreation={true} systemChoices={evidenceVariableDefinitionConceptSystemChoices} systemChoicesOpen={true}
      startingValue={referencedResourceJsonState?.definition} setResourceState={setReferencedResourceJsonState} />
    <DataEntry datatype='code' elementName='handling' fieldLabel='Handling'
      startingValue={referencedResourceJsonState?.handling} setResourceState={setReferencedResourceJsonState}
      allowedValues={evidenceVariableDotHandlingValues} />
    <DataEntry asArray={true} datatype='EvidenceVariableCategory' elementName='category'
      fieldLabel='Category' startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
      startingValue={referencedResourceJsonState?.category} setResourceState={setReferencedResourceJsonState} />
  </div>
}

const ReferencedGroupResourceContentEntry = ({ referencedResourceJsonState, setReferencedResourceJsonState }) => {

  return <div>
    <DataEntry datatype='string' elementName='title'
      fieldLabel={'Title (Label for the Group)'}
      startingValue={referencedResourceJsonState?.title || referencedResourceJsonState?.name || ""}
      required={(referencedResourceJsonState?.description || referencedResourceJsonState?.quantity || referencedResourceJsonState?.combinationMethod || referencedResourceJsonState?.combinationThreshold || referencedResourceJsonState?.characteristic)}
      setResourceState={setReferencedResourceJsonState} />
    <DataEntry datatype='markdown' elementName='description' fieldLabel={'Group Description'}
      startingValue={referencedResourceJsonState?.description || ""} setResourceState={setReferencedResourceJsonState} />
    <DataEntry datatype='unsignedInt' elementName='quantity' fieldLabel={'Number of Members'}
      startingValue={referencedResourceJsonState?.quantity || ""} setResourceState={setReferencedResourceJsonState} />
    <DataEntry datatype='code' elementName='combinationMethod'
      fieldLabel={'Criteria Combination Method'}
      allowedValues={['all-of', 'any-of', 'at-least', 'at-most', 'except-subset']}
      startingValue={referencedResourceJsonState?.combinationMethod || ""} setResourceState={setReferencedResourceJsonState} />
    {(referencedResourceJsonState?.combinationMethod === "at-least" ||
      referencedResourceJsonState?.combinationMethod === "at-most" ||
      referencedResourceJsonState?.combinationThreshold) &&
      <DataEntry datatype='positiveInt' elementName='combinationThreshold' fieldLabel='Number of characteristics'
        startingValue={referencedResourceJsonState.combinationThreshold || ""} setResourceState={setReferencedResourceJsonState} />}
    <br />
    <ManageInclusionExclusionEnhancedCharacteristicTables editMode={true}
      fhirJson={referencedResourceJsonState} setResourceState={setReferencedResourceJsonState} />
    <br />
  </div>
}

const ReferencedResearchStudyResourceContentEntry = ({ referencedResourceJsonState, setReferencedResourceJsonState }) => {

  const researchStudyDotPhaseValueSet = [
    { system: "http://hl7.org/fhir/research-study-phase", code: "n-a", display: "N/A" },
    { system: "http://hl7.org/fhir/research-study-phase", code: "early-phase-1", display: "Early Phase 1" },
    { system: "http://hl7.org/fhir/research-study-phase", code: "phase-1", display: "Phase 1" },
    { system: "http://hl7.org/fhir/research-study-phase", code: "phase-1-phase-2", display: "Phase 1/Phase 2" },
    { system: "http://hl7.org/fhir/research-study-phase", code: "phase-2", display: "Phase 2" },
    { system: "http://hl7.org/fhir/research-study-phase", code: "phase-2-phase-3", display: "Phase 2/Phase 3" },
    { system: "http://hl7.org/fhir/research-study-phase", code: "phase-3", display: "Phase 3" },
    { system: "http://hl7.org/fhir/research-study-phase", code: "phase-4", display: "Phase 4" }
  ];

  return <div style={{ marginLeft: "24px" }}>
    <DataEntry datatype='string' elementName='title' fieldLabel={'Study Title'}
      startingValue={referencedResourceJsonState?.title || referencedResourceJsonState?.name || ""}
      required={(referencedResourceJsonState?.descriptionSummary || referencedResourceJsonState?.description || referencedResourceJsonState?.phase || referencedResourceJsonState?.studyDesign || referencedResourceJsonState?.relatedArtifact || referencedResourceJsonState?.note)}
      setResourceState={setReferencedResourceJsonState} />
    <DataEntry datatype='markdown' elementName='descriptionSummary' fieldLabel={'Brief Study Description'}
      startingValue={referencedResourceJsonState?.descriptionSummary || ""} setResourceState={setReferencedResourceJsonState} />
    <DataEntry datatype='markdown' elementName='description' fieldLabel={'Long Study Description'}
      startingValue={referencedResourceJsonState?.description || ""} setResourceState={setReferencedResourceJsonState} />
    <DataEntry datatype='CodeableConcept' elementName='phase' fieldLabel='Phase'
      valueSet={researchStudyDotPhaseValueSet}
      startingValue={referencedResourceJsonState?.phase} setResourceState={setReferencedResourceJsonState} />
    <DataEntry asArray={true} datatype='CodeableConcept'
      elementName='studyDesign' fieldLabel='Study Design'
      valueSet={SEVCO.studyDesign}
      startingValue={referencedResourceJsonState?.studyDesign} setResourceState={setReferencedResourceJsonState} />
    <DataEntry asArray={true} datatype='RelatedArtifact'
      elementName='relatedArtifact' fieldLabel='Related Item'
      startingValue={referencedResourceJsonState?.relatedArtifact} setResourceState={setReferencedResourceJsonState} />
    <DataEntry asArray={true} datatype='Annotation'
      elementName='note' fieldLabel='Note'
      startingValue={referencedResourceJsonState?.note} setResourceState={setReferencedResourceJsonState} />
  </div>
}

const ReferencedResourceContentEntry = memo(({ globalContext, referencedResourceJsonState,
  setReferencedResourceJsonState, fullResourceState, setSourceJsonState }) => {

  let resourceType = referencedResourceJsonState.resourceType;

  const [saveJsonChangesState, setSaveJsonChangesState] = useState(false);
  const [changesAvailableToSaveState, setChangesAvailableToSaveState] = useState(false);
  const [firstRenderJsonState, setFirstRenderJsonState] = useState(true);
  const [startingValueState, setStartingValueState] = useState(JSON.stringify(referencedResourceJsonState));

  useEffect(() => {
    if (changesAvailableToSaveState) {
      globalContext.setUnsavedChangesInReferencedResourceState({
        "unsavedChanges": true, "modalHeader": "Unsaved Changes",
        "message": "You have unsaved changes to a Resource referenced by this Resource. Do you want to save those changes?",
        "runFunction": saveReferencedResourceJsonChanges
      });
    } else {
      globalContext.setUnsavedChangesInReferencedResourceState({
        "unsavedChanges": false, "modalHeader": "",
        "message": "", "runFunction": null
      });
    }
  }, [changesAvailableToSaveState])


  useEffect(() => {
    if (firstRenderJsonState) {
      setFirstRenderJsonState(false);
    } else if (!changesAvailableToSaveState && startingValueState !== JSON.stringify(referencedResourceJsonState)) {
      setChangesAvailableToSaveState(true);
    }
  }, [referencedResourceJsonState]);

  const saveReferencedResourceJsonChanges = async () => {
    setSaveJsonChangesState(true);
    if (referencedResourceJsonState?.id) {
      let workingJson = JSON.parse(JSON.stringify(referencedResourceJsonState));
      let body = {
        'functionid': 'updatefhirresource',
        'idToken': '',
        'fhirEntry': JSON.stringify(workingJson, null, 2),
        'resourcetype': resourceType,
        'resourceid': workingJson.id,
        'title': workingJson.title || workingJson.name,
        'status': "active",
        'bypasswarnings': true
      };
      let response = await submitToFevirServer(globalContext, 9000, body, false, false);
      if (!response?.success) {
        if (response.warningMessage) {
          alert(response.warningMessage);
        } else {
          alert("The Resource updating did not occur. You might not have editing rights to the referenced Resource.")
        }
      }
      setChangesAvailableToSaveState(false);
    }
    setSaveJsonChangesState(false);
    setChangesAvailableToSaveState(false);
    setStartingValueState(JSON.stringify(referencedResourceJsonState));
    console.log("saving referenced Resource JSON changes");
    loadSourceJsonFunction(fullResourceState, globalContext, setSourceJsonState);
  }

  if (resourceType === "Group" || resourceType === "EvidenceVariable" ||
    resourceType === "Evidence" || resourceType === "ResearchStudy") {
    return <div>
      <h4>Change the content for this {resourceType} Resource.</h4>
      <div style={{ marginLeft: "24px", padding: "6px", outline: "2px solid #444444", borderRadius: "8px", backgroundColor: "#FFFFB0" }}>
        <Button className="formButton" positive={changesAvailableToSaveState ? true : false}
          style={{ color: changesAvailableToSaveState ? "#FFFFFF" : "#000000" }}
          content={"Save changes to " + resourceType + " Resource"}
          onClick={saveReferencedResourceJsonChanges}
          disabled={(saveJsonChangesState || !changesAvailableToSaveState || (!referencedResourceJsonState || (!referencedResourceJsonState.title && !referencedResourceJsonState.name)))} />
        {resourceType === "Group" &&
          <ReferencedGroupResourceContentEntry referencedResourceJsonState={referencedResourceJsonState}
            setReferencedResourceJsonState={setReferencedResourceJsonState} />}
        {resourceType === "EvidenceVariable" &&
          <ReferencedEvidenceVariableResourceContentEntry referencedResourceJsonState={referencedResourceJsonState}
            setReferencedResourceJsonState={setReferencedResourceJsonState} />}
        {resourceType === "Evidence" &&
          <ReferencedEvidenceResourceContentEntry referencedResourceJsonState={referencedResourceJsonState}
            setReferencedResourceJsonState={setReferencedResourceJsonState} />}
        {resourceType === "ResearchStudy" &&
          <ReferencedResearchStudyResourceContentEntry referencedResourceJsonState={referencedResourceJsonState}
            setReferencedResourceJsonState={setReferencedResourceJsonState} />}
        <Button className="formButton" positive={changesAvailableToSaveState ? true : false}
          style={{ color: changesAvailableToSaveState ? "#FFFFFF" : "#000000" }}
          content={"Save changes to " + resourceType + " Resource"}
          onClick={saveReferencedResourceJsonChanges}
          disabled={((saveJsonChangesState || !changesAvailableToSaveState) || (!referencedResourceJsonState || (!referencedResourceJsonState.title && !referencedResourceJsonState.name)))} />
      </div>
      <br />
    </div>
  } else {
    return <p>THIS WILL BE FORM FOR EDITING RESOURCE CONTENT</p>
  }
})

const allFhirResourceTypes = ["Account", "ActivityDefinition", "ActorDefinition", "AdministrableProductDefinition",
  "AdverseEvent", "AllergyIntolerance", "Appointment", "AppointmentResponse", "ArtifactAssessment", "AuditEvent",
  "Basic", "Binary", "BiologicallyDerivedProduct", "BiologicallyDerivedProductDispense", "BodyStructure", "Bundle",
  "CapabilityStatement", "CarePlan", "CareTeam", "ChargeItem", "ChargeItemDefinition", "Citation", "Claim",
  "ClaimResponse", "ClinicalImpression", "ClinicalUseDefinition", "CodeSystem", "Communication", "CommunicationRequest",
  "CompartmentDefinition", "Composition", "ConceptMap", "Condition", "ConditionDefinition", "Consent", "Contract",
  "Coverage", "CoverageEligibilityRequest", "CoverageEligibilityResponse", "DetectedIssue", "Device",
  "DeviceAssociation", "DeviceDefinition", "DeviceDispense", "DeviceMetric", "DeviceRequest", "DeviceUsage",
  "DiagnosticReport", "DocumentReference", "Encounter", "EncounterHistory", "Endpoint", "EnrollmentRequest",
  "EnrollmentResponse", "EpisodeOfCare", "EventDefinition", "Evidence", "EvidenceReport", "EvidenceVariable",
  "ExampleScenario", "ExplanationOfBenefit", "FamilyMemberHistory", "Flag", "FormularyItem", "GenomicStudy",
  "Goal", "GraphDefinition", "Group", "GuidanceResponse", "HealthcareService", "ImagingSelection", "ImagingStudy",
  "Immunization", "ImmunizationEvaluation", "ImmunizationRecommendation", "ImplementationGuide", "Ingredient",
  "InsurancePlan", "InsuranceProduct", "InventoryItem", "InventoryReport", "Invoice", "Library", "Linkage", "List",
  "Location", "ManufacturedItemDefinition", "Measure", "MeasureReport", "Medication", "MedicationAdministration",
  "MedicationDispense", "MedicationKnowledge", "MedicationRequest", "MedicationStatement", "MedicinalProductDefinition",
  "MessageDefinition", "MessageHeader", "MolecularSequence", "NamingSystem", "NutritionIntake", "NutritionOrder",
  "NutritionProduct", "Observation", "ObservationDefinition", "OperationDefinition", "OperationOutcome",
  "Organization", "OrganizationAffiliation", "PackagedProductDefinition", "Parameters", "Patient", "PaymentNotice",
  "PaymentReconciliation", "Permission", "Person", "PlanDefinition", "Practitioner", "PractitionerRole", "Procedure",
  "Provenance", "Questionnaire", "QuestionnaireResponse", "RegulatedAuthorization", "RelatedPerson", "RequestOrchestration",
  "Requirements", "ResearchStudy", "ResearchSubject", "RiskAssessment", "Schedule", "SearchParameter", "ServiceRequest",
  "Slot", "Specimen", "SpecimenDefinition", "StructureDefinition", "StructureMap", "Subscription", "SubscriptionStatus",
  "SubscriptionTopic", "Substance", "SubstanceDefinition", "SubstanceNucleicAcid", "SubstancePolymer", "SubstanceProtein",
  "SubstanceReferenceInformation", "SubstanceSourceMaterial", "SupplyDelivery", "SupplyRequest", "Task", "TerminologyCapabilities",
  "TestPlan", "TestReport", "TestScript", "Transport", "ValueSet", "VerificationResult", "VisionPrescription"];
const ReferenceEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  enableCreation, referencedResourceTypes, fromIdentifier, fromReference, startCollapsed,
  startingResourceType, setProfile, selectProfile, selectAnyProfile, addElementValues,
  inTableCell, fullResourceState,
  setSourceJsonState, dataEntryStyle }) => {

  let startingReference = { "reference": "", "type": "", "identifier": "", "display": "" };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingReference.extension = startingValue.extension; }
    if (startingValue.reference) { startingReference.reference = startingValue.reference; }
    if (startingValue.type) { startingReference.type = startingValue.type; }
    if (startingValue.identifier) { startingReference.identifier = startingValue.identifier; }
    if (startingValue.display) { startingReference.display = startingValue.display; }
    if (!startingReference.display && addElementValues) {
      startingReference.display = addElementValues.title || addElementValues.name || "";
    }
  }

  let startingProfileSelectionState = { "profileChoices": [] };
  if (startingResourceType && (setProfile || selectAnyProfile || selectProfile)) {
    const profileDictionary = ProfilesByResourceType[startingResourceType];
    const profileNames = Object.keys(profileDictionary);
    if (profileDictionary) {
      if (setProfile) {
        if (profileNames.includes(setProfile)) {
          let meta;
          let section;
          let content;
          let profileUrl = "";
          let citedArtifactClassificationToAdd;
          let typeValue;
          let categoryToAdd;
          let useContextToAdd;
          let membershipValue;
          let combinationMethodValue;
          let characteristic;
          let codeValue;
          if (profileDictionary[setProfile]) {
            profileUrl = profileDictionary[setProfile].url;
            if (profileDictionary[setProfile].citedArtifactClassificationToAdd) {
              citedArtifactClassificationToAdd = profileDictionary[setProfile].citedArtifactClassificationToAdd;
            }
            if (profileDictionary[setProfile].typeValue) {
              typeValue = profileDictionary[setProfile].typeValue;
            }
            if (profileDictionary[setProfile].categoryToAdd) {
              categoryToAdd = profileDictionary[setProfile].categoryToAdd;
            }
            if (profileDictionary[setProfile].useContextToAdd) {
              useContextToAdd = profileDictionary[setProfile].useContextToAdd;
            }
            if (profileDictionary[setProfile].membershipValue) {
              membershipValue = profileDictionary[setProfile].membershipValue;
            }
            if (profileDictionary[setProfile].combinationMethodValue) {
              combinationMethodValue = profileDictionary[setProfile].combinationMethodValue;
            }
            if (profileDictionary[setProfile].codeValue) {
              codeValue = profileDictionary[setProfile].codeValue;
            }
            if (profileDictionary[setProfile].sectionStarter) {
              section = profileDictionary[setProfile].sectionStarter;
            }
            if (profileDictionary[setProfile].contentStarter) {
              content = profileDictionary[setProfile].contentStarter;
            }
            if (profileDictionary[setProfile].characteristicStarter) {
              characteristic = profileDictionary[setProfile].characteristicStarter;
            }
          }
          if (profileUrl) {
            meta = { "profile": [profileUrl] };
            startingProfileSelectionState = {
              "profileChoices": [setProfile], "profileDictionary": profileDictionary,
              'meta': meta,
              'citedArtifactClassificationToAdd': citedArtifactClassificationToAdd,
              'section': section,
              'content': content,
              'characteristic': characteristic,
              'typeValue': typeValue,
              'categoryToAdd': categoryToAdd,
              'useContextToAdd': useContextToAdd,
              'membershipValue': membershipValue,
              'combinationMethodValue': combinationMethodValue,
              'codeValue': codeValue,
              "profileSelected": setProfile
            };
          }
        }
      } else {
        let profileChoices = [];
        if (selectAnyProfile) {
          profileChoices = profileNames;
        }
        if (selectProfile) {
          for (const item of selectProfile) {
            if (profileNames.includes(item)) {
              profileChoices.push(item);
            }
          }
        }
        if (profileChoices.length === 0) {
          console.log('profileChoices.length = 0')
        } else if (profileChoices.length === 1) {
          startingProfileSelectionState = {
            "profileChoices": profileChoices, "profileDictionary": profileDictionary,
            "profileSelected": profileChoices[0]
          };
        } else if (profileChoices.length > 1) {
          startingProfileSelectionState = { "profileChoices": profileChoices, "profileDictionary": profileDictionary };
        }
      }
    }
  }
  if (dataEntryStyle === "humanAuthor" && !startingReference.type) {
    startingReference.type = "Practitioner";
  }

  const [referenceState, setReferenceState] = useState(startingReference);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed)
  const [selectResourceFromSearchState, setSelectResourceFromSearchState] = useState({});
  const [newResourceFoiState, setNewResourceFoiState] = useState("");
  const [referencedResourceJsonState, setReferencedResourceJsonState] = useState("");
  const [profileSelectionState, setProfileSelectionState] = useState(startingProfileSelectionState);
  const [editResourceContentState, setEditResourceContentState] = useState(false);

  const globalContext = useContext(FevirContext);

  const getReferencedResourceJson = async (referencedFoi) => {
    let resourceDictionary = await getResourceDictionaryFromFoiList(globalContext, [referencedFoi]);
    let referencedResourceJson = resourceDictionary?.[referencedFoi];
    if (referencedResourceJson) {
      setReferencedResourceJsonState(referencedResourceJson);
    }
  }

  useEffect((() => {
    if (setProfile && startingResourceType) {
      if (!referenceState.type) {
        setReferenceState(prevState => { return { ...prevState, 'type': startingResourceType } });
      }
    }
  }), []);

  useEffect((() => {
    if (referenceState.reference?.split("/").length === 2) {
      let presumedFoi = referenceState.reference.split("/")[1];
      if (!isNaN(presumedFoi)) {
        getReferencedResourceJson(presumedFoi);
      }
    }
  }), [referenceState.reference]);

  useEffect((() => {
    if (referenceState.type) {
      let profileChoices;
      if (selectAnyProfile || selectProfile) {
        const profileDictionary = ProfilesByResourceType[referenceState.type];
        if (profileDictionary) {
          profileChoices = [];
          const profileNames = Object.keys(profileDictionary);
          if (selectAnyProfile) {
            profileChoices = profileNames;
          }
          if (selectProfile) {
            for (const item of selectProfile) {
              if (profileNames.includes(item)) {
                profileChoices.push(item);
              }
            }
          }
          if (profileChoices.length === 0) {
            profileChoices = null;
            console.log('profileChoices.length = 0')
          } else if (profileChoices.length === 1) {
            setProfileSelectionState({
              "profileChoices": profileChoices, "profileDictionary": profileDictionary,
              "profileSelected": profileChoices[0]
            });
          } else if (profileChoices.length > 1) {
            setProfileSelectionState({ "profileChoices": profileChoices, "profileDictionary": profileDictionary });
          }
        }
      }
    }
  }), [referenceState.type]);

  useEffect((() => {
    if (selectResourceFromSearchState.selectedResourceReference) {
      setReferenceState(selectResourceFromSearchState.selectedResourceReference);
    }
  }), [selectResourceFromSearchState.selectedResourceReference])

  useEffect((() => {
    if (Object.keys(referenceState).length) {
      let newReference = {};
      if (referenceState.extension) { newReference.extension = referenceState.extension; }
      if (referenceState.reference) { newReference.reference = referenceState.reference; }
      if (referenceState.type) { newReference.type = referenceState.type; }
      if (referenceState.identifier && Object.keys(referenceState.identifier).length) {
        newReference.identifier = referenceState.identifier;
      }
      if (referenceState.display) {
        newReference.display = referenceState.display;
      } else if (addElementValues) {
        newReference.display = addElementValues.title || addElementValues.name || "";
      }
      if (Object.keys(newReference).length === 0) {
        newReference = null;
      }
      handleChange(elementName, newReference, setResourceState);
    }
  }), [referenceState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR reference={startingReference} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    if (enableCreation) {
      if (dataEntryStyle === "humanAuthor" && (!referenceState.type ||
        referenceState.type === "Practitioner" || referenceState.type === "Organization")) {
        return <>
          <StringEntry elementName='display' fieldLabel={fieldLabel + ' Name'}
            startingValue={referenceState.display || addElementValues?.title}
            setResourceState={setReferenceState} />
          <p style={{ margin: "0px" }}>
            <Checkbox
              checked={referenceState.type === "Organization"}
              onChange={(e) => {
                if (e.target.checked) {
                  setReferenceState(prevState => { return { ...prevState, "type": "Organization" } });
                } else {
                  setReferenceState(prevState => { return { ...prevState, "type": "Practitioner" } });
                }
              }}
              color="primary"
              style={{ paddingLeft: "0px" }}
            />Not a person?
          </p>
          {(referenceState.reference || referenceState.identifier) ?
            <>
              {referencedResourceJsonState &&
                <>
                  <Button className="formButton" style={{ color: "#000000" }}
                    content={`Edit Resource Content`} disabled={editResourceContentState}
                    onClick={() => {
                      setEditResourceContentState(true);
                    }} />
                  &nbsp;&nbsp;&nbsp;
                  {referencedResourceJsonState.id && <span>
                    <a href={'/' + referencedResourceJsonState.id}
                      target='_blank' rel='noopener noreferrer' >Edit this Resource in new window</a>
                  </span>}
                  {editResourceContentState &&
                    <ReferencedResourceContentEntry globalContext={globalContext}
                      referencedResourceJsonState={referencedResourceJsonState}
                      fullResourceState={fullResourceState}
                      setSourceJsonState={setSourceJsonState}
                      setReferencedResourceJsonState={setReferencedResourceJsonState} />}
                  <br />
                </>}
              <Button className="formButton" style={{ color: "#000000" }}
                content={`Replace with new ${referenceState.type} Resource`}
                onClick={async () => {
                  let resourceFOI = await createNewReferencedResource(referenceState.display,
                    referenceState.type, globalContext, profileSelectionState, addElementValues);
                  setNewResourceFoiState(resourceFOI);
                  setReferenceState(prevState => {
                    let reference = `${referenceState.type}/${resourceFOI}`;
                    return {
                      ...prevState,
                      reference: reference
                    };
                  });
                }} />
              &nbsp;&nbsp;&nbsp;
              {(newResourceFoiState && !referencedResourceJsonState) && <span>
                <a href={'/' + newResourceFoiState}
                  target='_blank' rel='noopener noreferrer' >Edit this new Resource in new window</a>
              </span>}
              <br />
            </>
            :
            <>
              {referenceState.display && <>
                <Button className="formButton" style={{ color: "#000000" }}
                  content={`Create this ${referenceState.type} Resource`}
                  onClick={async () => {
                    let resourceFOI = await createNewReferencedResource(referenceState.display,
                      referenceState.type, globalContext, profileSelectionState, addElementValues);
                    setNewResourceFoiState(resourceFOI);
                    setReferenceState(prevState => {
                      let reference = `${referenceState.type}/${resourceFOI}`;
                      return {
                        ...prevState,
                        reference: reference
                      };
                    });
                  }} />
                <br />
              </>}
            </>}
          {referenceState.display && <>
            <StringEntry elementName='reference'
              fieldLabel='Referenced resource absolute or relative URI'
              startingValue={referenceState.reference} setResourceState={setReferenceState} />
            {(!(fromIdentifier && fromReference) && !inTableCell) &&
              <IdentifierEntry elementName='identifier'
                dataEntryStyle={dataEntryStyle}
                fieldLabel='Referenced Resource Identifier' startCollapsed={true}
                startingValue={referenceState.identifier} setResourceState={setReferenceState}
                fromIdentifier={fromIdentifier} fromReference={true} />}
          </>}
          <br />
          <Button className="formButton" style={{ color: "#000000" }}
            content="Search Resources to Select One"
            onClick={() => { setSelectResourceFromSearchState({ "modalOpen": true }); }} />
          {selectResourceFromSearchState.modalOpen &&
            <SelectResourceFromSearchModal selectedResourceType={referenceState.type}
              allowedResourceTypes={referencedResourceTypes} globalContext={globalContext}
              setSelectResourceFromSearchState={setSelectResourceFromSearchState} />}
        </>
      } else {
        return <>
          <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
          <div style={{ marginLeft: "24px" }}>
            <Button className="formButton" style={{ color: "#000000" }}
              content="Search Resources to Select One"
              onClick={() => { setSelectResourceFromSearchState({ "modalOpen": true }); }} />
            <StringEntry elementName='display' fieldLabel={'Title of ' + fieldLabel + ' Resource'}
              startingValue={referenceState.display || addElementValues?.title} setResourceState={setReferenceState} />
            {profileSelectionState.showProfileSelector &&
              <SelectProfileForNewResource profileSelectionState={profileSelectionState}
                setProfileSelectionState={setProfileSelectionState} />}
            {(referenceState.reference || referenceState.identifier) ?
              <>
                {referencedResourceJsonState &&
                  <>
                    <Button className="formButton" style={{ color: "#000000" }}
                      content={`Edit Resource Content`} disabled={editResourceContentState}
                      onClick={() => {
                        setEditResourceContentState(true);
                      }} />
                    &nbsp;&nbsp;&nbsp;
                    {referencedResourceJsonState.id && <span>
                      <a href={'/' + referencedResourceJsonState.id}
                        target='_blank' rel='noopener noreferrer' >Edit this Resource in new window</a>
                    </span>}
                    {editResourceContentState &&
                      <ReferencedResourceContentEntry globalContext={globalContext}
                        referencedResourceJsonState={referencedResourceJsonState}
                        fullResourceState={fullResourceState}
                        setSourceJsonState={setSourceJsonState}
                        setReferencedResourceJsonState={setReferencedResourceJsonState} />}
                    <br />
                  </>}
                {((profileSelectionState.profileChoices?.length || selectAnyProfile || selectProfile) &&
                  !profileSelectionState.profileSelected && !setProfile) ?
                  <Button className="formButton" style={{ color: "#000000" }}
                    content={`Select Profile to replace with new ${startingResourceType || referenceState.type} Resource`}
                    onClick={() => {
                      setProfileSelectionState(prevState => {
                        return {
                          ...prevState,
                          showProfileSelector: true
                        };
                      });
                    }} />
                  :
                  <Button className="formButton" style={{ color: "#000000" }}
                    content={`Replace with new ${startingResourceType || referenceState.type || "[MUST SELECT A TYPE FIRST]"} Resource`}
                    onClick={async () => {
                      let resourceFOI = await createNewReferencedResource(referenceState.display,
                        startingResourceType || referenceState.type, globalContext, profileSelectionState, addElementValues);
                      setNewResourceFoiState(resourceFOI);
                      setReferenceState(prevState => {
                        let reference = `${startingResourceType || referenceState.type}/${resourceFOI}`;
                        return {
                          ...prevState,
                          reference: reference,
                          type: startingResourceType || referenceState.type
                        };
                      });
                    }} />
                }
                &nbsp;&nbsp;&nbsp;
                {(newResourceFoiState && !referencedResourceJsonState) && <span>
                  <a href={'/' + newResourceFoiState}
                    target='_blank' rel='noopener noreferrer' >Edit this new Resource in new window</a>
                </span>}
                <br /></>
              :
              <>
                {((profileSelectionState.profileChoices?.length || selectAnyProfile || selectProfile) &&
                  !profileSelectionState.profileSelected && !setProfile) ?
                  <Button className="formButton" style={{ color: "#000000" }}
                    content={`Select Profile to create this new ${startingResourceType || referenceState.type} Resource`}
                    onClick={() => {
                      setProfileSelectionState(prevState => {
                        return {
                          ...prevState,
                          showProfileSelector: true
                        };
                      });
                    }} />
                  :
                  <Button className="formButton" style={{ color: "#000000" }}
                    content={`Create this ${startingResourceType || referenceState.type || "[MUST SELECT A TYPE FIRST]"} Resource`}
                    onClick={async () => {
                      let resourceFOI = await createNewReferencedResource(referenceState.display,
                        startingResourceType || referenceState.type, globalContext, profileSelectionState, addElementValues);
                      setNewResourceFoiState(resourceFOI);
                      setReferenceState(prevState => {
                        let reference = `${startingResourceType || referenceState.type}/${resourceFOI}`;
                        return {
                          ...prevState,
                          reference: reference,
                          type: startingResourceType || referenceState.type
                        };
                      });
                    }} />
                }
                <br /></>}
            <StringEntry elementName='reference' fieldLabel='Referenced resource absolute or relative URI' startingValue={referenceState.reference} setResourceState={setReferenceState} />
            {referencedResourceTypes ?
              <CodeEntry elementName='type' fieldLabel='Resource Type' startingValue={referenceState.type} setResourceState={setReferenceState}
                allowedValues={referencedResourceTypes} />
              :
              <CodeEntry elementName='type' fieldLabel='Resource Type' startingValue={referenceState.type}
                allowedValues={allFhirResourceTypes} dataEntryStyle="dropdownsearch"
                setResourceState={setReferenceState} />
            }
            {(!(fromIdentifier && fromReference) && !inTableCell) && <IdentifierEntry elementName='identifier'
              fieldLabel='Referenced Resource Identifier' startCollapsed={true}
              startingValue={referenceState.identifier} setResourceState={setReferenceState}
              fromIdentifier={fromIdentifier} fromReference={true} />}
            {selectResourceFromSearchState.modalOpen &&
              <SelectResourceFromSearchModal selectedResourceType={referenceState.type}
                allowedResourceTypes={referencedResourceTypes} globalContext={globalContext}
                setSelectResourceFromSearchState={setSelectResourceFromSearchState} />}
          </div>
        </>
      }
    } else {
      return <>
        <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
        <div style={{ marginLeft: "24px" }}>
          <StringEntry elementName='display' fieldLabel={'Display of ' + fieldLabel}
            startingValue={referenceState.display || addElementValues?.title} setResourceState={setReferenceState} />
          <StringEntry elementName='reference' fieldLabel='Referenced resource absolute or relative URI' startingValue={referenceState.reference} setResourceState={setReferenceState} />
          {referencedResourceTypes ?
            <CodeEntry elementName='type' fieldLabel='Resource Type' startingValue={referenceState.type} setResourceState={setReferenceState}
              allowedValues={referencedResourceTypes} />
            :
            <StringEntry elementName='type' fieldLabel='Resource Type' startingValue={referenceState.type} setResourceState={setReferenceState} />
          }
          {!(fromIdentifier && fromReference) && <IdentifierEntry elementName='identifier'
            fieldLabel='Referenced Resource Identifier' startCollapsed={true}
            startingValue={referenceState.identifier} setResourceState={setReferenceState}
            fromIdentifier={fromIdentifier} fromReference={true} />}
          <br />
          <Button className="formButton" style={{ color: "#000000" }}
            content="Search Resources to Select One"
            onClick={() => { setSelectResourceFromSearchState({ "modalOpen": true }); }} />
          {selectResourceFromSearchState.modalOpen &&
            <SelectResourceFromSearchModal selectedResourceType={referenceState.type}
              allowedResourceTypes={referencedResourceTypes} globalContext={globalContext}
              setSelectResourceFromSearchState={setSelectResourceFromSearchState} />}
        </div>
      </>
    }
  }
})

const RelativeTimeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, valueSet,
  dataEntryStyle, inTableCell, referencedResourceTypes, startCollapsed }) => {
  let startingRelativeTime = {
    "contextReference": "", "contextDefinition": "", "contextPath": "", "contextCode": "",
    "offsetDuration": "", "offsetRange": "", "text": ""
  };
  let startingRelativeTimeOffsetDatatype = 'none';
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingRelativeTime.extension = startingValue.extension; }
    if (startingValue.contextReference) { startingRelativeTime.contextReference = startingValue.contextReference; }
    if (startingValue.contextDefinition) { startingRelativeTime.contextDefinition = startingValue.contextDefinition; }
    if (startingValue.contextPath) { startingRelativeTime.contextPath = startingValue.contextPath; }
    if (startingValue.contextCode) { startingRelativeTime.contextCode = startingValue.contextCode; }
    if (startingValue.offsetDuration) {
      startingRelativeTime.offsetDuration = startingValue.offsetDuration;
      startingRelativeTimeOffsetDatatype = "Duration";
    }
    if (startingValue.offsetRange) {
      startingRelativeTime.offsetRange = startingValue.offsetRange;
      startingRelativeTimeOffsetDatatype = "Range";
    }
    if (startingValue.text) { startingRelativeTime.text = startingValue.text; }
  }

  const [relativeTimeState, setRelativeTimeState] = useState(startingRelativeTime);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);
  const [relativeTimeOffsetDatatypeState, setRelativeTimeOffsetDatatypeState] = useState(startingRelativeTimeOffsetDatatype);

  const relativeTimeOffsetAllowedDatatypes = ['Duration', 'Range'];

  useEffect((() => {
    if (Object.keys(relativeTimeState).length) {
      let newRelativeTime = {};
      if (relativeTimeState.extension) { newRelativeTime.extension = relativeTimeState.extension; }
      if (relativeTimeState.contextReference) { newRelativeTime.contextReference = relativeTimeState.contextReference; }
      if (relativeTimeState.contextDefinition) { newRelativeTime.contextDefinition = relativeTimeState.contextDefinition; }
      if (relativeTimeState.contextPath) { newRelativeTime.contextPath = relativeTimeState.contextPath; }
      if (relativeTimeState.contextCode) { newRelativeTime.contextCode = relativeTimeState.contextCode; }
      if (relativeTimeOffsetDatatypeState === "Duration" && relativeTimeState.offsetDuration) { newRelativeTime.offsetDuration = relativeTimeState.offsetDuration; }
      if (relativeTimeOffsetDatatypeState === "Range" && relativeTimeState.offsetRange) { newRelativeTime.offsetRange = relativeTimeState.offsetRange; }
      if (relativeTimeState.text) { newRelativeTime.text = relativeTimeState.text; }
      if (Object.keys(newRelativeTime).length === 0) {
        newRelativeTime = null;
      }
      handleChange(elementName, newRelativeTime, setResourceState);
    }
  }), [relativeTimeState, relativeTimeOffsetDatatypeState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.RelativeTime(startingRelativeTime)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <p style={{ marginBottom: "0px" }}><b>Text Summary: </b> (brief description for display instead of structured data)</p>
        <StringEntry elementName='text' setResourceState={setRelativeTimeState}
          fieldLabel={'Display of ' + fieldLabel} startingValue={relativeTimeState.text} />
        <br />
        <p style={{ marginBottom: "0px" }}><b>The Event or Context (Reference Point in Time):</b></p>
        <CodeableConceptEntry elementName='contextCode' fieldLabel='Event (as CodeableConcept)'
          valueSet={valueSet} startCollapsed={startCollapsed}
          startingValue={relativeTimeState.contextCode} setResourceState={setRelativeTimeState} />
        <ReferenceEntry elementName='contextReference' setResourceState={setRelativeTimeState}
          startCollapsed={startCollapsed} enableCreation={true}
          fieldLabel={'Event (as Resource Reference)'} startingValue={relativeTimeState.contextReference} />
        <UriEntry elementName='contextDefinition' setResourceState={setRelativeTimeState}
          fieldLabel={'Event (as Canonical URI instead of Resource Reference)'}
          startingValue={relativeTimeState.contextDefinition} />
        <StringEntry elementName='contextPath' fieldLabel='Path (within Resource Reference)'
          startingValue={relativeTimeState.contextPath} setResourceState={setRelativeTimeState} />
        <p style={{ marginBottom: "0px" }}><b>Offset:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='offset[x]' allowedDatatypes={relativeTimeOffsetAllowedDatatypes}
            datatypeState={relativeTimeOffsetDatatypeState} setDatatypeState={setRelativeTimeOffsetDatatypeState} inTableCell={inTableCell} />
          {relativeTimeOffsetDatatypeState === "Duration" &&
            <QuantityEntry elementName='offsetDuration' fieldLabel="Offset Duration"
              startingValue={relativeTimeState.offsetDuration} setResourceState={setRelativeTimeState}
              dataEntryStyle={"Duration"} inTableCell={inTableCell} startCollapsed={startCollapsed} />}
          {relativeTimeOffsetDatatypeState === "Range" &&
            <RangeEntry elementName='offsetRange' setResourceState={setRelativeTimeState}
              startCollapsed={startCollapsed} inTableCell={inTableCell} dataEntryStyle={"Duration"}
              fieldLabel="Offset Range" startingValue={relativeTimeState.offsetRange} />
          }
        </div>
      </div>
    </>
  }
})

const relatedArtifactDotPublicationStatusValues = ['draft', 'active', 'retired', 'unknown'];
const RelatedArtifactEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, allowedTypeValues, valueSet,
  dataEntryStyle, referencedResourceTypes, startCollapsed,
  fullResourceState, setSourceJsonState }) => {
  let startingRelatedArtifact = {
    "type": "", "classifier": [], "label": "", "display": "", "citation": "",
    "document": "", "resource": "", "resourceReference": "", "publicationStatus": "", "publicationDate": ""
  };
  let startingRelatedArtifactDatatype = 'none';
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingRelatedArtifact.extension = startingValue.extension; }
    if (startingValue.type) { startingRelatedArtifact.type = startingValue.type; }
    if (startingValue.classifier) { startingRelatedArtifact.classifier = startingValue.classifier; }
    if (startingValue.label) { startingRelatedArtifact.label = startingValue.label; }
    if (startingValue.display) { startingRelatedArtifact.display = startingValue.display; }
    if (startingValue.citation) { startingRelatedArtifact.citation = startingValue.citation; }
    if (startingValue.document) {
      startingRelatedArtifact.document = startingValue.document;
      startingRelatedArtifactDatatype = 'Attachment';
    }
    if (startingValue.resource) {
      startingRelatedArtifact.resource = startingValue.resource;
      startingRelatedArtifactDatatype = 'uri';
    }
    if (startingValue.resourceReference) {
      startingRelatedArtifact.resourceReference = startingValue.resourceReference;
      startingRelatedArtifactDatatype = 'Reference';
    }
    if (startingValue.publicationStatus) { startingRelatedArtifact.publicationStatus = startingValue.publicationStatus; }
    if (startingValue.publicationDate) { startingRelatedArtifact.publicationDate = startingValue.publicationDate; }
  }
  if (allowedTypeValues === "FHIR") {
    allowedTypeValues = ['documentation', 'justification', 'predecessor', 'successor', 'derived-from', 'depends-on', 'composed-of', 'part-of', 'amends', 'amended-with', 'appends', 'appended-with', 'cites', 'cited-by', 'comments-on', 'comment-in', 'contains', 'contained-in', 'corrects', 'correction-in', 'replaces', 'replaced-with', 'retracts', 'retracted-by', 'signs', 'similar-to', 'supports', 'supported-with', 'transforms', 'transformed-into', 'transformed-with', 'documents', 'specification-of', 'created-with', 'cite-as']
  }

  const [relatedArtifactState, setRelatedArtifactState] = useState(startingRelatedArtifact);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);
  const [relatedArtifactDatatypeState, setRelatedArtifactDatatypeState] = useState(startingRelatedArtifactDatatype);
  const [editPublicationStatusState, setEditPublicationStatusState] = useState(false);

  const referenceAllowedDatatypes = ['Attachment', 'uri', 'Reference'];
  const RelatedArtifactDatatypeSelector = () => {
    return <div>
      <p style={{ marginBottom: "0px" }}><b>Datatype for the referenced item:</b></p>
      <FormControl>
        <FormLabel id="radiodatatypeselector-label"
          style={{ color: "#000000", paddingBottom: "6px" }}>Choose one way to define the referenced item. Use Reference for FHIR Resources on the FEvIR Platform. <i>(If you Update with a different datatype, you will lose previous reference data.)</i></FormLabel>
        <RadioGroup aria-label="radiodatatypeselector" name={"radiodatatypeselector-reference"}
          value={relatedArtifactDatatypeState} onChange={(e) => { setRelatedArtifactDatatypeState(e.target.value); }}>
          {referenceAllowedDatatypes.map((allowedDatatype, allowedDatatypeIndex) => {
            return <FormControlLabel
              key={allowedDatatypeIndex}
              value={allowedDatatype}
              control={<Radio color="primary" style={{ paddingTop: "4px", paddingBottom: "4px" }} />}
              name="radio-button-control"
              color="default"
              inputprops={{ 'aria-label': allowedDatatype }}
              label={<span style={{ textAlign: 'center' }}><b>{allowedDatatype}</b></span>}
              style={{ paddingLeft: "16px" }}
            />
          })}
        </RadioGroup>
      </FormControl></div>
  };

  const EditPublicationStatus = () => {
    return <span className={"unselectable"} style={{ cursor: "pointer" }}
      onClick={() => { setEditPublicationStatusState(true) }}>
      {(relatedArtifactState.publicationStatus || relatedArtifactState.publicationDate) ? <>✎ Edit Publication Status/Date</> : <>➕ Add Publication Status/Date</>}
    </span>
  }

  useEffect((() => {
    if (Object.keys(relatedArtifactState).length) {
      let newRelatedArtifact = {};
      if (relatedArtifactState.extension) { newRelatedArtifact.extension = relatedArtifactState.extension; }
      if (relatedArtifactState.type) { newRelatedArtifact.type = relatedArtifactState.type; }
      if (relatedArtifactState.classifier !== null && relatedArtifactState.classifier !== undefined && relatedArtifactState.classifier.length !== 0) {
        newRelatedArtifact.classifier = relatedArtifactState.classifier;
      }
      if (relatedArtifactState.label) { newRelatedArtifact.label = relatedArtifactState.label; }
      if (relatedArtifactState.display) { newRelatedArtifact.display = relatedArtifactState.display; }
      if (relatedArtifactState.citation) { newRelatedArtifact.citation = relatedArtifactState.citation; }
      if (relatedArtifactDatatypeState === "Attachment" && relatedArtifactState.document && Object.keys(relatedArtifactState.document).length) {
        newRelatedArtifact.document = relatedArtifactState.document;
      }
      if (relatedArtifactDatatypeState === "uri" && relatedArtifactState.resource) { newRelatedArtifact.resource = relatedArtifactState.resource; }
      if (relatedArtifactDatatypeState === "Reference" && relatedArtifactState.resourceReference && Object.keys(relatedArtifactState.resourceReference).length) {
        newRelatedArtifact.resourceReference = relatedArtifactState.resourceReference;
      }
      if (relatedArtifactState.publicationStatus) { newRelatedArtifact.publicationStatus = relatedArtifactState.publicationStatus; }
      if (relatedArtifactState.publicationDate) { newRelatedArtifact.publicationDate = relatedArtifactState.publicationDate; }
      if (Object.keys(newRelatedArtifact).length === 0) {
        newRelatedArtifact = null;
      }
      handleChange(elementName, newRelatedArtifact, setResourceState);
    }
  }), [relatedArtifactState, relatedArtifactDatatypeState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR relatedArtifact={startingRelatedArtifact} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        {allowedTypeValues ?
          <CodeEntry elementName='type' fieldLabel='Relationship Type' startingValue={relatedArtifactState.type} setResourceState={setRelatedArtifactState}
            allowedValues={allowedTypeValues} dataEntryStyle='dropdownsearch' />
          :
          <StringEntry elementName='type' fieldLabel='Relationship Type' startingValue={relatedArtifactState.type} setResourceState={setRelatedArtifactState} />
        }
        <p style={{ marginBottom: "0px" }}><b>The Referenced Item:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <RelatedArtifactDatatypeSelector />
          {relatedArtifactDatatypeState === "Attachment" &&
            <AttachmentEntry elementName='document' setResourceState={setRelatedArtifactState}
              startCollapsed={relatedArtifactState.document}
              fieldLabel={'Attachment for ' + fieldLabel} startingValue={relatedArtifactState.document} />}
          {relatedArtifactDatatypeState === "uri" &&
            <UriEntry elementName='resource' setResourceState={setRelatedArtifactState}
              fieldLabel={'Canonical URI for ' + fieldLabel} startingValue={relatedArtifactState.resource} />}
          {relatedArtifactDatatypeState === "Reference" &&
            <ReferenceEntry elementName='resourceReference' setResourceState={setRelatedArtifactState}
              startCollapsed={relatedArtifactState.resourceReference} enableCreation={true}
              fieldLabel={'Resource Reference for ' + fieldLabel}
              startingValue={relatedArtifactState.resourceReference}
              fullResourceState={fullResourceState}
              setSourceJsonState={setSourceJsonState} />
          }
        </div>
        {(relatedArtifactState.classifier === undefined || relatedArtifactState.classifier === null || Array.isArray(relatedArtifactState.classifier)) &&
          <ArrayEntry datatype='CodeableConcept' elementName='classifier' fieldLabel='Target Classifier'
            valueSet={valueSet} startingValue={relatedArtifactState.classifier} setResourceState={setRelatedArtifactState} />}
        <br /> <br />
        <span><b>Label: </b> (e.g. 1, 2, 3)</span>
        <StringEntry elementName='label' setResourceState={setRelatedArtifactState}
          fieldLabel={'Label for ' + fieldLabel} startingValue={relatedArtifactState.label} />
        <br /> <br />
        <span><b>Display: </b> (brief description)</span>
        <StringEntry elementName='display' setResourceState={setRelatedArtifactState}
          fieldLabel={'Display of ' + fieldLabel} startingValue={relatedArtifactState.display} />
        <br /> <br />
        <span><b>Citation: </b> (fuller detail)</span>
        <MarkdownEntry elementName='citation' setResourceState={setRelatedArtifactState}
          fieldLabel={'Citation for ' + fieldLabel} startingValue={relatedArtifactState.citation} />
        <br />
        {!editPublicationStatusState && <EditPublicationStatus />}
        {editPublicationStatusState && <>
          <CodeEntry elementName='publicationStatus' setResourceState={setRelatedArtifactState}
            fieldLabel={'Publication Status for ' + fieldLabel} startingValue={relatedArtifactState.publicationStatus}
            allowedValues={relatedArtifactDotPublicationStatusValues} />
          <DateEntry elementName='publicationDate' setResourceState={setRelatedArtifactState}
            fieldLabel={'Publication Date for ' + fieldLabel} startingValue={relatedArtifactState.publicationDate} width={"300px"} />
        </>}
      </div>
    </>
  }
})

const StringEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, debug, marginTop, required }) => {
  return <TextField error={required && !startingValue} style={{ width: "100%", marginTop: marginTop || "12px" }} multiline
    className="inputField" type='text' label={fieldLabel} size="small" variant='outlined'
    value={startingValue || ""}
    onChange={(e) => { handleChange(elementName, e.target.value, setResourceState) }} />
});

const TimeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  const oldTime = (startingValue) => {
    const startingTime = new Date();
    let startingTimeSplit = startingValue.split(":");
    startingTime.setHours(startingTimeSplit[0], startingTimeSplit[1], startingTimeSplit[2]);
    return startingTime
  }
  return <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardTimePicker
      ampm={true}
      format="HH:mm:ss"
      variant="inline"
      label={fieldLabel}
      emptyLabel="HH:mm:ss"
      value={startingValue ? oldTime(startingValue) : null}
      onChange={(e) => {
        let hours = e.getHours();
        if (hours < 10) {
          hours = "0" + hours;
        }
        let minutes = e.getMinutes();
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        let seconds = e.getSeconds();
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        let newTime = hours + ":" + minutes + ":" + seconds;
        handleChange(elementName, newTime, setResourceState);
      }}
    />
  </MuiPickersUtilsProvider>
})

const TimingEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle, debug, startCollapsed }) => {
  let startingTiming = { 'event': [], 'repeat': "", 'code': "" };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingTiming.extension = startingValue.extension; }
    if (startingValue.event) { startingTiming.event = startingValue.event; }
    if (startingValue.repeat) { startingTiming.repeat = startingValue.repeat; }
    if (startingValue.code) { startingTiming.code = startingValue.code; }
  }

  const [timingState, setTimingState] = useState(startingTiming);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);
  const timingDotCodeValueSet = [
    { system: "http://terminology.hl7.org/CodeSystem/timing-abbreviation", code: "C", display: "Continuous (frequency)" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "BID", display: "BID" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "TID", display: "TID" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "QID", display: "QID" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "AM", display: "AM" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "PM", display: "PM" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "QD", display: "QD" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "QOD", display: "QOD" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "Q1H", display: "every hour" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "Q2H", display: "every 2 hours" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "Q3H", display: "every 3 hours" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "Q4H", display: "Q4H" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "Q6H", display: "Q6H" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "Q8H", display: "every 8 hours" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "BED", display: "at bedtime" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "WK", display: "weekly" },
    { system: "https://terminology.hl7.org/4.0.0/CodeSystem-v3-GTSAbbreviation.html", code: "MO", display: "monthly" }
  ]

  useEffect((() => {
    if (Object.keys(timingState).length) {
      let newTiming = {};
      if (timingState.extension) { newTiming.extension = timingState.extension; }
      if (timingState.event && Array.isArray(timingState.event) && timingState.event.length) {
        newTiming.event = timingState.event;
      }
      if (timingState.repeat && Object.keys(timingState.repeat).length) {
        newTiming.repeat = timingState.repeat;
      }
      if (timingState.code && Object.keys(timingState.code).length) {
        newTiming.code = timingState.code;
      }
      if (Object.keys(newTiming).length === 0) {
        newTiming = null;
      }
      handleChange(elementName, newTiming, setResourceState);
    }
  }), [timingState]);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.Timing(startingTiming)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        {(timingState.event === undefined || Array.isArray(timingState.event) || timingState.event === null) &&
          <ArrayEntry datatype='dateTime' elementName='event' fieldLabel='Date/Time'
            startingValue={timingState.event} setResourceState={setTimingState} />}
        <TimingRepeatEntry elementName='repeat' fieldLabel='Repeat'
          startingValue={timingState.repeat} setResourceState={setTimingState} />
        <CodeableConceptEntry elementName='code' fieldLabel='Timing Abbreviation'
          valueSet={timingDotCodeValueSet}
          startingValue={timingState.code} setResourceState={setTimingState} />
      </div>
    </>
  }
})

const timingRepeatDotBoundsAllowedDatatypes = ['Duration', 'Range', 'Period'];
const timingRepeatPeriodUnitAllowedValues = ['s', 'min', 'h', 'd', 'wk', 'mo', 'a'];
const timingRepeatDayOfWeekAllowedValues = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const timingRepeatWhenAllowedValues = ['HS', 'WAKE', 'C', 'CM', 'CD', 'CV', 'AC', 'ACM', 'ACD', 'ACV', 'PC', 'PCM', 'PCD', 'PCV'];

const TimingRepeatEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle, inTableCell, debug, startCollapsed }) => {
  let startingTimingRepeat = {
    'boundsDuration': "", 'boundsRange': "", 'boundsPeriod': "", 'count': "", 'countMax': "",
    'duration': "", 'durationMax': "", 'durationUnit': "", 'frequency': "", 'frequencyMax': "",
    'period': "", 'periodMax': "", 'periodUnit': "", 'dayOfWeek': [], 'timeOfDay': [],
    'when': [], 'offset': ""
  };
  let startingBoundsDatatype = 'none';
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingTimingRepeat.extension = startingValue.extension; }
    if (startingValue.boundsDuration) {
      startingTimingRepeat.boundsDuration = startingValue.boundsDuration;
      startingBoundsDatatype = 'Duration';
    }
    if (startingValue.boundsRange) {
      startingTimingRepeat.boundsRange = startingValue.boundsRange;
      startingBoundsDatatype = 'Range';
    }
    if (startingValue.boundsPeriod) {
      startingTimingRepeat.boundsPeriod = startingValue.boundsPeriod;
      startingBoundsDatatype = 'Period';
    }
    if (startingValue.count) { startingTimingRepeat.count = startingValue.count; }
    if (startingValue.countMax) { startingTimingRepeat.countMax = startingValue.countMax; }
    if (startingValue.duration) { startingTimingRepeat.duration = startingValue.duration; }
    if (startingValue.durationMax) { startingTimingRepeat.durationMax = startingValue.durationMax; }
    if (startingValue.durationUnit) { startingTimingRepeat.durationUnit = startingValue.durationUnit; }
    if (startingValue.frequency) { startingTimingRepeat.frequency = startingValue.frequency; }
    if (startingValue.frequencyMax) { startingTimingRepeat.frequencyMax = startingValue.frequencyMax; }
    if (startingValue.period) { startingTimingRepeat.period = startingValue.period; }
    if (startingValue.periodMax) { startingTimingRepeat.periodMax = startingValue.periodMax; }
    if (startingValue.periodUnit) { startingTimingRepeat.periodUnit = startingValue.periodUnit; }
    if (startingValue.dayOfWeek) { startingTimingRepeat.dayOfWeek = startingValue.dayOfWeek; }
    if (startingValue.timeOfDay) { startingTimingRepeat.timeOfDay = startingValue.timeOfDay; }
    if (startingValue.when) { startingTimingRepeat.when = startingValue.when; }
    if (startingValue.offset) { startingTimingRepeat.offset = startingValue.offset; }
  }

  const [timingRepeatState, setTimingRepeatState] = useState(startingTimingRepeat);

  const [boundsDatatypeState, setBoundsDatatypeState] = useState(startingBoundsDatatype);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(timingRepeatState).length) {
      let newTimingRepeat = {};
      if (timingRepeatState.extension) { newTimingRepeat.extension = timingRepeatState.extension; }
      if (boundsDatatypeState === 'Duration' && timingRepeatState.boundsDuration && Object.keys(timingRepeatState.boundsDuration).length) {
        newTimingRepeat.boundsDuration = timingRepeatState.boundsDuration;
      }
      if (boundsDatatypeState === 'Range' && timingRepeatState.boundsRange && Object.keys(timingRepeatState.boundsRange).length) {
        newTimingRepeat.boundsRange = timingRepeatState.boundsRange;
      }
      if (boundsDatatypeState === 'Period' && timingRepeatState.boundsPeriod && Object.keys(timingRepeatState.boundsPeriod).length) {
        newTimingRepeat.boundsPeriod = timingRepeatState.boundsPeriod;
      }
      if (timingRepeatState.count) { newTimingRepeat.count = timingRepeatState.count; }
      if (timingRepeatState.countMax) { newTimingRepeat.countMax = timingRepeatState.countMax; }
      if (timingRepeatState.duration) { newTimingRepeat.duration = timingRepeatState.duration; }
      if (timingRepeatState.durationMax) { newTimingRepeat.durationMax = timingRepeatState.durationMax; }
      if (timingRepeatState.durationUnit) { newTimingRepeat.durationUnit = timingRepeatState.durationUnit; }
      if (timingRepeatState.frequency) { newTimingRepeat.frequency = timingRepeatState.frequency; }
      if (timingRepeatState.frequencyMax) { newTimingRepeat.frequencyMax = timingRepeatState.frequencyMax; }
      if (timingRepeatState.period) { newTimingRepeat.period = timingRepeatState.period; }
      if (timingRepeatState.periodMax) { newTimingRepeat.periodMax = timingRepeatState.periodMax; }
      if (timingRepeatState.periodUnit) { newTimingRepeat.periodUnit = timingRepeatState.periodUnit; }
      if (timingRepeatState.dayOfWeek && Array.isArray(timingRepeatState.dayOfWeek) && timingRepeatState.dayOfWeek.length) {
        newTimingRepeat.dayOfWeek = timingRepeatState.dayOfWeek;
      }
      if (timingRepeatState.timeOfDay && Array.isArray(timingRepeatState.timeOfDay) && timingRepeatState.timeOfDay.length) {
        newTimingRepeat.timeOfDay = timingRepeatState.timeOfDay;
      }
      if (timingRepeatState.when && Array.isArray(timingRepeatState.when) && timingRepeatState.when.length) {
        newTimingRepeat.when = timingRepeatState.when;
      }
      if (timingRepeatState.offset) { newTimingRepeat.offset = timingRepeatState.offset; }
      if (Object.keys(newTimingRepeat).length === 0) {
        newTimingRepeat = null;
      }
      handleChange(elementName, newTimingRepeat, setResourceState);
    }
  }), [timingRepeatState, boundsDatatypeState]);

  if (startCollapsedState) {
    return <>
      <p>
        <b>{fieldLabel}: </b>
        {startingValue && getStringFromFHIR.TimingRepeat(startingTimingRepeat)}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </p>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <p style={{ marginBottom: "0px" }}><b>Bounds for Timing Schedule:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='bounds[x]' allowedDatatypes={timingRepeatDotBoundsAllowedDatatypes}
            datatypeState={boundsDatatypeState} setDatatypeState={setBoundsDatatypeState} />
          {boundsDatatypeState === 'Duration' &&
            <QuantityEntry elementName='boundsDuration' fieldLabel={'Duration for Timing Schedule'}
              dataEntryStyle={"Duration"} inTableCell={inTableCell}
              startingValue={timingRepeatState.boundsDuration} setResourceState={setTimingRepeatState} />}
          {boundsDatatypeState === 'Range' &&
            <RangeEntry elementName='boundsRange' fieldLabel={'Range for Timing Schedule'}
              dataEntryStyle={dataEntryStyle}
              startingValue={timingRepeatState.boundsRange} setResourceState={setTimingRepeatState} />}
          {boundsDatatypeState === 'Period' &&
            <PeriodEntry elementName='boundsPeriod' fieldLabel={'Period for Timing Schedule'}
              inTableCell={inTableCell}
              startingValue={timingRepeatState.boundsPeriod} setResourceState={setTimingRepeatState} />}
        </div>
        <PositiveIntEntry elementName='count' fieldLabel='Number of times to repeat'
          startingValue={timingRepeatState.count} setResourceState={setTimingRepeatState} />
        <PositiveIntEntry elementName='countMax' fieldLabel='Maximum number of times to repeat'
          startingValue={timingRepeatState.countMax} setResourceState={setTimingRepeatState} />
        <DecimalEntry elementName='duration' fieldLabel='Duration'
          startingValue={timingRepeatState.duration} setResourceState={setTimingRepeatState} />
        <DecimalEntry elementName='durationMax' fieldLabel='Duration Maximum'
          startingValue={timingRepeatState.durationMax} setResourceState={setTimingRepeatState} />
        <CodeEntry elementName='durationUnit' fieldLabel='Duration unit'
          allowedValues={timingRepeatPeriodUnitAllowedValues} dataEntryStyle='dropdown'
          startingValue={timingRepeatState.durationUnit} setResourceState={setTimingRepeatState} />
        <PositiveIntEntry elementName='frequency' fieldLabel='Frequency (number of repetitions per period)'
          startingValue={timingRepeatState.frequency} setResourceState={setTimingRepeatState} />
        <PositiveIntEntry elementName='frequencyMax' fieldLabel='Frequency Maximum'
          startingValue={timingRepeatState.frequencyMax} setResourceState={setTimingRepeatState} />
        <DecimalEntry elementName='period' fieldLabel='Period (duration for frequency per period)'
          startingValue={timingRepeatState.period} setResourceState={setTimingRepeatState} />
        <DecimalEntry elementName='periodMax' fieldLabel='Period Maximum'
          startingValue={timingRepeatState.periodMax} setResourceState={setTimingRepeatState} />
        <CodeEntry elementName='periodUnit' fieldLabel='Period unit'
          allowedValues={timingRepeatPeriodUnitAllowedValues} dataEntryStyle='dropdown'
          startingValue={timingRepeatState.periodUnit} setResourceState={setTimingRepeatState} />
        {(timingRepeatState.dayOfWeek === undefined || Array.isArray(timingRepeatState.dayOfWeek) || timingRepeatState.dayOfWeek === null) &&
          <CodeArrayEntry datatype='code' elementName='dayOfWeek' fieldLabel='Day of Week'
            allowedValues={timingRepeatDayOfWeekAllowedValues} dataEntryStyle='dropdown'
            startingValue={timingRepeatState.dayOfWeek} setResourceState={setTimingRepeatState} />}
        {(timingRepeatState.timeOfDay === undefined || Array.isArray(timingRepeatState.timeOfDay) || timingRepeatState.timeOfDay === null) &&
          <ArrayEntry datatype='time' elementName='timeOfDay' fieldLabel='Time of Day'
            startingValue={timingRepeatState.timeOfDay} setResourceState={setTimingRepeatState} />}
        {(timingRepeatState.when === undefined || Array.isArray(timingRepeatState.when) || timingRepeatState.when === null) &&
          <CodeArrayEntry datatype='code' elementName='when' fieldLabel='When'
            allowedValues={timingRepeatWhenAllowedValues} dataEntryStyle='dropdown'
            startingValue={timingRepeatState.when} setResourceState={setTimingRepeatState} />}
        <UnsignedIntEntry elementName='offset' fieldLabel='Offset (minutes before or after)'
          startingValue={timingRepeatState.offset} setResourceState={setTimingRepeatState} />
      </div>
    </>
  }
})

const usageContextDotCodeValueSet = [
  { system: "http://terminology.hl7.org/CodeSystem/usage-context-type", code: "gender", display: "Gender" },
  { system: "http://terminology.hl7.org/CodeSystem/usage-context-type", code: "age", display: "Age Range" },
  { system: "http://terminology.hl7.org/CodeSystem/usage-context-type", code: "focus", display: "Clinical Focus" },
  { system: "http://terminology.hl7.org/CodeSystem/usage-context-type", code: "user", display: "User Type" },
  { system: "http://terminology.hl7.org/CodeSystem/usage-context-type", code: "workflow", display: "Workflow Setting" },
  { system: "http://terminology.hl7.org/CodeSystem/usage-context-type", code: "task", display: "Workflow Task" },
  { system: "http://terminology.hl7.org/CodeSystem/usage-context-type", code: "venue", display: "Clinical Venue" },
  { system: "http://terminology.hl7.org/CodeSystem/usage-context-type", code: "species", display: "Species" },
  { system: "http://terminology.hl7.org/CodeSystem/usage-context-type", code: "program", display: "Program" },
  { system: "https://fevir.net/resources/CodeSystem/179423", code: "evidence-communication", display: "Evidence Communication" },
  { "system": "https://clinicaltrials.gov", "code": "interventionType", "display": "Intervention Type for ClinicalTrials.gov" },
  { "system": "https://clinicaltrials.gov", "code": "measureType", "display": "Measure Type" },
  { "system": "https://clinicaltrials.gov", "code": "outcomeMeasureType", "display": "Outcome Measure Type" },
  { "system": "https://clinicaltrials.gov", "code": "adverseEventsType", "display": "Adverse Events Outcome Type for ClinicalTrials.gov" },
  { "system": "https://clinicaltrials.gov", "code": "adverseEventsOrganSystem", "display": "Organ System Classification for Adverse Events" }
];
const usageContextDotValueAllowedDatatypes = ['CodeableConcept', 'Quantity', 'Range', 'Reference'];
const UsageContextEntry = memo(({ elementName, fieldLabel, inTableCell, startingValue, setResourceState,
  valueSet, valueSetForType, referencedResourceTypes, dataEntryStyle, debug, systemChoices, setSystemChoices,
  systemChoicesOpen, startCollapsed }) => {
  if (!valueSetForType) {
    valueSetForType = usageContextDotCodeValueSet;
  }
  if (!referencedResourceTypes) {
    referencedResourceTypes = ['PlanDefinition', 'ResearchStudy', 'InsurancePlan', 'HealthcareService', 'Group', 'Location', 'Organization'];
  }
  let startingUsageContext = { "code": "", "valueCodeableConcept": "", "valueQuantity": "", "valueRange": "", "valueReference": "" };
  let startingValueDatatype = 'none';
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingUsageContext.extension = startingValue.extension; }
    if (startingValue.code) { startingUsageContext.code = startingValue.code; }
    if (startingValue.valueCodeableConcept) {
      startingUsageContext.valueCodeableConcept = startingValue.valueCodeableConcept;
      startingValueDatatype = 'CodeableConcept';
    }
    if (startingValue.valueQuantity) {
      startingUsageContext.valueQuantity = startingValue.valueQuantity;
      startingValueDatatype = 'Quantity';
    }
    if (startingValue.valueRange) {
      startingUsageContext.valueRange = startingValue.valueRange;
      startingValueDatatype = 'Range';
    }
    if (startingValue.valueReference) {
      startingUsageContext.valueReference = startingValue.valueReference;
      startingValueDatatype = 'Reference';
    }
  }

  const [usageContextState, setUsageContextState] = useState(startingUsageContext);

  const [valueDatatypeState, setValueDatatypeState] = useState(startingValueDatatype);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(usageContextState).length) {
      let newUsageContext = {};
      if (usageContextState.extension) { newUsageContext.extension = usageContextState.extension; }
      if (usageContextState.code && Object.keys(usageContextState.code).length) {
        newUsageContext.code = usageContextState.code;
      }
      if (valueDatatypeState === 'CodeableConcept' && usageContextState.valueCodeableConcept && Object.keys(usageContextState.valueCodeableConcept).length) {
        newUsageContext.valueCodeableConcept = usageContextState.valueCodeableConcept;
      }
      if (valueDatatypeState === 'Quantity' && usageContextState.valueQuantity && Object.keys(usageContextState.valueQuantity).length) {
        newUsageContext.valueQuantity = usageContextState.valueQuantity;
      }
      if (valueDatatypeState === 'Range' && usageContextState.valueRange && Object.keys(usageContextState.valueRange).length) {
        newUsageContext.valueRange = usageContextState.valueRange;
      }
      if (valueDatatypeState === 'Reference' && usageContextState.valueReference && Object.keys(usageContextState.valueReference).length) {
        newUsageContext.valueReference = usageContextState.valueReference;
      }
      if (Object.keys(newUsageContext).length === 0) {
        newUsageContext = null;
      }
      handleChange(elementName, newUsageContext, setResourceState);
    }
  }), [usageContextState, valueDatatypeState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR usageContext={startingUsageContext} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        {systemChoices ?
          <CodingEntryWithSystemSelector elementName='code' fieldLabel={'Type of ' + fieldLabel}
            startingValue={usageContextState.code} setResourceState={setUsageContextState}
            systemChoices={systemChoices} setSystemChoices={setSystemChoices} systemChoicesOpen={systemChoicesOpen}
            debug={debug} />
          :
          <CodingEntry elementName='code' fieldLabel={'Type of ' + fieldLabel}
            valueSet={valueSetForType} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
            startingValue={usageContextState.code} setResourceState={setUsageContextState} />
        }
        <p style={{ marginBottom: "0px" }}><b>Value:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='value[x]' allowedDatatypes={usageContextDotValueAllowedDatatypes}
            datatypeState={valueDatatypeState} setDatatypeState={setValueDatatypeState} />
          {valueDatatypeState === 'CodeableConcept' &&
            <CodeableConceptEntry elementName='valueCodeableConcept' fieldLabel={'Value for ' + fieldLabel}
              valueSet={valueSet} dataEntryStyle={dataEntryStyle}
              startingValue={usageContextState.valueCodeableConcept} setResourceState={setUsageContextState} />}
          {valueDatatypeState === 'Quantity' &&
            <QuantityEntry elementName='valueQuantity' fieldLabel={'Value for ' + fieldLabel}
              dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
              startingValue={usageContextState.valueQuantity} setResourceState={setUsageContextState} />}
          {valueDatatypeState === 'Range' &&
            <RangeEntry elementName='valueRange' fieldLabel={'Value for ' + fieldLabel}
              dataEntryStyle={dataEntryStyle}
              startingValue={usageContextState.valueRange} setResourceState={setUsageContextState} />}
          {valueDatatypeState === 'Reference' &&
            <ReferenceEntry elementName='valueReference' fieldLabel={'Value for ' + fieldLabel}
              dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} referencedResourceTypes={referencedResourceTypes}
              enableCreation={true}
              startingValue={usageContextState.valueReference} setResourceState={setUsageContextState} />}
        </div>
      </div>
    </>
  }
});

const UnsignedIntEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {

  return <TextField style={{ width: "100%", marginTop: "12px", maxWidth: "260px" }}
    className="inputField" type='number' inputProps={{ step: "any" }} label={fieldLabel} size="small" variant='outlined'
    value={startingValue ?? ""}
    onInput={alertIfInvalidNumberEntry}
    onChange={(e) => {
      if (e.target.value && !isNaN(e.target.value)) {
        if ((parseInt(e.target.value) != parseFloat(e.target.value)) || parseInt(e.target.value) < 0) {
          alert("Only non-negative integers are allowed.");
        }
      }
      if (e.target.value === null) {
        handleChange(elementName, "", setResourceState);
      } else if (isNaN(e.target.value)) {
        handleChange(elementName, e.target.value, setResourceState);
      } else {
        handleChange(elementName, Number(e.target.value), setResourceState);
      }
    }} />
});

const UriEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  return <div>
    <TextField style={{ width: "100%", marginTop: "12px" }} multiline
      className="inputField" type='text' label={fieldLabel} size="small" variant='outlined'
      value={startingValue || ""}
      onChange={(e) => { handleChange(elementName, e.target.value, setResourceState) }} />
    {startingValue && <div><b>{fieldLabel} will display as: </b><DisplayFromFHIR uri={startingValue} /><br /><br /></div>}
  </div>
});

const ArrayEntry = memo(({ datatype, elementName, fieldLabel, startingValue, setResourceState,
  addElementValues, addElementValuesArray, allowedValues,
  fullResourceState, setSourceJsonState,
  allowedTypeValues, allowedContentTypes, allowedLanguages, dataEntryStyle, inTableCell, valueSet, referencedResourceTypes,
  debug, deletable, deletableArray, doNotShowFieldLabel, typeValueSet, classifierValueSet, systemChoices, setSystemChoices, systemChoicesOpen,
  startEmptyArrayClosed, typeSpecificValueSets, startCollapsed, startingResourceType, extensionUrl,
  extensionValueType, classificationProfile, enableCreation, resourceType, setProfile, selectProfile,
  selectAnyProfile,
  path, adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  let startingArrayAsObject = {};
  let showDataEntry = true;
  if (startingValue) {
    for (let itemIndex in startingValue) {
      startingArrayAsObject[itemIndex] = startingValue[itemIndex];
    }
  } else {
    //startingArrayAsObject['0'] = null;
    if (startEmptyArrayClosed) {
      showDataEntry = false;
    }
  }

  const [arrayState, setArrayState] = useState(startingArrayAsObject);
  const [undoArrayState, setUndoArrayState] = useState(null);
  const [showDataEntryState, setShowDataEntryState] = useState(showDataEntry);
  const [selectMultipleResourcesFromSearchState, setSelectMultipleResourcesFromSearchState] = useState({
    "modalOpen": false, "selectedResourcesArray": []
  });

  useEffect(() => {
    if (selectMultipleResourcesFromSearchState.selectedResourcesArray.length > 0 &&
      selectMultipleResourcesFromSearchState.applySelection) {
      let initialArray = startingValue || [];
      let newArray = initialArray.concat(selectMultipleResourcesFromSearchState.selectedResourcesArray);
      handleChange(elementName, newArray, setResourceState);
    }
  }, [selectMultipleResourcesFromSearchState]);

  useEffect((() => {
    if (Object.keys(arrayState).length) {
      let newArray = [];
      for (let key of Object.keys(arrayState)) {
        if (arrayState[key] !== null &&
          !(typeof arrayState[key] === "object" && Object.keys(arrayState[key]).length === 0) &&
          !(Array.isArray(arrayState[key]) && arrayState[key].length === 0) &&
          arrayState[key] !== "" &&
          arrayState[key] !== undefined && arrayState[key] !== "DELETEME") {
          newArray.push(arrayState[key]);
        }
      }
      if (newArray.length > 0) {
        handleChange(elementName, newArray, setResourceState);
      } else {
        handleChange(elementName, null, setResourceState);
      }
      if (undoArrayState && (Object.keys(arrayState).length !== 1 || (Object.keys(arrayState).length === 1 && arrayState['0'] !== "DELETEME"))) {
        setUndoArrayState(null);
      }
    }
  }), [arrayState]);

  useEffect(() => {
    if (undoArrayState) {
      setArrayState({ '0': "DELETEME" });
    }
  }, [undoArrayState]);

  return <div>
    {(showDataEntryState === false || doNotShowFieldLabel || (inTableCell && Object.entries(arrayState).length > 0)) ? <></> : <p style={{ marginBottom: "2px" }}><b>{fieldLabel}: </b></p>}
    {showDataEntryState === false &&
      <Button className="formButton" style={{ color: "#000000", marginBottom: "12px" }} content={"+ Add " + fieldLabel}
        onClick={() => {
          setArrayState(prevState => {
            let arrayLength = Object.keys(prevState).length;
            let arrayIndexNumber = arrayLength.toString();
            if (prevState['0'] === null) {
              arrayIndexNumber = '1';
            }
            return {
              ...prevState,
              //['0']: prevState['0'] || "",
              [arrayIndexNumber]: ""
            };
          });
          if (!showDataEntryState) {
            setShowDataEntryState(true);
          }
        }} />}
    <div style={{ marginLeft: doNotShowFieldLabel || inTableCell ? "0px" : "24px" }}>
      {showDataEntryState === true && Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
        return <div key={keyValuePairIndex} style={{ marginBottom: doNotShowFieldLabel ? "6px" : "0px" }}>
          {keyValuePair[1] === "DELETEME" ?
            <>
              <p style={{ marginBottom: "0px" }}><b>{fieldLabel + ' entry ' + (keyValuePairIndex + 1)}: </b></p>
              <div style={{ marginLeft: "24px" }}>WILL BE DELETED.</div>
            </>
            :
            <DataEntry datatype={datatype} elementName={keyValuePair[0]} enableCreation={enableCreation}
              doNotShowFieldLabel={doNotShowFieldLabel} resourceType={resourceType}
              fieldLabel={doNotShowFieldLabel || Object.entries(arrayState).length > 1 ? fieldLabel + ' entry ' + (keyValuePairIndex + 1) : fieldLabel} classificationProfile={classificationProfile}
              startingValue={keyValuePair[1]} setResourceState={setArrayState} allowedValues={allowedValues}
              dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} valueSet={valueSet} referencedResourceTypes={referencedResourceTypes}
              debug={debug} allowedContentTypes={allowedContentTypes} allowedLanguages={allowedLanguages}
              startCollapsed={keyValuePair[1] && startCollapsed} startingResourceType={startingResourceType}
              systemChoices={systemChoices} setSystemChoices={setSystemChoices} systemChoicesOpen={systemChoicesOpen}
              typeSpecificValueSets={typeSpecificValueSets}
              addElementValues={addElementValues || addElementValuesArray?.[keyValuePair[0]]}
              allowedTypeValues={allowedTypeValues} deletable={deletable} typeValueSet={typeValueSet}
              classifierValueSet={classifierValueSet} extensionUrl={extensionUrl} extensionValueType={extensionValueType}
              setProfile={setProfile} selectProfile={selectProfile} selectAnyProfile={selectAnyProfile}
              fullResourceState={fullResourceState}
              setSourceJsonState={setSourceJsonState}
              path={path} adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          }
          {deletable && <span style={{ marginLeft: "24px" }}>
            <Button className="formButton" style={{ color: "#000000" }} content={"x Delete this " + fieldLabel}
              onClick={() => {
                setArrayState(prevState => {
                  return {
                    ...prevState,
                    [keyValuePairIndex.toString()]: "DELETEME"
                  };
                });
              }} />
            <br /><br />
          </span>}
        </div>
      })}
      <div style={{ marginBottom: "12px" }}>
        {showDataEntryState === true && <>
          <Button className="formButton" style={{ color: "#000000" }} content={"+ Add " + fieldLabel}
            onClick={() => {
              setArrayState(prevState => {
                let arrayLength = Object.keys(prevState).length;
                let arrayIndexNumber = arrayLength.toString();
                if (prevState['0'] === null) {
                  arrayIndexNumber = '1';
                }
                return {
                  ...prevState,
                  //['0']: prevState['0'] || "",
                  [arrayIndexNumber]: ""
                };
              });
              if (!showDataEntryState) {
                setShowDataEntryState(true);
              }
            }} />
        </>}
        {datatype === "Reference" && <><br />
          <Button className="formButton" style={{ color: "#000000" }}
            content={"Search Resources to Add Multiple " + fieldLabel + " Entries"}
            onClick={() => { setSelectMultipleResourcesFromSearchState({ "modalOpen": true, "selectedResourcesArray": [] }); }}
          />
        </>}
        {selectMultipleResourcesFromSearchState.modalOpen &&
          <SelectMultipleResourcesFromSearchModal allowedResourceTypes={referencedResourceTypes}
            selectMultipleResourcesFromSearchState={selectMultipleResourcesFromSearchState}
            setSelectMultipleResourcesFromSearchState={setSelectMultipleResourcesFromSearchState} />}
        {(deletableArray && showDataEntryState === true && Object.keys(arrayState).length !== 0) &&
          <Button className="formButton" style={{ color: "#000000" }} content={"Delete All " + fieldLabel + " Entries"}
            onClick={() => {
              alert('Deletion will remove all data in this list. When you click Update, the data will be deleted. If you do not want to delete, refresh the page to lose all your edits instead of clicking Update.');
              setUndoArrayState(arrayState);
              //setArrayState({ '0': null });
            }} />}
        {undoArrayState && <Button className="formButton" style={{ color: "#000000", marginLeft: "2px" }} content={"Undo Deletion"}
          onClick={() => { setArrayState(undoArrayState); }} />}
      </div>
    </div>
  </div>
});

const DatatypeSelector = memo(({ elementXName, allowedDatatypes, datatypeState, setDatatypeState }) => {
  return <div>
    <p style={{ marginBottom: "0px" }}><b>{elementXName} Datatype:</b></p>
    <FormControl>
      <FormLabel id="radiodatatypeselector-label" style={{ color: "#000000", paddingBottom: "6px" }}>Choose one way to define this value: <i>(If you Update with a different datatype, you will lose previous {elementXName} data.)</i></FormLabel>
      <RadioGroup aria-label="radiodatatypeselector" name={"radiodatatypeselector-" + elementXName}
        value={datatypeState} onChange={(e) => { setDatatypeState(e.target.value); }}>
        {allowedDatatypes.map((allowedDatatype, allowedDatatypeIndex) => {
          return <FormControlLabel
            key={allowedDatatypeIndex}
            value={allowedDatatype}
            control={<Radio color="primary" style={{ paddingTop: "4px", paddingBottom: "4px" }} />}
            name="radio-button-control"
            color="default"
            inputprops={{ 'aria-label': allowedDatatype }}
            label={<span style={{ textAlign: 'center' }}><b>{allowedDatatype}</b></span>}
            style={{ paddingLeft: "12px" }}
          />
        })}
      </RadioGroup>
    </FormControl></div>
});

const DataEntry = memo(({ datatype, elementName, fieldLabel, startingValue, setResourceState,
  startCollapsed, required,
  allowedValues, allowedTypeValues, allowedContentTypes, allowedLanguages, dataEntryStyle,
  doNotShowFieldLabel, inTableCell, startEmptyArrayClosed, storeFalse, resourceType,
  setProfile, selectProfile, selectAnyProfile, addElementValues, addElementValuesArray, generatedNarrative,
  valueSet, asArray, referencedResourceTypes, debug, deletable, deletableArray, enableCreation,
  valueSetForType, startingResourceType,
  numeratorValueSet, denominatorValueSet, typeValueSet, typeSpecificValueSets, classifierValueSet,
  systemChoices, setSystemChoices, systemChoicesOpen,
  extensionUrl, extensionValueType, classificationProfile, columnHeaders, columnHeader,
  addTableRowModalState, codeableConceptLevelValueSet, fullResourceState,
  setSourceJsonState,
  path, adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  if (adaptationReportState?.adaptOn) {
    return <AdaptItemDataEntry path={path} datatype={datatype} dataEntryStyle={dataEntryStyle}
      asArray={asArray} deletableArray={deletableArray}
      adaptationReportState={adaptationReportState}
      setAdaptationReportState={setAdaptationReportState}
      setChangeAvailableToSaveState={setChangeAvailableToSaveState}
      elementName={elementName}
      fieldLabel={fieldLabel}
      startingValue={startingValue}
      setResourceState={setResourceState}
      referencedResourceTypes={referencedResourceTypes}
      startCollapsed={startCollapsed} startEmptyArrayClosed={startEmptyArrayClosed}
      startingResourceType={startingResourceType} setProfile={setProfile}
      selectProfile={selectProfile} selectAnyProfile={selectAnyProfile}
      required={required} allowedValues={allowedValues} allowedTypeValues={allowedTypeValues}
      allowedContentTypes={allowedContentTypes} allowedLanguages={allowedLanguages}
      doNotShowFieldLabel={doNotShowFieldLabel} inTableCell={inTableCell}
      storeFalse={storeFalse} resourceType={resourceType}
      addElementValues={addElementValues} addElementValuesArray={addElementValuesArray}
      generatedNarrative={generatedNarrative} valueSetForType={valueSetForType}
      valueSet={valueSet} deletable={deletable} enableCreation={enableCreation}
      numeratorValueSet={numeratorValueSet} denominatorValueSet={denominatorValueSet}
      typeValueSet={typeValueSet} typeSpecificValueSets={typeSpecificValueSets} classifierValueSet={classifierValueSet}
      systemChoices={systemChoices} setSystemChoices={setSystemChoices} systemChoicesOpen={systemChoicesOpen}
      extensionUrl={extensionUrl} extensionValueType={extensionValueType}
      classificationProfile={classificationProfile} columnHeaders={columnHeaders} columnHeader={columnHeader}
      addTableRowModalState={addTableRowModalState} codeableConceptLevelValueSet={codeableConceptLevelValueSet}
      fullResourceState={fullResourceState} setSourceJsonState={setSourceJsonState}
    />
  }
  if (asArray) {
    if (datatype === "code") {
      return <CodeArrayEntry elementName={elementName} fieldLabel={fieldLabel} startCollapsed={startCollapsed}
        startingValue={startingValue} setResourceState={setResourceState} allowedValues={allowedValues}
        allowedContentTypes={allowedContentTypes} allowedLanguages={allowedLanguages} dataEntryStyle={dataEntryStyle}
        doNotShowFieldLabel={doNotShowFieldLabel}
        valueSet={valueSet} referencedResourceTypes={referencedResourceTypes} debug={debug} allowedTypeValues={allowedTypeValues}
        numeratorValueSet={numeratorValueSet} denominatorValueSet={denominatorValueSet} classificationProfile={classificationProfile}
        valueSetForType={valueSetForType} deletable={deletable} deletableArray={deletableArray} inTableCell={inTableCell}
        typeValueSet={typeValueSet} classifierValueSet={classifierValueSet} extensionUrl={extensionUrl} extensionValueType={extensionValueType} />
    } else if (datatype === "CompositionTableCell") {
      return <CompositionTableCellArrayEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel}
        setSourceJsonState={setSourceJsonState}
        setResourceState={setResourceState} columnHeaders={columnHeaders} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} />
    } else {
      return <ArrayEntry datatype={datatype} elementName={elementName} fieldLabel={fieldLabel} startCollapsed={startCollapsed}
        startingValue={startingValue} setResourceState={setResourceState} addElementValues={addElementValues}
        addElementValuesArray={addElementValuesArray} allowedValues={allowedValues}
        allowedContentTypes={allowedContentTypes} allowedLanguages={allowedLanguages} dataEntryStyle={dataEntryStyle}
        doNotShowFieldLabel={doNotShowFieldLabel} resourceType={resourceType}
        valueSet={valueSet} referencedResourceTypes={referencedResourceTypes} debug={debug} allowedTypeValues={allowedTypeValues}
        numeratorValueSet={numeratorValueSet} denominatorValueSet={denominatorValueSet} startEmptyArrayClosed={startEmptyArrayClosed}
        valueSetForType={valueSetForType} deletable={deletable} deletableArray={deletableArray}
        typeSpecificValueSets={typeSpecificValueSets} startingResourceType={startingResourceType}
        typeValueSet={typeValueSet} classifierValueSet={classifierValueSet} systemChoices={systemChoices}
        setSystemChoices={setSystemChoices} setProfile={setProfile} selectProfile={selectProfile} selectAnyProfile={selectAnyProfile}
        systemChoicesOpen={systemChoicesOpen} extensionUrl={extensionUrl} extensionValueType={extensionValueType}
        classificationProfile={classificationProfile} enableCreation={enableCreation}
        inTableCell={inTableCell}
        fullResourceState={fullResourceState}
        setSourceJsonState={setSourceJsonState}
        path={path} adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    }
  }
  switch (datatype) {
    case 'SearchPiece':
      return <SearchPieceEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug}
        typeValueSet={typeValueSet} classifierValueSet={classifierValueSet} startCollapsed={startCollapsed} />
    case 'ActivityDefinitionDynamicValue':
      return <ActivityDefinitionDynamicValueEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'ActivityDefinitionParticipant':
      return <ActivityDefinitionParticipantEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Address':
      return <AddressEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Age':
      return <QuantityEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} dataEntryStyle={"Age"} inTableCell={inTableCell} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Annotation':
      return <AnnotationEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Attachment':
      return <AttachmentEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} allowedContentTypes={allowedContentTypes} allowedLanguages={allowedLanguages} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Attester':
      return <AttesterEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'boolean':
      return <BooleanEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} storeFalse={storeFalse} inTableCell={inTableCell} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'canonical':
      return <UriEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'CharacteristicDefinitionByCombination':
      return <CharacteristicDefinitionByCombinationEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'CharacteristicExecutableExpression':
      return <CharacteristicExecutableExpressionEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'CharacteristicDefinitionByTypeAndValue':
      return <CharacteristicDefinitionByTypeAndValueEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'CharacteristicTimeFromEvent':
      return <CharacteristicTimeFromEventEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Classification':
      return <ClassificationEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} deletable={deletable} typeValueSet={typeValueSet} typeSpecificValueSets={typeSpecificValueSets} startCollapsed={startCollapsed} classificationProfile={classificationProfile} />
    case 'code':
      return <CodeEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'CodeableConcept':
      return <CodeableConceptEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} valueSet={valueSet} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} allowedValues={allowedValues} deletable={deletable} debug={debug} systemChoices={systemChoices} systemChoicesOpen={systemChoicesOpen} startCollapsed={startCollapsed} codeableConceptLevelValueSet={codeableConceptLevelValueSet} />
    case 'CodeableReference':
      return <CodeableReferenceEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} systemChoices={systemChoices} systemChoicesOpen={systemChoicesOpen}
        setResourceState={setResourceState} valueSet={valueSet} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} allowedValues={allowedValues}
        deletable={deletable} debug={debug} referencedResourceTypes={referencedResourceTypes}
        enableCreation={enableCreation} startCollapsed={startCollapsed} />
    case 'CodeSystemProperty':
      return <CodeSystemPropertyEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        setResourceState={setResourceState} valueSet={valueSet} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} allowedValues={allowedValues} deletable={deletable} debug={debug} referencedResourceTypes={referencedResourceTypes} startCollapsed={startCollapsed} />
    case 'CodeSystemConceptDesignation':
      return <CodeSystemConceptDesignationEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        setResourceState={setResourceState} valueSet={valueSet} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} allowedValues={allowedValues} deletable={deletable} debug={debug} referencedResourceTypes={referencedResourceTypes} startCollapsed={startCollapsed} />
    case 'Coding':
      if (systemChoices) {
        return <CodingEntryWithSystemSelector elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
          setResourceState={setResourceState} systemChoices={systemChoices} setSystemChoices={setSystemChoices} systemChoicesOpen={systemChoicesOpen} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
      } else {
        return <CodingEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
          setResourceState={setResourceState} valueSet={valueSet} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
      }
    case 'Comment':
      return <CommentEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        setResourceState={setResourceState} allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'CompositionTableCell':
      return <CompositionTableCellEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        setResourceState={setResourceState} allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} deletable={deletable} startCollapsed={startCollapsed}
        columnHeader={columnHeader} addTableRowModalState={addTableRowModalState}
        setSourceJsonState={setSourceJsonState} />
    case 'ConceptDesignation':
      return <ConceptDesignationEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'ContactDetail':
      return <ContactDetailEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} valueSet={valueSet} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'ContactPoint':
      return <ContactPointEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} valueSet={valueSet} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'date':
      return <DateEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'dateTime':
      return <DateTimeEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'decimal':
      return <DecimalEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Dosage':
      return <DosageEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} valueSet={valueSet} debug={debug} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} startCollapsed={startCollapsed} allowedValues={allowedValues} deletable={deletable} />
    case 'DoseAndRate':
      return <DoseAndRateEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} valueSet={valueSet} debug={debug} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} allowedValues={allowedValues} deletable={deletable} startCollapsed={startCollapsed} />
    case 'Duration':
      return <QuantityEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} dataEntryStyle={"Duration"} inTableCell={inTableCell} debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'EvidenceStatistic':
      return <EvidenceStatisticEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'EvidenceStatisticSampleSize':
      return <EvidenceStatisticSampleSizeEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'EvidenceStatisticAttributeEstimate':
      return <EvidenceStatisticAttributeEstimateEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'EvidenceStatisticModelCharacteristic':
      return <EvidenceStatisticModelCharacteristicEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'EvidenceStatisticModelCharacteristicVariable':
      return <EvidenceStatisticModelCharacteristicVariableEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} />
    case 'EvidenceVariableCategory':
      return <EvidenceVariableCategoryEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'EvidenceVariableDefinition':
      return <EvidenceVariableDefinitionEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Expression':
      return <ExpressionEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Extension':
      return <ExtensionEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} extensionUrl={extensionUrl} extensionValueType={extensionValueType} />
    case 'HumanName':
      return <HumanNameEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'id':
      return <IdEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} />
    case 'Identifier':
      return <IdentifierEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} valueSet={valueSet} debug={debug} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} startCollapsed={startCollapsed} allowedValues={allowedValues} deletable={deletable} />
    case 'integer':
      return <IntegerEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'ListEntry':
      return <ListEntryEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'markdown':
      return <MarkdownEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'xhtml':
      return <XhtmlEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Narrative':
      return <NarrativeEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable}
        debug={debug} startCollapsed={startCollapsed} generatedNarrative={generatedNarrative} />
    case 'Period':
      return <PeriodEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} inTableCell={inTableCell} setResourceState={setResourceState}
        deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'PlanDefinitionAction':
      return <PlanDefinitionActionEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'PlanDefinitionActor':
      return <PlanDefinitionActorEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'PlanDefinitionGoal':
      return <PlanDefinitionGoalEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Profile':
      return <ProfileEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        resourceType={resourceType} setResourceState={setResourceState} deletable={deletable} />
    case 'ProjectAction':
      return <ProjectActionEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'ProjectActionParameter':
      return <ProjectActionParameterEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'positiveInt':
      return <PositiveIntEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Quantity':
      return <QuantityEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} valueSet={valueSet} startCollapsed={startCollapsed} deletable={deletable} />
    case 'Range':
      return <RangeEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} valueSet={valueSet} deletable={deletable} startCollapsed={startCollapsed} />
    case 'Rating':
      return <RatingEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} deletable={deletable} typeValueSet={typeValueSet} typeSpecificValueSets={typeSpecificValueSets} startCollapsed={startCollapsed} />
    case 'Ratio':
      return <RatioEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} numeratorValueSet={numeratorValueSet} denominatorValueSet={denominatorValueSet} deletable={deletable} startCollapsed={startCollapsed} />
    case 'RecommendationJustificationComponent':
      return <RecommendationJustificationComponentEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} deletable={deletable} typeValueSet={typeValueSet} typeSpecificValueSets={typeSpecificValueSets} startCollapsed={startCollapsed} />
    case 'RecommendationJustificationContent':
      return <RecommendationJustificationContentEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} debug={debug} deletable={deletable} typeValueSet={typeValueSet} typeSpecificValueSets={typeSpecificValueSets} startCollapsed={startCollapsed} />
    case 'ResearchStudyAssociatedParty':
      return <ResearchStudyAssociatedPartyEntry elementName={elementName} fieldLabel={fieldLabel}
        startingValue={startingValue} setResourceState={setResourceState}
        allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
        debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'ResearchStudyComparisonGroup':
      return <ResearchStudyComparisonGroupEntry elementName={elementName} fieldLabel={fieldLabel}
        startingValue={startingValue} setResourceState={setResourceState}
        allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
        debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'ResearchStudyLabel':
      return <ResearchStudyLabelEntry elementName={elementName} fieldLabel={fieldLabel}
        startingValue={startingValue} setResourceState={setResourceState}
        allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
        debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'ResearchStudyObjective':
      return <ResearchStudyObjectiveEntry elementName={elementName} fieldLabel={fieldLabel}
        startingValue={startingValue} setResourceState={setResourceState}
        allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
        debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'Estimand':
      return <EstimandEntry elementName={elementName} fieldLabel={fieldLabel}
        startingValue={startingValue} setResourceState={setResourceState}
        allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
        debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'EventHandling':
      return <EventHandlingEntry elementName={elementName} fieldLabel={fieldLabel}
        startingValue={startingValue} setResourceState={setResourceState}
        allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
        debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'ResearchStudyOutcomeMeasure':
      return <ResearchStudyOutcomeMeasureEntry elementName={elementName} fieldLabel={fieldLabel}
        startingValue={startingValue} setResourceState={setResourceState}
        allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
        debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'ResearchStudyProgressStatus':
      return <ResearchStudyProgressStatusEntry elementName={elementName} fieldLabel={fieldLabel}
        startingValue={startingValue} setResourceState={setResourceState}
        allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
        debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'ResearchStudyRecruitment':
      return <ResearchStudyRecruitmentEntry elementName={elementName} fieldLabel={fieldLabel}
        startingValue={startingValue} setResourceState={setResourceState}
        allowedValues={allowedValues} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
        debug={debug} deletable={deletable} startCollapsed={startCollapsed} />
    case 'Reference':
      return <ReferenceEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState}
        enableCreation={enableCreation} setProfile={setProfile} selectProfile={selectProfile} selectAnyProfile={selectAnyProfile}
        valueSet={valueSet} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} allowedValues={allowedValues} referencedResourceTypes={referencedResourceTypes}
        startingResourceType={startingResourceType} deletable={deletable} debug={debug} startCollapsed={startCollapsed}
        addElementValues={addElementValues}
        fullResourceState={fullResourceState}
        setSourceJsonState={setSourceJsonState} />
    case 'RelatedArtifact':
      return <RelatedArtifactEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState}
        valueSet={valueSet} debug={debug} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell}
        allowedTypeValues={allowedTypeValues} referencedResourceTypes={referencedResourceTypes}
        deletable={deletable} startCollapsed={startCollapsed}
        fullResourceState={fullResourceState}
        setSourceJsonState={setSourceJsonState} />
    case 'RelativeTime':
      return <RelativeTimeEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState}
        doNotShowFieldLabel={doNotShowFieldLabel}
        valueSet={valueSet} debug={debug} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} referencedResourceTypes={referencedResourceTypes}
        deletable={deletable} startCollapsed={startCollapsed} />
    case 'SimpleQuantity':
      return <QuantityEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} dataEntryStyle={"SimpleQuantity"} inTableCell={inTableCell} valueSet={valueSet} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'string':
      return <StringEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} required={required} debug={debug} startCollapsed={startCollapsed} />
    case 'StudyAmendment':
      return <StudyAmendmentEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState}
        doNotShowFieldLabel={doNotShowFieldLabel}
        valueSet={valueSet} debug={debug} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} referencedResourceTypes={referencedResourceTypes}
        deletable={deletable} startCollapsed={startCollapsed} />
    case 'time':
      return <TimeEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'Timing':
      return <TimingEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'TimingRepeat':
      return <TimingRepeatEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'UsageContext':
      return <UsageContextEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} valueSet={valueSet} dataEntryStyle={dataEntryStyle} inTableCell={inTableCell} allowedValues={allowedValues} referencedResourceTypes={referencedResourceTypes} deletable={deletable} debug={debug} valueSetForType={valueSetForType} startCollapsed={startCollapsed} />
    case 'unsignedInt':
      return <UnsignedIntEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'uri':
      return <UriEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue}
        doNotShowFieldLabel={doNotShowFieldLabel} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'ValueSetCompose':
      return <ValueSetComposeEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'ValueSetComposeInclude':
      return <ValueSetComposeIncludeEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    case 'ValueSetComposeIncludeConcept':
      return <ValueSetComposeIncludeConceptEntry elementName={elementName} fieldLabel={fieldLabel} startingValue={startingValue} setResourceState={setResourceState} deletable={deletable} debug={debug} startCollapsed={startCollapsed} />
    default:
      return <>OOPS!  DataEntry datatype NOT recognized!</>
  }
});

const ProfileEntry = memo(({ resourceType, startingValue, setResourceState, elementName, fieldLabel }) => {
  const profileDictionary = ProfilesByResourceType[resourceType];
  let startingProfile = { "name": startingValue || "", "url": startingValue || "" }
  let startingEnterValueState = true;
  let profileNames;
  if (profileDictionary) {
    profileNames = Object.keys(profileDictionary);
    for (const key in profileDictionary) {
      if (profileDictionary[key].url === startingValue) {
        startingProfile.name = key;
        startingEnterValueState = false;
      }
    }
  }
  if (!startingValue) {
    startingEnterValueState = false;
  }
  const [profileState, setProfileState] = useState(startingProfile);
  const [enterValueState, setEnterValueState] = useState(startingEnterValueState);

  useEffect((() => {
    if (profileState.name) {
      let newProfileString = profileState.name;
      if (profileDictionary) {
        for (const key in profileDictionary) {
          if (key === newProfileString) {
            newProfileString = profileDictionary[key].url;
          }
        }
      }
      if (newProfileString === "Enter a different value.") {
        newProfileString = "";
        setEnterValueState(true);
      }
      handleChange(elementName, newProfileString, setResourceState);
    } else {
      handleChange(elementName, "", setResourceState);
    }
  }), [profileState]);

  if (profileNames && !enterValueState) {
    return <CodeEntry elementName='name' fieldLabel='Profile Name or URL'
      startingValue={profileState.name} setResourceState={setProfileState} dataEntryStyle="dropdown"
      allowedValues={profileNames} enableCreation={true} />
  }
  return <StringEntry elementName='name' fieldLabel='Profile Name or URL'
    startingValue={profileState.name !== "Enter a different value." ? profileState.name : ""} setResourceState={setProfileState} />
});

const MetaProfileEntry = memo(({ resourceType, startingValue, setResourceState }) => {
  let startingMeta = { "versionId": "", "lastUpdated": "", "source": "", "profile": [], "security": [], "tag": [] };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.versionId) { startingMeta.versionId = startingValue.versionId; }
    if (startingValue.lastUpdated) { startingMeta.lastUpdated = startingValue.lastUpdated; }
    if (startingValue.source) { startingMeta.source = startingValue.source; }
    if (startingValue.profile) { startingMeta.profile = startingValue.profile; }
    if (startingValue.security) { startingMeta.security = startingValue.security; }
    if (startingValue.tag) { startingMeta.tag = startingValue.tag; }
  }

  const [metaState, setMetaState] = useState(startingMeta);

  useEffect((() => {
    if (Object.keys(metaState).length) {
      let newMeta = {};
      if (metaState.versionId) { newMeta.versionId = metaState.versionId; }
      if (metaState.lastUpdated) { newMeta.lastUpdated = metaState.lastUpdated; }
      if (metaState.source) { newMeta.source = metaState.source; }
      if (metaState.profile && Array.isArray(metaState.profile) && metaState.profile.length > 0) {
        newMeta.profile = metaState.profile;
      }
      if (metaState.security && Array.isArray(metaState.security) && metaState.security.length > 0) {
        newMeta.security = metaState.security;
      }
      if (metaState.tag && Array.isArray(metaState.tag) && metaState.tag.length > 0) {
        newMeta.tag = metaState.tag;
      }
      handleChange("meta", newMeta, setResourceState);
    }
  }), [metaState]);

  return <DataEntry asArray={true} datatype='Profile' elementName='profile' fieldLabel='Profile'
    resourceType={resourceType} deletable={true} deletableArray={true}
    startingValue={metaState.profile} setResourceState={setMetaState} />
});

const metadataDotStatusValues = ['draft', 'active', 'retired', 'unknown'];
const compositionDotStatusValues = ['registered', 'partial', 'preliminary', 'final', 'amended', 'corrected',
  'appended', 'cancelled', 'entered-in-error', 'deprecated', 'unknown'];
const compositionDotAuthorResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];
const listDotSourceResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization', 'CareTeam'];
const versionAlgorithmAllowedDatatypes = ["string", "Coding"];
const versionAlgorithmValueSet = [
  { system: "http://hl7.org/fhir/version-algorithm", code: "semver", display: "SemVer" },
  { system: "http://hl7.org/fhir/version-algorithm", code: "integer", display: "Integer" },
  { system: "http://hl7.org/fhir/version-algorithm", code: "alpha", display: "Alphabetical" },
  { system: "http://hl7.org/fhir/version-algorithm", code: "date", display: "Date" },
  { system: "http://hl7.org/fhir/version-algorithm", code: "natural", display: "Natural" }
];
const MetadataPatternEdit = ({ resourceState, setResourceState }) => {
  let resourceType = resourceState.resourceJson.resourceType;
  let titleAsMetadataElement = true;
  let nameAsMetadataElement = true;
  let urlAsMetadataElement = true;
  let identifierAsMetadataElement = true;
  let versionAsMetadataElement = true;
  let versionAlgorithmAsMetadataElement = true;
  let statusAsMetadataElement = true;
  let experimentalAsMetadataElement = true;
  let dateAsMetadataElement = true;
  let publisherAsMetadataElement = true;
  let contactAsMetadataElement = true;
  let descriptionAsMetadataElement = true;
  let useContextAsMetadataElement = true;
  let jurisdictionAsMetadataElement = true;
  let purposeAsMetadataElement = true;
  let copyrightAsMetadataElement = true;
  let copyrightLabelAsMetadataElement = true;
  let topicAsMetadataElement = true;
  let authorAsMetadataElement = true;
  let reviewerAsMetadataElement = true;
  let editorAsMetadataElement = true;
  let endorserAsMetadataElement = true;
  let relatedArtifactAsMetadataElement = true;
  let approvalDateAsMetadataElement = true;
  let lastReviewDateAsMetadataElement = true;
  let effectivePeriodAsMetadataElement = true;
  if (resourceType === "ArtifactAssessment") {
    nameAsMetadataElement = false;
    urlAsMetadataElement = false;
    versionAsMetadataElement = false;
    versionAlgorithmAsMetadataElement = false;
    statusAsMetadataElement = false;
    experimentalAsMetadataElement = false;
    publisherAsMetadataElement = false;
    contactAsMetadataElement = false;
    descriptionAsMetadataElement = false;
    useContextAsMetadataElement = false;
    jurisdictionAsMetadataElement = false;
    purposeAsMetadataElement = false;
    copyrightLabelAsMetadataElement = false;
    topicAsMetadataElement = false;
    authorAsMetadataElement = false;
    reviewerAsMetadataElement = false;
    editorAsMetadataElement = false;
    endorserAsMetadataElement = false;
    relatedArtifactAsMetadataElement = false;
    effectivePeriodAsMetadataElement = false;
  } else if (resourceType === "Composition") {
    versionAlgorithmAsMetadataElement = false;
    experimentalAsMetadataElement = false;
    descriptionAsMetadataElement = false;
    jurisdictionAsMetadataElement = false;
    purposeAsMetadataElement = false;
    copyrightAsMetadataElement = false;
    copyrightLabelAsMetadataElement = false;
    approvalDateAsMetadataElement = false;
    lastReviewDateAsMetadataElement = false;
    effectivePeriodAsMetadataElement = false;
  } else if (resourceType === "Evidence") {
    jurisdictionAsMetadataElement = false;
  } else if (resourceType === "EvidenceVariable") {
    jurisdictionAsMetadataElement = false;
  } else if (resourceType === "Group") {
    topicAsMetadataElement = false;
    authorAsMetadataElement = false;
    reviewerAsMetadataElement = false;
    editorAsMetadataElement = false;
    endorserAsMetadataElement = false;
    relatedArtifactAsMetadataElement = false;
    approvalDateAsMetadataElement = false;
    lastReviewDateAsMetadataElement = false;
    effectivePeriodAsMetadataElement = false;
    jurisdictionAsMetadataElement = false;
  } else if (resourceType === "List") {
    nameAsMetadataElement = false;
    urlAsMetadataElement = false;
    versionAsMetadataElement = false;
    versionAlgorithmAsMetadataElement = false;
    experimentalAsMetadataElement = false;
    publisherAsMetadataElement = false;
    contactAsMetadataElement = false;
    descriptionAsMetadataElement = false;
    useContextAsMetadataElement = false;
    jurisdictionAsMetadataElement = false;
    purposeAsMetadataElement = false;
    copyrightLabelAsMetadataElement = false;
    topicAsMetadataElement = false;
    authorAsMetadataElement = false;
    reviewerAsMetadataElement = false;
    editorAsMetadataElement = false;
    endorserAsMetadataElement = false;
    relatedArtifactAsMetadataElement = false;
    approvalDateAsMetadataElement = false;
    lastReviewDateAsMetadataElement = false;
    effectivePeriodAsMetadataElement = false;
  } else if (resourceType === "ResearchStudy") {
    versionAlgorithmAsMetadataElement = false;
    experimentalAsMetadataElement = false;
    publisherAsMetadataElement = false;
    contactAsMetadataElement = false;
    useContextAsMetadataElement = false;
    jurisdictionAsMetadataElement = false;
    purposeAsMetadataElement = false;
    copyrightAsMetadataElement = false;
    copyrightLabelAsMetadataElement = false;
    topicAsMetadataElement = false;
    authorAsMetadataElement = false;
    reviewerAsMetadataElement = false;
    editorAsMetadataElement = false;
    endorserAsMetadataElement = false;
    approvalDateAsMetadataElement = false;
    lastReviewDateAsMetadataElement = false;
    effectivePeriodAsMetadataElement = false;
  }
  let startingExtension = resourceState.extension || [];
  let startingExtensionStateValue = {
    "otherExtensions": [], "identifier": [], "contact": [], "useContext": [], "jurisdiction": [],
    "topic": [], "author": [], "reviewer": [], "editor": [], "endorser": [], "relatedArtifact": []
  }
  if (Array.isArray(startingExtension) && startingExtension.length > 0) {
    for (const extension of startingExtension) {
      switch (extension.url) {
        case 'http://hl7.org/fhir/StructureDefinition/artifact-title':
          startingExtensionStateValue.title = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-name':
          startingExtensionStateValue.name = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-url':
          startingExtensionStateValue.url = extension.valueUri;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-identifier':
          startingExtensionStateValue.identifier.push(extension.valueIdentifier);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-version':
          startingExtensionStateValue.version = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-versionAlgorithm':
          startingExtensionStateValue.versionAlgorithmString = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-status':
          startingExtensionStateValue.status = extension.valueCode;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-experimental':
          startingExtensionStateValue.experimental = extension.valueBoolean;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-date':
          startingExtensionStateValue.date = extension.valueDateTime;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-publisher':
          startingExtensionStateValue.publisher = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-contact':
          startingExtensionStateValue.contact.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-description':
          startingExtensionStateValue.description = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-useContext':
          startingExtensionStateValue.useContext.push(extension.valueUsageContext);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-jurisdiction':
          startingExtensionStateValue.jurisdiction.push(extension.valueCodeableConcept);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-purpose':
          startingExtensionStateValue.purpose = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-copyright':
          startingExtensionStateValue.copyright = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-copyrightLabel':
          startingExtensionStateValue.copyrightLabel = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-approvalDate':
          startingExtensionStateValue.approvalDate = extension.valueDate;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-lastReviewDate':
          startingExtensionStateValue.lastReviewDate = extension.valueDate;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-effectivePeriod':
          startingExtensionStateValue.effectivePeriod = extension.valuePeriod;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-topic':
          startingExtensionStateValue.topic.push(extension.valueCodeableConcept);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-author':
          startingExtensionStateValue.author.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-reviewer':
          startingExtensionStateValue.reviewer.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-editor':
          startingExtensionStateValue.editor.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-endorser':
          startingExtensionStateValue.endorser.push(extension.valueContactDetail);
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-relatedArtifact':
          startingExtensionStateValue.relatedArtifact.push(extension.valueRelatedArtifact);
          break;
        default:
          startingExtensionStateValue.otherExtensions.push(extension)
      }
    }
  }
  let startingVersionAlgorithmDatatype = 'none';
  if (resourceState.versionAlgorithmString) {
    startingVersionAlgorithmDatatype = 'string';
  }
  if (resourceState.versionAlgorithmCoding) {
    startingVersionAlgorithmDatatype = 'Coding';
  }
  const [extensionState, setExtensionState] = useState(startingExtensionStateValue);
  const [urlChanged, setUrlChanged] = useState(false);
  const [versionAlgorithmDatatypeState, setVersionAlgorithmDatatypeState] = useState(startingVersionAlgorithmDatatype);

  useEffect(() => {
    if (versionAlgorithmDatatypeState === 'string' && resourceState.versionAlgorithmCoding) {
      setResourceState(prevState => { return { ...prevState, versionAlgorithmCoding: null } })
    }
    if (versionAlgorithmDatatypeState === 'Coding' && resourceState.versionAlgorithmString) {
      setResourceState(prevState => { return { ...prevState, versionAlgorithmString: null } })
    }
  }, [versionAlgorithmDatatypeState])

  useEffect(() => {
    let newExtension = [];
    if (extensionState.otherExtensions) {
      newExtension = JSON.parse(JSON.stringify(extensionState.otherExtensions));
    }
    if (extensionState.title) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-title",
        "valueString": extensionState.title
      });
    }
    if (extensionState.name) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-name",
        "valueString": extensionState.name
      });
    }
    if (extensionState.url) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-url",
        "valueUri": extensionState.url
      });
    }
    if (extensionState.identifier && extensionState.identifier.length > 0) {
      for (const identifierInstance of extensionState.identifier) {
        newExtension.push({
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-identifier",
          "valueIdentifier": identifierInstance
        });
      }
    }
    if (extensionState.version) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-version",
        "valueString": extensionState.version
      });
    }
    if (extensionState.versionAlgorithmString) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-versionAlgorithm",
        "valueString": extensionState.versionAlgorithmString
      });
    }
    if (extensionState.status) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
        "valueCode": extensionState.status
      });
    }
    if (typeof extensionState.experimental === "boolean") {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-experimental",
        "valueBoolean": extensionState.experimental
      });
    }
    if (extensionState.date) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-date",
        "valueDateTime": extensionState.date
      });
    }
    if (extensionState.publisher) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-publisher",
        "valueString": extensionState.publisher
      });
    }
    if (extensionState.contact && extensionState.contact.length > 0) {
      for (const instance of extensionState.contact) {
        newExtension.push({
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-contact",
          "valueContactDetail": instance
        });
      }
    }
    if (extensionState.description) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-description",
        "valueMarkdown": extensionState.description
      });
    }
    if (extensionState.useContext && extensionState.useContext.length > 0) {
      for (const instance of extensionState.useContext) {
        newExtension.push({
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-useContext",
          "valueUsageContext": instance
        });
      }
    }
    if (extensionState.jurisdiction && extensionState.jurisdiction.length > 0) {
      for (const instance of extensionState.jurisdiction) {
        newExtension.push({
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-jurisdiction",
          "valueCodeableConcept": instance
        });
      }
    }
    if (extensionState.purpose) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-purpose",
        "valueMarkdown": extensionState.purpose
      });
    }
    if (extensionState.copyright) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-copyright",
        "valueMarkdown": extensionState.copyright
      });
    }
    if (extensionState.copyrightLabel) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-copyrightLabel",
        "valueString": extensionState.copyrightLabel
      });
    }
    if (extensionState.topic && extensionState.topic.length > 0) {
      for (const instance of extensionState.topic) {
        newExtension.push({
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-topic",
          "valueCodeableConcept": instance
        });
      }
    }
    if (extensionState.author && extensionState.author.length > 0) {
      for (const instance of extensionState.author) {
        newExtension.push({
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
          "valueContactDetail": instance
        });
      }
    }
    if (extensionState.reviewer && extensionState.reviewer.length > 0) {
      for (const instance of extensionState.reviewer) {
        newExtension.push({
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-reviewer",
          "valueContactDetail": instance
        });
      }
    }
    if (extensionState.editor && extensionState.editor.length > 0) {
      for (const instance of extensionState.editor) {
        newExtension.push({
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-editor",
          "valueContactDetail": instance
        });
      }
    }
    if (extensionState.endorser && extensionState.endorser.length > 0) {
      for (const instance of extensionState.endorser) {
        newExtension.push({
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-endorser",
          "valueContactDetail": instance
        });
      }
    }
    if (extensionState.relatedArtifact && extensionState.relatedArtifact.length > 0) {
      for (const instance of extensionState.relatedArtifact) {
        newExtension.push({
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-relatedArtifact",
          "valueRelatedArtifact": instance
        });
      }
    }
    if (extensionState.approvalDate) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-approvalDate",
        "valueDate": extensionState.approvalDate
      });
    }
    if (extensionState.lastReviewDate) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-lastReviewDate",
        "valueDate": extensionState.lastReviewDate
      });
    }
    if (extensionState.effectivePeriod) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-effectivePeriod",
        "valuePeriod": extensionState.effectivePeriod
      });
    }
    if (newExtension.length > 0) {
      handleChange("extension", newExtension, setResourceState);
    } else {
      handleChange("extension", null, setResourceState);
    }
  }, [extensionState])

  return <div>
    {titleAsMetadataElement ?
      <StringEntry elementName='title' fieldLabel='Title' startingValue={resourceState.title} setResourceState={setResourceState} />
      :
      <StringEntry elementName='title' fieldLabel='Title' startingValue={extensionState.title} setResourceState={setExtensionState} />
    }
    {(resourceType === 'ActivityDefinition' || resourceType === 'PlanDefinition') &&
      <StringEntry elementName='subtitle' fieldLabel='Subtitle' startingValue={resourceState.subtitle} setResourceState={setResourceState} />
    }
    {nameAsMetadataElement ?
      <StringEntry elementName='name' fieldLabel='Name' startingValue={resourceState.name} setResourceState={setResourceState} />
      :
      <StringEntry elementName='name' fieldLabel='Name' startingValue={extensionState.name} setResourceState={setExtensionState} />
    }
    {urlAsMetadataElement ?
      <TextField style={{ width: "100%", marginTop: "12px" }} multiline
        className="inputField" type='text' label='URL' size="small" variant='outlined'
        value={resourceState.url || ""}
        onChange={(e) => { handleChange('url', e.target.value, setResourceState); setUrlChanged(true) }} />
      :
      <TextField style={{ width: "100%", marginTop: "12px" }} multiline
        className="inputField" type='text' label='URL' size="small" variant='outlined'
        value={extensionState.url || ""}
        onChange={(e) => { handleChange('url', e.target.value, setExtensionState); setUrlChanged(true) }} />
    }
    {urlChanged && <p><b>Changing the URL is only desired if you want to set the CANONICAL url for this Resource!</b></p>}
    {identifierAsMetadataElement ?
      <ArrayEntry datatype='Identifier' elementName='identifier' fieldLabel='Identifier' startCollapsed={true}
        startingValue={resourceState.identifier} setResourceState={setResourceState} dataEntryStyle='preserveFOI' />
      :
      <ArrayEntry datatype='Identifier' elementName='identifier' fieldLabel='Identifier' startCollapsed={true}
        startingValue={extensionState.identifier} setResourceState={setExtensionState} dataEntryStyle='preserveFOI' />
    }
    <MetaProfileEntry resourceType={resourceType} startingValue={resourceState.meta} setResourceState={setResourceState} />
    {versionAsMetadataElement ?
      <StringEntry elementName='version' fieldLabel='Version' startingValue={resourceState.version} setResourceState={setResourceState} />
      :
      <StringEntry elementName='version' fieldLabel='Version' startingValue={extensionState.version} setResourceState={setExtensionState} />
    }
    {versionAlgorithmAsMetadataElement ?
      <>
        <p style={{ marginBottom: "0px" }}><b>Version Algorithm:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='versonAlgorithm[x]' allowedDatatypes={versionAlgorithmAllowedDatatypes}
            datatypeState={versionAlgorithmDatatypeState} setDatatypeState={setVersionAlgorithmDatatypeState} />
          {versionAlgorithmDatatypeState === 'string' &&
            <StringEntry elementName='versionAlgorithmString' fieldLabel={'String for Version Algorithm'}
              startingValue={resourceState.versionAlgorithmString} setResourceState={setResourceState} />}
          {versionAlgorithmDatatypeState === 'Coding' &&
            <CodingEntry elementName='versionAlgorithmCoding' fieldLabel={'Coding for Version Algorithm'}
              valueSet={versionAlgorithmValueSet}
              startingValue={resourceState.versionAlgorithmCoding} setResourceState={setResourceState} />}
        </div>
      </>
      :
      <StringEntry elementName='versionAlgorithmString' fieldLabel={'Version Algorithm'}
        startingValue={extensionState.versionAlgorithmString} setResourceState={setExtensionState} />
    }
    {statusAsMetadataElement ?
      <CodeEntry elementName='status' fieldLabel='Status' startingValue={resourceState.status} setResourceState={setResourceState}
        allowedValues={resourceType === "Composition" ? compositionDotStatusValues : metadataDotStatusValues} togglable={false} />
      :
      <CodeEntry elementName='status' fieldLabel='Status' startingValue={extensionState.status} setResourceState={setExtensionState}
        allowedValues={metadataDotStatusValues} togglable={false} />
    }
    {experimentalAsMetadataElement ?
      <BooleanEntry elementName='experimental' fieldLabel='Experimental' startingValue={resourceState.experimental} setResourceState={setResourceState} />
      :
      <BooleanEntry elementName='experimental' fieldLabel='Experimental' startingValue={extensionState.experimental} setResourceState={setExtensionState} />
    }
    {dateAsMetadataElement ?
      <DateTimeEntry elementName='date' fieldLabel='Date last changed' startingValue={resourceState.date} setResourceState={setResourceState} />
      :
      <DateTimeEntry elementName='date' fieldLabel='Date last changed' startingValue={extensionState.date} setResourceState={setExtensionState} />
    }
    {
      resourceType === "Composition" ?
        <ReferenceEntry elementName='custodian' fieldLabel='Custodian/Publisher' startingValue={resourceState.custodian} setResourceState={setResourceState} referencedResourceTypes={['Organization']} startCollapsed={true} />
        :
        <>
          {publisherAsMetadataElement ?
            <StringEntry elementName='publisher' fieldLabel='Publisher' startingValue={resourceState.publisher} setResourceState={setResourceState} />
            :
            <StringEntry elementName='publisher' fieldLabel='Publisher' startingValue={extensionState.publisher} setResourceState={setExtensionState} />
          }
          {contactAsMetadataElement ?
            <ArrayEntry datatype='ContactDetail' elementName='contact' fieldLabel='Publisher Contact' startCollapsed={true}
              startingValue={resourceState.contact} setResourceState={setResourceState} />
            :
            <ArrayEntry datatype='ContactDetail' elementName='contact' fieldLabel='Publisher Contact' startCollapsed={true}
              startingValue={extensionState.contact} setResourceState={setExtensionState} />
          }
        </>
    }
    {descriptionAsMetadataElement ?
      <MarkdownEntry elementName='description' fieldLabel='Description' startingValue={resourceState.description} setResourceState={setResourceState} />
      :
      <MarkdownEntry elementName='description' fieldLabel='Description' startingValue={extensionState.description} setResourceState={setExtensionState} />
    }
    {useContextAsMetadataElement ?
      <ArrayEntry datatype='UsageContext' elementName='useContext' fieldLabel='Use Context'
        startingValue={resourceState.useContext} setResourceState={setResourceState} startCollapsed={true}
        startEmptyArrayClosed={true} deletableArray={true} />
      :
      <ArrayEntry datatype='UsageContext' elementName='useContext' fieldLabel='Use Context'
        startingValue={extensionState.useContext} setResourceState={setExtensionState} startCollapsed={true}
        startEmptyArrayClosed={true} deletableArray={true} />
    }
    {jurisdictionAsMetadataElement ?
      <ArrayEntry datatype='CodeableConcept' elementName='jurisdiction' fieldLabel='Jurisdiction'
        startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={resourceState.jurisdiction} setResourceState={setResourceState} />
      :
      <ArrayEntry datatype='CodeableConcept' elementName='jurisdiction' fieldLabel='Jurisdiction'
        startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={extensionState.jurisdiction} setResourceState={setExtensionState} />
    }
    {purposeAsMetadataElement ?
      <MarkdownEntry elementName='purpose' fieldLabel='Purpose' startingValue={resourceState.purpose} setResourceState={setResourceState} />
      :
      <MarkdownEntry elementName='purpose' fieldLabel='Purpose' startingValue={extensionState.purpose} setResourceState={setExtensionState} />
    }
    {(resourceType === 'ActivityDefinition' || resourceType === 'PlanDefinition') &&
      <MarkdownEntry elementName='usage' fieldLabel='Usage' startingValue={resourceState.usage} setResourceState={setResourceState} />
    }
    {copyrightAsMetadataElement ?
      <MarkdownEntry elementName='copyright' fieldLabel='Copyright' startingValue={resourceState.copyright} setResourceState={setResourceState} />
      :
      <MarkdownEntry elementName='copyright' fieldLabel='Copyright' startingValue={extensionState.copyright} setResourceState={setExtensionState} />
    }
    {copyrightLabelAsMetadataElement ?
      <StringEntry elementName='copyrightLabel' fieldLabel='Copyright Label' startingValue={resourceState.copyrightLabel} setResourceState={setResourceState} />
      :
      <StringEntry elementName='copyrightLabel' fieldLabel='Copyright Label' startingValue={extensionState.copyrightLabel} setResourceState={setExtensionState} />
    }
    {approvalDateAsMetadataElement ?
      <DateEntry elementName='approvalDate' fieldLabel='Date approved' startingValue={resourceState.approvalDate} setResourceState={setResourceState} />
      :
      <DateEntry elementName='approvalDate' fieldLabel='Date approved' startingValue={extensionState.approvalDate} setResourceState={setExtensionState} />
    }
    {lastReviewDateAsMetadataElement ?
      <DateEntry elementName='lastReviewDate' fieldLabel='Date last reviewed' startingValue={resourceState.lastReviewDate} setResourceState={setResourceState} />
      :
      <DateEntry elementName='lastReviewDate' fieldLabel='Date last reviewed' startingValue={extensionState.lastReviewDate} setResourceState={setExtensionState} />
    }
    {effectivePeriodAsMetadataElement ?
      <PeriodEntry elementName='effectivePeriod' fieldLabel='Effective period' startingValue={resourceState.effectivePeriod} setResourceState={setResourceState} />
      :
      <PeriodEntry elementName='effectivePeriod' fieldLabel='Effective period' startingValue={extensionState.effectivePeriod} setResourceState={setExtensionState} />
    }
    {topicAsMetadataElement ?
      <ArrayEntry datatype='CodeableConcept' elementName='topic' fieldLabel='Topic'
        startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={resourceState.topic} setResourceState={setResourceState} />
      :
      <ArrayEntry datatype='CodeableConcept' elementName='topic' fieldLabel='Topic'
        startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
        startingValue={extensionState.topic} setResourceState={setExtensionState} />
    }
    {authorAsMetadataElement ? <>
      {resourceType === "Composition" ?
        <ArrayEntry datatype='Reference' elementName='author' fieldLabel='Author' startCollapsed={true}
          referencedResourceTypes={compositionDotAuthorResourceTypes}
          startingValue={resourceState.author} setResourceState={setResourceState} />
        :
        <ArrayEntry datatype='ContactDetail' elementName='author' fieldLabel='Author' startCollapsed={true}
          startingValue={resourceState.author} setResourceState={setResourceState} />
      }
    </>
      :
      <>
        {resourceType === "List" ?
          <ReferenceEntry datatype='ContactDetail' elementName='source' fieldLabel='Author' startCollapsed={true}
            referencedResourceTypes={listDotSourceResourceTypes}
            startingValue={resourceState.source} setResourceState={setResourceState} />
          :
          <ArrayEntry datatype='ContactDetail' elementName='author' fieldLabel='Author' startCollapsed={true}
            startingValue={extensionState.author} setResourceState={setExtensionState} />
        }
      </>
    }
    {resourceType === "Composition" ?
      <>
        <ArrayEntry datatype='Attester' elementName='attester' fieldLabel='Attester' startCollapsed={true}
          startEmptyArrayClosed={true} deletableArray={true}
          startingValue={resourceState.attester} setResourceState={setResourceState} />
        <ArrayEntry datatype='RelatedArtifact' elementName='relatesTo' fieldLabel='Related Item' startCollapsed={true}
          allowedTypeValues='FHIR' startEmptyArrayClosed={true} deletableArray={true}
          startingValue={resourceState.relatesTo} setResourceState={setResourceState} />
      </>
      :
      <>
        {editorAsMetadataElement ?
          <ArrayEntry datatype='ContactDetail' elementName='editor' fieldLabel='Editor' startCollapsed={true}
            startEmptyArrayClosed={true} deletableArray={true}
            startingValue={resourceState.editor} setResourceState={setResourceState} />
          :
          <ArrayEntry datatype='ContactDetail' elementName='editor' fieldLabel='Editor' startCollapsed={true}
            startEmptyArrayClosed={true} deletableArray={true}
            startingValue={extensionState.editor} setResourceState={setExtensionState} />
        }
        {reviewerAsMetadataElement ?
          <ArrayEntry datatype='ContactDetail' elementName='reviewer' fieldLabel='Reviewer' startCollapsed={true}
            startEmptyArrayClosed={true} deletableArray={true}
            startingValue={resourceState.reviewer} setResourceState={setResourceState} />
          :
          <ArrayEntry datatype='ContactDetail' elementName='reviewer' fieldLabel='Reviewer' startCollapsed={true}
            startEmptyArrayClosed={true} deletableArray={true}
            startingValue={extensionState.reviewer} setResourceState={setExtensionState} />
        }
        {endorserAsMetadataElement ?
          <ArrayEntry datatype='ContactDetail' elementName='endorser' fieldLabel='Endorser' startCollapsed={true}
            startEmptyArrayClosed={true} deletableArray={true}
            startingValue={resourceState.endorser} setResourceState={setResourceState} />
          :
          <ArrayEntry datatype='ContactDetail' elementName='endorser' fieldLabel='Endorser' startCollapsed={true}
            startEmptyArrayClosed={true} deletableArray={true}
            startingValue={extensionState.endorser} setResourceState={setExtensionState} />
        }
        {relatedArtifactAsMetadataElement ?
          <ArrayEntry datatype='RelatedArtifact' elementName='relatedArtifact' fieldLabel='Related Item' startCollapsed={true}
            allowedTypeValues='FHIR' startEmptyArrayClosed={true} deletableArray={true}
            startingValue={resourceState.relatedArtifact} setResourceState={setResourceState} />
          :
          <ArrayEntry datatype='RelatedArtifact' elementName='relatedArtifact' fieldLabel='Related Item' startCollapsed={true}
            allowedTypeValues='FHIR' startEmptyArrayClosed={true} deletableArray={true}
            startingValue={extensionState.relatedArtifact} setResourceState={setExtensionState} />
        }
      </>
    }
  </div>
}

export { DataEntry, CodeEntry, MarkdownEntry, XhtmlEntry, DatatypeSelector, MetadataPatternEdit, generateCitation, DisplayHowToCite, MetaProfileEntry };