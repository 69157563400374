import { DisplayFromFHIR, getStringFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import ManageInclusionExclusionEnhancedCharacteristicTables from './ManageInclusionExclusionEnhancedCharacteristicTables';

const GroupDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  let characteristicExpression;
  if (fhirJson.extension && Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (let extension of fhirJson.extension) {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-relatedArtifact') {
        if (extension.valueRelatedArtifact.type === "cite-as") {
          howToCite = extension.valueRelatedArtifact.citation;
        }
      }
      if (extension.url === "http://hl7.org/fhir/StructureDefinition/characteristicExpression") {
        characteristicExpression = extension.valueExpression;
      }
    }
  }

  let noMemberText = "Members list not provided";
  if (fhirJson.member?.length > 0) {
    noMemberText = "";
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <span><b>Title: </b> {fhirJson.title ? fhirJson.title : <>Not stated</>}</span>
        <br />
        <span><b>Name: </b> {fhirJson.name ? fhirJson.name : <>Not stated</>}</span>
        <br />
        <span><b>Type: </b> {fhirJson.type && typeof fhirJson.type === "string" ? fhirJson.type : <>Not stated</>}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {fhirJson.code && <span><b>Kind: </b>{getStringFromFHIR.CodeableConcept(fhirJson.code)}</span>}
        <br />
        <span><b>Membership/Definitional: </b> {fhirJson.membership}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {fhirJson.quantity && <span><b>Quantity: </b> {fhirJson.quantity}</span>}
        <br />
        {fhirJson.description && <span><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /></span>}
        <br />
        {fhirJson.managingEntity && <span><b>Managing Entity: </b><DisplayFromFHIR reference={fhirJson.managingEntity} /></span>}
        {characteristicExpression && <div>
          <h4>Characteristic Expression:</h4>
          <DisplayFromFHIR expression={characteristicExpression} />
        </div>}
        {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") && <>
          <h4>Narrative Summary:</h4>
          <div style={{ marginLeft: "24px" }}>
            <DisplayFromFHIR xhtml={fhirJson.text.div} />
          </div>
        </>}
      </div>
      <h3 id="characteristic-combination">Characteristic Combination</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.combinationMethod && <span><b>Combination Method: </b>{fhirJson.combinationMethod}</span>}
        {fhirJson.combinationThreshold && <span>&nbsp;&nbsp;&nbsp;<b>Number of characteristics: </b>{fhirJson.combinationThreshold}</span>}
      </div>
      <br />
      <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={fhirJson} />
      <h3 id="members">Members</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.membership === "enumerated" ?
          <p>{noMemberText ?
            <>{noMemberText}</>
            :
            <>{fhirJson.member.map((member, memberIndex) => {
              if (member.entity === undefined) {
                return <p key={memberIndex}></p>
              }
              return <div key={memberIndex}>
                <DisplayFromFHIR reference={member.entity} />
              </div>
            })}</>
          }</p>
          :
          <p>This is a descriptive (definitional) Group. Listing members does not apply.</p>
        }
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default GroupDisplay;