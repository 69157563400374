import { clearState, jsonErrorCheck } from './ResourceFunctions';

import { updateIterativeJson } from './ResourceFunctions';

const commentRatingTextViewCalculations = (fhirEntryState, changeFhirEntryState, setCommentRatingState, commentRatingBlankState,
    setGroupCharacteristicKindOptions) => {

    let fhirEntryString = fhirEntryState.fhirEntryString;
    let fhirJson;
    try {
        fhirJson = JSON.parse(fhirEntryString);
    } catch {
        clearState(setCommentRatingState, commentRatingBlankState);
        setCommentRatingState(prevState  => { return { ...prevState, invalid: true }; });
        return false;
    }
    try {
        if (fhirJson.component?.length > 0) {
            if (fhirJson.component[0].informationType === "comment") {
                setCommentRatingState(prevState  => { return { ...prevState, headers: ["Type", "Subtype", "Description", "Notes", "Commenter"] }; });
            } else if (fhirJson.component[0].informationType === "classifier") {
                setCommentRatingState(prevState  => { return { ...prevState, headers: ["Type of Classifier", "Classifiers", "Description", "Notes", "Who Classified",] }; });
            } 
        }
        if (fhirEntryState.editMode == false) {
            //Then it's a brand new Group
            //changeFhirEntryState(true, "editMode");
        }
        /*
        let notes = [];
        if (fhirJson.note) {
            if (Array.isArray(fhirJson.note)) {
                for (let noteIndex in fhirJson.note) {
                    notes.push(fhirJson.note[noteIndex].text);
                }
                //certaintyRow["notes"] = fhirJson.note;
            } else if (typeof fhirJson.note === 'object' && fhirJson.note !== null && fhirJson.note.text) {
                notes = [fhirJson.note.text];
            }
        }*/
        setCommentRatingState(prevState  => { return { ...prevState, type: fhirJson.type, rating: fhirJson.rating, subcomponent: fhirJson.component, notes: fhirJson.text}; });
    } catch(e) {
        setCommentRatingState(prevState  => { return { ...prevState, invalid: true }; });
    }
}

const commentRatingTextViewChangesToJson = (fhirJson, commentRatingState) => {

    if (fhirJson === undefined) {
      return ;
    }
    for (let componentIndex in commentRatingState.subcomponent) {
        if (fhirJson.component === undefined) {
            fhirJson.component = [];
        }
        if (fhirJson.component[componentIndex] === undefined) {
            fhirJson.component.push({});
        }
        if (!(fhirJson.component[componentIndex].rater === undefined && (commentRatingState.subcomponent[componentIndex].rater === "" || commentRatingState.subcomponent[componentIndex].rater === undefined))) {
            fhirJson.component[componentIndex].rater = commentRatingState.subcomponent[componentIndex].rater;
        }
        fhirJson.component[componentIndex] = updateIterativeJson(commentRatingState.subcomponent[componentIndex], fhirJson.component[componentIndex], "subcomponent", "rater");

        if (!(fhirJson.component[componentIndex].description === undefined && (commentRatingState.subcomponent[componentIndex].description === "" || commentRatingState.subcomponent[componentIndex].description === undefined))) {
            fhirJson.component[componentIndex].description = commentRatingState.subcomponent[componentIndex].description;
        }
        fhirJson.component[componentIndex] = updateIterativeJson(commentRatingState.subcomponent[componentIndex], fhirJson.component[componentIndex], "subcomponent", "description");
    }
    


    return fhirJson;
}

const builderUpdateCommentRatingJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let commentRatingState = formInputsStateRef.current.commentRatingState;

    let fhirJson = jsonErrorCheck("ArtifactComment", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString, fhirEntryState.fhirJson, "");
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex == 0) {
        fhirJson = commentRatingTextViewChangesToJson(fhirJson, commentRatingState);
    }
    return fhirJson;
}

export { commentRatingTextViewCalculations, builderUpdateCommentRatingJson };