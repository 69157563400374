import React from 'react';
import { Table } from 'semantic-ui-react';
import "firebase/compat/auth";
import InclusionExclusionCharacteristicTables from './InclusionExclusionCharacteristicTables';
import { SimpleResourceFieldViewer } from './ResourceFunctions';

const RecommendationDisplay = ({ fhirJson, fhirEntryState, setFhirEntryState, changeFhirEntryState, referencedResourceState, setReferencedResourceState, groupCharacteristicKindOptions, setGroupCharacteristicKindOptions }) => {
  let editMode = fhirEntryState.editMode;
  let recommendingOrganizationsDisplay = "";
  if (fhirJson.recommender) {
    for (let recommenderIndex in fhirJson.recommender) {
      let display = fhirJson.recommender[recommenderIndex].display;
      if (display) {
        recommendingOrganizationsDisplay += display;
        if (recommenderIndex < fhirJson.recommender.length - 1) {
          recommendingOrganizationsDisplay += ", ";
        }
      }
    }
  }
  let reference = "";
  if (fhirJson.citeAsReference?.reference) {
    let slashesFound = fhirJson.citeAsReference.reference.toString().match(/\//g);
    if (slashesFound?.length === 1) {
      reference = fhirJson.citeAsReference.reference;
    }
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Title: </b> {fhirJson.title}</p>
        <p><b>Description: </b> <span style={{ whiteSpace: "pre-wrap" }}>{fhirJson.description}</span></p>
        <p><b>{fhirJson.recommender?.length > 1 ? <>Recommending Organizations: </> : <>Recommending Organization: </>} </b>
          {recommendingOrganizationsDisplay}</p>
        <p><b>For the Population: </b> {fhirJson.statement?.population}</p>
        <p><b>Recommended Action: </b> {fhirJson.statement?.action}</p>
        <p><b>Strength of Recommendation: </b> {fhirJson.strength}</p>
        <p style={{ marginBottom: "0px" }}><b>Guideline Reference: </b></p>
        <div style={{ marginTop: "0px", marginLeft: "24px" }}>
          {fhirJson.guideline &&
            <>
              {fhirJson.guideline.url && <p style={{ marginTop: "0px", marginBottom: "0px" }}><b>URL: </b> <a href={fhirJson.guideline.url} target="_blank" rel="noopener noreferrer">{fhirJson.guideline.url}</a></p>}
              {fhirJson.guideline.resource && <p style={{ marginTop: "0px", marginBottom: "0px" }}><b>{fhirJson.guideline.resource.type} Resource: </b> <a href={"/resources/" + fhirJson.guideline.resource.reference} target="_blank" rel="noopener noreferrer">{fhirJson.guideline.resource.reference}</a></p>}
            </>
          }
          <br />
        </div>
        <p><b>Cite As: </b>
          {(fhirJson.citeAsReference && typeof fhirJson.citeAsReference !== "string") ?
            <>{fhirJson.citeAsReference.display} {reference && <>(<a href={"/resources/" + reference} target="_blank" rel="noopener noreferrer">{reference}</a>)</>}</>
            :
            <>{fhirJson.citeAsMarkdown && fhirJson.citeAsMarkdown}</>
          }
        </p>
      </div>
      <h3 id="population">Population</h3>
      <div style={{ marginLeft: "24px" }}>
        <br />
        <p><b></b> {referencedResourceState.referencedGroup.name}</p>
      </div>
      <br />
      <InclusionExclusionCharacteristicTables editMode={editMode} smallCriteriaHeader={true} groupInclusionCharacteristics={referencedResourceState.groupInclusionCharacteristics} groupExclusionCharacteristics={referencedResourceState.groupExclusionCharacteristics} stateSetter={setFhirEntryState} changeState={changeFhirEntryState} groupCharacteristicKindOptions={groupCharacteristicKindOptions} setGroupCharacteristicKindOptions={setGroupCharacteristicKindOptions} />
      <div style={{ marginLeft: "24px" }}>
        <br />

        <b>Resource Reference</b>
        <div style={{ marginLeft: "24px" }}>
          {fhirJson.subjectReference?.display && <p>{fhirJson.subjectReference.display}</p>}
          {fhirJson.subjectReference?.reference && <p><a href={"/resources/" + fhirJson.subjectReference.reference} target="_blank" rel="noopener noreferrer"><b>Resource link {fhirJson.subjectReference.reference}</b></a></p>}
        </div>
      </div>
      <h3 id="action">Action</h3>
      <div style={{ marginLeft: "24px" }}>
        <p>TBD</p>
      </div>
      <h3 id="implementation">Implementation</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Summary: </b> {fhirJson.applicationDetail?.summary}</p>
        <p><b>Subgroup Considerations: </b> {fhirJson.applicationDetail?.subgroupConsiderations}</p>
        <p><b>Implementation Considerations: </b> {fhirJson.applicationDetail?.implementationConsiderations}</p>
        <p><b>Monitoring Considerations: </b> {fhirJson.applicationDetail?.monitoringConsiderations}</p>
      </div>
      <h3 id="evidence">Evidence</h3>
      <div style={{ marginLeft: "24px" }}>
        <div><b>Evidence Certainty:</b>
          <div style={{ marginLeft: "24px" }}>
            {fhirJson.evidenceCertainty?.length > 0 &&
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Rating</Table.HeaderCell>
                    <Table.HeaderCell>Rater</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Notes</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {fhirJson.evidenceCertainty.map((evidenceCertainty, index) => {
                    return <Table.Row key={index}>
                      <Table.Cell style={{ verticalAlign: "top" }}>{evidenceCertainty.type?.coding?.length > 0 && evidenceCertainty.type.coding[0].display}</Table.Cell>
                      <Table.Cell style={{ verticalAlign: "top" }}>{evidenceCertainty.rating?.coding?.length > 0 && evidenceCertainty.rating.coding[0].display}</Table.Cell>
                      <Table.Cell style={{ verticalAlign: "top" }}>{evidenceCertainty.rater}</Table.Cell>
                      <Table.Cell style={{ verticalAlign: "top" }}>{evidenceCertainty.description}</Table.Cell>
                      <Table.Cell style={{ verticalAlign: "top" }}>{(evidenceCertainty.note && Array.isArray(evidenceCertainty.note)) && evidenceCertainty.note.map((note, noteIndex) => {
                        return <span key={noteIndex}>{note.text}<br /></span>
                      })}</Table.Cell>
                    </Table.Row>
                  })}
                </Table.Body>
              </Table>
            }
          </div>
        </div>
        <br />
        <div><b>Evidence Detail:</b><br />
          <div style={{ marginLeft: "24px" }}>
            {(fhirJson.evidence && Array.isArray(fhirJson.evidence)) && fhirJson.evidence.map((evidence, index) => {
              return <p key={index}>{evidence.summary ? evidence.summary : evidence.resource?.display} <br />{evidence.url ? <a href={evidence.url} target="_blank" rel="noopener noreferrer">URL</a> : (evidence.resource?.reference) && <a href={"/resources/" + evidence.resource.reference} target="_blank" rel="noopener noreferrer">Evidence Resource</a>}</p>
            })}
          </div>
        </div>
      </div>
      <h3 id="justification">Justification</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Summary: </b> {fhirJson.derivationDetail?.justification}</p>
        <p><b>Net Benefit: </b> {fhirJson.derivationDetail?.netBenefit}</p>
        <p><b>Preferences: </b> {fhirJson.derivationDetail?.preferences}</p>
        <p><b>Resources: </b> {fhirJson.derivationDetail?.resources}</p>
        <p><b>Acceptability: </b> {fhirJson.derivationDetail?.acceptability}</p>
        <p><b>Equity: </b> {fhirJson.derivationDetail?.equity}</p>
        <p><b>Feasibility: </b> {fhirJson.derivationDetail?.feasibility}</p>
        <p><b>Competing Interests: </b> {fhirJson.derivationDetail?.competingInterests}</p>
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <p>TBD</p>
      </div>
      <h3 id="metadata">Metadata</h3>
    </div>
    <SimpleResourceFieldViewer resource={JSON.parse(fhirEntryState.fhirEntryString)} parentElement={""} />
  </div>
}

export default RecommendationDisplay;