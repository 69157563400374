import React, { useState, useEffect, useContext, memo } from 'react';
import { DataEntry, DatatypeSelector } from './DataEntryFormFunctions';
import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { Button } from 'semantic-ui-react';
import { TextField, Checkbox } from '@mui/material';
import SEVCO from './SEVCO';
import FevirContext from './FevirContext';
import { DisplayCharacteristicCombinationTable } from './CharacteristicResourceDisplay';
import submitToFevirServer from './SubmitToFevirServer';

const handleChange = (name, value, setResourceState) => {
  if (name.at(-1) === "]") {
    let nameSplit = name.split("[");
    setResourceState(prevState => {
      let newValue = prevState[nameSplit[0]].map((entry, entryIndex) => {
        if (entryIndex === parseInt(nameSplit[1])) {
          return value;
        } else {
          return entry;
        }
      })
      return { ...prevState, [nameSplit[0]]: newValue }
    });
  } else {
    setResourceState(prevState => { return { ...prevState, [name]: value } })
  }
}

const SearchPieceEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  dataEntryStyle, typeValueSet, classifierValueSet }) => {
  let startingSearchPiece = { type: "", classifier: [] };
  let startingTypeSpecificClassifierValues = [];
  if (!startingValue) {
    startingSearchPiece = {};
  } else {
    if (startingValue.extension) { startingSearchPiece.extension = startingValue.extension; }
    if (startingValue.type) {
      startingSearchPiece.type = startingValue.type;
      startingTypeSpecificClassifierValues = classifierValueSet[startingValue.type];
    }
    if (startingValue.classifier) { startingSearchPiece.classifier = startingValue.classifier; }
  }

  const [searchPieceState, setSearchPieceState] = useState(startingSearchPiece);
  const [typeSpecificClassifierValuesState, setTypeSpecificClassifierValuesState] = useState(startingTypeSpecificClassifierValues);

  useEffect((() => {
    if (Object.keys(searchPieceState).length) {
      let newSearchPiece = {};
      if (searchPieceState.extension) { newSearchPiece.extension = searchPieceState.extension; }
      if (searchPieceState.type) {
        newSearchPiece.type = searchPieceState.type;
        setTypeSpecificClassifierValuesState(classifierValueSet[newSearchPiece.type]);
      }
      if (searchPieceState.classifier && Array.isArray(searchPieceState.classifier) && searchPieceState.classifier.length > 0) {
        newSearchPiece.classifier = searchPieceState.classifier;
      }
      if (Object.keys(newSearchPiece).length === 0) {
        newSearchPiece = null;
      }
      handleChange(elementName, newSearchPiece, setResourceState);
    }
  }), [searchPieceState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='code' elementName='type' fieldLabel='Attribute Type'
        allowedValues={typeValueSet} dataEntryStyle='dropdownsearch'
        startingValue={searchPieceState.type} setResourceState={setSearchPieceState} />
      <DataEntry asArray={true} datatype='code' elementName='classifier' fieldLabel='Has Values Of'
        allowedValues={typeSpecificClassifierValuesState} dataEntryStyle='dropdownsearch'
        startingValue={searchPieceState.classifier || []} setResourceState={setSearchPieceState} />
    </div>
  </>
});

const ActivityDefinitionDynamicValueEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingDynamicValue = { path: "", expression: {} };
  if (!startingValue) {
    startingDynamicValue = {};
  } else {
    if (startingValue.extension) { startingDynamicValue.extension = startingValue.extension; }
    if (startingValue.path) { startingDynamicValue.path = startingValue.path; }
    if (startingValue.expression) { startingDynamicValue.expression = startingValue.expression; }
  }

  const [dynamicValueState, setDynamicValueState] = useState(startingDynamicValue);

  useEffect((() => {
    if (Object.keys(dynamicValueState).length) {
      let newDynamicValue = {};
      if (dynamicValueState.extension) { newDynamicValue.extension = dynamicValueState.extension; }
      if (dynamicValueState.path) { newDynamicValue.path = dynamicValueState.path; }
      if (dynamicValueState.expression && Object.keys(dynamicValueState.expression).length) {
        newDynamicValue.expression = dynamicValueState.expression;
      }
      if (Object.keys(newDynamicValue).length === 0) {
        newDynamicValue = null;
      }
      handleChange(elementName, newDynamicValue, setResourceState);
    }
  }), [dynamicValueState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='path' fieldLabel='Path'
        startingValue={dynamicValueState.path} setResourceState={setDynamicValueState} />
      <DataEntry datatype='Expression' elementName='expression' fieldLabel='Expression'
        startingValue={dynamicValueState.expression} setResourceState={setDynamicValueState} />
    </div>
  </>
});

const activityDefinitionDotParticipantDotTypeValues = ["careteam", "device", "group", "healthcareservice", "location", "organization", "patient", "practitioner", "practitionerrole", "relatedperson"];
const activityDefinitionDotParticipantDotTypeReferenceResourceTypes = ['CareTeam', 'Device', 'DeviceDefinition', 'Endpoint', 'Group', 'HealthcareService', 'Location', 'Organization', 'Patient', 'Practitioner', 'PractitionerRole', 'RelatedPerson']
const activityDefinitionDotParticipantDotRoleSystemChoices = [{ 'uri': 'http://terminology.hl7.org/CodeSystem/practitioner-role', 'display': 'HL7 FHIR Practitioner Role' }, { 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }, { 'uri': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode', 'display': 'HL7 V3 Role Code' }]
const activityDefinitionDotParticipantDotFunctionValueSet = [
  { system: "http://hl7.org/fhir/action-participant-function", code: "performer", display: "Perfomer" },
  { system: "http://hl7.org/fhir/action-participant-function", code: "author", display: "Author" },
  { system: "http://hl7.org/fhir/action-participant-function", code: "reviewer", display: "Reviewer" },
  { system: "http://hl7.org/fhir/action-participant-function", code: "witness", display: "Witness" }
]
const ActivityDefinitionParticipantEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingParticipant = { type: "", typeCanonical: "", typeReference: {}, role: {}, function: {} };
  if (!startingValue) {
    startingParticipant = {};
  } else {
    if (startingValue.extension) { startingParticipant.extension = startingValue.extension; }
    if (startingValue.type) { startingParticipant.type = startingValue.type; }
    if (startingValue.typeCanonical) { startingParticipant.typeCanonical = startingValue.typeCanonical; }
    if (startingValue.typeReference) { startingParticipant.typeReference = startingValue.typeReference; }
    if (startingValue.role) { startingParticipant.role = startingValue.role; }
    if (startingValue.function) { startingParticipant.function = startingValue.function; }
  }

  const [participantState, setParticipantState] = useState(startingParticipant);

  useEffect((() => {
    if (Object.keys(participantState).length) {
      let newParticipant = {};
      if (participantState.extension) { newParticipant.extension = participantState.extension; }
      if (participantState.type) { newParticipant.type = participantState.type; }
      if (participantState.typeCanonical) { newParticipant.typeCanonical = participantState.typeCanonical; }
      if (participantState.typeReference && Object.keys(participantState.typeReference).length) {
        newParticipant.typeReference = participantState.typeReference;
      }
      if (participantState.role && Object.keys(participantState.role).length) {
        newParticipant.role = participantState.role;
      }
      if (participantState.function && Object.keys(participantState.function).length) {
        newParticipant.function = participantState.function;
      }
      if (Object.keys(newParticipant).length === 0) {
        newParticipant = null;
      }
      handleChange(elementName, newParticipant, setResourceState);
    }
  }), [participantState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='code' elementName='type' fieldLabel='Participant type'
        allowedValues={activityDefinitionDotParticipantDotTypeValues}
        startingValue={participantState.type} setResourceState={setParticipantState} />
      <DataEntry datatype='uri' elementName='typeCanonical' fieldLabel='Participant type canonical'
        startingValue={participantState.typeCanonical} setResourceState={setParticipantState} />
      <DataEntry datatype='Reference' elementName='typeReference' fieldLabel='Participant type reference'
        referencedResourceTypes={activityDefinitionDotParticipantDotTypeReferenceResourceTypes}
        startingValue={participantState.typeReference} setResourceState={setParticipantState} />
      <DataEntry datatype='CodeableConcept' elementName='role' fieldLabel='Participant role'
        systemChoices={activityDefinitionDotParticipantDotRoleSystemChoices} systemChoicesOpen
        startingValue={participantState.role} setResourceState={setParticipantState} />
      <DataEntry datatype='CodeableConcept' elementName='function' fieldLabel='Participant function'
        valueSet={activityDefinitionDotParticipantDotFunctionValueSet}
        startingValue={participantState.function} setResourceState={setParticipantState} />
    </div>
  </>
})

const characteristicDefinitionByCombinationDotCodeValues = ['all-of', 'any-of', 'at-least', 'at-most', 'except-subset', 'statistical', 'net-effect', 'dataset'];
const CharacteristicDefinitionByCombinationEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingDefinitionByCombination = { code: "", threshold: "", characteristic: [] };
  if (!startingValue) {
    startingDefinitionByCombination = {};
  } else {
    if (startingValue.extension) { startingDefinitionByCombination.extension = startingValue.extension; }
    if (startingValue.code) { startingDefinitionByCombination.code = startingValue.code; }
    if (startingValue.threshold) { startingDefinitionByCombination.threshold = startingValue.threshold; }
    if (startingValue.characteristic) { startingDefinitionByCombination.characteristic = startingValue.characteristic; }
  }
  const globalContext = useContext(FevirContext);

  const [characteristicDefinitionByCombinationState, setCharacteristicDefinitionByCombinationState] = useState(startingDefinitionByCombination);

  useEffect((() => {
    if (Object.keys(characteristicDefinitionByCombinationState).length) {
      let newCharacteristicDefinitionByCombination = {};
      if (characteristicDefinitionByCombinationState.extension) { newCharacteristicDefinitionByCombination.extension = characteristicDefinitionByCombinationState.extension; }
      if (characteristicDefinitionByCombinationState.code) { newCharacteristicDefinitionByCombination.code = characteristicDefinitionByCombinationState.code; }
      if (characteristicDefinitionByCombinationState.threshold) { newCharacteristicDefinitionByCombination.threshold = characteristicDefinitionByCombinationState.threshold; }
      if (characteristicDefinitionByCombinationState.characteristic) { newCharacteristicDefinitionByCombination.characteristic = characteristicDefinitionByCombinationState.characteristic; }
      if (Object.keys(newCharacteristicDefinitionByCombination).length === 0) {
        newCharacteristicDefinitionByCombination = null;
      }
      handleChange(elementName, newCharacteristicDefinitionByCombination, setResourceState);
    }
  }), [characteristicDefinitionByCombinationState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='code' elementName='code' fieldLabel='Method of Combination'
        allowedValues={characteristicDefinitionByCombinationDotCodeValues}
        startingValue={characteristicDefinitionByCombinationState.code} setResourceState={setCharacteristicDefinitionByCombinationState} />
      <DataEntry datatype='positiveInt' elementName='threshold' fieldLabel='Threshold number (for at-least or at-most)'
        startingValue={characteristicDefinitionByCombinationState.threshold} setResourceState={setCharacteristicDefinitionByCombinationState} />
      {(characteristicDefinitionByCombinationState.characteristic &&
        Array.isArray(characteristicDefinitionByCombinationState.characteristic) &&
        characteristicDefinitionByCombinationState.characteristic.length > 0) &&
        <DisplayCharacteristicCombinationTable fhirJson={{
          definitionByCombination: {
            characteristic: characteristicDefinitionByCombinationState.characteristic
          }
        }} />
      }
      <DataEntry asArray={true} datatype='Reference' elementName='characteristic' fieldLabel='Characteristic'
        referencedResourceTypes={['Characteristic']} enableCreation={true}
        startingResourceType="Characteristic" startCollapsed={true} deletable={true}
        startingValue={characteristicDefinitionByCombinationState.characteristic} setResourceState={setCharacteristicDefinitionByCombinationState} />
    </div>
  </>
})

const CharacteristicExecutableExpressionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingExecutableExpression = { classifier: [], expression: "" };
  if (!startingValue) {
    startingExecutableExpression = {};
  } else {
    if (startingValue.extension) { startingExecutableExpression.extension = startingValue.extension; }
    if (startingValue.classifier) { startingExecutableExpression.classifier = startingValue.classifier; }
    if (startingValue.expression) { startingExecutableExpression.expression = startingValue.expression; }
  }

  const [characteristicExecutableExpressionState, setCharacteristicExecutableExpressionState] = useState(startingExecutableExpression);

  useEffect((() => {
    if (Object.keys(characteristicExecutableExpressionState).length) {
      let newCharacteristicExecutableExpression = {};
      if (characteristicExecutableExpressionState.extension) { newCharacteristicExecutableExpression.extension = characteristicExecutableExpressionState.extension; }
      if (characteristicExecutableExpressionState.classifier && Array.isArray(characteristicExecutableExpressionState.classifier) && characteristicExecutableExpressionState.classifier.length > 0) {
        newCharacteristicExecutableExpression.classifier = characteristicExecutableExpressionState.classifier;
      }
      if (characteristicExecutableExpressionState.expression && Object.keys(characteristicExecutableExpressionState.expression).length) { newCharacteristicExecutableExpression.expression = characteristicExecutableExpressionState.expression; }
      if (Object.keys(newCharacteristicExecutableExpression).length === 0) {
        newCharacteristicExecutableExpression = null;
      }
      handleChange(elementName, newCharacteristicExecutableExpression, setResourceState);
    }
  }), [characteristicExecutableExpressionState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='Expression' elementName='expression' fieldLabel='Expression'
        startingValue={characteristicExecutableExpressionState.expression} setResourceState={setCharacteristicExecutableExpressionState} />
      <DataEntry asArray={true} datatype='CodeableConcept' elementName='classifier' fieldLabel='Classifier'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={characteristicExecutableExpressionState.classifier} setResourceState={setCharacteristicExecutableExpressionState} />
    </div>
  </>
})

const characteristicDefinitionByTypeAndValueDotValueAllowedDatatypes = ['CodeableConcept', 'boolean', 'Quantity', 'Range', 'Reference', 'canonical', 'Expression'];
const CharacteristicDefinitionByTypeAndValueEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {

  let typeValueSet = [
    { system: "http://snomed.info/sct", code: "64572001", display: "Disease (disorder)" },
    { system: "http://snomed.info/sct", code: "260905004", display: "Condition" },
    { system: "http://snomed.info/sct", code: "246112005", display: "Severity" },
    { system: "http://snomed.info/sct", code: "397669002", display: "Age" },
    { system: "http://snomed.info/sct", code: "263495000", display: "Gender" },
    { system: "http://snomed.info/sct", code: "103579009", display: "Race" },
    { system: "http://snomed.info/sct", code: "186034007", display: "Ethnicity / related nationality data" },
    { system: "http://snomed.info/sct", code: "305335007", display: "Admission to establishment (procedure)" },
    { system: "http://snomed.info/sct", code: "246267002", display: "Location" },
    { system: "https://fevir.net/resources/CodeSystem/113876", code: "measurement-values", display: "Measurement values" },
    { system: "https://fevir.net/resources/CodeSystem/113876", code: "placeholder-for-exposure", display: "Exposure (intervention)" },
    { system: "https://fevir.net/resources/CodeSystem/113876", code: "medication-exposure-or-intervention", display: "Medication (exposure or intervention)" },
    { system: "https://fevir.net/resources/CodeSystem/113876", code: "placeholder-for-in-group-of", display: " In Group of	used for noting this is a subset of a previously defined group" },
    { system: "https://fevir.net/resources/CodeSystem/113876", code: "research-study-from-which-this-is-the-observed-sample", display: "Research Study from which this is the observed sample" },
  ];

  let typeSpecificValueSets = {
    "64572001": [ //Disease (disorder)
      { system: "http://snomed.info/sct", code: "840539006", display: "COVID-19" },
      { system: "http://snomed.info/sct", code: "840544004", display: "Suspected COVID-19" },
      { system: "http://snomed.info/sct", code: "197663003", display: "Impaired renal function disorder (disorder)" },
      { system: "http://snomed.info/sct", code: "64779008", display: "Coagulopathy" },
      { system: "http://snomed.info/sct", code: "371039008", display: "Thromboembolic disorder" }
    ],
    "260905004": [ //Condition
      { system: "http://snomed.info/sct", code: "77386006", display: "Pregnant (finding)" }
    ],
    "246112005": [ //Severity
      { system: "http://snomed.info/sct", code: "255604002", display: "Mild" },
      { system: "http://snomed.info/sct", code: "6736007", display: "Moderate" },
      { system: "http://snomed.info/sct", code: "24484000", display: "Severe" },
      { system: "http://snomed.info/sct", code: "371923003", display: "Mild to moderate" },
      { system: "http://snomed.info/sct", code: "371924009", display: "Moderate to severe" },
      { system: "http://snomed.info/sct", code: "442452003", display: "Life threatening severity" },
    ],
    "305335007": [ //Admission to establishment (procedure)
      { system: "http://snomed.info/sct", code: "32485007", display: "Hospital admission" }
    ]
  };
  let startingTypeSpecificValueSet;

  let addDevice = false;
  let addOffset = false;
  let addCalculatedAs = false;
  let startingDefinitionByTypeAndValue = {
    type: {}, method: null, device: {}, calculatedAs: {}, valueCodeableConcept: {}, valueBoolean: "",
    valueQuantity: {}, valueRange: {}, valueReference: {}, valueCanonical: "", valueExpression: {}, offset: {}
  };
  let startingCharacteristicDefinitionByTypeAndValueDotValueDatatype = 'none';
  if (!startingValue) {
    startingDefinitionByTypeAndValue = {};
  } else {
    if (startingValue.extension) { startingDefinitionByTypeAndValue.extension = startingValue.extension; }
    if (startingValue.type) {
      startingDefinitionByTypeAndValue.type = startingValue.type;
      if (typeSpecificValueSets) {
        try {
          let typeKey = startingValue.type.coding[0].code;
          startingTypeSpecificValueSet = typeSpecificValueSets[typeKey]
        } catch { }
      }
    }
    if (startingValue.method) { startingDefinitionByTypeAndValue.method = startingValue.method; }
    if (startingValue.device) {
      startingDefinitionByTypeAndValue.device = startingValue.device;
      addDevice = true;
    }
    if (startingValue.calculatedAs) {
      startingDefinitionByTypeAndValue.calculatedAs = startingValue.calculatedAs;
      addCalculatedAs = true;
    }
    if (startingValue.valueCodeableConcept) {
      startingDefinitionByTypeAndValue.valueCodeableConcept = startingValue.valueCodeableConcept;
      startingCharacteristicDefinitionByTypeAndValueDotValueDatatype = 'CodeableConcept';
    }
    if (typeof startingValue.valueBoolean === "boolean") {
      startingDefinitionByTypeAndValue.valueBoolean = startingValue.valueBoolean;
      startingCharacteristicDefinitionByTypeAndValueDotValueDatatype = 'boolean';
    }
    if (startingValue.valueQuantity) {
      startingDefinitionByTypeAndValue.valueQuantity = startingValue.valueQuantity;
      startingCharacteristicDefinitionByTypeAndValueDotValueDatatype = 'Quantity';
    }
    if (startingValue.valueRange) {
      startingDefinitionByTypeAndValue.valueRange = startingValue.valueRange;
      startingCharacteristicDefinitionByTypeAndValueDotValueDatatype = 'Range';
    }
    if (startingValue.valueReference) {
      startingDefinitionByTypeAndValue.valueReference = startingValue.valueReference;
      startingCharacteristicDefinitionByTypeAndValueDotValueDatatype = 'Reference';
    }
    if (startingValue.valueCanonical) {
      startingDefinitionByTypeAndValue.valueCanonical = startingValue.valueCanonical;
      startingCharacteristicDefinitionByTypeAndValueDotValueDatatype = 'canonical';
    }
    if (startingValue.valueExpression) {
      startingDefinitionByTypeAndValue.valueExpression = startingValue.valueExpression;
      startingCharacteristicDefinitionByTypeAndValueDotValueDatatype = 'Expression';
    }
    if (startingValue.offset) {
      startingDefinitionByTypeAndValue.offset = startingValue.offset;
      addOffset = true;
    }
  }

  const [characteristicDefinitionByTypeAndValueState, setCharacteristicDefinitionByTypeAndValueState] = useState(startingDefinitionByTypeAndValue);

  const [typeSpecificValueSetState, setTypeSpecificValueSetState] = useState(startingTypeSpecificValueSet);
  const [characteristicDefinitionByTypeAndValueDotValueDatatypeState, setCharacteristicDefinitionByTypeAndValueDotValueDatatypeState] = useState(startingCharacteristicDefinitionByTypeAndValueDotValueDatatype);

  const [addDeviceState, setAddDeviceState] = useState(addDevice);
  const [addOffsetState, setAddOffsetState] = useState(addOffset);
  const [addCalculatedAsState, setAddCalculatedAsState] = useState(addCalculatedAs);

  useEffect((() => {
    if (characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'CodeableConcept') {
      setCharacteristicDefinitionByTypeAndValueState(prevState => {
        return {
          ...prevState, 'valueReference': null,
          'valueCanonical': null, 'valueQuantity': null, 'valueRange': null, 'valueBoolean': null, 'valueExpression': null
        }
      })
    }
    if (characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'Reference') {
      setCharacteristicDefinitionByTypeAndValueState(prevState => {
        return {
          ...prevState, 'valueCodeableConcept': null,
          'valueCanonical': null, 'valueQuantity': null, 'valueRange': null, 'valueBoolean': null, 'valueExpression': null
        }
      })
    }
    if (characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'canonical') {
      setCharacteristicDefinitionByTypeAndValueState(prevState => {
        return {
          ...prevState, 'valueReference': null,
          'valueCodeableConcept': null, 'valueQuantity': null, 'valueRange': null, 'valueBoolean': null, 'valueExpression': null
        }
      })
    }
    if (characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'Quantity') {
      setCharacteristicDefinitionByTypeAndValueState(prevState => {
        return {
          ...prevState, 'valueReference': null,
          'valueCodeableConcept': null, 'valueCanonical': null, 'valueRange': null, 'valueBoolean': null, 'valueExpression': null
        }
      })
    }
    if (characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'Range') {
      setCharacteristicDefinitionByTypeAndValueState(prevState => {
        return {
          ...prevState, 'valueReference': null,
          'valueCodeableConcept': null, 'valueCanonical': null, 'valueQuantity': null, 'valueBoolean': null, 'valueExpression': null
        }
      })
    }
    if (characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'boolean') {
      setCharacteristicDefinitionByTypeAndValueState(prevState => {
        return {
          ...prevState, 'valueReference': null,
          'valueCodeableConcept': null, 'valueCanonical': null, 'valueQuantity': null, 'valueRange': null, 'valueExpression': null
        }
      })
    }
    if (characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'Expression') {
      setCharacteristicDefinitionByTypeAndValueState(prevState => {
        return {
          ...prevState, 'valueReference': null,
          'valueCodeableConcept': null, 'valueCanonical': null, 'valueQuantity': null, 'valueRange': null, 'valueBoolean': null
        }
      })
    }
  }), [characteristicDefinitionByTypeAndValueDotValueDatatypeState]);


  useEffect((() => {
    if (Object.keys(characteristicDefinitionByTypeAndValueState).length) {
      let newCharacteristicDefinitionByTypeAndValue = {};
      if (characteristicDefinitionByTypeAndValueState.extension) { newCharacteristicDefinitionByTypeAndValue.extension = characteristicDefinitionByTypeAndValueState.extension; }
      if (characteristicDefinitionByTypeAndValueState.type && Object.keys(characteristicDefinitionByTypeAndValueState.type).length) {
        newCharacteristicDefinitionByTypeAndValue.type = characteristicDefinitionByTypeAndValueState.type;
        if (typeSpecificValueSets) {
          try {
            let typeKey = characteristicDefinitionByTypeAndValueState.type.coding[0].code;
            setTypeSpecificValueSetState(typeSpecificValueSets[typeKey]);
          } catch { }
        }
      }
      if (characteristicDefinitionByTypeAndValueState.method !== null &&
        characteristicDefinitionByTypeAndValueState.method !== undefined &&
        Array.isArray(characteristicDefinitionByTypeAndValueState.method) &&
        characteristicDefinitionByTypeAndValueState.method.length !== 0 &&
        !(characteristicDefinitionByTypeAndValueState.method.length === 1 && (typeof characteristicDefinitionByTypeAndValueState.method[0] === "object" && Object.keys(characteristicDefinitionByTypeAndValueState.method[0]).length === 0))) {
        newCharacteristicDefinitionByTypeAndValue.method = characteristicDefinitionByTypeAndValueState.method;
      }
      if (characteristicDefinitionByTypeAndValueState.device && Object.keys(characteristicDefinitionByTypeAndValueState.device).length > 0) {
        newCharacteristicDefinitionByTypeAndValue.device = characteristicDefinitionByTypeAndValueState.device;
      }
      if (characteristicDefinitionByTypeAndValueState.calculatedAs && Object.keys(characteristicDefinitionByTypeAndValueState.calculatedAs).length > 0) {
        newCharacteristicDefinitionByTypeAndValue.calculatedAs = characteristicDefinitionByTypeAndValueState.calculatedAs;
      }
      if (characteristicDefinitionByTypeAndValueState.valueCodeableConcept && Object.keys(characteristicDefinitionByTypeAndValueState.valueCodeableConcept).length > 0) {
        newCharacteristicDefinitionByTypeAndValue.valueCodeableConcept = characteristicDefinitionByTypeAndValueState.valueCodeableConcept;
      }
      if (typeof characteristicDefinitionByTypeAndValueState.valueBoolean === "boolean") {
        newCharacteristicDefinitionByTypeAndValue.valueBoolean = characteristicDefinitionByTypeAndValueState.valueBoolean;
      }
      if (characteristicDefinitionByTypeAndValueState.valueQuantity && Object.keys(characteristicDefinitionByTypeAndValueState.valueQuantity).length > 0) {
        newCharacteristicDefinitionByTypeAndValue.valueQuantity = characteristicDefinitionByTypeAndValueState.valueQuantity;
      }
      if (characteristicDefinitionByTypeAndValueState.valueRange && Object.keys(characteristicDefinitionByTypeAndValueState.valueRange).length > 0) {
        newCharacteristicDefinitionByTypeAndValue.valueRange = characteristicDefinitionByTypeAndValueState.valueRange;
      }
      if (characteristicDefinitionByTypeAndValueState.valueReference && Object.keys(characteristicDefinitionByTypeAndValueState.valueReference).length > 0) {
        newCharacteristicDefinitionByTypeAndValue.valueReference = characteristicDefinitionByTypeAndValueState.valueReference;
      }
      if (characteristicDefinitionByTypeAndValueState.valueCanonical) { newCharacteristicDefinitionByTypeAndValue.valueCanonical = characteristicDefinitionByTypeAndValueState.valueCanonical; }
      if (characteristicDefinitionByTypeAndValueState.valueExpression && Object.keys(characteristicDefinitionByTypeAndValueState.valueExpression).length > 0) {
        newCharacteristicDefinitionByTypeAndValue.valueExpression = characteristicDefinitionByTypeAndValueState.valueExpression;
      }
      if (characteristicDefinitionByTypeAndValueState.offset && Object.keys(characteristicDefinitionByTypeAndValueState.offset).length > 0) {
        newCharacteristicDefinitionByTypeAndValue.offset = characteristicDefinitionByTypeAndValueState.offset;
      }
      if (Object.keys(newCharacteristicDefinitionByTypeAndValue).length === 0) {
        newCharacteristicDefinitionByTypeAndValue = null;
      }
      handleChange(elementName, newCharacteristicDefinitionByTypeAndValue, setResourceState);
    }
  }), [characteristicDefinitionByTypeAndValueState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Attribute' valueSet={typeValueSet}
        startingValue={characteristicDefinitionByTypeAndValueState.type} setResourceState={setCharacteristicDefinitionByTypeAndValueState} />
      <DataEntry asArray={true} datatype='CodeableConcept' elementName='method'
        fieldLabel='Method (for determination)'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={characteristicDefinitionByTypeAndValueState.method}
        setResourceState={setCharacteristicDefinitionByTypeAndValueState} />
      {addDeviceState === false && <div style={{ marginLeft: "24px" }}><br />
        <Button className="formButton" style={{ color: "#000000" }}
          content={"+ Add Device (used for determination)"}
          onClick={() => { setAddDeviceState(true) }} />
      </div>}
      {addDeviceState === true &&
        <DataEntry datatype='Reference' elementName='device' fieldLabel='Device (used for determination)'
          referencedResourceTypes={['Device', 'DeviceMetric']}
          startingValue={characteristicDefinitionByTypeAndValueState.device} setResourceState={setCharacteristicDefinitionByTypeAndValueState} />}
      {addCalculatedAsState === false && <div style={{ marginLeft: "24px" }}><br />
        <Button className="formButton" style={{ color: "#000000" }}
          content={"+ Add Expression/formula (how the attribute value is determined)"}
          onClick={() => { setAddCalculatedAsState(true) }} />
      </div>}
      {addCalculatedAsState === true &&
        <DataEntry datatype='Expression' elementName='calculatedAs' fieldLabel='Expression/formula (how the attribute value is determined)'
          startingValue={characteristicDefinitionByTypeAndValueState.calculatedAs} setResourceState={setCharacteristicDefinitionByTypeAndValueState} />}
      <br /> <br />
      <p style={{ marginBottom: "0px" }}><b>Value Specification</b></p>
      <div style={{ marginLeft: "24px" }}>
        <DatatypeSelector elementXName='value[x]' allowedDatatypes={characteristicDefinitionByTypeAndValueDotValueAllowedDatatypes}
          datatypeState={characteristicDefinitionByTypeAndValueDotValueDatatypeState} setDatatypeState={setCharacteristicDefinitionByTypeAndValueDotValueDatatypeState} />
        {characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'CodeableConcept' &&
          <DataEntry datatype='CodeableConcept' elementName='valueCodeableConcept' fieldLabel='Value as CodeableConcept'
            startingValue={characteristicDefinitionByTypeAndValueState.valueCodeableConcept}
            valueSet={typeSpecificValueSetState}
            setResourceState={setCharacteristicDefinitionByTypeAndValueState} />}
        {characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'boolean' &&
          <DataEntry datatype='boolean' elementName='valueBoolean' fieldLabel='Value as Boolean'
            storeFalse={true}
            startingValue={characteristicDefinitionByTypeAndValueState.valueBoolean}
            setResourceState={setCharacteristicDefinitionByTypeAndValueState} />}
        {characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'Quantity' &&
          <DataEntry datatype='Quantity' elementName='valueQuantity' fieldLabel='Value as Quantity'
            startingValue={characteristicDefinitionByTypeAndValueState.valueQuantity}
            setResourceState={setCharacteristicDefinitionByTypeAndValueState} />}
        {characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'Range' &&
          <DataEntry datatype='Range' elementName='valueRange' fieldLabel='Value as Range'
            startingValue={characteristicDefinitionByTypeAndValueState.valueRange}
            setResourceState={setCharacteristicDefinitionByTypeAndValueState} />}
        {characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'Reference' &&
          <DataEntry datatype='Reference' elementName='valueReference' fieldLabel='Value as Reference'
            startingValue={characteristicDefinitionByTypeAndValueState.valueReference}
            enableCreation={true}
            setResourceState={setCharacteristicDefinitionByTypeAndValueState} />}
        {characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'canonical' &&
          <DataEntry datatype='uri' elementName='valueCanonical' fieldLabel='Value as Canonical URL'
            startingValue={characteristicDefinitionByTypeAndValueState.valueCanonical}
            setResourceState={setCharacteristicDefinitionByTypeAndValueState} />}
        {characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'Expression' &&
          <DataEntry datatype='Expression' elementName='valueExpression' fieldLabel='Value as Expression'
            startingValue={characteristicDefinitionByTypeAndValueState.valueExpression}
            setResourceState={setCharacteristicDefinitionByTypeAndValueState} />}
        <br /><br />
        {(addOffsetState === false &&
          (characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'Quantity' || characteristicDefinitionByTypeAndValueDotValueDatatypeState === 'Range')) &&
          <div style={{ marginLeft: "24px" }}>
            <Button className="formButton" style={{ color: "#000000" }}
              content={"+ Add Offset"}
              onClick={() => { setAddOffsetState(true) }} />
          </div>}
        {addOffsetState === true &&
          <DataEntry datatype='CodeableConcept' elementName='offset' fieldLabel='Offset'
            startingValue={characteristicDefinitionByTypeAndValueState.offset} setResourceState={setCharacteristicDefinitionByTypeAndValueState} />}
      </div>
    </div>
  </>
})

const CharacteristicTimeFromEventDotEventAllowedDatatypes = ['CodeableConcept', 'Reference', 'dateTime'];
const CharacteristicTimeFromEventEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingTimeFromEvent = {
    description: "", note: [], eventCodeableConcept: {}, eventReference: {}, eventDateTime: "", quantity: {}, range: {}
  };
  let startingCharacteristicTimeFromEventDotEventDatatype = 'none';
  if (!startingValue) {
    startingTimeFromEvent = {};
  } else {
    if (startingValue.extension) { startingTimeFromEvent.extension = startingValue.extension; }
    if (startingValue.description) { startingTimeFromEvent.description = startingValue.description; }
    if (startingValue.note) { startingTimeFromEvent.note = startingValue.note; }
    if (startingValue.eventCodeableConcept) {
      startingTimeFromEvent.eventCodeableConcept = startingValue.eventCodeableConcept;
      startingCharacteristicTimeFromEventDotEventDatatype = 'CodeableConcept';
    }
    if (startingValue.eventReference) {
      startingTimeFromEvent.eventReference = startingValue.eventReference;
      startingCharacteristicTimeFromEventDotEventDatatype = 'Reference';
    }
    if (startingValue.eventDateTime) {
      startingTimeFromEvent.eventDateTime = startingValue.eventDateTime;
      startingCharacteristicTimeFromEventDotEventDatatype = 'dateTime';
    }
    if (startingValue.quantity) { startingTimeFromEvent.quantity = startingValue.quantity; }
    if (startingValue.range) { startingTimeFromEvent.range = startingValue.range; }
  }

  const [characteristicTimeFromEventState, setCharacteristicTimeFromEventState] = useState(startingTimeFromEvent);

  const [characteristicTimeFromEventDotEventDatatypeState, setCharacteristicTimeFromEventDotEventDatatypeState] = useState(startingCharacteristicTimeFromEventDotEventDatatype);

  useEffect((() => {
    if (characteristicTimeFromEventDotEventDatatypeState === 'CodeableConcept') {
      setCharacteristicTimeFromEventState(prevState => { return { ...prevState, 'eventReference': null, 'eventDateTime': null } })
    }
    if (characteristicTimeFromEventDotEventDatatypeState === 'Reference') {
      setCharacteristicTimeFromEventState(prevState => { return { ...prevState, 'eventCodeableConcept': null, 'eventDateTime': null } })
    }
    if (characteristicTimeFromEventDotEventDatatypeState === 'dateTime') {
      setCharacteristicTimeFromEventState(prevState => { return { ...prevState, 'eventReference': null, 'eventCodeableConcept': null } })
    }
  }), [characteristicTimeFromEventDotEventDatatypeState]);


  useEffect((() => {
    if (Object.keys(characteristicTimeFromEventState).length) {
      let newCharacteristicTimeFromEvent = {};
      if (characteristicTimeFromEventState.extension) { newCharacteristicTimeFromEvent.extension = characteristicTimeFromEventState.extension; }
      if (characteristicTimeFromEventState.description) { newCharacteristicTimeFromEvent.description = characteristicTimeFromEventState.description; }
      if (characteristicTimeFromEventState.note && Array.isArray(characteristicTimeFromEventState.note) && characteristicTimeFromEventState.note.length) {
        newCharacteristicTimeFromEvent.note = characteristicTimeFromEventState.note;
      }
      if (characteristicTimeFromEventDotEventDatatypeState === 'CodeableConcept' && characteristicTimeFromEventState.eventCodeableConcept && Object.keys(characteristicTimeFromEventState.eventCodeableConcept).length) {
        newCharacteristicTimeFromEvent.eventCodeableConcept = characteristicTimeFromEventState.eventCodeableConcept;
      }
      if (characteristicTimeFromEventDotEventDatatypeState === 'Reference' && characteristicTimeFromEventState.eventReference && Object.keys(characteristicTimeFromEventState.eventReference).length) {
        newCharacteristicTimeFromEvent.eventReference = characteristicTimeFromEventState.eventReference;
      }
      if (characteristicTimeFromEventDotEventDatatypeState === 'dateTime' && characteristicTimeFromEventState.eventDateTime) {
        newCharacteristicTimeFromEvent.eventDateTime = characteristicTimeFromEventState.eventDateTime;
      }
      if (characteristicTimeFromEventState.quantity && Object.keys(characteristicTimeFromEventState.quantity).length) {
        newCharacteristicTimeFromEvent.quantity = characteristicTimeFromEventState.quantity;
      }
      if (characteristicTimeFromEventState.range && Object.keys(characteristicTimeFromEventState.range).length) {
        newCharacteristicTimeFromEvent.range = characteristicTimeFromEventState.range;
      }
      if (Object.keys(newCharacteristicTimeFromEvent).length === 0) {
        newCharacteristicTimeFromEvent = null;
      }
      handleChange(elementName, newCharacteristicTimeFromEvent, setResourceState);
    }
  }), [characteristicTimeFromEventState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={characteristicTimeFromEventState.description} setResourceState={setCharacteristicTimeFromEventState} />
      <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={characteristicTimeFromEventState.note} setResourceState={setCharacteristicTimeFromEventState} />
      <DatatypeSelector elementXName='event[x]' allowedDatatypes={CharacteristicTimeFromEventDotEventAllowedDatatypes}
        datatypeState={characteristicTimeFromEventDotEventDatatypeState} setDatatypeState={setCharacteristicTimeFromEventDotEventDatatypeState} />
      {characteristicTimeFromEventDotEventDatatypeState === 'CodeableConcept' &&
        <DataEntry datatype='CodeableConcept' elementName='eventCodeableConcept' fieldLabel='Event as CodeableConcept'
          startingValue={characteristicTimeFromEventState.eventCodeableConcept}
          setResourceState={setCharacteristicTimeFromEventState} />}
      {characteristicTimeFromEventDotEventDatatypeState === 'Reference' &&
        <DataEntry datatype='Reference' elementName='eventReference' fieldLabel='Event as Reference'
          startingValue={characteristicTimeFromEventState.eventReference} enableCreation={true}
          setResourceState={setCharacteristicTimeFromEventState} />}
      {characteristicTimeFromEventDotEventDatatypeState === 'dateTime' &&
        <DataEntry datatype='dateTime' elementName='eventDateTime' fieldLabel='Event as Date/Time'
          startingValue={characteristicTimeFromEventState.eventDateTime}
          setResourceState={setCharacteristicTimeFromEventState} />}
      <DataEntry datatype='Quantity' elementName='quantity' fieldLabel='Quantity'
        startingValue={characteristicTimeFromEventState.quantity} setResourceState={setCharacteristicTimeFromEventState} />
      <DataEntry datatype='Range' elementName='range' fieldLabel='Range'
        startingValue={characteristicTimeFromEventState.range} setResourceState={setCharacteristicTimeFromEventState} />
    </div>
  </>
})

const CodeSystemPropertyEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingProperty = { "code": "", "uri": "", "description": "", "type": "" }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingProperty.extension = startingValue.extension; }
    if (startingValue.code) { startingProperty.code = startingValue.code; }
    if (startingValue.uri) { startingProperty.uri = startingValue.uri; }
    if (startingValue.description) { startingProperty.description = startingValue.description; }
    if (startingValue.type) { startingProperty.type = startingValue.type; }
  }

  const [codeSystemPropertyState, setCodeSystemPropertyState] = useState(startingProperty);

  useEffect((() => {
    if (Object.keys(codeSystemPropertyState).length > 0) {
      let newProperty = {};
      if (codeSystemPropertyState.extension) { newProperty.extension = codeSystemPropertyState.extension; }
      if (codeSystemPropertyState.code) { newProperty.code = codeSystemPropertyState.code; }
      if (codeSystemPropertyState.uri) { newProperty.uri = codeSystemPropertyState.uri; }
      if (codeSystemPropertyState.description) { newProperty.description = codeSystemPropertyState.description; }
      if (codeSystemPropertyState.type) { newProperty.type = codeSystemPropertyState.type; }
      if (Object.keys(newProperty).length === 0) {
        newProperty = null;
      }
      handleChange(elementName, newProperty, setResourceState);
    }
  }), [codeSystemPropertyState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='code' fieldLabel='Code'
        startingValue={codeSystemPropertyState.code} setResourceState={setCodeSystemPropertyState} />
      <DataEntry datatype='uri' elementName='uri' fieldLabel='Formal identifier'
        startingValue={codeSystemPropertyState.uri} setResourceState={setCodeSystemPropertyState} />
      <DataEntry datatype='string' elementName='description' fieldLabel='Description'
        startingValue={codeSystemPropertyState.description} setResourceState={setCodeSystemPropertyState} />
      <DataEntry datatype='code' elementName='type' fieldLabel='Datatype'
        allowedValues={["code", "Coding", "string", "integer", "boolean", "dateTime", "decimal"]}
        startingValue={codeSystemPropertyState.type} setResourceState={setCodeSystemPropertyState} />
    </div>
  </>
})

const languageCodes = ['ar', 'bg', 'bn', 'cs', 'bs', 'da', 'de', 'de-AT', 'de-CH', 'de-DE', 'el',
  'en', 'en-AU', 'en-CA', 'en-GB', 'en-IN', 'en-NZ', 'en-SG', 'en-US', 'es', 'es-AR', 'es-ES', 'es-UY',
  'et', 'fi', 'fr', 'fr-BE', 'fr-CH', 'fr-FR', 'fr-CA', 'fy',
  'hi', 'hr', 'is', 'it', 'it-CH', 'it-IT', 'ja', 'ko', 'lt', 'lv', 'nl', 'nl-BE', 'nl-NL',
  'no', 'pa', 'pl', 'pt', 'pt-PT', 'pt-BR', 'ro', 'ru', 'sk',
  'sl', 'sr', 'sv', 'sv-FI', 'sv-SE', 'te', 'zh', 'zh-CN', 'zh-HK', 'zh-SG', 'zh-TW'];
const CodeSystemConceptDesignationEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingDesignation = {
    "language": "",
    "use": {
      "system": "http://snomed.info/sct",
      "code": "900000000000013009",
      "display": "Synonym (core metadata concept)"
    },
    "value": ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingDesignation.extension = startingValue.extension; }
    if (startingValue.language) { startingDesignation.language = startingValue.language; }
    if (startingValue.use) { startingDesignation.use = startingValue.use; }
    if (startingValue.value) { startingDesignation.value = startingValue.value; }
  }
  if (startingDesignation.use.code === "900000000000003001") {
    startingDesignation.primaryTerm = true;
  }

  const [codeSystemConceptDesignationState, setCodeSystemConceptDesignationState] = useState(startingDesignation);
  const [addLanguageState, setAddLanguageState] = useState(startingDesignation.language ? true : false);

  useEffect((() => {
    if (Object.keys(codeSystemConceptDesignationState).length > 0) {
      let newDesignation = {};
      if (codeSystemConceptDesignationState.extension) { newDesignation.extension = codeSystemConceptDesignationState.extension; }
      if (codeSystemConceptDesignationState.language) { newDesignation.language = codeSystemConceptDesignationState.language; }
      if (codeSystemConceptDesignationState.use) { newDesignation.use = codeSystemConceptDesignationState.use; }
      if (codeSystemConceptDesignationState.value) { newDesignation.value = codeSystemConceptDesignationState.value; }
      if (codeSystemConceptDesignationState.primaryTerm) {
        newDesignation.use = {
          "system": "http://snomed.info/sct",
          "code": "900000000000003001",
          "display": "Fully specified name"
        }
      } else if (codeSystemConceptDesignationState.primaryTerm === false) {
        newDesignation.use = {
          "system": "http://snomed.info/sct",
          "code": "900000000000013009",
          "display": "Synonym (core metadata concept)"
        }
      }
      if (Object.keys(newDesignation).length === 0) {
        newDesignation = null;
      }
      handleChange(elementName, newDesignation, setResourceState);
    }
  }), [codeSystemConceptDesignationState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='value' fieldLabel='Alternative term'
        startingValue={codeSystemConceptDesignationState.value} setResourceState={setCodeSystemConceptDesignationState} />
      <span onClick={() => { setAddLanguageState(!addLanguageState) }} >
        {addLanguageState ?
          <>Collapse language</> :
          <>{codeSystemConceptDesignationState.language ? <>Edit language</> : <>Add language</>}</>
        }
      </span>
      {addLanguageState && <>
        <DataEntry datatype='code' elementName='language' fieldLabel='Language Code (See https://build.fhir.org/valueset-languages.html)'
          allowedValues={languageCodes}
          startingValue={codeSystemConceptDesignationState.language} setResourceState={setCodeSystemConceptDesignationState} />
        {codeSystemConceptDesignationState.language &&
          <DataEntry datatype='boolean' elementName='primaryTerm' fieldLabel='Primary Term?'
            startingValue={codeSystemConceptDesignationState.primaryTerm} setResourceState={setCodeSystemConceptDesignationState} />}
      </>}
    </div>
  </>
})

const CompositionTableCellEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  dataEntryStyle, addTableRowModalState, setSourceJsonState }) => {
  let startingTableCell = {
    "title": "",
    "code": "",
    "textStatus": "",
    "textDiv": "",
    "entry": []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.title) { startingTableCell.title = startingValue.title; }
    if (startingValue.code) { startingTableCell.code = startingValue.code; }
    if (startingValue.text) {
      startingTableCell.textStatus = startingValue.text.status;
      startingTableCell.textDiv = startingValue.text.div;
    }
    if (startingValue.entry) { startingTableCell.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingTableCell.emptyReason = startingValue.emptyReason; }
  }
  if (dataEntryStyle === "SummaryOfFindings") {
    if (startingValue.code.coding?.[0]?.code === "EvidenceVariable-outcome" && addTableRowModalState) {
      if (addTableRowModalState.newRowTitle) {
        startingTableCell.textStatus = "generated";
        startingTableCell.textDiv = addTableRowModalState.newRowTitle;
      }
      if (addTableRowModalState.newRowFocus) {
        startingTableCell.entry = [addTableRowModalState.newRowFocus];
      }
    }
    if ((startingTableCell.textStatus === "" || startingTableCell.textStatus === "empty") &&
      !startingTableCell.textDiv && startingTableCell.entry.length === 0 && !startingTableCell.emptyReason) {
      startingTableCell.emptyReason = {
        "coding": [{
          "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
          "code": "notstarted",
          "display": "Not Started"
        }]
      }
    }
  }

  const [compositionTableCellState, setCompositionTableCellState] = useState(startingTableCell);

  useEffect((() => {
    if (Object.keys(compositionTableCellState).length > 0) {
      let newTableCell = {};
      if (compositionTableCellState.title) { newTableCell.title = compositionTableCellState.title; }
      if (compositionTableCellState.code) { newTableCell.code = compositionTableCellState.code; }
      if (compositionTableCellState.textDiv) {
        newTableCell.text = {
          "status": "additional",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + compositionTableCellState.textDiv + "</div>"
        }
      } else {
        newTableCell.text = {
          "status": "empty",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
        }
      }
      if (compositionTableCellState.entry && Array.isArray(compositionTableCellState.entry) &&
        compositionTableCellState.entry.length > 0) {
        newTableCell.entry = compositionTableCellState.entry;
      }
      if ((!compositionTableCellState.textDiv || compositionTableCellState.textDiv === "[No data]") &&
        (!compositionTableCellState.entry || compositionTableCellState.entry.length === 0)) {
        newTableCell.text.status = "empty";
        if (compositionTableCellState.emptyReason && Object.keys(compositionTableCellState.emptyReason).length > 0) {
          newTableCell.emptyReason = compositionTableCellState.emptyReason;
        } else {
          newTableCell.emptyReason = { "text": "[Data deleted]" };
        }
      } else if (newTableCell.emptyReason) {
        delete newTableCell.emptyReason;
      }
      if (Object.keys(newTableCell).length === 0) {
        newTableCell = null;
      }
      handleChange(elementName, newTableCell, setResourceState);
    }
  }), [compositionTableCellState]);

  let emptyReasonValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }
  ];

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='markdown' elementName='textDiv' fieldLabel='Narrative Summary'
        startingValue={compositionTableCellState.textDiv} setResourceState={setCompositionTableCellState} />
      <DataEntry asArray={true} datatype='Reference' elementName='entry'
        fieldLabel='Source Content (as Resource Reference)' enableCreation={true}
        setSourceJsonState={setSourceJsonState}
        startingValue={compositionTableCellState.entry} setResourceState={setCompositionTableCellState} />
      {(dataEntryStyle === "SummaryOfFindings" &&
        (!compositionTableCellState.textDiv || compositionTableCellState.textDiv === "[No data]") &&
        (!compositionTableCellState.entry || compositionTableCellState.entry.length === 0)) && <>
          <br />
          <p><b>Explain why this Table Cell is empty</b></p>
          <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
            startingValue={compositionTableCellState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
            setResourceState={setCompositionTableCellState} />
        </>}
      <br />
    </div>
  </>
})

const EvidenceStatisticEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingStatistic = {
    description: "", note: [], statisticType: "", category: "", quantity: "", numberOfEvents: "",
    numberAffected: "", sampleSize: "", attributeEstimate: [], modelCharacteristic: [], analysisPlan: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingStatistic.extension = startingValue.extension; }
    if (startingValue.description) { startingStatistic.description = startingValue.description; }
    if (startingValue.note) { startingStatistic.note = startingValue.note; }
    if (startingValue.statisticType) { startingStatistic.statisticType = startingValue.statisticType; }
    if (startingValue.category) { startingStatistic.category = startingValue.category; }
    if (startingValue.quantity) { startingStatistic.quantity = startingValue.quantity; }
    if (startingValue.numberOfEvents || startingValue.numberOfEvents === 0) { startingStatistic.numberOfEvents = startingValue.numberOfEvents; }
    if (startingValue.numberAffected || startingValue.numberAffected === 0) { startingStatistic.numberAffected = startingValue.numberAffected; }
    if (startingValue.sampleSize) { startingStatistic.sampleSize = startingValue.sampleSize; }
    if (startingValue.attributeEstimate) { startingStatistic.attributeEstimate = startingValue.attributeEstimate; }
    if (startingValue.modelCharacteristic) { startingStatistic.modelCharacteristic = startingValue.modelCharacteristic; }
    if (startingValue.analysisPlan) { startingStatistic.analysisPlan = startingValue.analysisPlan; }
  }

  const [evidenceStatisticState, setEvidenceStatisticState] = useState(startingStatistic);

  useEffect((() => {
    if (Object.keys(evidenceStatisticState).length > 0) {
      let newEvidenceStatistic = {};
      if (evidenceStatisticState.extension) { newEvidenceStatistic.extension = evidenceStatisticState.extension; }
      if (evidenceStatisticState.description) { newEvidenceStatistic.description = evidenceStatisticState.description; }
      if (evidenceStatisticState.note && Array.isArray(evidenceStatisticState.note) && evidenceStatisticState.note.length > 0) {
        newEvidenceStatistic.note = evidenceStatisticState.note;
      }
      if (evidenceStatisticState.statisticType && Object.keys(evidenceStatisticState.statisticType).length > 0) {
        newEvidenceStatistic.statisticType = evidenceStatisticState.statisticType;
      }
      if (evidenceStatisticState.category && Object.keys(evidenceStatisticState.category).length > 0) {
        newEvidenceStatistic.category = evidenceStatisticState.category;
      }
      if (evidenceStatisticState.quantity && Object.keys(evidenceStatisticState.quantity).length > 0) {
        newEvidenceStatistic.quantity = evidenceStatisticState.quantity;
      }
      if (evidenceStatisticState.numberOfEvents || evidenceStatisticState.numberOfEvents === 0) {
        newEvidenceStatistic.numberOfEvents = evidenceStatisticState.numberOfEvents;
      }
      if (evidenceStatisticState.numberAffected || evidenceStatisticState.numberAffected === 0) {
        newEvidenceStatistic.numberAffected = evidenceStatisticState.numberAffected;
      }
      if (evidenceStatisticState.sampleSize && Object.keys(evidenceStatisticState.sampleSize).length > 0) {
        newEvidenceStatistic.sampleSize = evidenceStatisticState.sampleSize;
      }
      if (evidenceStatisticState.attributeEstimate && Array.isArray(evidenceStatisticState.attributeEstimate) && evidenceStatisticState.attributeEstimate.length > 0) {
        newEvidenceStatistic.attributeEstimate = evidenceStatisticState.attributeEstimate;
      }
      if (evidenceStatisticState.modelCharacteristic && Array.isArray(evidenceStatisticState.modelCharacteristic) && evidenceStatisticState.modelCharacteristic.length > 0) {
        newEvidenceStatistic.modelCharacteristic = evidenceStatisticState.modelCharacteristic;
      }
      if (evidenceStatisticState.analysisPlan && Array.isArray(evidenceStatisticState.analysisPlan) && evidenceStatisticState.analysisPlan.length > 0) {
        newEvidenceStatistic.analysisPlan = evidenceStatisticState.analysisPlan;
      }
      if (Object.keys(newEvidenceStatistic).length === 0) {
        newEvidenceStatistic = null;
      }
      handleChange(elementName, newEvidenceStatistic, setResourceState);
    }
  }), [evidenceStatisticState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={evidenceStatisticState.description} setResourceState={setEvidenceStatisticState} />
      <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticState.note} setResourceState={setEvidenceStatisticState} />
      <DataEntry datatype='CodeableConcept' elementName='statisticType' fieldLabel='Statistic Type'
        startingValue={evidenceStatisticState.statisticType}
        valueSet={SEVCO.statisticType}
        setResourceState={setEvidenceStatisticState} />
      <DataEntry datatype='CodeableConcept' elementName='category' fieldLabel='Category'
        startingValue={evidenceStatisticState.category}
        setResourceState={setEvidenceStatisticState} />
      <DataEntry datatype='Quantity' elementName='quantity' fieldLabel='Quantity'
        startingValue={evidenceStatisticState.quantity}
        setResourceState={setEvidenceStatisticState} />
      <br /><br />
      <span><b>Number of Events:</b>
        &nbsp;&nbsp;
        <DataEntry datatype='unsignedInt' elementName='numberOfEvents' fieldLabel='Number of Events'
          startingValue={evidenceStatisticState.numberOfEvents}
          setResourceState={setEvidenceStatisticState} />
      </span>
      <br /><br />
      <span><b>Number Affected:</b>
        &nbsp;&nbsp;
        <DataEntry datatype='unsignedInt' elementName='numberAffected' fieldLabel='Number Affected'
          startingValue={evidenceStatisticState.numberAffected}
          setResourceState={setEvidenceStatisticState} />
      </span>
      <DataEntry datatype='EvidenceStatisticSampleSize' elementName='sampleSize' fieldLabel='Sample Size'
        startingValue={evidenceStatisticState.sampleSize} setResourceState={setEvidenceStatisticState} />
      <DataEntry asArray={true} datatype='EvidenceStatisticAttributeEstimate' elementName='attributeEstimate' fieldLabel='Attribute Estimate'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticState.attributeEstimate} setResourceState={setEvidenceStatisticState} />
      <DataEntry asArray={true} datatype='EvidenceStatisticModelCharacteristic' elementName='modelCharacteristic' fieldLabel='Model Characteristic'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticState.modelCharacteristic} setResourceState={setEvidenceStatisticState} />
      {evidenceStatisticState.analysisPlan && <>
        <b>Analysis Plan:</b>
        <SimpleResourceFieldViewer resource={evidenceStatisticState.analysisPlan} parentElement={""} />
      </>}
    </div>
  </>
})

const EvidenceStatisticSampleSizeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingSampleSize = {
    description: "", note: [], numberOfStudies: "", numberOfParticipants: "", knownDataCount: ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingSampleSize.extension = startingValue.extension; }
    if (startingValue.description) { startingSampleSize.description = startingValue.description; }
    if (startingValue.note) { startingSampleSize.note = startingValue.note; }
    if (startingValue.numberOfStudies || startingValue.numberOfStudies === 0) { startingSampleSize.numberOfStudies = startingValue.numberOfStudies; }
    if (startingValue.numberOfParticipants || startingValue.numberOfParticipants === 0) { startingSampleSize.numberOfParticipants = startingValue.numberOfParticipants; }
    if (startingValue.knownDataCount || startingValue.knownDataCount === 0) { startingSampleSize.knownDataCount = startingValue.knownDataCount; }
  }

  const [evidenceStatisticSampleSizeState, setEvidenceStatisticSampleSizeState] = useState(startingSampleSize);

  useEffect((() => {
    if (Object.keys(evidenceStatisticSampleSizeState).length > 0) {
      let newStatisticSampleSize = {};
      if (evidenceStatisticSampleSizeState.extension) { newStatisticSampleSize.extension = evidenceStatisticSampleSizeState.extension; }
      if (evidenceStatisticSampleSizeState.description) { newStatisticSampleSize.description = evidenceStatisticSampleSizeState.description; }
      if (evidenceStatisticSampleSizeState.note && Array.isArray(evidenceStatisticSampleSizeState.note) && evidenceStatisticSampleSizeState.note.length > 0) {
        newStatisticSampleSize.note = evidenceStatisticSampleSizeState.note;
      }
      if (evidenceStatisticSampleSizeState.numberOfStudies || evidenceStatisticSampleSizeState.numberOfStudies === 0) {
        newStatisticSampleSize.numberOfStudies = evidenceStatisticSampleSizeState.numberOfStudies;
      }
      if (evidenceStatisticSampleSizeState.numberOfParticipants || evidenceStatisticSampleSizeState.numberOfParticipants === 0) {
        newStatisticSampleSize.numberOfParticipants = evidenceStatisticSampleSizeState.numberOfParticipants;
      }
      if (evidenceStatisticSampleSizeState.knownDataCount || evidenceStatisticSampleSizeState.knownDataCount === 0) {
        newStatisticSampleSize.knownDataCount = evidenceStatisticSampleSizeState.knownDataCount;
      }
      if (Object.keys(newStatisticSampleSize).length === 0) {
        newStatisticSampleSize = null;
      }
      handleChange(elementName, newStatisticSampleSize, setResourceState);
    }
  }), [evidenceStatisticSampleSizeState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={evidenceStatisticSampleSizeState.description} setResourceState={setEvidenceStatisticSampleSizeState} />
      <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticSampleSizeState.note} setResourceState={setEvidenceStatisticSampleSizeState} />
      <br /><br />
      <span><b>Number of Studies:</b>
        &nbsp;&nbsp;
        <DataEntry datatype='unsignedInt' elementName='numberOfStudies' fieldLabel='Number of Studies'
          startingValue={evidenceStatisticSampleSizeState.numberOfStudies}
          setResourceState={setEvidenceStatisticSampleSizeState} />
      </span>
      <br /><br />
      <span><b>Number of Participants:</b>
        &nbsp;&nbsp;
        <DataEntry datatype='unsignedInt' elementName='numberOfParticipants' fieldLabel='Number of Participants'
          startingValue={evidenceStatisticSampleSizeState.numberOfParticipants}
          setResourceState={setEvidenceStatisticSampleSizeState} />
      </span>
      <br /><br />
      <span><b>Known Data Count:</b>
        &nbsp;&nbsp;
        <DataEntry datatype='unsignedInt' elementName='knownDataCount' fieldLabel='Known Data Count'
          startingValue={evidenceStatisticSampleSizeState.knownDataCount}
          setResourceState={setEvidenceStatisticSampleSizeState} />
      </span>
    </div>
  </>
})

const EvidenceStatisticAttributeEstimateEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingAttributeEstimate = {
    description: "", note: [], type: "", quantity: "", level: "", range: "", attributeEstimate: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingAttributeEstimate.extension = startingValue.extension; }
    if (startingValue.description) { startingAttributeEstimate.description = startingValue.description; }
    if (startingValue.note) { startingAttributeEstimate.note = startingValue.note; }
    if (startingValue.type) { startingAttributeEstimate.type = startingValue.type; }
    if (startingValue.quantity) { startingAttributeEstimate.quantity = startingValue.quantity; }
    if (startingValue.level) { startingAttributeEstimate.level = startingValue.level; }
    if (startingValue.range) { startingAttributeEstimate.range = startingValue.range; }
    if (startingValue.attributeEstimate) { startingAttributeEstimate.attributeEstimate = startingValue.attributeEstimate; }
  }

  const [evidenceStatisticAttributeEstimateState, setEvidenceStatisticAttributeEstimateState] = useState(startingAttributeEstimate);

  useEffect((() => {
    if (Object.keys(evidenceStatisticAttributeEstimateState).length > 0) {
      let newAttributeEstimate = {};
      if (evidenceStatisticAttributeEstimateState.extension) { newAttributeEstimate.extension = evidenceStatisticAttributeEstimateState.extension; }
      if (evidenceStatisticAttributeEstimateState.description) { newAttributeEstimate.description = evidenceStatisticAttributeEstimateState.description; }
      if (evidenceStatisticAttributeEstimateState.note && Array.isArray(evidenceStatisticAttributeEstimateState.note) && evidenceStatisticAttributeEstimateState.note.length > 0) {
        newAttributeEstimate.note = evidenceStatisticAttributeEstimateState.note;
      }
      if (evidenceStatisticAttributeEstimateState.type && Object.keys(evidenceStatisticAttributeEstimateState.type).length > 0) {
        newAttributeEstimate.type = evidenceStatisticAttributeEstimateState.type;
      }
      if (evidenceStatisticAttributeEstimateState.quantity && Object.keys(evidenceStatisticAttributeEstimateState.quantity).length > 0) {
        newAttributeEstimate.quantity = evidenceStatisticAttributeEstimateState.quantity;
      }
      if (evidenceStatisticAttributeEstimateState.level || evidenceStatisticAttributeEstimateState.level === 0) {
        newAttributeEstimate.level = evidenceStatisticAttributeEstimateState.level;
      }
      if (evidenceStatisticAttributeEstimateState.range && Object.keys(evidenceStatisticAttributeEstimateState.range).length > 0) {
        newAttributeEstimate.range = evidenceStatisticAttributeEstimateState.range;
      }
      if (evidenceStatisticAttributeEstimateState.attributeEstimate && Array.isArray(evidenceStatisticAttributeEstimateState.attributeEstimate) && evidenceStatisticAttributeEstimateState.attributeEstimate.length > 0) {
        newAttributeEstimate.attributeEstimate = evidenceStatisticAttributeEstimateState.attributeEstimate;
      }
      if (Object.keys(newAttributeEstimate).length === 0) {
        newAttributeEstimate = null;
      }
      handleChange(elementName, newAttributeEstimate, setResourceState);
    }
  }), [evidenceStatisticAttributeEstimateState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={evidenceStatisticAttributeEstimateState.description} setResourceState={setEvidenceStatisticAttributeEstimateState} />
      <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticAttributeEstimateState.note} setResourceState={setEvidenceStatisticAttributeEstimateState} />
      <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Attirbute Estimate Type'
        startingValue={evidenceStatisticAttributeEstimateState.type}
        valueSet={SEVCO.statisticType}
        setResourceState={setEvidenceStatisticAttributeEstimateState} />
      <DataEntry datatype='Quantity' elementName='quantity' fieldLabel='Quantity'
        startingValue={evidenceStatisticAttributeEstimateState.quantity}
        setResourceState={setEvidenceStatisticAttributeEstimateState} />
      <br /><br />
      <span><b>Level:</b>
        &nbsp;&nbsp;
        <DataEntry datatype='decimal' elementName='level' fieldLabel='Level'
          startingValue={evidenceStatisticAttributeEstimateState.level}
          setResourceState={setEvidenceStatisticAttributeEstimateState} />
      </span>
      <DataEntry datatype='Range' elementName='range' fieldLabel='Range'
        startingValue={evidenceStatisticAttributeEstimateState.range}
        setResourceState={setEvidenceStatisticAttributeEstimateState} />
      <DataEntry asArray={true} datatype='EvidenceStatisticAttributeEstimate' elementName='attributeEstimate' fieldLabel='Attribute Estimate'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticAttributeEstimateState.attributeEstimate} setResourceState={setEvidenceStatisticAttributeEstimateState} />
    </div>
  </>
})

const ExtensionStatisticModelIncludeIfValueAllowedDatatypes = ['boolean', 'CodeableConcept', 'Quantity', 'Range', 'Expression'];
const ExtensionStatisticModelIncludeIfEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {

  //There can/should only be one of these elements: valueBoolean, valueCodeableConcept, valueQuantity, valueRange, or valueExpression
  let startingExtensionStatisticModelIncludeIf = {
    otherExtensions: [],
    attribute: null,
    valueBoolean: null,
    valueCodeableConcept: null,
    valueQuantity: null,
    valueRange: null,
    valueExpression: null
  };

  let startingIncludeIfDotValueDatatype = 'none';

  if (Array.isArray(startingValue)) {
    for (const extension of startingValue) {
      if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if" ||
        extension.url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if") {
        for (const ext of extension.extension) {
          if (ext.url === "attribute") {
            startingExtensionStatisticModelIncludeIf.attribute = ext.valueCodeableConcept;
          } else if (ext.url === "value") {
            if (typeof ext.valueBoolean === 'boolean') {
              startingExtensionStatisticModelIncludeIf.valueBoolean = ext.valueBoolean;
              startingIncludeIfDotValueDatatype = "boolean";
            } else if (ext.valueCodeableConcept) {
              startingExtensionStatisticModelIncludeIf.valueCodeableConcept = ext.valueCodeableConcept;
              startingIncludeIfDotValueDatatype = "CodeableConcept";
            } else if (ext.valueQuantity) {
              startingExtensionStatisticModelIncludeIf.valueQuantity = ext.valueQuantity;
              startingIncludeIfDotValueDatatype = "Quantity";
            } else if (ext.valueRange) {
              startingExtensionStatisticModelIncludeIf.valueRange = ext.valueRange;
              startingIncludeIfDotValueDatatype = "Range";
            } else if (ext.valueExpression) {
              startingExtensionStatisticModelIncludeIf.valueExpression = ext.valueExpression;
              startingIncludeIfDotValueDatatype = "Expression";
            }
          }
        }
      } else {
        startingExtensionStatisticModelIncludeIf.otherExtensions.push(extension);
      }
    }
  }

  const [extensionStatisticModelIncludeIfState, setExtensionStatisticModelIncludeIfState] = useState(startingExtensionStatisticModelIncludeIf);
  const [includeIfDotValueDatatypeState, setIncludeIfDotValueDatatypeState] = useState(startingIncludeIfDotValueDatatype);

  useEffect((() => {
    if (includeIfDotValueDatatypeState === 'boolean') {
      setExtensionStatisticModelIncludeIfState(prevState => { return { ...prevState, 'valueCodeableConcept': null, 'valueQuantity': null, 'valueRange': null, 'valueExpression': null } })
    }
    if (includeIfDotValueDatatypeState === 'CodeableConcept') {
      setExtensionStatisticModelIncludeIfState(prevState => { return { ...prevState, 'valueBoolean': null, 'valueQuantity': null, 'valueRange': null, 'valueExpression': null } })
    }
    if (includeIfDotValueDatatypeState === 'Quantity') {
      setExtensionStatisticModelIncludeIfState(prevState => { return { ...prevState, 'valueBoolean': null, 'valueCodeableConcept': null, 'valueRange': null, 'valueExpression': null } })
    }
    if (includeIfDotValueDatatypeState === 'Range') {
      setExtensionStatisticModelIncludeIfState(prevState => { return { ...prevState, 'valueBoolean': null, 'valueQuantity': null, 'valueCodeableConcept': null, 'valueExpression': null } })
    }
    if (includeIfDotValueDatatypeState === 'Expression') {
      setExtensionStatisticModelIncludeIfState(prevState => { return { ...prevState, 'valueBoolean': null, 'valueQuantity': null, 'valueCodeableConcept': null, 'valueRange': null } })
    }
  }), [includeIfDotValueDatatypeState]);

  useEffect((() => {
    if (Object.keys(extensionStatisticModelIncludeIfState).length > 0) {
      let newExtension = extensionStatisticModelIncludeIfState.otherExtensions || [];
      let extensionIndex;
      for (let index in newExtension) {
        if (newExtension[index].url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if" ||
          newExtension[index].url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if") {
          extensionIndex = index;
          break;
        }
      }
      let newIncludeIf = {
        "extension": [
          {
            "url": "attribute",
            "valueCodeableConcept": extensionStatisticModelIncludeIfState.attribute
          },
          {
            "url": "value",
            "valueBoolean": extensionStatisticModelIncludeIfState.valueBoolean,
            "valueCodeableConcept": extensionStatisticModelIncludeIfState.valueCodeableConcept,
            'valueQuantity': extensionStatisticModelIncludeIfState.valueQuantity,
            'valueRange': extensionStatisticModelIncludeIfState.valueRange,
            'valueExpression': extensionStatisticModelIncludeIfState.valueExpression
          }
        ],
        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if"
      };
      if (extensionStatisticModelIncludeIfState.valueBoolean === null) {
        delete newIncludeIf.extension[1].valueBoolean;
      }
      if (extensionStatisticModelIncludeIfState.valueCodeableConcept === null) {
        delete newIncludeIf.extension[1].valueCodeableConcept;
      }
      if (extensionStatisticModelIncludeIfState.valueQuantity === null) {
        delete newIncludeIf.extension[1].valueQuantity;
      }
      if (extensionStatisticModelIncludeIfState.valueRange === null) {
        delete newIncludeIf.extension[1].valueRange;
      }
      if (extensionStatisticModelIncludeIfState.valueExpression === null) {
        delete newIncludeIf.extension[1].valueExpression;
      }
      if (extensionIndex !== undefined) {
        if (extensionStatisticModelIncludeIfState.attribute ||
          extensionStatisticModelIncludeIfState.valueCodeableConcept ||
          extensionStatisticModelIncludeIfState.valueQuantity ||
          extensionStatisticModelIncludeIfState.valueRange ||
          extensionStatisticModelIncludeIfState.valueExpression) {
          newExtension[extensionIndex] = newIncludeIf;
        } else {
          delete newExtension[extensionIndex];
        }
      } else {
        if (extensionStatisticModelIncludeIfState.attribute ||
          extensionStatisticModelIncludeIfState.valueCodeableConcept ||
          extensionStatisticModelIncludeIfState.valueQuantity ||
          extensionStatisticModelIncludeIfState.valueRange ||
          extensionStatisticModelIncludeIfState.valueExpression) {
          newExtension.push(newIncludeIf);
        }
      }
      handleChange(elementName, newExtension, setResourceState);
    }
  }), [extensionStatisticModelIncludeIfState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableConcept' elementName='attribute' fieldLabel='Type of Condition'
        startingValue={extensionStatisticModelIncludeIfState.attribute}
        setResourceState={setExtensionStatisticModelIncludeIfState} />
      <DatatypeSelector elementXName='value[x]'
        allowedDatatypes={ExtensionStatisticModelIncludeIfValueAllowedDatatypes}
        datatypeState={includeIfDotValueDatatypeState}
        setDatatypeState={setIncludeIfDotValueDatatypeState} />
      {includeIfDotValueDatatypeState === 'boolean' &&
        <DataEntry datatype='boolean' elementName='valueBoolean' fieldLabel='Value as Boolean'
          startingValue={extensionStatisticModelIncludeIfState.valueBoolean}
          setResourceState={setExtensionStatisticModelIncludeIfState} />}
      {includeIfDotValueDatatypeState === 'CodeableConcept' &&
        <DataEntry datatype='CodeableConcept' elementName='valueCodeableConcept' fieldLabel='Value as CodeableConcept'
          startingValue={extensionStatisticModelIncludeIfState.valueCodeableConcept}
          setResourceState={setExtensionStatisticModelIncludeIfState} />}
      {includeIfDotValueDatatypeState === 'Quantity' &&
        <DataEntry datatype='Quantity' elementName='valueQuantity' fieldLabel='Value as Quantity'
          startingValue={extensionStatisticModelIncludeIfState.valueQuantity}
          setResourceState={setExtensionStatisticModelIncludeIfState} />}
      {includeIfDotValueDatatypeState === 'Range' &&
        <DataEntry datatype='Range' elementName='valueRange' fieldLabel='Value as Range'
          startingValue={extensionStatisticModelIncludeIfState.valueRange}
          setResourceState={setExtensionStatisticModelIncludeIfState} />}
      {includeIfDotValueDatatypeState === 'Expression' &&
        <DataEntry datatype='Expression' elementName='valueExpression' fieldLabel='Value as Expression'
          startingValue={extensionStatisticModelIncludeIfState.valueExpression}
          setResourceState={setExtensionStatisticModelIncludeIfState} />}
    </div>
  </>
})

const EvidenceStatisticModelCharacteristicDotValueAllowedDatatypes = ['CodeableConcept', 'Quantity', 'Range'];
const EvidenceStatisticModelCharacteristicEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingModelCharacteristic = {
    extension: [],
    conditionCodeableConcept: "", conditionExpression: "", code: "", value: "",
    valueQuantity: "", valueRange: "", valueCodeableConcept: "",
    intended: "", applied: "",
    variable: [], attributeEstimate: [], modelCharacteristic: []
  }
  let startingEvidenceModelCharacteristicDotValueDatatype = 'none';
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingModelCharacteristic.extension = startingValue.extension; }
    if (startingValue.conditionCodeableConcept) {
      if (!startingModelCharacteristic.extension) {
        startingModelCharacteristic.extension = [];
      }
      let includeIf = {
        "extension": [
          {
            "url": "attribute",
            "valueCodeableConcept": startingValue.conditionCodeableConcept
          },
          {
            "url": "value",
            "valueBoolean": true
          }
        ],
        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if"
      };
      startingModelCharacteristic.extension.push(includeIf);
    }
    if (startingValue.conditionExpression) {
      if (!startingModelCharacteristic.extension) {
        startingModelCharacteristic.extension = [];
      }
      let includeIf = {
        "extension": [
          {
            "url": "attribute",
            "valueCodeableConcept": { "text": "Defined by Expression" }
          },
          {
            "url": "value",
            "valueExpression": startingValue.conditionExpression
          }
        ],
        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if"
      };
      startingModelCharacteristic.extension.push(includeIf);
    }
    if (startingValue.code) { startingModelCharacteristic.code = startingValue.code; }
    if (startingValue.value) { startingModelCharacteristic.value = startingValue.value; }
    if (startingValue.valueCodeableConcept) {
      startingModelCharacteristic.valueCodeableConcept = startingValue.valueCodeableConcept;
      startingEvidenceModelCharacteristicDotValueDatatype = "CodeableConcept";
    }
    if (startingValue.valueQuantity) {
      startingModelCharacteristic.valueQuantity = startingValue.valueQuantity;
      startingEvidenceModelCharacteristicDotValueDatatype = "Quantity";
    }
    if (startingValue.valueRange) {
      startingModelCharacteristic.valueRange = startingValue.valueRange;
      startingEvidenceModelCharacteristicDotValueDatatype = "Range";
    }
    if (typeof startingValue.intended === "boolean") {
      startingModelCharacteristic.intended = startingValue.intended;
    }
    if (typeof startingValue.applied === "boolean") {
      startingModelCharacteristic.applied = startingValue.applied;
    }
    if (startingValue.variable) { startingModelCharacteristic.variable = startingValue.variable; }
    if (startingValue.attributeEstimate) { startingModelCharacteristic.attributeEstimate = startingValue.attributeEstimate; }
    if (startingValue.modelCharacteristic) { startingModelCharacteristic.modelCharacteristic = startingValue.modelCharacteristic; }
  }

  const [evidenceStatisticModelCharacteristicState, setEvidenceStatisticModelCharacteristicState] = useState(startingModelCharacteristic);
  const [evidenceStatisticModelCharacteristicDotValueDatatypeState, setEvidenceStatisticModelCharacteristicDotValueDatatypeState] = useState(startingEvidenceModelCharacteristicDotValueDatatype);

  useEffect((() => {
    if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'CodeableConcept') {
      setEvidenceStatisticModelCharacteristicState(prevState => { return { ...prevState, 'valueQuantity': null, 'valueRange': null } })
    }
    if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Quantity') {
      setEvidenceStatisticModelCharacteristicState(prevState => { return { ...prevState, 'valueCodeableConcept': null, 'valueRange': null } })
    }
    if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Range') {
      setEvidenceStatisticModelCharacteristicState(prevState => { return { ...prevState, 'valueQuantity': null, 'valueCodeableConcept': null } })
    }
  }), [evidenceStatisticModelCharacteristicDotValueDatatypeState]);

  useEffect((() => {
    if (Object.keys(evidenceStatisticModelCharacteristicState).length > 0) {
      let newModelCharacteristic = {};
      if (evidenceStatisticModelCharacteristicState.extension?.length > 0) { newModelCharacteristic.extension = evidenceStatisticModelCharacteristicState.extension; }
      if (evidenceStatisticModelCharacteristicState.code && Object.keys(evidenceStatisticModelCharacteristicState.code).length > 0) {
        newModelCharacteristic.code = evidenceStatisticModelCharacteristicState.code;
      }
      if (evidenceStatisticModelCharacteristicState.value || evidenceStatisticModelCharacteristicState.value === 0) {
        newModelCharacteristic.value = evidenceStatisticModelCharacteristicState.value;
      }
      if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'CodeableConcept' &&
        evidenceStatisticModelCharacteristicState.valueCodeableConcept &&
        Object.keys(evidenceStatisticModelCharacteristicState.valueCodeableConcept).length > 0) {
        newModelCharacteristic.valueCodeableConcept = evidenceStatisticModelCharacteristicState.valueCodeableConcept;
      }
      if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Quantity' &&
        evidenceStatisticModelCharacteristicState.valueQuantity &&
        Object.keys(evidenceStatisticModelCharacteristicState.valueQuantity).length > 0) {
        newModelCharacteristic.valueQuantity = evidenceStatisticModelCharacteristicState.valueQuantity;
      }
      if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Range' &&
        evidenceStatisticModelCharacteristicState.valueRange &&
        Object.keys(evidenceStatisticModelCharacteristicState.valueRange).length > 0) {
        newModelCharacteristic.valueRange = evidenceStatisticModelCharacteristicState.valueRange;
      }
      if (typeof evidenceStatisticModelCharacteristicState.intended === "boolean") {
        newModelCharacteristic.intended = evidenceStatisticModelCharacteristicState.intended;
      }
      if (typeof evidenceStatisticModelCharacteristicState.applied === "boolean") {
        newModelCharacteristic.applied = evidenceStatisticModelCharacteristicState.applied;
      }
      if (evidenceStatisticModelCharacteristicState.variable && Array.isArray(evidenceStatisticModelCharacteristicState.variable) && evidenceStatisticModelCharacteristicState.variable.length > 0) {
        newModelCharacteristic.variable = evidenceStatisticModelCharacteristicState.variable;
      }
      if (evidenceStatisticModelCharacteristicState.attributeEstimate && Array.isArray(evidenceStatisticModelCharacteristicState.attributeEstimate) && evidenceStatisticModelCharacteristicState.attributeEstimate.length > 0) {
        newModelCharacteristic.attributeEstimate = evidenceStatisticModelCharacteristicState.attributeEstimate;
      }
      if (evidenceStatisticModelCharacteristicState.modelCharacteristic && Array.isArray(evidenceStatisticModelCharacteristicState.modelCharacteristic) && evidenceStatisticModelCharacteristicState.modelCharacteristic.length > 0) {
        newModelCharacteristic.modelCharacteristic = evidenceStatisticModelCharacteristicState.modelCharacteristic;
      }
      if (Object.keys(newModelCharacteristic).length === 0) {
        newModelCharacteristic = null;
      }
      handleChange(elementName, newModelCharacteristic, setResourceState);
    }
  }), [evidenceStatisticModelCharacteristicState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Model Characteristic Specification'
        startingValue={evidenceStatisticModelCharacteristicState.code}
        setResourceState={setEvidenceStatisticModelCharacteristicState} />
      <DataEntry datatype='SimpleQuantity' elementName='value' fieldLabel='Value'
        startingValue={evidenceStatisticModelCharacteristicState.value}
        setResourceState={setEvidenceStatisticModelCharacteristicState} />
      <DatatypeSelector elementXName='value[x]'
        allowedDatatypes={EvidenceStatisticModelCharacteristicDotValueAllowedDatatypes}
        datatypeState={evidenceStatisticModelCharacteristicDotValueDatatypeState}
        setDatatypeState={setEvidenceStatisticModelCharacteristicDotValueDatatypeState} />
      {evidenceStatisticModelCharacteristicDotValueDatatypeState === 'CodeableConcept' &&
        <DataEntry datatype='CodeableConcept' elementName='valueCodeableConcept' fieldLabel='Value as CodeableConcept'
          startingValue={evidenceStatisticModelCharacteristicState.valueCodeableConcept}
          setResourceState={setEvidenceStatisticModelCharacteristicState} />}
      {evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Quantity' &&
        <DataEntry datatype='Quantity' elementName='valueQuantity' fieldLabel='Value as Quantity'
          startingValue={evidenceStatisticModelCharacteristicState.valueQuantity}
          setResourceState={setEvidenceStatisticModelCharacteristicState} />}
      {evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Range' &&
        <DataEntry datatype='Range' elementName='valueRange' fieldLabel='Value as Range'
          startingValue={evidenceStatisticModelCharacteristicState.valueRange}
          setResourceState={setEvidenceStatisticModelCharacteristicState} />}
      <DataEntry datatype='boolean' elementName='intended' fieldLabel='Intended' storeFalse={true}
        startingValue={evidenceStatisticModelCharacteristicState.intended ?? null}
        setResourceState={setEvidenceStatisticModelCharacteristicState} />
      {evidenceStatisticModelCharacteristicState.intended &&
        <ExtensionStatisticModelIncludeIfEntry elementName='extension' fieldLabel='Include If'
          startingValue={evidenceStatisticModelCharacteristicState.extension}
          setResourceState={setEvidenceStatisticModelCharacteristicState} />}
      <DataEntry datatype='boolean' elementName='applied' fieldLabel='Applied' storeFalse={true}
        startingValue={evidenceStatisticModelCharacteristicState.applied ?? null}
        setResourceState={setEvidenceStatisticModelCharacteristicState} />
      <DataEntry asArray={true} datatype='EvidenceStatisticModelCharacteristicVariable' elementName='variable'
        fieldLabel='Variable'
        dataEntryStyle={evidenceStatisticModelCharacteristicState.intended && "includeIf"}
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticModelCharacteristicState.variable}
        setResourceState={setEvidenceStatisticModelCharacteristicState} />
      <DataEntry asArray={true} datatype='EvidenceStatisticAttributeEstimate' elementName='attributeEstimate'
        fieldLabel='Attribute Estimate'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticModelCharacteristicState.attributeEstimate}
        setResourceState={setEvidenceStatisticModelCharacteristicState} />
      <DataEntry asArray={true} datatype='EvidenceStatisticModelCharacteristic' elementName='modelCharacteristic'
        fieldLabel='Model Characteristic'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticModelCharacteristicState.modelCharacteristic}
        setResourceState={setEvidenceStatisticModelCharacteristicState} />
    </div>
  </>
})

const evidenceStatisticModelCharacteristicVariableDotHandlingCodeValues = ['continuous', 'dichotomous', 'ordinal', 'polychotomous'];
const evidenceStatisticModelCharacteristicVariableDotVariableDefinitionResourceTypes = ['Group', 'EvidenceVariable'];
const EvidenceStatisticModelCharacteristicVariableEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingVariable = {
    variableDefinition: "", handling: "", valueCategory: [], valueQuantity: [], valueRange: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingVariable.extension = startingValue.extension; }
    if (startingValue.variableDefinition) { startingVariable.variableDefinition = startingValue.variableDefinition; }
    if (startingValue.handling) { startingVariable.handling = startingValue.handling; }
    if (startingValue.valueCategory) { startingVariable.valueCategory = startingValue.valueCategory; }
    if (startingValue.valueQuantity) { startingVariable.valueQuantity = startingValue.valueQuantity; }
    if (startingValue.valueRange) { startingVariable.valueRange = startingValue.valueRange; }
  }

  const [evidenceStatisticModelCharacteristicVariableState, setEvidenceStatisticModelCharacteristicVariableState] = useState(startingVariable);

  useEffect((() => {
    if (Object.keys(evidenceStatisticModelCharacteristicVariableState).length > 0) {
      let newEvidenceStatisticModelCharacteristicVariable = {};
      if (evidenceStatisticModelCharacteristicVariableState.extension?.length > 0) { newEvidenceStatisticModelCharacteristicVariable.extension = evidenceStatisticModelCharacteristicVariableState.extension; }
      if (evidenceStatisticModelCharacteristicVariableState.variableDefinition && Object.keys(evidenceStatisticModelCharacteristicVariableState.variableDefinition).length > 0) {
        newEvidenceStatisticModelCharacteristicVariable.variableDefinition = evidenceStatisticModelCharacteristicVariableState.variableDefinition;
      }
      if (evidenceStatisticModelCharacteristicVariableState.handling) { newEvidenceStatisticModelCharacteristicVariable.handling = evidenceStatisticModelCharacteristicVariableState.handling; }
      if (evidenceStatisticModelCharacteristicVariableState.valueCategory && Array.isArray(evidenceStatisticModelCharacteristicVariableState.valueCategory) && evidenceStatisticModelCharacteristicVariableState.valueCategory.length > 0) {
        newEvidenceStatisticModelCharacteristicVariable.valueCategory = evidenceStatisticModelCharacteristicVariableState.valueCategory;
      }
      if (evidenceStatisticModelCharacteristicVariableState.valueQuantity && Array.isArray(evidenceStatisticModelCharacteristicVariableState.valueQuantity) && evidenceStatisticModelCharacteristicVariableState.valueQuantity.length > 0) {
        newEvidenceStatisticModelCharacteristicVariable.valueQuantity = evidenceStatisticModelCharacteristicVariableState.valueQuantity;
      }
      if (evidenceStatisticModelCharacteristicVariableState.valueRange && Array.isArray(evidenceStatisticModelCharacteristicVariableState.valueRange) && evidenceStatisticModelCharacteristicVariableState.valueRange.length > 0) {
        newEvidenceStatisticModelCharacteristicVariable.valueRange = evidenceStatisticModelCharacteristicVariableState.valueRange;
      }
      if (Object.keys(newEvidenceStatisticModelCharacteristicVariable).length === 0) {
        newEvidenceStatisticModelCharacteristicVariable = null;
      }
      handleChange(elementName, newEvidenceStatisticModelCharacteristicVariable, setResourceState);
    }
  }), [evidenceStatisticModelCharacteristicVariableState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='Reference' elementName='variableDefinition' fieldLabel='Variable Definition'
        startingValue={evidenceStatisticModelCharacteristicVariableState.variableDefinition}
        referencedResourceTypes={evidenceStatisticModelCharacteristicVariableDotVariableDefinitionResourceTypes}
        setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />
      <DataEntry datatype='code' elementName='handling' fieldLabel='Handling'
        startingValue={evidenceStatisticModelCharacteristicVariableState.handling}
        allowedValues={evidenceStatisticModelCharacteristicVariableDotHandlingCodeValues}
        setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />
      <DataEntry asArray={true} datatype='CodeableConcept' elementName='valueCategory'
        fieldLabel='Value Option (as Category)'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticModelCharacteristicVariableState.valueCategory}
        setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />
      <DataEntry asArray={true} datatype='Quantity' elementName='valueQuantity'
        fieldLabel='Value Option (as Quantity)'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticModelCharacteristicVariableState.valueQuantity}
        setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />
      <DataEntry asArray={true} datatype='Range' elementName='valueRange'
        fieldLabel='Value Option (as Range)'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticModelCharacteristicVariableState.valueRange}
        setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />
      {dataEntryStyle === "includeIf" &&
        <ExtensionStatisticModelIncludeIfEntry elementName='extension' fieldLabel='Include If'
          startingValue={evidenceStatisticModelCharacteristicVariableState.extension}
          setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />}
    </div>
  </>
})

const EvidenceVariableCategoryDotValueAllowedDatatypes = ['CodeableConcept', 'Quantity', 'Range', 'Reference'];
const EvidenceVariableCategoryEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingVariableCategory = {
    name: "", valueCodeableConcept: "", valueQuantity: "", valueRange: "", valueReference: ""
  }
  let startingEvidenceVariableCategoryDotValueDatatype = 'none';
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingVariableCategory.extension = startingValue.extension; }
    if (startingValue.name) { startingVariableCategory.name = startingValue.name; }
    if (startingValue.valueCodeableConcept) {
      startingVariableCategory.valueCodeableConcept = startingValue.valueCodeableConcept;
      startingEvidenceVariableCategoryDotValueDatatype = "CodeableConcept";
    }
    if (startingValue.valueQuantity) {
      startingVariableCategory.valueQuantity = startingValue.valueQuantity;
      startingEvidenceVariableCategoryDotValueDatatype = "Quantity";
    }
    if (startingValue.valueRange) {
      startingVariableCategory.valueRange = startingValue.valueRange;
      startingEvidenceVariableCategoryDotValueDatatype = "Range";
    }
    if (startingValue.valueReference) {
      startingVariableCategory.valueReference = startingValue.valueReference;
      startingEvidenceVariableCategoryDotValueDatatype = "Reference";
    }
  }

  const [evidenceVariableCategoryState, setEvidenceVariableCategoryState] = useState(startingVariableCategory);

  const [evidenceVariableCategoryDotValueDatatypeState, setEvidenceVariableCategoryDotValueDatatypeState] = useState(startingEvidenceVariableCategoryDotValueDatatype);

  useEffect((() => {
    if (evidenceVariableCategoryDotValueDatatypeState === 'CodeableConcept') {
      setEvidenceVariableCategoryState(prevState => { return { ...prevState, 'valueQuantity': null, 'valueRange': null, 'valueReference': null } })
    }
    if (evidenceVariableCategoryDotValueDatatypeState === 'Quantity') {
      setEvidenceVariableCategoryState(prevState => { return { ...prevState, 'valueCodeableConcept': null, 'valueRange': null, 'valueReference': null } })
    }
    if (evidenceVariableCategoryDotValueDatatypeState === 'Range') {
      setEvidenceVariableCategoryState(prevState => { return { ...prevState, 'valueQuantity': null, 'valueCodeableConcept': null, 'valueReference': null } })
    }
    if (evidenceVariableCategoryDotValueDatatypeState === 'Reference') {
      setEvidenceVariableCategoryState(prevState => { return { ...prevState, 'valueQuantity': null, 'valueCodeableConcept': null, 'valueRange': null } })
    }
  }), [evidenceVariableCategoryDotValueDatatypeState]);


  useEffect((() => {
    if (Object.keys(evidenceVariableCategoryState).length > 0) {
      let newEvidenceVariableCategory = {};
      if (evidenceVariableCategoryState.extension) { newEvidenceVariableCategory.extension = evidenceVariableCategoryState.extension; }
      if (evidenceVariableCategoryState.name) { newEvidenceVariableCategory.name = evidenceVariableCategoryState.name; }
      if (evidenceVariableCategoryDotValueDatatypeState === 'CodeableConcept' && evidenceVariableCategoryState.valueCodeableConcept && Object.keys(evidenceVariableCategoryState.valueCodeableConcept).length > 0) {
        newEvidenceVariableCategory.valueCodeableConcept = evidenceVariableCategoryState.valueCodeableConcept;
      }
      if (evidenceVariableCategoryDotValueDatatypeState === 'Quantity' && evidenceVariableCategoryState.valueQuantity && Object.keys(evidenceVariableCategoryState.valueQuantity).length > 0) {
        newEvidenceVariableCategory.valueQuantity = evidenceVariableCategoryState.valueQuantity;
      }
      if (evidenceVariableCategoryDotValueDatatypeState === 'Range' && evidenceVariableCategoryState.valueRange && Object.keys(evidenceVariableCategoryState.valueRange).length > 0) {
        newEvidenceVariableCategory.valueRange = evidenceVariableCategoryState.valueRange;
      }
      if (evidenceVariableCategoryDotValueDatatypeState === 'Reference' && evidenceVariableCategoryState.valueReference && Object.keys(evidenceVariableCategoryState.valueReference).length > 0) {
        newEvidenceVariableCategory.valueReference = evidenceVariableCategoryState.valueReference;
      }
      if (Object.keys(newEvidenceVariableCategory).length === 0) {
        newEvidenceVariableCategory = null;
      }
      handleChange(elementName, newEvidenceVariableCategory, setResourceState);
    }
  }), [evidenceVariableCategoryState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='name' fieldLabel='Category Name'
        startingValue={evidenceVariableCategoryState.name} setResourceState={setEvidenceVariableCategoryState} />
      <DatatypeSelector elementXName='value[x]' allowedDatatypes={EvidenceVariableCategoryDotValueAllowedDatatypes}
        datatypeState={evidenceVariableCategoryDotValueDatatypeState} setDatatypeState={setEvidenceVariableCategoryDotValueDatatypeState} />
      {evidenceVariableCategoryDotValueDatatypeState === 'CodeableConcept' &&
        <DataEntry datatype='CodeableConcept' elementName='valueCodeableConcept' fieldLabel='Category Definition as CodeableConcept'
          startingValue={evidenceVariableCategoryState.valueCodeableConcept}
          setResourceState={setEvidenceVariableCategoryState} />}
      {evidenceVariableCategoryDotValueDatatypeState === 'Quantity' &&
        <DataEntry datatype='Quantity' elementName='valueQuantity' fieldLabel='Category Definition as Quantity'
          startingValue={evidenceVariableCategoryState.valueQuantity}
          setResourceState={setEvidenceVariableCategoryState} />}
      {evidenceVariableCategoryDotValueDatatypeState === 'Range' &&
        <DataEntry datatype='Range' elementName='valueRange' fieldLabel='Category Definition as Range'
          startingValue={evidenceVariableCategoryState.valueRange}
          setResourceState={setEvidenceVariableCategoryState} />}
      {evidenceVariableCategoryDotValueDatatypeState === 'Reference' &&
        <DataEntry datatype='Reference' elementName='valueReference' fieldLabel='Category Definition as Reference'
          startingValue={evidenceVariableCategoryState.valueReference} enableCreation={true}
          referencedResourceTypes={["Group"]}
          setResourceState={setEvidenceVariableCategoryState} />}
    </div>
  </>
})

const evidenceDotVariableDefinitionDotVariableRoleCodeSet = ["population", "exposure", "outcome", "covariate",
  "subpopulation", "referenceExposure", "measuredVariable", "confounder"];
const evidenceDotVariableDefinitionDotVariableRoleValueSet = [
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "population", display: "population" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "subpopulation", display: "subpopulation" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "exposure", display: "exposure" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "referenceExposure", display: "reference exposure" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "outcome", display: "outcome" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "measuredVariable", display: "measured variable" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "measuredVariableA", display: "measured variable A" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "measuredVariableB", display: "measured variable B" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "measuredVariableAB", display: "measured variable A to B" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "covariate", display: "covariate" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "confounder", display: "confounder" }
];
const evidenceDotVariableDefinitionDotReferenceResourceTypes = ['Group', 'EvidenceVariable'];
const evidenceDotVariableDefinitionDotDirectnessMatchValueSet = [
  { system: "http://terminology.hl7.org/CodeSystem/directness", version: "1.0.0", code: "low", display: "Low quality match between observed and intended variable" },
  { system: "http://terminology.hl7.org/CodeSystem/directness", version: "1.0.0", code: "moderate", display: "Moderate quality match between observed and intended variable" },
  { system: "http://terminology.hl7.org/CodeSystem/directness", version: "1.0.0", code: "high", display: "High quality match between observed and intended variable" },
  { system: "http://terminology.hl7.org/CodeSystem/directness", version: "1.0.0", code: "exact", display: "Exact match between observed and intended variable" }
];
const EvidenceVariableDefinitionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingVariableDefinition = {
    description: "", note: [], variableRole: "", roleSubtype: "", comparatorCategory: "", observed: "", intended: "", directnessMatch: ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingVariableDefinition.extension = startingValue.extension; }
    if (startingValue.description) { startingVariableDefinition.description = startingValue.description; }
    if (startingValue.note) { startingVariableDefinition.note = startingValue.note; }
    if (startingValue.variableRole) { startingVariableDefinition.variableRole = startingValue.variableRole; }
    if (startingValue.roleSubtype) { startingVariableDefinition.roleSubtype = startingValue.roleSubtype; }
    if (startingValue.comparatorCategory) { startingVariableDefinition.comparatorCategory = startingValue.comparatorCategory; }
    if (startingValue.observed) { startingVariableDefinition.observed = startingValue.observed; }
    if (startingValue.intended) { startingVariableDefinition.intended = startingValue.intended; }
    if (startingValue.directnessMatch) { startingVariableDefinition.directnessMatch = startingValue.directnessMatch; }
  }

  const [evidenceVariableDefinitionState, setEvidenceVariableDefinitionState] = useState(startingVariableDefinition);

  useEffect((() => {
    if (Object.keys(evidenceVariableDefinitionState).length > 0) {
      let newEvidenceVariableDefinition = {};
      if (evidenceVariableDefinitionState.extension) { newEvidenceVariableDefinition.extension = evidenceVariableDefinitionState.extension; }
      if (evidenceVariableDefinitionState.description) { newEvidenceVariableDefinition.description = evidenceVariableDefinitionState.description; }
      if (evidenceVariableDefinitionState.note && Array.isArray(evidenceVariableDefinitionState.note) && evidenceVariableDefinitionState.note.length > 0) {
        newEvidenceVariableDefinition.note = evidenceVariableDefinitionState.note;
      }
      if (evidenceVariableDefinitionState.variableRole) { newEvidenceVariableDefinition.variableRole = evidenceVariableDefinitionState.variableRole; }
      if (evidenceVariableDefinitionState.roleSubtype && Object.keys(evidenceVariableDefinitionState.roleSubtype).length > 0) {
        newEvidenceVariableDefinition.roleSubtype = evidenceVariableDefinitionState.roleSubtype;
      }
      if (evidenceVariableDefinitionState.comparatorCategory) { newEvidenceVariableDefinition.comparatorCategory = evidenceVariableDefinitionState.comparatorCategory; }

      if (evidenceVariableDefinitionState.observed && Object.keys(evidenceVariableDefinitionState.observed).length > 0) {
        newEvidenceVariableDefinition.observed = evidenceVariableDefinitionState.observed;
      }
      if (evidenceVariableDefinitionState.intended && Object.keys(evidenceVariableDefinitionState.intended).length > 0) {
        newEvidenceVariableDefinition.intended = evidenceVariableDefinitionState.intended;
      }
      if (evidenceVariableDefinitionState.directnessMatch && Object.keys(evidenceVariableDefinitionState.directnessMatch).length > 0) {
        newEvidenceVariableDefinition.directnessMatch = evidenceVariableDefinitionState.directnessMatch;
      }
      if (Object.keys(newEvidenceVariableDefinition).length === 0) {
        newEvidenceVariableDefinition = null;
      }
      handleChange(elementName, newEvidenceVariableDefinition, setResourceState);
    }
  }), [evidenceVariableDefinitionState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={evidenceVariableDefinitionState.description} setResourceState={setEvidenceVariableDefinitionState} />
      <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceVariableDefinitionState.note} setResourceState={setEvidenceVariableDefinitionState} />
      <DataEntry datatype='code' elementName='variableRole' fieldLabel='Variable Role'
        startingValue={evidenceVariableDefinitionState.variableRole}
        allowedValues={evidenceDotVariableDefinitionDotVariableRoleCodeSet}
        setResourceState={setEvidenceVariableDefinitionState} />

      <DataEntry datatype='CodeableConcept' elementName='roleSubtype' fieldLabel='Variable Role Subtype'
        startingValue={evidenceVariableDefinitionState.roleSubtype}
        valueSet={evidenceDotVariableDefinitionDotVariableRoleValueSet}
        setResourceState={setEvidenceVariableDefinitionState} />
      <DataEntry datatype='string' elementName='comparatorCategory' fieldLabel='Comparator Category'
        startingValue={evidenceVariableDefinitionState.comparatorCategory} setResourceState={setEvidenceVariableDefinitionState} />

      <DataEntry datatype='Reference' elementName='observed' fieldLabel='Observed'
        startingValue={evidenceVariableDefinitionState.observed} enableCreation={true}
        referencedResourceTypes={evidenceDotVariableDefinitionDotReferenceResourceTypes}
        setResourceState={setEvidenceVariableDefinitionState} />
      <DataEntry datatype='Reference' elementName='intended' fieldLabel='Intended'
        startingValue={evidenceVariableDefinitionState.intended} enableCreation={true}
        referencedResourceTypes={evidenceDotVariableDefinitionDotReferenceResourceTypes}
        setResourceState={setEvidenceVariableDefinitionState} />
      <DataEntry datatype='CodeableConcept' elementName='directnessMatch' fieldLabel='Directness Match'
        startingValue={evidenceVariableDefinitionState.directnessMatch}
        valueSet={evidenceDotVariableDefinitionDotDirectnessMatchValueSet}
        setResourceState={setEvidenceVariableDefinitionState} />
    </div>
  </>
})

const ListEntryEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingListEntry = {
    flag: "", deleted: "", date: "", item: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingListEntry.extension = startingValue.extension; }
    if (startingValue.flag) { startingListEntry.flag = startingValue.flag; }
    if (typeof startingValue.deleted === "boolean") { startingListEntry.deleted = startingValue.deleted; }
    if (startingValue.date) { startingListEntry.date = startingValue.date; }
    if (startingValue.item) { startingListEntry.item = startingValue.item; }
  }

  const [listEntryState, setListEntryState] = useState(startingListEntry);

  useEffect((() => {
    if (Object.keys(listEntryState).length > 0) {
      let newListEntry = {};
      if (listEntryState.extension) { newListEntry.extension = listEntryState.extension; }
      if (listEntryState.flag && Object.keys(listEntryState.flag).length > 0) {
        newListEntry.flag = listEntryState.flag;
      }
      if (typeof listEntryState.deleted === "boolean") { newListEntry.deleted = listEntryState.deleted; }
      if (listEntryState.date) { newListEntry.date = listEntryState.date; }
      if (listEntryState.item && Object.keys(listEntryState.item).length > 0) {
        newListEntry.item = listEntryState.item;
      }
      handleChange(elementName, newListEntry, setResourceState);
    }
  }), [listEntryState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableConcept' elementName='flag' fieldLabel='Flag'
        startingValue={listEntryState.flag}
        setResourceState={setListEntryState} />
      <DataEntry datatype='boolean' elementName='deleted' fieldLabel='Deleted'
        startingValue={listEntryState.deleted} setResourceState={setListEntryState} />
      <DataEntry datatype='dateTime' elementName='date' fieldLabel='Date added'
        startingValue={listEntryState.date} setResourceState={setListEntryState} />
      <DataEntry datatype='Reference' elementName='item' fieldLabel='The Item'
        startingValue={listEntryState.item} enableCreation={true}
        setResourceState={setListEntryState} />
    </div>
  </>
})

const PlanDefinitionActionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  return <p>Data entry form not yet created.</p>
})

const PlanDefinitionActorEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  return <p>Data entry form not yet created.</p>
})

const PlanDefinitionGoalEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  return <p>Data entry form not yet created.</p>
})

const researchStudyDotAssociatedPartyRoleValueSet = [
  { system: "http://hl7.org/fhir/research-study-party-role", code: "sponsor", display: "sponsor" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "lead-sponsor", display: "lead-sponsor" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "sponsor-investigator", display: "sponsor-investigator" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "primary-investigator", display: "primary-investigator" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "collaborator", display: "collaborator" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "funding-source", display: "funding-source" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "general-contact", display: "general-contact" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "recruitment-contact", display: "recruitment-contact" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "sub-investigator", display: "sub-investigator" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "study-director", display: "study-director" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "study-chair", display: "study-chair" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "irb", display: "irb" }

];
const ResearchStudyAssociatedPartyEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingPartyEntry = {
    name: "", role: "", period: [], classifier: [], party: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingPartyEntry.extension = startingValue.extension; }
    if (startingValue.name) { startingPartyEntry.name = startingValue.name; }
    if (startingValue.role) { startingPartyEntry.role = startingValue.role; }
    if (startingValue.period) { startingPartyEntry.period = startingValue.period; }
    if (startingValue.classifier) { startingPartyEntry.classifier = startingValue.classifier; }
    if (startingValue.party) { startingPartyEntry.party = startingValue.party; }
  }

  const [partyEntryState, setPartyEntryState] = useState(startingPartyEntry);

  useEffect((() => {
    if (Object.keys(partyEntryState).length > 0) {
      let newPartyEntry = {};
      if (partyEntryState.extension) { newPartyEntry.extension = partyEntryState.extension; }
      if (partyEntryState.name) { newPartyEntry.name = partyEntryState.name; }
      if (partyEntryState.role && Object.keys(partyEntryState.role).length > 0) {
        newPartyEntry.role = partyEntryState.role;
      }
      if (partyEntryState.period && Array.isArray(partyEntryState.period) && partyEntryState.period.length > 0) {
        newPartyEntry.period = partyEntryState.period;
      }
      if (partyEntryState.classifier && Array.isArray(partyEntryState.classifier) && partyEntryState.classifier.length > 0) {
        newPartyEntry.classifier = partyEntryState.classifier;
      }
      if (partyEntryState.party && Object.keys(partyEntryState.party).length > 0) {
        newPartyEntry.party = partyEntryState.party;
      }
      handleChange(elementName, newPartyEntry, setResourceState);
    }
  }), [partyEntryState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='name' fieldLabel='Name'
        startingValue={partyEntryState.name} setResourceState={setPartyEntryState} />
      <DataEntry datatype='CodeableConcept' elementName='role' fieldLabel='Role'
        valueSet={researchStudyDotAssociatedPartyRoleValueSet}
        startingValue={partyEntryState.role} setResourceState={setPartyEntryState} />
      <DataEntry asArray={true} datatype='Period' elementName='period' fieldLabel='Period'
        startingValue={partyEntryState.period} setResourceState={setPartyEntryState} />
      <DataEntry asArray={true} datatype='CodeableConcept' elementName='classifier' fieldLabel='Classifier'
        startingValue={partyEntryState.classifier} setResourceState={setPartyEntryState} />
      <DataEntry datatype='Reference' elementName='party' fieldLabel='Party (as Reference)'
        referencedResourceTypes={['Practitioner', 'PractitionerRole', 'Organization']}
        startingValue={partyEntryState.party} setResourceState={setPartyEntryState} />
    </div>
  </>
})

const researchStudyDotComparisonGroupTypeValueSet = [
  { system: "http://hl7.org/fhir/research-study-arm-type", code: "active-comparator", display: "Active Comparator" },
  { system: "http://hl7.org/fhir/research-study-arm-type", code: "placebo-comparator", display: "Placebo Comparator" },
  { system: "http://hl7.org/fhir/research-study-arm-type", code: "sham-comparator", display: "Sham Comparator" },
  { system: "http://hl7.org/fhir/research-study-arm-type", code: "no-intervention", display: "No Intervention" },
  { system: "http://hl7.org/fhir/research-study-arm-type", code: "experimental", display: "Experimental" },
  { system: "http://hl7.org/fhir/research-study-arm-type", code: "other-arm-type", display: "Other Arm Type" }
];
const ResearchStudyComparisonGroupEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingComparisonGroupEntry = {
    linkId: "", name: "", type: "", description: "", intendedExposure: [], observedGroup: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingComparisonGroupEntry.extension = startingValue.extension; }
    if (startingValue.linkId) { startingComparisonGroupEntry.linkId = startingValue.linkId; }
    if (startingValue.name) { startingComparisonGroupEntry.name = startingValue.name; }
    if (startingValue.type) { startingComparisonGroupEntry.type = startingValue.type; }
    if (startingValue.description) { startingComparisonGroupEntry.description = startingValue.description; }
    if (startingValue.intendedExposure) { startingComparisonGroupEntry.intendedExposure = startingValue.intendedExposure; }
    if (startingValue.observedGroup) { startingComparisonGroupEntry.observedGroup = startingValue.observedGroup; }
  }

  const [comparisonGroupEntryState, setComparisonGroupEntryState] = useState(startingComparisonGroupEntry);

  useEffect((() => {
    if (Object.keys(comparisonGroupEntryState).length > 0) {
      let newGroupEntry = {};
      if (comparisonGroupEntryState.extension) { newGroupEntry.extension = comparisonGroupEntryState.extension; }
      if (comparisonGroupEntryState.linkId) { newGroupEntry.linkId = comparisonGroupEntryState.linkId; }
      if (comparisonGroupEntryState.name) { newGroupEntry.name = comparisonGroupEntryState.name; }
      if (comparisonGroupEntryState.type && Object.keys(comparisonGroupEntryState.type).length > 0) {
        newGroupEntry.type = comparisonGroupEntryState.type;
      }
      if (comparisonGroupEntryState.description) { newGroupEntry.description = comparisonGroupEntryState.description; }
      if (comparisonGroupEntryState.intendedExposure && Array.isArray(comparisonGroupEntryState.intendedExposure) &&
        comparisonGroupEntryState.intendedExposure.length > 0) {
        newGroupEntry.intendedExposure = comparisonGroupEntryState.intendedExposure;
      }
      if (comparisonGroupEntryState.observedGroup && Object.keys(comparisonGroupEntryState.observedGroup).length > 0) {
        newGroupEntry.observedGroup = comparisonGroupEntryState.observedGroup;
      }
      handleChange(elementName, newGroupEntry, setResourceState);
    }
  }), [comparisonGroupEntryState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='id' elementName='linkId' fieldLabel='Id'
        startingValue={comparisonGroupEntryState.linkId} setResourceState={setComparisonGroupEntryState} />
      <DataEntry datatype='string' elementName='name' fieldLabel='Name'
        startingValue={comparisonGroupEntryState.name} setResourceState={setComparisonGroupEntryState} />
      <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type'
        valueSet={researchStudyDotComparisonGroupTypeValueSet}
        startingValue={comparisonGroupEntryState.type} setResourceState={setComparisonGroupEntryState} />
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={comparisonGroupEntryState.description} setResourceState={setComparisonGroupEntryState} />
      <DataEntry asArray={true} datatype='Reference'
        elementName='intendedExposure' fieldLabel='Intended Exposure'
        referencedResourceTypes={['EvidenceVariable',]}
        startingValue={comparisonGroupEntryState.intendedExposure} setResourceState={setComparisonGroupEntryState} />
      <DataEntry datatype='Reference' elementName='observedGroup' fieldLabel='Observed Group'
        referencedResourceTypes={['Group']}
        startingValue={comparisonGroupEntryState.observedGroup} setResourceState={setComparisonGroupEntryState} />
    </div>
  </>
})

const researchStudyDotLabelValueSet = [
  { system: "http://hl7.org/fhir/title-type", code: "primary", display: "Primary title" },
  { system: "http://hl7.org/fhir/title-type", code: "official", display: "Official title" },
  { system: "http://hl7.org/fhir/title-type", code: "scientific", display: "Scientific title" },
  { system: "http://hl7.org/fhir/title-type", code: "plain-language", display: "Plain language title" },
  { system: "http://hl7.org/fhir/title-type", code: "subtitle", display: "Subtitle" },
  { system: "http://hl7.org/fhir/title-type", code: "short-title", display: "Short title" },
  { system: "http://hl7.org/fhir/title-type", code: "acronym", display: "Acronym" },
  { system: "http://hl7.org/fhir/title-type", code: "earlier-title", display: "Different text in an earlier version" },
  { system: "http://hl7.org/fhir/title-type", code: "language", display: "Different language" },
  { system: "http://hl7.org/fhir/title-type", code: "autotranslated", display: "Different language derived from autotranslation" },
  { system: "http://hl7.org/fhir/title-type", code: "human-use", display: "Human use" },
  { system: "http://hl7.org/fhir/title-type", code: "machine-use", display: "Machine use" },
  { system: "http://hl7.org/fhir/title-type", code: "duplicate-uid", display: "Different text for the same object with a different identifier" }
];
const ResearchStudyLabelEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingLabelEntry = {
    type: "", value: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingLabelEntry.extension = startingValue.extension; }
    if (startingValue.type) { startingLabelEntry.type = startingValue.type; }
    if (startingValue.value) { startingLabelEntry.value = startingValue.value; }
  }

  const [labelEntryState, setLabelEntryState] = useState(startingLabelEntry);

  useEffect((() => {
    if (Object.keys(labelEntryState).length > 0) {
      let newLabelEntry = {};
      if (labelEntryState.extension) { newLabelEntry.extension = labelEntryState.extension; }
      if (labelEntryState.type && Object.keys(labelEntryState.type).length > 0) {
        newLabelEntry.type = labelEntryState.type;
      }
      if (labelEntryState.value) { newLabelEntry.value = labelEntryState.value; }
      handleChange(elementName, newLabelEntry, setResourceState);
    }
  }), [labelEntryState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Label type'
        valueSet={researchStudyDotLabelValueSet}
        startingValue={labelEntryState.type} setResourceState={setLabelEntryState} />
      <DataEntry datatype='string' elementName='value' fieldLabel='Label value'
        startingValue={labelEntryState.value} setResourceState={setLabelEntryState} />
    </div>
  </>
})

const EventHandlingEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingEventHandlingEntry = {
    event: "", group: "", handling: "", description: ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) {
      for (const ext of startingValue.extension) {
        if (ext.url === "event") {
          startingEventHandlingEntry.event = ext.valueCodeableConcept;
        } else if (ext.url === "group") {
          startingEventHandlingEntry.group = ext.valueCodeableConcept;
        } else if (ext.url === "handling") {
          startingEventHandlingEntry.handling = ext.valueCodeableConcept;
        } else if (ext.url === "description") {
          startingEventHandlingEntry.description = ext.valueMarkdown;
        }
      }
    }
  }

  const [eventHandlingState, setEventHandlingState] = useState(startingEventHandlingEntry);

  useEffect((() => {
    if (Object.keys(eventHandlingState).length > 0) {
      let newEventHandling = {
        extension: [], url: "eventHandling"
      };
      if (eventHandlingState.event) {
        newEventHandling.extension.push({ "url": "event", "valueCodeableConcept": eventHandlingState.event });
      }
      if (eventHandlingState.group) {
        newEventHandling.extension.push({ "url": "group", "valueCodeableConcept": eventHandlingState.group });
      }
      if (eventHandlingState.handling) {
        newEventHandling.extension.push({ "url": "handling", "valueCodeableConcept": eventHandlingState.handling });
      }
      if (eventHandlingState.description) {
        newEventHandling.extension.push({ "url": "description", "valueMarkdown": eventHandlingState.description });
      }
      handleChange(elementName, newEventHandling, setResourceState);
    }
  }), [eventHandlingState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableConcept' elementName='event' fieldLabel='Event'
        startingValue={eventHandlingState.event} setResourceState={setEventHandlingState} />
      <DataEntry datatype='CodeableConcept' elementName='group' fieldLabel='Group'
        startingValue={eventHandlingState.group} setResourceState={setEventHandlingState} />
      <DataEntry datatype='CodeableConcept' elementName='handling' fieldLabel='Handling'
        startingValue={eventHandlingState.handling} setResourceState={setEventHandlingState} />
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={eventHandlingState.description} setResourceState={setEventHandlingState} />
    </div>
  </>
})

const StudyAmendmentEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingStudyAmendment = {
    details: "",
    scope: "",
    description: "",
    rationale: "",
    substantialImpactSafety: "",
    substantialImpactSafetyComment: "",
    substantialImpactReliability: "",
    substantialImpactReliabilityComment: "",
    primaryReason: ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) {
      for (const ext of startingValue.extension) {
        if (ext.url === "details") {
          startingStudyAmendment.details = ext.valueString;
        } else if (ext.url === "scope") {
          startingStudyAmendment.scope = ext.valueString;
        } else if (ext.url === "description") {
          startingStudyAmendment.description = ext.valueString;
        } else if (ext.url === "rationale") {
          startingStudyAmendment.rationale = ext.valueString;
        } else if (ext.url === "substantialImpactSafety") {
          startingStudyAmendment.substantialImpactSafety = ext.valueBoolean;
        } else if (ext.url === "substantialImpactSafetyComment") {
          startingStudyAmendment.substantialImpactSafetyComment = ext.valueString;
        } else if (ext.url === "substantialImpactReliability") {
          startingStudyAmendment.substantialImpactReliability = ext.valueBoolean;
        } else if (ext.url === "substantialImpactReliabilityComment") {
          startingStudyAmendment.substantialImpactReliabilityComment = ext.valueString;
        } else if (ext.url === "primaryReason") {
          startingStudyAmendment.primaryReason = ext.valueCodeableConcept;
        }
      }
    }
  }

  const [studyAmendmentState, setStudyAmendmentState] = useState(startingStudyAmendment);

  useEffect((() => {
    if (Object.keys(studyAmendmentState).length > 0) {
      let newStudyAmendment = {
        extension: [], url: "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-study-amendment"
      };
      if (studyAmendmentState.details) {
        newStudyAmendment.extension.push({ "url": "details", "valueString": studyAmendmentState.details });
      }
      if (studyAmendmentState.scope) {
        newStudyAmendment.extension.push({ "url": "scope", "valueId": studyAmendmentState.scope });
      }
      if (studyAmendmentState.description) {
        newStudyAmendment.extension.push({ "url": "description", "valueString": studyAmendmentState.description });
      }
      if (studyAmendmentState.rationale) {
        newStudyAmendment.extension.push({ "url": "rationale", "valueString": studyAmendmentState.rationale });
      }
      if (studyAmendmentState.substantialImpactSafety === true || studyAmendmentState.substantialImpactSafety === false) {
        newStudyAmendment.extension.push({ "url": "substantialImpactSafety", "valueBoolean": studyAmendmentState.substantialImpactSafety });
      }
      if (studyAmendmentState.substantialImpactSafetyComment) {
        newStudyAmendment.extension.push({ "url": "substantialImpactSafetyComment", "valueString": studyAmendmentState.substantialImpactSafetyComment });
      }
      if (studyAmendmentState.substantialImpactReliability === true || studyAmendmentState.substantialImpactReliability === false) {
        newStudyAmendment.extension.push({ "url": "substantialImpactReliability", "valueBoolean": studyAmendmentState.substantialImpactReliability });
      }
      if (studyAmendmentState.substantialImpactReliabilityComment) {
        newStudyAmendment.extension.push({ "url": "substantialImpactReliabilityComment", "valueString": studyAmendmentState.substantialImpactReliabilityComment });
      }
      if (studyAmendmentState.primaryReason) {
        newStudyAmendment.extension.push({ "url": "primaryReason", "valueCodeableConcept": studyAmendmentState.primaryReason });
      }
      handleChange(elementName, newStudyAmendment, setResourceState);
    }
  }), [studyAmendmentState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='details' fieldLabel='Details'
        startingValue={studyAmendmentState.details} setResourceState={setStudyAmendmentState} />
      <DataEntry datatype='string' elementName='scope' fieldLabel='Scope'
        startingValue={studyAmendmentState.scope} setResourceState={setStudyAmendmentState} />
      <DataEntry datatype='string' elementName='description' fieldLabel='Description'
        startingValue={studyAmendmentState.description} setResourceState={setStudyAmendmentState} />
      <DataEntry datatype='string' elementName='rationale' fieldLabel='Rationale'
        startingValue={studyAmendmentState.rationale} setResourceState={setStudyAmendmentState} />
      <DataEntry datatype='boolean' elementName='substantialImpactSafety' fieldLabel='Substantial Impact on Safety'
        storeFalse
        startingValue={studyAmendmentState.substantialImpactSafety} setResourceState={setStudyAmendmentState} />
      <DataEntry datatype='string' elementName='substantialImpactSafetyComment' fieldLabel='Substantial Impact on Safety Comment'
        startingValue={studyAmendmentState.substantialImpactSafetyComment} setResourceState={setStudyAmendmentState} />
      <DataEntry datatype='boolean' elementName='substantialImpactReliability' fieldLabel='Substantial Impact on Reliability'
        storeFalse
        startingValue={studyAmendmentState.substantialImpactReliability} setResourceState={setStudyAmendmentState} />
      <DataEntry datatype='string' elementName='substantialImpactReliabilityComment' fieldLabel='Substantial Impact on Reliability Comment'
        startingValue={studyAmendmentState.substantialImpactReliabilityComment} setResourceState={setStudyAmendmentState} />
      <DataEntry datatype='CodeableConcept' elementName='primaryReason' fieldLabel='Primary Reason'
        startingValue={studyAmendmentState.primaryReason} setResourceState={setStudyAmendmentState} />
    </div>
  </>
})

const EstimandEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingEstimandEntry = {
    label: "", type: "", endpoint: "", population: "", intervention: "", comparator: "", summaryMeasure: "", eventHandling: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) {
      for (const ext of startingValue.extension) {
        if (ext.url === "label") {
          startingEstimandEntry.label = ext.valueString;
        } else if (ext.url === "type") {
          startingEstimandEntry.type = ext.valueCodeableConcept;
        } else if (ext.url === "endpoint") {
          startingEstimandEntry.endpoint = ext.valueReference;
        } else if (ext.url === "population") {
          startingEstimandEntry.population = ext.valueReference;
        } else if (ext.url === "intervention") {
          startingEstimandEntry.intervention = ext.valueId;
        } else if (ext.url === "comparator") {
          startingEstimandEntry.comparator = ext.valueId;
        } else if (ext.url === "summaryMeasure") {
          startingEstimandEntry.summaryMeasure = ext.valueCodeableConcept;
        } else if (ext.url === "eventHandling") {
          startingEstimandEntry.eventHandling.push(ext);
        }
      }
    }
  }

  const [estimandState, setEstimandState] = useState(startingEstimandEntry);

  useEffect((() => {
    if (Object.keys(estimandState).length > 0) {
      let newEndpointExtension = {
        extension: [], url: "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-estimand"
      };
      if (estimandState.label) {
        newEndpointExtension.extension.push({ "url": "label", "valueString": estimandState.label });
      }
      if (estimandState.type) {
        newEndpointExtension.extension.push({ "url": "type", "valueCodeableConcept": estimandState.type });
      }
      if (estimandState.endpoint) {
        newEndpointExtension.extension.push({ "url": "endpoint", "valueReference": estimandState.endpoint });
      }
      if (estimandState.population) {
        newEndpointExtension.extension.push({ "url": "population", "valueReference": estimandState.population });
      }
      if (estimandState.intervention) {
        newEndpointExtension.extension.push({ "url": "intervention", "valueId": estimandState.intervention });
      }
      if (estimandState.comparator) {
        newEndpointExtension.extension.push({ "url": "comparator", "valueId": estimandState.comparator });
      }
      if (estimandState.summaryMeasure) {
        newEndpointExtension.extension.push({ "url": "summaryMeasure", "valueCodeableConcept": estimandState.summaryMeasure });
      }
      if (estimandState.eventHandling && Array.isArray(estimandState.eventHandling) &&
        estimandState.eventHandling.length > 0) {
        newEndpointExtension.extension = newEndpointExtension.extension.concat(estimandState.eventHandling);
      }
      handleChange(elementName, newEndpointExtension, setResourceState);
    }
  }), [estimandState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='label' fieldLabel='Short name or identifier'
        startingValue={estimandState.label} setResourceState={setEstimandState} />
        <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type'
          valueSet={researchStudyDotObjectiveTypeValueSet}
          startingValue={estimandState.type} setResourceState={setEstimandState} />
      <DataEntry datatype='Reference' elementName='endpoint' fieldLabel='Endpoint'
        referencedResourceTypes={['EvidenceVariable', 'ObservationDefinition', 'PlanDefinition']}
        startingValue={estimandState.endpoint} setResourceState={setEstimandState} />
      <DataEntry datatype='Reference' elementName='population' fieldLabel='Population'
        referencedResourceTypes={['Group']}
        startingValue={estimandState.population} setResourceState={setEstimandState} />
      <DataEntry datatype='id' elementName='intervention' fieldLabel='Intervention'
        startingValue={estimandState.intervention} setResourceState={setEstimandState} />
      <DataEntry datatype='id' elementName='comparator' fieldLabel='Comparator'
        startingValue={estimandState.comparator} setResourceState={setEstimandState} />
      <DataEntry datatype='CodeableConcept' elementName='summaryMeasure' fieldLabel='Summary Measure'
        valueSet={SEVCO.statisticType}
        startingValue={estimandState.summaryMeasure} setResourceState={setEstimandState} />
      <DataEntry asArray={true} datatype='EventHandling' elementName='eventHandling' fieldLabel='Handling of Intercurrent Events'
        startingValue={estimandState.eventHandling} setResourceState={setEstimandState} />
    </div>
  </>
})

const researchStudyDotObjectiveTypeValueSet = [
  { system: "http://hl7.org/fhir/research-study-objective-type", code: "primary", display: "Primary" },
  { system: "http://hl7.org/fhir/research-study-objective-type", code: "secondary", display: "Secondary" },
  { system: "http://hl7.org/fhir/research-study-objective-type", code: "exploratory", display: "Exploratory" }
];
const ResearchStudyObjectiveEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingOutcomeEntry = {
    extension: [], name: "", type: "", description: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingOutcomeEntry.extension = startingValue.extension; }
    if (startingValue.name) { startingOutcomeEntry.name = startingValue.name; }
    if (startingValue.type) { startingOutcomeEntry.type = startingValue.type; }
    if (startingValue.description) { startingOutcomeEntry.description = startingValue.description; }
  }
  let startingExtensionStateValue = {
    "otherExtensions": [], "estimand": []
  }
  if (Array.isArray(startingOutcomeEntry.extension) && startingOutcomeEntry.extension.length > 0) {
    for (const extension of startingOutcomeEntry.extension) {
      switch (extension.url) {
        case 'http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-estimand':
          startingExtensionStateValue.estimand.push(extension);
          break;
        default:
          startingExtensionStateValue.otherExtensions.push(extension)
      }
    }
  }

  const [outcomeMeasureEntryState, setOutcomeMeasureEntryState] = useState(startingOutcomeEntry);
  const [extensionState, setExtensionState] = useState(startingExtensionStateValue);

  useEffect((() => {
    if (Object.keys(outcomeMeasureEntryState).length > 0) {
      let newOutcomeEntry = {};
      if (outcomeMeasureEntryState.extension) { newOutcomeEntry.extension = outcomeMeasureEntryState.extension; }
      if (outcomeMeasureEntryState.name) { newOutcomeEntry.name = outcomeMeasureEntryState.name; }
      if (outcomeMeasureEntryState.type && Object.keys(outcomeMeasureEntryState.type).length > 0) {
        newOutcomeEntry.type = outcomeMeasureEntryState.type;
      }
      if (outcomeMeasureEntryState.description) { newOutcomeEntry.description = outcomeMeasureEntryState.description; }
      handleChange(elementName, newOutcomeEntry, setResourceState);
    }
  }), [outcomeMeasureEntryState]);


  useEffect(() => {
    let newExtension = [];
    if (extensionState.otherExtensions) {
      newExtension = JSON.parse(JSON.stringify(extensionState.otherExtensions));
    }
    if (extensionState.estimand && extensionState.estimand.length > 0) {
      for (const instance of extensionState.estimand) {
        newExtension.push(instance);
      }
    }
    setOutcomeMeasureEntryState(prevState => { return { ...prevState, "extension": newExtension } });
  }, [extensionState])

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='name' fieldLabel='Name'
        startingValue={outcomeMeasureEntryState.name} setResourceState={setOutcomeMeasureEntryState} />
      <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type'
        valueSet={researchStudyDotObjectiveTypeValueSet}
        startingValue={outcomeMeasureEntryState.type} setResourceState={setOutcomeMeasureEntryState} />
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={outcomeMeasureEntryState.description} setResourceState={setOutcomeMeasureEntryState} />
      <DataEntry asArray={true} datatype="Estimand" elementName='estimand' fieldLabel='Endpoint/Estimand'
        startingValue={extensionState.estimand || null} setResourceState={setExtensionState}
        startCollapsed={true} />
    </div>
  </>
})

const ResearchStudyOutcomeMeasureEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingOutcomeEntry = {
    name: "", type: "", description: "", reference: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingOutcomeEntry.extension = startingValue.extension; }
    if (startingValue.name) { startingOutcomeEntry.name = startingValue.name; }
    if (startingValue.type) { startingOutcomeEntry.type = startingValue.type; }
    if (startingValue.description) { startingOutcomeEntry.description = startingValue.description; }
    if (startingValue.reference) { startingOutcomeEntry.reference = startingValue.reference; }
  }

  const [outcomeMeasureEntryState, setOutcomeMeasureEntryState] = useState(startingOutcomeEntry);

  useEffect((() => {
    if (Object.keys(outcomeMeasureEntryState).length > 0) {
      let newOutcomeEntry = {};
      if (outcomeMeasureEntryState.extension) { newOutcomeEntry.extension = outcomeMeasureEntryState.extension; }
      if (outcomeMeasureEntryState.name) { newOutcomeEntry.name = outcomeMeasureEntryState.name; }
      if (outcomeMeasureEntryState.type && Object.keys(outcomeMeasureEntryState.type).length > 0) {
        newOutcomeEntry.type = outcomeMeasureEntryState.type;
      }
      if (outcomeMeasureEntryState.description) { newOutcomeEntry.description = outcomeMeasureEntryState.description; }
      if (outcomeMeasureEntryState.reference && Object.keys(outcomeMeasureEntryState.reference).length > 0) {
        newOutcomeEntry.reference = outcomeMeasureEntryState.reference;
      }
      handleChange(elementName, newOutcomeEntry, setResourceState);
    }
  }), [outcomeMeasureEntryState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='name' fieldLabel='Name'
        startingValue={outcomeMeasureEntryState.name} setResourceState={setOutcomeMeasureEntryState} />
      <DataEntry asArray={true} datatype='CodeableConcept' elementName='type' fieldLabel='Type'
        valueSet={researchStudyDotObjectiveTypeValueSet}
        startingValue={outcomeMeasureEntryState.type} setResourceState={setOutcomeMeasureEntryState} />
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={outcomeMeasureEntryState.description} setResourceState={setOutcomeMeasureEntryState} />
      <DataEntry datatype='Reference' elementName='reference' fieldLabel='Outcome Definition'
        referencedResourceTypes={['EvidenceVariable']}
        startingValue={outcomeMeasureEntryState.reference} setResourceState={setOutcomeMeasureEntryState} />
    </div>
  </>
})

const researchStudyDotProgressStatusValueSet = [
  { system: "http://hl7.org/fhir/research-study-status", code: "overall-study", display: "Overall study" },
  { system: "http://hl7.org/fhir/research-study-status", code: "active", display: "Active" },
  { system: "http://hl7.org/fhir/research-study-status", code: "active-but-not-recruiting", display: "Active, not recruiting" },
  { system: "http://hl7.org/fhir/research-study-status", code: "administratively-completed", display: "Administratively Completed" },
  { system: "http://hl7.org/fhir/research-study-status", code: "approved", display: "Approved" },
  { system: "http://hl7.org/fhir/research-study-status", code: "closed-to-accrual", display: "Closed to Accrual" },
  { system: "http://hl7.org/fhir/research-study-status", code: "closed-to-accrual-and-intervention", display: "Closed to Accrual and Intervention" },
  { system: "http://hl7.org/fhir/research-study-status", code: "completed", display: "Completed" },
  { system: "http://hl7.org/fhir/research-study-status", code: "disapproved", display: "Disapproved" },
  { system: "http://hl7.org/fhir/research-study-status", code: "enrolling-by-invitation", display: "Enrolling by invitation" },
  { system: "http://hl7.org/fhir/research-study-status", code: "in-review", display: "In Review" },
  { system: "http://hl7.org/fhir/research-study-status", code: "not-yet-recruiting", display: "Not yet recruiting" },
  { system: "http://hl7.org/fhir/research-study-status", code: "recruiting", display: "Recruiting" },
  { system: "http://hl7.org/fhir/research-study-status", code: "temporarily-closed-to-accrual", display: "Temporarily Closed to Accrual" },
  { system: "http://hl7.org/fhir/research-study-status", code: "temporarily-closed-to-accrual-and-intervention", display: "Temporarily Closed to Accrual and Intervention" },
  { system: "http://hl7.org/fhir/research-study-status", code: "terminated", display: "Terminated" },
  { system: "http://hl7.org/fhir/research-study-status", code: "withdrawn", display: "Withdrawn" }
];
const ResearchStudyProgressStatusEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingProgressStatusEntry = {
    state: "", actual: "", period: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingProgressStatusEntry.extension = startingValue.extension; }
    if (startingValue.state) { startingProgressStatusEntry.state = startingValue.state; }
    if (typeof startingValue.actual === "boolean") { startingProgressStatusEntry.actual = startingValue.actual; }
    if (startingValue.period) { startingProgressStatusEntry.period = startingValue.period; }
  }

  const [progressStatusEntryState, setProgressStatusEntryState] = useState(startingProgressStatusEntry);

  useEffect((() => {
    if (Object.keys(progressStatusEntryState).length > 0) {
      let newProgressEntry = {};
      if (progressStatusEntryState.extension) { newProgressEntry.extension = progressStatusEntryState.extension; }
      if (progressStatusEntryState.state && Object.keys(progressStatusEntryState.state).length > 0) {
        newProgressEntry.state = progressStatusEntryState.state;
      }
      if (typeof progressStatusEntryState.actual === "boolean") {
        newProgressEntry.actual = progressStatusEntryState.actual;
      }
      if (progressStatusEntryState.period && Object.keys(progressStatusEntryState.period).length > 0) {
        newProgressEntry.period = progressStatusEntryState.period;
      }
      handleChange(elementName, newProgressEntry, setResourceState);
    }
  }), [progressStatusEntryState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableConcept' elementName='state' fieldLabel='Progress state'
        valueSet={researchStudyDotProgressStatusValueSet}
        startingValue={progressStatusEntryState.state} setResourceState={setProgressStatusEntryState} />
      <DataEntry datatype='boolean' elementName='actual' fieldLabel='Actual (false if Anticipated)'
        storeFalse={true}
        startingValue={progressStatusEntryState.actual} setResourceState={setProgressStatusEntryState} />
      <DataEntry datatype='Period' elementName='period' fieldLabel='Period (date range)'
        startingValue={progressStatusEntryState.period} setResourceState={setProgressStatusEntryState} />
    </div>
  </>
})

const ResearchStudyRecruitmentEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingRecruitmentEntry = {
    targetNumber: "", actualNumber: "", eligibility: "", actualGroup: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingRecruitmentEntry.extension = startingValue.extension; }
    if (startingValue.targetNumber || startingValue.targetNumber === 0) { startingRecruitmentEntry.targetNumber = startingValue.targetNumber; }
    if (startingValue.actualNumber || startingValue.actualNumber === 0) { startingRecruitmentEntry.actualNumber = startingValue.actualNumber; }
    if (startingValue.eligibility) { startingRecruitmentEntry.eligibility = startingValue.eligibility; }
    if (startingValue.actualGroup) { startingRecruitmentEntry.actualGroup = startingValue.actualGroup; }
  }

  const [recruitmentEntryState, setRecruitmentEntryState] = useState(startingRecruitmentEntry);

  useEffect((() => {
    if (Object.keys(recruitmentEntryState).length > 0) {
      let newRecruitmentEntry = {};
      if (recruitmentEntryState.extension) { newRecruitmentEntry.extension = recruitmentEntryState.extension; }
      if (recruitmentEntryState.targetNumber || recruitmentEntryState.targetNumber === 0) { newRecruitmentEntry.targetNumber = recruitmentEntryState.targetNumber; }
      if (recruitmentEntryState.actualNumber || recruitmentEntryState.actualNumber === 0) { newRecruitmentEntry.actualNumber = recruitmentEntryState.actualNumber; }
      if (recruitmentEntryState.eligibility && Object.keys(recruitmentEntryState.eligibility).length > 0) {
        newRecruitmentEntry.eligibility = recruitmentEntryState.eligibility;
      }
      if (recruitmentEntryState.actualGroup && Object.keys(recruitmentEntryState.actualGroup).length > 0) {
        newRecruitmentEntry.actualGroup = recruitmentEntryState.actualGroup;
      }
      handleChange(elementName, newRecruitmentEntry, setResourceState);
    }
  }), [recruitmentEntryState]);

  return <>
    <div style={{ marginLeft: "2px" }}>
      <DataEntry datatype='unsignedInt' elementName='targetNumber' fieldLabel='Target number of participants'
        startingValue={recruitmentEntryState.targetNumber} setResourceState={setRecruitmentEntryState} />
      <DataEntry datatype='unsignedInt' elementName='actualNumber' fieldLabel='Actual number of participants'
        startingValue={recruitmentEntryState.actualNumber} setResourceState={setRecruitmentEntryState} />
      <DataEntry datatype='Reference'
        elementName='eligibility' fieldLabel='Eligibility Criteria'
        referencedResourceTypes={['Group', 'EvidenceVariable']} enableCreation={true}
        startingValue={recruitmentEntryState.eligibility} setResourceState={setRecruitmentEntryState} />
      <DataEntry datatype='Reference'
        elementName='actualGroup' fieldLabel='Enrollment Group'
        referencedResourceTypes={['Group']} enableCreation={true}
        startingValue={recruitmentEntryState.actualGroup} setResourceState={setRecruitmentEntryState} />
    </div>
  </>
})

const ValueSetComposeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingValueSetCompose = { lockedDate: "", inactive: "", include: [], exclude: [], property: [] };
  if (!startingValue) {
    startingValueSetCompose = {};
  } else {
    if (startingValue.extension) { startingValueSetCompose.extension = startingValue.extension; }
    if (startingValue.lockedDate) { startingValueSetCompose.lockedDate = startingValue.lockedDate; }
    if (typeof startingValue.inactive === "boolean") { startingValueSetCompose.inactive = startingValue.inactive; }
    if (startingValue.include) { startingValueSetCompose.include = startingValue.include; }
    if (startingValue.exclude) { startingValueSetCompose.exclude = startingValue.exclude; }
    if (startingValue.property) { startingValueSetCompose.property = startingValue.property; }
  }

  const [valueSetComposeState, setValueSetComposeState] = useState(startingValueSetCompose);

  useEffect(() => {
    if (Object.keys(valueSetComposeState).length) {
      let newValueSetCompose = {};
      if (valueSetComposeState.extension) { newValueSetCompose.extension = valueSetComposeState.extension; }
      if (valueSetComposeState.lockedDate) { newValueSetCompose.lockedDate = valueSetComposeState.lockedDate; }
      if (typeof valueSetComposeState.inactive === "boolean") { newValueSetCompose.inactive = valueSetComposeState.inactive; }
      if (valueSetComposeState.include && Array.isArray(valueSetComposeState.include) && valueSetComposeState.include.length > 0) {
        newValueSetCompose.include = valueSetComposeState.include;
      }
      if (valueSetComposeState.exclude && Array.isArray(valueSetComposeState.exclude) && valueSetComposeState.exclude.length > 0) {
        newValueSetCompose.exclude = valueSetComposeState.exclude;
      }
      if (valueSetComposeState.property && Array.isArray(valueSetComposeState.property) && valueSetComposeState.property.length > 0) {
        newValueSetCompose.property = valueSetComposeState.property;
      }
      if (Object.keys(newValueSetCompose).length === 0) {
        newValueSetCompose = null;
      }
      handleChange(elementName, newValueSetCompose, setResourceState);
    }
  }, [valueSetComposeState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry asArray={true} datatype='ValueSetComposeInclude' elementName='include' fieldLabel='Include'
        deletable={true}
        startingValue={valueSetComposeState.include} setResourceState={setValueSetComposeState} />
      <DataEntry asArray={true} datatype='ValueSetComposeInclude' elementName='exclude' fieldLabel='Exclude'
        deletable={true} deletableArray={true}
        startingValue={valueSetComposeState.exclude} setResourceState={setValueSetComposeState} />
    </div>
  </>
})

const importTermsToValueSet = async (globalContext, valueSetComposeIncludeState, codeSystemTermConversionState, setCodeSystemTermConversionState) => {

  if (valueSetComposeIncludeState.system?.substring(0, 39) === 'https://fevir.net/resources/CodeSystem/') {
    let codeSystemFevirId = valueSetComposeIncludeState.system.substring(39);

    const body = {
      'functionid': "codesystemtermstovaluesetconcept",
      'idToken': "",
      'resourceid': codeSystemFevirId,
      'termcode': codeSystemTermConversionState.termcode,
      'recursive': codeSystemTermConversionState.recursive
    };

    let response = await submitToFevirServer(globalContext, 10000, body, true, false);

    let codecode = codeSystemTermConversionState.termcode;
    if (codecode === "") {
      codecode = "TOP";
    }
    if (response?.success && response.valuesetincludeinstance) {
      if (response.valuesetincludeinstance.concept?.length > 0) {
        setCodeSystemTermConversionState(prevState => {
          return {
            ...prevState,
            system: response.valuesetincludeinstance.system,
            version: response.valuesetincludeinstance.version,
            concept: response.valuesetincludeinstance.concept
          };
        });
      } else {
        alert("That term doesn't have any child terms.")
      }
    } else {
      alert("Term import failed. Perhaps due to not finding the CodeSystem or code.");
    }
  } else {
    alert("System URL field can't be blank and it needs to start with https://fevir.net/resources/CodeSystem/.");
  }
}

const ValueSetComposeIncludeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingValueSetComposeInclude = { system: "", version: "", concept: [], filter: [], valueSet: [], copyright: "" };
  let containsConcepts = false;
  if (!startingValue) {
    startingValueSetComposeInclude = { concept: [] };
  } else {
    if (startingValue.extension) { startingValueSetComposeInclude.extension = startingValue.extension; }
    if (startingValue.system) { startingValueSetComposeInclude.system = startingValue.system; }
    if (startingValue.version) { startingValueSetComposeInclude.version = startingValue.version; }
    if (startingValue.concept) {
      startingValueSetComposeInclude.concept = startingValue.concept;
      if (startingValue.concept.length > 0) {
        containsConcepts = true;
      }
    }
    if (startingValue.filter) { startingValueSetComposeInclude.filter = startingValue.filter; }
    if (startingValue.valueSet) { startingValueSetComposeInclude.valueSet = startingValue.valueSet; }
    if (startingValue.copyright) { startingValueSetComposeInclude.copyright = startingValue.copyright; }
  }

  const globalContext = useContext(FevirContext);
  const [valueSetComposeIncludeState, setValueSetComposeIncludeState] = useState(startingValueSetComposeInclude);

  let startingCodeSystemTermConversionState = {
    'resourceid': "",
    'system': "",
    'version': "",
    'concept': [],
    'termcode': "",
    'recursive': false
  }
  if (valueSetComposeIncludeState.system?.substring(0, 39) === 'https://fevir.net/resources/CodeSystem/') {
    let codeSystemFevirId = valueSetComposeIncludeState.system.substring(39);
    startingCodeSystemTermConversionState["resourceid"] = codeSystemFevirId;
  }
  if (valueSetComposeIncludeState.version) {
    startingCodeSystemTermConversionState["version"] = valueSetComposeIncludeState.version;
  }
  const [codeSystemTermConversionState, setCodeSystemTermConversionState] = useState(startingCodeSystemTermConversionState);
  const [directConceptEntryState, setDirectConceptEntryState] = useState(containsConcepts);

  useEffect(() => {
    if (Object.keys(valueSetComposeIncludeState).length) {
      let newValueSetComposeInclude = {};
      if (valueSetComposeIncludeState.extension) { newValueSetComposeInclude.extension = valueSetComposeIncludeState.extension; }
      if (valueSetComposeIncludeState.system) {
        newValueSetComposeInclude.system = valueSetComposeIncludeState.system;
      }
      if (valueSetComposeIncludeState.version) {
        newValueSetComposeInclude.version = valueSetComposeIncludeState.version;
      }
      if (valueSetComposeIncludeState.concept && Array.isArray(valueSetComposeIncludeState.concept) && valueSetComposeIncludeState.concept.length > 0) {
        newValueSetComposeInclude.concept = valueSetComposeIncludeState.concept;
      }
      if (valueSetComposeIncludeState.filter && Array.isArray(valueSetComposeIncludeState.filter) && valueSetComposeIncludeState.filter.length > 0) {
        newValueSetComposeInclude.filter = valueSetComposeIncludeState.filter;
      }
      if (valueSetComposeIncludeState.valueSet && Array.isArray(valueSetComposeIncludeState.valueSet) && valueSetComposeIncludeState.valueSet.length > 0) {
        newValueSetComposeInclude.valueSet = valueSetComposeIncludeState.valueSet;
      }
      if (valueSetComposeIncludeState.copyright) { newValueSetComposeInclude.copyright = valueSetComposeIncludeState.copyright; }
      if (Object.keys(newValueSetComposeInclude).length === 0) {
        newValueSetComposeInclude = null;
      }
      handleChange(elementName, newValueSetComposeInclude, setResourceState);
    }
  }, [valueSetComposeIncludeState]);

  useEffect(() => {
    if (codeSystemTermConversionState.system && codeSystemTermConversionState.concept.length > 0) {
      setDirectConceptEntryState(true);
      setValueSetComposeIncludeState(prevState => {
        return {
          ...prevState,
          version: codeSystemTermConversionState.version,
          concept: codeSystemTermConversionState.concept
        };
      });
    }
  }, [codeSystemTermConversionState])

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='uri' elementName='system' fieldLabel='Code System'
        startingValue={valueSetComposeIncludeState.system} setResourceState={setValueSetComposeIncludeState} />
      <DataEntry datatype='string' elementName='version' fieldLabel='Version'
        startingValue={valueSetComposeIncludeState.version} setResourceState={setValueSetComposeIncludeState} />
      {(!valueSetComposeIncludeState.concept || valueSetComposeIncludeState.concept.length === 0 ||
        (valueSetComposeIncludeState.concept.length === 1 && valueSetComposeIncludeState.concept[0].code === ""))
        &&
        <>
          <div>
            <br /><br />
            <span>
              <b>Term Code: </b>
              <TextField style={{ width: "160px" }} multiline className="inputField" type='text' label={''} size="small"
                variant='outlined' value={codeSystemTermConversionState.termcode}
                onChange={(e) => {
                  setCodeSystemTermConversionState(prevState => { return { ...prevState, "termcode": e.target.value } });
                }} />
            </span>
            <span style={{ paddingLeft: "12px" }}>
              <Checkbox checked={codeSystemTermConversionState.recursive}
                onChange={(e) => {
                  setCodeSystemTermConversionState(prevState => { return { ...prevState, "recursive": e.target.checked } });
                }}
                color="primary"
                style={{ paddingLeft: "0px", paddingRight: "4px" }}
              /><>Recursive Children</>
            </span>
            <span style={{ paddingLeft: "12px" }}>
              <Button className="formButton" style={{ color: "#000000" }}
                content="Import Terms From CodeSystem"
                onClick={() => {
                  importTermsToValueSet(globalContext, valueSetComposeIncludeState, codeSystemTermConversionState, setCodeSystemTermConversionState);
                }}
              />
              <>&nbsp;&nbsp;</>
              <Button className="formButton" style={{ color: "#000000" }}
                content="Add Concepts Individually"
                onClick={() => { setDirectConceptEntryState(true); }}
              />
            </span>
          </div>
        </>
      }
      {directConceptEntryState &&
        <DataEntry asArray={true} datatype='ValueSetComposeIncludeConcept' elementName='concept' fieldLabel='Concept'
          startingValue={valueSetComposeIncludeState.concept ?? []} setResourceState={setValueSetComposeIncludeState} />
      }
    </div>
  </>
})

const ValueSetComposeIncludeConceptEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingValueSetConcept = { code: "", display: "", designation: [] };
  if (!startingValue) {
    startingValueSetConcept = {};
  } else {
    if (startingValue.extension) { startingValueSetConcept.extension = startingValue.extension; }
    if (startingValue.code) { startingValueSetConcept.code = startingValue.code; }
    if (startingValue.display) { startingValueSetConcept.display = startingValue.display; }
    if (startingValue.designation) { startingValueSetConcept.designation = startingValue.designation; }
  }

  const [valueSetComposeIncludeConceptState, setValueSetComposeIncludeConceptState] = useState(startingValueSetConcept);

  useEffect(() => {
    if (Object.keys(valueSetComposeIncludeConceptState).length > 0) {
      let newValueSetConcept = {};
      if (valueSetComposeIncludeConceptState.extension) { newValueSetConcept.extension = valueSetComposeIncludeConceptState.extension; }
      if (valueSetComposeIncludeConceptState.code) { newValueSetConcept.code = valueSetComposeIncludeConceptState.code; }
      if (valueSetComposeIncludeConceptState.display) { newValueSetConcept.display = valueSetComposeIncludeConceptState.display; }
      if (valueSetComposeIncludeConceptState.designation && Array.isArray(valueSetComposeIncludeConceptState.designation) && valueSetComposeIncludeConceptState.designation.length > 0) {
        newValueSetConcept.designation = valueSetComposeIncludeConceptState.designation;
      }
      if (Object.keys(newValueSetConcept).length === 0) {
        newValueSetConcept = null;
      }
      handleChange(elementName, newValueSetConcept, setResourceState);
    }
  }, [valueSetComposeIncludeConceptState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='code' fieldLabel='Code'
        startingValue={valueSetComposeIncludeConceptState.code} setResourceState={setValueSetComposeIncludeConceptState} />
      <DataEntry datatype='string' elementName='display' fieldLabel='Display'
        startingValue={valueSetComposeIncludeConceptState.display} setResourceState={setValueSetComposeIncludeConceptState} />
      <DataEntry asArray={true} datatype='ConceptDesignation' elementName='designation' fieldLabel='Designation'
        startingValue={valueSetComposeIncludeConceptState.designation} setResourceState={setValueSetComposeIncludeConceptState} />
    </div>
  </>
})

const conceptDesignationDotUseValueSet = [
  { system: "http://snomed.info/sct", code: "900000000000550004", display: "Definition (core metadata concept)" },
  { system: "http://snomed.info/sct", code: "900000000000013009", display: "Synonym (core metadata concept)" }
]
const ConceptDesignationEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingConceptDesignation = { language: "", use: "", additionalUse: [], value: "" };
  if (!startingValue) {
    startingConceptDesignation = {};
  } else {
    if (startingValue.extension) { startingConceptDesignation.extension = startingValue.extension; }
    if (startingValue.language) { startingConceptDesignation.language = startingValue.language; }
    if (startingValue.use) { startingConceptDesignation.use = startingValue.use; }
    if (startingValue.additionalUse) { startingConceptDesignation.additionalUse = startingValue.additionalUse; }
    if (startingValue.value) { startingConceptDesignation.value = startingValue.value; }
  }

  const [conceptDesignationState, setConceptDesignationState] = useState(startingConceptDesignation);

  useEffect(() => {
    if (Object.keys(conceptDesignationState).length) {
      let newConceptDesignation = {};
      if (conceptDesignationState.extension) { newConceptDesignation.extension = conceptDesignationState.extension; }
      if (conceptDesignationState.language) { newConceptDesignation.language = conceptDesignationState.language; }
      if (conceptDesignationState.use && Object.keys(conceptDesignationState.use).length > 0) { newConceptDesignation.use = conceptDesignationState.use; }
      if (conceptDesignationState.additionalUse && Array.isArray(conceptDesignationState.additionalUse) && conceptDesignationState.additionalUse.length > 0) {
        newConceptDesignation.additionalUse = conceptDesignationState.additionalUse;
      }
      if (conceptDesignationState.value) { newConceptDesignation.value = conceptDesignationState.value; }
      if (Object.keys(newConceptDesignation).length === 0) {
        newConceptDesignation = null;
      }
      handleChange(elementName, newConceptDesignation, setResourceState);
    }
  }, [conceptDesignationState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='Coding' elementName='use' fieldLabel='Use'
        valueSet={conceptDesignationDotUseValueSet}
        startingValue={conceptDesignationState.use} setResourceState={setConceptDesignationState} />
      <DataEntry datatype='string' elementName='value' fieldLabel='Value'
        startingValue={conceptDesignationState.value} setResourceState={setConceptDesignationState} />
    </div>
  </>
})

export {
  ActivityDefinitionDynamicValueEntry, ActivityDefinitionParticipantEntry,
  CharacteristicDefinitionByCombinationEntry, CharacteristicExecutableExpressionEntry,
  CharacteristicDefinitionByTypeAndValueEntry, CharacteristicTimeFromEventEntry, SearchPieceEntry,
  CodeSystemPropertyEntry, CodeSystemConceptDesignationEntry,
  EvidenceStatisticEntry, EvidenceStatisticSampleSizeEntry, EvidenceVariableCategoryEntry, EvidenceVariableDefinitionEntry, EvidenceStatisticAttributeEstimateEntry,
  EvidenceStatisticModelCharacteristicEntry, EvidenceStatisticModelCharacteristicVariableEntry,
  ListEntryEntry,
  PlanDefinitionActionEntry, PlanDefinitionActorEntry, PlanDefinitionGoalEntry,
  ResearchStudyAssociatedPartyEntry, ResearchStudyComparisonGroupEntry, ResearchStudyLabelEntry,
  ResearchStudyObjectiveEntry, EstimandEntry, EventHandlingEntry,
  ResearchStudyOutcomeMeasureEntry, ResearchStudyProgressStatusEntry,
  ResearchStudyRecruitmentEntry, StudyAmendmentEntry,
  ValueSetComposeEntry, ValueSetComposeIncludeEntry, ValueSetComposeIncludeConceptEntry, ConceptDesignationEntry,
  CompositionTableCellEntry
};