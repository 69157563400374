import { RadioGroup, Radio, FormControlLabel, Checkbox } from '@mui/material';
import { getResource } from './UserAccountFunctions';
import submitToFevirServer from './SubmitToFevirServer';
import { getProjectResourcesList } from './ProjectFunctions';

const provideInput = async (parameters, provideInputState, setProvideInputState, setValidPatientDataBundleState, 
  loadingPatientDataBundleRadioButtonsState, setLoadingPatientDataBundleRadioButtonsState, setPatientDataBundleRadioButtonsState,
  resourceId, globalContext) => {
  let variableName = parameters["variableName"];
  let validationCheck = parameters["validationCheck"];
  let valid = true;
  let invalidReasons = [];

  //valid at first by default, remains valid unless validationCheck sets to false
  const checkValidation = () => {
    for (let x in validationCheck) {
      let check = validationCheck[x];

      if (check === "isJSON") {
        //Makes sure it's valid JSON
        let isJson = true;
        try {
          let json = JSON.parse(provideInputState[variableName]["input"]);
          if (typeof json !== "object" || Array.isArray(json)) {
            isJson = false;
          }
        } catch (e) { isJson = false; }
        if (isJson === false) {
          valid = false;
          invalidReasons.push("This isn't properly formatted JSON.");
        }
      } else if (check === "isBundle") {
        //Checks the JSON to be sure it's a bundle with at least one entry
        try {
          let json = JSON.parse(provideInputState[variableName]["input"]);
          if (json.resourceType !== "Bundle") {
            valid = false;
            invalidReasons.push("This isn't a Bundle Resource.");
          } else if (!(json.entry && Array.isArray(json.entry) && json.entry.length > 0)) {
            valid = false;
            invalidReasons.push("This Bundle doesn't have an entry.");
          }

        } catch (e) { valid = false; invalidReasons.push("Bundle Resource was not recognized."); }
      } else if (check === "contains1Patient") {
        //Makes sure it has one AND ONLY one Patient resource
        let patientCount = 0
        try {
          let json = JSON.parse(provideInputState[variableName]["input"]);
          try {
            for (let entryIndex in json.entry) {
              let entry = json.entry[entryIndex];
              let resource = entry.resource;
              if (resource.resourceType === "Patient") {
                patientCount += 1;
              }
            }
            if (patientCount === 0) {
              valid = false;
              invalidReasons.push("There isn't a Patient resource in the bundle.");
            } else if (patientCount > 1) {
              valid = false;
              invalidReasons.push("There is more than one Patient resource in the bundle.");
            }
          } catch (e) { valid = false; invalidReasons.push("Number of Patient resources undetermined, due to unexpected FHIR JSON structure."); }
        } catch (e) { valid = false; invalidReasons.push("Patient resource not found."); }

      } else if (check === "isEvidenceVariable") {
        //Checks the JSON to be sure it is an EvidenceVariable Resource
        try {
          let json = JSON.parse(provideInputState[variableName]["input"]);
          if (json.resourceType !== "EvidenceVariable") {
            valid = false;
            invalidReasons.push("This isn't an EvidenceVariable Resource.");
          }
        } catch (e) { valid = false; invalidReasons.push("EvidenceVariable Resource was not recognized."); }
      } else if (check === "containsCharacteristic") {
        try {
          let json = JSON.parse(provideInputState[variableName]["input"]);
          if (!(json.characteristic?.length > 0)) {
            valid = false;
            invalidReasons.push("This EvidenceVariable doesn't have a characteristic.");
          } else if (!Array.isArray(json.characteristic)) {
            valid = false;
            invalidReasons.push("This EvidenceVariable.characteristic is not an array.");
          }
        } catch (e) { valid = false; invalidReasons.push("EvidenceVariable.characteristic was not recognized."); }
      }
    }

    try {
      if (provideInputState[variableName]) {
        setProvideInputState(prevState => { return { ...prevState, [variableName]: { input: prevState[variableName]["input"], validated: valid, errors: invalidReasons } }; });
      }
    } catch (e) { }

  }
  const loadResource = async () => {
    if (variableName == "patientDataBundle") {
      setValidPatientDataBundleState(true);
      setPatientDataBundleRadioButtonsState(prevState => {
        return { ...prevState, selectedResourceIndex: "" };
      });
      if (window.confirm("It's structurally valid. This site is for test data only. Please confirm that you are not posting any personally identifiable information such as real names or medical record numbers.")) {
        //submit to server
        let bundleTitle = prompt("Please give the Bundle a name.");
        if (bundleTitle) {
          let body = {
            'functionid': 'submiteligibilitycriteriamatchingbundle',
            'fhirEntry': provideInputState[variableName]["input"],
            "projectid": resourceId,
            'projecttitle': bundleTitle,
            'resourcetype': "Bundle",
            'idToken': ''
          };
          let response = await submitToFevirServer(globalContext, 5000, body, false, false);
          if (response?.success) {
            alert("Bundle submitted.");
            //It reloads the radio buttons list
            if (loadingPatientDataBundleRadioButtonsState.parameters) {
              setLoadingPatientDataBundleRadioButtonsState(prevState => { return { ...prevState, loading: true }; });
            }
          } else {
            alert("That didn't work... Please contact admin.");
          }
        }
      }
    } else if (variableName == "eligibityCriteraEvidenceVariable") {
      //validEligibilityCriteriaEvidenceVariable = true;
      let json = JSON.parse(provideInputState[variableName]["input"]);
      let newEVtitle = json.title || json.name || "";
      if (newEVtitle === "") {
        newEVtitle = prompt("Please give the Resource a name.")
      }
      if (newEVtitle) {
        let body = {
          'functionid': 'submiteligibilitycriteriamatchingbundle',
          'fhirEntry': provideInputState[variableName]["input"],
          "projectid": resourceId,
          'projecttitle': newEVtitle,
          'resourcetype': "EvidenceVariable",
          'idToken': ''
        };
        let response = await submitToFevirServer(globalContext, 5000, body, false, false);
        if (response?.success) {
          alert("EvidenceVariable Resource submitted. Refresh the page to see it on the selection list.");
        } else {
          alert("That didn't work... Please contact admin.");
        }
      }
    } else {
      alert("It's valid.");
    }
  }

  if (validationCheck && Array.isArray(validationCheck)) {
    checkValidation();
  }
  if (valid) {
    loadResource();
  } else {
    let alertMessage = "It is NOT valid for the following reasons:\n";
    for (let reasonIndex in invalidReasons) {
      let reason = invalidReasons[reasonIndex];
      alertMessage += reason + "\n";
    }
    alert(alertMessage);
  }
}

const getUserMatchedResourcesList = async (projectId, globalContext) => {
  let projectresources = [];
  let body = {
    'functionid': 'getresourcesfrommatchingprojectuserandresourcetable',
    'idToken': "",
    'projectid': projectId
  };

  let projectresponse = await submitToFevirServer(globalContext, 10000, body, true, false);
  if (projectresponse) {
    projectresources = projectresponse.matchedresources;
  }
  return projectresources;
}

const getProjectsResourcesBySpecificTypesList = async (projectIds, resourceTypes, globalContext, getUsersMatchedResources) => {
  //For multiple Projects, if you want to do this for just one Project then pass it an array with a single entry
  let resourcesByType = {};
  let resourcesList = [];
  for (let x in projectIds) {
    let projectId = projectIds[x];
    //Load all resources that the project admin associated with the project
    let projectResourcesList = await getProjectResourcesList(projectId, false, globalContext);
    let resourcesByProject = projectResourcesList;
    if (getUsersMatchedResources) {
      //Load all resources that the user associated with the project to ADD to the list
      let userMatchedResourcesList = await getUserMatchedResourcesList(projectId, globalContext);
      resourcesByProject = resourcesByProject.concat(userMatchedResourcesList);
    }

    for (let y in resourcesByProject) {
      let resourceInfo = resourcesByProject[y];
      try {
        if (resourcesByType[resourceInfo.resourcetype] === undefined) {
          resourcesByType[resourceInfo.resourcetype] = {};
        }
        resourcesByType[resourceInfo.resourcetype][resourceInfo.id] = resourceInfo.title;
      } catch (e) { }
    }
  }

  for (let resourceTypesIndex in resourceTypes) {
    let resourceType = resourceTypes[resourceTypesIndex];
    for (let resourceId in resourcesByType[resourceType]) {
      resourcesList.push({ "id": resourceId, "resourcetype": resourceType, "title": resourcesByType[resourceType][resourceId] });
    }
  }
  return resourcesList;
}

const PatientDataBundleRadioButtons = ({ parameters, globalContext, setProvideInputState, setValidPatientDataBundleState, patientDataBundleRadioButtonsState, setPatientDataBundleRadioButtonsState, loadingPatientDataBundleRadioButtonsState, setLoadingPatientDataBundleRadioButtonsState }) => {
  //TODO to do: THIS IS BAD PRACTICE to change state uppon component load, you will get a warning.
  if (!loadingPatientDataBundleRadioButtonsState.loading) {
    setLoadingPatientDataBundleRadioButtonsState({ loading: true, parameters: parameters });
  }
  return <>
    {patientDataBundleRadioButtonsState.resourcesList &&
      <div>
        <RadioGroup aria-label="selectPatientDataBundle" name="selectPatientDataBundle"
          value={patientDataBundleRadioButtonsState.selectedResourceIndex || ""} onChange={async (e) => {
            setValidPatientDataBundleState(true);
            setPatientDataBundleRadioButtonsState(prevState => {
              return { ...prevState, selectedResourceIndex: e.target.value };
            });
            const selectedResource = await getResource(patientDataBundleRadioButtonsState.resourcesList[e.target.value.replace("entry-", "")].id, "Bundle", globalContext.userState.idToken);
            if (selectedResource?.fhirResource) {
              setProvideInputState(prevState => { return { ...prevState, patientDataBundle: { radioinput: selectedResource.fhirResource, validated: true, errors: [] } }; });
            }
          }}>
          {patientDataBundleRadioButtonsState.resourcesList.map((resource, resourceIndex) => {
            return <FormControlLabel
              key={resourceIndex}
              value={"entry-" + resourceIndex.toString()}
              control={<Radio color="primary" style={{ paddingTop: "2px", paddingBottom: "2px" }} />}
              name="radio-button-control"
              color="default"
              inputprops={{ 'aria-label': resource.title }}
              label={resource.title}
            />
          })}
        </RadioGroup>
      </div>
    }
  </>
}

const EligibilityCriteriaListCheckboxes = ({ parameters, eligibilityCriteriaCheckBoxesState, setEligibilityCriteriaCheckBoxesState, loadingEligibilityCriteriaCheckBoxesState, setLoadingEligibilityCriteriaCheckBoxesState }) => {
  //eligibilityCriteriaCheckBoxesState, setEligibilityCriteriaCheckBoxesState
  //TODO to do: THIS IS BAD PRACTICE to change state uppon component load, you will get a warning.
  if (!loadingEligibilityCriteriaCheckBoxesState.loading) {
    setLoadingEligibilityCriteriaCheckBoxesState({ loading: true, parameters: parameters });
  }
  return <>
    {eligibilityCriteriaCheckBoxesState.resourcesList?.map((resource, resourceIndex) => {
      return <div key={resourceIndex}>
        <Checkbox size="small" color="primary" style={{ padding: "0px", color: "#000000" }}
          checked={resource.checked || false}
          onChange={(e) => {
            setEligibilityCriteriaCheckBoxesState(prevState => { let resourcesList = prevState.resourcesList; resourcesList[resourceIndex].checked = e.target.checked; return { ...prevState, resourcesList: resourcesList }; });
          }}
        />&nbsp;{resource.title}
      </div>
    })}
  </>
}

export { provideInput, PatientDataBundleRadioButtons, getUserMatchedResourcesList, getProjectsResourcesBySpecificTypesList, EligibilityCriteriaListCheckboxes };