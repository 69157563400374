import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { provideInput, PatientDataBundleRadioButtons, getProjectsResourcesBySpecificTypesList, EligibilityCriteriaListCheckboxes } from './MatchingFunctions';
import { generateCitation } from './DataEntryFormFunctions';
import submitToFevirServer from './SubmitToFevirServer';

const getProjectResourcesList = async (projectId, loadcitations, globalContext) => {
    let projectresources = [];
    let body = {
        'functionid': 'getprojectresourceslist',
        'idToken': "",
        'projectresourceid': projectId,
        'loadcitations': loadcitations
    };

    let projectresponse = await submitToFevirServer(globalContext, 60000, body, true, false);

    if (projectresponse) {
        projectresources = projectresponse.projectresources;
    }
    return projectresources;
}

const loadProjectResourcesList = async (setFhirEntryState, resourceType, projectId, loadcitations, globalContext) => {
    if (resourceType === "Project") {
        let projectresources = [];
        projectresources = await getProjectResourcesList(projectId, loadcitations, globalContext);
        setFhirEntryState(prevState => {
            if (projectresources) {
                try {
                    projectresources.sort((a, b) => {
                        if (a.resourcetype > b.resourcetype) return 1;
                        if (a.resourcetype < b.resourcetype) return -1;
                        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                        if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                        if (a.id > b.id) return 1;
                        if (a.id < b.id) return -1;
                    });
                } catch (e) { }
            }
            return {
                ...prevState,
                projectResources: projectresources,
                projectResourcesLoaded: true
            };
        });

    }
}

const projectTextViewChangesToJson = (fhirJson, projectState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = projectState.meta;
    fhirJson.language = projectState.language;
    fhirJson.text = projectState.text;
    fhirJson.contained = projectState.contained || "DELETEME";
    fhirJson.extension = projectState.extension || "DELETEME";
    fhirJson.modifierExtension = projectState.modifierExtension || "DELETEME";
    fhirJson.url = projectState.url || "DELETEME";
    fhirJson.identifier = projectState.identifier || "DELETEME";
    fhirJson.version = projectState.version || "DELETEME";
    fhirJson.versionAlgorithmString = projectState.versionAlgorithmString || "DELETEME";
    fhirJson.versionAlgorithmCoding = projectState.versionAlgorithmCoding || "DELETEME";
    fhirJson.name = projectState.name || "DELETEME";
    fhirJson.title = projectState.title || "DELETEME";
    fhirJson.status = projectState.status || "unknown";
    fhirJson.experimental = projectState.experimental || "DELETEME";
    if (projectState.experimental === false) {
        fhirJson.experimental = false;
    }
    fhirJson.date = projectState.date || "DELETEME";
    fhirJson.publisher = projectState.publisher || "DELETEME";
    fhirJson.contact = projectState.contact || "DELETEME";
    fhirJson.description = projectState.description || "DELETEME";
    fhirJson.useContext = projectState.useContext || "DELETEME";
    fhirJson.jurisdiction = projectState.jurisdiction || "DELETEME";
    fhirJson.purpose = projectState.purpose || "DELETEME";
    fhirJson.usage = projectState.usage || "DELETEME";
    fhirJson.copyright = projectState.copyright || "DELETEME";
    fhirJson.copyrightLabel = projectState.copyrightLabel || "DELETEME";
    fhirJson.approvalDate = projectState.approvalDate || "DELETEME";
    fhirJson.lastReviewDate = projectState.lastReviewDate || "DELETEME";
    fhirJson.effectivePeriod = projectState.effectivePeriod || "DELETEME";
    fhirJson.topic = projectState.topic || "DELETEME";
    fhirJson.author = projectState.author || "DELETEME";
    fhirJson.editor = projectState.editor || "DELETEME";
    fhirJson.reviewer = projectState.reviewer || "DELETEME"
    fhirJson.endorser = projectState.endorser || "DELETEME";
    fhirJson.relatedArtifact = projectState.relatedArtifact || "DELETEME";

    fhirJson.detail = projectState.detail || "DELETEME";
    fhirJson.action = projectState.action || "DELETEME";
    fhirJson.associatedLink = projectState.associatedLink || "DELETEME";
    fhirJson.softwareCode = projectState.softwareCode || "DELETEME";
    fhirJson.referenceList = projectState.referenceList || "DELETEME";
    fhirJson.showOnProjectsList = projectState.showOnProjectsList || "DELETEME";
    if (projectState.showOnProjectsList === false) {
        fhirJson.showOnProjectsList = projectState.showOnProjectsList;
    }
    fhirJson.enableRoBAT = projectState.enableRoBAT || "DELETEME";
    if (projectState.enableRoBAT === false) {
        fhirJson.enableRoBAT = projectState.enableRoBAT;
    }

    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    return fhirJsonWithCiteAs;
}

const createNewClassificationJson = (projectState) => {
    let contentArray = projectState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "PlanDefinition";
    let classifiedFoi = projectState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = projectState.title || projectState.name || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta": {
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const toggleEnableRoBATinProjectJson = (projectJson, resourceId, fhirEntryState) => {
    let fhirJson = JSON.parse(JSON.stringify(projectJson));
    fhirJson = jsonErrorCheck2("Project", resourceId, fhirEntryState.startingVersionId, projectJson);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }
    fhirJson.enableRoBAT = !(fhirJson.enableRoBAT || false);
    return fhirJson;
}

const builderUpdateReferenceListToProjectJson = (generatedReferenceList, projectJson, resourceId, fhirEntryState) => {
    let fhirJson = JSON.parse(JSON.stringify(projectJson));

    fhirJson = jsonErrorCheck2("Project", resourceId, fhirEntryState.startingVersionId, projectJson);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }
    if (generatedReferenceList?.length > 0) {

        let referenceList = [];
        for (let x in generatedReferenceList) {
            let citation = generatedReferenceList[x];
            let referenceEntry = {};
            referenceEntry.reference = { "reference": citation.resourcetype + "/" + citation.id, "type": citation.resourcetype, "display": citation.title };
            if (citation.description) {
                referenceEntry.description = citation.description;
            }
            referenceEntry.summary = citation.summary;
            let riskofbiasratings = citation.riskofbiasratings;
            if (riskofbiasratings?.length > 0) {
                referenceEntry.robatRating = [];
                for (let ratingIndex in riskofbiasratings) {
                    let rating = riskofbiasratings[ratingIndex];
                    if (rating?.display) {
                        referenceEntry.robatRating.push(rating);
                    }
                }
            } else {
                delete referenceEntry.robatRating;
            }
            referenceList.push(referenceEntry);
        }
        referenceList.sort((a, b) => {
            let summaryA = a.summary;
            if (!summaryA && a.reference) {
                summaryA = a.reference.display;
            }
            let summaryB = b.summary;
            if (!summaryB && b.reference) {
                summaryB = b.reference.display;
            }
            if (summaryA && summaryB) {
                if (summaryA.toLowerCase() > summaryB.toLowerCase()) return 1;
                if (summaryA.toLowerCase() < summaryB.toLowerCase()) return -1;
            } else if (summaryA) {
                return 1;
            } else if (summaryB) {
                return -1;
            }
        });

        fhirJson.referenceList = referenceList;

        fhirJson = jsonErrorCheck2("Project", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    return fhirJson
}

const builderUpdateProjectJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let projectState = formInputsStateRef.current.projectState;

    let fhirJson = jsonErrorCheck2("Project", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = projectTextViewChangesToJson(fhirJson, projectState);
        fhirJson = jsonErrorCheck2("Project", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(projectState);

    return [fhirJson, newClassificationJson];
}

export {
    provideInput, PatientDataBundleRadioButtons, getProjectResourcesList, getProjectsResourcesBySpecificTypesList,
    EligibilityCriteriaListCheckboxes, loadProjectResourcesList, builderUpdateReferenceListToProjectJson,
    toggleEnableRoBATinProjectJson, builderUpdateProjectJson
};