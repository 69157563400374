import React, { useState, useEffect, useRef, useContext } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { Button, Dropdown, Segment, TextArea, Dimmer, Loader, Tab, Modal } from 'semantic-ui-react';
import { RadioGroup, Radio, FormControlLabel, Checkbox } from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import "firebase/compat/auth";
import GenericToggleButtons from './GenericToggleButtons';
import YesNoRadioCheckBoxSwitch from './YesNoRadioCheckBoxSwitch';
import NavigationSegment from './NavigationSegment';
import NavigationCodeSystemTermSegment from './NavigationCodeSystemTermSegment';
import NavigationRobatTermSegment from './NavigationRobatTermSegment';
import { NavigationCharacteristicSegment } from './NavigationCharacteristicSegment';
import NavigationAdaptationItemSegment from './NavigationAdaptationItemSegment';
import SchemaElementDisplay from './SchemaElementDisplay';
import CitationDisplay from './CitationDisplay';
import CodeSystemTermDisplay from './CodeSystemTermDisplay';
import CodeSystemTermEdit from './CodeSystemTermEdit';
import RobatTermDisplay from './RobatTermDisplay';
import { AdaptationDetailDisplay } from './AdaptationDetailDisplay';
import { AdaptationDetailEdit, expandElementComponents } from './AdaptationDetailEdit';
import { ComparisonDetailDisplay } from './ComparisonDetailDisplay';
import { ComparisonDetailEdit } from './ComparisonDetailEdit';
import { EvidenceVariableCharacteristicDisplay } from './EvidenceVariableCharacteristicDisplay';
import EvidenceVariableCharacteristicEdit from './EvidenceVariableCharacteristicEdit';
import ResourceBuilderViewer from './ResourceBuilderViewer';
import QuestionnaireDisplay from './QuestionnaireDisplay';
import RecommendationDisplay from './RecommendationDisplay';
import EvidenceReportResourceDisplay from './EvidenceReportResourceDisplay';
import RiskOfBiasAssessmentReader from './RiskOfBiasAssessmentReader';
import CommentRatingDisplay from './CommentRatingDisplay';
import CodeSystemTermProgressTable from './CodeSystemTermProgressTable';
import getFhirDatatype from './getFhirDatatype';
import submitToFevirServer from './SubmitToFevirServer';
import JsonVersionComparison from './JsonVersionComparison';

import {
  loadResourceVersionList, getHistoryVersionOfResource, getVersionDifference, loadReferencedResource,
  SimpleResourceFieldViewer, getResourceComments, loadUsageView,
  createInviteLink, revokeInviteLink, FHIRtoCTgovConverter, builderUpdateJson, submitUpdatedFhirResource,
  joinGroup, addToProject, votePermissionCheck, getValueSetCodesAsOptions, getStringFromFHIR
} from './ResourceFunctions';
import { loadProjectResourcesList } from './ProjectFunctions';
import { findConceptByCode, findRobatConceptByCode, submitCodeSystemComment, getAlternativeTerms, getPropertyCodes, findConceptProperties } from './CodeSystemFunctions';
import { characteristicNavigationEntryDisplay, navigateToAnotherCharacteristic } from './NavigationCharacteristicSegment';
import server_config from './ServerConfiguration';

import { startingGroupCharacteristicKindOptions, startingLanguageOptions } from './CodeSystemLookup';
import { SearchResourcesToAddToProjectModal } from './SearchModal';

import './App.css';
import FevirContext from './FevirContext';

const ports = server_config["ports"];
const server_address = server_config["address"];

const TermCommentButton = ({ firebase, history, resourceId, fhirEntryState, setFormState, commentEntryState,
  setCommentEntryState }) => {
  const globalContext = useContext(FevirContext);
  let disableButton = false;
  let conceptCode = fhirEntryState.conceptCode;
  if (conceptCode) {
    disableButton = false;
  } else {
    disableButton = true;
  }
  let loggedInContent;
  let actions;
  let expandModalButtonBackgroundColor = "";
  let modalBodyContentStyle = { paddingLeft: "20px" };
  if (commentEntryState.modalExpanded === false) {
    expandModalButtonBackgroundColor = "#DAA520";
    modalBodyContentStyle["display"] = "none";
  }
  if (globalContext.userState.id) {
    loggedInContent = <div style={modalBodyContentStyle}>
      {commentEntryState.commentSummary && <Beforeunload onBeforeunload={(event) => { event.preventDefault(); }} />}
      <br />
      <TextArea style={{ marginTop: "6px", width: "98%" }} rows={5} className="inputField" type='text' label={'Comment'} size="small" variant='outlined' value={commentEntryState.commentSummary} onChange={(e) => { setCommentEntryState(prevState => { return { ...prevState, "commentSummary": e.target.value } }); }} />
      <b style={{ margin: "0px", padding: "0px" }}></b>
      <p style={{ margin: "0px", padding: "0px", paddingLeft: "20px" }}></p>
      <br />
    </div>;
    actions = [
      {
        key: 'submit', content: 'Submit', positive: true, onClick: async () => {
          let resourceTitle = JSON.parse(fhirEntryState.fhirEntryString).title;
          let response = await submitCodeSystemComment(globalContext, "comment", firebase, resourceId, resourceTitle, fhirEntryState.conceptCode, fhirEntryState.conceptName, fhirEntryState.conceptDefinition, fhirEntryState.conceptSelected, commentEntryState.commentTitle, commentEntryState.commentSummary, commentEntryState.termVote, true, setCommentEntryState, null);
          if (response?.success) {
            setFormState(prevState => {
              return {
                ...prevState,
                hashLoaded: false
              };
            });
            history.push("/");
            history.push(`/resources/CodeSystem/${resourceId}#${conceptCode}`);
          }
        }
      },
      { key: 'done', content: 'Cancel', negative: true }
    ];
  } else {
    loggedInContent = <div style={{ paddingLeft: "20px" }}>
      <h1>Please close window and login</h1>
    </div>;
    actions = [
      { key: 'done', content: 'Cancel', negative: true }
    ];
  }
  if (commentEntryState.modalExpanded === false) {
    actions = undefined;
  }
  return (
    <Modal
      trigger={<Button className="formButton subCommunicateButton" content="Comment" style={{ margin: "0px", color: "#000000" }} disabled={disableButton || fhirEntryState.previousVersionLoaded} onClick={() => { if (globalContext.userState.id) { setCommentEntryState(prevState => { return { ...prevState, "modalExpanded": true, "commentTitle": `Comment on ${fhirEntryState.conceptName} by ${globalContext.userState.name}`, "commentSummary": "" } }); } else { alert("Please login to use the comment function."); } }} />}
      header={<div style={{ padding: "8px" }}><div style={{ paddingLeft: "10px", borderBottom: "1px solid" }}><h2 style={{ paddingBottom: "6px" }}>{commentEntryState.commentTitle}</h2><Button style={{ position: "absolute", padding: "0px 8px 0px 8px", fontWeight: "bold", right: "3px", top: "3px", backgroundColor: expandModalButtonBackgroundColor }} className="formButton" content={commentEntryState.modalExpanded ? "▼" : "▶"} onClick={() => { setCommentEntryState(prevState => { return { ...prevState, "modalExpanded": !prevState.modalExpanded } }); }} /></div></div>}
      centered={false}
      closeOnDimmerClick={false}
      content={loggedInContent}
      actions={actions}
    />
  )
}

const TermVoteButton = ({ firebase, history, resourceId, fhirEntryState, setFhirEntryState, setFormState,
  commentEntryState, setCommentEntryState }) => {
  const globalContext = useContext(FevirContext);
  let disableButton = false;
  let conceptCode = fhirEntryState.conceptCode;
  if (fhirEntryState.conceptOpenForVoting) {
    disableButton = false;
  } else {
    disableButton = true;
  }
  let loggedInContent;
  let actions;
  let expandModalButtonBackgroundColor = "";
  let modalBodyContentStyle = { paddingLeft: "20px" };
  if (commentEntryState.modalExpanded === false) {
    expandModalButtonBackgroundColor = "#DAA520";
    modalBodyContentStyle["display"] = "none";
  }
  if (globalContext.userState.id) {
    if (fhirEntryState.votePermission === true) {
      loggedInContent = <div>
        <div style={{ padding: "8px" }}>
          <div style={{ paddingLeft: "10px", borderBottom: "1px solid" }}>
            {(commentEntryState.termVote || commentEntryState.commentSummary) && <Beforeunload onBeforeunload={(event) => { event.preventDefault(); }} />}
            <h2 style={{ paddingBottom: "6px" }}><span style={{ fontWeight: "normal" }}>Voting on </span>{fhirEntryState.conceptName}<span style={{ fontWeight: "normal" }}> as {globalContext.userState.name}</span></h2>
            <Button style={{ position: "absolute", padding: "0px 8px 0px 8px", fontWeight: "bold", right: "3px", top: "3px", backgroundColor: expandModalButtonBackgroundColor }} className="formButton" content={commentEntryState.modalExpanded ? "▼" : "▶"} onClick={() => { setCommentEntryState(prevState => { return { ...prevState, "modalExpanded": !prevState.modalExpanded } }); }} />
          </div>
        </div>
        <div style={modalBodyContentStyle}>
          <br />
          <fieldset style={{ width: "100%", display: "inline", border: "0px", verticalAlign: "bottom", padding: "0px" }}>
            <input type="radio" name={"voteYesOrNo"} value="true" checked={commentEntryState.termVote === "Yes"} onChange={(e) => {
              setCommentEntryState(prevState => { return { ...prevState, "termVote": "Yes" } });
            }} />
            <label style={{ fontSize: "16px" }}> Vote Yes on {fhirEntryState.conceptName} = {fhirEntryState.conceptDefinition}</label>
            <br /><br />
            <input type="radio" name={"voteYesOrNo"} value="true" checked={commentEntryState.termVote === "No"} onChange={(e) => {
              setCommentEntryState(prevState => { return { ...prevState, "termVote": "No" } });
            }} />
            <label style={{ fontSize: "16px" }}> Vote No on Term</label>
          </fieldset>
          <br /><br />
          <span style={{ margin: "0px", padding: "0px" }}>Comment (required if you vote No):</span>
          <p style={{ margin: "0px", padding: "0px", paddingLeft: "20px" }}>
            <TextArea style={{ marginTop: "6px", width: "98%" }} rows={5} className="inputField" type='text' label={'Comment'} size="small" variant='outlined' value={commentEntryState.commentSummary} onChange={(e) => { setCommentEntryState(prevState => { return { ...prevState, "commentSummary": e.target.value } }); }} />
          </p>
          <br />
        </div>
      </div>;
    } else if (fhirEntryState.votePermission === false) {
      loggedInContent = <div style={{ paddingLeft: "20px" }}>
        <br />
        <span><b>For voting privileges, please join the {"SEVCO Expert Working Group"}.</b></span>
        <br /><br />
        <span>Would you like to join the group in order to vote?</span>
        <br />
        <br />
      </div>;
    } else {
      loggedInContent = <div style={{ paddingLeft: "20px" }}>
        <br />
        <span>Loading...</span>
        <br />
        <br />
      </div>;
    }
    let disabledSubmit = false;
    if (commentEntryState.termVote === "" || (commentEntryState.termVote === "No" && commentEntryState.commentSummary === "")) {
      disabledSubmit = true;
    }
    if (fhirEntryState.votePermission === true) {
      actions = [
        {
          key: 'submit', content: 'Submit', positive: true, disabled: disabledSubmit, onClick: async () => {
            let resourceTitle = JSON.parse(fhirEntryState.fhirEntryString).title;
            let response = await submitCodeSystemComment(globalContext, "vote", firebase, resourceId, resourceTitle, fhirEntryState.conceptCode, fhirEntryState.conceptName, fhirEntryState.conceptDefinition, fhirEntryState.conceptSelected, commentEntryState.commentTitle, commentEntryState.commentSummary, commentEntryState.termVote, true, setCommentEntryState, null);
            if (response?.success) {
              setFormState(prevState => {
                return {
                  ...prevState,
                  hashLoaded: false
                };
              });
              history.push("/");
              history.push(`/resources/CodeSystem/${resourceId}#${conceptCode}`);
            }
          }
        },
        { key: 'done', content: 'Cancel', negative: true }
      ];
    } else {
      actions = [
        {
          key: 'join', content: 'Join Group', positive: true, onClick: async () => {
            let response = await joinGroup(resourceId, globalContext);
            if (response?.success) {
              setFormState(prevState => {
                return {
                  ...prevState,
                  hashLoaded: false
                };
              });
              history.push("/");
              history.push(`/resources/CodeSystem/${resourceId}#${conceptCode}`);
            }
          }
        },
        { key: 'done', content: 'Cancel', negative: true }
      ];
    }
  } else {
    loggedInContent = <div style={{ paddingLeft: "20px" }}>
      <h1>Please close window and login before you vote.</h1>
    </div>;
    actions = [
      { key: 'done', content: 'Cancel', negative: true }
    ];
  }
  if (commentEntryState.modalExpanded === false) {
    actions = undefined;
  }
  return (
    <Modal
      trigger={
        <Button className="formButton subCommunicateButton" content="Vote" style={{ margin: "0px", color: "#000000" }} disabled={disableButton || fhirEntryState.previousVersionLoaded}
          onClick={async () => {
            if (globalContext.userState.id) {
              if (fhirEntryState.votePermission === undefined) {
                let response = await votePermissionCheck(firebase, globalContext, resourceId, fhirEntryState, setFhirEntryState);
              }
              setCommentEntryState(prevState => { return { ...prevState, "modalExpanded": true, "commentTitle": `Voting on ${fhirEntryState.conceptName} as ${globalContext.userState.name}`, "commentSummary": "", "termVote": "" } });
            } else {
              alert("Please login to vote.");
            }
          }
          } />
      }
      centered={false}
      closeOnDimmerClick={false}
      content={loggedInContent}
      actions={actions}
    />
  )
}

const ResourceTextDisplay = ({ resourceType, resourceId, formInputsStateRef, getResource, changeFhirEntryState,
  referencedResourceState, setReferencedResourceState, changeFormState, firebase,
  userState, setFhirEntryState, languageOptions, setLanguageOptions, fhirEntryState, history,
  groupCharacteristicKindOptions, setGroupCharacteristicKindOptions,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState, update }) => {
  if (fhirEntryState.fhirEntry && fhirEntryState.fhirEntryString && fhirEntryState.fhirEntryString !== "{}") {
    try {
      let fhirJson = JSON.parse(fhirEntryState.fhirEntryString);
      if (resourceType === "Citation") {
        return <CitationDisplay resourceId={resourceId} formInputsStateRef={formInputsStateRef}
          fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
          changeFhirEntryState={changeFhirEntryState} changeFormState={changeFormState}
          languageOptions={languageOptions} setLanguageOptions={setLanguageOptions}
          userName={userState.name} />
      } else if (resourceType === "Project") {
        return <ResourceBuilderViewer changeFormState={changeFormState} formInputsStateRef={formInputsStateRef} fhirJson={fhirJson}
          fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState}
          history={history} update={update}
          referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState} />
        {/**
            return <ProjectDisplay
          firebase={firebase} resourceId={resourceId}
          changeFormState={changeFormState} formInputsStateRef={formInputsStateRef} fhirJson={fhirJson} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
          groupCharacteristicKindOptions={groupCharacteristicKindOptions} setGroupCharacteristicKindOptions={setGroupCharacteristicKindOptions} getResource={getResource} 
          history={history}
          referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
        />
        */}
      } else if (resourceType === "Recommendation") {
        return <RecommendationDisplay fhirJson={fhirJson} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState}
          groupCharacteristicKindOptions={groupCharacteristicKindOptions} setGroupCharacteristicKindOptions={setGroupCharacteristicKindOptions}
          referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
        />
      } else if (resourceType === "EvidenceReport") {
        return <EvidenceReportResourceDisplay changeFormState={changeFormState} fhirJson={fhirJson} fhirEntryState={fhirEntryState} />
      } else if (resourceType === "ArtifactComment") {
        return <CommentRatingDisplay formInputsStateRef={formInputsStateRef} fhirJson={fhirJson} fhirEntryState={fhirEntryState}
          changeFhirEntryState={changeFhirEntryState} changeFormState={changeFormState} />
      } else if (resourceType === "Questionnaire") {
        return <QuestionnaireDisplay changeFormState={changeFormState} formInputsStateRef={formInputsStateRef} fhirJson={fhirJson} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState}
          userState={userState} groupCharacteristicKindOptions={groupCharacteristicKindOptions} setGroupCharacteristicKindOptions={setGroupCharacteristicKindOptions}
          referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
        />
      } else if (resourceType === "Evidence" || resourceType === "ActivityDefinition" || resourceType === "PlanDefinition" ||
        resourceType === "SoftwareScript" || resourceType === "Characteristic" || resourceType === "Composition" ||
        resourceType === "Group" || resourceType === "Library" || resourceType === "List" || resourceType === "Bundle" ||
        resourceType === "Measure" || resourceType === "ResearchStudy" || resourceType === "ValueSet" ||
        resourceType === "EvidenceVariable" || resourceType === "CodeSystem") {
        return <ResourceBuilderViewer changeFormState={changeFormState} formInputsStateRef={formInputsStateRef}
          fhirJson={fhirJson} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
          changeFhirEntryState={changeFhirEntryState} history={history} adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState} setChangeAvailableToSaveState={setChangeAvailableToSaveState} update={update}
        />
      } else if (resourceType === "ArtifactAssessment" && (fhirEntryState.classification ||
        fhirEntryState.rating || fhirEntryState.comment || fhirEntryState.adaptation ||
        fhirEntryState.comparison || fhirEntryState.recommendationJustification)) {
        return <ResourceBuilderViewer changeFormState={changeFormState} formInputsStateRef={formInputsStateRef} fhirJson={fhirJson}
          fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState} update={update}
        />
      } else if (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource) {
        return <RiskOfBiasAssessmentReader fhirJson={fhirJson} changeFormState={changeFormState} />
      } else if (resourceType === "SchemaElement") {
        return <SchemaElementDisplay changeFormState={changeFormState} formInputsStateRef={formInputsStateRef}
          fhirJson={fhirJson} fhirEntryState={fhirEntryState}
        />
      } else {
        return <div>
          <div style={{ marginTop: "12px" }}>
          </div>
          <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} startExpanded={true} />
        </div>
      }

    } catch (e) {
      return <>Invalid {resourceType} JSON. See JSON Tab to see the resource.</>
    }
  } else {
    return <></>
  }
}

const AdaptationDetailTabRouting = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState, history }) => {
  if (fhirEntryState.editMode) {
    return <AdaptationDetailEdit fhirEntryState={fhirEntryState}
      formInputsStateRef={formInputsStateRef} />
  } else {
    return <AdaptationDetailDisplay resourceId={resourceId}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history} />
  }
}

const ComparisonDetailTabRouting = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState, history }) => {
  if (fhirEntryState.editMode) {
    return <ComparisonDetailEdit fhirEntryState={fhirEntryState}
      formInputsStateRef={formInputsStateRef} />
  } else {
    return <ComparisonDetailDisplay resourceId={resourceId}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history} />
  }
}

const CharacteristicDetailTabRouting = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState, history,
  changeFhirEntryState, submitUpdatedFhirResource, changeFormState, addToast }) => {
  if (fhirEntryState.editMode) {
    return <EvidenceVariableCharacteristicEdit formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history}
      changeFhirEntryState={changeFhirEntryState} submitUpdatedFhirResource={submitUpdatedFhirResource}
      changeFormState={changeFormState} addToast={addToast} />
  } else {
    return <EvidenceVariableCharacteristicDisplay formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history}
      changeFhirEntryState={changeFhirEntryState} submitUpdatedFhirResource={submitUpdatedFhirResource}
      changeFormState={changeFormState} addToast={addToast} />
  }
}

const CodeSystemTermDetailTabRouting = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState, history, update }) => {
  if (fhirEntryState.editMode) {
    return <CodeSystemTermEdit fhirEntryState={fhirEntryState} formInputsStateRef={formInputsStateRef}
      resourceId={resourceId} setFhirEntryState={setFhirEntryState} history={history} update={update} />
  } else {
    return <CodeSystemTermDisplay resourceId={resourceId}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history} />
  }
}

const ResourceDisplayContent = ({ history, resourceEntryState, pageEditModeState, setPageEditModeState,
  claimedResourceType, resourceType, resourceTypeURL, resourceId, getResource, formState, setFormState,
  changeFormState, firebase, indexPath, jsonView }) => {
  const globalContext = useContext(FevirContext);
  let userState = globalContext.userState;

  const { addToast } = useToasts();

  const formInputsStateRef = useRef({});

  let loadedActiveIndex = 0;

  if (jsonView) {
    if (resourceTypeURL === "CodeSystem") {
      loadedActiveIndex = 2;
    } else {
      loadedActiveIndex = 1;
    }
  }

  const [fhirEntryState, setFhirEntryState] = useState({
    "fhirEntry": {}, "fhirEntryString": "",
    "fhirEntryStringChange": false, "loadedFhirEntryString": "", "title": "", "loading": true, "found": null,
    "classification": false, "rating": false, "comment": false, "adaptation": false, "comparison": false,
    "riskOfBiasAssessmentToolResource": false, "recommendationJustification": false,
    "systematicReviewProfile": false,
    "recommendationProfile": false, "guidelineProfile": false, "summaryOfFindingsProfile": false,
    "comparativeEvidenceReportProfile": false, "baselineMeasureReportProfile": false,
    "participantFlowReportProfile": false, "outcomeMeasureReportProfile": false,
    "m11reportProfile": false, "evidenceReportPackageProfile": false,
    "characteristicDefinition": false, "editMode": false, "projectChangeMode": false,
    "readpermission": false, "editpermission": true, "loggedin": false, "status": "", "totalviews": null,
    "totalusersviewed": null, "totaledits": null, "totalusersedited": null, "projectsChecked": {},
    "projectsList": [], "projectsContain": [], "projectsContainIds": [], "projectResources": [],
    "startingStatus": "draft", "profileName": undefined, "newChildConcepts": [], "conceptCommentsExpanded": false,
    "conceptJsonExpanded": false, "comments": [], "startingVersionId": "0",
    "activeIndex": loadedActiveIndex, 'jsonView': resourceEntryState.jsonView, 'viewMyWorkModalOpen': resourceEntryState.viewMyWorkModalOpen
  });
  const [adaptationReportState, setAdaptationReportState] = useState({
    "adaptOn": false,
    "adaptationDictionary": "", "pathList": ""
  });
  const [changeAvailableToSaveState, setChangeAvailableToSaveState] = useState(false);

  const [versionHistoryState, setVersionHistoryState] = useState({ "viewVersions": false, "selectedVersion": undefined, "showButtonClicked": false });
  const [versionListState, setVersionListState] = useState([]);
  const [viewVersionDifferencesState, setViewVersionDifferencesState] = useState({ "viewDifferences": false, "showButtonClicked": false });
  const [versionDifferencesInterfaceState, setVersionDifferencesInterfaceState] = useState({ "selectedVersionLeft": undefined, "selectedVersionRight": undefined, "justTheDifferences": true });
  const [versionDifferencesState, setVersionDifferencesState] = useState();
  const [loadingVersionDifferences, setLoadingVersionDifferences] = useState(false);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [commentEntryState, setCommentEntryState] = useState({ "modalExpanded": true, "commentTitle": "", "commentSummary": "", "termVote": "" });

  const [codeSystemTermProgressState, setCodeSystemTermProgressState] = useState({ "loading": true, "termProgress": {} });

  const [referencedResourceState, setReferencedResourceState] = useState({
    "populationVariableDefinitionIndex": undefined, "referencedGroup": {}, "referencedGroupString": "", "groupInclusionCharacteristics": [], "groupExclusionCharacteristics": [], "groupCharacteristics": [], "referencedIntendedGroup": {}, "referencedIntendedGroupString": "", "intendedGroupInclusionCharacteristics": [], "intendedGroupExclusionCharacteristics": [], "intendedGroupCharacteristics": [],
    "exposureVariableDefinitionIndex": undefined, "referencedObservedExposure": {}, "referencedObservedExposureString": "", "observedExposureCharacteristicCombination": [], "observedExposureCharacteristics": [], "referencedIntendedExposure": {}, "referencedIntendedExposureString": "", "intendedExposureCharacteristicCombination": [], "intendedExposureCharacteristics": [],
    "referenceExposureVariableDefinitionIndex": undefined, "referencedObservedReferenceExposure": {}, "referencedObservedReferenceExposureString": "", "observedReferenceExposureCharacteristicCombination": [], "observedReferenceExposureCharacteristics": [], "referencedIntendedReferenceExposure": {}, "referencedIntendedReferenceExposureString": "", "intendedReferenceExposureCharacteristicCombination": [], "intendedReferenceExposureCharacteristics": [],
    "outcomeVariableDefinitionIndex": undefined, "referencedObservedOutcome": {}, "referencedObservedOutcomeString": "", "observedOutcomeCharacteristicCombination": [], "observedOutcomeCharacteristics": [], "referencedIntendedOutcome": {}, "referencedIntendedOutcomeString": "", "intendedOutcomeCharacteristicCombination": [], "intendedOutcomeCharacteristics": []
  });

  const [languageOptions, setLanguageOptions] = useState({ "options": startingLanguageOptions });

  const [groupCharacteristicKindOptions, setGroupCharacteristicKindOptions] = useState({ "options": startingGroupCharacteristicKindOptions });

  const [factorPresenceOptions, setFactorPresenceOptions] = useState([]);
  const [factorPresenceLookup, setFactorPresenceLookup] = useState({});
  const [potentialInfluenceOptions, setPotentialInfluenceOptions] = useState([]);
  const [potentialInfluenceLookup, setPotentialInfluenceLookup] = useState({});
  const [biasDirectionOptions, setBiasDirectionOptions] = useState([]);
  const [biasDirectionLookup, setBiasDirectionLookup] = useState({});
  const [biasRiskOptions, setBiasRiskOptions] = useState([]);
  const [biasRiskLookup, setBiasRiskLookup] = useState({});
  const [locationKeys, setLocationKeys] = useState([]);
  const [searchToAddToProjectModalOpenState, setSearchToAddToProjectModalOpenState] = useState({});

  const updateVersionDifferencesFunction = async () => {
    if (viewVersionDifferencesState.viewDifferences) {

      ///
      //let versionDiffJson = await getVersionDifference(resourceId, newVersionNumber, oldVersionNumber, newHistoryId, oldHistoryId, justTheDifferences, globalContext);

      let selectedVersionLeft = versionDifferencesInterfaceState.selectedVersionLeft;
      let selectedVersionRight = versionDifferencesInterfaceState.selectedVersionRight;
      if (selectedVersionLeft === selectedVersionRight) {
        //THEY SHOULDN'T BE EQUAL

      } else {
        setLoadingVersionDifferences(true);
        let justTheDifferences = versionDifferencesInterfaceState.justTheDifferences;
        let newVersionNumber;
        let oldVersionNumber;
        if (selectedVersionLeft > selectedVersionRight) {
          newVersionNumber = selectedVersionLeft;
          oldVersionNumber = selectedVersionRight;
        } else {
          newVersionNumber = selectedVersionRight;
          oldVersionNumber = selectedVersionLeft;
        }
        let newHistoryId = versionListState[versionListState.length - newVersionNumber]["historyid"]; //Because reverse order, so for example if there are 64 versions and I selected version 62, 64 - 62 == index 2, version 63 would be index 1, and version 64 would be index 0
        let oldHistoryId = versionListState[versionListState.length - oldVersionNumber]["historyid"];
        let versionDiffJson = await getVersionDifference(resourceId, newVersionNumber, oldVersionNumber, newHistoryId, oldHistoryId, justTheDifferences, globalContext); //Temporary test
        setVersionDifferencesState(versionDiffJson);
        setLoadingVersionDifferences(false);
      }

    }
  };

  useEffect(() => {
    updateVersionDifferencesFunction();
  }, [versionDifferencesInterfaceState]);

  useEffect(() => {
    return history.listen(location => {
      /*
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ]);
      }
      */

      if (history.action === "POP") {
        setFhirEntryState(prevState => { return { ...prevState }; });
        /*
        if (locationKeys[1] === location.key) {
          // Handle back event
          setLocationKeys(([ _, ...keys ]) => keys);
  
        } else {
          // Handle forward event 
          setLocationKeys((keys) => [ location.key, ...keys ]);
        }
        */
      } else if (history.action === "PUSH") {
        //setFhirEntryState(prevState  => { return { ...prevState, conceptName: "" }; });
      }
    })
  }, [locationKeys]);

  const updateFhirEntryStateFunction = async () => {
    if (resourceEntryState !== undefined && resourceEntryState.found !== null) {
      let fhirJsonString = resourceEntryState["fhirResource"];
      let fhirJson;
      let status = resourceEntryState.status;
      let found = resourceEntryState.found;
      let loggedin = resourceEntryState.loggedin;
      let readpermission = resourceEntryState.readpermission;
      let editpermission = resourceEntryState.editpermission;
      let adminpermission = resourceEntryState.adminpermission;

      //let commentslist = resourceEntryState.commentslist;
      let commentsdict = resourceEntryState.commentsdict;
      //let voteslist = resourceEntryState.voteslist;
      let votesdict = resourceEntryState.votesdict;
      //let myvoteslist = resourceEntryState.myvoteslist;
      let myvotesdict = resourceEntryState.myvotesdict;

      let fhirEntryTitle = fhirEntryState.title;

      let fhirEntryStatus = fhirEntryState.status;
      let fhirEntryEditMode = fhirEntryState.editMode;
      let fhirEntryStartingVersionId = fhirEntryState.startingVersionId;
      let fhirEntryStartingStatus = fhirEntryState.startingStatus;

      let riskOfBiasAssessmentToolResource = fhirEntryState.riskOfBiasAssessmentToolResource;
      let recommendationJustification = fhirEntryState.recommendationJustification;
      let systematicReviewProfile = fhirEntryState.systematicReviewProfile;
      let recommendationProfile = fhirEntryState.recommendationProfile;
      let guidelineProfile = fhirEntryState.guidelineProfile;
      let m11reportProfile = fhirEntryState.m11reportProfile;
      let evidenceReportPackageProfile = fhirEntryState.evidenceReportPackageProfile;
      let summaryOfFindingsProfile = fhirEntryState.summaryOfFindingsProfile;
      let comparativeEvidenceReportProfile = fhirEntryState.comparativeEvidenceReportProfile;
      let baselineMeasureReportProfile = fhirEntryState.baselineMeasureReportProfile;
      let participantFlowReportProfile = fhirEntryState.participantFlowReportProfile;
      let outcomeMeasureReportProfile = fhirEntryState.outcomeMeasureReportProfile;
      let classification = fhirEntryState.classification;
      let rating = fhirEntryState.rating;
      let comment = fhirEntryState.comment;
      let adaptation = fhirEntryState.adaptation;
      let comparison = fhirEntryState.comparison;
      let characteristicDefinition = fhirEntryState.characteristicDefinition;

      let profileName = fhirEntryState.profileName;

      if (found && readpermission && fhirJsonString) {
        found = true;
        fhirJson = JSON.parse(fhirJsonString);
        fhirEntryTitle = resourceEntryState["title"];
        changeFormState(fhirEntryTitle, "tempResourceTitle");
        changeFormState(resourceId, "tempResourceId");

        if (resourceType === "ArtifactAssessment" && !riskOfBiasAssessmentToolResource) { //Checking to see if riskOfBiasAssessmentToolResource is not yet set
          for (let useContextIndex in fhirJson.useContext) {
            let useContext = fhirJson.useContext[useContextIndex];
            if (useContext.code?.display === "to be viewed by this tool" && useContext.valueCodeableConcept?.text === "Computable Publishing: Risk of Bias Assessment Tool") {
              riskOfBiasAssessmentToolResource = true;
              break;
            }
          }
          if (profileName) {

          } else {
            if (riskOfBiasAssessmentToolResource) {
              for (let contentIndex in fhirJson.content) {
                let content = fhirJson.content[contentIndex];
                if (content.informationType === "classifier" && content.type?.text === "Profile") {
                  for (let classifierIndex in content.classifier) {
                    let classifier = content.classifier[classifierIndex];
                    if (classifier.text) {
                      profileName = classifier.text;
                      break;
                    }
                  }
                }
                if (profileName) {
                  break;
                }
              }
            }
          }
        }
        if (resourceType === "ArtifactAssessment") {
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification") ||
            fhirJson.meta?.profile?.includes("https://fevir.net/Profile/RecommendationJustification")) {
            recommendationJustification = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/classification") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/classification")) {
            classification = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/rating") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/rating")) {
            rating = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/comment") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/comment")) {
            comment = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation")) {
            adaptation = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/comparison") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/comparison")) {
            comparison = true;
          }
        }

        if (resourceType === "ResearchStudy") {
          if (fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/systematic-review")) {
            systematicReviewProfile = true;
          }
        }

        if (resourceType === "Composition") {
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation")) {
            recommendationProfile = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/guideline") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/guideline")) {
            guidelineProfile = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package")) {
            evidenceReportPackageProfile = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report")) {
            m11reportProfile = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings")) {
            summaryOfFindingsProfile = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report")) {
            comparativeEvidenceReportProfile = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report")) {
            baselineMeasureReportProfile = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report")) {
            participantFlowReportProfile = true;
          }
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report")) {
            outcomeMeasureReportProfile = true;
          }
        }

        if (resourceType === "EvidenceVariable") {
          if (fhirJson.meta?.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/cohort-definition") ||
            fhirJson.meta?.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/cohort-definition")) {
            characteristicDefinition = true;
          }
        }

        if (status) {
        } else if (fhirJson.status && fhirJsonString) {
          status = JSON.parse(fhirJsonString)["status"];
        }
      }
      if (resourceEntryState.startEditMode) {
        fhirEntryEditMode = true * editpermission;
      }

      if (pageEditModeState === resourceId && resourceType === "ArtifactAssessment" && riskOfBiasAssessmentToolResource) {
        fhirEntryEditMode = true;
      }

      fhirEntryStatus = status;
      if (fhirJson?.meta?.versionId) {
        fhirEntryStartingVersionId = fhirJson.meta.versionId;
      }
      if (fhirJson?.status) {
        fhirEntryStartingStatus = fhirJson.status;
      }

      setFhirEntryState(prevState => {
        return {
          ...prevState,
          found: found,
          loggedin: loggedin,
          readpermission: readpermission,
          editpermission: editpermission,
          adminpermission: adminpermission,
          title: fhirEntryTitle,
          status: fhirEntryStatus,
          startingStatus: fhirEntryStartingStatus,
          startingVersionId: fhirEntryStartingVersionId,
          loadedFhirEntryString: fhirJsonString,
          fhirEntryString: fhirJsonString,
          classification: classification,
          rating: rating,
          comment: comment,
          adaptation: adaptation,
          comparison: comparison,
          riskOfBiasAssessmentToolResource: riskOfBiasAssessmentToolResource,
          recommendationJustification: recommendationJustification,
          systematicReviewProfile: systematicReviewProfile,
          recommendationProfile: recommendationProfile,
          guidelineProfile: guidelineProfile,
          m11reportProfile: m11reportProfile,
          evidenceReportPackageProfile: evidenceReportPackageProfile,
          summaryOfFindingsProfile: summaryOfFindingsProfile,
          comparativeEvidenceReportProfile: comparativeEvidenceReportProfile,
          baselineMeasureReportProfile: baselineMeasureReportProfile,
          participantFlowReportProfile: participantFlowReportProfile,
          outcomeMeasureReportProfile: outcomeMeasureReportProfile,
          characteristicDefinition: characteristicDefinition,
          profileName: profileName,
          //commentslist: commentslist,
          commentsdict: commentsdict,
          //voteslist: voteslist,
          votesdict: votesdict,
          //myvoteslist: myvoteslist,
          myvotesdict: myvotesdict,
          editMode: fhirEntryEditMode,
          loading: false
        };
      });

      if (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource && factorPresenceOptions.length === 0) {
        let factorPresenceOptionsResponse = await getValueSetCodesAsOptions('29654', globalContext);
        let [factorPresenceOptions, factorPresenceOptionsLookup] = factorPresenceOptionsResponse;
        setFactorPresenceOptions(factorPresenceOptions);
        setFactorPresenceLookup(factorPresenceOptionsLookup);

        let potentialInfluenceResponse = await getValueSetCodesAsOptions('29656', globalContext);
        let [potentialInfluenceOptions, potentialInfluenceOptionsLookup] = potentialInfluenceResponse;
        setPotentialInfluenceOptions(potentialInfluenceOptions);
        setPotentialInfluenceLookup(potentialInfluenceOptionsLookup);

        let biasDirectionResponse = await getValueSetCodesAsOptions('29655', globalContext);
        let [biasDirectionOptions, biasDirectionOptionsLookup] = biasDirectionResponse;
        setBiasDirectionOptions(biasDirectionOptions);
        setBiasDirectionLookup(biasDirectionOptionsLookup);

        let biasRiskResponse = await getValueSetCodesAsOptions('29653', globalContext);
        let [biasRiskOptions, biasRiskOptionsLookup] = biasRiskResponse;
        setBiasRiskOptions(biasRiskOptions);
        setBiasRiskLookup(biasRiskOptionsLookup);
      }

      if (resourceType === "Project") {
        loadProjectResourcesList(setFhirEntryState, resourceType, resourceId, false, globalContext);
      }

      if (resourceType === "Recommendation") {
        let referencedGroupJson;
        let referencedExposureJson;
        let referencedReferenceExposureJson;
        let referencedOutcomeJson;
        if (fhirJson) {
          let referencedObservedGroupUrl;
          let referencedIntendedGroupUrl;
          let referencedObservedOutcomeUrl;
          let referencedIntendedOutcomeUrl;
          let referencedObservedExposureUrl;
          let referencedIntendedExposureUrl;
          let referencedObservedReferenceExposureUrl;
          let referencedIntendedReferenceExposureUrl;
          if (fhirJson.subjectReference?.reference) {
            referencedObservedGroupUrl = fhirJson.subjectReference.reference;
          }
          if (referencedGroupJson === undefined) {
            await loadReferencedResource(getResource, userState, setReferencedResourceState, setGroupCharacteristicKindOptions, referencedObservedGroupUrl, "referencedGroup", "referencedGroupString", "groupCharacteristics", "groupExclusionCharacteristics", "groupInclusionCharacteristics");
            await loadReferencedResource(getResource, userState, setReferencedResourceState, setGroupCharacteristicKindOptions, referencedIntendedGroupUrl, "referencedIntendedGroup", "referencedIntendedGroupString", "intendedGroupCharacteristics", "intendedGroupExclusionCharacteristics", "intendedGroupInclusionCharacteristics");
          }
          if (referencedExposureJson === undefined) {
            await loadReferencedResource(getResource, userState, setReferencedResourceState, setGroupCharacteristicKindOptions, referencedObservedExposureUrl, "referencedObservedExposure", "referencedObservedExposureString", "observedExposureCharacteristics", "observedExposureExclusionCharacteristics", "observedExposureInclusionCharacteristics");
            await loadReferencedResource(getResource, userState, setReferencedResourceState, setGroupCharacteristicKindOptions, referencedIntendedExposureUrl, "referencedIntendedExposure", "referencedIntendedExposureString", "intendedExposureCharacteristics", "intendedExposureExclusionCharacteristics", "intendedExposureInclusionCharacteristics");
          }
          if (referencedReferenceExposureJson === undefined) {
            await loadReferencedResource(getResource, userState, setReferencedResourceState, setGroupCharacteristicKindOptions, referencedObservedReferenceExposureUrl, "referencedObservedReferenceExposure", "referencedObservedReferenceExposureString", "observedReferenceExposureCharacteristics", "observedReferenceExposureExclusionCharacteristics", "observedReferenceExposureInclusionCharacteristics");
            await loadReferencedResource(getResource, userState, setReferencedResourceState, setGroupCharacteristicKindOptions, referencedIntendedReferenceExposureUrl, "referencedIntendedReferenceExposure", "referencedIntendedReferenceExposureString", "intendedReferenceExposureCharacteristics", "intendedReferenceExposureExclusionCharacteristics", "intendedReferenceExposureInclusionCharacteristics");
          }
          if (referencedOutcomeJson === undefined) {
            await loadReferencedResource(getResource, userState, setReferencedResourceState, setGroupCharacteristicKindOptions, referencedObservedOutcomeUrl, "referencedObservedOutcome", "referencedObservedOutcomeString", "observedOutcomeCharacteristics", "observedOutcomeExclusionCharacteristics", "observedOutcomeInclusionCharacteristics");
            await loadReferencedResource(getResource, userState, setReferencedResourceState, setGroupCharacteristicKindOptions, referencedIntendedOutcomeUrl, "referencedIntendedOutcome", "referencedIntendedOutcomeString", "intendedOutcomeCharacteristics", "intendedOutcomeExclusionCharacteristics", "intendedOutcomeInclusionCharacteristics");
          }
        }
      }

    }
  };

  useEffect(() => {
    updateFhirEntryStateFunction();
  }, [resourceEntryState]);

  const associateResourceWithProjectFunction = async () => {
    if (searchToAddToProjectModalOpenState.selectedResourceReference) {
      let targetfoi = searchToAddToProjectModalOpenState.selectedResourceReference;
      let projectfoi = resourceId;
      //setReferenceState(selectResourceFromSearchState.selectedResourceReference); REPLACE WITH ACTION
      try {
        const associateProjectBody = {
          'functionid': 'associateresourcewithproject',
          'idToken': '',
          'targetfoi': targetfoi,
          'projectfoi': projectfoi
        };
        let response = {};
        response = await submitToFevirServer(globalContext, 10000, associateProjectBody, true, false);
        if (!response.success) {
          alert("Problem associating the Resource with the Project");
        }
      } catch (e) { alert("Problem associating the Resource with the Project"); }
    }
  };

  useEffect((() => {
    associateResourceWithProjectFunction();
  }), [searchToAddToProjectModalOpenState.selectedResourceReference]);

  const updateFhirEntryStateFunctionAgain = async () => {

    if (fhirEntryState.loading && pageEditModeState && indexPath.length > 0) {
      if ((resourceType === "ArtifactAssessment" || claimedResourceType === "ArtifactAssessment") || (resourceType === "EvidenceVariable" || claimedResourceType === "EvidenceVariable")) {
        if (fhirEntryState.activeIndex !== 1) {
          setFhirEntryState(prevState => { return { ...prevState, activeIndex: 1 }; });
        }
      }
    }

    if (resourceType !== "Citation" && resourceType !== "Project" && resourceType !== "Evidence" &&
      resourceType !== "EvidenceVariable" && resourceType !== "Group" && resourceType !== "Composition" &&
      resourceType !== "PlanDefinition" && resourceType !== "Library" && resourceType !== "List" && resourceType !== "Measure" &&
      resourceType !== "ActivityDefinition" && resourceType !== "SoftwareScript" && resourceType !== "Characteristic" &&
      resourceType !== "ArtifactComment" && resourceType !== "ArtifactAssessment" && resourceType !== "CodeSystem" &&
      resourceType !== "ResearchStudy" && resourceType !== "ValueSet" && resourceType !== "SchemaElement" && resourceType !== "Bundle") {

      if (claimedResourceType === "ResearchStudy") {
        changeFormState("FEvIR®: ResearchStudy Viewer", "pageTitle");
      } else if (claimedResourceType !== "ArtifactAssessment" && claimedResourceType !== "EvidenceVariable") {
        changeFormState("FEvIR®: Resource Viewer", "pageTitle");
      }
    } else if (resourceType === "CodeSystem") {
      let stateChanged = false;
      let comments;
      let votes;
      let myvotes;
      if (fhirEntryState.editMode) {
        changeFormState("FEvIR®: CodeSystem Builder", "pageTitle");
      } else {
        changeFormState("FEvIR®: CodeSystem Viewer", "pageTitle");
      }
      try {
        if (fhirEntryState.fhirEntryString) {
          let json = JSON.parse(fhirEntryState.fhirEntryString);
          if (fhirEntryState.hasApprovalProperty == undefined) {
            if (json.property) {
              for (let property of json.property) {
                if (property.code && property.code == "approval") {
                  setFhirEntryState(prevState => { return { ...prevState, hasApprovalProperty: true }; });
                  break;
                }
              }
            }
          }
          if (fhirEntryState.conceptCode && (fhirEntryState.conceptCommentsExpanded || fhirEntryState.conceptVotesExpanded || fhirEntryState.conceptMyVotesExpanded)) {
            if ((fhirEntryState.comments === undefined || fhirEntryState.comments.length === 0 || fhirEntryState.votes === undefined || fhirEntryState.votes.length === 0 || fhirEntryState.myvotes === undefined || fhirEntryState.myvotes.length === 0)) {
              let commentsvotesresponse = await getResourceComments(globalContext, fhirEntryState, resourceId);
              comments = commentsvotesresponse.comments;
              votes = commentsvotesresponse.votes;
              myvotes = commentsvotesresponse.myvotes;
            }
          } else {
            comments = [];
            votes = [];
            myvotes = [];
          }

          if ((indexPath?.length > 0 || (fhirEntryState.conceptPath === undefined || fhirEntryState.conceptPath?.length === 0)) && (fhirEntryState.fhirEntryString && Object.keys(JSON.parse(fhirEntryState.fhirEntryString)).length > 0)) {
            if (fhirEntryState.submittingToServer) {
              let submitResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState,
                setFhirEntryState, globalContext, history, changeFormState, false, undefined);
            } else {

              let concept = json;
              let conceptCode = indexPath[0];
              let conceptPath = [];
              let indexPathIndexes = indexPath.slice(1, indexPath.length);
              let found = false;
              if (indexPathIndexes.length > 0) {
                for (let indexPathIndex in indexPathIndexes) {
                  let currentIndex = indexPathIndexes[indexPathIndex];
                  if (concept) {
                    concept = concept.concept;
                    if (concept && concept[currentIndex]) {
                      concept = concept[currentIndex];
                      conceptPath.push(concept.display);
                    }
                  }
                }
                if (concept && (conceptCode === concept.code || conceptCode === "undefined")) {
                  found = true;
                }
              }

              if (found === false) {
                let conceptFound = findConceptByCode(json, conceptCode, [], []);
                if (conceptFound?.length > 0) {
                  concept = conceptFound[0];
                  conceptPath = conceptFound[1];
                  indexPathIndexes = conceptFound[2];
                }
                if (concept && (conceptCode === concept.code || conceptCode === "undefined")) {
                  found = true;
                }
              }

              if (found && fhirEntryState.conceptCode !== concept.code && fhirEntryState.conceptName !== concept.display && fhirEntryState.termChanged !== true && !(fhirEntryState.newChildConcepts?.length > 0)) {

                let propertyProperties = getPropertyCodes(json);
                let propertyCodes = propertyProperties["propertyCodes"];
                let conceptProperties = findConceptProperties(concept, propertyCodes);
                let conceptOpenForVoting = false;
                let conceptPropertyComment = "";
                if (conceptProperties["open-for-voting"] && conceptProperties["open-for-voting"] !== "") {
                  conceptOpenForVoting = true;
                }
                if (conceptProperties["comment"] && conceptProperties["comment"] !== "") {
                  conceptPropertyComment = conceptProperties["comment"];
                }
                let propertyDescriptions = propertyProperties["propertyDescriptions"];
                stateChanged = true;
                setFhirEntryState(prevState => {
                  return {
                    ...prevState,
                    submittingToServer: false,
                    conceptName: concept.display,
                    conceptCode: concept.code,
                    conceptSystem: undefined,
                    conceptDefinition: concept.definition,
                    conceptPath: conceptPath,
                    conceptPathIndexes: indexPathIndexes,
                    conceptPathString: conceptPath.join(": "),
                    conceptSelected: concept,
                    alternativeTerms: getAlternativeTerms(concept),
                    propertyDescriptions: propertyDescriptions,
                    propertyCodes: propertyCodes,
                    conceptProperties: conceptProperties,
                    conceptOpenForVoting: conceptOpenForVoting,
                    conceptPropertyComment: conceptPropertyComment,
                    newChildConcepts: [],
                    comments: comments,
                    votes: votes,
                    myvotes: myvotes
                  };
                });
              }
            }
          }
        }

      } catch (e) { }

      if (!formState.hashLoaded && indexPath?.length > 0 && fhirEntryState.activeIndex !== tabPanesLookup["Term Detail"]) {
        changeResourceViewTab(tabPanesLookup["Term Detail"], "Term Detail", true);
        changeFormState(true, "hashLoaded");
      }
      if ((indexPath === undefined || indexPath.length === 0) && fhirEntryState.activeIndex === 1 && !(fhirEntryState.conceptCode === undefined || fhirEntryState.conceptCode === "")) {
        stateChanged = true;
        setFhirEntryState(prevState => {
          return {
            ...prevState,
            characteristicSelected: undefined, conceptName: "", conceptCode: "", conceptSystem: "",
            conceptDefinition: undefined, conceptPath: undefined, conceptPathIndexes: undefined,
            conceptPathString: undefined, conceptSelected: undefined, newChildConcepts: [],
            comments: comments, votes: [], myvotes: []
          };
        });
      }
      if (stateChanged === false && ((comments && fhirEntryState.comments && fhirEntryState.comments.length !== comments.length) || (votes && fhirEntryState.votes && fhirEntryState.votes.length !== votes.length) || (myvotes && fhirEntryState.myvotes && fhirEntryState.myvotes.length !== myvotes.length))) {
        setFhirEntryState(prevState => { return { ...prevState, comments: comments, votes: votes, myvotes: myvotes }; });
      }
    } else if (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource) {

      let stateChanged = false;

      if (fhirEntryState.activeIndex !== tabPanesLookup["Term Detail"] && resourceType === "ArtifactAssessment" && (pageEditModeState || fhirEntryState.editMode)) {
        changeResourceViewTab(tabPanesLookup["Term Detail"], "Term Detail", true);
        changeFormState(true, "hashLoaded");
      }

      try {
        if (fhirEntryState.fhirEntryString) {
          let json = JSON.parse(fhirEntryState.fhirEntryString);
          let concept = json;
          let jsonConceptCode;
          let jsonConceptDisplay;
          let jsonConceptSystem;
          let conceptCode = indexPath[0];
          let conceptPath = [];
          let indexPathIndexes = indexPath.slice(1, indexPath.length);
          let found = false;

          let conceptFound = findRobatConceptByCode(json, conceptCode, [], []);
          if (conceptFound?.length > 0) {
            concept = conceptFound[0];
            conceptPath = conceptFound[1];
            indexPathIndexes = conceptFound[2];
            if (concept.type) {
              for (let codingIndex in concept.type.coding) {
                let coding = concept.type.coding[codingIndex];
                if (coding.code) {
                  jsonConceptCode = coding.code;
                  jsonConceptSystem = coding.system;
                  jsonConceptDisplay = coding.display;
                  break;
                }
              }
            }
          }
          if (concept && (conceptCode === jsonConceptCode || conceptCode === "undefined")) {
            found = true;
          }

          if (found && fhirEntryState.conceptCode !== jsonConceptCode && fhirEntryState.conceptName !== jsonConceptDisplay) {
            let propertyProperties = getPropertyCodes(json);
            let propertyCodes = propertyProperties["propertyCodes"];
            let conceptProperties = findConceptProperties(concept, propertyCodes);
            let propertyDescriptions = propertyProperties["propertyDescriptions"];
            stateChanged = true;
            setFhirEntryState(prevState => {
              return {
                ...prevState,
                submittingToServer: false,
                conceptName: jsonConceptDisplay,
                conceptCode: jsonConceptCode,
                conceptSystem: jsonConceptSystem,
                conceptDefinition: concept.definition,
                conceptPath: conceptPath,
                conceptPathIndexes: indexPathIndexes,
                conceptPathString: conceptPath.join(": "),
                conceptSelected: concept,
                alternativeTerms: getAlternativeTerms(concept),
                propertyDescriptions: propertyDescriptions,
                propertyCodes: propertyCodes,
                conceptProperties: conceptProperties
              };
            });
          }
        }
      } catch (e) { }
    } else if (resourceType === "ArtifactAssessment" && fhirEntryState.adaptation && false) {
      //for later use if there is a need to reach the page by URL
      if (fhirEntryState.activeIndex !== tabPanesLookup["Adaptation Detail"] && resourceType === "ArtifactAssessment" && (pageEditModeState || fhirEntryState.editMode)) {
        changeResourceViewTab(tabPanesLookup["Adaptation Detail"], "Adaptation Detail", true);
        changeFormState(true, "hashLoaded");
      }
      try {
        if (fhirEntryState.fhirEntryString) {
          let json = JSON.parse(fhirEntryState.fhirEntryString);
          //todo
        }
      } catch (e) { }
    } else if (resourceType === "EvidenceVariable") {
      if (fhirEntryState.fhirEntryString && indexPath?.length > 0 && JSON.stringify(indexPath) !== JSON.stringify(fhirEntryState.conceptPathIndexes)) {

        if (fhirEntryState.activeIndex !== tabPanesLookup["Characteristic Detail"]) {
          changeResourceViewTab(tabPanesLookup["Characteristic Detail"], "Characteristic Detail", true);
        }
        if (formState.hashLoaded === false) {
          changeFormState(true, "hashLoaded");
        }
        let json = JSON.parse(fhirEntryState.fhirEntryString);
        let characteristic = json;
        let characteristicPath = [];
        let found = false;
        if (indexPath.length > 0) {
          for (let indexPathIndex in indexPath) {
            let currentIndex = indexPath[indexPathIndex];
            if (characteristic) {
              if (characteristic.characteristic) {
                characteristic = characteristic.characteristic;
              } else if (characteristic.definitionByCombination?.characteristic?.length > 0) {
                characteristic = characteristic.definitionByCombination.characteristic;
              }
              if (characteristic && characteristic[currentIndex]) {
                characteristic = characteristic[currentIndex];
                characteristicPath.push(characteristic.display);
              }
            }
          }
          if (characteristic) {
            found = true;
          }
        }
        if (found) {
          let tempPathString = characteristicNavigationEntryDisplay(characteristic);
          navigateToAnotherCharacteristic("Characteristic", history, fhirEntryState, setFhirEntryState, 'EvidenceVariable', resourceId,
            json, characteristic, tempPathString[1], characteristicPath, indexPath, characteristicPath.join(": "), formInputsStateRef,
            changeFhirEntryState, submitUpdatedFhirResource, globalContext, changeFormState, addToast);
        }
      }
    }
  };

  useEffect(() => {
    updateFhirEntryStateFunctionAgain();
  }, [fhirEntryState]);

  let robatTermLoad = false;
  if (fhirEntryState.loading && pageEditModeState && indexPath.length > 0 && (resourceType === "ArtifactAssessment" || claimedResourceType === "ArtifactAssessment")) {
    robatTermLoad = true;
  }

  let evidenceVariableCriteriaLoad = false;
  if (fhirEntryState.loading && pageEditModeState && indexPath.length > 0 && (resourceType === "EvidenceVariable" || claimedResourceType === "EvidenceVariable")) {
    evidenceVariableCriteriaLoad = true;
  }

  let tabPanesLookup;
  if (resourceType === "CodeSystem") {
    tabPanesLookup = { 'Text View': null, 'Term Detail': null, 'JSON View': null, 'Usage View': null };
  } else if (robatTermLoad || (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource && (pageEditModeState || fhirEntryState.editMode))) {
    tabPanesLookup = { 'Text View': null, 'Term Detail': null, 'JSON View': null, 'Usage View': null };
  //} else if (resourceType === "ArtifactAssessment" && fhirEntryState.adaptation) {
    //tabPanesLookup = { 'Text View': null, 'Adaptation Detail': null, 'JSON View': null, 'Usage View': null };
  } else if (resourceType === "ArtifactAssessment" && fhirEntryState.comparison) {
    tabPanesLookup = { 'Text View': null, 'Comparison Detail': null, 'JSON View': null, 'Usage View': null };
  } else if (evidenceVariableCriteriaLoad || (resourceType === "EvidenceVariable" && !(fhirEntryState.characteristicDefinition === true))) {
    tabPanesLookup = { 'Text View': null, 'Characteristic Detail': null, 'JSON View': null, 'Usage View': null };
  } else {
    tabPanesLookup = { 'Text View': null, 'JSON View': null, 'Usage View': null };
  }
  let tabPanes = Object.keys(tabPanesLookup).map((tab, index) => {
    tabPanesLookup[tab] = index;
    let tabObj = {
      menuItem: tab
    };
    return tabObj;
  });

  const changeFhirEntryState = (newValue, field) => {
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        //fhirEntry: newValue,
        [field]: newValue,
        //loading: false, //Commented out on 09-24-2021 Because it MIGHT no longer be needed
      };
    });
  };

  const changeResourceViewTab = async (tabIndex, tabLabel, automated) => {
    let allowTabChange = true;
    if (resourceType === "CodeSystem") {
      if (tabIndex == 1 && fhirEntryState.editMode) {
        if (window.confirm("You can't go to the Term Detail tab until you save or discard changes that you've made to the Resource. Click 'OK' to save your changes and then navigate away. Click 'Cancel' and then refresh the browser to discard the changes.")) {
          setLoadingSubmit(true);
          let submitResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState,
            setFhirEntryState, globalContext, history, changeFormState, false, undefined);
          setLoadingSubmit(false);
        } else {
          allowTabChange = false;
        }
      }
      if (tabIndex != 1) {
        if (fhirEntryState.editMode && (fhirEntryState.termChanged || fhirEntryState.newChildConcepts?.length > 0)) {
          if (window.confirm("You can't leave this tab until you save or discard changes that you've made to the term. Click 'OK' to save your changes and then navigate away. Click 'Cancel' and then refresh the browser to discard the changes.")) {
            setLoadingSubmit(true);
            let submitResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
            setLoadingSubmit(false);
            allowTabChange = false;
          } else {
            allowTabChange = false;
          }
        }
      }
      if (tabIndex == 3 && fhirEntryState.fhirEntryString) {
        let fhirJson = JSON.parse(fhirEntryState.fhirEntryString);
        if (fhirJson.version === "pre-release") {
          let termProgress;
          try {
            const usersBody = {
              'functionid': 'gettermcountincodesystem',
              'idToken': '',
              'resourceid': resourceId
            };
            let response;
            response = await submitToFevirServer(globalContext, 10000, usersBody, true, false);
            if (response.success) {
              termProgress = response.termcountinfo;
            }
          } catch (e) { }
          setCodeSystemTermProgressState(prevState => {
            return {
              ...prevState,
              termProgress: termProgress,
              loading: false
            };
          });
        }
      }
    } else if (fhirEntryState.editMode && resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource && tabIndex != 1) {
      changeFhirEntryState(false, "editMode");
      setPageEditModeState("");
      if (automated) {

      } else {
        let updateResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
        if (updateResult) {
          alert("Saved your rating change to the server, taking you out of edit mode and bringing you to the Text View tab.");
        } else {
          tabIndex = 0;
          tabLabel = "Text View";
          alert("Taking you out of edit mode and bringing you to the Text View tab.");
        }
      }
    } else if (fhirEntryState.editMode && resourceType === "EvidenceVariable" && tabIndex != 1 && formInputsStateRef?.current?.characteristicState?.termChanged) {
      //changeFhirEntryState(false, "editMode");
      setPageEditModeState("");
      if (automated) {

      } else {
        let doYouWantToSave = false;
        if (window.confirm("Do you want to save your change to criteria to the server? If not, please refresh this page to change tabs.")) {
          doYouWantToSave = true;
        }
        if (doYouWantToSave) {
          let updateResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, addToast);
          if (updateResult) {
            alert("Saved your criteria change to the server, taking you out of edit mode and bringing you to the Text View tab.");
          } else {
            tabIndex = 0;
            tabLabel = "Text View";
            alert("Taking you out of edit mode and bringing you to the Text View tab.");
          }
        } else {
          allowTabChange = false;
        }
      }
    }
    if (allowTabChange) {

      if (tabLabel === "Text View") {
        loadProjectResourcesList(setFhirEntryState, resourceType, resourceId, false, globalContext);
      } else if (tabLabel === "JSON View") {
        await builderUpdateJson(resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext);
      } else if (tabLabel === "Usage View") {
        await builderUpdateJson(resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext);
        loadUsageView(resourceType, resourceId, globalContext, fhirEntryState, setFhirEntryState);
      } else if (tabLabel === "Term Detail") {
        if (resourceType === "CodeSystem") {
          if (fhirEntryState.conceptCode) {
            history.push(`/resources/CodeSystem/${resourceId}#${fhirEntryState.conceptCode}`);
          }
        }
      } else if (tabLabel === "Characteristic Detail") {
        if (resourceType === "EvidenceVariable") {
          if (fhirEntryState.pathIndexes?.length > 0) {
            history.push(`/resources/EvidenceVariable/${resourceId}#${fhirEntryState.pathIndexes.toString()}`);
          }
        }
      //} else if (tabLabel === "Adaptation Detail") {
        //if (resourceType === "ArtifactAssessment") {
          //if (fhirEntryState.pathIndexes?.length > 0) {
            //history.push(`/resources/ArtifactAssessment/${resourceId}#${fhirEntryState.pathIndexes.toString()}`);
          //}
        //}
      }
      if (tabIndex != 1 && (resourceType === "CodeSystem" || resourceType === "EvidenceVariable")) {
        history.push(`/resources/${resourceType}/${resourceId}`);
      }
      changeFhirEntryState(tabIndex, "activeIndex");
    }
  };

  const editProjects = async () => {
    changeFhirEntryState(true, "projectChangeMode");
    let body = {
      'functionid': 'getprojectslist',
      'idToken': ""
    };

    let response = await submitToFevirServer(globalContext, 10000, body, true, false);
    if (response?.success) {
      setFhirEntryState(prevState => {
        return {
          ...prevState,
          projectsList: response.projectslist
        };
      });
    }
  }

  const ResourceStatusRadioButton = () => {

    let values = ["active", "draft"];
    let labels = ["Publish (anyone can view)", "Draft (only editors can view)"];

    if (userState.name === "Khalid Shahin" || userState.name === "Brian S. Alper" || userState.name === "Joanne Dehnbostel" || userState.name === "Kevin O'Brien") {
      values.push("retired");
      labels.push("Retired");
    }

    return <>

      <GenericToggleButtons thin={true} boxes={true} togglable={false}
        values={values} labels={labels} fieldLabel={"status"}
        elementName={"status"} changeElementName={"fhirEntryStringChange"}
        startingValue={fhirEntryState.status} setter={setFhirEntryState}
      />
      <br />
      <br />
    </>
  }

  const ViewTabPane = () => (<Tab menu={{ secondary: true, pointing: true }} panes={tabPanes} activeIndex={fhirEntryState.activeIndex}
    onTabChange={(e, data) => {
      changeResourceViewTab(data.activeIndex, tabPanes[data.activeIndex]["menuItem"], false);
      /*
        if (!(resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource && fhirEntryState.editMode)) {
          changeResourceViewTab(data.activeIndex, tabPanes[data.activeIndex]["menuItem"], false);
        } else {
          alert("You must click the Update button on the bottom of the page before switching tabs.");
        }
      */
    }}
  />);

  const CantDisplayResource = () => {
    let displayMessage = "";
    if (fhirEntryState.found === true) {
      if (fhirEntryState.loggedin) {
        displayMessage = "You do not have permission to view this resource.";
      } else {
        displayMessage = "Please log in to view resource.";
      }
    } else if (fhirEntryState.found === false) {
      displayMessage = "Resource not found";
    }
    return <span><h1>{displayMessage}<br /><br /></h1></span>
  }

  const ResourceButtons = () => {
    return <>
      {fhirEntryState.found && fhirEntryState.readpermission ?
        <>
          <Button className="formButton" content="Thank You" onClick={() => { }} disabled />
          &nbsp;&nbsp;&nbsp;
          <Button className="formButton" content="Share" onClick={() => { }} disabled />
          &nbsp;&nbsp;&nbsp;
          <Button className="formButton" content="Feedback" onClick={() => { }} disabled />
        </>
        :
        <></>
      }
      {
        fhirEntryState.editMode
          ?
          <>
            {userState.id ?
              <>
                &nbsp;&nbsp;&nbsp;
                {fhirEntryState.startingStatus !== "draft" &&
                  <>
                    <Button className="formButton" style={{ color: "#000000" }}
                      content="Edit Resource in JSON Tree Writer"
                      onClick={() => { window.open("https://khalid-shahin.github.io/JSON-Tree-Writer/?server=" + encodeURIComponent(server_address) + "&port=" + ports["master_server"] + "&extrarequestheader=functionid&extrarequestinfo=getfhirresource&responseobjectname=fhirjsonstring&string=true&resourcetype=" + resourceType + "&resourceid=" + resourceId, '_blank'); }} disabled={fhirEntryState.previousVersionLoaded} />
                    &nbsp;&nbsp;&nbsp;
                  </>
                }
                <Button className="formButton" style={{ color: "#000000" }} content="Submit Updated Resource"
                  onClick={async () => {
                    setLoadingSubmit(true);
                    await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState,
                      setFhirEntryState, globalContext, history, changeFormState, false, undefined);
                    setLoadingSubmit(false);
                  }}
                  disabled={!fhirEntryState.fhirEntryStringChange || fhirEntryState.previousVersionLoaded}
                />
              </>
              :
              <>&nbsp;&nbsp;&nbsp;Please log back in.</>
            }
          </>
          :
          <>
            {userState.id && fhirEntryState.readpermission && fhirEntryState.editpermission ?
              <>
                &nbsp;&nbsp;&nbsp;
                <Button className="formButton" style={{ color: "#000000" }} content="Edit Resource" onClick={() => { changeFhirEntryState(true, "editMode"); setPageEditModeState(resourceId); }} disabled={fhirEntryState.previousVersionLoaded} />
                {fhirEntryState.status === "draft" &&
                  <>
                    &nbsp;&nbsp;&nbsp;
                    <Button className="formButton" style={{ color: "#000000" }} content="Publish"
                      onClick={() => { changeFhirEntryState("active", "status"); submitUpdatedFhirResource("active", resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined); }}
                      disabled={fhirEntryState.previousVersionLoaded}
                    />
                  </>
                }
              </>
              :
              <></>
            }
            {fhirEntryState.found &&
              <>
                &nbsp;&nbsp;&nbsp;
                <Button className="formButton" style={{ color: "#000000" }} content="Clone Resource" onClick={() => { history.push({ "pathname": "/create/" + resourceType + "/" + resourceId, "resourceType": resourceType, "resourceId": resourceId }); }} disabled={fhirEntryState.previousVersionLoaded} />
              </>
            }
            &nbsp;&nbsp;&nbsp;
            <Button className="formButton" style={{ color: "#000000" }} content="Add New Resource" onClick={() => { history.push("/create"); }} />
          </>
      }</>
  }

  let tempResourceType = claimedResourceType;
  if (resourceType) {
    tempResourceType = resourceType;
  }
  let resourceTypeDisplay = tempResourceType || "";

  let navigationElements = [];
  if (tempResourceType === "Project") {
    navigationElements = ["Project Title", "Project Description", "Project Actions", "Project Details", "Associated Links", "Associated Documents", "Associated Resources", "Software Code", "Reference List", "How to Cite", "Metadata", "Classifiers"];
  } else if (tempResourceType === "Citation") {
    navigationElements = ["Citation Summary", "Identifiers", "Title", "Abstract", "Publication Form", "URLs", "Contributors", "Classifiers", "MeSH Headings", "Third-Party Classifiers", "Status", "Modifiers", "Related Articles", "Citation Metadata", "JSON Outline"];
  } else if (tempResourceType === "ActivityDefinition") {
    navigationElements = ["Summary", "Title", "Activity Definition", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "Library") {
    navigationElements = ["Summary", "Title", "Subject", "Content", "Parameters", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "List") {
    navigationElements = ["Summary", "Description", "List Entries", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "Bundle") {
    navigationElements = ["Metadata", "Bundle Entries", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "PlanDefinition") {
    navigationElements = ["Summary", "Title", "Goal", "Actor", "Action", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ResearchStudy") {
    navigationElements = ["Summary", "Identification", "Status", "Subject", "Protocol", "Organizations", "Recruitment", "Variables", "Results", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "SoftwareScript") {
    navigationElements = ["Title", "Detail", "Code String", "Files/Documents", "How to Cite", "Metadata", "Classifiers"];
  } else if (tempResourceType === "Characteristic") {
    navigationElements = ["Description", "Definition", "Expression", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactComment") {
    navigationElements = ["Summary", "Classifiers", "Ratings", "Comments", "Metadata"];
    resourceTypeDisplay = "Comment/Rating";
  } else if (tempResourceType === "Recommendation") {
    navigationElements = ["Summary", "Population", "Action", "Implementation", "Evidence", "Justification", "Classifiers", "Metadata"];
  } else if (tempResourceType === "Evidence") {
    navigationElements = ["Summary", "Population", "Exposures", "Outcome", "Study Design", "Statistics", "Certainty", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
    if (fhirEntryState.editMode) {
      navigationElements = ["Summary", "Variable Definitions", "Study Design", "Statistics", "Certainty", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
    }
  } else if (tempResourceType === "Group") {
    navigationElements = ["Summary", "Characteristic Combination", "Inclusion Criteria", "Exclusion Criteria", "Members", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "Composition") {
    navigationElements = ["Summary", "Table View", "Section Detail", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
    if (fhirEntryState.editMode) {
      navigationElements = ["Metadata", "Table View", "Section Definition", "How to Cite", "Associated Resources", "Classifiers", "JSON Outline"];
    }
    if (fhirEntryState.evidenceReportPackageProfile) {
      if (fhirEntryState.editMode) {
        navigationElements = ["Metadata", "Introduction", "Discussion", "Methods", "References", "Competing Interests", "Acknowledgements", "Appendices", "How to Cite", "Associated Resources", "Classifiers", "JSON Outline"];
      } else {
        navigationElements = ["Introduction", "Discussion", "Methods", "References", "Competing Interests", "Acknowledgements", "Appendices", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      }
    }
    if (fhirEntryState.recommendationProfile) {
      navigationElements = ["Summary", "Introduction", "Recommendation", "Justification", "Considerations", "Methods", "Evidence", "Discussion", "References", "Competing Interests", "Acknowledgements", "Appendices", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
    }
    if (fhirEntryState.guidelineProfile) {
      navigationElements = ["Summary", "Introduction", "Methods", "Recommendations", "Discussion", "References",
        "Competing Interests", "Acknowledgements", "Appendices",
        "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
    }
    if (fhirEntryState.m11reportProfile) {
      navigationElements = ["Title Page", "1 Protocol Summary", "2 Introduction", "3 Estimands", "4 Trial Design",
        "5 Trial Population", "6 Trial Intervention", "7 Participant Discontinuation",
        "8 Trial Assessments", "9 Adverse Events", "10 Statistics", "11 Oversight",
        "12 Supporting Details", "13 Glossary", "14 References",
        "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
    }
    if (fhirEntryState.summaryOfFindingsProfile) {
      if (fhirEntryState.editMode) {
        navigationElements = ["Table View", "Metadata", "Column Definition", "Section Definition", "How to Cite", "Associated Resources", "Classifiers", "JSON Outline"];
      } else {
        navigationElements = ["Summary", "Table View", "Section Detail", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
      }
    }
    if (fhirEntryState.comparativeEvidenceReportProfile) {
      navigationElements = ["Introduction", "Population", "Intervention", "Comparator", "Research Study", "Methods",
        "Baseline Measures", "Participant Flow", "Outcomes", "Discussion", "References",
        "Competing Interests", "Acknowledgements", "Appendices",
        "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
    }
    if (fhirEntryState.baselineMeasureReportProfile) {
      navigationElements = ["Groups", "Baseline Measures", "Evidence", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
    }
    if (fhirEntryState.participantFlowReportProfile) {
      navigationElements = ["Groups", "Participant Flow Measures", "Evidence", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
    }
    if (fhirEntryState.outcomeMeasureReportProfile) {
      navigationElements = ["Groups", "Outcome Measures", "Evidence", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
    }
  } else if (tempResourceType === "Measure") {
    navigationElements = ["Summary", "Title", "Measure Definition", "How to Cite", "Metadata", "Associated Resources", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "EvidenceReport") {
    navigationElements = ["Summary", "Table View", "Populations", "Exposures", "Results", "Risk of Bias", "Metadata"];
  } else if (tempResourceType === "EvidenceVariable") {
    navigationElements = ["Summary", "Definition", "Handling", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
    if (!fhirEntryState.characteristicDefinition) {
      navigationElements = ["Summary", "Definition", "Inclusion Criteria", "Exclusion Criteria", "Handling", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
    }
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.classification) {
    navigationElements = ["Classified Item", "Classifications", "Classification Status", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.rating) {
    navigationElements = ["Rated Item", "Ratings", "Rating Status", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.comment) {
    navigationElements = ["Comment On", "Comments", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.adaptation) {
    navigationElements = ["Adapted Item", "Table View", "Adaptations", "Adaptation Status", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.comparison) {
    navigationElements = ["Compared Items", "Comparisons", "Adaptation Status", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource) {
    navigationElements = ["Top", "Risk of Bias Assessment", "Details", "Metadata"];
  } else if (tempResourceType === "ArtifactAssessment" && fhirEntryState.recommendationJustification) {
    navigationElements = ["Summary", "Recommendation", "Evidence", "Net Effect", "Judgments", "Competing Interests", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "CodeSystem") {
    navigationElements = ["Description", "Properties", "Concepts", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "Questionnaire") {
    navigationElements = ["Summary", "Questionnaire", "Metadata"];
  } else if (tempResourceType === "ValueSet") {
    navigationElements = ["Description", "Content", "How to Cite", "Metadata", "Classifiers", "JSON Outline"];
  } else if (tempResourceType === "SchemaElement") {
    navigationElements = ["Identification", "Details", "Mapping"];
  }

  let editDisplay = "Edit " + resourceTypeDisplay;
  if (fhirEntryState.riskOfBiasAssessmentToolResource) {
    editDisplay = "Edit ROB Assessment";
  } else if (fhirEntryState.recommendationJustification) {
    editDisplay = "Edit Recommendation Justification";
    resourceTypeDisplay = "Recommendation Justification";
  } else if (fhirEntryState.systematicReviewProfile) {
    editDisplay = "Edit Systematic Review";
    resourceTypeDisplay = "Systematic Review";
  } else if (fhirEntryState.recommendationProfile) {
    editDisplay = "Edit Recommendation";
    resourceTypeDisplay = "Recommendation";
  } else if (fhirEntryState.guidelineProfile) {
    editDisplay = "Edit Guideline";
    resourceTypeDisplay = "Guideline";
  } else if (fhirEntryState.evidenceReportPackageProfile) {
    editDisplay = "Edit Evidence Report Package";
    resourceTypeDisplay = "EvidenceReportPackage";
  } else if (fhirEntryState.m11reportProfile) {
    editDisplay = "Edit M11 Report";
    resourceTypeDisplay = "M11 Report";
  } else if (fhirEntryState.summaryOfFindingsProfile) {
    editDisplay = "Edit Summary of Findings";
    resourceTypeDisplay = "Summary of Findings";
  } else if (fhirEntryState.comparativeEvidenceReportProfile) {
    editDisplay = "Edit Comparative Evidence Report";
    resourceTypeDisplay = "Comparative Evidence Report";
  } else if (fhirEntryState.baselineMeasureReportProfile) {
    editDisplay = "Edit Baseline Measure Report";
    resourceTypeDisplay = "Baseline Measure Report";
  } else if (fhirEntryState.participantFlowReportProfile) {
    editDisplay = "Edit Participant Flow Report";
    resourceTypeDisplay = "Participant Flow Report";
  } else if (fhirEntryState.outcomeMeasureReportProfile) {
    editDisplay = "Edit Outcome Measure Report";
    resourceTypeDisplay = "Outcome Measure Report";
  }

  let inviteText = "";
  if (fhirEntryState.inviteCode) {
    inviteText = `You're invited by ${userState.name} to become an editor for the ${fhirEntryState.title} resource FOI ${resourceId}\nYou can accept this invite by going to this URL, be logged in, and then click the Accept button.\nhttps://fevir.net/invite/${fhirEntryState.inviteCode}`;
  }

  const submitNewClassification = async (resourceType, resourceId, fhirEntryState, history) => {
    const currenttime = new Date().toISOString();
    let newClassification = {};
    let title = "Classification of " + fhirEntryState.title + " by " + globalContext.userState.name + " " + currenttime;
    newClassification = {
      "resourceType": "ArtifactAssessment",
      "extension": [
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
          "valueCode": "active"
        },
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
          "valueContactDetail": {
            "name": globalContext.userState.name
          }
        }
      ],
      "title": title,
      "artifactReference": {
        "reference": resourceType + "/" + resourceId,
        "type": resourceType,
        "display": fhirEntryState.title
      },
      "meta": {
        "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
      }
    };
    const body = {
      'functionid': 'submitfhirresource',
      'idToken': '',
      'fhirEntry': JSON.stringify(newClassification),
      'title': title,
      'status': "active",
    };
    if (resourceType === 'Citation') {
      try {
        let citationJson = JSON.parse(fhirEntryState.fhirEntryString);
        if (citationJson.useContext && Array.isArray(citationJson.useContext) &&
          citationJson.useContext.length > 0) {
          for (const useContext of citationJson.useContext) {
            if (useContext.code?.code === "fevir-platform-use" &&
              useContext.valueCodeableConcept && useContext.valueCodeableConcept.coding &&
              Array.isArray(useContext.valueCodeableConcept.coding) && useContext.valueCodeableConcept.coding.length > 0 &&
              (useContext.valueCodeableConcept.coding[0].code === "fhir-resource" || useContext.valueCodeableConcept.coding[0].code === "project-specific")) {
              let citedArtifactFoi;
              if (citationJson.citedArtifact?.identifier && Array.isArray(citationJson.citedArtifact.identifier)) {
                for (let identifier of citationJson.citedArtifact.identifier) {
                  if (identifier.type?.text === "FEvIR Object Identifier" && identifier.system === "https://fevir.net") {
                    citedArtifactFoi = parseInt(identifier.value);
                  }
                }
              }
              if (citedArtifactFoi && typeof citedArtifactFoi === "number") {
                body.aboutformstateid = citedArtifactFoi;
                if (citationJson.citedArtifact.title && Array.isArray(citationJson.citedArtifact.title) &&
                  citationJson.citedArtifact.title.length > 0 && citationJson.citedArtifact.title[0].text) {
                  body.targettitle = citationJson.citedArtifact.title[0].text;
                }
                let citedResourceType = useContext.valueCodeableConcept.text || "Resource";
                newClassification.artifactReference = {
                  "reference": citedResourceType + "/" + citedArtifactFoi,
                  "type": citedResourceType,
                  "display": body.targettitle
                }
                if (citedResourceType === "Resource") {
                  delete newClassification.artifactReference.type;
                }
                body.fhirEntry = JSON.stringify(newClassification);
              }
            }
          }
        }
      } catch {
        alert('failure changing body.targettitle (This should only occur when Classify applied to a Citation of a FHIR Resource on the FEvIR Platform.');
      }
    }
    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
    if (response?.success) {
      history.push(`/`);
      history.push(`/resources/${response.resourcetype}/${response.formstateid}/edit`);
    }
  }

  const submitNewRating = async (resourceType, resourceId, fhirEntryState, history) => {
    const currenttime = new Date().toISOString();
    let newRating = {};
    let title = "Rating of " + fhirEntryState.title + " by " + globalContext.userState.name + " " + currenttime;
    newRating = {
      "resourceType": "ArtifactAssessment",
      "extension": [
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
          "valueCode": "active"
        },
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
          "valueContactDetail": {
            "name": globalContext.userState.name
          }
        }
      ],
      "title": title,
      "artifactReference": {
        "reference": resourceType + "/" + resourceId,
        "type": resourceType,
        "display": fhirEntryState.title
      },
      "meta": {
        "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/rating"]
      }
    };
    const body = {
      'functionid': 'submitfhirresource',
      'idToken': '',
      'fhirEntry': JSON.stringify(newRating),
      'title': title,
      'status': "active",
    };
    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
    if (response?.success) {
      history.push(`/`);
      history.push(`/resources/${response.resourcetype}/${response.formstateid}/edit`);
    }
  }

  const submitNewComment = async (resourceType, resourceId, fhirEntryState, history) => {
    const currenttime = new Date().toISOString();
    let newComment = {};
    let title = "Comment on " + fhirEntryState.title + " by " + globalContext.userState.name + " " + currenttime;
    newComment = {
      "resourceType": "ArtifactAssessment",
      "extension": [
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
          "valueCode": "active"
        },
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
          "valueContactDetail": {
            "name": globalContext.userState.name
          }
        }
      ],
      "title": title,
      "artifactReference": {
        "reference": resourceType + "/" + resourceId,
        "type": resourceType,
        "display": fhirEntryState.title
      },
      "meta": {
        "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/comment"]
      }
    };
    const body = {
      'functionid': 'submitfhirresource',
      'idToken': '',
      'fhirEntry': JSON.stringify(newComment),
      'title': title,
      'status': "active",
    };
    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
    if (response?.success) {
      history.push(`/`);
      history.push(`/resources/${response.resourcetype}/${response.formstateid}/edit`);
    }
  }

  const submitNewAdaptation = async (resourceType, resourceId, fhirEntryState, history) => {
    let title = "";
    let fhirJson;
    if (fhirEntryState.fhirEntryString) {
      fhirJson = JSON.parse(fhirEntryState.fhirEntryString);
    }
    if (fhirJson) {
      let sourceTitle = fhirJson.title || fhirJson.name || "[Untitled Resource]";
      title = globalContext.userState.name + "'s Adaptation of " + sourceTitle;
    }
    const submittingAdaptation = async (title) => {
      let adaptationContent = [];
      if (fhirJson && Object.keys(fhirJson).length) {
        for (let key of Object.keys(fhirJson)) {
          let valueType;
          let valueTypeClassifier;
          let typeValue;
          let keyValue;
          let componentComponents;
          let adaptationComponent;
          if (typeof (fhirJson[key]) === "string" || typeof (fhirJson[key]) === "number" || typeof (fhirJson[key]) === "boolean") {
            valueType = 'primitive';
            valueTypeClassifier = {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112109",
                "code": typeof (fhirJson[key]) + "-datatype",
                "display": typeof (fhirJson[key]) + " Datatype"
              }]
            };
          } else if (key === 'coding') {
            valueType = 'Coding';
            if (Array.isArray(fhirJson[key])) {
              valueTypeClassifier = {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112109",
                  "code": "coding-array",
                  "display": "Coding Array"
                }]
              };
            } else if (typeof (fhirJson[key]) === 'object') {
              valueTypeClassifier = {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112109",
                  "code": "single-coding-object",
                  "display": "Single Coding Object"
                }]
              };
            }
          } else if (Array.isArray(fhirJson[key]) && fhirJson[key].length > 0) {
            valueType = 'array';
            let arrayValueDatatype = getFhirDatatype(key, fhirJson[key]);
            if (arrayValueDatatype !== "ERROR--Element value is an Array not matched.") {
              arrayValueDatatype = arrayValueDatatype.replace("array-of-", "");
              valueTypeClassifier = {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112109",
                  "code": "manageAs" + arrayValueDatatype,
                  "display": "Manage as " + arrayValueDatatype
                }]
              };
            }
            if (arrayValueDatatype === "ERROR--Element value is an Array not matched.") {
              console.log(key + ' -- ERROR--Element value is an Array not matched.');
            }
          } else if (typeof (fhirJson[key]) === 'object') {
            valueType = 'object';
            let objectValueDatatype = getFhirDatatype(key, fhirJson[key]);
            valueTypeClassifier = {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112109",
                "code": "manageAs" + objectValueDatatype,
                "display": "Manage as " + objectValueDatatype
              }]
            };
          }
          switch (valueType) {
            case 'primitive':
              typeValue = {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "PrimitiveElement",
                  "display": "Primitive Element"
                }]
              };
              if (typeof (fhirJson[key]) === 'string') {
                keyValue = fhirJson[key];
              }
              if (typeof (fhirJson[key]) === 'number' || typeof (fhirJson[key]) === 'boolean') {
                keyValue = fhirJson[key].toString();
              }
              componentComponents = [
                {
                  "informationType": "comment",
                  "summary": keyValue,
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContent",
                      "display": "Original Resource Content"
                    }]
                  }
                },
                {
                  "informationType": "response",
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "RationaleForChange",
                      "display": "Rationale for Change to Resource Content"
                    }]
                  }
                },
                {
                  "informationType": "change-request",
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "SuggestedChange",
                      "display": "Suggested Change to Resource Content"
                    }]
                  }
                }
              ];
              adaptationComponent = {
                "informationType": "container",
                "type": typeValue,
                "classifier": [valueTypeClassifier],
                "path": [key],
                "component": componentComponents
              };
              adaptationContent.push(adaptationComponent);
              break;
            case 'Coding':
              typeValue = {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "CodingElement",
                  "display": "Coding Element"
                }]
              };
              keyValue = fhirJson[key];
              let codingDisplay;
              if (Array.isArray(keyValue)) {
                codingDisplay = keyValue.map(coding => getStringFromFHIR.Coding(coding)).join('; ');
              } else {
                codingDisplay = getStringFromFHIR.Coding(keyValue);
              }
              componentComponents = [
                {
                  "informationType": "comment",
                  "summary": codingDisplay,
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContent",
                      "display": "Original Resource Content"
                    }]
                  },
                  "component": [{
                    "informationType": "comment",
                    "summary": JSON.stringify(keyValue),
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "OriginalResourceContentJSON",
                        "display": "Original Resource Content JSON"
                      }]
                    }
                  }]
                },
                {
                  "informationType": "response",
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "RationaleForChange",
                      "display": "Rationale for Change to Resource Content"
                    }]
                  }
                },
                {
                  "informationType": "change-request",
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "SuggestedChange",
                      "display": "Suggested Change to Resource Content"
                    }]
                  }
                }
              ];
              adaptationComponent = {
                "informationType": "container",
                "type": typeValue,
                "classifier": [valueTypeClassifier],
                "path": [key],
                "component": componentComponents
              };
              adaptationContent.push(adaptationComponent);
              break;
            case 'array':
              //This can later be converted to routing array and object entries to path values and converting this to components with end-units of primitive datatypes
              keyValue = JSON.stringify(fhirJson[key]);
              adaptationComponent = {
                "informationType": "container",
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ArrayElement",
                    "display": "Array Element"
                  }]
                },
                "classifier": [valueTypeClassifier],
                "path": [key],
                "component": [
                  {
                    "informationType": "comment",
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "OriginalResourceContent",
                        "display": "Original Resource Content"
                      }]
                    },
                    "component": [{
                      "informationType": "comment",
                      "summary": keyValue,
                      "type": {
                        "coding": [{
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "OriginalResourceContentJSON",
                          "display": "Original Resource Content JSON"
                        }]
                      }
                    }]
                  },
                  {
                    "informationType": "response",
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "RationaleForChange",
                        "display": "Rationale for Change to Resource Content"
                      }]
                    }
                  },
                  {
                    "informationType": "change-request",
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "SuggestedChange",
                        "display": "Suggested Change to Resource Content"
                      }]
                    }
                  },
                  {
                    "informationType": "container",
                    "summary": keyValue,
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "ContainedElementAdaptation",
                        "display": "Contained Element Adaptation"
                      }]
                    },
                    "path": [key],
                    "component": (Array.isArray(keyValue) && keyValue.length === 0) ? [] : expandElementComponents(globalContext, keyValue, key)
                  }
                ]
              };
              adaptationContent.push(adaptationComponent);
              break;
            case 'object':
              //This can later be converted to routing array and object entries to path values and converting this to components with end-units of primitive datatypes
              keyValue = JSON.stringify(fhirJson[key]);
              adaptationComponent = {
                "informationType": "container",
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ObjectElement",
                    "display": "Object Element"
                  }]
                },
                "classifier": [valueTypeClassifier],
                "path": [key],
                "component": [
                  {
                    "informationType": "comment",
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "OriginalResourceContent",
                        "display": "Original Resource Content"
                      }]
                    },
                    "component": [{
                      "informationType": "comment",
                      "summary": keyValue,
                      "type": {
                        "coding": [{
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "OriginalResourceContentJSON",
                          "display": "Original Resource Content JSON"
                        }]
                      }
                    }]
                  },
                  {
                    "informationType": "response",
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "RationaleForChange",
                        "display": "Rationale for Change to Resource Content"
                      }]
                    }
                  },
                  {
                    "informationType": "change-request",
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "SuggestedChange",
                        "display": "Suggested Change to Resource Content"
                      }]
                    }
                  },
                  {
                    "informationType": "container",
                    "summary": keyValue,
                    "type": {
                      "coding": [{
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "ContainedElementAdaptation",
                        "display": "Contained Element Adaptation"
                      }]
                    },
                    "path": [key],
                    "component": (Array.isArray(keyValue) && keyValue.length === 0) ? [] : expandElementComponents(globalContext, keyValue, key)
                  }
                ]
              };
              adaptationContent.push(adaptationComponent);
              break;
            default:
              console.log(key + 'has unrecognized datatype')
          }
        }
      }
      let newAdaptation = {};
      newAdaptation = {
        "resourceType": "ArtifactAssessment",
        "extension": [
          {
            "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
            "valueCode": "active"
          },
          {
            "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
            "valueContactDetail": {
              "name": globalContext.userState.name
            }
          }
        ],
        "title": title,
        "artifactReference": {
          "reference": resourceType + "/" + resourceId,
          "type": resourceType,
          "display": fhirEntryState.title
        },
        "content": adaptationContent,
        "meta": {
          "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation"]
        }
      };
      const body = {
        'functionid': 'submitfhirresource',
        'idToken': '',
        'fhirEntry': JSON.stringify(newAdaptation),
        'title': title,
        'status': "active",
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response?.success) {
        history.push(`/`);
        history.push(`/resources/${response.resourcetype}/${response.formstateid}/edit`);
      }
    }
    if (title === "") {
      title = prompt("Please enter a title for your Adaptation.", "");
      submittingAdaptation(title);
    } else {
      submittingAdaptation(title);
    }
  }

  let leftVersionDifferencesOptions = [];
  let rightVersionDifferencesOptions = [];
  if (fhirEntryState.editpermission && fhirEntryState.startingVersionId > 1 && viewVersionDifferencesState.viewDifferences) {
    //{ key: "codeableConcept", value: "CodeableConcept", text: "Codeable Concept", disabled: false }
    //versionListState
    //for (let versionNumber = fhirEntryState.startingVersionId; versionNumber >= 0; versionNumber--) {
    for (let versionEntry of versionListState) {
      let versionNumber = versionEntry.version;
      //let username = versionEntry.username;
      let datecreated = versionEntry.datecreated;
      let datecreatedTruncated = datecreated.substring(0, 16);
      let text = `${versionNumber}: ${datecreatedTruncated}`; //by ${username}
      let leftOption = { key: versionNumber, value: versionNumber, text: text, disabled: versionDifferencesInterfaceState.selectedVersionRight == versionNumber, selected: versionDifferencesInterfaceState.selectedVersionLeft == versionNumber };
      let rightOption = { key: versionNumber, value: versionNumber, text: text, disabled: versionDifferencesInterfaceState.selectedVersionLeft == versionNumber, selected: versionDifferencesInterfaceState.selectedVersionRight == versionNumber };
      let index = fhirEntryState.startingVersionId - versionNumber;
      //leftVersionDifferencesOptions.push(leftOption);
      //rightVersionDifferencesOptions.push(rightOption);
      leftVersionDifferencesOptions[index] = leftOption;
      rightVersionDifferencesOptions[index] = rightOption;
    }
  }

  let loading = (globalContext.userState.loading || fhirEntryState.loading || loadingSubmit);

  const turnAdaptOn = () => {
    setFhirEntryState(prevState => { return { ...prevState, editMode: true }; })
    setAdaptationReportState(prevState => { return { ...prevState, adaptOn: true }; })
  };

  const submitResource = async (globalContext, fhirJson) => {

    let fhirEntryString = JSON.stringify(fhirJson, null, 2);
  
    const body = {
      'functionid': "submitfhirresource",
      'idToken': "",
      'fhirEntry': fhirEntryString,
      'title': fhirJson.title,
      'status': "active",
    };
  
    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
  
    if (response?.success) {
      return response.formstateid;
    }
  
  };

  const createAdaptedComposition = (compositionState, adaptationReportState, globalContext) => {
    let returningdata = { "success": false };
    try {
      let currentdate = new Date().toISOString();
      const adaptationDictionary = adaptationReportState.adaptationDictionary;
      let newCompositionJson = JSON.parse(JSON.stringify(compositionState));
      newCompositionJson.resourceType = "Composition";
      delete newCompositionJson.resourceJson;
      delete newCompositionJson.newClassifications;
      delete newCompositionJson.attester;
      let adaptationReportJson = {
        "resourceType": "ArtifactAssessment",
        "meta": {
          "profile": [
            "http://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation"
          ],
          "versionId": "1",
          "lastUpdated": currentdate
        },
        "extension": [
          {
            "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
            "valueCode": "active"
          },
          {
            "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
            "valueContactDetail": {
              "name": globalContext.userState.name
            }
          }
        ],
        "title": globalContext.userState.name + "'s Adaptation Report of " + newCompositionJson.title,
        "artifactReference": {
          "reference": "Composition/" + newCompositionJson.id,
          "type": "Composition",
          "display": newCompositionJson.title || newCompositionJson.name
        },
        "content": []
      }
      newCompositionJson.meta.versionId = "1";
      newCompositionJson.meta.lastUpdated = "";
      newCompositionJson.url = "";
      newCompositionJson.date = currentdate;
      if (newCompositionJson.version) {
        newCompositionJson.version += "-adapted";
      }
      newCompositionJson.author = [{ "display": globalContext.userState.name }];
  
      newCompositionJson.custodian = {
        "reference": "Organization/118079",
        "type": "Organization",
        "display": "Computable Publishing LLC"
      };
      newCompositionJson.relatesTo = newCompositionJson.relatesTo.filter((relatedItem) => (relatedItem.type !== "cite-as" && relatedItem.type !== "derived-from"));
      newCompositionJson.relatesTo.push({
        "type": "derived-from",
        "classifier": [{ "text": "Original Recommendation" }],
        "resourceReference": {
          "reference": "Composition/" + newCompositionJson.id,
          "type": "Composition",
          "display": newCompositionJson.title || newCompositionJson.name
        }
      });
      if (newCompositionJson.name) {
        newCompositionJson.name += "-adapted";
      }
      if (newCompositionJson.title) {
        newCompositionJson.title += "-adapted";
      }
      newCompositionJson.id = "";
      for (let section of newCompositionJson.section) {
        if (section.code) {
          let sectionCode;
          if (section.code.text) {
            sectionCode = "section:" + section.code.text;
          } else if (section.code.coding?.[0]) {
            sectionCode = "section:" + section.code.coding[0].system + "#" + section.code.coding[0].code;
          } else {
            sectionCode = "section:" + JSON.stringify(section.code);
          }
          let sectionTitleMapCode = sectionCode + ".title";
          let sectionAuthorMapCode = sectionCode + ".author";
          let sectionOrderedByMapCode = sectionCode + ".orderedBy";
          let sectionEntryMapCode = sectionCode + ".entry";
          let sectionEmptyReasonMapCode = sectionCode + ".emptyReason";
          if (adaptationDictionary[sectionCode]) {
            if (adaptationDictionary[sectionCode].itemChanged) {
              if (adaptationDictionary[sectionCode].revisedValue) {
                section.text = {
                  "status": "additional",
                  "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + adaptationDictionary[sectionCode].revisedValue + "</div>"
                }
              } else {
                section.text = {
                  "status": "empty",
                  "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
                }
              }
              let adaptationContent = {
                "informationType": "container",
                "type": { "text": "adapted element" },
                "classifier": [
                  {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112109",
                        "code": "string-datatype",
                        "display": "string Datatype"
                      }
                    ]
                  }
                ],
                "path": [sectionCode],
                "component": [
                  {
                    "summary": adaptationDictionary[sectionCode].initialValue,
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "OriginalResourceContent",
                          "display": "Original Resource Content"
                        }
                      ]
                    }
                  },
                  {
                    "summary": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + adaptationDictionary[sectionCode].revisedValue + "</div>",
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "RevisedResourceContent",
                          "display": "Revised Resource Content"
                        }
                      ]
                    }
                  }
                ]
              };
              if (adaptationDictionary[sectionCode].rationale) {
                adaptationContent.component.push({
                  "summary": adaptationDictionary[sectionCode].rationale,
                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "RationaleForChange",
                        "display": "Rationale for Change to Resource Content"
                      }
                    ]
                  }
                });
              }
              adaptationReportJson.content.push(adaptationContent);
            }
          }
          if (adaptationDictionary[sectionTitleMapCode]) {
            if (adaptationDictionary[sectionTitleMapCode].itemChanged) {
              if (adaptationDictionary[sectionTitleMapCode].revisedValue) {
                section.title = adaptationDictionary[sectionTitleMapCode].revisedValue;
              } else {
                section.title = "";
              }
              let adaptationContent = {
                "informationType": "container",
                "type": { "text": "adapted element" },
                "classifier": [
                  {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112109",
                        "code": "string-datatype",
                        "display": "string Datatype"
                      }
                    ]
                  }
                ],
                "path": [sectionCode + ".title"],
                "component": [
                  {
                    "summary": adaptationDictionary[sectionTitleMapCode].initialValue,
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "OriginalResourceContent",
                          "display": "Original Resource Content"
                        }
                      ]
                    }
                  },
                  {
                    "summary": adaptationDictionary[sectionTitleMapCode].revisedValue,
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "RevisedResourceContent",
                          "display": "Revised Resource Content"
                        }
                      ]
                    }
                  }
                ]
              };
              if (adaptationDictionary[sectionTitleMapCode].rationale) {
                adaptationContent.component.push({
                  "summary": adaptationDictionary[sectionTitleMapCode].rationale,
                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "RationaleForChange",
                        "display": "Rationale for Change to Resource Content"
                      }
                    ]
                  }
                });
              }
              adaptationReportJson.content.push(adaptationContent);
            }
          }
          if (adaptationDictionary[sectionAuthorMapCode]) {
            if (adaptationDictionary[sectionAuthorMapCode].itemChanged) {
              if (adaptationDictionary[sectionAuthorMapCode].revisedValue) {
                section.author = adaptationDictionary[sectionAuthorMapCode].revisedValue;
              } else {
                section.author = [];
              }
              let adaptationContent = {
                "informationType": "container",
                "type": { "text": "adapted element" },
                "classifier": [
                  {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112109",
                        "code": "reference-array",
                        "display": "Reference Array"
                      }
                    ]
                  }
                ],
                "path": [sectionCode + ".author"],
                "component": [
                  {
                    "summary": JSON.stringify(adaptationDictionary[sectionAuthorMapCode].initialValue),
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "OriginalResourceContent",
                          "display": "Original Resource Content"
                        }
                      ]
                    }
                  },
                  {
                    "summary": JSON.stringify(adaptationDictionary[sectionAuthorMapCode].revisedValue),
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "RevisedResourceContent",
                          "display": "Revised Resource Content"
                        }
                      ]
                    }
                  }
                ]
              };
              if (adaptationDictionary[sectionAuthorMapCode].rationale) {
                adaptationContent.component.push({
                  "summary": adaptationDictionary[sectionAuthorMapCode].rationale,
                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "RationaleForChange",
                        "display": "Rationale for Change to Resource Content"
                      }
                    ]
                  }
                });
              }
              adaptationReportJson.content.push(adaptationContent);
            }
          }
          if (adaptationDictionary[sectionOrderedByMapCode]) {
            if (adaptationDictionary[sectionOrderedByMapCode].itemChanged) {
              if (adaptationDictionary[sectionOrderedByMapCode].revisedValue) {
                section.orderedBy = adaptationDictionary[sectionOrderedByMapCode].revisedValue;
              } else {
                section.orderedBy = "";
              }
              let adaptationContent = {
                "informationType": "container",
                "type": { "text": "adapted element" },
                "classifier": [
                  {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112109",
                        "code": "single-codeableconcept-object",
                        "display": "Single CodeableConcept Object"
                      }
                    ]
                  }
                ],
                "path": [sectionCode + ".orderedBy"],
                "component": [
                  {
                    "summary": JSON.stringify(adaptationDictionary[sectionOrderedByMapCode].initialValue),
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "OriginalResourceContent",
                          "display": "Original Resource Content"
                        }
                      ]
                    }
                  },
                  {
                    "summary": JSON.stringify(adaptationDictionary[sectionOrderedByMapCode].revisedValue),
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "RevisedResourceContent",
                          "display": "Revised Resource Content"
                        }
                      ]
                    }
                  }
                ]
              };
              if (adaptationDictionary[sectionOrderedByMapCode].rationale) {
                adaptationContent.component.push({
                  "summary": adaptationDictionary[sectionOrderedByMapCode].rationale,
                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "RationaleForChange",
                        "display": "Rationale for Change to Resource Content"
                      }
                    ]
                  }
                });
              }
              adaptationReportJson.content.push(adaptationContent);
            }
          }
          if (adaptationDictionary[sectionEntryMapCode]) {
            if (adaptationDictionary[sectionEntryMapCode].itemChanged) {
              if (adaptationDictionary[sectionEntryMapCode].revisedValue) {
                section.entry = adaptationDictionary[sectionEntryMapCode].revisedValue;
              } else {
                section.entry = [];
              }
              let adaptationContent = {
                "informationType": "container",
                "type": { "text": "adapted element" },
                "classifier": [
                  {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112109",
                        "code": "reference-array",
                        "display": "Reference Array"
                      }
                    ]
                  }
                ],
                "path": [sectionCode + ".entry"],
                "component": [
                  {
                    "summary": JSON.stringify(adaptationDictionary[sectionEntryMapCode].initialValue),
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "OriginalResourceContent",
                          "display": "Original Resource Content"
                        }
                      ]
                    }
                  },
                  {
                    "summary": JSON.stringify(adaptationDictionary[sectionEntryMapCode].revisedValue),
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "RevisedResourceContent",
                          "display": "Revised Resource Content"
                        }
                      ]
                    }
                  }
                ]
              };
              if (adaptationDictionary[sectionEntryMapCode].rationale) {
                adaptationContent.component.push({
                  "summary": adaptationDictionary[sectionEntryMapCode].rationale,
                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "RationaleForChange",
                        "display": "Rationale for Change to Resource Content"
                      }
                    ]
                  }
                });
              }
              adaptationReportJson.content.push(adaptationContent);
            }
          }
          if (adaptationDictionary[sectionEmptyReasonMapCode]) {
            if (adaptationDictionary[sectionEmptyReasonMapCode].itemChanged) {
              if (adaptationDictionary[sectionEmptyReasonMapCode].revisedValue) {
                section.emptyReason = adaptationDictionary[sectionEmptyReasonMapCode].revisedValue;
              } else {
                section.emptyReason = "";
              }
              let adaptationContent = {
                "informationType": "container",
                "type": { "text": "adapted element" },
                "classifier": [
                  {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112109",
                        "code": "single-codeableconcept-object",
                        "display": "Single CodeableConcept Object"
                      }
                    ]
                  }
                ],
                "path": [sectionCode + ".emptyReason"],
                "component": [
                  {
                    "summary": JSON.stringify(adaptationDictionary[sectionEmptyReasonMapCode].initialValue),
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "OriginalResourceContent",
                          "display": "Original Resource Content"
                        }
                      ]
                    }
                  },
                  {
                    "summary": JSON.stringify(adaptationDictionary[sectionEmptyReasonMapCode].revisedValue),
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112108",
                          "code": "RevisedResourceContent",
                          "display": "Revised Resource Content"
                        }
                      ]
                    }
                  }
                ]
              };
              if (adaptationDictionary[sectionEmptyReasonMapCode].rationale) {
                adaptationContent.component.push({
                  "summary": adaptationDictionary[sectionEmptyReasonMapCode].rationale,
                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112108",
                        "code": "RationaleForChange",
                        "display": "Rationale for Change to Resource Content"
                      }
                    ]
                  }
                });
              }
              adaptationReportJson.content.push(adaptationContent);
            }
          }
          if (Array.isArray(section.section)) {
            for (let subsection of section.section) {
              if (subsection.code) {
                let subsectionCode;
                if (subsection.code.text) {
                  subsectionCode = "section:" + subsection.code.text;
                } else if (subsection.code.coding?.[0]) {
                  subsectionCode = "section:" + subsection.code.coding[0].system + "#" + subsection.code.coding[0].code;
                } else {
                  subsectionCode = "section:" + JSON.stringify(subsection.code);
                }
                let subsectionTitleMapCode = subsectionCode + ".title";
                let subsectionAuthorMapCode = subsectionCode + ".author";
                let subsectionOrderedByMapCode = subsectionCode + ".orderedBy";
                let subsectionEntryMapCode = subsectionCode + ".entry";
                let subsectionEmptyReasonMapCode = subsectionCode + ".emptyReason";
                if (adaptationDictionary[subsectionCode]) {
                  if (adaptationDictionary[subsectionCode].itemChanged) {
                    if (adaptationDictionary[subsectionCode].revisedValue) {
                      subsection.text = {
                        "status": "additional",
                        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + adaptationDictionary[subsectionCode].revisedValue + "</div>"
                      }
                    } else {
                      subsection.text = {
                        "status": "empty",
                        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>"
                      }
                    }
                    let adaptationContent = {
                      "informationType": "container",
                      "type": { "text": "adapted element" },
                      "classifier": [
                        {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112109",
                              "code": "string-datatype",
                              "display": "string Datatype"
                            }
                          ]
                        }
                      ],
                      "path": [subsectionCode],
                      "component": [
                        {
                          "summary": adaptationDictionary[subsectionCode].initialValue,
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "OriginalResourceContent",
                                "display": "Original Resource Content"
                              }
                            ]
                          }
                        },
                        {
                          "summary": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + adaptationDictionary[subsectionCode].revisedValue + "</div>",
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "RevisedResourceContent",
                                "display": "Revised Resource Content"
                              }
                            ]
                          }
                        }
                      ]
                    };
                    if (adaptationDictionary[subsectionCode].rationale) {
                      adaptationContent.component.push({
                        "summary": adaptationDictionary[subsectionCode].rationale,
                        "type": {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112108",
                              "code": "RationaleForChange",
                              "display": "Rationale for Change to Resource Content"
                            }
                          ]
                        }
                      });
                    }
                    adaptationReportJson.content.push(adaptationContent);
                  }
                }
                if (adaptationDictionary[subsectionTitleMapCode]) {
                  if (adaptationDictionary[subsectionTitleMapCode].itemChanged) {
                    if (adaptationDictionary[subsectionTitleMapCode].revisedValue) {
                      subsection.title = adaptationDictionary[subsectionTitleMapCode].revisedValue;
                    } else {
                      subsection.title = "";
                    }
                    let adaptationContent = {
                      "informationType": "container",
                      "type": { "text": "adapted element" },
                      "classifier": [
                        {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112109",
                              "code": "string-datatype",
                              "display": "string Datatype"
                            }
                          ]
                        }
                      ],
                      "path": [subsectionCode + ".title"],
                      "component": [
                        {
                          "summary": adaptationDictionary[subsectionTitleMapCode].initialValue,
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "OriginalResourceContent",
                                "display": "Original Resource Content"
                              }
                            ]
                          }
                        },
                        {
                          "summary": adaptationDictionary[subsectionTitleMapCode].revisedValue,
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "RevisedResourceContent",
                                "display": "Revised Resource Content"
                              }
                            ]
                          }
                        }
                      ]
                    };
                    if (adaptationDictionary[subsectionTitleMapCode].rationale) {
                      adaptationContent.component.push({
                        "summary": adaptationDictionary[subsectionTitleMapCode].rationale,
                        "type": {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112108",
                              "code": "RationaleForChange",
                              "display": "Rationale for Change to Resource Content"
                            }
                          ]
                        }
                      });
                    }
                    adaptationReportJson.content.push(adaptationContent);
                  }
                }
                if (adaptationDictionary[subsectionAuthorMapCode]) {
                  if (adaptationDictionary[subsectionAuthorMapCode].itemChanged) {
                    if (adaptationDictionary[subsectionAuthorMapCode].revisedValue) {
                      subsection.author = adaptationDictionary[subsectionAuthorMapCode].revisedValue;
                    } else {
                      subsection.author = "";
                    }
                    let adaptationContent = {
                      "informationType": "container",
                      "type": { "text": "adapted element" },
                      "classifier": [
                        {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112109",
                              "code": "reference-array",
                              "display": "Reference Array"
                            }
                          ]
                        }
                      ],
                      "path": [subsectionCode + ".author"],
                      "component": [
                        {
                          "summary": JSON.stringify(adaptationDictionary[subsectionAuthorMapCode].initialValue),
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "OriginalResourceContent",
                                "display": "Original Resource Content"
                              }
                            ]
                          }
                        },
                        {
                          "summary": JSON.stringify(adaptationDictionary[subsectionAuthorMapCode].revisedValue),
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "RevisedResourceContent",
                                "display": "Revised Resource Content"
                              }
                            ]
                          }
                        }
                      ]
                    };
                    if (adaptationDictionary[subsectionAuthorMapCode].rationale) {
                      adaptationContent.component.push({
                        "summary": adaptationDictionary[subsectionAuthorMapCode].rationale,
                        "type": {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112108",
                              "code": "RationaleForChange",
                              "display": "Rationale for Change to Resource Content"
                            }
                          ]
                        }
                      });
                    }
                    adaptationReportJson.content.push(adaptationContent);
                  }
                }
                if (adaptationDictionary[subsectionOrderedByMapCode]) {
                  if (adaptationDictionary[subsectionOrderedByMapCode].itemChanged) {
                    if (adaptationDictionary[subsectionOrderedByMapCode].revisedValue) {
                      subsection.orderedBy = adaptationDictionary[subsectionOrderedByMapCode].revisedValue;
                    } else {
                      subsection.orderedBy = "";
                    }
                    let adaptationContent = {
                      "informationType": "container",
                      "type": { "text": "adapted element" },
                      "classifier": [
                        {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112109",
                              "code": "single-codeableconcept-object",
                              "display": "Single CodeableConcept Object"
                            }
                          ]
                        }
                      ],
                      "path": [subsectionCode + ".orderedBy"],
                      "component": [
                        {
                          "summary": JSON.stringify(adaptationDictionary[subsectionOrderedByMapCode].initialValue),
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "OriginalResourceContent",
                                "display": "Original Resource Content"
                              }
                            ]
                          }
                        },
                        {
                          "summary": JSON.stringify(adaptationDictionary[subsectionOrderedByMapCode].revisedValue),
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "RevisedResourceContent",
                                "display": "Revised Resource Content"
                              }
                            ]
                          }
                        }
                      ]
                    };
                    if (adaptationDictionary[subsectionOrderedByMapCode].rationale) {
                      adaptationContent.component.push({
                        "summary": adaptationDictionary[subsectionOrderedByMapCode].rationale,
                        "type": {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112108",
                              "code": "RationaleForChange",
                              "display": "Rationale for Change to Resource Content"
                            }
                          ]
                        }
                      });
                    }
                    adaptationReportJson.content.push(adaptationContent);
                  }
                }
                if (adaptationDictionary[subsectionEntryMapCode]) {
                  if (adaptationDictionary[subsectionEntryMapCode].itemChanged) {
                    if (adaptationDictionary[subsectionEntryMapCode].revisedValue) {
                      subsection.entry = adaptationDictionary[subsectionEntryMapCode].revisedValue;
                    } else {
                      subsection.entry = "";
                    }
                    let adaptationContent = {
                      "informationType": "container",
                      "type": { "text": "adapted element" },
                      "classifier": [
                        {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112109",
                              "code": "reference-array",
                              "display": "Reference Array"
                            }
                          ]
                        }
                      ],
                      "path": [subsectionCode + ".entry"],
                      "component": [
                        {
                          "summary": JSON.stringify(adaptationDictionary[subsectionEntryMapCode].initialValue),
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "OriginalResourceContent",
                                "display": "Original Resource Content"
                              }
                            ]
                          }
                        },
                        {
                          "summary": JSON.stringify(adaptationDictionary[subsectionEntryMapCode].revisedValue),
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "RevisedResourceContent",
                                "display": "Revised Resource Content"
                              }
                            ]
                          }
                        }
                      ]
                    };
                    if (adaptationDictionary[subsectionEntryMapCode].rationale) {
                      adaptationContent.component.push({
                        "summary": adaptationDictionary[subsectionEntryMapCode].rationale,
                        "type": {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112108",
                              "code": "RationaleForChange",
                              "display": "Rationale for Change to Resource Content"
                            }
                          ]
                        }
                      });
                    }
                    adaptationReportJson.content.push(adaptationContent);
                  }
                }
                if (adaptationDictionary[subsectionEmptyReasonMapCode]) {
                  if (adaptationDictionary[subsectionEmptyReasonMapCode].itemChanged) {
                    if (adaptationDictionary[subsectionEmptyReasonMapCode].revisedValue) {
                      subsection.emptyReason = adaptationDictionary[subsectionEmptyReasonMapCode].revisedValue;
                    } else {
                      subsection.emptyReason = "";
                    }
                    let adaptationContent = {
                      "informationType": "container",
                      "type": { "text": "adapted element" },
                      "classifier": [
                        {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112109",
                              "code": "single-codeableconcept-object",
                              "display": "Single CodeableConcept Object"
                            }
                          ]
                        }
                      ],
                      "path": [subsectionCode + ".emptyReason"],
                      "component": [
                        {
                          "summary": JSON.stringify(adaptationDictionary[subsectionEmptyReasonMapCode].initialValue),
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "OriginalResourceContent",
                                "display": "Original Resource Content"
                              }
                            ]
                          }
                        },
                        {
                          "summary": JSON.stringify(adaptationDictionary[subsectionEmptyReasonMapCode].revisedValue),
                          "type": {
                            "coding": [
                              {
                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                "code": "RevisedResourceContent",
                                "display": "Revised Resource Content"
                              }
                            ]
                          }
                        }
                      ]
                    };
                    if (adaptationDictionary[subsectionEmptyReasonMapCode].rationale) {
                      adaptationContent.component.push({
                        "summary": adaptationDictionary[subsectionEmptyReasonMapCode].rationale,
                        "type": {
                          "coding": [
                            {
                              "system": "https://fevir.net/resources/CodeSystem/112108",
                              "code": "RationaleForChange",
                              "display": "Rationale for Change to Resource Content"
                            }
                          ]
                        }
                      });
                    }
                    adaptationReportJson.content.push(adaptationContent);
                  }
                }
              }
            }
          }
        }
      }
      returningdata = { "success": true, "newCompositionJson": newCompositionJson, "adaptationReportJson": adaptationReportJson };
    } catch (error) {
      alert("Failure creating new Composition. See console for error message and notify support@computablepublishing.com");
      console.log(error);
    }
    return returningdata;
  }

  const submitNewAdaptedResource = async () => {
    let resourceJson = JSON.parse(fhirEntryState.fhirEntryString);
    if (resourceJson.resourceType === "Composition") {
      let response = createAdaptedComposition(resourceJson, adaptationReportState, globalContext);
      if (response?.success) {
        let newCompositionJson = response.newCompositionJson;
        const adaptationReportJson = response.adaptationReportJson;
        let adaptationReportFOI = await submitResource(globalContext, adaptationReportJson);
        newCompositionJson.relatesTo.push({
          "type": "derived-from",
          "classifier": [{ "text": "Adaptation Report" }],
          "resourceReference": {
            "reference": "ArtifactAssessment/" + adaptationReportFOI,
            "type": "ArtifactAssessment",
            "display": adaptationReportJson.title
          }
        });
        let newCompositionFOI = await submitResource(globalContext, newCompositionJson);
        if (newCompositionFOI) {
          let newUrl = "/resources/Composition/" + newCompositionFOI;
          history.push('/');
          history.push(newUrl);
        }
      }
    }
  }

  const update = async () => {
    if (fhirEntryState.editMode) {
      setLoadingSubmit(true);
      if (globalContext.unsavedChangesInReferencedResourceState.unsavedChanges) {
        globalContext.openConfirmation({
          header: globalContext.unsavedChangesInReferencedResourceState.modalHeader || "",
          content: globalContext.unsavedChangesInReferencedResourceState.message || "",
          runFunction: globalContext.unsavedChangesInReferencedResourceState.runFunction || null
        });
      }
      await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
      setLoadingSubmit(false);
    } else {
      changeFhirEntryState(true, "editMode");
      setPageEditModeState(resourceId);
    }
  }

  const updateCodeSystemTerm = async () => {
    if (fhirEntryState.editMode) {
      setLoadingSubmit(true);
      console.log("submitting term");
      await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined);
      console.log("submitted");
      setLoadingSubmit(false);
    } else {
      changeFhirEntryState(true, "editMode");
      setPageEditModeState(resourceId);
    }

  }

  return (
    <div>
      <div style={{ display: "flex", width: "100%" }}>
        {/*{(tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Text View" && !(fhirEntryState.riskOfBiasAssessmentToolResource && fhirEntryState.editMode)) &&*/}
        {(tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Text View") &&
          <div style={{ float: "left", minWidth: "290px", marginRight: "18px" }}>
            {/* CHANGED ON MAY 8, 2024: position was changed from fixed to absolute, CHANGE IT BACK TO "fixed" if there's any issues with the left navigation*/}
            <div style={{ position: "absolute", width: "290px" }}>
              {loading &&
                <Dimmer className={"loadingDimmer"} active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>}
              <h3 style={{ fontSize: "1.1rem", marginTop: "0px", textAlign: "center" }}>
                {fhirEntryState.title ?
                  <>{fhirEntryState.title}</>
                  :
                  <>{formState.tempResourceId === resourceId ?
                    <>{formState.tempResourceTitle}</>
                    :
                    <><br /></>}</>}
              </h3>
              <NavigationSegment elements={navigationElements} resourceId={resourceId} loading={loading} />
              <div style={{ marginTop: "14px" }} />
              <div>
                <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                  <Button className="formButton communicateButton" style={{ color: "#000000", width: "100%", paddingTop: "6px", paddingBottom: "6px", margin: "0px", marginRight: "0px" }} content="Communicate" disabled />
                </div>
                {/*<div style={{width: "100%", textAlign: "center", display: "flex"}}>
                <Button className="formButton subCommunicateButton" style={{margin: "0px"}} content="TY" disabled/>
                <Button className="formButton subCommunicateButton" style={{margin: "0px"}} content="Share" disabled/>
                <Button className="formButton subCommunicateButton" style={{margin: "0px", color: "#000000"}} content="Follow" disabled />
                <Button className="formButton subCommunicateButton" style={{margin: "0px"}} content="Ask" disabled/>
              </div>*/}
                <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                  <Button className="formButton subCommunicateButtonWider" style={{ margin: "0px", color: "#000000" }}
                    content="Classify" title="Create a classification of this resource."
                    onClick={() => {
                      {
                        !userState.loading && userState.id
                          ?
                          submitNewClassification(resourceType, resourceId, fhirEntryState, history)
                          :
                          alert("Please log in to classify.")
                      }
                    }}
                    disabled={fhirEntryState.previousVersionLoaded} />
                  <Button className="formButton subCommunicateButtonWider" style={{ margin: "0px", color: "#000000" }}
                    content={resourceType === "Citation" || resourceType === "Evidence" || resourceType === "EvidenceReport" ? "RoBAT" : "Rate"}
                    title={resourceType === "Citation" || resourceType === "Evidence" || resourceType === "EvidenceReport" ? "Create a Risk of Bias Assessment" : "Rate this resource"}
                    onClick={() => {

                      if (!globalContext.userState.loading && globalContext.userState.id) {
                        if (resourceType === "Citation" || resourceType === "Evidence" || resourceType === "EvidenceReport") {
                          history.push({ "pathname": "/createriskofbiasassessment/" + resourceType + "/" + resourceId + "/", "resourceType": resourceType, "resourceId": resourceId });
                        } else {
                          submitNewRating(resourceType, resourceId, fhirEntryState, history)
                          //history.push({ "pathname": "/createcommentrating/" + resourceType + "/" + resourceId + "/" + "rating", "resourceType": resourceType, "resourceId": resourceId });
                        }
                      } else {
                        alert("Please log in to rate.")
                      }
                    }}
                    disabled={fhirEntryState.previousVersionLoaded}
                  />
                  <Button className="formButton subCommunicateButtonWider" style={{ margin: "0px", color: "#000000" }}
                    content="Comment" title="Comment on this resource."
                    onClick={() => {
                      {
                        !globalContext.userState.loading && globalContext.userState.id
                          ?
                          submitNewComment(resourceType, resourceId, fhirEntryState, history)
                          //history.push({ "pathname": "/createcommentrating/" + resourceType + "/" + resourceId + "/" + "comment", "resourceType": resourceType, "resourceId": resourceId }) 
                          :
                          alert("Please log in to comment.")
                      }
                    }}
                    disabled={fhirEntryState.previousVersionLoaded} />
                </div>
                <div style={{ marginTop: "10px" }} />
                <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                  {fhirEntryState.previousVersionLoaded ?
                    <>{/*
                    <div style={{ width: "100%", textAlign: "center", width: "100%", textAlign: "left" }}>
                      <Button className="formButton navigationButton" style={{ color: "#000000", width: "100%", margin: "0px" }}
                        content="Revert to this Version"
                        disabled={true}
                      />
                    </div>*/}
                    </>
                    :
                    <>
                               <div style={{ width: "50%", textAlign: "left", float: "left" }}>
                        {changeAvailableToSaveState === true ? <>
                          <Button style={{ color: "#FFFFFF", width: "97%", margin: "0px" }}
                            className="formButton"
                            content="Submit New (Adapted) Resource" positive
                            onClick={() => {submitNewAdaptedResource();}}
                          />
                        </> : <>
                          <Button className="formButton navigationButton" 
                          style={{ color: "#000000", width: "97%", margin: "0px" }}
                            content={fhirEntryState.editMode ? "Update" : editDisplay}
                            onClick={update}
                            disabled={(!loadingSubmit && globalContext.userState.id &&
                              fhirEntryState.readpermission && fhirEntryState.editpermission &&
                              resourceType !== "Recommendation" && !adaptationReportState.adaptOn) ? false : true}
                          />
                        </>}
                      </div>
                      <div style={{ width: "50%", float: "right", textAlign: "right" }}>
                        <Button className="formButton navigationButton" style={{ color: "#000000", width: "97%", margin: "0px" }}
                          content={"Clone " + resourceTypeDisplay}
                          onClick={() => {
                            let redirectUrl = "/create";
                            if (resourceType) {
                              redirectUrl += "/" + resourceType;
                            }
                            redirectUrl += "/" + resourceId;
                            history.push({ "pathname": redirectUrl, "resourceType": resourceType, "resourceId": resourceId });
                          }}
                          disabled={fhirEntryState.previousVersionLoaded}
                        />
                      </div>
                    </>
                  }
                </div>
                <div style={{ marginTop: "10px" }} />
                <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                  <Button className="formButton navigationButton" style={{ color: "#000000", width: "100%", margin: "0px" }}
                    content={"Adapt " + resourceTypeDisplay}
                    onClick={() => {
                      !globalContext.userState.loading && globalContext.userState.id
                        ?
                        turnAdaptOn()
                        //submitNewAdaptation(resourceType, resourceId, fhirEntryState, history)
                        :
                        alert("Please log in to adapt this resource.")
                    }}
                    disabled={fhirEntryState.previousVersionLoaded || adaptationReportState.adaptOn}
                  />
                </div>
                {/*<div style={{marginTop: "10px"}} />
              <div style={{width: "100%", textAlign: "center", display: "flex"}}>              
                <div style={{width: "100%", textAlign: "center", width: "50%", float: "left", textAlign: "left"}}>
                  <Button className="formButton navigationButton" style={{color: "#000000", width: "97%", margin: "0px"}} content={"Verify " + resourceTypeDisplay}  disabled/>
                </div>
                <div style={{width: "100%", textAlign: "center", width: "50%", float: "right", textAlign: "right"}}>
                  <Button className="formButton navigationButton" style={{color: "#000000", width: "97%", margin: "0px"}} content="Exchange Data" disabled/>
                </div>
              </div>*/}
                <div style={{ marginTop: "10px" }} />
                <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                  <div style={{ width: "50%", float: "left", textAlign: "left" }}>
                    <Button className="formButton navigationButton" style={{ color: "#000000", width: "97%", margin: "0px" }}
                      content={(resourceType === "Project" || !userState.name) ? "View Usage" : "Add to Project"}
                      onClick={() => { changeResourceViewTab(tabPanesLookup["Usage View"], "Usage View", true); editProjects(); }}
                      disabled={fhirEntryState.previousVersionLoaded}
                    />
                  </div>
                  <div style={{ width: "50%", float: "right", textAlign: "right" }}>
                    <Button className="formButton navigationButton" style={{ color: "#000000", width: "97%", margin: "0px" }} content={fhirEntryState.editMode ? "Edit JSON" : "View JSON"} onClick={() => { changeResourceViewTab(tabPanesLookup["JSON View"], "JSON View", true); }} />
                  </div>
                </div>
                {/* SUPPRESS FEVIR-TO-CT.GOV CONVERTER
                 resourceType === "ResearchStudy" &&
                  <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                    <div style={{ width: "100%", float: "left", textAlign: "left" }}>
                      <div style={{ marginTop: "10px" }} />
                      <Button className="formButton navigationButton"
                        style={{ color: "#000000", width: "100%", margin: "0px" }}
                        content="Create ClinicalTrials.gov JSON"
                        onClick={() => { FHIRtoCTgovConverter(changeFormState, history, globalContext, resourceId); }} />
                    </div>
                  </div>
            */}
                {(!fhirEntryState.editMode && fhirEntryState.status === "draft" && userState.name && fhirEntryState.readpermission && fhirEntryState.editpermission) &&
                  <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                    <div style={{ width: "100%", float: "left", textAlign: "left" }}>
                      <div style={{ marginTop: "10px" }} />
                      <Button className="formButton navigationButton" style={{ color: "#000000", width: "100%", margin: "0px" }}
                        content={"Publish"}
                        onClick={() => { changeFhirEntryState("active", "status"); submitUpdatedFhirResource("active", resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, false, undefined); }}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        }

        {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Term Detail" &&
          <><div style={{ float: "left", minWidth: "500px", marginRight: "18px" }}><div style={{ position: "fixed", width: "500px" }}>
            {loading &&
              <Dimmer className={"loadingDimmer"} active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>}
            <h3 style={{ fontSize: "1.1rem", marginTop: "0px", textAlign: "center" }}>
              {fhirEntryState.title ?
                <>{fhirEntryState.title}</>
                :
                <>{formState.tempResourceId === resourceId ?
                  <>{formState.tempResourceTitle}</>
                  :
                  <><br /></>}</>}
            </h3>
            {(robatTermLoad || (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource)) ?
              <NavigationRobatTermSegment resourceId={resourceId} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} changeFormState={changeFormState} changeFhirEntryState={changeFhirEntryState} addToast={addToast} />
              :
              <NavigationCodeSystemTermSegment resourceId={resourceId} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} />
            }
            <div style={{ marginTop: "14px" }} />
            {resourceType === "CodeSystem" ?
              <div>
                <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                  <Button className="formButton communicateButton" style={{ color: "#000000", width: "100%", paddingTop: "6px", paddingBottom: "6px", margin: "0px", marginRight: "0px" }} content="Communicate" disabled />
                </div>
                <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                  <Button className="formButton subCommunicateButton" style={{ margin: "0px", color: "#000000" }} content="Share" disabled />
                  <TermCommentButton firebase={firebase} history={history} resourceId={resourceId} fhirEntryState={fhirEntryState} setFormState={setFormState} commentEntryState={commentEntryState} setCommentEntryState={setCommentEntryState} />
                  <TermVoteButton firebase={firebase} history={history} resourceId={resourceId} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} setFormState={setFormState} commentEntryState={commentEntryState} setCommentEntryState={setCommentEntryState} />
                  {/*<Button className="formButton subCommunicateButton" style={{margin: "0px", color: "#000000"}} content="Vote" onClick={() => { {!userState.loading && userState.id ? voteOnTerm(fhirEntryState) : alert("Please log in to vote.") } } } disabled={!fhirEntryState.conceptOpenForVoting} />*/}
                  <Button className="formButton subCommunicateButton" style={{ margin: "0px", color: "#000000" }} content="Follow" disabled />
                </div>
                <div style={{ marginTop: "10px" }} />
                <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ width: "100%", float: "left", textAlign: "left" }}>
                    <Button className="formButton navigationButton" style={{ color: "#000000", width: "100%" }} content={"Create Coding JSON/XML"} disabled />
                  </div>
                </div>
                <div style={{ marginTop: "10px" }} />
                <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                  <div style={{ width: "50%", float: "left", textAlign: "left" }}>
                    <Button className="formButton navigationButton" style={{ color: "#000000", width: "97%", margin: "0px" }} content={fhirEntryState.editMode ? "Update" : "Edit CodeSystem Term"}
                      onClick={async () => { updateCodeSystemTerm();
                      }}
                      disabled={(!fhirEntryState.previousVersionLoaded && userState.name && fhirEntryState.readpermission && fhirEntryState.editpermission && resourceType !== "Recommendation") ? false : true} />
                  </div>
                  <div style={{ width: "50%", float: "right", textAlign: "right" }}>
                    <Button className="formButton navigationButton" style={{ color: "#000000", width: "97%", margin: "0px" }} content={"Clone CodeSystem Term"} disabled />
                  </div>
                </div>
              </div>
              :
              <>{(resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource) &&
                <>{fhirEntryState.editMode ?
                  <>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ float: "left" }}>
                        <RadioGroup row aria-label="status" name="status"
                          value={fhirEntryState.status} onChange={(e) => { changeFhirEntryState(e.target.value, "status"); }}>
                          <FormControlLabel
                            value="active"
                            control={<Radio color="primary" />}
                            name="radio-button-control"
                            color="default"
                            inputprops={{ 'aria-label': 'Publish' }}
                            labelPlacement="top"
                            style={{ marginLeft: '0px' }}
                            label={<p style={{ textAlign: 'center' }}>Publish<br />(anyone can view)</p>}
                          />
                          <FormControlLabel
                            value="draft"
                            control={<Radio color="primary" />}
                            color="default"
                            name="radio-button-control"
                            inputprops={{ 'aria-label': 'Draft' }}
                            labelPlacement="top"
                            label={<p style={{ textAlign: 'center' }}>Draft<br />(only editors can view)</p>}
                          />
                        </RadioGroup>
                      </div>
                      <div style={{ marginLeft: "28px" }} />
                      <div style={{ float: "right" }}>
                        <div style={{ marginTop: "20px" }} />
                        <Button className="formButton positive" content="Update" compact
                          onClick={async () => {
                            globalContext.setGlobalLoadingState(true);
                            //submitResource();
                            //setFhirEntryState(prevState  => { return { ...prevState, loading: true }; });
                            let updateResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, true, addToast);
                            if (updateResult === false) {
                              //setFhirEntryState(prevState  => { return { ...prevState, loading: false }; });
                              alert("You made no changes to this term");
                            } else {
                              changeFhirEntryState(false, "editMode");
                              setPageEditModeState("");
                              changeResourceViewTab(0, "Text View", true);
                            }
                            globalContext.setGlobalLoadingState(false);
                          }}
                        />
                      </div>
                    </div>
                    <br />
                    <b>(Auto-saves whenever you change terms or tabs)</b>
                  </>
                  :
                  <></>
                }</>
              }</>
            }
          </div>
          </div>
          </>
        }
        {/*tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Adaptation Detail" &&
          <>
            <div style={{ float: "left", minWidth: "420px", marginRight: "18px" }}>
              <div style={{ position: "fixed", width: "420px" }}>
                {loading &&
                  <Dimmer className={"loadingDimmer"} active inverted>
                    <Loader inverted>Loading</Loader>
                  </Dimmer>}
                <h3 style={{ fontSize: "1.1rem", marginTop: "0px", textAlign: "center" }}>
                  {fhirEntryState.title ?
                    <>{fhirEntryState.title}</>
                    :
                    <>{formState.tempResourceId === resourceId ?
                      <>{formState.tempResourceTitle}</>
                      :
                      <><br /></>}</>}
                </h3>
                <NavigationAdaptationItemSegment resourceType={resourceType} resourceId={resourceId} fhirEntryState={fhirEntryState}
                  setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} changeFhirEntryState={changeFhirEntryState}
                  submitUpdatedFhirResource={submitUpdatedFhirResource} changeFormState={changeFormState}
                  addToast={addToast} />
                <>
                  {resourceType === "ArtifactAssessment" &&
                    <div>
                      <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                        <div style={{ width: "100%", float: "left", textAlign: "left" }}>
                          <Button className="formButton navigationButton"
                            style={{ color: "#000000", width: "100%", margin: "0px" }}
                            content={fhirEntryState.editMode ? "Update" : "Edit Adaptation Detail"}
                            onClick={async () => {
                              if (fhirEntryState.editMode) {
                                setLoadingSubmit(true);
                                await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef,
                                  fhirEntryState, setFhirEntryState, globalContext, history, changeFormState,
                                  false, undefined);
                                setLoadingSubmit(false);
                              } else {
                                changeFhirEntryState(true, "editMode");
                                setPageEditModeState(resourceId);
                              }
                            }}
                            disabled={(!fhirEntryState.previousVersionLoaded && userState.name && fhirEntryState.readpermission && fhirEntryState.editpermission && resourceType !== "Recommendation") ? false : true}
                          />
                        </div>
                      </div>
                    </div>
                  }
                </>
              </div>
            </div>
          </>
        */}
        {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Comparison Detail" &&
          <>
            <div style={{ float: "left", minWidth: "420px", marginRight: "18px" }}>
              <div style={{ position: "fixed", width: "420px" }}>
                {loading &&
                  <Dimmer className={"loadingDimmer"} active inverted>
                    <Loader inverted>Loading</Loader>
                  </Dimmer>}
                <h3 style={{ fontSize: "1.1rem", marginTop: "0px", textAlign: "center" }}>
                  {fhirEntryState.title ?
                    <>{fhirEntryState.title}</>
                    :
                    <>{formState.tempResourceId === resourceId ?
                      <>{formState.tempResourceTitle}</>
                      :
                      <><br /></>}</>}
                </h3>
                <NavigationAdaptationItemSegment resourceType={resourceType} resourceId={resourceId} fhirEntryState={fhirEntryState}
                  setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} changeFhirEntryState={changeFhirEntryState}
                  submitUpdatedFhirResource={submitUpdatedFhirResource} changeFormState={changeFormState}
                  addToast={addToast} />
                <>
                  {resourceType === "ArtifactAssessment" &&
                    <div>
                      <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                        <div style={{ width: "100%", float: "left", textAlign: "left" }}>
                          <Button className="formButton navigationButton"
                            style={{ color: "#000000", width: "100%", margin: "0px" }}
                            content={fhirEntryState.editMode ? "Update" : "Edit Comparison Detail"}
                            onClick={async () => {
                              if (fhirEntryState.editMode) {
                                setLoadingSubmit(true);
                                await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef,
                                  fhirEntryState, setFhirEntryState, globalContext, history, changeFormState,
                                  false, undefined);

                                setLoadingSubmit(false);
                              } else {
                                changeFhirEntryState(true, "editMode");
                                setPageEditModeState(resourceId);
                              }
                            }}
                            disabled={(!fhirEntryState.previousVersionLoaded && userState.name && fhirEntryState.readpermission && fhirEntryState.editpermission && resourceType !== "Recommendation") ? false : true}
                          />
                        </div>
                      </div>
                    </div>
                  }
                </>
              </div>
            </div>
          </>
        }
        {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Characteristic Detail" &&
          <><div style={{ float: "left", minWidth: "520px", marginRight: "18px" }}>
            <div style={{ position: "fixed", width: "520px" }}>
              {loading &&
                <Dimmer className={"loadingDimmer"} active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>}
              <h3 style={{ fontSize: "1.1rem", marginTop: "0px", textAlign: "center" }}>
                {fhirEntryState.title ?
                  <>{fhirEntryState.title}</>
                  :
                  <>{formState.tempResourceId === resourceId ?
                    <>{formState.tempResourceTitle}</>
                    :
                    <><br /></>}</>}
              </h3>
              <NavigationCharacteristicSegment resourceType={resourceType} resourceId={resourceId} fhirEntryState={fhirEntryState}
                setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} changeFhirEntryState={changeFhirEntryState}
                submitUpdatedFhirResource={submitUpdatedFhirResource} changeFormState={changeFormState}
                addToast={addToast} />
              <>
                {resourceType === "EvidenceVariable" &&
                  <div>
                    <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                      <div style={{ width: "100%", float: "left", textAlign: "left" }}>
                        <Button className="formButton navigationButton" style={{ color: "#000000", width: "100%", margin: "0px" }} content={fhirEntryState.editMode ? "Update" : "Edit Criteria"}
                          onClick={async () => {
                            if (fhirEntryState.editMode) {
                              setLoadingSubmit(true);
                              await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef,
                                fhirEntryState, setFhirEntryState, globalContext, history, changeFormState,
                                false, undefined);

                              setLoadingSubmit(false);
                            } else {
                              changeFhirEntryState(true, "editMode");
                              setPageEditModeState(resourceId);
                            }
                          }}
                          disabled={(!fhirEntryState.previousVersionLoaded && userState.name && fhirEntryState.readpermission && fhirEntryState.editpermission) ? false : true}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: "10px" }} />
                    <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                      <div style={{ width: "100%", float: "left", textAlign: "left" }}>
                        <Button className="formButton navigationButton" style={{ color: "#000000", width: "100%" }} content={"Copy from Existing Criteria"} disabled />
                      </div>
                    </div>
                    <div style={{ marginTop: "10px" }} />
                    <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
                      <div style={{ width: "50%", float: "left", textAlign: "left" }}>
                        <Button className="formButton navigationButton" style={{ color: "#000000", width: "98%", margin: "0px" }} content={"Create Criteria from JSON"} disabled />
                      </div>
                      <div style={{ width: "50%", float: "right", textAlign: "right" }}>
                        <Button className="formButton navigationButton" style={{ color: "#000000", width: "98%", margin: "0px" }} content={"Delete This Criteria"} disabled />
                      </div>
                    </div>
                  </div>
                }
              </>
            </div>
          </div>
          </>
        }
        <div style={{ float: "right", width: "100%" }}>
          <Segment id="resourceTextViewSegment" name="resourceTextViewSegment" className={`containerSegment remainderOfPageSegment ${fhirEntryState.editMode && "editSegment"} ${fhirEntryState.previousVersionLoaded && "historySegment"}`} raised>
            <>
              {(fhirEntryState.loading ||
                (versionHistoryState.viewResourceButtonClicked && versionHistoryState.viewVersions)
                || (versionHistoryState.showButtonClicked && !versionHistoryState.viewVersions)
                || (viewVersionDifferencesState.showButtonClicked && !viewVersionDifferencesState.viewDifferences)) &&
                <Dimmer className={"loadingDimmer"} active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              }
            </>
            <ViewTabPane />

            {fhirEntryState.loading || (fhirEntryState.found && fhirEntryState.readpermission) ?
              <></>
              :
              <CantDisplayResource />
            }
            {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
            {(resourceType && resourceType !== "" && tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Text View") &&
              <>
                <ResourceTextDisplay resourceType={resourceType} resourceId={resourceId} getResource={getResource}
                  formInputsStateRef={formInputsStateRef}
                  setFhirEntryState={setFhirEntryState} changeFhirEntryState={changeFhirEntryState}
                  firebase={firebase} userState={userState}
                  changeFormState={changeFormState}
                  languageOptions={languageOptions}
                  setLanguageOptions={setLanguageOptions}
                  groupCharacteristicKindOptions={groupCharacteristicKindOptions} setGroupCharacteristicKindOptions={setGroupCharacteristicKindOptions}
                  fhirEntryState={fhirEntryState} history={history} key="resourceTextDisplay"
                  referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
                  adaptationReportState={adaptationReportState}
                  setAdaptationReportState={setAdaptationReportState}
                  setChangeAvailableToSaveState={setChangeAvailableToSaveState} update={update} />
              </>
            }
            {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Term Detail" &&
              <>{(resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource) ?
                <RobatTermDisplay changeFormState={changeFormState}
                  formInputsStateRef={formInputsStateRef} resourceId={resourceId} fhirEntryState={fhirEntryState}
                  setFhirEntryState={setFhirEntryState} history={history}
                  factorPresenceLookup={factorPresenceLookup} potentialInfluenceLookup={potentialInfluenceLookup}
                  biasDirectionLookup={biasDirectionLookup} biasRiskLookup={biasRiskLookup} />
                :
                <CodeSystemTermDetailTabRouting formInputsStateRef={formInputsStateRef} resourceId={resourceId}
                  fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history} update={updateCodeSystemTerm} />
              }</>
            }
            {/*tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Adaptation Detail" &&
              <>{resourceType === "ArtifactAssessment" &&
                <AdaptationDetailTabRouting formInputsStateRef={formInputsStateRef} resourceId={resourceId}
                  fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history} />
              }</>
            */}
            {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Comparison Detail" &&
              <>{resourceType === "ArtifactAssessment" &&
                <ComparisonDetailTabRouting formInputsStateRef={formInputsStateRef} resourceId={resourceId}
                  fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history} />
              }</>
            }
            {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Characteristic Detail" &&
              <>{resourceType === "EvidenceVariable" &&
                <CharacteristicDetailTabRouting formInputsStateRef={formInputsStateRef} resourceId={resourceId}
                  fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history}
                  changeFhirEntryState={changeFhirEntryState} submitUpdatedFhirResource={submitUpdatedFhirResource}
                  changeFormState={changeFormState} addToast={addToast} />
              }</>
            }
            {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "JSON View" &&
              <>
                {(fhirEntryState.found && fhirEntryState.readpermission) &&
                  <>
                    <h2 style={{ marginTop: "6px", marginBottom: "12px" }}>{fhirEntryState.title}</h2>
                    <TextArea style={{ minHeight: "400px", width: "65%" }} value={fhirEntryState.fhirEntryString || ''} onChange={(e) => { changeFhirEntryState(e.target.value, "fhirEntryString"); changeFhirEntryState(true, "fhirEntryStringChange"); }} readOnly={!fhirEntryState.editMode} />
                    <br /><br />
                    {fhirEntryState.editMode && <ResourceStatusRadioButton />}
                  </>
                }
                <ResourceButtons />
                <br /><br /><br />
              </>
            }
            {tabPanes[fhirEntryState.activeIndex]["menuItem"] === "Usage View" &&
              <>
                {(fhirEntryState.found && fhirEntryState.readpermission) &&
                  <>
                    <h2 style={{ marginTop: "6px", marginBottom: "12px" }}>{fhirEntryState.title}</h2>
                    <br />
                    <p>This resource has been accessed <b>{fhirEntryState.totalviews}</b> times by at least <b>{fhirEntryState.totalusersviewed}</b> users.</p>
                    {(fhirEntryState.totaledits > 0) && <p>This resource has been modified <b>{fhirEntryState.totaledits}</b> times by at least <b>{fhirEntryState.totalusersedited}</b> users.</p>}
                    <br />
                    {(fhirEntryState.editpermission && fhirEntryState.startingVersionId > 1) &&
                      <>
                        <div className="resourceListHeader" style={{ cursor: "default" }}><div style={{ paddingTop: "20px" }} className="resourceListHeaderText"><span>Version History</span></div></div>
                        {!versionHistoryState.viewVersions && <>
                          <Button className="formButton" style={{ color: "#000000" }} content={"Show Version History"}
                            onClick={async () => {
                              setVersionHistoryState(prevState => { return { ...prevState, "showButtonClicked": true } });
                              if (versionListState.length === 0) {
                                let versionList = await loadResourceVersionList(resourceId, globalContext);
                                setVersionListState(versionList);
                              }
                              setVersionHistoryState(prevState => { return { ...prevState, "viewVersions": true } });
                            }}
                            disabled={versionHistoryState.showButtonClicked} />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>}
                        {versionHistoryState.viewVersions &&
                          <div>
                            <select name="versionHistoryList" size="10" style={{ width: "100%" }} value={versionHistoryState.selectedVersion}
                              onChange={(e) => { setVersionHistoryState(prevState => { return { ...prevState, "selectedVersion": e.target.value } }); }}>
                              {versionListState.map((historyEntry, historyIndex) => {
                                let version = historyEntry?.version || "NULL";
                                let datecreated = historyEntry?.datecreated;
                                let username = historyEntry?.username || "Anonymous";
                                return <option value={version} key={historyIndex} disabled={(historyIndex === 0 || version == versionHistoryState.viewingVersion)}>{version}: {datecreated} by {username} </option>
                              })}
                            </select>
                            <Button className="formButton" style={{ color: "#000000" }} content={"View Version"} onClick={async () => {
                              let proceed = true;
                              if (fhirEntryState.editMode) {
                                proceed = window.confirm("Warning: You're in edit mode any unsaved data will be lost. Do you wish to continue?");
                              }
                              if (proceed) {
                                let selectedVersion = versionHistoryState.selectedVersion;
                                let historyid;
                                try {
                                  historyid = versionListState[versionListState.length - selectedVersion]["historyid"]; //Because reverse order, so for example if there are 64 versions and I selected version 62, 64 - 62 == index 2, version 63 would be index 1, and version 64 would be index 0
                                } catch { }
                                setVersionHistoryState(prevState => { return { ...prevState, "viewResourceButtonClicked": true } });
                                let historyVersionOfResourceResponse = await getHistoryVersionOfResource(resourceId, selectedVersion, historyid, globalContext);
                                let historyVersionOfResource = historyVersionOfResourceResponse["fhirjsonstring"];
                                let historyVersionOfResourceStatus = historyVersionOfResourceResponse["status"];
                                //KEEPS RECORD OF CURRENT JSON, CURRENT VERSION, AND CURRENT STATUS
                                if (historyVersionOfResourceResponse.warning) {
                                  alert(historyVersionOfResourceResponse.warning);
                                }
                                setVersionHistoryState(prevState => { return { ...prevState, 'selectedVersion': undefined, 'viewingVersion': selectedVersion, "viewHistoryVersionOfResource": true, "historyVersionOfResource": historyVersionOfResource, "historyVersionOfResourceStatus": historyVersionOfResourceStatus, "viewResourceButtonClicked": false, "currentStatus": fhirEntryState.status, "currentVersion": fhirEntryState.startingVersionId, "currentFhirEntryString": fhirEntryState.fhirEntryString } });
                                setFhirEntryState(prevState => { return { ...prevState, "previousVersionLoaded": true, "fhirEntryString": historyVersionOfResource, "status": historyVersionOfResourceStatus, "activeIndex": 0, "editMode": false } });
                              }
                            }} disabled={!versionHistoryState.selectedVersion || versionHistoryState.viewResourceButtonClicked} />
                          </div>
                        }
                        {!viewVersionDifferencesState.viewVersions &&
                          <>
                            {versionHistoryState.viewVersions && <br />}
                            {!viewVersionDifferencesState.viewDifferences && <Button className="formButton" style={{ color: "#000000" }} content={"Show Version Differences"}
                              onClick={async () => {
                                setViewVersionDifferencesState(prevState => { return { ...prevState, "showButtonClicked": true } });
                                if (versionListState.length === 0) {
                                  let versionList = await loadResourceVersionList(resourceId, globalContext);
                                  setVersionListState(versionList);
                                }
                                setViewVersionDifferencesState(prevState => { return { ...prevState, "viewDifferences": true } });
                                let currentVersion = parseInt(fhirEntryState.startingVersionId);
                                setVersionDifferencesInterfaceState({ selectedVersionLeft: currentVersion, selectedVersionRight: currentVersion - 1, justTheDifferences: true })
                              }}
                              disabled={viewVersionDifferencesState.showButtonClicked} />}
                          </>
                        }
                        {viewVersionDifferencesState.viewDifferences &&
                          <>
                            <br /><br />
                            <Dropdown
                              name="versionDifferencesOne"
                              placeholder=""
                              closeOnChange selection selectOnBlur={false} autoComplete="off"
                              style={{ width: "200px" }}
                              options={leftVersionDifferencesOptions}
                              value={versionDifferencesInterfaceState.selectedVersionLeft}
                              onChange={(e, data) => {
                                setVersionDifferencesInterfaceState(prevState => { return { ...prevState, "selectedVersionLeft": data.value } });
                              }}
                            />
                            &nbsp;&nbsp;<i>vs.</i>&nbsp;&nbsp;
                            <Dropdown
                              name="versionDifferencesTwo"
                              placeholder=""
                              closeOnChange selection selectOnBlur={false} autoComplete="off"
                              style={{ width: "200px" }}
                              options={rightVersionDifferencesOptions}
                              value={versionDifferencesInterfaceState.selectedVersionRight}
                              onChange={(e, data) => {
                                setVersionDifferencesInterfaceState(prevState => { return { ...prevState, "selectedVersionRight": data.value } });
                              }}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <YesNoRadioCheckBoxSwitch elementName={"justTheDifferences"} labelElementName={"Only show the differences?"} flatElementValue={true} togglable={false} thin={true} fontSize={"14px"} alternativeValues={[true, false]} alternativeLabels={["Just the Differences", "Full JSON"]} startingValue={versionDifferencesInterfaceState.justTheDifferences} setter={setVersionDifferencesInterfaceState} />
                            <div>
                              {loadingVersionDifferences &&
                                <Dimmer className={"loadingDimmer"} active inverted>
                                  <Loader inverted>Loading</Loader>
                                </Dimmer>}
                              <br />
                              {versionDifferencesState && <JsonVersionComparison jsonDifferences={versionDifferencesState} />}
                            </div>
                          </>
                        }
                      </>

                    }
                    {fhirEntryState.adminpermission &&
                      <>
                        <div className="resourceListHeader" style={{ cursor: "default" }}><div style={{ paddingTop: "20px" }} className="resourceListHeaderText"><span>Co-editors</span></div></div>
                        {fhirEntryState.editors?.map((editor, editorIndex) => {
                          return <h4 key={editorIndex} style={{ margin: "0px" }}>{editor.name}</h4>
                        })}
                        <br />
                        {fhirEntryState.inviteCode &&
                          <>
                            {/*<b>Invite collaborators with this link</b>: <a href={`https://fevir.net/invite/${fhirEntryState.inviteCode}`} target="_blank" rel="noopener noreferrer">https://fevir.net/invite/{fhirEntryState.inviteCode}</a>*/}
                            <div style={{ width: "100%", padding: "6px", background: "#FFFFFF", border: "1px solid #000000", whiteSpace: "pre-wrap" }}>{inviteText}</div>
                            <br />
                            <Button className="formButton" style={{ color: "#000000" }} content="Copy Invite to Clipboard" onClick={() => { navigator.clipboard.writeText(inviteText) }} />
                            <br /><br />
                            <Button className="formButton" style={{ color: "#000000" }} content="Disable Invite-to-Edit Link" onClick={() => {
                              if (window.confirm("Are you sure you want to disable this invite link to no longer add additional co-editors to this resource?")) {
                                revokeInviteLink(resourceId, fhirEntryState.inviteCode, globalContext, setFhirEntryState);
                              }
                            }}
                              disabled={fhirEntryState.previousVersionLoaded}
                            />
                            {/*BUTTON TO COPY TO CLIPBOARD*/}
                          </>
                        }
                        {fhirEntryState.inviteNotCreatedYet &&
                          <><Button className="formButton" style={{ color: "#000000" }} content="Create Invite-to-Edit Link" onClick={() => { createInviteLink(resourceId, globalContext, setFhirEntryState); }} disabled={fhirEntryState.creatingInvite || fhirEntryState.previousVersionLoaded} /></>
                        }
                      </>
                    }
                    {resourceType !== "Project" ?
                      <>
                        {fhirEntryState.projectChangeMode ?
                          <>
                            <div className="resourceListHeader" style={{ cursor: "default" }}>
                              <div style={{ paddingTop: "20px" }} className="resourceListHeaderText">
                                <span>Projects</span>
                              </div>
                            </div>
                            <Button className="formButton" style={{ color: "#000000" }} content="Apply Changes"
                              disabled={userState.id ? false : true}
                              onClick={async () => {
                                await addToProject(globalContext, resourceId, fhirEntryState.projectsChecked);
                                await loadUsageView(resourceType, resourceId, globalContext, fhirEntryState, setFhirEntryState);
                                changeFhirEntryState(false, "projectChangeMode");
                              }}
                            />
                            <br />
                            <br />
                            {fhirEntryState.projectsList?.map((project, projectIndex) => {
                              return <div key={projectIndex}>
                                {project.canedit &&
                                  <FormControlLabel
                                    disabled={!project.canedit}
                                    control={<Checkbox style={{ pointerEvents: "auto" }} name="projectCheckBox" inputProps={{ 'aria-label': project.name }} color="primary" checked={fhirEntryState.projectsChecked[project.value] || false}
                                      onChange={(e) => {
                                        let projectsChecked = fhirEntryState.projectsChecked;
                                        projectsChecked[project.value] = e.target.checked;
                                        changeFhirEntryState(projectsChecked, "projectsChecked");
                                      }} />}
                                    label={<span>{project.label}</span>}
                                  />
                                }
                              </div>
                            })}
                          </>
                          :
                          <>
                            <div className="resourceListHeader" style={{ cursor: "default" }}><div style={{ paddingTop: "20px" }} className="resourceListHeaderText"><span>This resource is used in the following projects:</span></div></div>
                            <Button className="formButton" style={{ color: "#000000" }} content="Add/Remove from Projects"
                              disabled={userState.id && fhirEntryState.status !== "retired" ? false : true}
                              onClick={editProjects}
                            />
                            <br />
                            <br />
                            {fhirEntryState.projectsContain?.map((project, projectIndex) => {
                              return <p key={projectIndex}><a href={"/resources/Project/" + project.projectresourceid} target="_blank" rel="noopener noreferrer">{project.projectname}</a></p>
                            })}
                            {resourceType === "CodeSystem" && <>
                              <div className="resourceListHeader" style={{ cursor: "default" }}><div style={{ paddingTop: "20px" }} className="resourceListHeaderText"><span>CodeSystem Development Progress</span></div></div>
                              <CodeSystemTermProgressTable termProgress={codeSystemTermProgressState.termProgress} tableHeaderStyle={{}} tableCellStyle={{}} totalText={"TOTAL"} />
                            </>}
                          </>
                        }
                      </>
                      :
                      <>
                        <div className="resourceListHeader" style={{ cursor: "default" }}>
                          <div style={{ paddingTop: "20px" }} className="resourceListHeaderText">
                            <span>Resources in Project</span>
                          </div>
                        </div>
                        {fhirEntryState.adminpermission &&
                          <>
                            <Button className="formButton" style={{ color: "#000000" }}
                              content="Search to Add Resources"
                              disabled={userState.id ? false : true}
                              onClick={() => { setSearchToAddToProjectModalOpenState({ "modalOpen": true }); }}
                            />
                            {searchToAddToProjectModalOpenState.modalOpen &&
                              <SearchResourcesToAddToProjectModal globalContext={globalContext}
                                setSelectResourceFromSearchState={setSearchToAddToProjectModalOpenState} />}
                            <br />
                            <br />
                          </>}
                        {fhirEntryState.projectResources?.map((resource, resourceIndex) => {
                          return <div key={resourceIndex}>
                            <a href={"/resources/" + resource.resourcetype + "/" + resource.id}>{resource.title}</a>
                            {/*<Link to={"/resources/"+resource.resourcetype+"/"+resource.id}>{resource.title}</Link>*/}
                          </div>
                        })}
                      </>
                    }
                  </>
                }
                <br /><br /><br />
              </>
            }
          </Segment>
        </div>
      </div>
    </div>
  );
};

export default ResourceDisplayContent;