import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import { getResource } from './UserAccountFunctions';
import { getCodeDisplayOrText, SimpleResourceFieldViewer } from './ResourceFunctions';
import { CertaintyRows } from './EvidenceFunctions';

const EvidenceReportResourceDisplay = ({ changeFormState, fhirJson, fhirEntryState }) => {

  let editMode = fhirEntryState.editMode;

  const [populationCriteriaState, setPopulationCriteriaState] = useState("");
  const [populationCriteriaURLState, setPopulationCriteriaURLState] = useState("");
  const [populationCohortState, setPopulationCohortState] = useState("");
  const [populationCohortURLState, setPopulationCohortURLState] = useState("");
  const [exposureInterventionState, setExposureInterventionState] = useState("");
  const [exposureInterventionURLState, setExposureInterventionURLState] = useState("");
  const [exposureComparatorState, setExposureComparatorState] = useState("");
  const [exposureComparatorURLState, setExposureComparatorURLState] = useState("");
  const [outcomeEvidenceVariablesState, setOutcomeEvidenceVariablesState] = useState({ resultOutcomes: [] });
  const [outcomeEvidenceReferencesState, setOutcomeEvidenceReferencesState] = useState({ references: [] });
  const [riskOfBiasArtifactCommentState, setRiskOfBiasArtifactCommentState] = useState({ "json": {}, "headers": ["Type", "Subtype", "Description", "Notes", "Commenter"] });

  const [rowHeadersState, setRowHeadersState] = useState([]);
  const [rowHeadersStructureState, setRowHeadersStructureState] = useState({});
  const [rowHeadersAndTextState, setRowHeadersAndTextState] = useState({});
  const [rowHeadersPathAndTextState, setRowHeadersPathAndTextState] = useState({});
  const [rowHeadersAndValuesState, setRowHeadersAndValuesState] = useState({});
  const [rowHeadersAndReferenceValuesState, setRowHeadersAndReferenceValuesState] = useState({});

  const [columnTableState, setColumnTableState] = useState(false);
  const [columnHeadersState, setColumnHeadersState] = useState([]);

  const [tableRowsState, setTableRowsState] = useState([]);

  useEffect(async () => {
    if (fhirEntryState.editMode) {
      //changeFormState("FEvIR®:EvidenceReport Builder", "pageTitle");
    } else {
      changeFormState("FEvIR®: EvidenceReport Resource Viewer", "pageTitle");
    }

  }, [fhirEntryState]);

  useEffect(async () => {
    setOutcomeEvidenceReferencesState({ references: [] });
    setRiskOfBiasArtifactCommentState({ "json": {}, "headers": ["Type", "Subtype", "Description", "Notes", "Commenter"] });

    let columnTable = false;
    let columnHeaders = [];
    let rowHeaders = [];
    let rowHeadersStructure = {};
    let rowHeadersAndText = {};
    let rowHeadersPathAndText = {};
    let rowHeadersAndValues = {};
    let rowHeadersAndReferenceValues = {};
    let rowHeadersAndReasons = {};
    let studyRows = [];
    let tableRows = [];

    //{"EvidenceVariable-outcome-/-.-\-Efficacy-outcomes-/-.-\-EvidenceVariable/7754" : ["EvidenceVariable-outcome", "Efficacy-outcomes", "EvidenceVariable/7754"]}
    let pathStringToPath = {}; // "EvidenceVariable-outcome-/-.-\-Efficacy-outcomes-/-.-\-EvidenceVariable/7754" to ["EvidenceVariable-outcome", "Efficacy-outcomes", "EvidenceVariable/7754"]


    for (let sectionIndex in fhirJson.section) {
      let topSection = fhirJson.section[sectionIndex];
      if (topSection.focus?.coding?.length > 0 && topSection.focus.coding[0].code === "EvidenceVariable") {
        for (let secondSectionIndex in topSection.section) {
          let secondSection = topSection.section[secondSectionIndex];
          if (secondSection.focus?.coding[0].code === "EvidenceVariable-observed") {
            for (let thirdSectionIndex in secondSection.section) {
              let thirdSection = secondSection.section[thirdSectionIndex];
              if (thirdSection.focus) {
                if (thirdSection.focus.coding[0].code === "EvidenceVariable-population") {
                  setPopulationCohortState(thirdSection.text.div);
                  if (thirdSection.entryReference?.length > 0 && thirdSection.entryReference[0].reference) {
                    setPopulationCohortURLState(thirdSection.entryReference[0].reference);
                  }
                } else if (thirdSection.focus.coding[0].code === "EvidenceVariable-exposure") {
                  if (thirdSection.entryReference.length > 0) {
                    setExposureInterventionState(thirdSection.entryReference[0].display);
                    setExposureInterventionURLState(thirdSection.entryReference[0].reference);
                  }
                  if (thirdSection.entryReference.length > 1) {
                    setExposureComparatorState(thirdSection.entryReference[1].display);
                    setExposureComparatorURLState(thirdSection.entryReference[1].reference);
                  }
                } else if (thirdSection.focus.coding[0].code === "EvidenceVariable-outcome") {
                  if (thirdSection.entryReference) {
                    for (let entryReferenceIndex in thirdSection.entryReference) {
                      let entryReference = thirdSection.entryReference[entryReferenceIndex];
                      if (entryReference.reference && entryReference.display) {
                        //entryReference.display = entryReference.display;
                        if (entryReference.reference.split("/").length === 2) {
                          entryReference.evidenceVariableURL = entryReference.reference;
                        }
                        setOutcomeEvidenceVariablesState(prevState => {
                          let prevResultOutcomes = [...prevState["resultOutcomes"]];
                          prevResultOutcomes.push(entryReference);
                          return { ...prevState, [entryReference.display]: entryReference.reference, resultOutcomes: prevResultOutcomes }
                        });
                      }
                      /*
                      if (entryReference.reference && entryReference.display) {
                        entryReference.display = entryReference.display;
                        if (entryReference.reference.split("/").length === 2) {
                          entryReference.evidenceVariableURL = entryReference.reference;
                        }
                        setOutcomeEvidenceVariablesState(prevState => {
                          return {...prevState, [entryReference.display]: entryReference.display, [entryReference.reference]: entryReference.reference}
                        });*/
                    }
                  }
                }
              }
            }
          } else if (secondSection.focus.coding[0].code === "EvidenceVariable-intended") {
            for (let thirdSectionIndex in secondSection.section) {
              let thirdSection = secondSection.section[thirdSectionIndex];
              if (thirdSection.focus.coding[0].code === "EvidenceVariable-population") {
                setPopulationCriteriaState(thirdSection.text.div);
                if (thirdSection.entryReference?.length > 0 && thirdSection.entryReference[0].reference) {
                  setPopulationCriteriaURLState(thirdSection.entryReference[0].reference);
                }
              }
            }
          }
        }
      } else if (topSection.focus?.coding?.length > 0 && topSection.focus.coding[0].code === "Evidence") {
        for (let secondSectionIndex in topSection.section) {
          let secondSection = topSection.section[secondSectionIndex];
          if (secondSection.focus?.coding?.length > 0) {
            let focusCodingCode = secondSection.focus.coding[0].code;
            if (focusCodingCode === "Intervention-vs-Control Evidence") {
              for (let entryReferenceIndex in secondSection.entryReference) {
                let entryReference = secondSection.entryReference[entryReferenceIndex];
                if (entryReference.display) {
                  ///let colonIndex = entryReference.display.indexOf(":");
                  //if (colonIndex !== -1) {
                  //entryReference.splitFirstHalf = entryReference.display.substring(0, colonIndex).trim();
                  //entryReference.splitSecondHalf = entryReference.display.substring(colonIndex+1).trim();
                  //} else {
                  //entryReference.splitSecondHalf = entryReference.display.trim();
                  //}
                  entryReference.splitSecondHalf = entryReference.display;

                  if (entryReference.reference.split("/").length === 2) {
                    entryReference.evidenceURL = entryReference.reference;
                  }
                }
                setOutcomeEvidenceReferencesState(prevState => {
                  let prevReferences = [...prevState["references"]];
                  prevReferences.push(entryReference);
                  return {
                    ...prevState,
                    references: prevReferences,
                  };
                });
              }
            }
          }
        }
      } else if (topSection.focus?.coding?.length > 0 && topSection.focus.coding[0].code === "RiskOfBias") {
        for (let entrReferenceIndex in topSection.entryReference) {
          let entryReference = topSection.entryReference[entrReferenceIndex];
          if (entryReference.reference) {
            let entryReferenceSplit = entryReference.reference.split("/");
            let resource = await getResource(entryReferenceSplit[entryReferenceSplit.length - 1]);
            let artifactCommentJson = JSON.parse(resource.fhirResource);
            setRiskOfBiasArtifactCommentState(prevState => {
              return {
                ...prevState,
                json: artifactCommentJson
              }
            });
            if (artifactCommentJson.component?.length > 0) {
              if (artifactCommentJson.component[0].informationType === "comment") {
                setRiskOfBiasArtifactCommentState(prevState => { return { ...prevState, headers: ["Type", "Subtype", "Description", "Notes", "Commenter"] }; });
              } else if (artifactCommentJson.component[0].informationType === "classifier") {
                setRiskOfBiasArtifactCommentState(prevState => { return { ...prevState, headers: ["Type of Classifier", "Classifiers", "Description", "Notes", "Who Classified",] }; });
              }
            }
            break;
          }
          //await loadReferencedResource(getResource, userState, setReferencedResourceState, setGroupCharacteristicKindOptions, referencedObservedGroupUrl, "referencedGroup", "referencedGroupString", "groupCharacteristics", "groupExclusionCharacteristics", "groupInclusionCharacteristics");
        }
      }
    }

    const sectionHeadersRetrieve = (topSectionJson, topSectionHeaderCode, path, pathString, columnFocus, rowHeaderIteration, column) => {
      if (topSectionJson.section?.length > 0) {
        for (let sectionIndex in topSectionJson.section) {
          let section = topSectionJson.section[sectionIndex];
          let sectionHeaderCode = "";
          if (section.focus?.coding?.length > 0 && section.focus.coding[0].code) {
            sectionHeaderCode = section.focus.coding[0].code;
          } else if (section.focus?.text) {
            sectionHeaderCode = section.focus.text;
          } else if (section.focusReference?.reference) {
            sectionHeaderCode = section.focusReference.reference;
          } else if (section.focusReference?.display) {
            sectionHeaderCode = section.focusReference.display;
          }
          if (sectionHeaderCode) {
            if (rowHeaderIteration) {
              let sectionHeader;
              if (section.text?.div) {
                sectionHeader = section.text.div;
              } else if (section.entryReference?.length > 0 && section.entryReference[0] && section.entryReference[0].display) {
                sectionHeader = section.entryReference[0].display;
              } else if (section.title) {
                sectionHeader = section.title;
              }
              rowHeadersAndText[sectionHeaderCode] = sectionHeader;

              let currentPath = [...path];

              let currentHeader;
              let currentStructure = rowHeadersStructure;
              if (!column) {
                for (let headerPathIndex in currentPath) {
                  currentHeader = currentPath[headerPathIndex];
                  currentStructure = currentStructure[currentHeader];
                }
                currentStructure[sectionHeaderCode] = {};
              }

              currentPath.push(sectionHeaderCode);
              let currentPathString = pathString;
              if (pathString) {
                currentPathString = pathString + "-^-.-^-";
              }
              currentPathString += sectionHeaderCode;

              pathStringToPath[currentPathString] = currentPath;
              rowHeadersPathAndText[currentPathString] = sectionHeader;
              if (column) {
                columnHeaders.push(currentPathString);
                rowHeaders.push(currentPathString);
              } else {
                rowHeaders.push(currentPathString);
              }
              sectionHeadersRetrieve(section, sectionHeaderCode, currentPath, currentPathString, "", true, column);
            } else {

              let sectionValue = [];
              let sectionReferenceValues = [];
              if (section.emptyReason) {
                //
              } else if (section.text?.div !== undefined) {
                sectionValue.push(section.text.div);
              }

              if (section.entryReference) {
                for (let entryReferenceIndex in section.entryReference) {
                  if (section.entryReference[entryReferenceIndex].display || section.entryReference[entryReferenceIndex].reference) {
                    sectionValue.push(section.entryReference[entryReferenceIndex].display);
                    if (section.entryReference[entryReferenceIndex].reference?.split("/").length === 2) {
                      sectionReferenceValues.push(section.entryReference[entryReferenceIndex].reference);
                    } else {
                      //sectionReferenceValues.push(""); //Commented out on September 14, 2021, I don't know if there's negative consequences of this being commented out
                    }
                  }
                }
              }

              let currentPathString = pathString;
              if (pathString) {
                currentPathString = pathString + "-^-.-^-";
              }
              currentPathString += sectionHeaderCode;


              let valueReasons = "";
              if (section.section) {
                for (let subSectionIndex in section.section) {
                  let subSection = section.section[subSectionIndex];
                  if (subSection.focus?.coding?.length > 0) {
                    if (subSection.focus.coding[0].code === "Reasons") {
                      if (subSection.entryReference) {
                        for (let entryReferenceIndex in subSection.entryReference) {
                          let entryReference = subSection.entryReference[entryReferenceIndex];
                          if (entryReference.display) {
                            valueReasons += entryReference.display;
                            if (entryReferenceIndex < subSection.entryReference.length - 1) {
                              valueReasons += "\n";
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              if (column) {
                if (rowHeadersStructure[columnFocus] === undefined) {
                  rowHeadersStructure[columnFocus] = {};
                }
                if (rowHeadersAndValues[columnFocus] === undefined) {
                  rowHeadersAndValues[columnFocus] = {};
                  rowHeadersAndReferenceValues[columnFocus] = {};
                  rowHeadersAndReasons[columnFocus] = {};
                }
                rowHeadersAndReasons[columnFocus][currentPathString] = valueReasons;
                rowHeadersAndValues[columnFocus][currentPathString] = sectionValue;
                if (sectionReferenceValues.length) {
                  rowHeadersAndReferenceValues[columnFocus][currentPathString] = sectionReferenceValues;
                }
              } else {
                if (rowHeadersAndValues[currentPathString] === undefined) {
                  rowHeadersAndValues[currentPathString] = {};
                  rowHeadersAndReferenceValues[currentPathString] = {};
                  rowHeadersAndReasons[currentPathString] = {};
                }
                rowHeadersAndReasons[currentPathString][columnFocus] = valueReasons;
                if (currentPathString === "Column-Header") {
                  columnHeaders.push(columnFocus);
                } else if (currentPathString === "RiskOfBias") {
                  if (sectionReferenceValues.length === 0 && sectionValue.length === 1) {
                    sectionReferenceValues = [columnFocus];
                  }
                }
                if (sectionValue.length) {
                  rowHeadersAndValues[currentPathString][columnFocus] = sectionValue;
                }
                if (sectionReferenceValues.length) {
                  rowHeadersAndReferenceValues[currentPathString][columnFocus] = sectionReferenceValues;
                }
              }

              sectionHeadersRetrieve(section, "", "", currentPathString, columnFocus, false, column);
            }
          }
        }
      }
    }

    const generateTableView = (fhirJson) => {
      columnTable = false;
      columnHeaders = [];
      rowHeaders = [];
      rowHeadersAndText = {};
      rowHeadersPathAndText = {}
      rowHeadersAndValues = {};
      studyRows = [];
      tableRows = [];
      for (let sectionIndex in fhirJson.section) {
        let section = fhirJson.section[sectionIndex];
        let topSection = fhirJson.section[sectionIndex];
        if (topSection.focus?.coding?.length > 0) {
          let topSectionHeaderCode = topSection.focus.coding[0].code;
          if (topSectionHeaderCode === "Row-Headers") {
            //sectionHeaderRetrieve(topSection, topSectionHeaderCode);
            //sectionHeaderRetrieve(topSection, "");
            sectionHeadersRetrieve(topSection, "", [], "", "", true, false);
          } else if (topSectionHeaderCode === "Column-Headers") {
            setColumnTableState(true);
            columnTable = true;
            sectionHeadersRetrieve(topSection, "", [], "", "", true, true);
          } else {
            //
            setColumnTableState(false);
          }
        } else if (section.focusReference?.type === "EvidenceReport") {

          sectionHeadersRetrieve(topSection, "", "", "", section.focusReference.reference, false, false);
          //let code = section.focus.coding[0].code;
          //console.log(code);
        } else if (section.focusReference?.type === "EvidenceVariable") {
          let focusReferenceReference = section.focusReference.reference;
          studyRows.push(focusReferenceReference);
          sectionHeadersRetrieve(topSection, "", "", "", focusReferenceReference, false, true);
          //let code = section.focus.coding[0].code;
          //console.log(code);
        }
      }
      //<Table.Row><Table.Cell>Test 1</Table.Cell><Table.Cell>Test 2</Table.Cell></Table.Row>, <Table.Row><Table.Cell>Test 3</Table.Cell><Table.Cell>Test 4</Table.Cell></Table.Row>
      const addRowsToTable = (headersStructure, parentPath, depth) => {
        for (let headerIndex in Object.keys(headersStructure)) {
          let header = Object.keys(headersStructure)[headerIndex];
          let headerPath = parentPath;
          if (parentPath) {
            headerPath += "-^-.-^-";
          }
          headerPath += header;
          let headerText = rowHeadersAndText[header];
          if (header !== "Column-Header") {
            let row;
            if (columnTable) {
              row = <Table.Row key={header}>
                {columnHeaders.map((column, columnIndex) => {
                  return <Table.Cell key={columnIndex + 1} title={rowHeadersAndReasons[headerPath][column]} style={{ verticalAlign: "top" }}>
                    {(rowHeadersAndValues[headerPath] && rowHeadersAndValues[headerPath][column]) && <div style={{ whiteSpace: "pre-wrap" }}>{rowHeadersAndValues[headerPath][column][0]}</div>}
                    {(rowHeadersAndReferenceValues[headerPath] && rowHeadersAndReferenceValues[headerPath][column]) &&
                      <> (
                        {rowHeadersAndReferenceValues[headerPath][column].map((entryReference, entryReferenceIndex) => {
                          return <span key={entryReferenceIndex}><a href={"/resources/" + entryReference} target="_blank" rel="noopener noreferrer">
                            {entryReference}
                          </a>{entryReferenceIndex < rowHeadersAndReferenceValues[headerPath][column].length - 1 && <>,&nbsp;</>}</ span>
                        })}
                        )</>
                    }
                  </Table.Cell>
                })}
              </Table.Row>
            } else {
              row = <Table.Row key={header}>
                <Table.Cell key="0"><span style={{ paddingLeft: 20 * depth + "px", display: "inline-block" }}>
                  {(Object.keys(headersStructure[header]).length > 0 && parentPath) ?
                    <>{depth % 2 == 1 ?
                      <b><i>{headerText}</i></b>
                      :
                      <b>{headerText}</b>
                    }</>
                    :
                    <>{parentPath ? <>{headerText}</> : <b>{headerText}</b>}</>
                  }
                </span></Table.Cell>
                {columnHeaders.map((column, columnIndex) => {
                  try {
                    return <Table.Cell key={columnIndex + 1} title={rowHeadersAndReasons[headerPath][column]} style={{ verticalAlign: "top" }}>
                      {(rowHeadersAndValues[headerPath] && rowHeadersAndValues[headerPath][column]) && rowHeadersAndValues[headerPath][column][0]}
                      {(rowHeadersAndReferenceValues[headerPath] && rowHeadersAndReferenceValues[headerPath][column]) &&
                        <> (
                          {rowHeadersAndReferenceValues[headerPath][column].map((entryReference, entryReferenceIndex) => {
                            try {
                              return <span key={entryReferenceIndex}><a href={"/resources/" + entryReference} target="_blank" rel="noopener noreferrer">
                                {entryReference}
                              </a>{entryReferenceIndex < rowHeadersAndReferenceValues[headerPath][column].length - 1 && <>,&nbsp;</>}
                              </ span>
                            } catch (e) {
                              return <span key={entryReferenceIndex}></span>
                            }
                          })}
                          )</>
                      }
                    </Table.Cell>
                  } catch (e) {
                    return <Table.Cell key={columnIndex + 1} style={{ verticalAlign: "top", paddingBottom: "0px" }}><i style={{ fontSize: "12px" }}>ERROR</i></Table.Cell>
                  }
                })}
              </Table.Row>
            }

            tableRows.push(row);
            if (Object.keys(headersStructure[header]).length > 0) {
              addRowsToTable(headersStructure[header], headerPath, depth + 1);
            }
          }

        }
      }
      addRowsToTable(rowHeadersStructure, "", 0);

    }

    generateTableView(fhirJson);
    setColumnHeadersState(columnHeaders);
    setRowHeadersState(rowHeaders);
    setRowHeadersStructureState(rowHeadersStructure);
    setRowHeadersAndTextState(rowHeadersAndText);
    setRowHeadersPathAndTextState(rowHeadersPathAndText);
    setRowHeadersAndValuesState(rowHeadersAndValues);
    setRowHeadersAndReferenceValuesState(rowHeadersAndReferenceValues);
    setTableRowsState(tableRows);

  }, [])

  let superScriptCount = 0;
  let superScriptCountTwo = 0;
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        {editMode ?
          <></>
          :
          <p><b>Title: </b>{fhirJson.title}</p>
        }
        {fhirJson.description && <p><b>Description: </b><span style={{ whiteSpace: "pre-wrap" }}>{fhirJson.description}</span></p>}
        {fhirJson.citeAsReference?.display && <p><b>Cite As: </b>{fhirJson.citeAsReference.display}
          {fhirJson.citeAsReference.reference && <> (<a href={"/resources/" + fhirJson.citeAsReference.reference} target="_blank" rel="noopener noreferrer">{fhirJson.citeAsReference.reference}</a>)</>}
        </p>}
        {fhirJson.subject?.characteristic?.length > 0 && <><p><b>Subject of Evidence Report: </b></p>
          <Table>
            {/*<Table.Header><Table.Row><Table.HeaderCell>Code</Table.HeaderCell><Table.HeaderCell>Display</Table.HeaderCell></Table.Row></Table.Header>*/}
            <Table.Body>
              {fhirJson.subject.characteristic.map((characteristic, characteristicIndex) => {
                let codeDisplay = getCodeDisplayOrText(characteristic.code);
                let valueDisplay = "";

                if (characteristic.valueReference?.display) {
                  valueDisplay = characteristic.valueReference.display;
                } else if (characteristic.valueBoolean !== undefined) {
                  valueDisplay = characteristic.valueBoolean.toString();
                } else if (characteristic.valueQuantity?.value !== undefined) {
                  valueDisplay = characteristic.valueQuantity.comparator + " " + characteristic.valueQuantity.value + " " + characteristic.valueQuantity.unit;
                } else if (characteristic.valueRange && (characteristic.valueRange.low?.value || characteristic.valueRange.high?.value)) {
                  if (characteristic.valueRange.low.value) {
                    valueDisplay = characteristic.valueRange.low.value + " " + characteristic.valueRange.low.unit;
                  }
                  if (characteristic.valueRange.high.value) {
                    if (valueDisplay) {
                      valueDisplay += " to ";
                    }
                    valueDisplay += characteristic.valueRange.high.value + " " + characteristic.valueRange.high.unit;
                  }
                } else if (characteristic.valueCodeableConcept) {
                  valueDisplay = getCodeDisplayOrText(characteristic.valueCodeableConcept);
                }
                return <Table.Row key={characteristicIndex}>
                  <Table.Cell>{codeDisplay}</Table.Cell>
                  <Table.Cell>{valueDisplay}</Table.Cell>
                </Table.Row>
              })}
            </Table.Body>
          </Table>
        </>}
      </div>
      <h3 id="table-view">Table View</h3>
      <div style={{ marginLeft: "24px" }}>
        {(!columnTableState && Object.keys(rowHeadersStructureState).length) ?
          <>
            <Table>
              <Table.Header><Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                {columnHeadersState.map((column, columnIndex) => {
                  return <Table.HeaderCell key={columnIndex}>
                    {rowHeadersAndValuesState["Column-Header"] &&
                      <>
                        {column.split("/").length === 2 ?
                          <a href={"/resources/" + column} target="blank">{rowHeadersAndValuesState["Column-Header"][column]} </a>
                          :
                          <>{column.split("/").length > 2 ?
                            <>
                              {/*<a href={column} target="blank">{rowHeadersAndValuesState["Column-Header"][column]} </a>*/} {/* COMMENTED OUT ON September 14, 2021 because we might use this code in the future */}
                              {rowHeadersAndValuesState["Column-Header"][column]}
                            </>
                            :
                            <>{rowHeadersAndValuesState["Column-Header"][column]}</>
                          }</>
                        }

                        {(rowHeadersAndReferenceValuesState["References"] && rowHeadersAndReferenceValuesState["References"][column]) && rowHeadersAndReferenceValuesState["References"][column].map((reference, referenceIndex) => {
                          superScriptCount++;
                          let hoverText = rowHeadersAndValuesState["References"][column][referenceIndex];
                          if (rowHeadersAndReferenceValuesState["References"][column][referenceIndex] && rowHeadersAndReferenceValuesState["References"][column][referenceIndex].split("/").length === 2) {
                            hoverText = rowHeadersAndReferenceValuesState["References"][column][referenceIndex] + ": " + hoverText;
                            return <sup key={referenceIndex} title={hoverText}> <a href={"/resources/" + rowHeadersAndReferenceValuesState["References"][column][referenceIndex]} target="_blank" rel="noopener noreferrer"> {superScriptCount}</a>{referenceIndex < rowHeadersAndReferenceValuesState["References"][column].length - 1 && <>,</>}</sup>
                          } else {
                            return <sup key={referenceIndex} title={hoverText}> {superScriptCount}{referenceIndex < rowHeadersAndReferenceValuesState["References"][column].length - 1 && <>,</>}</sup>
                          }

                        })}
                      </>
                    }
                  </Table.HeaderCell>
                })}
              </Table.Row></Table.Header>
              <Table.Body>
                {tableRowsState.map((row) => {
                  return row
                })}
              </Table.Body>
            </Table>
          </>
          :
          <>{columnTableState ?
            <Table>
              <Table.Header><Table.Row>
                {rowHeadersState.map((column, columnIndex) => {
                  return <Table.HeaderCell key={columnIndex}>
                    {rowHeadersAndTextState[column] && <>{rowHeadersAndTextState[column]}</>}
                  </Table.HeaderCell>
                })}
              </Table.Row></Table.Header>
              <Table.Body>
                {tableRowsState.map((row) => {
                  return row
                })}
              </Table.Body>
            </Table>
            :
            <>Not applicable</>
          }</>
        }
        {(Object.keys(rowHeadersStructureState).length > 0 && rowHeadersAndValuesState["References"] !== undefined && !rowHeadersState.includes("References")) &&
          <>
            <h4>References:</h4>
            <div style={{ marginLeft: "24px" }}>
              {columnHeadersState.map((column, columnIndex) => {
                return <div key={columnIndex}>
                  {(rowHeadersAndValuesState["References"][column] && rowHeadersAndValuesState["References"][column].length) &&
                    <div>{rowHeadersAndValuesState["References"][column].map((entryReference, entryReferenceIndex) => {
                      superScriptCountTwo++;
                      return <p key={entryReferenceIndex}>
                        <b>{superScriptCountTwo}</b>. {entryReference}
                        {(rowHeadersAndReferenceValuesState["References"] && rowHeadersAndReferenceValuesState["References"][column] && rowHeadersAndReferenceValuesState["References"][column].length !== 0 && rowHeadersAndReferenceValuesState["References"][column][entryReferenceIndex]) && <> (<a href={"/resources/" + rowHeadersAndReferenceValuesState["References"][column][entryReferenceIndex]} target="_blank" rel="noopener noreferrer">{rowHeadersAndReferenceValuesState["References"][column][entryReferenceIndex]}</a>)</>}
                        <br />
                      </p>
                    })}
                    </div>
                  }
                  <br />
                </div>
              }
              )}
            </div>
          </>
        }
      </div>
      <h3 id="populations">Populations</h3>
      <div style={{ marginLeft: "24px" }}>
        {populationCriteriaState && <p><b>Intended population: </b>{populationCriteriaState} {populationCriteriaURLState && <>(<a href={"/resources/" + populationCriteriaURLState} target="_blank" rel="noopener noreferrer">{populationCriteriaURLState}</a>)</>} </p>}
        {populationCohortState && <p><b>Observed cohort: </b>{populationCohortState} {populationCohortURLState && <>(<a href={"/resources/" + populationCohortURLState} target="_blank" rel="noopener noreferrer">{populationCohortURLState}</a>)</>}</p>}
        {(!populationCriteriaState && !populationCohortState) && <>Not applicable</>}
      </div>
      <h3 id="exposures">Exposures</h3>
      <div style={{ marginLeft: "24px" }}>
        {(exposureInterventionState || exposureInterventionURLState) && <b>Intervention: </b>}{exposureInterventionState && <>{exposureInterventionState}</>} {exposureInterventionURLState && <>(<a href={"/resources/" + exposureInterventionURLState} target="_blank" rel="noopener noreferrer">{exposureInterventionURLState}</a>)<br /></>}
        {(exposureComparatorState || exposureComparatorURLState) && <b>Comparator: </b>}{exposureComparatorState && <>{exposureComparatorState}</>} {exposureComparatorURLState && <>(<a href={"/resources/" + exposureComparatorURLState} target="_blank" rel="noopener noreferrer">{exposureComparatorURLState}</a>)</>}
        {(!exposureInterventionState && !exposureInterventionURLState && !exposureComparatorState && !exposureComparatorURLState) && <>Not applicable</>}
      </div>
      <h3 id="results">Results</h3>
      <div style={{ marginLeft: "24px" }}>
        {outcomeEvidenceReferencesState.references?.length > 0 ?
          <Table>
            <Table.Header><Table.Row><Table.HeaderCell>Outcome</Table.HeaderCell><Table.HeaderCell>Result</Table.HeaderCell></Table.Row></Table.Header>
            <Table.Body>
              {outcomeEvidenceReferencesState.references.map((evidenceReference, evidenceReferenceIndex) => {
                let evidenceVariableReference;
                if (evidenceReferenceIndex < outcomeEvidenceVariablesState["resultOutcomes"].length) {
                  evidenceVariableReference = outcomeEvidenceVariablesState["resultOutcomes"][evidenceReferenceIndex];
                }
                return <Table.Row key={evidenceReferenceIndex}>
                  <Table.Cell style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>{evidenceVariableReference.display} {evidenceVariableReference.evidenceVariableURL && <>(<a href={"/resources/" + evidenceVariableReference.evidenceVariableURL} target="_blank" rel="noopener noreferrer">{evidenceVariableReference.evidenceVariableURL}</a>)</>}</Table.Cell>
                  <Table.Cell style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>{evidenceReference.splitSecondHalf} {evidenceReference.evidenceURL && <>(<a href={"/resources/" + evidenceReference.evidenceURL} target="_blank" rel="noopener noreferrer">{evidenceReference.evidenceURL}</a>)</>}</Table.Cell>
                </Table.Row>
              })}
            </Table.Body>
          </Table>
          :
          <>Not applicable</>
        }
      </div>
      <h3 id="risk-of-bias">Risk of Bias</h3>
      <div style={{ marginLeft: "24px" }}>
        {riskOfBiasArtifactCommentState.json.component?.length > 0 ?
          <Table style={{ margin: "4px" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ padding: "6px" }}>{riskOfBiasArtifactCommentState.headers[0]}</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px" }}>{riskOfBiasArtifactCommentState.headers[1]}</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px", width: "25%" }}>{riskOfBiasArtifactCommentState.headers[2]}</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px", width: "25%" }}>{riskOfBiasArtifactCommentState.headers[3]}</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px" }}>{riskOfBiasArtifactCommentState.headers[4]}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <CertaintyRows certaintyEntries={riskOfBiasArtifactCommentState.json.component} level={0} editMode={editMode} state={undefined} stateSetter={undefined} />
            </Table.Body>
          </Table>
          :
          <>Not applicable</>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
    {/*<SimpleResourceFieldViewer resource={JSON.parse(fhirEntryState.fhirEntryString)} parentElement={""} />*/}
  </div>
}

export default EvidenceReportResourceDisplay;