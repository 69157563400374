import React, { useState, useImperativeHandle } from 'react';
import { DataEntry, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import { SimpleResourceFieldViewer } from './ResourceFunctions';

const CodeSystemEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson, classificationsArrayState, classificationsLoadedState }) => {
/*
  const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "status", "experimental",
    "date", "publisher", "contact", "description", "useContext", "jurisdiction", "purpose", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "caseSensitive", "valueSet", "hierarchyMeaning", "compositional", "versionNeeded", "content", "supplements", "count", "filter",
    "property", "concept"]
*/
  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "resourceType": "CodeSystem", "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "versionAlgorithmString": fhirJson.versionAlgorithmString, "versionAlgorithmCoding": fhirJson.versionAlgorithmCoding,
    "name": fhirJson.name, "title": fhirJson.title, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "caseSensitive": fhirJson.caseSensitive, "valueSet": fhirJson.valueSet, "hierarchyMeaning": fhirJson.hierarchyMeaning,
    "compositional": fhirJson.compositional, "versionNeeded": fhirJson.versionNeeded, "content": fhirJson.content,
    "supplements": fhirJson.supplements, "count": fhirJson.count, "filter": fhirJson.filter,
    "property": fhirJson.property, "concept": fhirJson.concept, "newClassifications": null
  });

  useImperativeHandle(formInputsStateRef, () => ({
    codeSystemState: resourceState
  }), [resourceState]);

  return <div style={{ marginTop: "12px" }}>
    <h3 id="description">Description</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='title' fieldLabel='Title'
        startingValue={resourceState.title} setResourceState={setResourceState} />
      <DataEntry datatype='string' elementName='name' fieldLabel='Name'
        startingValue={resourceState.name} setResourceState={setResourceState} />
      <DataEntry datatype='string' elementName='version' fieldLabel='Version'
        startingValue={resourceState.version} setResourceState={setResourceState} />
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={resourceState.description} setResourceState={setResourceState} />
      <DataEntry datatype='uri' elementName='valueSet' fieldLabel='Value set with entire code system'
        startingValue={resourceState.valueSet} setResourceState={setResourceState} />
    </div>
    <h3 id="properties">Properties</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='boolean' elementName='caseSensitive' fieldLabel='Case sensitive'
        storeFalse={true}
        startingValue={resourceState.caseSensitive} setResourceState={setResourceState} />
      <DataEntry datatype='code' elementName='hierarchyMeaning' fieldLabel='Hierarchy meaning'
        dataEntryStyle="dropdown" allowedValues={["grouped-by", "is-a", "part-of", "classified-with"]}
        startingValue={resourceState.hierarchyMeaning} setResourceState={setResourceState} />
      <DataEntry datatype='boolean' elementName='compositional' fieldLabel='Compositional'
        storeFalse={true}
        startingValue={resourceState.compositional} setResourceState={setResourceState} />
      <DataEntry datatype='boolean' elementName='versionNeeded' fieldLabel='Version needed'
        storeFalse={true}
        startingValue={resourceState.versionNeeded} setResourceState={setResourceState} />
      <DataEntry datatype='code' elementName='content' fieldLabel='Content'
        dataEntryStyle="dropdown" allowedValues={["not-present", "example", "fragment", "complete", "supplement"]}
        startingValue={resourceState.content} setResourceState={setResourceState} />
      <DataEntry asArray={true} datatype='CodeSystemProperty' elementName='property' fieldLabel='Property'
        startingValue={resourceState.property} setResourceState={setResourceState} />
    </div>
    <h3 id="concepts">Concepts</h3>
    <div style={{ marginLeft: "24px" }}>
      <SimpleResourceFieldViewer resource={fhirJson.concept} parentElement={""} />
    </div>
    <h3 id="how-to-cite">How to Cite</h3>
    <div style={{ marginLeft: "24px" }}>
      <DisplayHowToCite citationSummary={citationSummary}
        citationJson={citationJson} />
    </div>
    <h3 id="metadata">Metadata</h3>
    <div style={{ marginLeft: "24px" }}>
      <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
    </div>
    <h3 id="classifiers">Classifiers</h3>
    <div style={{ marginLeft: "24px" }}>
      <div>
        <p>Add Classifiers:</p>
        <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
          fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
      </div>
      {(classificationsArrayState) && <div>
        <p>Existing Classifiers:</p>
        {classificationsLoadedState ?
          <DisplayClassifiers classificationsArray={classificationsArrayState} />
          :
          <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
        }
      </div>}
    </div>
    <h3 id="json-outline">JSON Outline</h3>
    <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
    <br /><br />
  </div>
}

export default CodeSystemEdit;
