import React, { useState, useEffect, useContext } from 'react';
import submitToFevirServer from './SubmitToFevirServer';
import { SimpleResourceFieldViewer, submitUpdatedFhirResource } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';
import ActivityDefinitionDisplay from './ActivityDefinitionDisplay';
import ActivityDefinitionEdit from './ActivityDefinitionEdit';
import AdaptationDisplay from './AdaptationDisplay';
import AdaptationEdit from './AdaptationEdit';
import BundleDisplay from './BundleDisplay';
import BundleEdit from './BundleEdit';
import ComparisonDisplay from './ComparisonDisplay';
import { ComparisonEdit } from './ComparisonEdit';
import ClassificationDisplay from './ClassificationDisplay';
import ClassificationEdit from './ClassificationEdit';
import RatingDisplay from './RatingDisplay';
import RatingEdit from './RatingEdit';
import CommentDisplay from './CommentDisplay';
import CommentEdit from './CommentEdit';
import { CharacteristicResourceDisplay } from './CharacteristicResourceDisplay';
import CharacteristicResourceEdit from './CharacteristicResourceEdit';
import CodeSystemDisplay from './CodeSystemDisplay';
import CodeSystemEdit from './CodeSystemEdit';
import ComparativeEvidenceReportAuthoringTool from './ComparativeEvidenceReportAuthoringTool';
import ComparativeEvidenceReportViewerTool from './ComparativeEvidenceReportViewingTool';
import CompositionDisplay from './CompositionDisplay';
import CompositionEdit from './CompositionEdit';
import CompositionMeasureReportAuthoringTool from './CompositionMeasureReportAuthoringTool';
import CompositionMeasureReportViewerTool from './CompositionMeasureReportViewingTool';
import EvidenceDisplay from './EvidenceDisplay';
import EvidenceEdit from './EvidenceEdit';
import EvidenceVariableDisplay from './EvidenceVariableDisplay';
import EvidenceVariableEdit from './EvidenceVariableEdit';
import { EvidenceReportPackageAuthoringTool } from './EvidenceReportPackageAuthoringTool';
import { EvidenceReportPackageViewerTool } from './EvidenceReportPackageViewingTool';
import GroupDisplay from './GroupDisplay';
import GroupEdit from './GroupEdit';
import GuidelineAuthoringTool from './GuidelineAuthoringTool';
import GuidelineViewerTool from './GuidelineViewingTool';
import LibraryDisplay from './LibraryDisplay';
import LibraryEdit from './LibraryEdit';
import ListDisplay from './ListDisplay';
import ListEdit from './ListEdit';
import M11ReportAuthoringTool from './M11ReportAuthoringTool';
import M11ReportViewerTool from './M11ReportViewingTool';
import MeasureDisplay from './MeasureDisplay';
import MeasureEdit from './MeasureEdit';
import PlanDefinitionDisplay from './PlanDefinitionDisplay';
import PlanDefinitionEdit from './PlanDefinitionEdit';
import ProjectDisplay from './ProjectDisplay';
import ProjectEdit from './ProjectEdit';
import { RecommendationJustificationEdit } from './RecommendationJustificationEdit';
import RecommendationJustificationDisplay from './RecommendationJustificationDisplay';
import { RecommendationAuthoringTool } from './RecommendationAuthoringTool';
import RecommendationViewerTool from './RecommendationViewingTool';
import ResearchStudyDisplay from './ResearchStudyDisplay';
import ResearchStudyEdit from './ResearchStudyEdit';
import SoftwareScriptDisplay from './SoftwareScriptDisplay';
import SoftwareScriptEdit from './SoftwareScriptEdit';
import { SummaryOfFindingsAuthoringTool } from './SummaryOfFindingsAuthoringTool';
import SummaryOfFindingsViewerTool from './SummaryOfFindingsViewingTool';
import ValueSetDisplay from './ValueSetDisplay';
import ValueSetEdit from './ValueSetEdit';
import FevirContext from './FevirContext';

const ResourceBuilderViewer = ({ changeFormState, formInputsStateRef, fhirJson, fhirEntryState, setFhirEntryState,
  changeFhirEntryState, history, referencedResourceState, setReferencedResourceState, 
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState, update }) => {
  let editMode = fhirEntryState.editMode;
  const globalContext = useContext(FevirContext);

  const [classificationsArrayState, setClassificationsArrayState] = useState([]);
  const [classificationsLoadedState, setClassificationsLoadedState] = useState(false);

  useEffect(() => {
    let resourceTypeName = fhirJson.resourceType || "Resource";
    let profileName;
    if (fhirJson.meta?.profile?.length) {
      if (fhirJson.meta.profile[0].includes('http://hl7.org/fhir/uv/ebm/StructureDefinition/')) {
        let forProfileName = fhirJson.meta.profile[0].replace('http://hl7.org/fhir/uv/ebm/StructureDefinition/', '');
        let profileNameSplit = forProfileName.split('-');
        profileName = profileNameSplit.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1) }).join('');
      } else if (fhirJson.meta.profile[0].includes('https://hl7.org/fhir/uv/ebm/StructureDefinition/')) {
        let forProfileName = fhirJson.meta.profile[0].replace('https://hl7.org/fhir/uv/ebm/StructureDefinition/', '');
        let profileNameSplit = forProfileName.split('-');
        profileName = profileNameSplit.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1) }).join('');
      } else if (fhirJson.meta.profile[0].includes('https://fevir.net/Profile/')) {
        profileName = fhirJson.meta.profile[0].replace('https://fevir.net/Profile/', '');
      }
    }
    let toolDisplayName = profileName || resourceTypeName;
    if (toolDisplayName === "Recommendation" || toolDisplayName === "Guideline" ||
      toolDisplayName === "SummaryOfFindings" || toolDisplayName === "ParticipantFlowReport" ||
      toolDisplayName === "ComparativeEvidenceReport" || toolDisplayName === "BaselineMeasureReport" ||
      toolDisplayName === "ParticipantFlowReport" || toolDisplayName === "OutcomeMeasureReport" ||
      toolDisplayName === "EvidenceReportPackage" || toolDisplayName === "SystematicReview") {
      toolDisplayName = "Computable Publishing®: " + toolDisplayName;
      if (fhirEntryState.editMode) {
        toolDisplayName = toolDisplayName + " Authoring Tool";
      } else {
        toolDisplayName = toolDisplayName + " Viewing Tool";
      }
    } else {
      toolDisplayName = "FEvIR®: " + toolDisplayName;
      if (fhirEntryState.editMode) {
        toolDisplayName = toolDisplayName + " Builder";
      } else {
        toolDisplayName = toolDisplayName + " Viewer";
      }
    }
    changeFormState(toolDisplayName, "pageTitle");
  }, [fhirEntryState.editMode]);

  //warning about race condition with async at top of useEffect but this is the pattern that works

  const loadResourceClassifiers = async () => {
    let classificationsArray;
    let response = { "success": false };
    try {
      response = await getResourceClassifiers(fhirJson.id);
      classificationsArray = response.classificationsArray;
      setClassificationsArrayState(classificationsArray);
      setClassificationsLoadedState(true);
    } catch {
      console.log('Some error has occurred in getResourceClassifiers');
    }
  };

  useEffect(() => {
    loadResourceClassifiers();
  }, [fhirJson.id]);

  const getResourceClassifiers = async (targetFoi) => {
    let returningdata = { "success": false, "classificationsArray": [] }
    const body = {
      'functionid': "getresourceclassifiers",
      'targetfoi': parseInt(targetFoi),
      'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 120000, body, true, false);
    if (!response || !response.success) {
      alert("Problem getting the resource Classifications.");
      return returningdata;
    }
    /*
    if (Array.isArray(response.resourceClassifiers)) {
      let resourceClassifiers = response.resourceClassifiers;
      let classificationIndex = {};
      let classificationsArray = [];
      if (resourceClassifiers.length > 0) {
        for (const classifier of resourceClassifiers) {
          let typeJson = null;
          if ((classifier.typecode || classifier.typedisplay) && classifier.typetext) {
            typeJson = { "coding": [{ "code": classifier.typecode || "", "display": classifier.typedisplay || "" }], "text": classifier.typetext };
          } else if (classifier.typecode || classifier.typedisplay) {
            typeJson = { "coding": [{ "code": classifier.typecode || "", "display": classifier.typedisplay || "" }] };
          } else if (classifier.typetext) {
            typeJson = { "text": classifier.typetext }
          } else {
            typeJson = { "text": "[Untyped]" };
          }
          let typeString = JSON.stringify(typeJson);
          let classifierJson = null;
          if ((classifier.classifiercode || classifier.classifierdisplay) && classifier.classifiertext) {
            classifierJson = { "coding": [{ "code": classifier.classifiercode || "", "display": classifier.classifierdisplay || "" }], "text": classifier.classifiertext };
          } else if (classifier.classifiercode || classifier.classifierdisplay) {
            classifierJson = { "coding": [{ "code": classifier.classifiercode || "", "display": classifier.classifierdisplay || "" }] };
          } else if (classifier.classifiertext) {
            classifierJson = { "text": classifier.classifiertext }
          }
          let authorDisplay;
          if (classifier.authordisplay) {
            authorDisplay = classifier.authordisplay;
          }
          let freetoshare = classifier.freetoshare ?? true;
          if (classificationIndex[typeString]) {
            classificationIndex[typeString].classifier.push(classifierJson);
          } else {
            classificationIndex[typeString] = {
              "informationType": "classifier",
              "type": typeJson,
              "classifier": [classifierJson],
              "author": { "display": authorDisplay },
              "freeToShare": freetoshare
            };
            if (!authorDisplay) {
              delete classificationIndex[typeString].author;
            }
          }
        }
        for (const typeIndex in classificationIndex) {
          classificationsArray.push(classificationIndex[typeIndex]);
        }
      }
      returningdata = { "success": true, "classificationsArray": classificationsArray }
    }
    */
    if (typeof response.resourceClassifiers === "object" && !Array.isArray(response.resourceClassifiers)) {
      let resourceClassifiers = response.resourceClassifiers;
      let classificationIndex = {};
      let classificationsArray = [];
      for (const type in resourceClassifiers) {
        classificationIndex[type] = {
          "informationType": "classifier",
          "type": type,
          "classifier": [],
          "author": { "display": "" },
          "freeToShare": true
        };
        for (const classifier of resourceClassifiers[type]) {
          if (classifier.classifiervalue && classifier.freeToShare !== false) {
            classificationIndex[type].classifier.push(classifier.classifiervalue);
            if (classifier.authordisplay) {
              classificationIndex[type].author.display = classifier.authordisplay;
            }
          }
        }
        if (!classificationIndex[type].author.display) {
          delete classificationIndex[type].author;
        }
        classificationsArray.push(classificationIndex[type]);
      }
      returningdata = { "success": true, "classificationsArray": classificationsArray }
    }
    return returningdata;
  }

  if (editMode) {
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id)
    if (fhirJson.resourceType === "ActivityDefinition") {
      return <ActivityDefinitionEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "ArtifactAssessment" && fhirJson.meta?.profile) {
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation") {
        return <AdaptationEdit fhirJson={fhirJson} fhirEntryState={fhirEntryState}
          formInputsStateRef={formInputsStateRef} resourceId={fhirJson.id} setFhirEntryState={setFhirEntryState}
          submitUpdatedFhirResource={submitUpdatedFhirResource}
          changeFormState={changeFormState} classificationsLoadedState={classificationsLoadedState}
          classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} />
      }
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparison" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comparison") {
        return <ComparisonEdit fhirJson={fhirJson} fhirEntryState={fhirEntryState}
          formInputsStateRef={formInputsStateRef} resourceId={fhirJson.id} setFhirEntryState={setFhirEntryState}
          submitUpdatedFhirResource={submitUpdatedFhirResource}
          changeFormState={changeFormState} classificationsLoadedState={classificationsLoadedState}
          classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} />
      }
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/classification" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/classification") {
        return <ClassificationEdit fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary} citationJson={citationJson} />
      }
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comment" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comment") {
        return <CommentEdit fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary} citationJson={citationJson} />
      }
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/rating" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/rating") {
        return <RatingEdit fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary} citationJson={citationJson} />
      }
      if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification" ||
        fhirJson.meta.profile[0] === "https://fevir.net/Profile/RecommendationJustification") {
        return <RecommendationJustificationEdit fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} />
      }
    }
    if (fhirJson.resourceType === "Bundle") {
      return <BundleEdit fhirJson={fhirJson}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} />
    }
    if (fhirJson.resourceType === "Characteristic") {
      return <CharacteristicResourceEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "CodeSystem") {
      return <CodeSystemEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "Composition") {
      if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation")) {
        return <RecommendationAuthoringTool fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} globalContext={globalContext} history={history}
          previousVersionLoaded={fhirEntryState.previousVersionLoaded}
          adaptationReportState={adaptationReportState} 
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/guideline" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/guideline")) {
        return <GuidelineAuthoringTool fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} globalContext={globalContext}
          previousVersionLoaded={fhirEntryState.previousVersionLoaded} update={update} />
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report")) {
        return <M11ReportAuthoringTool fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} globalContext={globalContext} />
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package")) {
        return <EvidenceReportPackageAuthoringTool fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} globalContext={globalContext}
          previousVersionLoaded={fhirEntryState.previousVersionLoaded} />
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings")) {
        return <SummaryOfFindingsAuthoringTool fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
          globalContext={globalContext} history={history} changeFormState={changeFormState} />
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report")) {
        return <ComparativeEvidenceReportAuthoringTool fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} globalContext={globalContext} previousVersionLoaded={fhirEntryState.previousVersionLoaded} />
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report")) {
        return <CompositionMeasureReportAuthoringTool fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} globalContext={globalContext} reportType={"Baseline Measure"} />
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report")) {
        return <CompositionMeasureReportAuthoringTool fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} globalContext={globalContext} reportType={"Participant Flow"} />
      } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report" ||
        fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report")) {
        return <CompositionMeasureReportAuthoringTool fhirJson={fhirJson} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} classificationsArrayState={classificationsArrayState} citationSummary={citationSummary}
          citationJson={citationJson} globalContext={globalContext} reportType={"Outcome Measure"} />
      } else {
        return <CompositionEdit fhirJson={fhirJsonWithCiteAs}
          classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
          formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson}
          fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} globalContext={globalContext}
          history={history} changeFormState={changeFormState} />
      }
    }
    if (fhirJson.resourceType === "Evidence") {
      return <EvidenceEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "EvidenceVariable") {
      return <EvidenceVariableEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "Group") {
      return <GroupEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "Library") {
      return <LibraryEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "List") {
      return <ListEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "Measure") {
      return <MeasureEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "PlanDefinition") {
      return <PlanDefinitionEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "Project") {
      return <ProjectEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson}
        fhirEntryState={fhirEntryState}
      />
    }
    if (fhirJson.resourceType === "ResearchStudy") {
      return <ResearchStudyEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "SoftwareScript") {
      return <SoftwareScriptEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
    if (fhirJson.resourceType === "ValueSet") {
      return <ValueSetEdit fhirJson={fhirJsonWithCiteAs}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
        formInputsStateRef={formInputsStateRef} citationSummary={citationSummary} citationJson={citationJson} />
    }
  }

  if (fhirJson.resourceType === "ActivityDefinition") {
    return <ActivityDefinitionDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "ArtifactAssessment" && fhirJson.meta?.profile) {
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation") {
      return <AdaptationDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparison" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comparison") {
      return <ComparisonDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/classification" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/classification") {
      return <ClassificationDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comment" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comment") {
      return <CommentDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/rating" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/rating") {
      return <RatingDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
    if (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation-justification" ||
      fhirJson.meta.profile[0] === "https://fevir.net/Profile/RecommendationJustification") {
      return <RecommendationJustificationDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    }
  }
  if (fhirJson.resourceType === "Bundle") {
    return <BundleDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "Characteristic") {
    return <CharacteristicResourceDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "CodeSystem") {
    return <CodeSystemDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "Composition") {
    if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation")) {
      return <RecommendationViewerTool fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/guideline" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/guideline")) {
      return <GuidelineViewerTool fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/m11-report")) {
      return <M11ReportViewerTool fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/evidence-report-package")) {
      return <EvidenceReportPackageViewerTool fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report")) {
      return <ComparativeEvidenceReportViewerTool fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/baseline-measure-report")) {
      return <CompositionMeasureReportViewerTool fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} reportType={"Baseline Measure"} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/participant-flow-report")) {
      return <CompositionMeasureReportViewerTool fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} reportType={"Participant Flow"} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-report")) {
      return <CompositionMeasureReportViewerTool fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        classificationsLoadedState={classificationsLoadedState} reportType={"Outcome Measure"} />
    } else if (fhirJson.meta?.profile && (fhirJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings" ||
      fhirJson.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings")) {
      return <SummaryOfFindingsViewerTool fhirEntryState={fhirEntryState} fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
        formInputsStateRef={formInputsStateRef} setFhirEntryState={setFhirEntryState} changeFormState={changeFormState}
        classificationsLoadedState={classificationsLoadedState} globalContext={globalContext} history={history} />
    } else {
      return <CompositionDisplay fhirJson={fhirJson}
        classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
    }
  }
  if (fhirJson.resourceType === "Evidence") {
    return <EvidenceDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "EvidenceVariable") {
    return <EvidenceVariableDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "Group") {
    return <GroupDisplay fhirJson={fhirJson} classificationsArrayState={classificationsArrayState}
      classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "Library") {
    return <LibraryDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "List") {
    return <ListDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "Measure") {
    return <MeasureDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "PlanDefinition") {
    return <PlanDefinitionDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "Project") {
    return <ProjectDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState}
      formInputsStateRef={formInputsStateRef} fhirEntryState={fhirEntryState}
      referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
      history={history} changeFormState={changeFormState} setFhirEntryState={setFhirEntryState} />
  }
  if (fhirJson.resourceType === "ResearchStudy") {
    return <ResearchStudyDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "SoftwareScript") {
    return <SoftwareScriptDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }
  if (fhirJson.resourceType === "ValueSet") {
    return <ValueSetDisplay fhirJson={fhirJson}
      classificationsArrayState={classificationsArrayState} classificationsLoadedState={classificationsLoadedState} />
  }

  return <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
}

export default ResourceBuilderViewer;