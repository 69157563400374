import React, { useState, useEffect, useContext } from 'react';
import { TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DataEntry } from './DataEntryFormFunctions';
import { Button } from 'semantic-ui-react';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } })
}

const createNewSubjectResource = async (resourceState, globalContext) => {
  let section = resourceState.section;
  let population;
  let intervention;
  let comparator;
  let outcomes;

  for (let sect of section) {
    let sectionCode = sect?.code?.text || sect?.code?.coding?.[0].code || '';
    if (sectionCode === "Population" || sectionCode === "population") {
      if (sect.entry?.length > 0) {
        population = sect.entry[0];
      }
    }
    if (sectionCode === "Intervention" || sectionCode === "intervention") {
      if (sect.section?.length > 0) {
        let interventionDescription;
        let interventionGroup;
        for (let subsection of sect.section) {
          let subsectionCode = subsection?.code?.text || subsection?.code?.coding[0].code;
          if (subsectionCode === "Intervention Description" || subsectionCode === "intervention-description") {
            if (subsection.entry?.length > 0) {
              interventionDescription = subsection.entry[0];
            }
          }
          if (subsectionCode === "Intervention Group" || subsectionCode === "intervention-group") {
            if (subsection.entry?.length > 0) {
              interventionGroup = subsection.entry[0];
            }
          }
        }
        intervention = interventionDescription || interventionGroup;
      }
    }
    if (sectionCode === "Comparator" || sectionCode === "comparator") {
      if (sect.section?.length > 0) {
        let comparatorDescription;
        let comparatorGroup;
        for (let subsection of sect.section) {
          let subsectionCode = subsection?.code?.text || subsection?.code?.coding[0].code;
          if (subsectionCode === "Comparator Description" || subsectionCode === "comparator-description") {
            if (subsection.entry?.length > 0) {
              comparatorDescription = subsection.entry[0];
            }
          }
          if (subsectionCode === "Comparator Group" || subsectionCode === "comparator-group") {
            if (subsection.entry?.length > 0) {
              comparatorGroup = subsection.entry[0];
            }
          }
        }
        comparator = comparatorDescription || comparatorGroup;
      }
    }
    if (sectionCode === "Outcomes" || sectionCode === "outcome-measures") {
      if (sect.section?.length > 0) {
        outcomes = [];
        for (let subsection of sect.section) {
          if (subsection?.focus) {
            let outcome = subsection.focus;
            if (!(outcome?.display) && subsection.title) {
              outcome.display = subsection.title;
            }
            if (outcome.display && outcome.display !== "Outcome Name") {
              outcomes.push({ item: outcome });
            }
          }
        }
      }
    }
  }

  if (!population) {
    alert("Plese add a Group Resource in the Population section.");
    return;
  }
  if (!intervention) {
    alert("Plese add a Group Resource in the Intervention section.");
    return;
  }
  if (!comparator) {
    alert("Plese add a Group Resource in the Comparator section.");
    return;
  }
  if (!outcomes || outcomes.length === 0) {
    alert("Plese add an EvidenceVariable Resource as a focus in the Outcomes section.");
    return;
  }

  let titleStarter = resourceState.title.replace("ComparativeEvidenceReport: ", "") || "Untitled ComparativeEvidenceReport";

  let outcomeListResource = {
    "resourceType": "List",
    "meta": {
      "profile": [
        "http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-list"
      ]
    },
    "status": "current",
    "mode": "snapshot",
    "title": "OutcomeList: Outcomes for " + titleStarter,
    "code": {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/179423",
          "code": "OutcomeList",
          "display": "OutcomeList"
        }
      ],
      "text": "Outcome List"
    },
    "source": {
      "display": "Computable Publishing®: Comparative Evidence Report Authoring Tool"
    },
    "entry": outcomes
  };

  let fhirEntryString = JSON.stringify(outcomeListResource, null, 2);

  const body = {
    'functionid': "submitfhirresource",
    'idToken': "",
    'fhirEntry': fhirEntryString,
    'title': outcomeListResource.title,
    'status': 'active',
  };

  let response = await submitToFevirServer(globalContext, 5000, body, true, false);

  if (response.success) {
    let outcomeListFoi = response.formstateid;
    let outcomeListReference = {
      "reference": "List/" + outcomeListFoi,
      "type": "List",
      "display": outcomeListResource.title
    };
    let subjectResource = {
      "resourceType": "Group",
      "meta": {
        "profile": [
          "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report-subject"
        ]
      },
      "extension": [
        {
          "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
          "valueContactDetail": {
            "name": "Computable Publishing®: Comparative Evidence Report Authoring Tool"
          }
        }
      ],
      "title": "ComparativeEvidenceReportSubject: " + titleStarter,
      "status": "active",
      "publisher": "Computable Publishing LLC",
      "contact": [
        {
          "telecom": [
            {
              "system": "email",
              "value": "support@computablepublishing.com"
            }
          ]
        }
      ],
      "description": "This Group Resource (ComparativeEvidenceReportSubject Profile) expresses the conceptual group of PICO elements using the 'all of'' code in the combinationMethod element. Each of the characteristics described in this Resource are defined by reference to another Resource so the characteristic.code element has a value of text:'Defined by Reference' and uses a valueReference element. Three of the characteristics are defined by reference to a Group Resource and the last characteristic (Outcomes) is defined by reference to a List Resource. In this case the Group Resource is being used for the conceptual grouping of characteristics and is not a group of people or physical objects.",
      "useContext": [
        {
          "code": {
            "system": "https://fevir.net/resources/CodeSystem/179423",
            "code": "evidence-communication",
            "display": "Evidence Communication"
          },
          "valueCodeableConcept": {
            "coding": [
              {
                "system": "https://fevir.net/resources/CodeSystem/179423",
                "code": "ComparativeEvidenceReportSubject",
                "display": "ComparativeEvidenceReportSubject"
              }
            ]
          }
        }
      ],
      "copyright": "https://creativecommons.org/licenses/by-nc-sa/4.0/",
      "membership": "conceptual",
      "combinationMethod": "all-of",
      "characteristic": [
        {
          "description": "Population - " + population?.display,
          "code": {
            "text": "Population"
          },
          "valueReference": population,
          "exclude": false
        },
        {
          "description": "Intervention or Exposure - " + intervention?.display,
          "code": {
            "text": "Intervention or Exposure"
          },
          "valueReference": intervention,
          "exclude": false
        },
        {
          "description": "Comparator - " + comparator?.display,
          "code": {
            "text": "Comparator"
          },
          "valueReference": comparator,
          "exclude": false
        },
        {
          "description": "Outcomes - " + outcomeListResource.title,
          "code": {
            "text": "Outcomes"
          },
          "valueReference": outcomeListReference,
          "exclude": false
        }
      ]
    };
    let fhirEntryString2 = JSON.stringify(subjectResource, null, 2);
    const body2 = {
      'functionid': "submitfhirresource",
      'idToken': "",
      'fhirEntry': fhirEntryString2,
      'title': subjectResource.title,
      'status': 'active',
    };

    let response2 = await submitToFevirServer(globalContext, 5000, body2, true, false);
    if (response2.success) {
      let subjectFoi = response2.formstateid;
      return subjectFoi;
    }
  }
};

const compositionDotStatusValues = ['registered', 'partial', 'preliminary', 'final', 'amended', 'corrected',
  'appended', 'cancelled', 'entered-in-error', 'deprecated', 'unknown'];
const compositionDotAuthorResourceTypes = ['Practitioner', 'Organization'];

const EvidenceReportMetadataPatternEdit = ({ resourceState, setResourceState }) => {
  const globalContext = useContext(FevirContext);
  let resourceType = resourceState.resourceJson.resourceType;
  if (resourceType !== "Composition") {
    alert("resourceType should be Composition");
  }

  let profile = "Composition";
  if (resourceState.resourceJson.meta.profile?.[0]) {
    if (resourceState.resourceJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/guideline") {
      profile = "Guideline";
    } else if (resourceState.resourceJson.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/recommendation") {
      profile = "Recommendation";
    } else {
      profile = "Evidence Report";
    }
  }

  let subjectReferencedResourceTypes = ['ActivityDefinition', 'ArtifactAssessment', 'Citation', 'Composition', 'Evidence', 'EvidenceVariable', 'Group', 'List', 'Organization', 'PlanDefinition', 'ResearchStudy'];

  let startingExtension = resourceState.extension || [];
  let startingExtensionStateValue = { "otherExtensions": [] }
  if (Array.isArray(startingExtension) && startingExtension.length > 0) {
    for (const extension of startingExtension) {
      switch (extension.url) {
        case 'http://hl7.org/fhir/StructureDefinition/artifact-versionAlgorithm':
          startingExtensionStateValue.versionAlgorithmString = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-experimental':
          startingExtensionStateValue.experimental = extension.valueBoolean;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-description':
          startingExtensionStateValue.description = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-purpose':
          startingExtensionStateValue.purpose = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-copyright':
          startingExtensionStateValue.copyright = extension.valueMarkdown;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-copyrightLabel':
          startingExtensionStateValue.copyrightLabel = extension.valueString;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-approvalDate':
          startingExtensionStateValue.approvalDate = extension.valueDate;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-lastReviewDate':
          startingExtensionStateValue.lastReviewDate = extension.valueDate;
          break;
        case 'http://hl7.org/fhir/StructureDefinition/artifact-effectivePeriod':
          startingExtensionStateValue.effectivePeriod = extension.valuePeriod;
          break;
        default:
          startingExtensionStateValue.otherExtensions.push(extension)
      }
    }
  }

  const [extensionState, setExtensionState] = useState(startingExtensionStateValue);
  const [urlChanged, setUrlChanged] = useState(false);
  const [expandOtherMetadataState, setExpandOtherMetadataState] = useState(false);

  const statusSetterFunction = (value) => {
    setResourceState(prevState => { return { ...prevState, "status": value }; });
  }

  useEffect(() => {
    let newExtension = [];
    if (extensionState.otherExtensions) {
      newExtension = JSON.parse(JSON.stringify(extensionState.otherExtensions));
    }
    if (extensionState.versionAlgorithmString) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-versionAlgorithm",
        "valueString": extensionState.versionAlgorithmString
      });
    }
    if (typeof extensionState.experimental === "boolean") {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-experimental",
        "valueBoolean": extensionState.experimental
      });
    }
    if (extensionState.description) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-description",
        "valueMarkdown": extensionState.description
      });
    }
    if (extensionState.purpose) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-purpose",
        "valueMarkdown": extensionState.purpose
      });
    }
    if (extensionState.copyright) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-copyright",
        "valueMarkdown": extensionState.copyright
      });
    }
    if (extensionState.copyrightLabel) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-copyrightLabel",
        "valueString": extensionState.copyrightLabel
      });
    }
    if (extensionState.approvalDate) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-approvalDate",
        "valueDate": extensionState.approvalDate
      });
    }
    if (extensionState.lastReviewDate) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-lastReviewDate",
        "valueDate": extensionState.lastReviewDate
      });
    }
    if (extensionState.effectivePeriod) {
      newExtension.push({
        "url": "http://hl7.org/fhir/StructureDefinition/artifact-effectivePeriod",
        "valuePeriod": extensionState.effectivePeriod
      });
    }
    if (newExtension.length > 0) {
      handleChange("extension", newExtension, setResourceState);
    } else {
      handleChange("extension", null, setResourceState);
    }
  }, [extensionState])

  return <div>
    <h4>Identify this {profile} (document/digital object).</h4>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype="string" elementName='title' fieldLabel='Title (for human use)'
        startingValue={resourceState.title} setResourceState={setResourceState} />
      <DataEntry datatype="string" elementName='version' fieldLabel='Version'
        startingValue={resourceState.version} setResourceState={setResourceState} />
    </div>
    <h4>Describe this {profile} (document/digital object).</h4>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype="markdown" elementName='description' fieldLabel='Description' startingValue={extensionState.description} setResourceState={setExtensionState} />
      <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
        startingValue={resourceState.note} setResourceState={setResourceState} />
      <b>Status: </b>
      <ToggleButtonGroup
        className={"genericToggleButtonGroup buttonGroupWrap"}
        exclusive
        value={resourceState.status || ""}
        aria-label={'Status'}
        name={'Status'} >
        <ToggleButton key={0} className="genericToggleButton" color="primary"
          aria-label={'Registered'} value={'registered'}
          onClick={() => { statusSetterFunction('registered'); }}>
          Registered
        </ToggleButton>
        <ToggleButton key={1} className="genericToggleButton" color="primary"
          aria-label={'Draft'} value={'partial'}
          onClick={() => { statusSetterFunction('partial'); }}>
          Draft
        </ToggleButton>
        <ToggleButton key={2} className="genericToggleButton" color="primary"
          aria-label={'Published'} value={'final'}
          onClick={() => { statusSetterFunction('final'); }}>
          Published (public)
        </ToggleButton>
        <ToggleButton key={3} className="genericToggleButton" color="primary"
          aria-label={'Amended'} value={'amended'}
          onClick={() => { statusSetterFunction('amended'); }}>
          Amended (public)
        </ToggleButton>
        <ToggleButton key={4} className="genericToggleButton" color="primary"
          aria-label={'Retired'} value={'deprecated'}
          onClick={() => { statusSetterFunction('deprecated'); }}>
          Retired
        </ToggleButton>
        <ToggleButton key={5} className="genericToggleButton" color="primary"
          aria-label={'Unknown'} value={'unknown'}
          onClick={() => { statusSetterFunction('unknown'); }}>
          Unknown (public)
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
    <h4>Report what this {profile} is about.</h4>
    <div style={{ marginLeft: "24px" }}>
      {(!resourceState.subject && resourceState?.meta?.profile &&
        resourceState.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparative-evidence-report") && <>
          <Button className="formButton" style={{ color: "#000000" }}
            content={`Create a Group Resource (ComparativeEvidenceReportSubject Profile) for the Subject`}
            onClick={async () => {
              let resourceFOI = await createNewSubjectResource(resourceState, globalContext);
              if (resourceFOI) {
                setResourceState(prevState => {
                  let subject = [{ 'reference': `Group/${resourceFOI}`, 'type': 'Group' }];
                  return {
                    ...prevState,
                    subject: subject
                  };
                });
              }
            }} />
        </>}
      <DataEntry asArray={true} datatype='CodeableConcept' elementName='category' fieldLabel='Category'
        startingValue={resourceState.category} startCollapsed
        setResourceState={setResourceState} systemChoicesOpen={true} />
      <DataEntry asArray={true} datatype='Reference' elementName='subject' fieldLabel='Subject'
        enableCreation={true} startCollapsed referencedResourceTypes={subjectReferencedResourceTypes}
        startingValue={resourceState.subject} setResourceState={setResourceState} />
    </div>
    <h4>Report contributorship.</h4>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel='Author'
        startCollapsed={true} dataEntryStyle={'humanAuthor'}
        referencedResourceTypes={compositionDotAuthorResourceTypes} enableCreation={true}
        startingValue={resourceState.author} setResourceState={setResourceState} />
      <br />
      <DataEntry asArray={true} datatype='Attester' elementName='attester' fieldLabel='Contributor'
        dataEntryStyle={'humanAuthor'}
        startCollapsed={true} startEmptyArrayClosed={true} deletableArray={true}
        startingValue={resourceState.attester} setResourceState={setResourceState} />
    </div>
    <br />
    <span className={"unselectable"} style={{ cursor: "pointer" }}
      onClick={() => { setExpandOtherMetadataState(!expandOtherMetadataState) }}>
      {expandOtherMetadataState ? <>Collapse Other Metadata ▼</> : <>Expand Other Metadata ►</>}
    </span>
    {expandOtherMetadataState &&
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='RelatedArtifact' elementName='relatesTo' fieldLabel='Related Item' startCollapsed={true}
          allowedTypeValues='FHIR' startEmptyArrayClosed={true} deletableArray={true}
          startingValue={resourceState.relatesTo} setResourceState={setResourceState} />
        <h4>Identify this Composition (document/digital object).</h4>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype="string" elementName='name' fieldLabel='Name (for machine use)'
            startingValue={resourceState.name} setResourceState={setResourceState} />
          <DataEntry asArray={true} datatype='Identifier' elementName='identifier' fieldLabel='Identifier'
            startCollapsed={true} dataEntryStyle='preserveFOI'
            startingValue={resourceState.identifier} setResourceState={setResourceState} />
          <TextField style={{ width: "100%", marginTop: "16px" }} multiline
            className="inputField" type='text' label='URL' size="small" variant='outlined'
            value={resourceState.url || ""}
            onChange={(e) => { handleChange('url', e.target.value, setResourceState); setUrlChanged(true) }} />
          {urlChanged && <p>
            <b>Changing the URL is only desired if you want to set the CANONICAL url for this Resource!</b>
          </p>}
        </div>
        <h4>Date-related metadata</h4>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype="dateTime" elementName='date' fieldLabel='Date last changed'
            startingValue={resourceState.date} setResourceState={setResourceState} />
          <DataEntry datatype="date" elementName='approvalDate' fieldLabel='Date approved'
            startingValue={extensionState.approvalDate} setResourceState={setExtensionState} />
          <DataEntry datatype="date" elementName='lastReviewDate' fieldLabel='Date last reviewed'
            startingValue={extensionState.lastReviewDate} setResourceState={setExtensionState} />
          <DataEntry datatype="Period" elementName='effectivePeriod' fieldLabel='Effective period'
            startingValue={extensionState.effectivePeriod} setResourceState={setExtensionState} />
        </div>
        <h4>Publication metadata</h4>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype="Reference" elementName='custodian' fieldLabel='Custodian/Publisher'
            startingValue={resourceState.custodian} setResourceState={setResourceState}
            referencedResourceTypes={['Organization']} startCollapsed={true} />
          <DataEntry datatype="markdown" elementName='copyright' fieldLabel='Copyright'
            startingValue={extensionState.copyright} setResourceState={setExtensionState} />
          <DataEntry datatype="string" elementName='copyrightLabel' fieldLabel='Copyright Label'
            startingValue={extensionState.copyrightLabel} setResourceState={setExtensionState} />
        </div>
        <h4>Technical metadata</h4>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='UsageContext' elementName='useContext' fieldLabel='Use Context'
            startingValue={resourceState.useContext} setResourceState={setResourceState} startCollapsed={true}
            startEmptyArrayClosed={true} deletableArray={true} />
          <DataEntry datatype="markdown" elementName='purpose' fieldLabel='Purpose'
            startingValue={extensionState.purpose} setResourceState={setExtensionState} />
          <DataEntry datatype="boolean" elementName='experimental' fieldLabel='Experimental'
            startingValue={extensionState.experimental} setResourceState={setExtensionState} />
          <DataEntry datatype="string" elementName='versionAlgorithmString' fieldLabel={'Version Algorithm'}
            startingValue={extensionState.versionAlgorithmString} setResourceState={setExtensionState} />
          <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type'
            startingValue={resourceState.type} startCollapsed
            setResourceState={setResourceState} systemChoicesOpen={true} />
          <DataEntry datatype='Reference' elementName='encounter' fieldLabel='Encounter'
            referencedResourceTypes={["Encounter"]} startCollapsed
            startingValue={resourceState.encounter} setResourceState={setResourceState} />
        </div>
      </div>}
  </div>
}

export { EvidenceReportMetadataPatternEdit };