import React, { useState, useEffect, useImperativeHandle } from 'react';
import { AssociatedResourcesDisplay, SimpleResourceFieldViewer, getStringFromFHIR } from './ResourceFunctions';
import { DataEntry, DatatypeSelector, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';

const measureDotBasisValues = ['Observation', 'Condition', 'Medication'];
const measureDotScoringSystemChoices = [{ 'uri': 'http://terminology.hl7.org/CodeSystem/measure-scoring', 'display': 'HL7 Measure Scoring CodeSystem' }];
const measureDotScoringUnitSystemChoices = [{ 'uri': 'http://unitsofmeasure.org	', 'display': 'UCUM' }];
const measureDotCompositeScoringSystemChoices = [{ 'uri': 'http://terminology.hl7.org/CodeSystem/composite-measure-scoring', 'display': 'HL7 Composite Measure Scoring CodeSystem' }];
const measureDotTypeSystemChoices = [{ 'uri': 'http://terminology.hl7.org/CodeSystem/measure-type', 'display': 'HL7 Measure Type CodeSystem' }];
const measureDotSubjectAllowedDatatypes = ['CodeableConcept', 'Reference'];
const measureDotSubjectReferenceResourceTypes = ['Group'];
const measureDotSubjectCodeableConceptValueSet = [
  { system: "http://hl7.org/fhir/fhir-types", code: "CareTeam", display: "CareTeam" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Device", display: "Device" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Group", display: "Group" },
  { system: "http://hl7.org/fhir/fhir-types", code: "HealthcareService", display: "HealthcareService" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Location", display: "Location" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Organization", display: "Organization" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Patient", display: "Patient" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Practitioner", display: "Practitioner" },
  { system: "http://hl7.org/fhir/fhir-types", code: "PractitionerRole", display: "PractitionerRole" },
  { system: "http://hl7.org/fhir/fhir-types", code: "RelatedPerson", display: "RelatedPerson" }
 ];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.subtitle && typeof resource.type === "string") {
      innerDiv += "<p><b>Subtitle: </b>" + resource.subtitle + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.subjectCodeableConcept) {
      innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.CodeableConcept(resource.subjectCodeableConcept) + "</p><br/>";
    }
    if (resource.subjectReference) {
      innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.Reference(resource.subjectReference) + "</p><br/>";
    }
    if (resource.basis) {
      innerDiv += "<p><b>Basis: </b>" + resource.basis + "</p><br/>";
    }
    if (resource.library?.length > 0) {
      innerDiv += resource.library.map((item) => {
        return "<p><b>Library: </b>" + item + "</p><br/>"
      }).join("");
    }
    if (resource.disclaimer) {
      innerDiv += "<p><b>Disclaimer: </b>" + resource.disclaimer + "</p><br/>";
    }
    if (resource.scoring) {
      innerDiv += "<p><b>Scoring: </b>" + getStringFromFHIR.CodeableConcept(resource.scoring) + "</p><br/>";
    }
    if (resource.scoringUnit) {
      innerDiv += "<p><b>Scoring Unit: </b>" + getStringFromFHIR.CodeableConcept(resource.scoringUnit) + "</p><br/>";
    }
    if (resource.compositeScoring) {
      innerDiv += "<p><b>Composite Scoring: </b>" + getStringFromFHIR.CodeableConcept(resource.compositeScoring) + "</p><br/>";
    }
    if (resource.type?.length > 0) {
      innerDiv += resource.type.map((item) => {
        return "<p><b>Measure Type: </b>" + getStringFromFHIR.CodeableConcept(item) + "</p><br/>"
      }).join("");
    }
    if (resource.riskAdjustment) {
      innerDiv += "<p><b>Risk Adjustment: </b>" + resource.riskAdjustment + "</p><br/>";
    }
    if (resource.rateAggregation) {
      innerDiv += "<p><b>Rate Aggregation: </b>" + resource.rateAggregation + "</p><br/>";
    }
    if (resource.rationale) {
      innerDiv += "<p><b>Rationale: </b>" + resource.rationale + "</p><br/>";
    }
    if (resource.clinicalRecommendationStatement) {
      innerDiv += "<p><b>Clinical Recommendation Statement: </b>" + resource.clinicalRecommendationStatement + "</p><br/>";
    }
    if (resource.improvementNotation) {
      innerDiv += "<p><b>Improvement Notation: </b>" + getStringFromFHIR.CodeableConcept(resource.improvementNotation) + "</p><br/>";
    }
    if (resource.guidance) {
      innerDiv += "<p><b>Guidance: </b>" + resource.guidance + "</p><br/>";
    }
  } catch {
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const MeasureEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson, classificationsArrayState, classificationsLoadedState }) => {
/*
  const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "subtitle", "status", "experimental",
    "subjectCodeableConcept", "subjectReference", "basis", "date", "publisher", "contact", "description", "useContext", "jurisdiction",
    "purpose", "usage", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "library", "disclaimer", "scoring", "scoringUnit",
    "compositeScoring", "type", "riskAdjustment", "rateAggregation", "rationale", "clinicalRecommendationStatement",
    "improvementNotation", "term", "guidance", "group", "supplementalData"]
*/
  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "versionAlgorithmString": fhirJson.versionAlgorithmString, "versionAlgorithmCoding": fhirJson.versionAlgorithmCoding,
    "name": fhirJson.name, "title": fhirJson.title, "subtitle": fhirJson.subtitle, "status": fhirJson.status, "experimental": fhirJson.experimental,
    "subjectCodeableConcept": fhirJson.subjectCodeableConcept, "subjectReference": fhirJson.subjectReference, "basis": fhirJson.basis,
    "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "library": fhirJson.library, "disclaimer": fhirJson.disclaimer, "scoring": fhirJson.scoring, "scoringUnit": fhirJson.scoringUnit,
    "compositeScoring": fhirJson.compositeScoring, "type": fhirJson.type, "riskAdjustment": fhirJson.riskAdjustment,
    "rateAggregation": fhirJson.rateAggregation, "rationale": fhirJson.rationale, "clinicalRecommendationStatement": fhirJson.clinicalRecommendationStatement,
    "improvementNotation": fhirJson.improvementNotation, "term": fhirJson.term,
    "guidance": fhirJson.guidance, "group": fhirJson.group, "supplementalData": fhirJson.supplementalData, "newClassifications": null
  });

  let startingSubjectDatatype = 'none';
  if (fhirJson.subjectCodeableConcept) { startingSubjectDatatype = 'CodeableConcept'; }
  if (fhirJson.subjectReference) { startingSubjectDatatype = 'Reference'; }

  const [subjectDatatypeState, setSubjectDatatypeState] = useState(startingSubjectDatatype);

  useImperativeHandle(formInputsStateRef, () => ({
    measureState: resourceState
  }), [resourceState]);

  useEffect((() => {
    if (subjectDatatypeState === 'CodeableConcept') {
      setResourceState(prevState => { return { ...prevState, 'subjectReference': null } })
    }
    if (subjectDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'subjectCodeableConcept': null } })
    }
  }), [subjectDatatypeState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
    <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          generatedNarrative={generateNarrative(resourceState)}
          startingValue={resourceState.text} setResourceState={setResourceState} />
      </div>
     <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='subtitle' fieldLabel='Subtitle'
          startingValue={resourceState.subtitle} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={resourceState.name} setResourceState={setResourceState} />
          <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
            startingValue={resourceState.description} setResourceState={setResourceState} />
      </div>
      <h3 id="measure-definition">Measure Definition</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='uri' elementName='library'
          fieldLabel='Library (canonical URL)' startingValue={resourceState.library} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='disclaimer' fieldLabel='Disclaimer' startingValue={resourceState.disclaimer}
          setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='scoring' fieldLabel='Scoring' startingValue={resourceState.scoring}
          setResourceState={setResourceState} systemChoices={measureDotScoringSystemChoices} systemChoicesOpen={true} />
        <DataEntry datatype='CodeableConcept' elementName='scoringUnit' fieldLabel='Scoring Unit' startingValue={resourceState.scoringUnit}
          setResourceState={setResourceState} systemChoices={measureDotScoringUnitSystemChoices} systemChoicesOpen={true} />
        <DataEntry datatype='CodeableConcept' elementName='compositeScoring' fieldLabel='Composite Scoring'
          startingValue={resourceState.compositeScoring}
          setResourceState={setResourceState} systemChoices={measureDotCompositeScoringSystemChoices} systemChoicesOpen={true} />
        <DataEntry asArray={true} datatype='CodeableConcept' elementName='type' fieldLabel='Measure Type'
          startingValue={resourceState.type} systemChoices={measureDotTypeSystemChoices}
          systemChoicesOpen={true} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='riskAdjustment' fieldLabel='Risk Adjustment' startingValue={resourceState.riskAdjustment}
          setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='rateAggregation' fieldLabel='Rate Aggregation' startingValue={resourceState.rateAggregation}
          setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='rationale' fieldLabel='Rationale' startingValue={resourceState.rationale}
          setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='clinicalRecommendationStatement' fieldLabel='Clinical Recommendation Statement'
          startingValue={resourceState.clinicalRecommendationStatement}
          setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='improvementNotation' fieldLabel='Improvement Notation'
          startingValue={resourceState.improvementNotation}
          setResourceState={setResourceState} systemChoicesOpen={true} />
<p>Placeholder - data entry for term BackboneElement</p>
<DataEntry datatype='markdown' elementName='guidance' fieldLabel='Guidance'
          startingValue={resourceState.guidance}
          setResourceState={setResourceState} />
          <p>Placeholder - data entry for group BackboneElement</p>
          <p>Placeholder - data entry for supplementalData BackboneElement</p>
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
        <p><b>Subject:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='subject[x]' allowedDatatypes={measureDotSubjectAllowedDatatypes}
            datatypeState={subjectDatatypeState} setDatatypeState={setSubjectDatatypeState} />
          {(subjectDatatypeState === 'CodeableConcept') &&
            <DataEntry datatype='CodeableConcept' elementName='subjectCodeableConcept'
              fieldLabel='Subject (as CodeableConcept)' startingValue={resourceState.subjectCodeableConcept || null}
              valueSet={measureDotSubjectCodeableConceptValueSet} setResourceState={setResourceState} />}
          {(subjectDatatypeState === 'Reference') &&
            <DataEntry datatype='Reference' elementName='subjectReference' fieldLabel='Subject (as Reference)'
              referencedResourceTypes={measureDotSubjectReferenceResourceTypes}
              startingValue={resourceState.subjectReference || null} setResourceState={setResourceState} />}
        </div>
        <DataEntry asArray={true} datatype='uri' elementName='library' fieldLabel='Library' startingValue={resourceState.library} setResourceState={setResourceState} />
        <DataEntry datatype='code' elementName='basis' fieldLabel='Basis' startingValue={resourceState.basis} setResourceState={setResourceState}
          allowedValues={measureDotBasisValues}
          dataEntryStyle='dropdown' />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default MeasureEdit;
